import useSWR from 'swr/immutable'
import { BigNumber, ethers } from 'ethers'
import { useSwellWeb3 } from '@swell-web3/core'
import { useMulticallContract } from '@/hooks/useContract'
import { isEthAddressAlias } from '@/util/tokens'
import IERC20_ABI from '@/abis/IERC20.json'
import { IERC20, Multicall3 } from '@/abis/types'
import { uniq } from 'lodash'

async function fetchTokenAllowances(
  tokenAddresses: string[],
  multicall: Multicall3,
  owner: string,
  spender: string
) {
  const indexToTokenAddress = new Map<number, string>()
  const calls: Multicall3.CallStruct[] = []
  tokenAddresses.forEach((tokenAddress, index) => {
    indexToTokenAddress.set(index, tokenAddress)
    const contract = new ethers.Contract(tokenAddress, IERC20_ABI) as IERC20

    calls.push({
      target: tokenAddress,
      callData: contract.interface.encodeFunctionData('allowance', [
        owner,
        spender,
      ]),
    })
  })

  const results = await multicall.callStatic.tryAggregate(true, calls)

  const allowances: Record<string, BigNumber> = {}

  results.forEach((result, index) => {
    const tokenAddress = indexToTokenAddress.get(index)
    if (!tokenAddress) throw new Error('Token address not found')

    const contract = new ethers.Contract(tokenAddress, IERC20_ABI) as IERC20
    const allowance = contract.interface.decodeFunctionResult(
      'allowance',
      result.returnData
    )[0]

    allowances[tokenAddress] = allowance
  })

  return allowances
}

export function useTokenAllowancesMulticall(
  tokenAddresses: string[],
  spender: string
) {
  // filter out addresses that aren't on-chain (e.g. 0xee..ee for ETH)
  let addresses = uniq(tokenAddresses)
  if (addresses.some((address) => isEthAddressAlias(address))) {
    console.warn('useTokenAllowancesMulticall cannot handle address aliases')
    addresses = addresses.filter((address) => !isEthAddressAlias(address))
  }

  const { account: owner } = useSwellWeb3()
  const multicall = useMulticallContract()

  return useSWR(
    owner ? ['allowances', owner, spender, ...uniq(tokenAddresses)] : null,
    async () => {
      if (!owner) throw new Error('account is required')
      return fetchTokenAllowances(tokenAddresses, multicall!, owner, spender)
    }
  )
}
