import { INodeOperatorRegistry } from '@/abis/types/NodeOperatorRegistry'
import { DepositInfo } from '@/types/deposits'
import { prefix0x } from '@/util/hexStrings'

/**
 * Adapter logic to convert client-side representation of a chunk to the on-chain
 *  representation (for the sake of submission)
 *
 * @param chunk The chunk to be submitted on chain
 * @returns The on-chain representation of the chunk
 */
export const chunkToOnChainValidatorDetails = (
  chunk: DepositInfo[]
): INodeOperatorRegistry.ValidatorDetailsStruct[] => {
  return chunk.map(({ pubkey, signature }) => {
    return {
      pubKey: prefix0x(pubkey),
      signature: prefix0x(signature),
    }
  })
}
