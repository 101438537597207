import { useSwellWeb3 } from '@swell-web3/core'
import { useAlchemyTokenBalancesQuery } from '@/services/AlchemyService'
import { useCoingeckoTokenListQuery } from '@/services/TokenList'

export function useTokenBalances() {
  const { account } = useSwellWeb3()
  const coingeckoTokenListQuery = useCoingeckoTokenListQuery()
  const tokenBalancesQuery = useAlchemyTokenBalancesQuery(
    account,
    coingeckoTokenListQuery.data?.tokenMap
  )

  return tokenBalancesQuery
}
