/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  YearnDelayedWithdraw,
  YearnDelayedWithdrawInterface,
} from "../YearnDelayedWithdraw";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "_lrtVault",
        type: "address",
      },
      {
        internalType: "address",
        name: "_feeAddress",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__AlreadySetup",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__BadAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__MaxLossExceeded",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__MaxLossTooLarge",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__NoSharesToWithdraw",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__Paused",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__RequestPastCompletionWindow",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__ThirdPartyCompletionNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__WithdrawFeeTooHigh",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__WithdrawNotMatured",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__WithdrawsNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "DelayedWithdraw__transferNotAllowed",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        internalType: "contract Authority",
        name: "newAuthority",
        type: "address",
      },
    ],
    name: "AuthorityUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "newCompletionWindow",
        type: "uint32",
      },
    ],
    name: "CompletionWindowUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newFeeAddress",
        type: "address",
      },
    ],
    name: "FeeAddressSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint16",
        name: "newMaxLoss",
        type: "uint16",
      },
    ],
    name: "MaxLossUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "withdrawDelay",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "uint16",
        name: "withdrawFee",
        type: "uint16",
      },
      {
        indexed: false,
        internalType: "uint16",
        name: "maxLoss",
        type: "uint16",
      },
    ],
    name: "SetupWithdrawalsInAsset",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "allowed",
        type: "bool",
      },
    ],
    name: "ThirdPartyCompletionChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "Unpaused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint96",
        name: "shares",
        type: "uint96",
      },
    ],
    name: "WithdrawCancelled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "shares",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "assets",
        type: "uint256",
      },
    ],
    name: "WithdrawCompleted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "newWithdrawDelay",
        type: "uint32",
      },
    ],
    name: "WithdrawDelayUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint16",
        name: "newWithdrawFee",
        type: "uint16",
      },
    ],
    name: "WithdrawFeeUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint96",
        name: "shares",
        type: "uint96",
      },
      {
        indexed: false,
        internalType: "uint40",
        name: "maturity",
        type: "uint40",
      },
    ],
    name: "WithdrawRequested",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
    ],
    name: "WithdrawalsStopped",
    type: "event",
  },
  {
    inputs: [],
    name: "authority",
    outputs: [
      {
        internalType: "contract Authority",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "cancelUserWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
    ],
    name: "cancelWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "completionWindow",
        type: "uint32",
      },
    ],
    name: "changeCompletionWindow",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint16",
        name: "maxLoss",
        type: "uint16",
      },
    ],
    name: "changeMaxLoss",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "withdrawDelay",
        type: "uint32",
      },
    ],
    name: "changeWithdrawDelay",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint16",
        name: "withdrawFee",
        type: "uint16",
      },
    ],
    name: "changeWithdrawFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "completeUserWithdraw",
    outputs: [
      {
        internalType: "uint256",
        name: "assetsOut",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "completeWithdraw",
    outputs: [
      {
        internalType: "uint256",
        name: "assetsOut",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "feeAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isPaused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint96",
        name: "shares",
        type: "uint96",
      },
      {
        internalType: "uint16",
        name: "maxLoss",
        type: "uint16",
      },
      {
        internalType: "bool",
        name: "allowThirdPartyToComplete",
        type: "bool",
      },
    ],
    name: "requestWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "bool",
        name: "allow",
        type: "bool",
      },
    ],
    name: "setAllowThirdPartyToComplete",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract Authority",
        name: "newAuthority",
        type: "address",
      },
    ],
    name: "setAuthority",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_feeAddress",
        type: "address",
      },
    ],
    name: "setFeeAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "withdrawDelay",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "completionWindow",
        type: "uint32",
      },
      {
        internalType: "uint16",
        name: "withdrawFee",
        type: "uint16",
      },
      {
        internalType: "uint16",
        name: "maxLoss",
        type: "uint16",
      },
    ],
    name: "setupWithdrawAsset",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
    ],
    name: "stopWithdrawalsInAsset",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
    ],
    name: "transferDustToFeeRecipient",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "asset",
        type: "address",
      },
    ],
    name: "viewOutstandingDebt",
    outputs: [
      {
        internalType: "uint256",
        name: "debt",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20[]",
        name: "assets",
        type: "address[]",
      },
    ],
    name: "viewOutstandingDebts",
    outputs: [
      {
        internalType: "uint256[]",
        name: "debts",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "",
        type: "address",
      },
    ],
    name: "withdrawAssets",
    outputs: [
      {
        internalType: "bool",
        name: "allowWithdraws",
        type: "bool",
      },
      {
        internalType: "uint32",
        name: "withdrawDelay",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "completionWindow",
        type: "uint32",
      },
      {
        internalType: "uint128",
        name: "outstandingShares",
        type: "uint128",
      },
      {
        internalType: "uint16",
        name: "withdrawFee",
        type: "uint16",
      },
      {
        internalType: "uint16",
        name: "maxLoss",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "contract ERC20",
        name: "",
        type: "address",
      },
    ],
    name: "withdrawRequests",
    outputs: [
      {
        internalType: "bool",
        name: "allowThirdPartyToComplete",
        type: "bool",
      },
      {
        internalType: "uint16",
        name: "maxLoss",
        type: "uint16",
      },
      {
        internalType: "uint40",
        name: "maturity",
        type: "uint40",
      },
      {
        internalType: "uint96",
        name: "shares",
        type: "uint96",
      },
      {
        internalType: "uint256",
        name: "assetsAtTimeOfRequest",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class YearnDelayedWithdraw__factory {
  static readonly abi = _abi;
  static createInterface(): YearnDelayedWithdrawInterface {
    return new utils.Interface(_abi) as YearnDelayedWithdrawInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): YearnDelayedWithdraw {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as YearnDelayedWithdraw;
  }
}
