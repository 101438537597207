import { ChainDepositConfig } from '@/state/deployments/types'
import { DepositInfo } from '@/types/deposits'
import { IDepositInfoVerifier } from '../verifier/IDepositInfoVerifier'

type VerificationStep = {
  /**
   * Unique id of the verification step
   */
  id: string
  /**
   * Unit of logic responsible for performing verification
   */
  verifier: IDepositInfoVerifier
}

/**
 * Takes in an array of config for setting up verifiers and arbitrarily interpreting their results
 *  to create a domain-specific result.
 *
 * @returns A single function to validate a deposit and return a truthy failure state
 */
export const composeVerifiers = (args: {
  /**
   * Any asynchronous setup that must occur before performing validation
   * (e.g. initBLS)
   */
  beforeAll?: () => Promise<void>
  verifiers: VerificationStep[]
}) => ({
  verify: async (
    deposit: DepositInfo,
    chainDepositConfig: ChainDepositConfig
  ) => {
    const { verifiers, beforeAll = () => Promise.resolve() } = args

    await beforeAll()

    for (const { verifier, id } of verifiers) {
      try {
        const failure = await verifier.verify(deposit, chainDepositConfig)

        if (failure) {
          return { id, meta: failure }
        }
      } catch (err) {
        console.error('Verifier encountered an error', { err, id })
        throw err
      }
    }

    return null
  },
})
