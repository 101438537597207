import { Multicall3 } from '@/abis/types'
import { CumulativeMerkleDrop__factory } from '@/abis/types/factories/CumulativeMerkleDrop__factory'
import { MerkleContractsState } from '@/types/merkle'
import { ethers } from 'ethers'

export class MerkleDropService {
  multicall: Multicall3
  merkleDropAddress: string
  stakingAddress: string | undefined
  constructor({
    multicall,
    merkleDropAddress,
    stakingAddress,
  }: {
    multicall: Multicall3
    merkleDropAddress: string
    stakingAddress: string | undefined
  }) {
    this.multicall = multicall
    this.merkleDropAddress = merkleDropAddress
    this.stakingAddress = stakingAddress
  }

  fetchContractsState = async (): Promise<MerkleContractsState> => {
    const calls: Multicall3.Call3Struct[] = []
    calls.push({
      target: this.merkleDropAddress,
      allowFailure: false,
      callData:
        CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
          'merkleRoot'
        ),
    })
    calls.push({
      target: this.merkleDropAddress,
      allowFailure: false,
      callData:
        CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
          'claimIsOpen'
        ),
    })
    calls.push({
      target: this.merkleDropAddress,
      allowFailure: false,
      callData:
        CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
          'stakingContract'
        ),
    })

    const [merkleRootAirdropResult, claimIsOpenAirdropResult, stakingContract] =
      await this.multicall.callStatic.tryAggregate(true, calls)

    const merkleRootAirdrop =
      CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
        'merkleRoot',
        merkleRootAirdropResult.returnData
      )[0]
    const claimIsOpenAirdrop =
      CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
        'claimIsOpen',
        claimIsOpenAirdropResult.returnData
      )[0]
    const stakingContractAddress =
      CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
        'stakingContract',
        stakingContract.returnData
      )[0]

    const stakingExists =
      stakingContractAddress !== ethers.constants.AddressZero

    return {
      merkleDrop: {
        claimIsOpen: claimIsOpenAirdrop === 1,
        merkleRoot: merkleRootAirdrop,
      },
      staking: stakingExists
        ? { exists: true, kind: 'vault', isPaused: false }
        : { exists: false },
    }
  }
}
