import styled from 'styled-components/macro'
import { BigNumber } from 'ethers'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Skeleton } from '@/swell-ui/Skeleton'
import { Typography } from '@/swell-ui/Typography'
import { RswethIcon } from '@/swell-ui/icons/RswethIcon'
import { useRestakingRate } from '@/state/restakingStats/hooks'
import { AccountInfoButton } from './AccountInfoButton'
import { useDisplayRswEth } from '@/hooks/useRswEthDisplay'

interface RestakerAccountButtonProps {
  rswEthBalance: BigNumber | undefined
  loading: boolean
}

const Btn = styled(AccountInfoButton)`
  pointer-events: none;
  padding: 6px 12px;
`

const FieldContainer = styled.div`
  display: flex;
  min-width: 78px;
`

const StyledRswEthIcon = styled(RswethIcon)`
  width: 32px;
  height: 32px;
`

function RestakerAccountButton({
  rswEthBalance,
  loading,
}: RestakerAccountButtonProps) {
  const stakingRate = useRestakingRate()
  const displayRswEth = useDisplayRswEth()

  return (
    <Btn color="accent">
      <FlexRow gap="12">
        <StyledRswEthIcon />
        <AsyncDiv
          loading={loading || !stakingRate.data || !rswEthBalance}
          Container={FieldContainer}
          loader={() => <Skeleton width={100} />}
        >
          {() => {
            const rswEthBalanceDisplay = displayRswEth(
              rswEthBalance as BigNumber,
              {
                precision: 4,
                localize: true,
              }
            )

            return (
              <Typography variant="body" size="medium" letterSpacing="small">
                {rswEthBalanceDisplay}
              </Typography>
            )
          }}
        </AsyncDiv>
      </FlexRow>
    </Btn>
  )
}

export { RestakerAccountButton }
