import { useWeb3Button } from '@/hooks/useWeb3Button'
import styled from 'styled-components'
import { Button } from '@/swell-ui/Button'
import { CircularProgress } from '@/swell-ui/CircularProgress'
import { useSwellWeb3 } from '@/swell-web3/core'
import {
  EigenStakedropErrors,
  PreparedClaimEigenStakedrop as PreparedClaimEigenStakedrop,
} from './eigenStakedropCalls'
import { ClaimEigenStakedrop } from '@/state/rsweth/hooks'
import { ReactNode } from 'react'

const EigenStakedropButton = styled(Button)`
  &:disabled {
    svg {
      opacity: 0.5;
    }
  }
`
const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`
const ButtonInner = styled.span`
  position: relative;
  > div {
    position: absolute;
    top: -7px;
    left: -32px;
  }
`

export function ConnectWalletButton() {
  const { connect } = useSwellWeb3()
  return (
    <EigenStakedropButton onClick={() => connect()}>
      Connect wallet
    </EigenStakedropButton>
  )
}

export function ClaimedEigenStakedropButton({
  prepared,
  claimEigenStakedrop,
}: {
  prepared: PreparedClaimEigenStakedrop
  claimEigenStakedrop: ClaimEigenStakedrop
}) {
  let idleLabel: ReactNode = 'Claim'
  if (prepared.error === EigenStakedropErrors.ClaimingNotOpen) {
    idleLabel = 'Claim not open'
  } else if (prepared.error === EigenStakedropErrors.AlreadyClaimed) {
    idleLabel = (
      <ButtonInner>
        <div>
          <Tick />
        </div>
        <span>Claimed</span>
      </ButtonInner>
    )
  }

  const btn = useWeb3Button(
    claimEigenStakedrop,
    claimEigenStakedrop.call,
    prepared.args,
    {
      idleLabel,
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Claimed',
    }
  )

  return (
    <EigenStakedropButton disabled={btn.disabled} onClick={btn.onClick}>
      {btn.label}
    </EigenStakedropButton>
  )
}

function Tick() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      style={{ marginTop: '8px' }}
    >
      <path
        d="M15.2812 9.4375L10.2367 14.25L7.71875 11.8438"
        stroke={'rgba(24,13,104,0.5)'}
        stroke-width="1.33"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M11.5 19.75C16.0563 19.75 19.75 16.0563 19.75 11.5C19.75 6.94365 16.0563 3.25 11.5 3.25C6.94365 3.25 3.25 6.94365 3.25 11.5C3.25 16.0563 6.94365 19.75 11.5 19.75Z"
        stroke={'rgba(24,13,104,0.5)'}
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
