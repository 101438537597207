import {
  yearnDepositAssetValueUsd,
  yearnVaultTokenToUsd,
} from '@/components/StakingWidget/yearn/yearnConversions'
import { displayFiat } from '@/util/displayFiat'
import { BigNumber } from 'ethers'

export function displayBtcLrtVaultTokenValue({
  ethUsdMarketRate,
  vaultTokenBalance,
  depositAssetEthRate,
  pricePerShare,
  vaultTokenDecimals,
  depositAssetDecimals,
}: {
  ethUsdMarketRate: number
  vaultTokenBalance: BigNumber
  depositAssetEthRate: BigNumber
  pricePerShare: BigNumber
  vaultTokenDecimals: number
  depositAssetDecimals: number
}) {
  return displayFiat(
    yearnVaultTokenToUsd({
      ethUsdMarketRate,
      vaultTokenAmount: vaultTokenBalance,
      depositAssetEthRate,
      pricePerShare,
      vaultTokenDecimals,
      depositAssetDecimals,
    })
  )
}

export function displayBtcLrtTVL({
  ethUsdMarketRate,
  totalAssets,
  depositAssetEthRate,
  depositAssetDecimals,
}: {
  ethUsdMarketRate: number
  totalAssets: BigNumber
  depositAssetEthRate: BigNumber
  depositAssetDecimals: number
}) {
  return displayFiat(
    yearnDepositAssetValueUsd({
      depositAssetAmount: totalAssets,
      ethUsdMarketRate,
      depositAssetEthRate,
      depositAssetDecimals,
    })
  )
}
