// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/eigenpoints.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { EigenPointsUserRequest, EigenPointsUserResponse } from "./eigenpoints_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.EigenPointsService
 */
export const EigenPointsService = {
  typeName: "swell.v3.EigenPointsService",
  methods: {
    /**
     * @generated from rpc swell.v3.EigenPointsService.EigenPointsUser
     */
    eigenPointsUser: {
      name: "EigenPointsUser",
      I: EigenPointsUserRequest,
      O: EigenPointsUserResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

