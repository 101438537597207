import React from 'react'
import { useDepositSubmissionDetailsContext } from '@/state/depositSubmission/hooks'
import useDepositSubmissionFailNotify from '@/hooks/useDepositSubmissionFailNotify'
import { DepositCollectionSubmissionTableCommon } from './DepositCollectionSubmissionTableCommon'

interface StakingDepositCollectionSubmissionTableProps {
  isHistoric: boolean
}

export function StakingDepositCollectionSubmissionTable({
  isHistoric,
}: StakingDepositCollectionSubmissionTableProps) {
  return (
    <DepositCollectionSubmissionTableCommon
      useDepositSubmissionDetailsContext={useDepositSubmissionDetailsContext}
      useDepositSubmissionFailNotify={useDepositSubmissionFailNotify}
      isHistoric={isHistoric}
    />
  )
}
