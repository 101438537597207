import { SerializableContractReceipt } from '@/util/transactionSerialization'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface RestakingState {
  nativeCurrencyAmountHex?: string
  txHash?: string
  receipt?: SerializableContractReceipt
  error?: string
}

const initialState: RestakingState = {}

const restakingSlice = createSlice({
  name: 'restaking',
  initialState,
  reducers: {
    clearRestaking: () => initialState,
    onRestake: (
      state,
      { payload }: PayloadAction<{ nativeCurrencyAmountHex: string }>
    ) => {
      state.nativeCurrencyAmountHex = payload.nativeCurrencyAmountHex
    },
    setRestakingError: (
      state,
      { payload }: PayloadAction<{ error: string }>
    ) => {
      state.error = payload.error
    },
    setRestakingTxHash: (
      state,
      { payload }: PayloadAction<{ txHash: string }>
    ) => {
      state.txHash = payload.txHash
    },
    setRestakingReceipt: (
      state,
      { payload }: PayloadAction<{ receipt: SerializableContractReceipt }>
    ) => {
      state.receipt = payload.receipt
    },
  },
})

export const {
  clearRestaking,
  setRestakingTxHash,
  onRestake,
  setRestakingError,
  setRestakingReceipt,
} = restakingSlice.actions
export default restakingSlice.reducer
