import { GeofencePermission, GeoFenceResult } from '@/types/geofence'
import aquanautSadUrl from '@/assets/images/aquanaut-sad-158x158.png'
import { DecoBox } from '../DecoBox'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

// https://www.figma.com/design/1trLPK52KCe6Nko8e2NCbU/Swell-Network-Design?node-id=10949-11127&node-type=frame&t=5t576JWvgah3EpS7-0
export function GeoRestrictedView({
  permission,
  result,
  returnLinkBlockedAirdrops,
}: {
  result: GeoFenceResult | undefined
  permission: GeofencePermission | undefined
  returnLinkBlockedAirdrops: string
}) {
  const showReturnAirdrops = permission === 'blocked_airdrops'

  let timeStr = ''
  let countryStr = ''
  let regionStr = ''
  let ipStr = ''
  if (result) {
    timeStr = `${result.timestamp}`
    countryStr = `${result.countryCode}`
    regionStr = `${result.region}`
    ipStr = `${result.ip}`
  }

  return (
    <GeoRestrictedLayout>
      <img src={aquanautSadUrl} alt="Aquanaut sad" width="158" height="158" />
      <DecoBox>
        <h1>Access restricted</h1>
        <p>
          Unfortunately this page is not available in your country right now.
        </p>
        <p>
          For more information please read our{' '}
          <a
            href="https://www.swellnetwork.io/legal/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms of service
          </a>
          .
        </p>
        {showReturnAirdrops && (
          <p>
            Or <Link to={returnLinkBlockedAirdrops}>click here</Link> to return
            to the L2 page.
          </p>
        )}
        <pre>
          <div>Time: {timeStr}</div>
          <div>Country: {countryStr}</div>
          <div>Region: {regionStr}</div>
          <div>IP: {ipStr}</div>
        </pre>
      </DecoBox>
    </GeoRestrictedLayout>
  )
}

const GeoRestrictedLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 0;

  img {
    width: 158px;
    height: 158px;
  }

  p:nth-child(2) {
    margin: 0;
  }
  p:nth-child(3) {
    margin: 0;
    margin-top: 8px;
  }
  p:nth-child(4) {
    margin: 0;
    margin-top: 8px;
  }
  p:nth-child(5) {
    margin: 0;
    margin-top: 8px;
  }

  * {
    text-align: center;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(0, -79px);

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      transform: translate(-50%, -50%);

      h1 {
        font-size: 24px;
      }

      img {
        width: 79px;
        height: 79px;
      }
    }
  `}
`
