import { useRestakeNativeCurrencyState } from '@/state/restaking/hooks'
import { BigNumber } from 'ethers'

export enum StakingSubmissionStatus {
  IDLE = 'idle',
  ERROR = 'error',
  PROMPTING = 'prompting',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
}

/**
 * Reads the raw state of the restaking transaction workflow to provide a high level
 *  representation ("lens") which is better suited for presentation.
 *
 * @returns High level representation of the restaking store's state.
 */
export const useRestakeLens = () => {
  const {
    error: maybeError,
    nativeCurrencyAmountHex,
    receipt,
    txHash,
  } = useRestakeNativeCurrencyState()

  const { status: receiptStatus } = receipt ?? {}

  return {
    get error() {
      if (maybeError) return maybeError
      if (receiptStatus === 0) {
        // Not enough information to determine why
        return 'TX Failed'
      }

      return undefined
    },
    get nativeCurrencyAmount() {
      if (!nativeCurrencyAmountHex) return undefined
      return BigNumber.from(nativeCurrencyAmountHex)
    },
    receipt,
    txHash,
    get status(): StakingSubmissionStatus {
      if (this.error) return StakingSubmissionStatus.ERROR

      if (!nativeCurrencyAmountHex) return StakingSubmissionStatus.IDLE
      if (!txHash) return StakingSubmissionStatus.PROMPTING
      if (!receipt) return StakingSubmissionStatus.PENDING

      return StakingSubmissionStatus.FULFILLED
    },
  }
}
