import React from 'react'

function TwitterIcon(props: any) {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.292 8.61854C28.604 9.53634 27.7723 10.3369 26.8291 10.9894C26.8291 11.2292 26.8291 11.4689 26.8291 11.722C26.8366 16.0759 25.0975 20.2507 22.0016 23.3105C18.9057 26.3704 14.7119 28.0593 10.3605 27.9986C7.84488 28.007 5.36145 27.4326 3.10477 26.3203C2.98309 26.2672 2.90461 26.1468 2.90507 26.014V25.8674C2.90507 25.6762 3.06004 25.5211 3.25122 25.5211C5.72403 25.4396 8.10863 24.5823 10.0676 23.0703C7.82939 23.0253 5.81554 21.6993 4.88875 19.6605C4.84194 19.5491 4.85652 19.4214 4.92721 19.3235C4.99791 19.2255 5.11452 19.1715 5.2349 19.181C5.91515 19.2494 6.6022 19.1861 7.25852 18.9945C4.78769 18.4816 2.93113 16.4304 2.66543 13.9197C2.65599 13.7993 2.70996 13.6827 2.80785 13.6119C2.90574 13.5412 3.0334 13.5266 3.14471 13.5734C3.80777 13.866 4.52355 14.0201 5.24821 14.0263C3.08318 12.6053 2.14802 9.90245 2.97164 7.44641C3.05665 7.20776 3.2609 7.03145 3.50933 6.98229C3.75776 6.93313 4.01372 7.01836 4.18315 7.20666C7.10473 10.3161 11.119 12.169 15.3796 12.3747C15.2705 11.9392 15.2168 11.4917 15.2199 11.0427C15.2597 8.68864 16.7163 6.59159 18.9076 5.73331C21.099 4.87503 23.5915 5.4254 25.2182 7.12674C26.327 6.9155 27.399 6.54305 28.4 6.02121C28.4734 5.97543 28.5664 5.97543 28.6397 6.02121C28.6854 6.09458 28.6854 6.18761 28.6397 6.26097C28.1548 7.3713 27.3357 8.30256 26.2965 8.92489C27.2065 8.81937 28.1005 8.60471 28.9592 8.28555C29.0315 8.23633 29.1265 8.23633 29.1988 8.28555C29.2594 8.31326 29.3047 8.36625 29.3227 8.43041C29.3406 8.49458 29.3294 8.56339 29.292 8.61854Z"
        fill="#E7E7E7"
      />
    </svg>
  )
}

export { TwitterIcon }
