import { compareHex } from '@/util/hexStrings'
import { IDepositInfoVerifier } from './IDepositInfoVerifier'

export const withdrawalCredentialsVerifier: IDepositInfoVerifier = {
  verify: async (deposit, chainDepositConfig) => {
    const { withdrawalCredentials } = chainDepositConfig

    if (!withdrawalCredentials) return false // if not provided, not checked on frontend
    return !compareHex(deposit.withdrawal_credentials, withdrawalCredentials)
  },
}
