import useSWRImmutable from 'swr/immutable'
import { ethers } from 'ethers'
import { useSwellWeb3 } from '@swell-web3/core'
import { useRswETHApi } from './context'
import { useWeb3Call } from '@/hooks/useWeb3Call'
import { getApproveGasEstimate } from '@/constants/gasEstimates'
import { getAddress } from 'ethers/lib/utils'

// --
// static

export function useRswEthToken() {
  return useRswETHApi().rswETHToken
}
export function useEigenToken() {
  return useRswETHApi().eigenToken
}
export function useRswEthExitAssets() {
  return useRswETHApi().exitAssets
}
export function useRswEthExitToWithdrawAsset() {
  return useRswETHApi().exitToWithdrawAsset
}
export function useRswEthWithdrawAssetToExitAddress() {
  return useRswETHApi().withdrawAssetToExit
}

// --
// views

export function useRswEthBalance() {
  const { account, chainId } = useSwellWeb3()
  const api = useRswETHApi()
  return useSWRImmutable(
    account ? ['rswETHBalance', chainId, account] : undefined,
    api.read.rswETHBalance
  )
}

export function useRswEXITClaims() {
  const { account, chainId } = useSwellWeb3()
  const api = useRswETHApi()
  return useSWRImmutable(
    account ? ['rswEXITClaims', account, chainId] : undefined,
    api.read.rswEXITClaims
  )
}

export function useRswEXITAllowances() {
  const { account, chainId } = useSwellWeb3()
  const api = useRswETHApi()
  return useSWRImmutable(
    account ? ['rswEXITAllowances', account, chainId] : undefined,
    api.read.rswEXITAllowances
  )
}

export function useRswEthEigenStakedrop() {
  const { account, chainId } = useSwellWeb3()
  const api = useRswETHApi()
  return useSWRImmutable(
    account ? ['rswEthEigenStakedrop', account, chainId] : undefined,
    () => api.read.eigenStakedrop()
  )
}
export function useRswEthEigenStakedropContractsState() {
  const { chainId } = useSwellWeb3()
  const api = useRswETHApi()
  return useSWRImmutable(['rswEthEigenStakedropContractsState', chainId], () =>
    api.read.eigenStakedropContractsState()
  )
}

// --
// mutations

export function useApproveRswETHForWithdrawal() {
  const api = useRswETHApi()

  return useWeb3Call({
    estimateGas: api.write.approveRswETHForWithdrawalEstimateGas,
    fn: api.write.approveRswETHForWithdrawal,
    staticGasEstimate: () => getApproveGasEstimate(),
    validate: async ({ amount, assetAddress }) => {
      if (amount.lte(0)) {
        return 'Amount must be greater than 0'
      }

      if (assetAddress) {
        try {
          if (getAddress(assetAddress) === ethers.constants.AddressZero)
            return 'Invalid asset address'
        } catch (e) {
          return 'Invalid asset address'
        }
      }

      return null
    },
  })
}
export type ApproveRswETHForWithdrawal = ReturnType<
  typeof useApproveRswETHForWithdrawal
>

export function useCreateWithdrawRequestRswETH() {
  const api = useRswETHApi()

  return useWeb3Call({
    estimateGas: api.write.createWithdrawRequestEstimateGas,
    fn: api.write.createWithdrawRequest,
    staticGasEstimate: () => getApproveGasEstimate(),
    validate: async ({ rswETHAmount, assetAddress }) => {
      if (rswETHAmount.lte(0)) {
        return 'Amount must be greater than 0'
      }

      if (assetAddress) {
        try {
          if (getAddress(assetAddress) === ethers.constants.AddressZero)
            return 'Invalid asset address'
        } catch (e) {
          return 'Invalid asset address'
        }
      }

      return null
    },
  })
}
export type CreateWithdrawRequestRswETH = ReturnType<
  typeof useCreateWithdrawRequestRswETH
>

export function useFinalizeWithdrawalRswETH() {
  const api = useRswETHApi()

  return useWeb3Call({
    estimateGas: api.write.finalizeWithdrawalEstimateGas,
    fn: api.write.finalizeWithdrawal,
    staticGasEstimate: () => getApproveGasEstimate(),
    validate: async ({ requestId, assetAddress }) => {
      if (!requestId) {
        return 'No request id'
      }

      if (assetAddress) {
        try {
          if (getAddress(assetAddress) === ethers.constants.AddressZero)
            return 'Invalid asset address'
        } catch (e) {
          return 'Invalid asset address'
        }
      }

      return null
    },
  })
}
export type FinalizeWithdrawalRswETH = ReturnType<
  typeof useFinalizeWithdrawalRswETH
>

export function useClaimEigenStakedrop() {
  const api = useRswETHApi()

  return useWeb3Call({
    estimateGas: api.write.claimEigenStakedropEstimateGas,
    fn: api.write.claimEigenStakedrop,
    staticGasEstimate: () => getApproveGasEstimate(),
    validate: async ({ amountToLock, cumulativeAmount, merkleProof }) => {
      const valid = await api.read.checkClaimEigenStakedrop({
        cumulativeAmount,
        amountToLock,
        merkleProof,
      })

      if (!valid) {
        return 'Invalid merkle proof'
      }

      return null
    },
  })
}
export type ClaimEigenStakedrop = ReturnType<typeof useClaimEigenStakedrop>
