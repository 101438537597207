import { useSwellWeb3 } from '@swell-web3/core'
import { useAppDispatch, useAppSelector } from '../hooks'
import { IBatchDepositSubmissionDetails } from '../depositSubmission/models/BatchDepositSubmissionDetails'
import { DepositSubmissionDetails } from '../depositSubmission/models/DepositSubmissionDetails'
import { bindActionCreators } from 'redux'
import * as reducer from './reducer'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react'
import { useRestakingNodeOperatorRegistryContract } from '@/hooks/useContract'
import { DepositInfo } from '@/types/deposits'
import { batchSubmitAwaitInLoop } from './thunks'

/**
 * The overall submission status across all batches of deposits
 */
export enum BatchDepositSubmissionStatus {
  UNSTARTED = 'unstarted',
  STARTED = 'started',
  FINISHED = 'finished',
}

export const useBatchRestakingDepositSubmissionStatus = () => {
  const { submissionStartTimestamp, submissionEndTimestamp } = useAppSelector(
    (state) => state.restakingDepositSubmission
  )

  return {
    get submissionStatus() {
      if (submissionEndTimestamp) return BatchDepositSubmissionStatus.FINISHED
      if (submissionStartTimestamp) return BatchDepositSubmissionStatus.STARTED
      return BatchDepositSubmissionStatus.UNSTARTED
    },
  }
}

export const useRestakingDepositSubmissionHistoricState = () => {
  const { submissionStartTimestamp, viewedFinalSubmissionTimestamp } =
    useAppSelector((state) => state.restakingDepositSubmission)

  const hasViewedResult = !!viewedFinalSubmissionTimestamp

  // if this hook mounts with an existing start timestamp, we can infer that the data is historic
  const initialSubmissionStartTimestamp = useRef(submissionStartTimestamp)
  const isHistoric = useMemo<boolean>(() => {
    // The data is no longer historic if the current submission start timestamp does not equal the
    //  ref that was saved on first mount
    return (
      !!initialSubmissionStartTimestamp.current &&
      submissionStartTimestamp === initialSubmissionStartTimestamp.current
    )
  }, [submissionStartTimestamp])

  return {
    isHistoric,
    hasViewedResult,
    get hasUnviewedHistoricSubmission() {
      return !hasViewedResult && isHistoric
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { default: _default, ...actions } = reducer

export const useRestakingDepositSubmissionActions = () => {
  const dispatch = useAppDispatch()

  return useMemo(() => {
    return bindActionCreators(actions, dispatch)
  }, [dispatch])
}

/**
 * Hook responsible for coordinating the batch submission of validator keys
 * Awaits the current transaction before prompting the user with the next.
 */
export const useBatchRestakingSubmitAwaitInLoop = () => {
  const nodeOperatorRegistry = useRestakingNodeOperatorRegistryContract()
  const dispatch = useAppDispatch()

  const { isGnosis } = useSwellWeb3()

  return useCallback(
    (chunks: DepositInfo[][]) => {
      if (!nodeOperatorRegistry) return
      dispatch(
        batchSubmitAwaitInLoop({ chunks, isGnosis, nodeOperatorRegistry })
      )
    },
    [nodeOperatorRegistry, dispatch, isGnosis]
  )
}

/**
 * Redux store contains the minimum required state, but it is not in a form that is easy for
 *  the frontend to use.
 *
 * The purpose of this hook is to provide a lens into the state of the submission store, to
 *  minimize the amount of data transformations occurring in the view layer
 *
 * This hook is wrapped with a context provider to ensure that it is only calculated in one
 *  location, because it is expensive to calculate for larger submission sizes.
 *
 * @returns High level representation of submission state @see IBatchDepositSubmissionDetails
 */
const useRestakingDepositSubmissionDetails = () => {
  const {
    errorMap,
    promptMap,
    receiptMap,
    transactionMap,
    chunks: validatorSubmissionChunks = [],
  } = useAppSelector((state) => state.restakingDepositSubmission)

  const { isGnosis } = useSwellWeb3()

  return {
    // Q: Why not React.useMemo?
    // A: shallow compare on deep arrays does not work; calculating a comparison
    //    hash is expensive and prone to bugs. Performance gains are either lost
    //    or insignificant.
    get details(): IBatchDepositSubmissionDetails {
      const batches = validatorSubmissionChunks.map((chunk, chunkIdx) => {
        const maybeError = errorMap[chunkIdx]
        if (maybeError)
          return DepositSubmissionDetails.fromError({
            chunk,
            chunkIdx,
            error: maybeError,
          })

        if (promptMap[chunkIdx])
          return DepositSubmissionDetails.asPrompting({ chunk, chunkIdx })

        const receipt = receiptMap[chunkIdx]
        const transaction = transactionMap[chunkIdx]

        if (!transaction)
          return DepositSubmissionDetails.asNotStarted({
            chunk,
            chunkIdx,
          })

        if (isGnosis)
          return DepositSubmissionDetails.asExternal({ chunk, chunkIdx })

        const { txHash } = transaction

        if (!receipt)
          return DepositSubmissionDetails.fromTransaction({
            chunk,
            chunkIdx,
            txHash,
          })

        return DepositSubmissionDetails.fromReceipt({
          chunk,
          chunkIdx,
          receipt,
          txHash,
        })
      })

      return { batches }
    },
  }
}

const MISSING_PROVIDER = Symbol()
const RestakingDepositSubmissionDetailsContext = createContext<
  | {
      details: IBatchDepositSubmissionDetails
    }
  | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useRestakingDepositSubmissionDetailsContext() {
  const value = useContext(RestakingDepositSubmissionDetailsContext)
  if (value === MISSING_PROVIDER) {
    throw new Error(
      'DepositSubmissionDetails hooks must be wrapped in a <DepositSubmissionDetailsProvider>'
    )
  }
  return value
}

/**
 * Provides a context for reading the result of useDepositSubmissionDetails
 *
 * useDepositSubmissionDetails is expensive to calculate for larger batch sizes, and should
 *  only be consumed in one place in the app.
 */
export function RestakingDepositSubmissionDetailsProvider({
  children,
}: {
  children: ReactNode
}) {
  return (
    <RestakingDepositSubmissionDetailsContext.Provider
      value={useRestakingDepositSubmissionDetails()}
    >
      {children}
    </RestakingDepositSubmissionDetailsContext.Provider>
  )
}
