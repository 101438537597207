import {
  useRestakingDepositSubmissionDetailsContext,
  useBatchRestakingDepositSubmissionStatus,
  BatchDepositSubmissionStatus,
} from '@/state/restakingDepositSubmission/hooks'
import { DepositSubmissionStatus } from '@/state/depositSubmission/models/DepositSubmissionDetails'
import { useGlobalNotification } from '@/swell-ui/GlobalNotification'
import React from 'react'

/**
 * Displays a warning banner when the validator deposit submission process finishes and
 *  has errors.
 */
export const useDepositSubmissionFailNotify = () => {
  const { details } = useRestakingDepositSubmissionDetailsContext()
  const { submissionStatus } = useBatchRestakingDepositSubmissionStatus()

  const prevSubmissionStatus =
    React.useRef<typeof submissionStatus>(submissionStatus)

  const { notify, removeNotification } = useGlobalNotification()

  React.useEffect(() => {
    const didFinish =
      prevSubmissionStatus.current !== BatchDepositSubmissionStatus.FINISHED &&
      submissionStatus === BatchDepositSubmissionStatus.FINISHED

    prevSubmissionStatus.current = submissionStatus

    if (!didFinish) return

    const nErrors = details.batches.filter(
      (b) => b.status === DepositSubmissionStatus.ERROR
    ).length

    if (nErrors > 0) {
      // TODO: i18n pluralization
      const msg =
        nErrors > 1
          ? `There are ${nErrors} failed transactions, please check the table for errors.`
          : `There is 1 failed transaction, please check the table for errors.`

      const nId = notify(msg, 'error')

      return () => {
        removeNotification(nId)
      }
    }
  }, [details.batches, notify, removeNotification, submissionStatus])
}

export default useDepositSubmissionFailNotify
