import { SerializableContractReceipt } from '@/util/transactionSerialization'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface StakingState {
  nativeCurrencyAmountHex?: string
  txHash?: string
  receipt?: SerializableContractReceipt
  error?: string
}

const initialState: StakingState = {}

const stakingSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    clearStaking: () => initialState,
    onStake: (
      state,
      { payload }: PayloadAction<{ nativeCurrencyAmountHex: string }>
    ) => {
      state.nativeCurrencyAmountHex = payload.nativeCurrencyAmountHex
    },
    setStakingError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.error = payload.error
    },
    setStakingTxHash: (
      state,
      { payload }: PayloadAction<{ txHash: string }>
    ) => {
      state.txHash = payload.txHash
    },
    setStakingReceipt: (
      state,
      { payload }: PayloadAction<{ receipt: SerializableContractReceipt }>
    ) => {
      state.receipt = payload.receipt
    },
  },
})

export const {
  clearStaking,
  setStakingTxHash,
  onStake,
  setStakingError,
  setStakingReceipt,
} = stakingSlice.actions
export default stakingSlice.reducer
