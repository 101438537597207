// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/predeposit.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { PreDepositRatesRequest, PreDepositRatesResponse, PreDepositStatsRequest, PreDepositStatsResponse, PreDepositSummaryRequest, PreDepositSummaryResponse, PreDepositVoyageUserRequest, PreDepositVoyageUserResponse, TokenListRequest, TokenListResponse } from "./predeposit_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.PreDepositService
 */
export const PreDepositService = {
  typeName: "swell.v3.PreDepositService",
  methods: {
    /**
     * @generated from rpc swell.v3.PreDepositService.Stats
     */
    stats: {
      name: "Stats",
      I: PreDepositStatsRequest,
      O: PreDepositStatsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.PreDepositService.VoyageUser
     */
    voyageUser: {
      name: "VoyageUser",
      I: PreDepositVoyageUserRequest,
      O: PreDepositVoyageUserResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.PreDepositService.TokenList
     */
    tokenList: {
      name: "TokenList",
      I: TokenListRequest,
      O: TokenListResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.PreDepositService.RatesUsd
     */
    ratesUsd: {
      name: "RatesUsd",
      I: PreDepositRatesRequest,
      O: PreDepositRatesResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.PreDepositService.Summary
     */
    summary: {
      name: "Summary",
      I: PreDepositSummaryRequest,
      O: PreDepositSummaryResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

