import { useMemo } from 'react'
import { useConvertNativeCurrencyToRswEth } from './useCurrencyConversions'
import { useDisplayNativeCurrency } from './useNativeCurrencyDisplay'
import { useRestakeLens } from './useRestakeLens'
import { useDisplayRswEth } from './useRswEthDisplay'
import { BigNumber } from 'ethers'
import { bigNumberRateToFloat } from '@/util/big'
import { parseEther } from 'ethers/lib/utils'

/**
 * Prepares the necessary display strings for the conversion that will occur during the staking workflow.
 * This is purely informational / read-only. The strings are formatted and include the token symbol.
 *
 * These strings are:
 * - swEthAmountDisplayStr     - The amount of swETH that will be received (e.g. "1.05 swETH")
 * - nativeCurrencyDisplayStr  - The amount of native currency that will be staked (e.g. "1.05 goETH")
 *
 * In the case where the information required to construct these strings is not present, the strings will be undefined.
 */
export const useRestakingDisplayStrings = (
  rswETHToETHRate: BigNumber | undefined
) => {
  const { nativeCurrencyAmount } = useRestakeLens()

  const displayRswEth = useDisplayRswEth()
  const displayNativeCurrency = useDisplayNativeCurrency()
  const convertNativeCurrencyToRswEth = useConvertNativeCurrencyToRswEth()

  let nativeToRswEthRate: number | undefined = undefined
  if (rswETHToETHRate) {
    nativeToRswEthRate = bigNumberRateToFloat(
      parseEther('1').mul(parseEther('1')).div(rswETHToETHRate)
    )
  }

  return useMemo(() => {
    if (!nativeCurrencyAmount || !nativeToRswEthRate) {
      return {
        rswEthAmountDisplayStr: undefined,
        nativeCurrencyDisplayStr: undefined,
      }
    }

    const rswEthAmount = convertNativeCurrencyToRswEth(
      nativeCurrencyAmount,
      nativeToRswEthRate
    )

    const rswEthAmountDisplayStr = displayRswEth(rswEthAmount)
    const nativeCurrencyDisplayStr = displayNativeCurrency(nativeCurrencyAmount)

    return {
      rswEthAmountDisplayStr,
      nativeCurrencyDisplayStr,
    }
  }, [
    convertNativeCurrencyToRswEth,
    displayNativeCurrency,
    displayRswEth,
    nativeCurrencyAmount,
    nativeToRswEthRate,
  ])
}
