import React from 'react'

function WarningCircleIcon(props: any) {
  return (
    <svg
      {...props}
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34 0.6875C27.4114 0.6875 20.9708 2.64124 15.4926 6.30167C10.0144 9.96209 5.74462 15.1648 3.22328 21.2519C0.701934 27.3389 0.0422351 34.037 1.32761 40.4989C2.61298 46.9609 5.78568 52.8967 10.4445 57.5555C15.1034 62.2143 21.0391 65.387 27.5011 66.6724C33.9631 67.9578 40.6611 67.2981 46.7482 64.7767C52.8352 62.2554 58.0379 57.9856 61.6983 52.5074C65.3588 47.0292 67.3125 40.5886 67.3125 34C67.3032 25.1678 63.7905 16.7001 57.5452 10.4548C51.2999 4.20952 42.8322 0.696827 34 0.6875ZM34 62.1875C28.4251 62.1875 22.9753 60.5343 18.3399 57.437C13.7045 54.3398 10.0916 49.9375 7.95816 44.7869C5.82472 39.6363 5.26651 33.9687 6.35413 28.5009C7.44175 23.0331 10.1264 18.0105 14.0684 14.0684C18.0105 10.1263 23.0331 7.44174 28.5009 6.35412C33.9687 5.26649 39.6363 5.8247 44.7869 7.95815C49.9375 10.0916 54.3398 13.7045 57.4371 18.3399C60.5343 22.9753 62.1875 28.425 62.1875 34C62.179 41.4732 59.2066 48.6379 53.9222 53.9222C48.6379 59.2066 41.4732 62.179 34 62.1875ZM31.4375 36.5625V18.625C31.4375 17.9454 31.7075 17.2936 32.1881 16.813C32.6686 16.3325 33.3204 16.0625 34 16.0625C34.6796 16.0625 35.3314 16.3325 35.812 16.813C36.2925 17.2936 36.5625 17.9454 36.5625 18.625V36.5625C36.5625 37.2421 36.2925 37.8939 35.812 38.3745C35.3314 38.855 34.6796 39.125 34 39.125C33.3204 39.125 32.6686 38.855 32.1881 38.3745C31.7075 37.8939 31.4375 37.2421 31.4375 36.5625ZM37.8438 48.0938C37.8438 48.854 37.6183 49.5971 37.196 50.2292C36.7736 50.8613 36.1733 51.354 35.471 51.6449C34.7686 51.9358 33.9958 52.012 33.2501 51.8636C32.5045 51.7153 31.8196 51.3492 31.2821 50.8117C30.7445 50.2741 30.3784 49.5892 30.2301 48.8436C30.0818 48.098 30.1579 47.3252 30.4489 46.6228C30.7398 45.9205 31.2324 45.3201 31.8645 44.8978C32.4966 44.4754 33.2398 44.25 34 44.25C35.0194 44.25 35.9971 44.655 36.718 45.3758C37.4388 46.0967 37.8438 47.0743 37.8438 48.0938Z"
        fill="#FFA114"
      />
    </svg>
  )
}

export { WarningCircleIcon }
