import { useMemo } from 'react'
import useSWRImmutable from 'swr/immutable'
import { useSwellWeb3 } from '@swell-web3/core'
import { EIP1559GasPriceProvider } from '@/services/GasPriceProvider/EIP1559GasPriceProvider'
import { GasPrice } from '@/services/GasPriceProvider/IGasPriceProvider'
import { useGetAllStatsV3Backend } from '@/services/V3BackendService/hooks'
import { parseWeiSafe } from '@/util/big'
import { useDeploymentSetConfig } from '../deployments/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useGasPriceOnChain() {
  const { provider, chainId } = useSwellWeb3()

  const gasPriceProvider = useMemo(() => {
    if (!provider) return null
    return new EIP1559GasPriceProvider(provider)
  }, [provider])

  return useSWRImmutable<GasPrice>(
    gasPriceProvider ? [chainId, 'gasPrice'] : null,
    () => {
      return gasPriceProvider!.getGasPrice()
    }
  )
}

function useGasPriceV3Backend() {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { data, ...query } = useGetAllStatsV3Backend(v3BackendLstUrl)

  return {
    ...query,
    get data(): GasPrice | undefined {
      if (!data) return undefined

      const gasPriceWei = parseWeiSafe(data.suggestedGasPriceWei)
      return { gasPriceWei }
    },
  }
}

// gas price is currently only sourced via the backend.
// Note that this gas price is only used for display purposes and does not
//  directly influence user's on-chain actions
// TODO: resiliency
export const useGasPrice = useGasPriceV3Backend

// TODO: better solution for determining whether a block updater is needed
export const GAS_PRICE_NEEDS_BLOCK_LISTENER =
  (useGasPrice as any) === useGasPriceOnChain
