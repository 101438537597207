import { TOKEN_LIST_ETH } from '@/constants/tokens'
import {
  useApproveRswETHForWithdrawal,
  useCreateWithdrawRequestRswETH,
  useFinalizeWithdrawalRswETH,
} from '@/state/rsweth/hooks'
import { ZapRoute } from '@/state/zap/types'
import { Token } from '@/types/tokens'
import { formatUnits } from 'ethers/lib/utils'
import { useEffect } from 'react'

export function useRswEthExitCalls() {
  const finalizeWithdrawal = useFinalizeWithdrawalRswETH()
  const createWithdrawRequest = useCreateWithdrawRequestRswETH()
  const approveRswETHForWithdrawal = useApproveRswETHForWithdrawal()

  return {
    finalizeWithdrawal,
    createWithdrawRequest,
    approveRswETHForWithdrawal,
  }
}
