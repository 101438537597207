import { ReactNode, useEffect } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { Container } from '@swell-ui/Container'
import { FlexRow } from '@swell-ui/FlexRow'
import { GlobalNotificationContainer } from '@swell-ui/GlobalNotification'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { TopNavBar } from '@/components/TopNavBar'
import { StakingPage } from '@/pages/StakingPage'
import { Restaking } from '@/pages/Restaking'
import { Earn } from '@/pages/Earn'
import { AllOperators } from '@/pages/AllOperators'
import { OperatorDashboard } from '@/pages/OperatorDashboard'
import { Portfolio } from '@/pages/Portfolio'
import { SwelL2 } from '@/pages/SwelL2'
import { ValidatorKeys } from '@/pages/ValidatorKeys'
import { Voyage } from '@/pages/Voyage'
import { AllOperators as AllRestakingOperators } from '@/pages/restaking-operators/AllOperators'
import { Dashboard as RestakingOperatorDashboard } from '@/pages/restaking-operators/Dashboard'
import { ValidatorKeys as RestakingValidatorKeys } from '@/pages/restaking-operators/ValidatorKeys'
import { Footer } from '@/components/Footer'
import { Web3NavWidget } from '@/components/Web3NavWidget'
import { Helmet } from 'react-helmet'
import { DepositSubmissionDetailsProvider } from './state/depositSubmission/hooks'
import { RestakingDepositSubmissionDetailsProvider } from './state/restakingDepositSubmission/hooks'
import { AppBanner } from './components/AppBanner'
import { PreDepositZapProvider } from './state/predeposit/zap/context'
import { useGeoRestricted } from './state/geofence/hooks'
import { ROUTES } from './constants/routes'
import { SwellCity } from './pages/SwellCity'
import { BtcLrtVault } from './pages/BtcLrt'
import { L2Background } from './components/Backgrounds/L2Background'
import { VoyageBgImg } from './components/Backgrounds/VoyageBgImg'
import { VoyageBackground } from './components/Backgrounds/VoyageBackground'
import { OceanBackground } from './components/Backgrounds/OceanBackground'
import { BtcLrtBackground } from './components/Backgrounds/BtcLrtBackground'
import { GeofencePermission, GeoFenceResult } from './types/geofence'
import { GeoRestrictedView } from './components/GeoRestrictedView'

const StyledGlobalNotificationContainer = styled(GlobalNotificationContainer)`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 100;
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const AppContainer = styled(Container)`
  padding-top: 120px;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 10;

  opacity: 0;
  animation: ${fadeIn} 0.65s ease-out forwards;
  animation-delay: 0;
`

const AppInner = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  flex: 1 1 auto;
  max-width: 1536px;
  padding-left: 36px;
  padding-right: 36px;
  margin: 0 auto;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      padding-left: 16px;
      padding-right: 16px;
    }
  `}
`

const InnerWallet = styled(FlexRow)`
  margin-bottom: 24px;
`

const BANNER_ROUTES: string[] = [
  // ROUTES.StakeRswETH,
  // ROUTES.StakeSwETH,
  // ROUTES.Layer2SwellL2,
  // ROUTES.EarnDefi,
  // ROUTES.EarnPortfolio,
]

function App() {
  const geoRestricted = useGeoRestricted()

  let hideNav: boolean
  let content: ReactNode
  let background = (
    <Routes>
      <Route path={ROUTES.Layer2SwellL2} element={<L2Background />} />
      <Route path={ROUTES.DaoVoyage} element={<VoyageBgImg />} />
      <Route path={ROUTES.DaoSwellCity} element={<VoyageBackground />} />
      <Route path={ROUTES.StakeSwBTC} element={<BtcLrtBackground />} />
      <Route path="*" element={<OceanBackground />} />
    </Routes>
  )

  if (geoRestricted.shouldRestrict) {
    content = (
      <ContentGeoRestricted
        result={geoRestricted.data}
        permission={geoRestricted.permission}
      />
    )
    background = <OceanBackground />
    hideNav = true
  } else if (geoRestricted.loading) {
    content = <ContentLoading />
    hideNav = true
  } else {
    content = <ContentLoaded />
    hideNav = false
  }

  return (
    <>
      <Helmet>
        <title>Swell: Liquid restaking for DeFi</title>
      </Helmet>
      <div style={{ zIndex: 11, position: 'relative', height: 0 }}>
        <TopNavBar onlyLogo={hideNav} />
      </div>
      {content}
      {background}
    </>
  )
}

export default App

function ContentGeoRestricted({
  result,
  permission,
}: {
  result: GeoFenceResult | undefined
  permission: GeofencePermission | undefined
}) {
  const navigate = useNavigate()

  // reason for navigating: restake page has hardcoded global CSS which does not look good with this content
  useEffect(() => {
    if (permission === 'blocked') {
      navigate('/restricted')
    }
  }, [navigate, permission])

  return (
    <AppContainer>
      <GeoRestrictedView
        result={result}
        permission={permission}
        returnLinkBlockedAirdrops={ROUTES.Layer2SwellL2}
      />
    </AppContainer>
  )
}

function ContentLoading() {
  return <></>
}

function ContentLoaded() {
  const is740Up = useMediaQuery('(min-width:740px)')

  return (
    <AppContainer maxWidth="xl">
      <StyledGlobalNotificationContainer />
      <AppInner>
        {!is740Up && (
          <InnerWallet justify="right">
            <Web3NavWidget />
          </InnerWallet>
        )}
        <Routes>
          {BANNER_ROUTES.map((route: string) => {
            return <Route key={route} path={route} element={<AppBanner />} />
          })}
        </Routes>
        <Routes>
          <Route index element={<Navigate to={ROUTES.StakeRswETH} />} />
          <Route path={ROUTES.StakeRswETH} element={<Restaking />} />
          <Route path={ROUTES.StakeSwETH} element={<StakingPage />} />
          <Route path={ROUTES.StakeSwBTC} element={<BtcLrtVault />} />
          <Route
            path={ROUTES.Layer2SwellL2}
            element={
              <PreDepositZapProvider>
                <SwelL2 />
              </PreDepositZapProvider>
            }
          />
          <Route path={ROUTES.EarnDefi} element={<Earn />} />
          <Route path={ROUTES.EarnPortfolio} element={<Portfolio />} />
          <Route path={ROUTES.DaoVoyage} element={<Voyage />} />
          <Route path={ROUTES.DaoSwellCity} element={<SwellCity />} />
          <Route path="operators">
            <Route index element={<Navigate to="/operators/dashboard" />} />
            <Route path="dashboard" element={<OperatorDashboard />} />
            <Route path="all-operators" element={<AllOperators />} />
            <Route
              path="validator-keys"
              element={
                <DepositSubmissionDetailsProvider>
                  <ValidatorKeys />
                </DepositSubmissionDetailsProvider>
              }
            />
            <Route path="*" element={<Navigate to="/operators/dashboard" />} />
          </Route>
          <Route path="restaking-operators">
            <Route
              index
              element={<Navigate to="/restaking-operators/dashboard" />}
            />
            <Route path="dashboard" element={<RestakingOperatorDashboard />} />
            <Route path="all-operators" element={<AllRestakingOperators />} />
            <Route
              path="validator-keys"
              element={
                <RestakingDepositSubmissionDetailsProvider>
                  <RestakingValidatorKeys />
                </RestakingDepositSubmissionDetailsProvider>
              }
            />
          </Route>
          <Route path="/stake" element={<Navigate to={ROUTES.StakeSwETH} />} />
          <Route
            path="/restake"
            element={<Navigate to={ROUTES.StakeRswETH} />}
          />
          <Route path="/vault" element={<Navigate to={ROUTES.StakeSwBTC} />} />
          <Route path="/voyage" element={<Navigate to={ROUTES.DaoVoyage} />} />
          <Route path="/earn" element={<Navigate to={ROUTES.EarnDefi} />} />
          <Route
            path="/portfolio"
            element={<Navigate to={ROUTES.EarnPortfolio} />}
          />
          <Route
            path="/swell-l2"
            element={<Navigate to={ROUTES.Layer2SwellL2} />}
          />
          <Route path="*" element={<Navigate to={ROUTES.StakeRswETH} />} />
        </Routes>
      </AppInner>
      <Routes>
        <Route path="voyage" element={<></>} />
        <Route path="*" element={<Footer />} />
      </Routes>
    </AppContainer>
  )
}
