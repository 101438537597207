import { ClaimEigenStakedrop } from '@/state/rsweth/hooks'
import { EigenLayerStakedropResult } from '@/state/rsweth/types'
import { MerkleDropState } from '@/types/merkle'
import { merkleClaimable } from '@/util/merkledrop'
import { BigNumber } from 'ethers'

export const EigenStakedropErrors = {
  NoEigenStakedropData: 'No stakedrop data',
  InvalidMerkleRoot: 'Invalid merkle root',
  InvalidMerkleProof: 'Invalid merkle proof',
  ClaimingNotOpen: 'Claiming is not open',
  NothingToClaim: 'Nothing to claim',
  AlreadyClaimed: 'Already claimed',
}

type ValidatedArgs<T> =
  | { args?: undefined; error: string | null }
  | { args: T; error?: undefined }

export function prepareClaimEigenStakedrop({
  eigenStakedropResult,
  merkleState,
}: {
  eigenStakedropResult: EigenLayerStakedropResult | undefined
  merkleState: MerkleDropState | undefined
}): ValidatedArgs<Parameters<ClaimEigenStakedrop['call']>> {
  if (!eigenStakedropResult || !merkleState) {
    return { error: null }
  }

  if (
    !eigenStakedropResult.exists ||
    eigenStakedropResult.data.totalAmount.eq(0)
  ) {
    return { error: EigenStakedropErrors.NoEigenStakedropData }
  }

  const eigenStakedropData = eigenStakedropResult.data

  if (
    eigenStakedropData.cumulativeAmount.eq(
      eigenStakedropResult.cumulativeClaimed
    )
  ) {
    return { error: EigenStakedropErrors.AlreadyClaimed }
  }

  const { claimableAmount } = merkleClaimable({
    cumulativeAmount: eigenStakedropData.cumulativeAmount,
    cumulativeClaimed: eigenStakedropResult.cumulativeClaimed,
    totalAmount: eigenStakedropData.totalAmount,
  })
  if (claimableAmount.eq(0)) {
    return { error: EigenStakedropErrors.NothingToClaim }
  }

  if (!merkleState.claimIsOpen) {
    return { error: EigenStakedropErrors.ClaimingNotOpen }
  }
  if (eigenStakedropData.merkleRoot !== merkleState.merkleRoot) {
    return { error: EigenStakedropErrors.InvalidMerkleRoot }
  }
  if (!eigenStakedropData.merkleProof.length) {
    return { error: EigenStakedropErrors.InvalidMerkleProof }
  }

  const amountToLock = BigNumber.from(0)

  return {
    args: [
      {
        amountToLock,
        cumulativeAmount: eigenStakedropData.cumulativeAmount,
        merkleProof: eigenStakedropData.merkleProof,
      },
    ],
  }
}
export type PreparedClaimEigenStakedrop = ReturnType<
  typeof prepareClaimEigenStakedrop
>
