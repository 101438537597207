import React from 'react'
import { Grid } from '@swell-ui/Grid'
import { ExchangeRateChartWidget } from '@/components/ExchangeRateChartWidget'
import { PageTitle } from '@/components/PageTitle'
import { SwEthHoldings } from '@/components/SwEthHoldings'
import { StakerTransactionHistory } from '@/components/StakerTransactionHistory'

const TITLE = 'Track your holdings'
const SUBTITLE = 'Monitor your balances and staking rewards.'

export function Portfolio() {
  return (
    <>
      <PageTitle title={TITLE} subtitle={SUBTITLE} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <SwEthHoldings />
        </Grid>
        <Grid item xs={12} md={7}>
          <ExchangeRateChartWidget />
        </Grid>
        <Grid item xs={12} lg={12}>
          <StakerTransactionHistory />
        </Grid>
      </Grid>
    </>
  )
}
