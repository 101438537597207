import useSWR from 'swr'
import axios from 'axios'
// import { swapInfoAdapter } from './adapters'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'

interface RoutesOptions {
  slippage: string
  maxPriceImpact: string
  integrator?: string
  exchanges?: string[]
}

interface RoutesRequestParams {
  fromChainId: number
  fromAmount: string
  fromTokenAddress: string
  toChainId: number
  toTokenAddress: string
  options: RoutesOptions
}

interface PopulateParams {
  stepRaw: string // base64 encoded string of a step
  forAddress?: string
}

// constants
// const ROUTES_URL = 'https://v3-lrt.svc.swellnetwork.io/swell.v3.LifiService/Routes'
// const POP_URL = 'https://v3-lrt.svc.swellnetwork.io/swell.v3.LifiService/PopulateTransaction'

// mainnet exchanges only
const ALLOWED_EXCHANGES = ['enso']
const INTEGRATOR = 'Swell'

let controller: AbortController

// We aren't using the LiFi SDK here so we can get have request cancellation functionality
const getRoutesFetcher = async ({
  url,
  params,
}: {
  url: string
  params: RoutesRequestParams
}) => {
  params.options.integrator = INTEGRATOR
  params.options.exchanges = ALLOWED_EXCHANGES

  if (controller) {
    controller.abort()
  }

  controller = new AbortController()
  const signal = controller.signal

  const result = await axios.post(url, params, { signal } as any)
  return result.data
}

export function useGetRoutesQuery(
  params: RoutesRequestParams,
  paused?: boolean
) {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const key = paused
    ? null
    : {
        url: `${v3BackendLrtUrl}/swell.v3.LifiService/Routes`,
        params,
      }

  const { data, ...query } = useSWR(key, getRoutesFetcher, {
    refreshInterval: 15000,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined

      return {
        ...data,
        steps: JSON.parse(atob(data.stepsRaw)),
      }
    },
  }
}

export function usePopulateTransactionData() {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const url = `${v3BackendLrtUrl}/swell.v3.LifiService/PopulateTransaction`
  return async (params: PopulateParams) => {
    try {
      const result = await axios.post(url, params)
      return result.data
    } catch (err) {
      console.error(err)
    }
  }
}
