import useSWRImmutable from 'swr/immutable'
import { useGeoFenceApi } from './context'
import { useState } from 'react'
import { GeofencePermission, GeoFenceResult } from '@/types/geofence'
import { useLocation } from 'react-router'
import { shouldGeoRestrict } from '@/util/geofence'

export function useIsGeoRestrictedQuery() {
  const [hitDeadline, setHitDeadline] = useState(false)
  const geoFenceApi = useGeoFenceApi()

  const query = useSWRImmutable(
    'isGeoRestricted',
    geoFenceApi.isGeoRestricted,
    {
      loadingTimeout: 800,
      shouldRetryOnError: true,
      onLoadingSlow: () => setHitDeadline(true),
    }
  )

  return { ...query, hitDeadline }
}

export type GeoRestricted = {
  shouldRestrict: boolean
  permission: GeofencePermission | undefined
  loading: boolean
  data?: GeoFenceResult
}
export function useGeoRestricted(): GeoRestricted {
  const pathname = useLocation().pathname

  const { data, error, hitDeadline } = useIsGeoRestrictedQuery()

  if (!data) {
    if (error) {
      return {
        permission: undefined,
        shouldRestrict: false,
        loading: false,
      }
    }

    if (hitDeadline) {
      return {
        permission: undefined,
        shouldRestrict: false,
        loading: false,
      }
    }

    return {
      permission: undefined,
      shouldRestrict: false,
      loading: true,
    }
  }

  const { permission, shouldRestrict } = shouldGeoRestrict(data, pathname)
  return {
    permission,
    shouldRestrict,
    loading: false,
    data,
  }
}
