import {
  DEPOSIT_AMOUNT_GWEI,
  getGenesisForkVersion,
} from '@/constants/deposits'
import { strip0x } from '@/util/hexStrings'
import { useMemo } from 'react'
import { useDeploymentSetConfig } from './useDeploymentSetConfig'
import { ChainDepositConfig } from '../types'

const WITHDRAWAL_PREFIX = '0x010000000000000000000000'

export const useStakingChainDepositConfig = (): ChainDepositConfig => {
  const {
    chainId,
    nodeOperators: { withdrawalAddress },
  } = useDeploymentSetConfig()

  return useMemo(() => {
    const withdrawalCredentials = `${WITHDRAWAL_PREFIX}${strip0x(
      withdrawalAddress
    )}`

    return {
      genesisForkVersion: getGenesisForkVersion(chainId),
      withdrawalCredentials,
      depositAmountGwei: DEPOSIT_AMOUNT_GWEI,
    }
  }, [chainId, withdrawalAddress])
}

export const useRestakingChainDepositConfig = () => {
  const { chainId } = useDeploymentSetConfig()

  return useMemo(() => {
    const cfg: ChainDepositConfig = {
      genesisForkVersion: getGenesisForkVersion(chainId),
      depositAmountGwei: DEPOSIT_AMOUNT_GWEI,
      withdrawalCredentials: null, // backend validated
    }

    return cfg
  }, [chainId])
}

export default useStakingChainDepositConfig
