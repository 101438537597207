import {
  YearnWithdrawRequest,
  YearnWithdrawRequestResult,
} from '@/state/yearnVault/types'
import { DurationBreakdown } from '@/util/datetime'

export const YearnWithdrawalStatus = {
  Requesting: 'Requesting' as const,
  Claimable: 'Claimable' as const,
  Expired: 'Expired' as const,
  NoActiveWithdrawal: 'NoActiveWithdrawal' as const,
}
export type YearnWithdrawalStatus =
  (typeof YearnWithdrawalStatus)[keyof typeof YearnWithdrawalStatus]

export type YearnActiveWithdrawalResult =
  | {
      status:
        | (typeof YearnWithdrawalStatus)['Requesting']
        | (typeof YearnWithdrawalStatus)['Claimable']
        | (typeof YearnWithdrawalStatus)['Expired']
      request: YearnWithdrawRequest
    }
  | {
      status: (typeof YearnWithdrawalStatus)['NoActiveWithdrawal']
      request?: undefined
    }

// exported for hooks, not intended to be consumed directly in components
// calculates UI state given info about user withdrawals
export function yearnActiveWithdrawalMemo({
  withdrawRequest,
  nowUnix,
  completionWindowSeconds,
}: {
  withdrawRequest: YearnWithdrawRequestResult
  nowUnix: number
  completionWindowSeconds: number
}): YearnActiveWithdrawalResult {
  if (!withdrawRequest.exists) {
    return {
      status: YearnWithdrawalStatus.NoActiveWithdrawal,
    }
  }

  const { request } = withdrawRequest

  if (request.shares.eq(0)) {
    return {
      status: YearnWithdrawalStatus.NoActiveWithdrawal,
    }
  }

  const requestMaturityInFuture = nowUnix < request.maturityUnix
  const requestExpiryInFuture =
    nowUnix <= request.maturityUnix + completionWindowSeconds

  const isRequesting = requestMaturityInFuture && requestExpiryInFuture
  const isClaimable = !requestMaturityInFuture && requestExpiryInFuture
  const isExpired = !requestMaturityInFuture && !requestExpiryInFuture

  if (isRequesting) {
    return {
      status: YearnWithdrawalStatus.Requesting,
      request: request,
    }
  }

  if (isClaimable) {
    return {
      status: YearnWithdrawalStatus.Claimable,
      request: request,
    }
  }

  if (isExpired) {
    return {
      status: YearnWithdrawalStatus.Expired,
      request: request,
    }
  }

  return {
    status: YearnWithdrawalStatus.NoActiveWithdrawal,
  }
}

export function yearnWithdrawCountdown({
  countdown,
  withdrawRequestDelayMs,
}: {
  countdown: DurationBreakdown
  withdrawRequestDelayMs: number
}) {
  if (withdrawRequestDelayMs <= 0) {
    return {
      countdownLeft: '',
      countdownMiddle: '',
      countdownRight: '',
    }
  }

  const oneHour = 60 * 60 * 1000
  const oneDay = oneHour * 24

  let countdownLeft: string
  let countdownMiddle: string
  let countdownRight: string

  const durationSeconds = Math.round(withdrawRequestDelayMs / 1000)
  const durationHours = Math.round(withdrawRequestDelayMs / oneHour)
  const durationDays = Math.round(withdrawRequestDelayMs / oneDay)

  if (withdrawRequestDelayMs >= oneDay || durationHours === 24) {
    countdownLeft = `0d`
    countdownMiddle = `${countdown.days}d ${countdown.hours}h ${countdown.minutes}m`
    countdownRight = `${durationDays}d`
  } else if (withdrawRequestDelayMs >= oneHour) {
    countdownLeft = `0h`
    countdownMiddle = `${countdown.hours}h ${countdown.minutes}m ${countdown.seconds}s`
    countdownRight = `${durationHours}h`
  } else {
    countdownLeft = `0s`
    countdownMiddle = `${countdown.minutes}m ${countdown.seconds}s`
    countdownRight = `${durationSeconds}s`
  }

  return {
    countdownLeft,
    countdownMiddle,
    countdownRight,
  }
}
