import React from 'react'

function ModalOpenIcon(props: any) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00028 6.5V10.5C6.00028 10.6326 5.9476 10.7598 5.85383 10.8536C5.76006 10.9473 5.63289 11 5.50028 11C5.36767 11 5.24049 10.9473 5.14672 10.8536C5.05296 10.7598 5.00028 10.6326 5.00028 10.5V7.7075L0.854028 11.8538C0.807573 11.9002 0.752423 11.9371 0.691726 11.9622C0.63103 11.9873 0.565975 12.0003 0.500278 12.0003C0.434581 12.0003 0.369526 11.9873 0.30883 11.9622C0.248133 11.9371 0.192983 11.9002 0.146528 11.8538C0.100073 11.8073 0.0632227 11.7521 0.0380814 11.6914C0.0129401 11.6308 0 11.5657 0 11.5C0 11.4343 0.0129401 11.3692 0.0380814 11.3086C0.0632227 11.2479 0.100073 11.1927 0.146528 11.1462L4.29278 7H1.50028C1.36767 7 1.24049 6.94732 1.14672 6.85355C1.05296 6.75979 1.00028 6.63261 1.00028 6.5C1.00028 6.36739 1.05296 6.24021 1.14672 6.14645C1.24049 6.05268 1.36767 6 1.50028 6H5.50028C5.63289 6 5.76006 6.05268 5.85383 6.14645C5.9476 6.24021 6.00028 6.36739 6.00028 6.5ZM11.0003 0H3.00028C2.73506 0 2.48071 0.105357 2.29317 0.292893C2.10563 0.48043 2.00028 0.734784 2.00028 1V4C2.00028 4.13261 2.05296 4.25979 2.14672 4.35355C2.24049 4.44732 2.36767 4.5 2.50028 4.5C2.63289 4.5 2.76006 4.44732 2.85383 4.35355C2.9476 4.25979 3.00028 4.13261 3.00028 4V1H11.0003V9H8.00028C7.86767 9 7.74049 9.05268 7.64672 9.14645C7.55296 9.24021 7.50028 9.36739 7.50028 9.5C7.50028 9.63261 7.55296 9.75979 7.64672 9.85355C7.74049 9.94732 7.86767 10 8.00028 10H11.0003C11.2655 10 11.5198 9.89464 11.7074 9.70711C11.8949 9.51957 12.0003 9.26522 12.0003 9V1C12.0003 0.734784 11.8949 0.48043 11.7074 0.292893C11.5198 0.105357 11.2655 0 11.0003 0Z"
        fill="#B0B0B0"
      />
    </svg>
  )
}

export { ModalOpenIcon }
