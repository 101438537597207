// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/eigenpoints.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.EigenPointsUserRequest
 */
export class EigenPointsUserRequest extends Message<EigenPointsUserRequest> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  constructor(data?: PartialMessage<EigenPointsUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EigenPointsUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EigenPointsUserRequest {
    return new EigenPointsUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EigenPointsUserRequest {
    return new EigenPointsUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EigenPointsUserRequest {
    return new EigenPointsUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EigenPointsUserRequest | PlainMessage<EigenPointsUserRequest> | undefined, b: EigenPointsUserRequest | PlainMessage<EigenPointsUserRequest> | undefined): boolean {
    return proto3.util.equals(EigenPointsUserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.EigenPointsUserResponse
 */
export class EigenPointsUserResponse extends Message<EigenPointsUserResponse> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: float points = 2;
   */
  points = 0;

  constructor(data?: PartialMessage<EigenPointsUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EigenPointsUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EigenPointsUserResponse {
    return new EigenPointsUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EigenPointsUserResponse {
    return new EigenPointsUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EigenPointsUserResponse {
    return new EigenPointsUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EigenPointsUserResponse | PlainMessage<EigenPointsUserResponse> | undefined, b: EigenPointsUserResponse | PlainMessage<EigenPointsUserResponse> | undefined): boolean {
    return proto3.util.equals(EigenPointsUserResponse, a, b);
  }
}

