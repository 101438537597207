import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextInput } from '../TextInput'

const StyledMuiDatePicker = styled(MuiDatePicker)`
  .MuiInputAdornment-root {
    display: none;
  }
`

function DatePicker(props: any) {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <StyledMuiDatePicker
      {...props}
      disableHighlightToday={true}
      disableFuture={true}
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      slots={{
        textField: TextInput,
      }}
      slotProps={{
        textField: {
          onKeyDown: (e: any) => {
            e.preventDefault()
          },
          onMouseDown: () => {
            setOpen(true)
          },
        },
      }}
      format="dd/MM/yyyy"
      views={['day']}
    />
  )
}

export { DatePicker }
