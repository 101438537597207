import { ArrowDownIcon } from '@swell-ui/icons/ArrowDownIcon'
import { ArrowUpIcon } from '@swell-ui/icons/ArrowUpIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { SORT_DIR } from '@swell-ui/Table/constants'

interface SortOptionProps {
  label: string
  direction: string
}

export function SortOption({ label, direction }: SortOptionProps) {
  const icon = () => {
    if (direction === SORT_DIR.DESC) {
      return <ArrowDownIcon />
    }

    return <ArrowUpIcon />
  }

  return (
    <FlexRow gap="4">
      <span>{label}</span>
      {icon()}
    </FlexRow>
  )
}
