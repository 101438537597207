import { createContext, useContext } from 'react'
import { RswETHApi } from './types'
import { Token } from '@/types/tokens'
import { ExitAssets } from '@/types/claims'
import { MerkleDrop } from '@/types/merkle'

const MISSING_PROVIDER = Symbol()

export type ExitToWithdrawAsset = Map<string, Token>
export type WithdrawAssetToExit = Map<string, string>

export type RswETHContext = RswETHApi & {
  rswETHToken: Token
  eigenToken: Token
  exitAssets: ExitAssets
  exitToWithdrawAsset: ExitToWithdrawAsset
  withdrawAssetToExit: WithdrawAssetToExit
  eigenStakedropMerkleDrop: MerkleDrop
}

export const RswETHContext = createContext<
  RswETHContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useRswETHApi() {
  const ctx = useContext(RswETHContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'RswETHContext hooks must be wrapped in a <RswETHContext.Provider>'
    )
  }
  return ctx
}
