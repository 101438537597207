import { useMemo } from 'react'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { FungibleToken } from '@/util/FungibleToken'

// TODO: update this to reflect the real rswETH!!!!
const RSWETH_DECIMALS = 18
const RSWETH_SYMBOL = 'rswETH'

export const useRswEthToken = () => {
  const { addresses, chainId } = useDeploymentSetConfig()

  return useMemo(() => {
    return new FungibleToken({
      address: addresses.rswETH,
      chainId,
      decimals: RSWETH_DECIMALS,
      // TODO: Temporary image for rswETH; replace with official URL later
      logoURL:
        'https://swellnetwork.io/wp-content/uploads/2022/07/cropped-site_icon-32x32.png',
      symbol: RSWETH_SYMBOL,
      name: 'Swell Staked ETH',
    })
  }, [addresses.rswETH, chainId])
}
