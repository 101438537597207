import React from 'react'
import { FlexRow } from '@/swell-ui/FlexRow'
import styled from 'styled-components'
import { DecoBox } from '@/components/DecoBox'
import { Typography } from '@/swell-ui/Typography'
import { CountdownBoxClock } from '@/components/CountdownFlipClock/CountdownBoxClock'
import { ClaimWavedropButton, ConnectWalletButton } from './buttons'
import blackPearlUrl from '@/assets/images/black-pearls-128x128.png'
import { TOKEN_LIST_SWELL } from '@/constants/tokens'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useSwellDaoWavedrop } from '@/state/dao/hooks'
import { useTimeCountdown } from '@/hooks/useTimeCountdown'
import { formatWithConfig } from '@/util/number'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { useSwellUiTheme } from '@/swell-ui/theme'
import { useMediaQuery } from '@mui/material'
import { DurationBreakdown } from '@/util/datetime'
import { Divider } from '@/swell-ui/Divider'
import { Tooltip } from '@/swell-ui/Tooltip'
import { InfoIcon } from '@/swell-ui/icons/InfoIcon'

const READ_MORE_HREF = 'https://www.swellnetwork.io/post/voyage-snapshot'
const BLACK_PEARLS_WIP_INFO_URL =
  'https://docs.swellnetwork.io/swell-dao/faqs/swell-city-black-pearl-integration'
const DISPLAY_MULTIPLIER = false // temporarily disabled until S1 voyage is finalized and multiplier is confirmed

const CURRENT_WAVE_NUMBER = 1
const NEXT_WAVEDROP_END = 1728352800 // Tuesday, 8 October 2024 02:00:00 UTC
const NEXT_WAVEDROP_DATE_FORMATTED = '8th Oct. 2024'

export function SwellCity() {
  const { theme } = useSwellUiTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { account } = useSwellWeb3()
  const wavedropQuery = useSwellDaoWavedrop()

  const wavedrop = wavedropQuery.data

  // global stats
  const nextWavedropEndMs = NEXT_WAVEDROP_END * 1000
  const currentWaveTitle = `Wave ${CURRENT_WAVE_NUMBER}: Ends ${NEXT_WAVEDROP_DATE_FORMATTED}`
  const countdown = useTimeCountdown(nextWavedropEndMs)
  const showCountdown = countdown !== null && !!currentWaveTitle

  // user values
  let blackPearlsStr = '-'
  let multiplierStr = ''
  const swellBalanceStr = '-' // N/A for now
  if (account) {
    // trigger loading appearance with falsy values
    blackPearlsStr = ''

    if (wavedrop) {
      blackPearlsStr = formatWithConfig(wavedrop.blackPearls, {
        localize: true,
        precision: 0,
      })
      if (DISPLAY_MULTIPLIER) {
        const multiplierNumStr = formatWithConfig(wavedrop.multiplier, {
          localize: true,
          precision: 1,
        })
        multiplierStr = `${multiplierNumStr}x`
      }
    }
  }

  // Black Pearls (1x boost)
  let blackPearlsLabelStr = 'Black Pearls'
  if (multiplierStr) {
    blackPearlsLabelStr += ` (${multiplierStr} boost)`
  }

  let widget = (
    <WidgetMobile
      showCountdown={showCountdown}
      countdown={countdown}
      blackPearlsStr={blackPearlsStr}
      swellBalanceStr={swellBalanceStr}
      account={account}
      blackPearlsLabelStr={blackPearlsLabelStr}
      currentWaveTitle={currentWaveTitle}
    />
  )
  if (isMdUp) {
    widget = (
      <WidgetDesktop
        showCountdown={showCountdown}
        countdown={countdown}
        blackPearlsStr={blackPearlsStr}
        swellBalanceStr={swellBalanceStr}
        account={account}
        blackPearlsLabelStr={blackPearlsLabelStr}
        currentWaveTitle={currentWaveTitle}
      />
    )
  }

  return (
    <Layout
      align="center"
      direction="column"
      maxWidth={isMdUp ? '912px' : '433px'}
      gap="24"
    >
      <Typography
        variant="headline"
        xs="h3"
        md="h2"
        size="h2"
        fstyle="bold"
        letterSpacing="small"
      >
        Swell City
      </Typography>
      <span className="sub">🏄🏼 Ride the waves to boost your Wavedrops</span>
      <DecoBox>
        <p>
          2% of $SWELL will be Wavedropped, snapshot to be taken on Oct 8.{' '}
          <a href={READ_MORE_HREF} target="_blank" rel="noopener noreferrer">
            Read more...
          </a>
        </p>
      </DecoBox>
      {widget}
    </Layout>
  )
}

function BlackPearlsWIPTooltip() {
  return (
    <Tooltip
      title={
        <>
          Not all positions earning Black Pearls are being displayed yet, please{' '}
          <a
            href={BLACK_PEARLS_WIP_INFO_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            check here
          </a>
          .
        </>
      }
      placement="top"
      offset={[0, -10]}
      arrow
    >
      <InfoIcon />
    </Tooltip>
  )
}

function WidgetDesktop({
  account,
  blackPearlsLabelStr,
  blackPearlsStr,
  countdown,
  showCountdown,
  swellBalanceStr,
  currentWaveTitle,
}: {
  showCountdown: boolean
  countdown: DurationBreakdown | undefined
  blackPearlsStr: string
  swellBalanceStr: string
  account: string | undefined
  blackPearlsLabelStr: string
  currentWaveTitle: string
}) {
  return (
    <DecoBox padding="42px !important" direction="row" gap="48">
      <FlexRow
        direction="column"
        gap="24"
        justify="center"
        align="start"
        width="433px"
        style={{
          alignSelf: 'stretch',
          visibility: showCountdown ? 'visible' : 'hidden',
          flexShrink: 0,
        }}
      >
        <span className="swc-label-1">{currentWaveTitle}</span>
        {countdown && <CountdownBoxClock countdown={countdown} />}
      </FlexRow>
      <VDivider />
      <FlexRow
        direction="column"
        justify="space-between"
        align="center"
        gap="12"
      >
        <span className="swc-label-colored info-icon">
          {blackPearlsLabelStr} <BlackPearlsWIPTooltip />
        </span>
        <FlexRow gap="12" width="auto">
          <img src={blackPearlUrl} width={32} height={32} />
          <AsyncDiv loading={!blackPearlsStr}>
            {() => <span className="swc-med-value">{blackPearlsStr}</span>}
          </AsyncDiv>
        </FlexRow>
        <span className="swc-label-colored">
          Wavedrop {CURRENT_WAVE_NUMBER}
        </span>
        <FlexRow gap="12" width="auto">
          <img src={TOKEN_LIST_SWELL.logoURI} width={32} height={32} />
          <AsyncDiv loading={!swellBalanceStr}>
            {() => <span className="swc-med-value">{swellBalanceStr}</span>}
          </AsyncDiv>
        </FlexRow>
        <FlexRow width="100%" padding="12px 0 0">
          {account ? <ClaimWavedropButton /> : <ConnectWalletButton />}
        </FlexRow>
      </FlexRow>
    </DecoBox>
  )
}

function WidgetMobile({
  account,
  blackPearlsLabelStr,
  blackPearlsStr,
  countdown,
  showCountdown,
  swellBalanceStr,
  currentWaveTitle,
}: {
  showCountdown: boolean
  countdown: DurationBreakdown | undefined
  blackPearlsStr: string
  swellBalanceStr: string
  account: string | undefined
  blackPearlsLabelStr: string
  currentWaveTitle: string
}) {
  return (
    <DecoBox gap="24">
      <FlexRow
        direction="column"
        style={{
          visibility: showCountdown ? 'visible' : 'hidden',
        }}
        gap="24"
      >
        <span className="swc-label-1">{currentWaveTitle}</span>
        {countdown && <CountdownBoxClock countdown={countdown} compact />}
      </FlexRow>
      <Divider />
      <FlexRow
        direction="column"
        justify="space-between"
        align="center"
        gap="12"
      >
        <span className="swc-label-colored info-icon">
          {blackPearlsLabelStr} <BlackPearlsWIPTooltip />
        </span>
        <FlexRow gap="12" width="auto">
          <img src={blackPearlUrl} width={32} height={32} />
          <AsyncDiv loading={!blackPearlsStr}>
            {() => <span className="swc-med-value">{blackPearlsStr}</span>}
          </AsyncDiv>
        </FlexRow>
        <span className="swc-label-colored">
          Wavedrop {CURRENT_WAVE_NUMBER}
        </span>
        <FlexRow gap="12" width="auto">
          <img src={TOKEN_LIST_SWELL.logoURI} width={32} height={32} />
          <AsyncDiv loading={!swellBalanceStr}>
            {() => <span className="swc-med-value">{swellBalanceStr}</span>}
          </AsyncDiv>
        </FlexRow>
        <FlexRow width="100%" padding="12px 0 0">
          {account ? <ClaimWavedropButton /> : <ConnectWalletButton />}
        </FlexRow>
      </FlexRow>
    </DecoBox>
  )
}

const VDivider = styled.div`
  width: 1px;
  align-self: stretch;
  background: rgba(255, 255, 255, 0.2);
`

const Layout = styled(FlexRow)`
  /* display: flex; */
  margin: 0 auto;
  padding-top: 60px;

  > .sub {
    color: var(--white-125, #b0b0b0);
    text-align: center;
    /* Body/large */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 38.4px */
    letter-spacing: -0.72px;
  }

  .swc-label-1 {
    color: var(--white-50, #fff);
    text-align: center;
    /* Body/large */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 38.4px */
    letter-spacing: -0.72px;
  }

  .swc-label-colored {
    color: var(--lightblue-50, #a4abf1);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 13.2px */
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  .info-icon {
    svg {
      position: relative;
      top: 2px;

      path {
        stroke: currentColor;
      }
    }
  }

  .swc-med-value {
    color: var(--white-50, #fff);
    text-align: center;
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  }

  // Make loader container shrink to fit content when not loading
  *[aria-busy='false'] {
    min-width: unset;
  }
`
