import React from 'react'

function DownCaratIcon(props: any) {
  return (
    <svg
      {...props}
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0L4 4L8 0H0Z" fill="#B0B0B0" />
    </svg>
  )
}

export { DownCaratIcon }
