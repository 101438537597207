export const ROUTES = {
  StakeSwETH: '/stake/sweth',
  StakeRswETH: '/stake/rsweth',
  StakeSwBTC: '/stake/swbtc',
  Layer2SwellL2: '/layer2/swell-l2',
  DaoVoyage: '/dao/voyage',
  DaoSwellCity: '/dao/swell-city',
  EarnDefi: '/earn/defi',
  EarnPortfolio: '/earn/portfolio',
}
