import { DepositInfo } from '@/types/deposits'
import { SerializableContractReceipt } from '@/util/transactionSerialization'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface DepositSubmissionState {
  chunks?: DepositInfo[][]

  transactionMap: Partial<Record<number, { txHash: string }>>
  receiptMap: Partial<Record<number, SerializableContractReceipt>>
  promptMap: Record<number, boolean>
  errorMap: Partial<Record<number, string>>

  // timestamps to determine whether submission has started/finished
  submissionStartTimestamp?: number
  submissionEndTimestamp?: number

  // timestamp to determine whether the user has seen the results of
  //  the finished (or unfinished but historic) submission
  viewedFinalSubmissionTimestamp?: number
}

const initialState: DepositSubmissionState = {
  errorMap: {},
  promptMap: {},
  receiptMap: {},
  transactionMap: {},
}

const depositSubmissionSlice = createSlice({
  name: 'depositSubmission',
  initialState,
  reducers: {
    clearSubmissionState: () => {
      return initialState
    },
    onStartedSubmission: (
      state,
      { payload }: PayloadAction<DepositInfo[][]>
    ) => {
      state.chunks = payload
      state.submissionStartTimestamp = Date.now()
    },
    onFinishedSubmission: (state) => {
      state.submissionEndTimestamp = Date.now()
    },
    onViewedFinalSubmission: (state) => {
      state.viewedFinalSubmissionTimestamp = Date.now()
    },
    onTransactionCreated: (
      state,
      {
        payload: { chunkIdx, transaction },
      }: PayloadAction<{
        chunkIdx: number
        transaction: { txHash: string }
      }>
    ) => {
      state.transactionMap[chunkIdx] = transaction
    },
    onTransactionReceipt: (
      state,
      {
        payload: { chunkIdx, receipt },
      }: PayloadAction<{
        chunkIdx: number
        receipt: SerializableContractReceipt
      }>
    ) => {
      state.receiptMap[chunkIdx] = receipt
    },
    onWalletPrompt: (
      state,
      { payload: { chunkIdx } }: PayloadAction<{ chunkIdx: number }>
    ) => {
      state.promptMap[chunkIdx] = true
    },
    onWalletInteraction: (
      state,
      { payload: { chunkIdx } }: PayloadAction<{ chunkIdx: number }>
    ) => {
      state.promptMap[chunkIdx] = false
    },
    onChunkError: (
      state,
      {
        payload: { chunkIdx, message },
      }: PayloadAction<{ chunkIdx: number; message: string }>
    ) => {
      state.errorMap[chunkIdx] = message
    },
    clearChunkError: (
      state,
      { payload: { chunkIdx } }: PayloadAction<{ chunkIdx: number }>
    ) => {
      state.errorMap[chunkIdx] = undefined
    },
  },
})

export const {
  onTransactionCreated,
  onTransactionReceipt,
  clearChunkError,
  onChunkError,
  onWalletInteraction,
  onWalletPrompt,
  clearSubmissionState,
  onFinishedSubmission,
  onStartedSubmission,
  onViewedFinalSubmission,
} = depositSubmissionSlice.actions
export default depositSubmissionSlice.reducer
