import { useMemo } from 'react'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { FungibleToken } from '@/util/FungibleToken'

const SWETH_DECIMALS = 18
const SWETH_SYMBOL = 'swETH'

export const useSwEthToken = () => {
  const { addresses, chainId } = useDeploymentSetConfig()

  return useMemo(() => {
    return new FungibleToken({
      address: addresses.swETH,
      chainId,
      decimals: SWETH_DECIMALS,
      // TODO: Temporary image for swETH; replace with official URL later
      logoURL:
        'https://swellnetwork.io/wp-content/uploads/2022/07/cropped-site_icon-32x32.png',
      symbol: SWETH_SYMBOL,
      name: 'Swell Staked ETH',
    })
  }, [addresses.swETH, chainId])
}
