import React from 'react'

function CaretRightIcon(props: any) {
  return (
    <svg
      {...props}
      width="5"
      height="10"
      viewBox="0 0 5 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.51552 5.26552L0.765521 9.01552C0.73068 9.05036 0.689317 9.078 0.643795 9.09686C0.598272 9.11571 0.549482 9.12542 0.500208 9.12542C0.450935 9.12542 0.402145 9.11571 0.356622 9.09686C0.3111 9.078 0.269737 9.05036 0.234896 9.01552C0.200054 8.98068 0.172417 8.93932 0.153561 8.8938C0.134705 8.84827 0.125 8.79948 0.125 8.75021C0.125 8.70094 0.134705 8.65214 0.153561 8.60662C0.172417 8.5611 0.200054 8.51974 0.234896 8.4849L3.72005 5.00021L0.234896 1.51552C0.164531 1.44516 0.125 1.34972 0.125 1.25021C0.125 1.1507 0.164531 1.05526 0.234896 0.984896C0.305261 0.914531 0.400697 0.875 0.500208 0.875C0.59972 0.875 0.695156 0.914531 0.765521 0.984896L4.51552 4.7349C4.55039 4.76972 4.57805 4.81108 4.59692 4.85661C4.61579 4.90213 4.6255 4.95093 4.6255 5.00021C4.6255 5.04949 4.61579 5.09829 4.59692 5.14381C4.57805 5.18934 4.55039 5.23069 4.51552 5.26552Z"
        fill="white"
      />
    </svg>
  )
}

export { CaretRightIcon }
