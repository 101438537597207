// originally based on https://github.com/ethereum/staking-launchpad/blob/2971ba5d36a8e8449f3ca74936d14fcd80e63278/src/pages/UploadValidator/validateDepositKey.ts
import { ChainDepositConfig } from '@/state/deployments/types'
import { composeVerifiers } from './composeVerifiers'
import {
  depositAmountVerifier,
  forkVersionVerifier,
  stringLengthVerifier,
  withdrawalCredentialsVerifier,
} from '../verifier'
import { blsVerifier } from '../verifier/blsVerifier'
import isPlainObject from 'lodash/isPlainObject'
import { BLSVerifierId } from '../verifier/blsVerifier/util/validateBLS'
import { DepositInfo, DepositInfoSchema } from '@/types/deposits'
import { DepositProblem } from '@/state/depositValidation/models/DepositProblem'

enum VerifierId {
  VERIFY_STRING_LENGTH = 'verify-string-length',
  VERIFY_AMOUNT = 'verify-amount',
  VERIFY_FORK_VERSION = 'verify-fork-version',
  VERIFY_WITHDRAWAL_CREDENTIALS = 'verify-withdrawal-credentials',
  VERIFY_BLS = 'verify-bls',
}

const verifier = composeVerifiers({
  // Validating a DepositInfo involves the following validation tasks in serial
  verifiers: [
    {
      id: VerifierId.VERIFY_STRING_LENGTH,
      verifier: stringLengthVerifier,
    },
    {
      id: VerifierId.VERIFY_AMOUNT,
      verifier: depositAmountVerifier,
    },
    {
      id: VerifierId.VERIFY_FORK_VERSION,
      verifier: forkVersionVerifier,
    },
    {
      id: VerifierId.VERIFY_WITHDRAWAL_CREDENTIALS,
      verifier: withdrawalCredentialsVerifier,
    },
    {
      id: VerifierId.VERIFY_BLS,
      verifier: blsVerifier,
    },
  ],
})

export const validateDepositInfo = async (
  _deposit: unknown,
  chainDepositConfig: ChainDepositConfig,
  opts: { maxJobTimeMillis: number } = { maxJobTimeMillis: 4000 }
): Promise<DepositProblem | null> => {
  const parseResult = DepositInfoSchema.safeParse(_deposit)

  if (!parseResult.success)
    return { code: 'schema-fail', meta: parseResult.error.errors[0] }

  const { data: deposit } = parseResult

  let failTimeout: NodeJS.Timeout

  const timeoutPromise = new Promise<DepositProblem | null>((resolve) => {
    failTimeout = setTimeout(() => {
      console.error('validateDepositInfo timed out')
      resolve({ code: 'something-went-wrong' })
    }, opts.maxJobTimeMillis)
  })

  return Promise.race([
    timeoutPromise,
    verifier
      .verify(deposit as DepositInfo, chainDepositConfig)
      .then((failure): DepositProblem | null => {
        if (!failure) {
          return null
        }

        const id = failure.id
        const meta = failure.meta as any

        switch (id) {
          case VerifierId.VERIFY_STRING_LENGTH:
            return { code: 'invalid-string-length', meta }
          case VerifierId.VERIFY_AMOUNT:
            return { code: 'invalid-amount' }
          case VerifierId.VERIFY_FORK_VERSION:
            return { code: 'incorrect-fork-version' }
          case VerifierId.VERIFY_WITHDRAWAL_CREDENTIALS:
            return { code: 'incorrect-withdrawal-credentials' }
          case VerifierId.VERIFY_BLS: {
            // we expect to receive a failure object containing the id of the BLS verification
            //  step that failed. If that is not the case, there was an exception returning failure: true
            if (!isPlainObject(meta)) return { code: 'something-went-wrong' }

            switch ((meta as any).id) {
              case BLSVerifierId.VERIFY_SIGNATURE:
                return { code: 'signature-fail' }
              case BLSVerifierId.VERIFY_DEPOSIT_ROOTS:
                return { code: 'deposit-roots-fail' }
              default:
                throw new Error(`Invalid BLS failure id: ${(meta as any).id}`)
            }
          }

          default:
            throw new Error(`Invalid failure id: ${id}`)
        }
      }),
  ]).finally(() => failTimeout && clearTimeout(failTimeout))
}
