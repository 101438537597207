import { v4 as uuid } from 'uuid'
import { IDepositInfoVerifier } from '../IDepositInfoVerifier'
import { BLSValidationWorker } from './worker/BLSValidationWorkerWeb'
import {
  BLSVerifierWorkerRequest,
  BLSVerifierWorkerResponse,
} from './worker/workerInterface'

const worker = BLSValidationWorker.create()

/**
 * Verifies BLS signatures
 *
 * Communicates with a worker to do so. The worker will verify the deposit root and the signature
 *  as a single task.
 */
export const blsVerifier: IDepositInfoVerifier = {
  verify: (deposit, chainDepositConfig) => {
    const taskId = uuid()

    return new Promise((resolve) => {
      worker.onmessage = (msg) => {
        const data = msg.data as BLSVerifierWorkerResponse
        if (data.taskId !== taskId) return
        resolve(data.failure)
      }

      worker.postMessage({
        deposit,
        taskId,
        chainDepositConfig,
      } as BLSVerifierWorkerRequest)
    })
  },
}
