import React from 'react'
import styled from 'styled-components/macro'

const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;

  padding: 12px;
  border-radius: 8px;
  background: rgba(12, 8, 39, 0.8);

  > * + * {
    margin-top: 12px;
  }
`

const Frame = styled.div`
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid #845aff;

  ul {
    margin: 0;
    padding-inline-start: 21px;
  }

  color: var(--white-100, #e7e7e7);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`

export function Tldr() {
  return (
    <Layout>
      <Frame>
        <span>Depositors get:</span>
        <ul>
          <li>Bonus Swell L2 mainnet launch airdrop</li>
          <li>Bonus Swell L2 airdrops from ecosystem projects</li>
          <li>
            1M EigenLayer points giveaway for depositors in the first 4 weeks
          </li>
        </ul>
      </Frame>
      <Frame>
        <span>Continue earning:</span>
        <ul>
          <li>EigenLayer & Symbiotic points</li>
          <li>LRT and other points</li>
          <li>Staking yield</li>
        </ul>
      </Frame>
    </Layout>
  )
}
