import { createContext, useContext } from 'react'
import { SwellDaoApi } from './types'

const MISSING_PROVIDER = Symbol()

export type SwellDaoContext = SwellDaoApi

export const SwellDaoContext = createContext<
  SwellDaoContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useSwellDaoApi() {
  const ctx = useContext(SwellDaoContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'SwellDaoContext hooks must be wrapped in a <SwellDaoContext.Provider>'
    )
  }
  return ctx
}
