import { StakingConfirmationProgressWidget } from '@/components/StakingConfirmationProgressWidget'
import { BigNumber } from 'ethers'
import { useEffect } from 'react'
import {
  YearnApproveAssetForDeposit,
  YearnApproveVaultTokenForWithdraw,
  YearnDeposit,
  YearnRequestWithdraw,
  YearnCancelWithdraw,
  YearnCompleteWithdraw,
} from '@/state/yearnVault/hooks'
import { Token } from '@/types/tokens'
import { TRANSACTION_TOAST_TITLE } from '../constants'
import {
  vaultTokenReceivedForDepositAsset,
  withdrawAssetReceivedForVaultToken,
} from '@/components/StakingWidget/yearn/yearnConversions'
import { YearnTokenRates, YearnWithdrawAsset } from '@/state/yearnVault/types'
import { displayCryptoLocale } from '@/util/displayCrypto'

export function YearnApproveAssetForDepositToast({
  approveAssetForDeposit,
  anyTransactionInProgress,
  depositAsset,
}: {
  approveAssetForDeposit: YearnApproveAssetForDeposit
  anyTransactionInProgress: boolean
  depositAsset: Token
}) {
  const onClose = approveAssetForDeposit.clear

  const complete =
    approveAssetForDeposit.status === approveAssetForDeposit.STATUS.FULFILLED
  const confirming =
    approveAssetForDeposit.status === approveAssetForDeposit.STATUS.PROMPTING
  const pending =
    approveAssetForDeposit.status === approveAssetForDeposit.STATUS.PENDING

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_PENDING
  }

  let message = ''
  if (approveAssetForDeposit.args) {
    const { amount } = approveAssetForDeposit.args[0]
    const amountStr = displayCryptoLocale(amount, depositAsset.decimals, {
      btc: true,
    })
    message = `Approve ${amountStr} ${depositAsset.symbol} for deposit`
  }

  const txHash = approveAssetForDeposit.txHash
  const open = !!title && !!message

  return (
    <StakingConfirmationProgressWidget
      complete={complete}
      confirming={confirming}
      pending={pending}
      title={title}
      txHash={txHash}
      onClose={onClose}
      open={open}
      message={message}
    />
  )
}

export function YearnApproveVaultTokenForWithdrawToast({
  approveVaultTokenForWithdraw,
  anyTransactionInProgress,
  vaultToken,
}: {
  approveVaultTokenForWithdraw: YearnApproveVaultTokenForWithdraw
  anyTransactionInProgress: boolean
  vaultToken: Token
}) {
  const onClose = approveVaultTokenForWithdraw.clear

  const complete =
    approveVaultTokenForWithdraw.status ===
    approveVaultTokenForWithdraw.STATUS.FULFILLED
  const confirming =
    approveVaultTokenForWithdraw.status ===
    approveVaultTokenForWithdraw.STATUS.PROMPTING
  const pending =
    approveVaultTokenForWithdraw.status ===
    approveVaultTokenForWithdraw.STATUS.PENDING

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_PENDING
  }

  let message = ''
  if (approveVaultTokenForWithdraw.args) {
    const { amount } = approveVaultTokenForWithdraw.args[0]
    const amountStr = displayCryptoLocale(amount, vaultToken.decimals, {
      btc: true,
    })
    message = `Approve ${amountStr} ${vaultToken.symbol} for withdrawal`
  }

  const txHash = approveVaultTokenForWithdraw.txHash
  const open = !!title && !!message

  return (
    <StakingConfirmationProgressWidget
      complete={complete}
      confirming={confirming}
      pending={pending}
      title={title}
      txHash={txHash}
      onClose={onClose}
      open={open}
      message={message}
    />
  )
}

export function YearnDepositToast({
  deposit,
  anyTransactionInProgress,
  rates,
  depositAsset,
  vaultToken,
}: {
  deposit: YearnDeposit
  anyTransactionInProgress: boolean
  rates: YearnTokenRates | undefined
  depositAsset: Token
  vaultToken: Token
}) {
  const onClose = deposit.clear

  const complete = deposit.status === deposit.STATUS.FULFILLED
  const confirming = deposit.status === deposit.STATUS.PROMPTING
  const pending = deposit.status === deposit.STATUS.PENDING

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.YEARN_DEPOSIT_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.YEARN_DEPOSIT_PENDING
  }

  let message = ''
  if (deposit.args && rates) {
    const { amount } = deposit.args[0]
    const pricePerShare = rates.pricePerShare
    if (!pricePerShare) {
      return null
    }
    const receivedVaultToken = vaultTokenReceivedForDepositAsset({
      toSendAsset: amount,
      pricePerShare,
      vaultTokenDecimals: vaultToken.decimals,
    })

    const depositAmountStr = displayCryptoLocale(
      amount,
      depositAsset.decimals,
      { btc: true }
    )
    const receivedVaultTokenStr = displayCryptoLocale(
      receivedVaultToken,
      vaultToken.decimals,
      { btc: true }
    )

    if (complete) {
      message = `Deposited ${depositAmountStr} ${depositAsset.symbol} for ${receivedVaultTokenStr} ${vaultToken.symbol}`
    } else {
      message = `Depositing ${depositAmountStr} ${depositAsset.symbol} for ${receivedVaultTokenStr} ${vaultToken.symbol}`
    }
  }

  const txHash = deposit.txHash
  const open = !!title && !!message

  return (
    <StakingConfirmationProgressWidget
      complete={complete}
      confirming={confirming}
      pending={pending}
      title={title}
      txHash={txHash}
      onClose={onClose}
      open={open}
      message={message}
    />
  )
}

export function YearnRequestWithdrawToast({
  requestWithdraw,
  anyTransactionInProgress,
  rates,
  vaultToken,
  withdrawAsset,
}: {
  requestWithdraw: YearnRequestWithdraw
  anyTransactionInProgress: boolean
  rates: Record<string, BigNumber> | undefined
  vaultToken: Token
  withdrawAsset: YearnWithdrawAsset | undefined
}) {
  const onClose = requestWithdraw.clear

  const complete = requestWithdraw.status === requestWithdraw.STATUS.FULFILLED
  const confirming = requestWithdraw.status === requestWithdraw.STATUS.PROMPTING
  const pending = requestWithdraw.status === requestWithdraw.STATUS.PENDING

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.YEARN_REQUEST_WITHDRAWAL_COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.YEARN_REQUEST_WITHDRAWAL_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.YEARN_REQUEST_WITHDRAWAL_PENDING
  }

  let message = ''
  if (requestWithdraw.args && rates && withdrawAsset) {
    const { shares } = requestWithdraw.args[0]
    const { pricePerShare } = rates
    const { withdrawFeeBasisPoints } = withdrawAsset

    const assets = withdrawAssetReceivedForVaultToken({
      pricePerShare,
      toSendVaultToken: shares,
      vaultTokenDecimals: vaultToken.decimals,
      withdrawFeeBasisPoints,
    })

    const sharesStr = displayCryptoLocale(shares, vaultToken.decimals, {
      btc: true,
    })

    const receiveAssetsStr = displayCryptoLocale(
      assets,
      withdrawAsset.decimals,
      { btc: true }
    )

    if (confirming) {
      message = `Withdraw request ${sharesStr} ${vaultToken.symbol} for ${receiveAssetsStr} ${withdrawAsset.symbol}`
    } else if (pending) {
      message = `Withdrawing ${sharesStr} ${vaultToken.symbol} for ${receiveAssetsStr} ${withdrawAsset.symbol}`
    } else if (complete) {
      message = `Withdraw ${sharesStr} ${vaultToken.symbol} for ${receiveAssetsStr} ${withdrawAsset.symbol}`
    }
  }

  const txHash = requestWithdraw.txHash
  const open = !!title && !!message

  return (
    <StakingConfirmationProgressWidget
      complete={complete}
      confirming={confirming}
      pending={pending}
      title={title}
      txHash={txHash}
      onClose={onClose}
      open={open}
      message={message}
    />
  )
}

export function YearnCancelWithdrawToast({
  cancelWithdraw,
  anyTransactionInProgress,
  withdrawAsset,
}: {
  cancelWithdraw: YearnCancelWithdraw
  anyTransactionInProgress: boolean
  withdrawAsset: Token
}) {
  const onClose = cancelWithdraw.clear

  const complete = cancelWithdraw.status === cancelWithdraw.STATUS.FULFILLED
  const confirming = cancelWithdraw.status === cancelWithdraw.STATUS.PROMPTING
  const pending = cancelWithdraw.status === cancelWithdraw.STATUS.PENDING

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.YEARN_CANCEL_WITHDRAWAL_COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.YEARN_CANCEL_WITHDRAWAL_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.YEARN_CANCEL_WITHDRAWAL_PENDING
  }

  let message = ''
  if (cancelWithdraw.args) {
    message = `Cancel withdrawal request for ${withdrawAsset.symbol}`
  }

  const txHash = cancelWithdraw.txHash
  const open = !!title && !!message

  return (
    <StakingConfirmationProgressWidget
      complete={complete}
      confirming={confirming}
      pending={pending}
      title={title}
      txHash={txHash}
      onClose={onClose}
      open={open}
      message={message}
    />
  )
}

export function YearnCompleteWithdrawToast({
  completeWithdraw,
  anyTransactionInProgress,
  withdrawAsset,
}: {
  completeWithdraw: YearnCompleteWithdraw
  anyTransactionInProgress: boolean
  withdrawAsset: Token
}) {
  const onClose = completeWithdraw.clear

  const complete = completeWithdraw.status === completeWithdraw.STATUS.FULFILLED
  const confirming =
    completeWithdraw.status === completeWithdraw.STATUS.PROMPTING
  const pending = completeWithdraw.status === completeWithdraw.STATUS.PENDING

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.YEARN_COMPLETE_WITHDRAWAL_COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.YEARN_COMPLETE_WITHDRAWAL_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.YEARN_COMPLETE_WITHDRAWAL_PENDING
  }

  let message = ''
  if (completeWithdraw.args) {
    message = `Complete withdrawal request for ${withdrawAsset.symbol}`
  }

  const txHash = completeWithdraw.txHash
  const open = !!title && !!message

  return (
    <StakingConfirmationProgressWidget
      complete={complete}
      confirming={confirming}
      pending={pending}
      title={title}
      txHash={txHash}
      onClose={onClose}
      open={open}
      message={message}
    />
  )
}
