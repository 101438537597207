import { ProgressPayload } from '@/services/TaskRunner/ITaskRunner'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDepositCollectionValidationResult } from './models/DepositCollectionValidationResult'

interface DepositValidationState {
  input: string
  validationResult?: IDepositCollectionValidationResult | undefined
  validationProgress?: ProgressPayload | undefined
  validating: boolean
}

const initialState: DepositValidationState = {
  input: '',
  validating: false,
}

const depositValidationSlice = createSlice({
  name: 'depositValidation',
  initialState,
  reducers: {
    setDepositCollectionInput: (state, { payload }: PayloadAction<string>) => {
      if (state.validating)
        throw new Error('Cannot change input while validating')

      state.input = payload
      state.validationResult = undefined
      state.validationProgress = undefined
    },
    setValidationProgress: (
      state,
      { payload }: PayloadAction<ProgressPayload>
    ) => {
      state.validationProgress = payload
    },
    setValidationResult: (
      state,
      { payload }: PayloadAction<IDepositCollectionValidationResult>
    ) => {
      state.validationResult = payload
    },
    setValidating: (state, { payload }: PayloadAction<boolean>) => {
      state.validating = payload
    },
  },
})

export const {
  setDepositCollectionInput,
  setValidating,
  setValidationProgress,
  setValidationResult,
} = depositValidationSlice.actions
export default depositValidationSlice.reducer
