import { SupportedChainId } from './chains'
import swbtcLogo from '@/assets/images/swbtc-logo-64x64.png'
import { getAddress, hexZeroPad } from 'ethers/lib/utils'
import swellLogo from 'assets/images/swell-logo.png'
import eigenlayerLogo from 'assets/images/predeposit/eigenlayer.png'

export const TOKEN_LIST_ETH = {
  chainId: 1,
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  name: 'Ethereum',
  symbol: 'ETH',
  decimals: 18,
  logoURI: '',
}

export const TOKEN_LIST_SWETH = {
  chainId: 1,
  address: '0xf951e335afb289353dc249e82926178eac7ded78',
  name: 'Swell Ethereum',
  symbol: 'swETH',
  decimals: 18,
  logoURI:
    'https://assets.coingecko.com/coins/images/30326/thumb/_lB7zEtS_400x400.jpg?1684128260',
}

export const TOKEN_LIST_RSWETH = {
  chainId: 1,
  address: '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0',
  name: 'Restaked Swell Ethereum',
  symbol: 'rswETH',
  decimals: 18,
  logoURI:
    'https://assets.coingecko.com/coins/images/34489/thumb/Restaked_Swell_ETH.png?1705048450',
}

export const TOKEN_LIST_WBTC = {
  chainId: 1,
  address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  name: 'Wrapped Bitcoin',
  symbol: 'WBTC',
  decimals: 8, // 8 decimals for WBTC
  logoURI:
    'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/668f36f525894f0dbb90cdc1_wBTC.svg',
}

export const TOKEN_LIST_WBTC_SEPOLIA = {
  ...TOKEN_LIST_WBTC,
  decimals: 8,
  chainId: SupportedChainId.SEPOLIA,
  address: '0xfBefEB5dAaCD725CD8943c82B13C4B4499Dc87f5',
}

export const TOKEN_LIST_WBTC_HOLESKY = {
  ...TOKEN_LIST_WBTC,
  decimals: 8,
  chainId: SupportedChainId.HOLESKY,
  address: '0x640577074A5d054b011E0f05114078777646ca8e',
}

export const TOKEN_LIST_SWBTC = {
  chainId: 1,
  address: '0x8DB2350D78aBc13f5673A411D4700BCF87864dDE',
  name: 'swBTC',
  symbol: 'swBTC',
  decimals: 8,
  logoURI: swbtcLogo,
}
export const TOKEN_LIST_BTCLRT = TOKEN_LIST_SWBTC

export const TOKEN_LIST_SWBTC_SEPOLIA = {
  ...TOKEN_LIST_SWBTC,
  chainId: SupportedChainId.SEPOLIA,
  address: '0xeFc03C91C5F11E6B86645337D11f76BbbDBA151F',
}
export const TOKEN_LIST_BTCLRT_SEPOLIA = TOKEN_LIST_SWBTC_SEPOLIA

export const TOKEN_LIST_SWBTC_HOLESKY = {
  ...TOKEN_LIST_SWBTC,
  chainId: SupportedChainId.HOLESKY,
  address: '0xC42aEa23C2E77a0831d7eefc07BB4ec9052503FF',
}
export const TOKEN_LIST_BTCLRT_HOLESKY = TOKEN_LIST_SWBTC_HOLESKY

export const TOKEN_LIST_SWELL = {
  chainId: 1,
  address: getAddress(hexZeroPad('0xffff00', 20)), // TODO
  name: 'Swell',
  symbol: 'SWELL',
  decimals: 18,
  logoURI: swellLogo,
}

export const TOKEN_LIST_EIGEN = {
  chainId: 1,
  address: '0xec53bF9167f50cDEB3Ae105f56099aaaB9061F83',
  name: 'Eigen',
  symbol: 'EIGEN',
  decimals: 18,
  logoURI: eigenlayerLogo,
}
