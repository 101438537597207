import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Alert } from '@swell-ui/Alert'
import { Box } from '@swell-ui/Box'
import { Button } from '@swell-ui/Button'
import { FlexRow } from '@swell-ui/FlexRow'
import { Grid } from '@swell-ui/Grid'
import { TextInput } from '@swell-ui/inputs'
import { UploadIcon } from '@swell-ui/icons/UploadIcon'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { SectionBoxLabel } from '@/components/SectionBoxLabel'
import { readFileAsText } from '@/util/file'
import { DepositInfo } from '@/types/deposits'
import { useChunkDepositCollection } from '@/hooks/useChunkDepositCollection'

interface UploadDepositCollectionWidgetProps {
  depositCollectionValidationInput: any
  depositCollectionValidation: any
  onSubmit: (chunks: DepositInfo[][]) => void
  preventInteraction: boolean
  setTextAreaRef: any
  isOperator: boolean
}

const ValidatorWidgetGrid = styled(Grid)`
  .MuiGrid-item {
    width: 100%;
  }
`

const ContainerBox = styled(Box)`
  padding: 24px 32px;
`

const UploadButton = styled(Button)`
  float: right;
  padding-left: 51.5px;
  padding-right: 51.5px;
`

const StyledUploadIcon = styled(UploadIcon)`
  height: 18px;
  width: 18px;
  margin-left: -2px;
  margin-right: -8px;

  path {
    fill: ${({ theme }) => theme.mainColor};
  }
`

const DescriptionText = styled.div`
  letter-spacing: -0.03em;
`

const StyledTextInput = styled(TextInput)`
  width: 100%;
  letter-spacing: -0.03em;
`

const ButtonRow = styled(FlexRow)`
  button {
    width: 176px;
    height: 56px;
  }
`

const ClearButton = styled(Button)`
  margin-left: 8px;
`

const TextAreaGrid = styled(Grid)`
  position: relative;
`

const UploadAlert = styled(Alert)`
  position: absolute;
  top: 24px;
  width: calc(100% - 24px);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 20;
`

export function UploadDepositCollectionCommon({
  depositCollectionValidationInput,
  depositCollectionValidation,
  setTextAreaRef,
  onSubmit,
  preventInteraction,
  isOperator,
}: UploadDepositCollectionWidgetProps) {
  const { account } = useSwellWeb3()

  const [value, setValue] = depositCollectionValidationInput
  const [errorMessage, setErrorMessage] = useState<string>('')
  const fileInputRef = useRef<any>()
  const textAreaRef = useRef<any>()

  const { validating, validationResult, validate } = depositCollectionValidation
  const chunkDepositCollection = useChunkDepositCollection()

  useEffect(() => {
    if (textAreaRef) {
      setTextAreaRef(textAreaRef)
    }
  }, [textAreaRef, setTextAreaRef])

  const handleUploadClick = () => {
    fileInputRef.current.click()
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const file = event.target.files[0]
      readFileAsText(file, handleFileLoad, handleFileError)
    }
  }

  function handleFileLoad(text: string) {
    try {
      const fileJSON = JSON.parse(text)
      if (fileJSON.length > 10000) {
        return console.error('More than 10k keys')
      }

      setValue(text)
    } catch (err: any) {
      console.error(err)
      setErrorMessage(err.message + '.')
    }
  }

  function handleFileError(error: DOMException | null) {
    console.error(error)
  }

  function readyToSubmit() {
    return !preventInteraction && validationResult && validationResult.valid
  }

  function validateDisabled() {
    return preventInteraction || !value || validating || !isOperator
  }

  const handleOnSubmit = () => {
    if (validationResult?.depositCollection) {
      const chunks = chunkDepositCollection(validationResult.depositCollection)
      onSubmit(chunks)
    }
  }

  return (
    <ContainerBox>
      <ValidatorWidgetGrid container direction="column" spacing={3}>
        <Grid item>
          <FlexRow align="center" justify="space-between">
            <SectionBoxLabel>Upload keys</SectionBoxLabel>
            <UploadButton
              variant="secondary"
              size="small"
              disabled={!account || preventInteraction}
              onClick={handleUploadClick}
            >
              <StyledUploadIcon />
              Upload
            </UploadButton>
            <input
              type="file"
              accept="application/json"
              hidden
              onChange={handleFileChange}
              id="upload-btn"
              ref={fileInputRef}
            />
          </FlexRow>
        </Grid>
        <Grid item>
          <DescriptionText>
            Paste or upload your deposit data JSON.
            <br />
            1,000 keys maximum.
          </DescriptionText>
        </Grid>
        <TextAreaGrid item>
          {!!errorMessage && (
            <UploadAlert level="error">{errorMessage}</UploadAlert>
          )}
          <StyledTextInput
            multiline
            disabled={!isOperator || preventInteraction}
            variant="outlined"
            rows={18}
            value={value}
            onChange={(e: any) => setValue(e.currentTarget.value)}
            inputRef={textAreaRef}
            inputProps={{ id: 'keys-input ' }}
            placeholder={'Paste JSON here.'}
          />
        </TextAreaGrid>
        <Grid item>
          <ButtonRow align="center" justify="left">
            {!account && (
              <ConnectWalletButton>Connect wallet</ConnectWalletButton>
            )}
            {account && !readyToSubmit() && (
              <Button disabled={validateDisabled()} onClick={validate}>
                {!validating && 'Validate'}
                {validating && 'Validating...'}
              </Button>
            )}
            {account && readyToSubmit() && (
              <Button onClick={handleOnSubmit}>Submit</Button>
            )}
            <ClearButton
              variant="secondary"
              onClick={() => setValue('')}
              disabled={!value || preventInteraction}
            >
              Clear
            </ClearButton>
          </ButtonRow>
        </Grid>
      </ValidatorWidgetGrid>
    </ContainerBox>
  )
}
