import React, { ReactNode, useState } from 'react'
import { Box } from '@/swell-ui/Box'
import { Tabs } from '@/swell-ui/Tabs'
import styled from 'styled-components'
import { YearnVaultStats } from '@/state/yearnVault/types'
import { Token } from '@/types/tokens'
import { BigNumber } from 'ethers'
import { BtcLrtStatistics } from './BtcLrtStatistics'
import { BtcLrtVaultDescription } from './BtcLrtVaultDescription'
import { BtcLrtContracts } from './BtcLrtContracts'
import { hScrollStyle } from './common'

const Tab = {
  Statistics: 'Statistics' as const,
  Description: 'Description' as const,
  Contracts: 'Contracts' as const,
}
type Tab = (typeof Tab)[keyof typeof Tab]

export function BtcLrtDetails({
  vaultStats,
  vaultToken,
  depositAsset,
  vaultStrategyAddress,
  depositAssetEthRate,
  ethUsdMarketRate,
  karakPoints,
  pricePerShare,
  vaultTokenBalance,
  blackPearls,
  vaultSymbioticPoints,
  eigenLayerPoints,
}: {
  vaultToken: Token
  depositAsset: Token
  vaultStrategyAddress: string
  vaultStats: YearnVaultStats | undefined
  ethUsdMarketRate: number | undefined
  depositAssetEthRate: BigNumber | undefined
  pricePerShare: BigNumber | undefined
  vaultTokenBalance: BigNumber | undefined
  karakPoints: string | undefined
  blackPearls: number | undefined
  vaultSymbioticPoints: number | undefined
  eigenLayerPoints: string | undefined
}) {
  const [selectedTab, setTab] = useState(Tab.Statistics as string)

  let content: ReactNode = null
  if (selectedTab === Tab.Statistics) {
    content = (
      <BtcLrtStatistics
        vaultStats={vaultStats}
        vaultToken={vaultToken}
        depositAssetEthRate={depositAssetEthRate}
        ethUsdMarketRate={ethUsdMarketRate}
        depositAsset={depositAsset}
        karakPoints={karakPoints}
        pricePerShare={pricePerShare}
        vaultSymbioticPoints={vaultSymbioticPoints}
        vaultTokenBalance={vaultTokenBalance}
        blackPearls={blackPearls}
        eigenLayerPoints={eigenLayerPoints}
      />
    )
  } else if (selectedTab === Tab.Description) {
    content = <BtcLrtVaultDescription />
  } else if (selectedTab === Tab.Contracts) {
    content = (
      <BtcLrtContracts
        vaultStrategyAddress={vaultStrategyAddress}
        vaultToken={vaultToken}
      />
    )
  }

  return (
    <DetailsBox>
      <Tabs
        selectedTab={selectedTab}
        tabs={[
          { id: Tab.Statistics, label: 'Statistics' },
          { id: Tab.Description, label: 'Description' },
          { id: Tab.Contracts, label: 'Contracts' },
        ]}
        setTab={setTab as (tab: string) => void}
      />
      <div className="positioner">
        <div className="content">
          <div className="scrollcontent">{content}</div>
        </div>
      </div>
    </DetailsBox>
  )
}

const DetailsBox = styled(Box)`
  width: 100%;
  padding: 24px;
  height: 312px;
  display: flex;
  flex-direction: column;

  .positioner,
  .content,
  .scrollcontent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 900px) {
    height: 542px;

    .positioner {
      position: relative;
    }
    .content {
      position: absolute;
      inset: 0;
    }
    .scrollcontent {
      overflow-x: auto;
      ${hScrollStyle};
    }
  }
`
