import { DefaultTheme, css } from 'styled-components/macro'
import { createTheme } from '@mui/material/styles'
import { colors } from './colors'
import { typography } from './typography'
import { ThemeData } from './branding'
import { transparentize, mix } from 'polished'
import cloneDeep from 'lodash/cloneDeep'

// navigation is universal, with less page-specific branding. Only light/dark.
export const navigationLight: DefaultTheme['navigation'] = {
  dialog: {
    bgColor: colors.white['50'],
    borderRadius: '16px',
    backdrop: {
      backdropFilter: 'blur(5px)',
      bgColor: 'rgba(255, 255, 255, 0.50)',
    },
    borderColor: colors.eigenLayer['federalBlue'],
  },
  newIndicator: {
    bgColor: ThemeData.Symbiotic.symbioticBtcOrange,
    color: '#000',
    border: 'none',
    borderRadius: '4px',
  },
  dividerColor: '#ECF2FE',
  pagesTypography: css`
    color: rgb(24, 13, 104);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
    letter-spacing: normal;
  `,
  pagesUnselectedColor: 'rgba(24, 13, 104, 0.5)',
  pagesOpenColor: mix(0.5, 'rgb(24, 13, 104)', 'rgba(24, 13, 104, 0.5)'),
  columnHeadingTypography: css`
    color: var(--Eigen-Layer-Federal-Blue, #180d68);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 19.2px */
    letter-spacing: 0.9px;
    text-transform: uppercase;
  `,
  drawerChevronColor: '#180d68',
  drawerHeadingTypography: css`
    color: var(--Eigen-Layer-Federal-Blue, #180d68);
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  `,
  linkTypography: css`
    color: var(--Eigen-Layer-Federal-Blue, #180d68);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.48px;
  `,
  linkHoverBgColor: '#CEDFFC',
  linkHoverBorderRadius: '4px',
}

export const navigationDark: DefaultTheme['navigation'] = {
  dialog: {
    bgColor: '#000',
    borderRadius: '16px',
    backdrop: {
      backdropFilter: 'blur(5px)',
      bgColor: 'rgba(0, 0, 0, 0.50)',
    },
    borderColor: '#1D1D1D',
  },
  newIndicator: {
    bgColor: ThemeData.Symbiotic.symbioticBtcOrange,
    border: 'none',
    borderRadius: '4px',
    color: '#000',
  },
  dividerColor: 'rgba(255, 255, 255, 0.10)',
  pagesTypography: css`
    color: var(--white-50, #fff);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
    letter-spacing: normal;
  `,
  pagesUnselectedColor: 'rgb(176, 176, 176)',
  pagesOpenColor: mix(0.5, 'rgb(176, 176, 176)', '#fff'),
  columnHeadingTypography: css`
    color: var(--white-125, #b0b0b0);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 19.2px */
    letter-spacing: 0.9px;
    text-transform: uppercase;
  `,

  drawerChevronColor: '#fff',
  drawerHeadingTypography: css`
    color: var(--white-50, #fff);
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  `,
  linkTypography: css`
    color: var(--white-50, #fff);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.48px;
  `,
  linkHoverBgColor: '#181818',
  linkHoverBorderRadius: '4px',
}

const toastDark: DefaultTheme['toast'] = {
  bgColor: colors.oceanBlue['125'],
  altBgColor: `linear-gradient(180deg, #3068EF 0.29%, #1322AC 167.95%)`,
  messageColor: colors.white['125'],
  border: '0.8px solid #424557',
  borderRadius: '8px',
  progressCompleteBgColor: '#009e49',
  a: {
    hoverColor: colors.white['50'],
  },
}

const toastLight: DefaultTheme['toast'] = {
  bgColor: colors.white['50'],
  altBgColor: colors.eigenLayer['yellow'],
  messageColor: colors.eigenLayer['federalBlue'],
  border: `0.8px solid #CEDFFC`,
  borderRadius: '8px',
  progressCompleteBgColor: '#009e49',
  a: {
    hoverColor: colors.eigenLayer['federalBlue'],
  },
}
export const darkTheme: DefaultTheme = createTheme({
  mainBgColor: colors.oceanBlue['100'],
  mainColor: colors.white['50'],
  secondaryBgColor: 'rgb(14, 20, 26)',
  accentBgColor: 'rgb(41, 50, 73)',
  accentColor: 'rgb(200, 200, 200)',
  fiatColor: colors.lightBlue['50'],

  a: {
    color: colors.white['125'],
    hoverColor: colors.white['50'],
  },

  accordionTable: {
    summaryBgColor: colors.oceanBlue['100'],
    expandedBgColor:
      'inear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) ), #051a2b',
  },

  alert: {
    success: {
      bgColor: colors.lightBlue['125'],
      linkHoverColor: colors.white['100'],
    },
    error: {
      bgColor: colors.red['100'],
    },
  },

  box: {
    bgColor: colors.oceanBlue['125'],
    borderColor: `transparent`,
    color: colors.white['125'],
    label: {
      color: colors.lightBlue['50'],
    },
    borderRadius: '16px',
  },

  bottomNav: {
    bgColor: colors.oceanBlue['125'],
    color: colors.white['50'],
    selectedColor: colors.white['50'],
  },

  button: {
    accent: {
      bgColor: colors.oceanBlue['125'],
      color: colors.white['50'],
    },
    small: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    medium: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    large: {
      fontSize: typography.body.large.fontSize,
      lineHeight: typography.body.large.lineHeight,
    },
    primary: {
      bgColor: colors.lightBlue['100'],
      color: colors.white['50'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledBgColor: '#131B5E',
      disabledColor: colors.white['150'],
      disabledOpacity: 0.5,
    },
    secondary: {
      bgColor: 'transparent',
      color: colors.white['50'],
      disabledColor: colors.white['50'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
    tertiary: {
      bgColor: 'transparent',
      color: colors.white['50'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
  },

  chip: {
    color: colors.white['125'],
    filledBgColor: 'rgba(241,241,241,0.1)',
    filledColor: colors.white['50'],
  },

  colors,

  dataBoxGrid: {
    dataBox: {
      color: colors.white['50'],
      bgColor: 'rgba(255, 255, 255, 0.05)',
      dataColor: colors.white['125'],
    },
  },

  dialog: {
    bgColor: colors.oceanBlue['125'],
    borderColor: colors.lightBlue['50'],
    borderRadius: '16px',
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
    title: {
      color: colors.white['125'],
    },
  },

  divider: {
    darkColor: colors.auxGrey,
    lightColor: colors.auxGrey,
  },

  drawer: {
    bgColor: colors.oceanBlue['125'],
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
  },

  footer: {
    link: {
      color: colors.lightBlue['50'],
    },
  },

  input: {
    underlineColor: colors.white['50'],
    underlineFocusColor: colors.lightBlue['100'],
    outlined: {
      border: '1px solid rgba(255, 255, 255, 0.05)',
      borderRadius: '8px',
    },
  },

  menu: {
    bgColor: colors.oceanBlue['125'],
    color: colors.white['50'],
    borderColor: '#424557',
    borderRadius: '16px',
  },

  navigation: navigationDark,

  pagination: {
    item: {
      color: colors.white['50'],
      bgColor: colors.lightBlue['100'],
    },
  },

  toast: toastDark,

  tabs: {
    color: colors.white['125'],
    hoverColor: colors.white['50'],
  },

  table: {
    head: {
      color: colors.lightBlue['50'],
      secondaryColor: colors.white['125'],
    },
    cell: {
      bgColor: colors.oceanBlue['100'],
    },
  },

  tokenList: {
    rowHoverColor: colors.oceanBlue['100'],
  },

  typography,
})

export const restakeTheme: DefaultTheme = createTheme({
  mainBgColor: colors.eigenLayer['aliceBlue'],
  mainColor: colors.eigenLayer['federalBlue'],
  secondaryBgColor: 'rgb(14, 20, 26)',
  accentBgColor: 'rgb(41, 50, 73)',
  accentColor: 'rgb(200, 200, 200)',
  fiatColor: colors.lightBlue['50'],

  a: {
    color: '#180d6880',
    hoverColor: colors.eigenLayer['federalBlue'],
  },

  accordionTable: {
    summaryBgColor: colors.eigenLayer['aliceBlue'],
    expandedBgColor: colors.eigenLayer['aliceBlue'],
  },

  alert: {
    success: {
      bgColor: colors.lightBlue['125'],
      linkHoverColor: colors.white['100'],
    },
    error: {
      bgColor: colors.red['100'],
    },
  },

  box: {
    bgColor: colors.white['50'],
    borderRadius: '16px',
    border: 'none',
    color: colors.eigenLayer['federalBlue'],
    label: {
      color: colors.eigenLayer['federalBlue'],
    },
  },

  bottomNav: {
    bgColor: colors.white['50'],
    color: colors.white['50'],
    selectedColor: colors.eigenLayer['federalBlue'],
  },

  button: {
    accent: {
      bgColor: 'transparent',
      border: `1px solid ${colors.eigenLayer['federalBlue']}`,
      color: colors.eigenLayer['federalBlue'],
    },
    small: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    medium: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    large: {
      fontSize: typography.body.large.fontSize,
      lineHeight: typography.body.large.lineHeight,
    },
    primary: {
      bgColor: colors.eigenLayer['yellow'],
      color: colors.eigenLayer['federalBlue'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledBgColor: 'rgba(255, 188, 1, 0.5)',
      disabledColor: 'rgba(24, 13, 104, 0.5)',
      disabledOpacity: 0.5,
    },
    secondary: {
      bgColor: 'transparent',
      color: colors.eigenLayer['federalBlue'],
      disabledColor: colors.eigenLayer['federalBlue'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
    tertiary: {
      bgColor: 'transparent',
      color: colors.eigenLayer['federalBlue'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
  },

  chip: {
    color: colors.eigenLayer['federalBlue'],
    filledBgColor: colors.eigenLayer['aliceBlue'],
    filledColor: colors.eigenLayer['federalBlue'],
  },

  colors,

  dataBoxGrid: {
    dataBox: {
      color: colors.eigenLayer['federalBlue'],
      bgColor: 'rgba(255, 255, 255, 0.05)',
      dataColor: colors.lightBlue['100'],
    },
  },

  dialog: {
    bgColor: colors.white['50'],
    borderColor: colors.eigenLayer['federalBlue'],
    borderRadius: '16px',
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
    title: {
      color: colors.eigenLayer['federalBlue'],
    },
  },

  divider: {
    darkColor: colors.eigenLayer['federalBlue'],
    lightColor: colors.eigenLayer['platinum'],
  },

  drawer: {
    bgColor: colors.eigenLayer['aliceBlue'],
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
  },

  footer: {
    link: {
      color: colors.eigenLayer['federalBlue'],
    },
  },

  input: {
    underlineColor: colors.eigenLayer['federalBlue'],
    underlineFocusColor: colors.lightBlue['100'],
    outlined: {
      border: `1px solid ${colors.lightBlue['50']}`,
    },
  },

  menu: {
    bgColor: colors.white['50'],
    color: colors.eigenLayer['federalBlue'],
    borderColor: '#424557',
    borderRadius: '16px',
  },

  navigation: navigationLight,

  pagination: {
    item: {
      color: colors.eigenLayer['federalBlue'],
      bgColor: colors.eigenLayer['yellow'],
    },
  },

  tabs: {
    color: '#180d6880',
    hoverColor: colors.eigenLayer['federalBlue'],
  },

  table: {
    head: {
      color: colors.lightBlue['100'],
      secondaryColor: colors.lightBlue['100'],
    },
    cell: {
      bgColor: colors.white['50'],
    },
  },

  toast: toastLight,

  tokenList: {
    rowHoverColor: colors.eigenLayer['aliceBlue'],
  },

  typography,
})

export const btcLrtTheme: DefaultTheme = cloneDeep(darkTheme)
btcLrtTheme.fiatColor = ThemeData.Symbiotic.symbioticBtcOrange

btcLrtTheme.a.hoverColor = ThemeData.Symbiotic.symbioticBtcOrange
btcLrtTheme.tabs.hoverColor = ThemeData.Symbiotic.symbioticBtcOrange

btcLrtTheme.button.primary.bgColor = ThemeData.Symbiotic.symbioticBtcOrange
btcLrtTheme.button.primary.disabledBgColor = transparentize(
  0.3,
  ThemeData.Symbiotic.symbioticBtcOrange
)
btcLrtTheme.button.primary.disabledColor = transparentize(0.3, '#000')
btcLrtTheme.button.primary.color =
  ThemeData.Symbiotic.symbioticButtonPrimaryTextColor

btcLrtTheme.button.accent.bgColor = 'transparent'
btcLrtTheme.button.accent.color = '#fff'
btcLrtTheme.button.accent.border = `1px solid rgba(255, 255, 255, 0.30)`

btcLrtTheme.box.borderColor = 'transparent'
btcLrtTheme.box.borderRadius = ThemeData.Symbiotic.symbioticBorderRadius
btcLrtTheme.box.bgColor = ThemeData.Symbiotic.symbioticBitcoinDark

btcLrtTheme.drawer.backdrop.backdropFilter =
  ThemeData.Symbiotic.SymbioticBackdropFilter
btcLrtTheme.drawer.backdrop.bgColor = ThemeData.Symbiotic.symbioticBackdropColor
btcLrtTheme.drawer.bgColor = ThemeData.Symbiotic.symbioticBlack

btcLrtTheme.menu.bgColor = ThemeData.Symbiotic.symbioticBlack
btcLrtTheme.menu.borderColor = 'rgba(129, 129, 129, 0.30)'
btcLrtTheme.menu.borderRadius = ThemeData.Symbiotic.symbioticBorderRadius

btcLrtTheme.input.outlined.borderRadius =
  ThemeData.Symbiotic.symbioticBorderRadius

btcLrtTheme.dialog.borderRadius = ThemeData.Symbiotic.symbioticBorderRadius
btcLrtTheme.dialog.bgColor = ThemeData.Symbiotic.symbioticBlack
btcLrtTheme.dialog.borderColor = ThemeData.Symbiotic.symbioticBtcOrange

btcLrtTheme.scrollbar = {
  trackBg: ThemeData.Symbiotic.symbioticBlack,
  thumbBg: ThemeData.Symbiotic.symbioticBtcOrange,
  width: '8px',
  borderRadius: '0',
}

btcLrtTheme.navigation = navigationDark
