import React from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { Button, ButtonProps } from '@swell-ui/Button'

function ConnectWalletButton(props: ButtonProps) {
  const { connect } = useSwellWeb3()

  const connectWallet = async () => {
    await connect()
  }

  return (
    <Button onClick={connectWallet} {...props}>
      {props.children}
    </Button>
  )
}

export { ConnectWalletButton }
