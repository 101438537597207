import React, { forwardRef, SVGProps } from 'react'

// export function SwBtcIcon(props: SVGProps<SVGSVGElement>) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="96"
//       height="96"
//       viewBox="0 0 96 96"
//       fill="none"
//     >
//       <rect
//         x="2.5"
//         y="2.5"
//         width="91"
//         height="91"
//         rx="45.5"
//         fill="url(#paint0_linear_9999_12322)"
//       />
//       <rect
//         x="2.5"
//         y="2.5"
//         width="91"
//         height="91"
//         rx="45.5"
//         stroke="url(#paint1_linear_9999_12322)"
//         stroke-width="5"
//       />
//       <path
//         fill-rule="evenodd"
//         clip-rule="evenodd"
//         d="M70.2032 41.2259C71.1928 34.6159 66.1816 31.0851 59.3764 28.6503L61.6142 19.7024L56.1816 18.3057L54.0136 26.9783L49.6811 25.9299L51.9181 17.1887L46.4855 15.792L44.2476 24.7399C43.0785 24.4603 41.9094 24.1807 40.8091 23.9013L33.3133 22.0152L31.8451 27.7969C31.8451 27.7969 35.9029 28.7066 35.7649 28.775C37.9655 29.3337 38.3728 30.7818 38.2994 31.9527L37.0511 36.8682C39.0615 38.9258 42.0338 40.3477 45.968 41.1339L48.141 32.4691C48.4414 32.5437 48.8061 32.6251 49.2181 32.717C53.05 33.5716 60.9795 35.3402 59.4651 41.1197C59.3515 41.5721 59.1945 41.974 59 42.3301C62.8022 42.9322 65.9757 44.094 68.5205 45.8156C69.3389 44.6343 69.9248 43.1227 70.2032 41.2259ZM35.7125 42.1394L35.926 41.2985C36.2449 42.0833 36.7725 42.8419 37.6025 43.4945C40.8201 46.0244 45.8564 47.3522 52.7112 47.4781C57.3116 47.5625 61.062 48.1796 64.1608 49.5729C68.4977 52.1442 70.5636 56.0067 68.3305 62.3686C68.2885 62.4864 68.2457 62.6027 68.2022 62.7174C68.1259 61.5066 67.561 60.0965 65.9715 58.5864C62.0736 54.8833 56.2463 52.779 48.4894 52.2735C42.1819 51.8624 37.404 50.5407 34.1558 48.3083L35.7122 42.2083C35.8153 42.2431 35.9013 42.2607 35.9873 42.2782C36.0733 42.2957 36.1592 42.3132 36.2624 42.348C36.1359 42.263 36.0092 42.2299 35.8985 42.201C35.8289 42.1828 35.7655 42.1662 35.7125 42.1394ZM33.0082 52.8057L32.0768 56.4559C31.7988 57.1439 31.1066 58.1059 29.5936 57.7562C29.6622 57.8253 25.6738 56.7781 25.6738 56.7781L22.9648 62.9688L30.0479 64.7846L30.0481 64.7846C31.3545 65.1336 32.661 65.4826 33.8986 65.8313L31.5917 74.8479L37.0243 76.2446L39.2621 67.2966C40.7749 67.7152 42.1503 68.0645 43.5944 68.4139L41.3568 77.293L46.7895 78.6896L49.0964 69.6731C56.9179 71.1508 63.0858 70.9123 66.6251 65.7843C64.2208 62.5477 61.3048 60.4378 57.5903 59.1277C55.648 64.6431 46.371 62.178 42.0793 61.0376C41.6747 60.93 41.3144 60.8343 41.0087 60.7581L42.1297 56.6498C38.4244 55.9068 35.4854 54.7076 33.3128 53.0521C33.2067 52.9712 33.1052 52.8891 33.0082 52.8057Z"
//         fill="#F7931A"
//       />
//       <defs>
//         <linearGradient
//           id="paint0_linear_9999_12322"
//           x1="48"
//           y1="0"
//           x2="48"
//           y2="96"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stop-color="#090D14" />
//           <stop offset="1" stop-color="#1B2445" />
//         </linearGradient>
//         <linearGradient
//           id="paint1_linear_9999_12322"
//           x1="48"
//           y1="96.0512"
//           x2="48"
//           y2="0.0520929"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stop-color="#F4A94E" />
//           <stop offset="1" stop-color="#F7931A" />
//         </linearGradient>
//       </defs>
//     </svg>
//   )
// }

export const SwBtcIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  (props, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
      >
        <rect
          x="2.5"
          y="2.5"
          width="91"
          height="91"
          rx="45.5"
          fill="url(#paint0_linear_9999_12322)"
        />
        <rect
          x="2.5"
          y="2.5"
          width="91"
          height="91"
          rx="45.5"
          stroke="url(#paint1_linear_9999_12322)"
          stroke-width="5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M70.2032 41.2259C71.1928 34.6159 66.1816 31.0851 59.3764 28.6503L61.6142 19.7024L56.1816 18.3057L54.0136 26.9783L49.6811 25.9299L51.9181 17.1887L46.4855 15.792L44.2476 24.7399C43.0785 24.4603 41.9094 24.1807 40.8091 23.9013L33.3133 22.0152L31.8451 27.7969C31.8451 27.7969 35.9029 28.7066 35.7649 28.775C37.9655 29.3337 38.3728 30.7818 38.2994 31.9527L37.0511 36.8682C39.0615 38.9258 42.0338 40.3477 45.968 41.1339L48.141 32.4691C48.4414 32.5437 48.8061 32.6251 49.2181 32.717C53.05 33.5716 60.9795 35.3402 59.4651 41.1197C59.3515 41.5721 59.1945 41.974 59 42.3301C62.8022 42.9322 65.9757 44.094 68.5205 45.8156C69.3389 44.6343 69.9248 43.1227 70.2032 41.2259ZM35.7125 42.1394L35.926 41.2985C36.2449 42.0833 36.7725 42.8419 37.6025 43.4945C40.8201 46.0244 45.8564 47.3522 52.7112 47.4781C57.3116 47.5625 61.062 48.1796 64.1608 49.5729C68.4977 52.1442 70.5636 56.0067 68.3305 62.3686C68.2885 62.4864 68.2457 62.6027 68.2022 62.7174C68.1259 61.5066 67.561 60.0965 65.9715 58.5864C62.0736 54.8833 56.2463 52.779 48.4894 52.2735C42.1819 51.8624 37.404 50.5407 34.1558 48.3083L35.7122 42.2083C35.8153 42.2431 35.9013 42.2607 35.9873 42.2782C36.0733 42.2957 36.1592 42.3132 36.2624 42.348C36.1359 42.263 36.0092 42.2299 35.8985 42.201C35.8289 42.1828 35.7655 42.1662 35.7125 42.1394ZM33.0082 52.8057L32.0768 56.4559C31.7988 57.1439 31.1066 58.1059 29.5936 57.7562C29.6622 57.8253 25.6738 56.7781 25.6738 56.7781L22.9648 62.9688L30.0479 64.7846L30.0481 64.7846C31.3545 65.1336 32.661 65.4826 33.8986 65.8313L31.5917 74.8479L37.0243 76.2446L39.2621 67.2966C40.7749 67.7152 42.1503 68.0645 43.5944 68.4139L41.3568 77.293L46.7895 78.6896L49.0964 69.6731C56.9179 71.1508 63.0858 70.9123 66.6251 65.7843C64.2208 62.5477 61.3048 60.4378 57.5903 59.1277C55.648 64.6431 46.371 62.178 42.0793 61.0376C41.6747 60.93 41.3144 60.8343 41.0087 60.7581L42.1297 56.6498C38.4244 55.9068 35.4854 54.7076 33.3128 53.0521C33.2067 52.9712 33.1052 52.8891 33.0082 52.8057Z"
          fill="#F7931A"
        />
        <defs>
          <linearGradient
            id="paint0_linear_9999_12322"
            x1="48"
            y1="0"
            x2="48"
            y2="96"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#090D14" />
            <stop offset="1" stop-color="#1B2445" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_9999_12322"
            x1="48"
            y1="96.0512"
            x2="48"
            y2="0.0520929"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F4A94E" />
            <stop offset="1" stop-color="#F7931A" />
          </linearGradient>
        </defs>
      </svg>
    )
  }
)
