// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/rates.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.EthUsdRequest
 */
export class EthUsdRequest extends Message<EthUsdRequest> {
  constructor(data?: PartialMessage<EthUsdRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EthUsdRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EthUsdRequest {
    return new EthUsdRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EthUsdRequest {
    return new EthUsdRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EthUsdRequest {
    return new EthUsdRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EthUsdRequest | PlainMessage<EthUsdRequest> | undefined, b: EthUsdRequest | PlainMessage<EthUsdRequest> | undefined): boolean {
    return proto3.util.equals(EthUsdRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.SwethEthRequest
 */
export class SwethEthRequest extends Message<SwethEthRequest> {
  constructor(data?: PartialMessage<SwethEthRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.SwethEthRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SwethEthRequest {
    return new SwethEthRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SwethEthRequest {
    return new SwethEthRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SwethEthRequest {
    return new SwethEthRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SwethEthRequest | PlainMessage<SwethEthRequest> | undefined, b: SwethEthRequest | PlainMessage<SwethEthRequest> | undefined): boolean {
    return proto3.util.equals(SwethEthRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.EthUsdResponse
 */
export class EthUsdResponse extends Message<EthUsdResponse> {
  /**
   * @generated from field: int64 time = 1;
   */
  time = protoInt64.zero;

  /**
   * @generated from field: string rate = 2;
   */
  rate = "";

  constructor(data?: PartialMessage<EthUsdResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EthUsdResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EthUsdResponse {
    return new EthUsdResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EthUsdResponse {
    return new EthUsdResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EthUsdResponse {
    return new EthUsdResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EthUsdResponse | PlainMessage<EthUsdResponse> | undefined, b: EthUsdResponse | PlainMessage<EthUsdResponse> | undefined): boolean {
    return proto3.util.equals(EthUsdResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.SwethEthResponse
 */
export class SwethEthResponse extends Message<SwethEthResponse> {
  /**
   * @generated from field: int64 time = 1;
   */
  time = protoInt64.zero;

  /**
   * @generated from field: string rate = 2;
   */
  rate = "";

  constructor(data?: PartialMessage<SwethEthResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.SwethEthResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SwethEthResponse {
    return new SwethEthResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SwethEthResponse {
    return new SwethEthResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SwethEthResponse {
    return new SwethEthResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SwethEthResponse | PlainMessage<SwethEthResponse> | undefined, b: SwethEthResponse | PlainMessage<SwethEthResponse> | undefined): boolean {
    return proto3.util.equals(SwethEthResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.Reprice
 */
export class Reprice extends Message<Reprice> {
  /**
   * @generated from field: int64 time = 1;
   */
  time = protoInt64.zero;

  /**
   * It'll be a small decimal ratio, something like 0.95 or 1.05
   *
   * @generated from field: string rate = 2;
   */
  rate = "";

  /**
   * @generated from field: string weth_usdc = 3;
   */
  wethUsdc = "";

  /**
   * @generated from field: int64 block_number = 4;
   */
  blockNumber = protoInt64.zero;

  constructor(data?: PartialMessage<Reprice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.Reprice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "weth_usdc", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "block_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reprice {
    return new Reprice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reprice {
    return new Reprice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reprice {
    return new Reprice().fromJsonString(jsonString, options);
  }

  static equals(a: Reprice | PlainMessage<Reprice> | undefined, b: Reprice | PlainMessage<Reprice> | undefined): boolean {
    return proto3.util.equals(Reprice, a, b);
  }
}

/**
 * Request repricing data
 *
 * @generated from message swell.v3.RepricingHistoryRequest
 */
export class RepricingHistoryRequest extends Message<RepricingHistoryRequest> {
  constructor(data?: PartialMessage<RepricingHistoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RepricingHistoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RepricingHistoryRequest {
    return new RepricingHistoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RepricingHistoryRequest {
    return new RepricingHistoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RepricingHistoryRequest {
    return new RepricingHistoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RepricingHistoryRequest | PlainMessage<RepricingHistoryRequest> | undefined, b: RepricingHistoryRequest | PlainMessage<RepricingHistoryRequest> | undefined): boolean {
    return proto3.util.equals(RepricingHistoryRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.RepricingHistoryResponse
 */
export class RepricingHistoryResponse extends Message<RepricingHistoryResponse> {
  /**
   * @generated from field: repeated swell.v3.Reprice prices = 1;
   */
  prices: Reprice[] = [];

  constructor(data?: PartialMessage<RepricingHistoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RepricingHistoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "prices", kind: "message", T: Reprice, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RepricingHistoryResponse {
    return new RepricingHistoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RepricingHistoryResponse {
    return new RepricingHistoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RepricingHistoryResponse {
    return new RepricingHistoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RepricingHistoryResponse | PlainMessage<RepricingHistoryResponse> | undefined, b: RepricingHistoryResponse | PlainMessage<RepricingHistoryResponse> | undefined): boolean {
    return proto3.util.equals(RepricingHistoryResponse, a, b);
  }
}

