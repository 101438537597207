import axios from 'axios'

type RedstoneRes = [
  {
    value: number
  }
]
type PriceRes = { rate: number }

class _RedstoneService {
  swEthUsdPrice = async () => {
    let raw: RedstoneRes
    try {
      raw = await axios
        .get(
          'https://api.redstone.finance/prices?symbol=SWETH&provider=redstone&limit=1'
        )
        .then((res) => res.data)
    } catch (err) {
      console.error({ err }, 'fetch swETH price from redstone')
      throw err
    }

    return { rate: raw[0].value } as PriceRes
  }

  swEthEthPrice = async () => {
    let raw: RedstoneRes
    try {
      raw = await axios
        .get(
          'https://api.redstone.finance/prices?symbol=SWETH%2FETH&provider=redstone&limit=1'
        )
        .then((res) => res.data)
    } catch (err) {
      console.error({ err }, 'fetch swETH/ETH price from redstone')
      throw err
    }

    return { rate: raw[0].value } as PriceRes
  }
}

export const RedstoneService = new _RedstoneService()
