import styled from 'styled-components/macro'

export const TitleTypography = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */

  letter-spacing: 1.05px;
  text-transform: uppercase;
  background: linear-gradient(90deg, #845aff 0%, #4943e0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
