import { getChainTitleName } from '@/constants/chains'
import useChainDetection from '@/hooks/useChainDetection'
import { useGlobalNotification } from '@/swell-ui/GlobalNotification'
import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

const FakeLink = styled.span`
  /* Same color as link text */
  color: ${(props) => props.theme.colors.lightBlue[50]};
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.alert.success.linkHoverColor};
  }
`

interface IWrongChainDetectionProps {
  children?: React.ReactNode
}

const WrongChainDetection: React.FC<IWrongChainDetectionProps> = () => {
  const { notify, removeNotification } = useGlobalNotification()

  const {
    isWrongChainIdDebounced,
    deploymentChainId,
    switchToDeploymentChain,
  } = useChainDetection()

  useEffect(() => {
    // use debounced chainId to prevent warning from appearing quickly in transitionary states
    if (isWrongChainIdDebounced) {
      const id = notify(
        <span>
          Chain not supported.{' '}
          <FakeLink role="button" onClick={switchToDeploymentChain}>
            Please switch to {getChainTitleName(deploymentChainId)} to continue
            to use the app.
          </FakeLink>
        </span>,
        'error'
      )
      return () => removeNotification(id)
    }
  }, [
    notify,
    removeNotification,
    deploymentChainId,
    isWrongChainIdDebounced,
    switchToDeploymentChain,
  ])

  return null
}

export default WrongChainDetection
