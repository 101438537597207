import { ChainInfo, getChainInfo } from '@/constants/chainInfo'
import useChainDetection from '@/hooks/useChainDetection'

export const useChainInfo = (): ChainInfo => {
  const {
    isTestnetChain,
    deploymentChainId,
    allowTestnet,
    chainId: currentChainId,
  } = useChainDetection()

  let chainId = deploymentChainId
  if (isTestnetChain && allowTestnet) {
    chainId = currentChainId
  }

  return getChainInfo(chainId)
}

export default useChainInfo
