import { BigNumber, ethers } from 'ethers'
import { IPreDepositZap } from '../zap/IPredepositZap'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useState } from 'react'
import { getPreDepositZapGasEstimate } from '@/constants/gasEstimates'
import { calculateGasMargin } from '@/util/calculateGasMargin'
import { Token } from '@/types/tokens'
import { formatUnits } from 'ethers/lib/utils'

export interface ZapTransactionResponse {
  tx?: any
  error?: {
    message: string
  }
}

export interface UseZap {
  isLoading: boolean
  clear: () => void
  sendTransaction: (
    token: Token,
    amount: BigNumber
  ) => Promise<ZapTransactionResponse>
  setStatus: (status: string) => void
  status: string
  STATUS: {
    [key: string]: string
  }
  amount: BigNumber
  token: Token | undefined
  tx: any
}

export function usePreDepositZap(zap: IPreDepositZap | undefined): UseZap {
  const { account } = useSwellWeb3()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [amount, setAmount] = useState<BigNumber>(BigNumber.from(0))
  const [token, setToken] = useState<Token | undefined>(undefined)

  const STATUS = {
    IDLE: 'idle',
    PROMPTING: 'prompting',
    PENDING: 'pending',
    FULFILLED: 'fulfilled',
    ERROR: 'error',
  }
  const [status, setStatus] = useState<string>(STATUS.IDLE)
  const [tx, setTx] = useState<any>(undefined)

  const clear = () => {
    setStatus(STATUS.IDLE)
    setTx(undefined)
    setAmount(BigNumber.from(0))
    setToken(undefined)
  }

  const sendTransaction = async (token: Token, amount: BigNumber) => {
    if (!zap) {
      return {
        error: { message: 'No zap.' },
      }
    }

    if (!account) {
      return {
        error: { message: 'No account.' },
      }
    }

    if (!amount) {
      return {
        error: { message: 'No amount specified.' },
      }
    }

    if (!token) {
      return {
        error: { message: 'No token specified.' },
      }
    }

    setIsLoading(true)
    setAmount(amount)
    setToken(token)

    let tx
    try {
      const gasLimitLowest = getPreDepositZapGasEstimate()
      const gasLimitEst = await zap.zapInEstimateGas(amount)
      let gasLimit = gasLimitLowest
      if (gasLimitEst.gt(gasLimitLowest)) {
        gasLimit = gasLimitEst
      }

      setStatus(STATUS.PROMPTING)

      const tx = await zap.zapIn(amount, {
        gasLimit: calculateGasMargin(gasLimit),
      })
      setTx(tx)
      setStatus(STATUS.PENDING)
      await tx.wait()

      setIsLoading(false)
      setStatus(STATUS.FULFILLED)
      return {
        tx: tx,
      }
    } catch (error: any) {
      setIsLoading(false)
      setStatus(STATUS.IDLE)
      console.error('zap in:', error)
      return {
        error: { message: error.reason || 'Unknown error' },
      }
    }
  }

  return {
    isLoading,
    clear,
    sendTransaction,
    setStatus,
    status,
    STATUS,
    tx,
    amount,
    token,
  }
}
