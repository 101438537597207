/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  SimpleStakingERC20,
  SimpleStakingERC20Interface,
} from "../SimpleStakingERC20";

const _abi = [
  {
    type: "constructor",
    inputs: [
      {
        name: "_owner",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "acceptOwnership",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "deposit",
    inputs: [
      {
        name: "_token",
        type: "address",
        internalType: "contract ERC20",
      },
      {
        name: "_amount",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "_receiver",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "owner",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "pendingOwner",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "renounceOwnership",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "rescueERC20",
    inputs: [
      {
        name: "_token",
        type: "address",
        internalType: "contract ERC20",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "stakedBalances",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
      {
        name: "",
        type: "address",
        internalType: "contract ERC20",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "supportToken",
    inputs: [
      {
        name: "_token",
        type: "address",
        internalType: "contract ERC20",
      },
      {
        name: "_supported",
        type: "tuple",
        internalType: "struct ISimpleStakingERC20.Supported",
        components: [
          {
            name: "deposit",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "withdraw",
            type: "bool",
            internalType: "bool",
          },
        ],
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "supportedTokens",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "contract ERC20",
      },
    ],
    outputs: [
      {
        name: "deposit",
        type: "bool",
        internalType: "bool",
      },
      {
        name: "withdraw",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "totalStakedBalance",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "contract ERC20",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "transferOwnership",
    inputs: [
      {
        name: "newOwner",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "withdraw",
    inputs: [
      {
        name: "_token",
        type: "address",
        internalType: "contract ERC20",
      },
      {
        name: "_amount",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "_receiver",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "event",
    name: "Deposit",
    inputs: [
      {
        name: "token",
        type: "address",
        indexed: true,
        internalType: "contract ERC20",
      },
      {
        name: "staker",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferStarted",
    inputs: [
      {
        name: "previousOwner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "newOwner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        name: "previousOwner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "newOwner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "SupportedToken",
    inputs: [
      {
        name: "token",
        type: "address",
        indexed: true,
        internalType: "contract ERC20",
      },
      {
        name: "supported",
        type: "tuple",
        indexed: false,
        internalType: "struct ISimpleStakingERC20.Supported",
        components: [
          {
            name: "deposit",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "withdraw",
            type: "bool",
            internalType: "bool",
          },
        ],
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Withdraw",
    inputs: [
      {
        name: "token",
        type: "address",
        indexed: true,
        internalType: "contract ERC20",
      },
      {
        name: "staker",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "error",
    name: "ADDRESS_NULL",
    inputs: [],
  },
  {
    type: "error",
    name: "AMOUNT_NULL",
    inputs: [],
  },
  {
    type: "error",
    name: "OwnableInvalidOwner",
    inputs: [
      {
        name: "owner",
        type: "address",
        internalType: "address",
      },
    ],
  },
  {
    type: "error",
    name: "OwnableUnauthorizedAccount",
    inputs: [
      {
        name: "account",
        type: "address",
        internalType: "address",
      },
    ],
  },
  {
    type: "error",
    name: "TOKEN_NOT_ALLOWED",
    inputs: [
      {
        name: "token",
        type: "address",
        internalType: "contract ERC20",
      },
    ],
  },
] as const;

export class SimpleStakingERC20__factory {
  static readonly abi = _abi;
  static createInterface(): SimpleStakingERC20Interface {
    return new utils.Interface(_abi) as SimpleStakingERC20Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): SimpleStakingERC20 {
    return new Contract(address, _abi, signerOrProvider) as SimpleStakingERC20;
  }
}
