import React from 'react'
import styled from 'styled-components/macro'
import startCase from 'lodash/startCase'
import { DepositSubmissionStatus } from '@/state/depositSubmission/models/DepositSubmissionDetails'

interface ISubmissionStatusIndicatorProps {
  // unknown is a special case when viewing a historic transaction which was submitted, but has
  // no transaction id
  status: DepositSubmissionStatus | 'unknown'
}

// TODO: styling
// Renders a colored dot + status text for a submitted validator key chunk
const SubmissionStatusIndicator: React.FC<ISubmissionStatusIndicatorProps> = ({
  status,
}) => {
  return <Layout status={status}>{startCase(status)}</Layout>
}

export default SubmissionStatusIndicator

const Layout = styled.span<ISubmissionStatusIndicatorProps>`
  display: flex;
  align-items: center;

  ::before {
    content: '';

    display: block;

    margin-right: 0.5em;

    width: 4px;
    height: 4px;
    border-radius: 50%;

    background-color: ${(props) => {
      switch (props.status) {
        case DepositSubmissionStatus.ERROR:
          return 'red'
        case DepositSubmissionStatus.EXTERNALLY_MANAGED:
          return 'cyan'
        case DepositSubmissionStatus.PENDING:
          return 'white'
        case DepositSubmissionStatus.NOT_STARTED:
          return 'grey'
        case DepositSubmissionStatus.PROMPTING:
          return 'orange'
        case DepositSubmissionStatus.SUBMITTED:
          return 'green'
        case 'unknown':
          return 'purple'

        default:
          throw new Error(`invalid props.status: ${props.status}`)
      }
    }};
  }
`
