import { FlexRow } from '@/swell-ui/FlexRow'
import styled from 'styled-components'
import { yearnWithdrawTypographyCSS } from './common'
import { Divider } from '@/swell-ui/Divider'
import { YearnWithdrawAsset } from '@/state/yearnVault/types'

export function YearnWithdrawExpired({
  withdrawAsset,
}: {
  withdrawAsset: YearnWithdrawAsset | undefined
}) {
  const claimWindowStr = '0d 0h 0m'

  let withdrawalDurationStr = ''
  if (withdrawAsset) {
    const durationDays = Math.floor(
      withdrawAsset.completionWindowSeconds / 86400
    )

    if (durationDays > 1) {
      withdrawalDurationStr = `${durationDays} days`
    } else {
      withdrawalDurationStr = `1 day`
    }
  }

  return (
    <Layout>
      <FlexRow direction="column" gap="12" align="center">
        <Warning />
        <span className="title">Claim window lapsed</span>
        <FlexRow
          direction="column"
          gap="8"
          align="center"
          justify="center"
          style={{ visibility: withdrawalDurationStr ? 'visible' : 'hidden' }}
        >
          <p>
            You did not claim your withdrawal within {withdrawalDurationStr}. If
            you wish to withdraw please try again.
          </p>
        </FlexRow>
      </FlexRow>
      <FlexRow
        direction="column"
        gap="8"
        align="center"
        justify="center"
        style={{ marginTop: 'auto' }}
      >
        <span className="subheading">Time window to claim</span>
        <span className="body">{claimWindowStr}</span>
        <span className="body hidden">-</span>
      </FlexRow>
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  padding-top: 50px;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
  ${yearnWithdrawTypographyCSS}

  > div:first-child {
    flex-grow: 1;
  }
  ${Divider} {
    margin: 0;
    margin-top: 12px;
    margin-bottom: 8px;
  }
  > p {
    margin: 0;
    margin-bottom: -12px;
  }
  .hidden {
    visibility: hidden;
  }
`

function Warning() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="51"
      viewBox="0 0 52 51"
      fill="none"
    >
      <path
        d="M26 0.145844C21.0184 0.145844 16.1486 1.62306 12.0066 4.3907C7.86452 7.15834 4.63618 11.0921 2.7298 15.6945C0.823413 20.2969 0.324617 25.3613 1.29648 30.2472C2.26835 35.1331 4.66722 39.6211 8.18976 43.1436C11.7123 46.6661 16.2003 49.065 21.0862 50.0369C25.9721 51.0087 31.0364 50.5099 35.6388 48.6036C40.2413 46.6972 44.175 43.4688 46.9427 39.3268C49.7103 35.1847 51.1875 30.315 51.1875 25.3333C51.1805 18.6554 48.5245 12.2529 43.8025 7.53088C39.0804 2.80884 32.678 0.152896 26 0.145844ZM24.0625 13.7083C24.0625 13.1945 24.2666 12.7017 24.63 12.3383C24.9933 11.975 25.4862 11.7708 26 11.7708C26.5139 11.7708 27.0067 11.975 27.37 12.3383C27.7334 12.7017 27.9375 13.1945 27.9375 13.7083V27.2708C27.9375 27.7847 27.7334 28.2775 27.37 28.6409C27.0067 29.0042 26.5139 29.2083 26 29.2083C25.4862 29.2083 24.9933 29.0042 24.63 28.6409C24.2666 28.2775 24.0625 27.7847 24.0625 27.2708V13.7083ZM26 38.8958C25.4252 38.8958 24.8633 38.7254 24.3854 38.4061C23.9075 38.0867 23.535 37.6328 23.315 37.1018C23.095 36.5707 23.0375 35.9864 23.1496 35.4226C23.2617 34.8589 23.5385 34.341 23.945 33.9346C24.3514 33.5281 24.8693 33.2513 25.433 33.1392C25.9968 33.027 26.5811 33.0846 27.1122 33.3046C27.6432 33.5245 28.0971 33.897 28.4165 34.375C28.7358 34.8529 28.9063 35.4148 28.9063 35.9896C28.9063 36.7604 28.6001 37.4996 28.055 38.0446C27.51 38.5896 26.7708 38.8958 26 38.8958Z"
        fill="#F7931A"
      />
    </svg>
  )
}
