import React from 'react'
import styled from 'styled-components/macro'
import { Box } from '@swell-ui/Box'
import { Typography } from '@swell-ui/Typography'

const ClockBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  gap: 12.27px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(12.2672px);
  border-radius: 29.4413px;
  margin: 0 auto;
  margin-top: -30px;
  max-width: 891px;
  border: none;

  @media (max-width: 360px) {
    padding: 30px 10px;
  }
`

const FlavorText = styled(Typography)`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  letter-spacing: 0.075em;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin-left: 8px;

  a {
    text-decoration: underline !important;
  }
`

const HERO_TITLE = (
  <>
    Snapshot taken at block number:{' '}
    <a
      href="https://etherscan.io/block/20422895"
      target="_blank"
      rel="noopener noreferrer"
    >
      20422895
    </a>{' '}
    (~30 July 2024 23:59:59)
  </>
)

function VoyageHero() {
  return (
    <ClockBox>
      <FlavorText variant="body" size="medium">
        {HERO_TITLE}
      </FlavorText>
    </ClockBox>
  )
}

export { VoyageHero }
