import React from 'react'
import styled from 'styled-components'
import { TextInput, TextFieldProps } from '../TextInput'

const StyledTextInput = styled(TextInput)`
  .MuiInputBase-input.Mui-disabled {
    opacity: 0.8;

    &:hover {
      cursor: not-allowed;
    }
  }
`

function SwEthInput(props: TextFieldProps) {
  function handleKeyDown(event: any) {
    if (
      event.key === 'e' ||
      event.key === 'E' ||
      event.key === '-' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      // IE/Edge need 'Up' and 'Down'
      event.key === 'Up' ||
      event.key === 'Down'
    ) {
      event.preventDefault()
    }
  }

  return (
    <>
      <StyledTextInput type="number" onKeyDown={handleKeyDown} {...props} />
    </>
  )
}

export { SwEthInput }
