import React from 'react'

function HamburgerIcon(props: any) {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M8.80005 11.2H23.2V12.8H8.80005V11.2ZM8.80005 15.2H23.2V16.8H8.80005V15.2ZM8.80005 19.2H23.2V20.8H8.80005V19.2Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export { HamburgerIcon }
