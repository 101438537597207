import React from 'react'
import styled from 'styled-components/macro'
import { FlexRow } from '@swell-ui/FlexRow'
import { Tooltip } from '@swell-ui/Tooltip'
import dropShadowEllipseUrl from '@/assets/images/voyage-map/drop-shadow-ellipse.svg'
import pearlUrl from '@/assets/images/voyage-map/pearl.svg'
import stonePedestalUrl from '@/assets/images/voyage-map/stone-pedestal.png'

const MapItemWrapper = styled.div`
  position: relative;
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;

  a {
    height: 75px;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: transform;

    &:hover,
    &:focus,
    &:active {
      -webkit-transform: scale(1.1) rotate(10deg);
      transform: scale(1.1) rotate(10deg);
      cursor: pointer;
    }
  }

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      width: 82.5px;

      a {
        height: 36.35px;
      }
    }
  `}
`

const PearlsContainer = styled(FlexRow)`
  height: 30px;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      height: 15px;
    }
  `}
`

const PearlPlaceholder = styled.div<any>`
  width: 20px;
  height: 20px;
  border: 1px solid #dbc7bc;
  border-radius: 11px;
  margin-top: 10px;

  ${({ size }) =>
    size === 'large'
      ? `
    width: 25px;
    height: 25px;
    border-radius: 15px;
    margin-top: 0px;
  `
      : ``}

  ${({ theme, size }) => `
      ${theme.breakpoints.down('md')} {
        width: 10px;
        height: 10px;
        margin-top: 4px;

        ${
          size === 'large'
            ? `
          width: 12px;
          height: 12px;
          border-radius: 15px;
          margin-top: 0px;
        `
            : ``
        }
      }
    `}
`

const Pearl = styled.img<any>`
  height: 20px;
  width: 20px;
  margin-top: 10px;

  ${({ size }) =>
    size === 'large'
      ? `
    width: 25px;
    height: 25px;
    border-radius: 15px;
    margin-top: 0px;
  `
      : ``}

  ${({ theme, size }) => `
    ${theme.breakpoints.down('md')} {
      width: 10px;
      height: 10px;
      margin-top: 4px;

      ${
        size === 'large'
          ? `
        width: 12px;
        height: 12px;
        border-radius: 15px;
        margin-top: 0px;
      `
          : ``
      }
    }
  `}
`

const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.075em;
  text-align: center;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      font-size: 10px;
      line-height: 16px;
    }
  `}
`

const Img = styled.img`
  height: 75px;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      height: 36.35px;
    }
  `}
`

const Pedestal = styled.div`
  position: absolute;
  top: 77px;
  z-index: -2;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      top: 37px;
    }
  `}
`

const Rock = styled.img`
  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      height: 37px;
    }
  `}
`

const Shadow = styled.img`
  margin-top: -20px;
  position: absolute;
  top: 23px;
  left: 32px;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      top: 28px;
      left: 25px;
      width: 34px;
    }
  `}
`

function MapItem({
  hidePearls,
  href,
  imgUrl,
  label,
  pearlValue,
  tooltip,
}: {
  hidePearls?: boolean
  href?: string
  imgUrl: string
  label?: string
  pearlValue?: number
  tooltip?: string
}) {
  const calculatedPearlValue = pearlValue || 0

  return (
    <MapItemWrapper>
      <PearlsContainer gap="2" align="flex-start" justify="center">
        {!hidePearls && (
          <>
            {calculatedPearlValue > 0 && <Pearl src={pearlUrl} />}
            {calculatedPearlValue > 1 ? (
              <Pearl src={pearlUrl} size="large" />
            ) : (
              <PearlPlaceholder size="large" />
            )}
            {calculatedPearlValue > 2 ? (
              <Pearl src={pearlUrl} />
            ) : (
              <PearlPlaceholder />
            )}
          </>
        )}
      </PearlsContainer>
      {href && (
        <a href={href} target="blank" rel="noreferrer">
          <Img src={imgUrl} />
        </a>
      )}
      {tooltip && (
        <Tooltip title={tooltip} placement="top" arrow={true}>
          <Img src={imgUrl} />
        </Tooltip>
      )}
      {!href && !tooltip && <Img src={imgUrl} />}
      <Label>{label || 'MAVERIC'}</Label>
      <Pedestal>
        <Rock src={stonePedestalUrl} />
        <Shadow src={dropShadowEllipseUrl} />
      </Pedestal>
    </MapItemWrapper>
  )
}

export { MapItem }
