import { keyframes } from 'styled-components'

export const pulseKeyframe = keyframes`
  0% {
    transform: scale3d(1, 1, 1);
  }

  16% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  33% {
    transform: scale3d(1, 1, 1);
  }
`
