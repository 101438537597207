import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`

function SvgFromString({
  svgString,
  size,
}: {
  svgString: string
  size: number
}) {
  const ref = React.useRef<any>()

  React.useEffect(() => {
    if (!ref.current) {
      return
    }
    ref.current.innerHTML = svgString
  }, [svgString])

  return <Wrapper size={size} ref={ref} />
}

export { SvgFromString }
