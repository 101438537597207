import useSWRImmutable from 'swr/immutable'
import { useRswETHContractView } from '@/hooks/useContract'

export const useRswEthTotalSupply = () => {
  const rswEth = useRswETHContractView()
  return useSWRImmutable(
    rswEth ? ['totalSupply', rswEth.address] : null,
    async () => ({
      totalSupply: await rswEth!.totalSupply(),
    })
  )
}
