import React from 'react'
import styled from 'styled-components/macro'
import first from 'lodash/first'
import last from 'lodash/last'
import { CloseIcon } from '@swell-ui/icons/CloseIcon'
import { Dialog, DialogContent, DialogTitle } from '@/swell-ui/Dialog'
import { FlexRow } from '@swell-ui/FlexRow'
import { shortenHash } from '@/util/hexStrings'
import {
  DepositSubmissionStatus,
  IDepositSubmissionDetails,
} from '@/state/depositSubmission/models/DepositSubmissionDetails'
import { useChainInfo } from '@/state/deployments/hooks'
import { Typography } from '@/swell-ui/Typography'
import { ErrorOutlineIcon } from '@swell-ui/icons'
import { CheckCircleIcon } from '@swell-ui/icons/CheckCircleIcon'

interface IDepositSubmissionDetailModalProps {
  open: boolean
  onClose: () => void
  submissionItem: IDepositSubmissionDetails
}

const ErrorText = styled.span`
  color: ${({ theme }) => theme.colors.red['100']};
`

const StyledCloseIcon = styled(CloseIcon)`
  float: right;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  path {
    stroke-width: 0.666667px;
  }
`

const ModalSection = styled(FlexRow)`
  margin-bottom: 12px;
`

const StyledCheckCircle = styled(CheckCircleIcon)`
  width: 96px;
  height: 96px;
  padding: 8px;

  path {
    stroke: green;
  }
`

const StyledContent = styled(DialogContent)`
  padding: 20px 24px;
  letter-spacing: -0.03em;
  text-align: center;
`

/* TODO: media query to ensure consistent size */
const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 407px;
    margin: 16px;
  }

  .MuiDialogContent-root {
    display: block;
  }

  .MuiButtonBase-root {
    padding: 0;
  }
`

const StyledDialogTitle = styled(DialogTitle)`
  padding-bottom: 0;
`

/**
 * When DepositCollectionSubmissionTable is viewing the status of a finished run, the rows of
 *  the table will be clickable. Clicking on a row will show a modal with information
 *  about the submitted chunk.
 */
const DepositSubmissionDetailModal: React.FC<
  IDepositSubmissionDetailModalProps
> = ({ open, onClose, submissionItem }) => {
  const { chunk, chunkIdx, error, status, txHash } = submissionItem
  const { explorer } = useChainInfo()

  const derived = {
    get heroIcon() {
      switch (status) {
        case DepositSubmissionStatus.EXTERNALLY_MANAGED:
          return <ErrorOutlineIcon sx={{ fontSize: '96px', color: 'white' }} />
        case DepositSubmissionStatus.NOT_STARTED:
          return <ErrorOutlineIcon sx={{ fontSize: '96px', color: 'white' }} />
        case DepositSubmissionStatus.PROMPTING:
          return <ErrorOutlineIcon sx={{ fontSize: '96px', color: 'white' }} />
        case DepositSubmissionStatus.PENDING:
          return <ErrorOutlineIcon sx={{ fontSize: '96px', color: 'white' }} />
        case DepositSubmissionStatus.SUBMITTED:
          return <StyledCheckCircle />
        case DepositSubmissionStatus.ERROR:
          return (
            <ErrorOutlineIcon sx={{ fontSize: '96px', color: '#C32323' }} />
          )

        default:
          throw new Error(`invalid status: ${status}`)
      }
    },
    get pubKeyInfo() {
      // special case: one pubkey
      if (chunk.length === 1) {
        return (
          <Typography variant="body" size="xsmall">
            <span>PubKey: {shortenHash(chunk[0].pubkey)}</span>
          </Typography>
        )
      }

      const [firstItem, lastItem] = [first, last].map(
        (operation) => operation(chunk)!
      )

      const [firstPubKey, lastPubKey] = [firstItem, lastItem].map(
        (i) => i.pubkey
      )

      return (
        <Typography variant="body" size="xsmall">
          <span>From: {shortenHash(firstPubKey)}</span>
          <br />
          <span>To: {shortenHash(lastPubKey)}</span>
        </Typography>
      )
    },
    get content() {
      switch (status) {
        case DepositSubmissionStatus.EXTERNALLY_MANAGED:
          return <span>This transaction is externally managed</span>

        case DepositSubmissionStatus.NOT_STARTED:
          return <span>This transaction was not submitted.</span>

        case DepositSubmissionStatus.PROMPTING:
        case DepositSubmissionStatus.PENDING:
          // i.e. they received a prompt the transaction wasn't confirmed while the app was open
          return <span>This transaction is in an unknown state.</span>

        case DepositSubmissionStatus.SUBMITTED:
          return <span>This transaction was submitted.</span>
        case DepositSubmissionStatus.ERROR:
          return (
            <div>
              <span>This transaction encountered an error:</span>
              <br />
              <ErrorText>{error}</ErrorText>
            </div>
          )

        default:
          throw new Error(`invalid status: ${status}`)
      }
    },
    get scannerLink() {
      if (!txHash) return null

      return (
        <Typography variant="body" size="xsmall">
          <span>Txn: {shortenHash(txHash)}</span>&nbsp; (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${explorer}/tx/${txHash}`}
          >
            View on Etherscan
          </a>
          )
        </Typography>
      )
    },
  }

  return (
    <StyledDialog open={open}>
      <StyledDialogTitle>
        <StyledCloseIcon onClick={onClose} />
      </StyledDialogTitle>
      <StyledContent>
        <div>{derived.heroIcon}</div>
        <ModalSection justify="center">
          <Typography variant="body" size="large">
            Transaction #{chunkIdx + 1}
          </Typography>
        </ModalSection>
        <ModalSection justify="center">{derived.content}</ModalSection>
        <ModalSection justify="center">{derived.pubKeyInfo}</ModalSection>
        {derived.scannerLink && (
          <FlexRow justify="center">{derived.scannerLink}</FlexRow>
        )}
      </StyledContent>
    </StyledDialog>
  )
}

export { DepositSubmissionDetailModal }
