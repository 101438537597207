import ethereumLogoUrl from '../assets/images/ethereum-logo.png'
import ms from 'ms.macro'
import { ChainId, SupportedChainId } from './chains'
import arbitrumLogoUrl from '@/assets/images/chains/arbitrum-64x64.png'
import basechainLogoUrl from '@/assets/images/chains/basechain-64x64.png'
import blastLogoUrl from '@/assets/images/chains/blast-64x64.png'
import optimismLogoUrl from '@/assets/images/chains/optimism-64x64.png'
import starknetLogoUrl from '@/assets/images/chains/starknet-64x64.png'
import zksyncLogoUrl from '@/assets/images/chains/zksync-64x64.png'
import berachainLogoUrl from '@/assets/images/chains/berachain-64x64.png'
import monadLogoUrl from '@/assets/images/chains/monad-64x64.png'
import zircuitLogoUrl from '@/assets/images/chains/zircuit-64x64.png'
import fraxtalLogoUrl from '@/assets/images/chains/fraxtal-64x64.png'
import corechainLogoUrl from '@/assets/images/chains/corechain-64x64.png'

export const AVERAGE_L1_BLOCK_TIME = ms`12s`

export interface CurrencyMinimal {
  name: string // e.g. 'Goerli ETH',
  symbol: string // e.g. 'gorETH',
  decimals: number // e.g. 18,
}

export interface ChainInfo {
  readonly explorer: string
  readonly logoUrl: string
  readonly label: string
  readonly nativeCurrency: CurrencyMinimal
}

type ChainInfoMap = {
  readonly [chainId: number]: ChainInfo
}

const CHAIN_INFO: ChainInfoMap = {
  [SupportedChainId.HARDHAT]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.MAINNET]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.GOERLI]: {
    explorer: 'https://goerli.etherscan.io/',
    label: 'Goerli',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Goerli Ether', symbol: 'goETH', decimals: 18 },
  },
  [SupportedChainId.SEPOLIA]: {
    explorer: 'https://sepolia.etherscan.io/',
    label: 'Sepolia',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Sepolia Ether', symbol: 'sepETH', decimals: 18 },
  },
  [SupportedChainId.HOLESKY]: {
    explorer: 'https://holesky.etherscan.io/',
    label: 'Holesky',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Holesky Ether', symbol: 'holETH', decimals: 18 },
  },
}

export function getChainInfo(chainId: SupportedChainId): ChainInfo

export function getChainInfo(chainId: SupportedChainId): ChainInfo {
  return CHAIN_INFO[chainId]
}

export function getChainIcon(chainId: number) {
  let uri = getChainInfo(chainId)?.logoUrl
  let shape: 'circle' | 'square' = 'circle'

  if (!uri) {
    switch (chainId) {
      case ChainId.ARBITRUM_ONE:
        uri = arbitrumLogoUrl
        break
      case ChainId.BASE:
        uri = basechainLogoUrl
        break
      case ChainId.BLAST:
        uri = blastLogoUrl
        break
      case ChainId.OPTIMISM:
        uri = optimismLogoUrl
        break
      case ChainId.STARKNET:
        uri = starknetLogoUrl
        break
      case ChainId.ZKSYNC:
        uri = zksyncLogoUrl
        break
      case ChainId.BERACHAIN:
        uri = berachainLogoUrl
        break
      case ChainId.MONAD:
        uri = monadLogoUrl
        break
      case ChainId.ZIRCUIT_TESTNET:
      case ChainId.ZIRCUIT:
        uri = zircuitLogoUrl
        break
      case ChainId.FRAXTAL:
        uri = fraxtalLogoUrl
        break
      case ChainId.CORE:
        uri = corechainLogoUrl
        break
      default:
        uri = ethereumLogoUrl
        shape = 'square'
        break
    }
  }

  return { uri, shape }
}
