import { Token } from '@/types/tokens'
import { YearnVaultContext } from './context'
import {
  IYearnVaultApiRead,
  IYearnVaultApiWrite,
  YearnVaultStats,
  YearnAllowances,
  YearnBalances,
  YearnTokenRates,
  YearnAuthResult,
  YearnAssets,
  YearnPausedResult,
  YearnWithdrawRequestResult,
} from './types'
import {
  Multicall3,
  YearnDelayedWithdraw,
  YearnDelayedWithdraw__factory,
  YearnV3Vault,
  YearnV3Vault__factory,
} from '@/abis/types'
import {
  useIERC20Contract,
  useMulticallContract,
  useYearnDelayedWithdrawContract,
  useYearnV3VaultContract,
} from '@/hooks/useContract'
import { useSwellWeb3 } from '@/swell-web3/core'
import { BigNumber } from 'ethers'
import { SolmateRolesAuthorityService } from '@/services/SolmateRolesAuthority'
import { TokenMulticalls } from '@/services/Tokens'

const REQUEST_WITHDRAW_SIGNATURE = '0xb75fa7b3' // keccak256("requestWithdraw(address,uint96,uint16,bool)")
const COMPLETE_WITHDRAW_SIGNATURE = '0x4953cdbe' // keccak256("completeWithdraw(address,address)")
const CANCEL_WITHDRAW_SIGNATURE = '0xe9919629' // keccak256("cancelWithdraw(address)")

const ALLOW_THIRD_PARTY_TO_COMPLETE = false
const DEFAULT_COMPLETION_WINDOW_SECONDS = 60 * 60 * 24 * 7 // 7 days

export interface IRateFetcher {
  getRate: () => Promise<BigNumber>
}
export interface IYearnVaultStatsFetcher {
  stats: () => Promise<{ totalDepositors: number; apy: number }>
}

export function useYearnVaultImpl({
  vaultToken,
  depositAsset,
  delayedWithdrawalsAddress,
  rolesAuthorityAddress,
  depositAssetRateFetcher,
  statsFetcher,
}: {
  vaultToken: Token
  depositAsset: Token
  delayedWithdrawalsAddress: string
  rolesAuthorityAddress: string
  depositAssetRateFetcher: IRateFetcher
  statsFetcher: IYearnVaultStatsFetcher
}): YearnVaultContext {
  return {
    read: useYearnVaultReadApi({
      delayedWithdrawalsAddress,
      depositAsset,
      vaultToken,
      rolesAuthorityAddress,
      depositAssetRateFetcher,
      statsFetcher,
    }),
    write: useYearnVaultWriteImpl({
      delayedWithdrawalsAddress,
      depositAsset,
      vaultToken,
    }),
    depositAsset,
    vaultToken,
  }
}

async function fetchSupportedAssets(
  delayedWithdrawal: YearnDelayedWithdraw,
  depositAsset: Token
): Promise<YearnAssets> {
  const withdrawAssetStruct = await delayedWithdrawal.withdrawAssets(
    depositAsset.address
  )

  const allowWithdraws = withdrawAssetStruct.allowWithdraws
  let completionWindowSeconds = withdrawAssetStruct.completionWindow
  if (completionWindowSeconds === 0) {
    completionWindowSeconds = DEFAULT_COMPLETION_WINDOW_SECONDS
  }
  const maxLossBasisPoints = withdrawAssetStruct.maxLoss
  const withdrawDelaySeconds = withdrawAssetStruct.withdrawDelay
  const withdrawFeeBasisPoints = withdrawAssetStruct.withdrawFee

  return {
    depositAsset,
    withdrawAsset: {
      ...depositAsset,
      allowWithdraws,
      completionWindowSeconds,
      maxLossBasisPoints,
      withdrawDelaySeconds,
      withdrawFeeBasisPoints,
    },
  }
}

async function fetchAllowances(
  multicall: Multicall3,
  vaultTokenAddress: string,
  depositAssetAddress: string,
  delayedWithdrawalsAddress: string,
  account: string
): Promise<YearnAllowances> {
  const tmc = new TokenMulticalls(multicall)

  const [
    { allowance: depositAssetForVault },
    { allowance: vaultTokenForWithdrawals },
  ] = await tmc.fetchAllowances(
    [
      {
        spender: vaultTokenAddress,
        token: depositAssetAddress,
      },
      {
        spender: delayedWithdrawalsAddress,
        token: vaultTokenAddress,
      },
    ],
    account
  )

  return {
    depositAssetForVault,
    vaultTokenForWithdrawals,
  }
}

async function fetchBalances(
  multicall: Multicall3,
  vaultTokenAddress: string,
  depositAssetAddress: string,
  account: string
): Promise<YearnBalances> {
  const tmc = new TokenMulticalls(multicall)

  const [{ balance: vaultTokenBalance }, { balance: depositAssetBalance }] =
    await tmc.fetchBalances([vaultTokenAddress, depositAssetAddress], account)

  return {
    vaultToken: vaultTokenBalance,
    depositAsset: depositAssetBalance,
  }
}

async function fetchVaultStats(
  statsFetcher: IYearnVaultStatsFetcher,
  yearnVault: YearnV3Vault
): Promise<YearnVaultStats> {
  const totalAssets = await yearnVault.totalAssets()
  const { apy: apyPercent, totalDepositors } = await statsFetcher.stats()
  return {
    apyPercent,
    totalAssets,
    totalDepositors,
  }
}

async function fetchYearnVaultRates(
  yearnVault: YearnV3Vault,
  depositAssetRateFetcher: IRateFetcher
): Promise<YearnTokenRates> {
  const pricePerShareP = yearnVault.pricePerShare()
  const depositAssetEthRateP = depositAssetRateFetcher.getRate()
  const pricePerShare = await pricePerShareP
  const depositAssetEthRate = await depositAssetEthRateP

  return {
    depositAssetEthRate,
    pricePerShare,
  }
}

async function fetchYearnAuth(
  multicall: Multicall3,
  rolesAuthorityAddress: string,
  delayedWithdrawalsAddress: string,
  account: string
): Promise<YearnAuthResult> {
  const rolesAuth = new SolmateRolesAuthorityService(
    multicall,
    rolesAuthorityAddress
  )

  const [
    { canCall: canRequestWithdraw },
    { canCall: canCompleteWithdraw },
    { canCall: canCancelWithdraw },
  ] = await rolesAuth.fetchCanCall([
    {
      user: account,
      target: delayedWithdrawalsAddress,
      sig: REQUEST_WITHDRAW_SIGNATURE,
    },
    {
      user: account,
      target: delayedWithdrawalsAddress,
      sig: COMPLETE_WITHDRAW_SIGNATURE,
    },
    {
      user: account,
      target: delayedWithdrawalsAddress,
      sig: CANCEL_WITHDRAW_SIGNATURE,
    },
  ])

  return {
    canDeposit: true,
    canRequestWithdraw,
    canCompleteWithdraw,
    canCancelWithdraw,
  }
}

async function fetchPaused(
  multicall: Multicall3,
  vaultTokenAddress: string,
  delayedWithdrawAddress: string
): Promise<YearnPausedResult> {
  const calls: Multicall3.Call3Struct[] = [
    {
      target: vaultTokenAddress,
      callData:
        YearnV3Vault__factory.createInterface().encodeFunctionData(
          'isShutdown'
        ),
      allowFailure: false,
    },
    {
      target: delayedWithdrawAddress,
      callData:
        YearnDelayedWithdraw__factory.createInterface().encodeFunctionData(
          'isPaused'
        ),
      allowFailure: false,
    },
  ]

  const results = await multicall.callStatic.tryAggregate(true, calls)
  const [vaultPausedResult, delayedWithdrawPausedResult] = results.map(
    (res) => res.returnData
  )

  const depositPaused =
    YearnV3Vault__factory.createInterface().decodeFunctionResult(
      'isShutdown',
      vaultPausedResult
    )[0]
  const withdrawPaused =
    YearnDelayedWithdraw__factory.createInterface().decodeFunctionResult(
      'isPaused',
      delayedWithdrawPausedResult
    )[0]

  return {
    depositPaused,
    withdrawPaused,
  }
}

async function fetchYearnWithdrawRequest(
  delayedWithdrawal: YearnDelayedWithdraw,
  depositAsset: Token,
  account: string
): Promise<YearnWithdrawRequestResult> {
  const withdrawRequest = await delayedWithdrawal.withdrawRequests(
    account,
    depositAsset.address
  )

  if (withdrawRequest.shares.eq(0)) {
    return {
      exists: false,
    }
  }

  return {
    exists: true,
    request: {
      maxLossBasisPoints: withdrawRequest.maxLoss,
      maturityUnix: withdrawRequest.maturity,
      shares: withdrawRequest.shares,
      assetsAtTimeOfRequest: withdrawRequest.assetsAtTimeOfRequest,
    },
  }
}

function useYearnVaultReadApi({
  depositAsset,
  vaultToken,
  delayedWithdrawalsAddress,
  rolesAuthorityAddress,
  depositAssetRateFetcher,
  statsFetcher,
}: {
  vaultToken: Token
  depositAsset: Token
  delayedWithdrawalsAddress: string
  rolesAuthorityAddress: string
  depositAssetRateFetcher: IRateFetcher
  statsFetcher: IYearnVaultStatsFetcher
}): IYearnVaultApiRead {
  const { account: maybeAccount } = useSwellWeb3()
  const multicall = useMulticallContract()
  const yearnVault = useYearnV3VaultContract(vaultToken.address)
  const delayedWithdrawal = useYearnDelayedWithdrawContract(
    delayedWithdrawalsAddress
  )

  const account = maybeAccount!

  return {
    supportedAssets: async () => {
      return fetchSupportedAssets(delayedWithdrawal, depositAsset)
    },
    vaultStats: async () => {
      return fetchVaultStats(statsFetcher, yearnVault)
    },
    paused: async () => {
      return fetchPaused(
        multicall,
        vaultToken.address,
        delayedWithdrawalsAddress
      )
    },
    rates: async () => {
      return fetchYearnVaultRates(yearnVault, depositAssetRateFetcher)
    },
    allowances: async () => {
      return fetchAllowances(
        multicall,
        vaultToken.address,
        depositAsset.address,
        delayedWithdrawalsAddress,
        account
      )
    },
    balances: async () => {
      return fetchBalances(
        multicall,
        vaultToken.address,
        depositAsset.address,
        account
      )
    },
    auth: async () => {
      return fetchYearnAuth(
        multicall,
        rolesAuthorityAddress,
        delayedWithdrawalsAddress,
        account
      )
    },
    withdrawRequest: async () => {
      return fetchYearnWithdrawRequest(delayedWithdrawal, depositAsset, account)
    },
    checkDeposit: async (params) => {
      // const [assets, receiver] = params
      return true // TODO
    },
    checkRequestWithdraw: async (params) => {
      // const [asset, shares, maxLoss, allowThirdPartyToComplete] = params
      return true // TODO
    },
  }
}

function useYearnVaultWriteImpl({
  vaultToken,
  depositAsset,
  delayedWithdrawalsAddress,
}: {
  vaultToken: Token
  depositAsset: Token
  delayedWithdrawalsAddress: string
}): IYearnVaultApiWrite {
  const { account: maybeAccount } = useSwellWeb3()
  const vaultContract = useYearnV3VaultContract(vaultToken.address)
  const depositAssetContract = useIERC20Contract(depositAsset.address)!
  const delayedWithdrawal = useYearnDelayedWithdrawContract(
    delayedWithdrawalsAddress
  )

  const account = maybeAccount!
  const allowThirdPartyToComplete = ALLOW_THIRD_PARTY_TO_COMPLETE

  return {
    // deposit
    deposit: async ({ amount }) => {
      return vaultContract.deposit(amount, account)
    },
    depositEstimateGas: async ({ amount }) => {
      return vaultContract.estimateGas.deposit(amount, account)
    },
    approveAssetForDeposit: async ({ amount }) => {
      return depositAssetContract.approve(vaultToken.address, amount)
    },
    approveAssetForDepositEstimateGas: async ({ amount }) => {
      return depositAssetContract.estimateGas.approve(
        vaultToken.address,
        amount
      )
    },
    // request withdraw
    requestWithdraw: async ({ shares, maxLossBasisPoints }) => {
      return delayedWithdrawal.requestWithdraw(
        depositAsset.address,
        shares,
        maxLossBasisPoints,
        allowThirdPartyToComplete
      )
    },
    requestWithdrawEstimateGas: async ({ shares, maxLossBasisPoints }) => {
      return delayedWithdrawal.estimateGas.requestWithdraw(
        depositAsset.address,
        shares,
        maxLossBasisPoints,
        allowThirdPartyToComplete
      )
    },
    approveVaultTokenForWithdraw: async ({ amount }) => {
      return vaultContract.approve(delayedWithdrawalsAddress, amount)
    },
    approveVaultTokenForWithdrawEstimateGas: async ({ amount }) => {
      return vaultContract.estimateGas.approve(
        delayedWithdrawalsAddress,
        amount
      )
    },
    // cancel withdraw
    cancelWithdraw: async () => {
      return delayedWithdrawal.cancelWithdraw(depositAsset.address)
    },
    cancelWithdrawEstimateGas: async () => {
      return delayedWithdrawal.estimateGas.cancelWithdraw(depositAsset.address)
    },
    // complete withdraw
    completeWithdraw: async () => {
      return delayedWithdrawal.completeWithdraw(depositAsset.address, account)
    },
    completeWithdrawEstimateGas: async () => {
      return delayedWithdrawal.estimateGas.completeWithdraw(
        depositAsset.address,
        account
      )
    },
  }
}
