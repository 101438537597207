import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { FlexRow } from '@swell-ui/FlexRow'
import { pulseKeyframe } from '@swell-ui/keyframes'
import { Typography } from '@swell-ui/Typography'
import { ACTIONS } from './constants'

interface ActionChooserProps {
  actions: string[]
  onActionClick: (action: string) => void
  defaultAction?: string
}

const ActionChooserWrapper = styled.div`
  padding-bottom: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.divider.darkColor};
  margin-bottom: 16px !important;
`

const ActionChoice = styled.div<any>`
  color: ${({ theme }) => theme.a.color};
  ${({ active, theme }) => active && `color: ${theme.a.hoverColor};`}
  ${({ pulse }) =>
    pulse &&
    css`
      animation: ${pulseKeyframe} 3s linear 0s infinite normal;
    `}

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.a.hoverColor};
  }
`

function ActionChooser({
  actions,
  onActionClick,
  defaultAction,
}: ActionChooserProps) {
  const [selectedAction, setSelectedAction] = useState<string>(
    defaultAction || actions[0]
  )

  const handleActionClick = (action: string) => {
    setSelectedAction(action)
    onActionClick(action)
  }

  return (
    <ActionChooserWrapper>
      <Typography variant="body" size="small" letterSpacing="small">
        <FlexRow align="center" gap="16">
          {actions.map((action: string) => {
            let label = action
            if (action === ACTIONS.ZAP) {
              label = 'Restake'
            }
            if (action === ACTIONS.VAULT) {
              label = 'Deposit'
            }
            if (action === ACTIONS.CLAIM_EIGEN) {
              label = 'Claim EIGEN'
            }
            return (
              <ActionChoice
                key={action}
                active={action === selectedAction}
                onClick={() => handleActionClick(action)}
                pulse={action === ACTIONS.CLAIM}
              >
                {label}
              </ActionChoice>
            )
          })}
        </FlexRow>
      </Typography>
    </ActionChooserWrapper>
  )
}

export { ActionChooser }
