import { useEveryNthBlock } from '@/hooks/useBlockNumber'
import { useDataLivenessConfig } from '../deployments/hooks/useDataLivenessConfig'
import { useEthUsdMarketRate } from './hooks'

export interface IFiatUpdaterProps {
  // Refresh interval (ms) of the backend data source
  // (Not applicable when on chain fallback is active)
  primaryRefreshIntervalMs?: number

  // When the on chain fallback is active, dictates how many blocks between refetching
  fallbackBlocksPerFetch?: number
}

export default function FiatUpdater({
  fallbackBlocksPerFetch,
  primaryRefreshIntervalMs,
}: IFiatUpdaterProps): null {
  const { fiatDataLiveness } = useDataLivenessConfig()

  const {
    mutateFallback: ethUsdMarketRateMutate,
    fallbackActive: ethUsdMarketRateFallbackActive,
  } = useEthUsdMarketRate({ primaryRefreshIntervalMs })

  useEveryNthBlock({
    blocksPerFetch:
      fallbackBlocksPerFetch ?? fiatDataLiveness.fallbackBlocksPerFetch,
    fetch: ethUsdMarketRateMutate,
    skip: !ethUsdMarketRateFallbackActive,
  })

  return null
}
