import React from 'react'
import { displayCryptoLocale } from '@/util/displayCrypto'
import styled from 'styled-components'
import { Token } from '@/types/tokens'
import { FlexRow } from '@/swell-ui/FlexRow'
import { yearnWithdrawTypographyCSS } from './common'
import { Divider } from '@/swell-ui/Divider'
import {
  YearnWithdrawAsset,
  YearnWithdrawRequest,
} from '@/state/yearnVault/types'
import { BigNumber } from 'ethers'
import { assetsReceivedFromWithdrawal } from '../yearnConversions'
import { useTimeCountdown } from '@/hooks/useTimeCountdown'

export function YearnWithdrawClaimable({
  withdrawRequest,
  depositAsset,
  pricePerShare,
  withdrawAsset,
  vaultToken,
}: {
  withdrawRequest: YearnWithdrawRequest
  depositAsset: Token
  pricePerShare: BigNumber | undefined
  withdrawAsset: YearnWithdrawAsset | undefined
  vaultToken: Token
}) {
  const { maturityUnix } = withdrawRequest

  let windowDeadlineMs: number | undefined
  if (withdrawAsset) {
    const windowDeadlineUnix =
      maturityUnix + withdrawAsset.completionWindowSeconds
    windowDeadlineMs = windowDeadlineUnix * 1000
  }

  const windowCountdown = useTimeCountdown(windowDeadlineMs)

  let receivingAmountStr: string | undefined
  if (pricePerShare && withdrawAsset) {
    const { assetsAtTimeOfRequest, shares } = withdrawRequest

    const receiveAssets = assetsReceivedFromWithdrawal({
      assetsAtTimeOfRequest,
      shares,
      pricePerShare,
      withdrawFeeBasisPoints: withdrawAsset.withdrawFeeBasisPoints,
      vaultTokenDecimals: vaultToken.decimals,
    })
    receivingAmountStr = displayCryptoLocale(
      receiveAssets,
      withdrawAsset.decimals,
      { btc: true }
    )
  }

  let claimWindowStr = ''
  if (windowCountdown) {
    claimWindowStr = `${windowCountdown.days}d ${windowCountdown.hours}h ${windowCountdown.minutes}m`
  }

  return (
    <Layout>
      <FlexRow direction="column" gap="12" align="center">
        <Tick />
        <span className="title">Processing completed</span>
        <FlexRow
          direction="column"
          gap="8"
          align="center"
          justify="center"
          style={{ marginTop: '24px' }}
        >
          <span className="subheading">You received</span>
          <span className="big">{receivingAmountStr}</span>
          <FlexRow gap="8" justify="center">
            <TokenIcon src={depositAsset.logoURI} />
            <span className="med">{depositAsset.symbol}</span>
          </FlexRow>
        </FlexRow>
        <FlexRow
          direction="column"
          gap="8"
          align="center"
          justify="center"
          style={{
            marginTop: 'auto',
            visibility: claimWindowStr ? 'visible' : 'hidden',
          }}
        >
          <span className="subheading">Time window to claim</span>
          <span className="body">{claimWindowStr}</span>
          <span className="body hidden">-</span>
        </FlexRow>
      </FlexRow>
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  padding-top: 50px;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
  text-align: center;
  ${yearnWithdrawTypographyCSS}

  > div:first-child {
    flex-grow: 1;
  }
  ${Divider} {
    margin: 0;
    margin-top: 12px;
    margin-bottom: 8px;
  }
  > p {
    margin: 0;
    margin-bottom: -12px;
  }

  .hidden {
    visibility: hidden;
  }
`
const TokenIcon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

function Tick() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="51"
      viewBox="0 0 52 51"
      fill="none"
    >
      <path
        d="M26 0.145813C21.0184 0.145813 16.1486 1.62303 12.0066 4.39067C7.86452 7.15831 4.63618 11.0921 2.7298 15.6945C0.823413 20.2969 0.324617 25.3613 1.29648 30.2472C2.26835 35.133 4.66722 39.621 8.18976 43.1436C11.7123 46.6661 16.2003 49.065 21.0862 50.0368C25.9721 51.0087 31.0364 50.5099 35.6388 48.6035C40.2413 46.6971 44.175 43.4688 46.9427 39.3267C49.7103 35.1847 51.1875 30.3149 51.1875 25.3333C51.1805 18.6553 48.5245 12.2529 43.8025 7.53085C39.0804 2.8088 32.678 0.152865 26 0.145813ZM37.0583 20.8916L23.4958 34.4541C23.3159 34.6342 23.1022 34.7771 22.867 34.8746C22.6317 34.9722 22.3796 35.0223 22.125 35.0223C21.8704 35.0223 21.6183 34.9722 21.3831 34.8746C21.1479 34.7771 20.9342 34.6342 20.7542 34.4541L14.9417 28.6416C14.5782 28.278 14.3739 27.785 14.3739 27.2708C14.3739 26.7567 14.5782 26.2636 14.9417 25.9C15.3053 25.5365 15.7984 25.3322 16.3125 25.3322C16.8267 25.3322 17.3197 25.5365 17.6833 25.9L22.125 30.3442L34.3167 18.15C34.4967 17.97 34.7104 17.8272 34.9456 17.7298C35.1808 17.6324 35.4329 17.5822 35.6875 17.5822C35.9421 17.5822 36.1942 17.6324 36.4294 17.7298C36.6646 17.8272 36.8783 17.97 37.0583 18.15C37.2383 18.33 37.3811 18.5438 37.4785 18.779C37.5759 19.0142 37.6261 19.2662 37.6261 19.5208C37.6261 19.7754 37.5759 20.0275 37.4785 20.2627C37.3811 20.4979 37.2383 20.7116 37.0583 20.8916Z"
        fill="#009E49"
      />
    </svg>
  )
}
