import React from 'react'
import styled from 'styled-components/macro'
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle'
import MuiDialogContent, {
  DialogContentProps,
} from '@mui/material/DialogContent'

const StyledMuiDialogContent = styled(MuiDialogContent)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
  }
`

function DialogTitle(props: DialogTitleProps) {
  return <MuiDialogTitle {...props} />
}

function DialogContent(props: DialogContentProps) {
  return <StyledMuiDialogContent {...props} />
}

export { DialogTitle, DialogContent }
