import { ClaimEigenStakedrop } from '@/state/rsweth/hooks'
import { EigenLayerStakedropResult } from '@/state/rsweth/types'
import { MerkleDropState } from '@/types/merkle'
import { ReactNode } from 'react'
import {
  EigenStakedropErrors,
  prepareClaimEigenStakedrop,
} from './eigenStakedropCalls'
import { useSwellWeb3 } from '@/swell-web3/core'
import { merkleClaimable } from '@/util/merkledrop'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { Token } from '@/types/tokens'
import {
  ClaimedEigenStakedropButton,
  ConnectWalletButton,
} from './EigenStakedropButtons'
import styled from 'styled-components'
import { FlexRow } from '@/swell-ui/FlexRow'
import { ClaimEigenStakedropToast } from './EigenStakedropToasts'
import eigenlayerLogo from '@/assets/images/eigen-round-74x74.png'

const EIGEN_SEASON_2_URL =
  'https://blog.eigenfoundation.org/season-2-stakedrop/'

export function ClaimEigenWidget({
  eigenStakedropResult,
  claimEigenStakedrop,
  merkleState,
  eigenToken,
}: {
  eigenStakedropResult: EigenLayerStakedropResult | undefined
  claimEigenStakedrop: ClaimEigenStakedrop
  merkleState: MerkleDropState | undefined
  eigenToken: Token
}) {
  const { account } = useSwellWeb3()

  const preparedClaimEigenStakedrop = prepareClaimEigenStakedrop({
    eigenStakedropResult,
    merkleState,
  })

  let claimableEigenStr: string
  let button: ReactNode = (
    <ClaimedEigenStakedropButton
      claimEigenStakedrop={claimEigenStakedrop}
      prepared={preparedClaimEigenStakedrop}
    />
  )
  if (!account) {
    claimableEigenStr = '-'
    button = <ConnectWalletButton />
  } else if (!eigenStakedropResult) {
    claimableEigenStr = '-'
  } else if (
    preparedClaimEigenStakedrop.error ===
      EigenStakedropErrors.NoEigenStakedropData ||
    preparedClaimEigenStakedrop.error === EigenStakedropErrors.NothingToClaim
  ) {
    claimableEigenStr = '0'
  } else if (
    preparedClaimEigenStakedrop.error === EigenStakedropErrors.AlreadyClaimed
  ) {
    if (!eigenStakedropResult?.exists) {
      return null
    }

    claimableEigenStr = displayCryptoLocale(
      eigenStakedropResult.data.cumulativeAmount,
      eigenToken.decimals
    )
  } else if (eigenStakedropResult.exists) {
    const { claimableAmount } = merkleClaimable({
      cumulativeAmount: eigenStakedropResult.data.cumulativeAmount,
      cumulativeClaimed: eigenStakedropResult.cumulativeClaimed,
      totalAmount: eigenStakedropResult.data.totalAmount,
    })
    claimableEigenStr = displayCryptoLocale(
      claimableAmount,
      eigenToken.decimals
    )
  } else {
    claimableEigenStr = '-'
  }

  return (
    <>
      <Layout direction="column" gap="24" height="510px">
        <h1>EIGEN Season 2 Claim</h1>
        <img src={eigenlayerLogo} width="74" height="74" alt="EIGEN" />

        <InnerLayout direction="column">
          <span>Claimable EIGEN</span>
          <span className="amt">{claimableEigenStr}</span>
          <p>
            Claim EIGEN earned for holding rswETH during{' '}
            <a
              href={EIGEN_SEASON_2_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              EigenLayer Season 2
            </a>
          </p>
          {button}
        </InnerLayout>
      </Layout>
      <ClaimEigenStakedropToast
        claimEigenStakedrop={claimEigenStakedrop}
        eigenToken={eigenToken}
        eigenStakedropResult={eigenStakedropResult}
      />
    </>
  )
}

const InnerLayout = styled(FlexRow)`
  flex: 1;

  p {
    margin-top: auto;
    margin-bottom: 24px;
    text-align: center;
  }
`

const Layout = styled(FlexRow)`
  h1 {
    text-align: center;
  }
  .amt {
    font-size: 32px;
    font-weight: 600;
    margin-top: 12px;
    line-height: 140%; /* 44.8px */
  }

  button {
    align-self: stretch;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`
