import React from 'react'
import styled from 'styled-components/macro'
import MuiCircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'

const CircularProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 8px 0;
`

const StyledCircularProgress = styled(MuiCircularProgress)`
  circle {
    stroke-linecap: round;
  }

  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.lightBlue['100']};
  }
`

function CircularProgress(props: CircularProgressProps) {
  return (
    <CircularProgressWrapper>
      <StyledCircularProgress {...props} />
    </CircularProgressWrapper>
  )
}

export { CircularProgress }
