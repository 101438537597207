import { depositInfoCryptoFormatAdapter } from '@/types/deposits'
import { verifyDepositRoots } from '@/util/verifyDepositRoots'
import { IDepositInfoVerifier } from '../../IDepositInfoVerifier'

export const depositRootsVerifier: IDepositInfoVerifier = {
  verify: async (deposit) => {
    return !verifyDepositRoots(
      // verifyDepositRoots requires the deposit in a crypto-friendly format
      depositInfoCryptoFormatAdapter(deposit)
    )
  },
}
