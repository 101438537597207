import { useEffect } from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { useUserOperatorId } from '@/state/userOperator/hooks'
import { useUserOperatorId as useRestakingUserOperatorId } from '@/state/restakingUserOperator/hooks'
import {
  useGlobalNotification,
  GLOBAL_NOTIFICATION_TYPES,
} from '@/swell-ui/GlobalNotification'

export enum UserOperatorStatus {
  IS_OPERATOR = 'is-operator',
  NOT_OPERATOR = 'not-operator',
  UNKNOWN = 'unknown',
}

export function useUserOperatorStatus() {
  const { account } = useSwellWeb3()
  const userOperatorId = useUserOperatorId()

  return {
    get userOperatorStatus(): UserOperatorStatus {
      if (!account || !userOperatorId.data) return UserOperatorStatus.UNKNOWN

      return userOperatorId.data.operatorId.eq(0)
        ? UserOperatorStatus.NOT_OPERATOR
        : UserOperatorStatus.IS_OPERATOR
    },
    get isOperator(): boolean {
      return this.userOperatorStatus === UserOperatorStatus.IS_OPERATOR
    },
  }
}

export function useUserOperatorStatusWithWarning() {
  const { userOperatorStatus, isOperator } = useUserOperatorStatus()
  const { notify, removeNotification } = useGlobalNotification()

  useEffect(() => {
    if (userOperatorStatus === UserOperatorStatus.NOT_OPERATOR) {
      const msg = 'This address is not a registered node operator address.'
      const nId = notify(msg, GLOBAL_NOTIFICATION_TYPES.ERROR)

      return () => {
        removeNotification(nId)
      }
    }
  }, [notify, removeNotification, userOperatorStatus])

  return { userOperatorStatus, isOperator }
}

export function useRestakingUserOperatorStatus() {
  const { account } = useSwellWeb3()
  const userOperatorId = useRestakingUserOperatorId()

  return {
    get userOperatorStatus(): UserOperatorStatus {
      if (!account || !userOperatorId.data) return UserOperatorStatus.UNKNOWN

      return userOperatorId.data.operatorId.eq(0)
        ? UserOperatorStatus.NOT_OPERATOR
        : UserOperatorStatus.IS_OPERATOR
    },
    get isOperator(): boolean {
      return this.userOperatorStatus === UserOperatorStatus.IS_OPERATOR
    },
  }
}

export function useRestakingUserOperatorStatusWithWarning() {
  const { userOperatorStatus, isOperator } = useRestakingUserOperatorStatus()
  const { notify, removeNotification } = useGlobalNotification()

  useEffect(() => {
    if (userOperatorStatus === UserOperatorStatus.NOT_OPERATOR) {
      const msg =
        'This address is not a registered restaking node operator address.'
      const nId = notify(msg, GLOBAL_NOTIFICATION_TYPES.ERROR)

      return () => {
        removeNotification(nId)
      }
    }
  }, [notify, removeNotification, userOperatorStatus])

  return { userOperatorStatus, isOperator }
}
