// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/operator.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.DepositsManagerStatsRequest
 */
export class DepositsManagerStatsRequest extends Message<DepositsManagerStatsRequest> {
  constructor(data?: PartialMessage<DepositsManagerStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.DepositsManagerStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DepositsManagerStatsRequest {
    return new DepositsManagerStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DepositsManagerStatsRequest {
    return new DepositsManagerStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DepositsManagerStatsRequest {
    return new DepositsManagerStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DepositsManagerStatsRequest | PlainMessage<DepositsManagerStatsRequest> | undefined, b: DepositsManagerStatsRequest | PlainMessage<DepositsManagerStatsRequest> | undefined): boolean {
    return proto3.util.equals(DepositsManagerStatsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.DepositsManagerStatsResponse
 */
export class DepositsManagerStatsResponse extends Message<DepositsManagerStatsResponse> {
  /**
   * @generated from field: string buffered_wei = 1;
   */
  bufferedWei = "";

  /**
   * @generated from field: string current_capacity_wei = 2;
   */
  currentCapacityWei = "";

  /**
   * @generated from field: uint64 unused_keys_count = 3;
   */
  unusedKeysCount = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp last_deposit_at = 4;
   */
  lastDepositAt?: Timestamp;

  constructor(data?: PartialMessage<DepositsManagerStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.DepositsManagerStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "buffered_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "current_capacity_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "unused_keys_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "last_deposit_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DepositsManagerStatsResponse {
    return new DepositsManagerStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DepositsManagerStatsResponse {
    return new DepositsManagerStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DepositsManagerStatsResponse {
    return new DepositsManagerStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DepositsManagerStatsResponse | PlainMessage<DepositsManagerStatsResponse> | undefined, b: DepositsManagerStatsResponse | PlainMessage<DepositsManagerStatsResponse> | undefined): boolean {
    return proto3.util.equals(DepositsManagerStatsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.AllOperatorsRequest
 */
export class AllOperatorsRequest extends Message<AllOperatorsRequest> {
  constructor(data?: PartialMessage<AllOperatorsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.AllOperatorsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AllOperatorsRequest {
    return new AllOperatorsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AllOperatorsRequest {
    return new AllOperatorsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AllOperatorsRequest {
    return new AllOperatorsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AllOperatorsRequest | PlainMessage<AllOperatorsRequest> | undefined, b: AllOperatorsRequest | PlainMessage<AllOperatorsRequest> | undefined): boolean {
    return proto3.util.equals(AllOperatorsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.AllOperatorsResponse
 */
export class AllOperatorsResponse extends Message<AllOperatorsResponse> {
  /**
   * @generated from field: repeated swell.v3.NodeOperatorInfo all_operators = 1;
   */
  allOperators: NodeOperatorInfo[] = [];

  constructor(data?: PartialMessage<AllOperatorsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.AllOperatorsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "all_operators", kind: "message", T: NodeOperatorInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AllOperatorsResponse {
    return new AllOperatorsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AllOperatorsResponse {
    return new AllOperatorsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AllOperatorsResponse {
    return new AllOperatorsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AllOperatorsResponse | PlainMessage<AllOperatorsResponse> | undefined, b: AllOperatorsResponse | PlainMessage<AllOperatorsResponse> | undefined): boolean {
    return proto3.util.equals(AllOperatorsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.NodeOperatorInfo
 */
export class NodeOperatorInfo extends Message<NodeOperatorInfo> {
  /**
   * @generated from field: int32 operator_id = 1;
   */
  operatorId = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string address = 3;
   */
  address = "";

  /**
   * @generated from field: int32 total_keys = 4;
   */
  totalKeys = 0;

  /**
   * @generated from field: int32 used_keys = 5;
   */
  usedKeys = 0;

  /**
   * @generated from field: int32 unused_keys = 6;
   */
  unusedKeys = 0;

  constructor(data?: PartialMessage<NodeOperatorInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NodeOperatorInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "operator_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_keys", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "used_keys", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "unused_keys", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NodeOperatorInfo {
    return new NodeOperatorInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NodeOperatorInfo {
    return new NodeOperatorInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NodeOperatorInfo {
    return new NodeOperatorInfo().fromJsonString(jsonString, options);
  }

  static equals(a: NodeOperatorInfo | PlainMessage<NodeOperatorInfo> | undefined, b: NodeOperatorInfo | PlainMessage<NodeOperatorInfo> | undefined): boolean {
    return proto3.util.equals(NodeOperatorInfo, a, b);
  }
}

/**
 * @generated from message swell.v3.DepositsUserStatsRequest
 */
export class DepositsUserStatsRequest extends Message<DepositsUserStatsRequest> {
  constructor(data?: PartialMessage<DepositsUserStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.DepositsUserStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DepositsUserStatsRequest {
    return new DepositsUserStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DepositsUserStatsRequest {
    return new DepositsUserStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DepositsUserStatsRequest {
    return new DepositsUserStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DepositsUserStatsRequest | PlainMessage<DepositsUserStatsRequest> | undefined, b: DepositsUserStatsRequest | PlainMessage<DepositsUserStatsRequest> | undefined): boolean {
    return proto3.util.equals(DepositsUserStatsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.DepositsUserStatsResponse
 */
export class DepositsUserStatsResponse extends Message<DepositsUserStatsResponse> {
  /**
   * @generated from field: uint32 total_keys_count = 1;
   */
  totalKeysCount = 0;

  /**
   * @generated from field: uint32 used_keys_count = 2;
   */
  usedKeysCount = 0;

  /**
   * @generated from field: uint32 unused_keys_count = 3;
   */
  unusedKeysCount = 0;

  constructor(data?: PartialMessage<DepositsUserStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.DepositsUserStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_keys_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "used_keys_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "unused_keys_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DepositsUserStatsResponse {
    return new DepositsUserStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DepositsUserStatsResponse {
    return new DepositsUserStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DepositsUserStatsResponse {
    return new DepositsUserStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DepositsUserStatsResponse | PlainMessage<DepositsUserStatsResponse> | undefined, b: DepositsUserStatsResponse | PlainMessage<DepositsUserStatsResponse> | undefined): boolean {
    return proto3.util.equals(DepositsUserStatsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.TransactionsTokensRequest
 */
export class TransactionsTokensRequest extends Message<TransactionsTokensRequest> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: int32 first = 2;
   */
  first = 0;

  /**
   * @generated from field: int32 skip = 3;
   */
  skip = 0;

  constructor(data?: PartialMessage<TransactionsTokensRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TransactionsTokensRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "skip", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransactionsTokensRequest {
    return new TransactionsTokensRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransactionsTokensRequest {
    return new TransactionsTokensRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransactionsTokensRequest {
    return new TransactionsTokensRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TransactionsTokensRequest | PlainMessage<TransactionsTokensRequest> | undefined, b: TransactionsTokensRequest | PlainMessage<TransactionsTokensRequest> | undefined): boolean {
    return proto3.util.equals(TransactionsTokensRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.TransactionsTokensResponse
 */
export class TransactionsTokensResponse extends Message<TransactionsTokensResponse> {
  /**
   * @generated from field: repeated swell.v3.TransactionToken token_transactions = 1;
   */
  tokenTransactions: TransactionToken[] = [];

  constructor(data?: PartialMessage<TransactionsTokensResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TransactionsTokensResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token_transactions", kind: "message", T: TransactionToken, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransactionsTokensResponse {
    return new TransactionsTokensResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransactionsTokensResponse {
    return new TransactionsTokensResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransactionsTokensResponse {
    return new TransactionsTokensResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TransactionsTokensResponse | PlainMessage<TransactionsTokensResponse> | undefined, b: TransactionsTokensResponse | PlainMessage<TransactionsTokensResponse> | undefined): boolean {
    return proto3.util.equals(TransactionsTokensResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.TransactionsNativeRequest
 */
export class TransactionsNativeRequest extends Message<TransactionsNativeRequest> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: int32 first = 2;
   */
  first = 0;

  /**
   * @generated from field: int32 skip = 3;
   */
  skip = 0;

  constructor(data?: PartialMessage<TransactionsNativeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TransactionsNativeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "skip", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransactionsNativeRequest {
    return new TransactionsNativeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransactionsNativeRequest {
    return new TransactionsNativeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransactionsNativeRequest {
    return new TransactionsNativeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TransactionsNativeRequest | PlainMessage<TransactionsNativeRequest> | undefined, b: TransactionsNativeRequest | PlainMessage<TransactionsNativeRequest> | undefined): boolean {
    return proto3.util.equals(TransactionsNativeRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.TransactionsNativeResponse
 */
export class TransactionsNativeResponse extends Message<TransactionsNativeResponse> {
  /**
   * @generated from field: repeated swell.v3.TransactionNative native_transactions = 1;
   */
  nativeTransactions: TransactionNative[] = [];

  constructor(data?: PartialMessage<TransactionsNativeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TransactionsNativeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "native_transactions", kind: "message", T: TransactionNative, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransactionsNativeResponse {
    return new TransactionsNativeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransactionsNativeResponse {
    return new TransactionsNativeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransactionsNativeResponse {
    return new TransactionsNativeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TransactionsNativeResponse | PlainMessage<TransactionsNativeResponse> | undefined, b: TransactionsNativeResponse | PlainMessage<TransactionsNativeResponse> | undefined): boolean {
    return proto3.util.equals(TransactionsNativeResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.TransactionNative
 */
export class TransactionNative extends Message<TransactionNative> {
  /**
   * @generated from field: string tx_hash = 1;
   */
  txHash = "";

  /**
   * @generated from field: string method = 2;
   */
  method = "";

  /**
   * @generated from field: string from = 3;
   */
  from = "";

  /**
   * @generated from field: string to = 4;
   */
  to = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: string value_wei = 6;
   */
  valueWei = "";

  constructor(data?: PartialMessage<TransactionNative>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TransactionNative";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "to", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created_at", kind: "message", T: Timestamp },
    { no: 6, name: "value_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransactionNative {
    return new TransactionNative().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransactionNative {
    return new TransactionNative().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransactionNative {
    return new TransactionNative().fromJsonString(jsonString, options);
  }

  static equals(a: TransactionNative | PlainMessage<TransactionNative> | undefined, b: TransactionNative | PlainMessage<TransactionNative> | undefined): boolean {
    return proto3.util.equals(TransactionNative, a, b);
  }
}

/**
 * @generated from message swell.v3.TransactionToken
 */
export class TransactionToken extends Message<TransactionToken> {
  /**
   * @generated from field: string tx_hash = 1;
   */
  txHash = "";

  /**
   * @generated from field: string contract = 2;
   */
  contract = "";

  /**
   * @generated from field: string from = 3;
   */
  from = "";

  /**
   * @generated from field: string to = 4;
   */
  to = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: string value = 6;
   */
  value = "";

  constructor(data?: PartialMessage<TransactionToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TransactionToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "contract", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "to", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created_at", kind: "message", T: Timestamp },
    { no: 6, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransactionToken {
    return new TransactionToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransactionToken {
    return new TransactionToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransactionToken {
    return new TransactionToken().fromJsonString(jsonString, options);
  }

  static equals(a: TransactionToken | PlainMessage<TransactionToken> | undefined, b: TransactionToken | PlainMessage<TransactionToken> | undefined): boolean {
    return proto3.util.equals(TransactionToken, a, b);
  }
}

/**
 * @generated from message swell.v3.ValidateKeysRequest
 */
export class ValidateKeysRequest extends Message<ValidateKeysRequest> {
  /**
   * @generated from field: bytes deposit_data = 1;
   */
  depositData = new Uint8Array(0);

  /**
   * @generated from field: string node_operator_name = 2;
   */
  nodeOperatorName = "";

  constructor(data?: PartialMessage<ValidateKeysRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.ValidateKeysRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deposit_data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "node_operator_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateKeysRequest {
    return new ValidateKeysRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateKeysRequest {
    return new ValidateKeysRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateKeysRequest {
    return new ValidateKeysRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateKeysRequest | PlainMessage<ValidateKeysRequest> | undefined, b: ValidateKeysRequest | PlainMessage<ValidateKeysRequest> | undefined): boolean {
    return proto3.util.equals(ValidateKeysRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.ValidateKeysResponse
 */
export class ValidateKeysResponse extends Message<ValidateKeysResponse> {
  /**
   * @generated from field: int32 invalid_count = 1;
   */
  invalidCount = 0;

  /**
   * @generated from field: repeated swell.v3.ValidateKeyError invalids = 2;
   */
  invalids: ValidateKeyError[] = [];

  constructor(data?: PartialMessage<ValidateKeysResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.ValidateKeysResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invalid_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "invalids", kind: "message", T: ValidateKeyError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateKeysResponse {
    return new ValidateKeysResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateKeysResponse {
    return new ValidateKeysResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateKeysResponse {
    return new ValidateKeysResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateKeysResponse | PlainMessage<ValidateKeysResponse> | undefined, b: ValidateKeysResponse | PlainMessage<ValidateKeysResponse> | undefined): boolean {
    return proto3.util.equals(ValidateKeysResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.ValidateKeyError
 */
export class ValidateKeyError extends Message<ValidateKeyError> {
  /**
   * @generated from field: string pubkey = 1;
   */
  pubkey = "";

  /**
   * @generated from field: string error = 2;
   */
  error = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  constructor(data?: PartialMessage<ValidateKeyError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.ValidateKeyError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pubkey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateKeyError {
    return new ValidateKeyError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateKeyError {
    return new ValidateKeyError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateKeyError {
    return new ValidateKeyError().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateKeyError | PlainMessage<ValidateKeyError> | undefined, b: ValidateKeyError | PlainMessage<ValidateKeyError> | undefined): boolean {
    return proto3.util.equals(ValidateKeyError, a, b);
  }
}

