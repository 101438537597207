import isArray from 'lodash/isArray'
import { SWRResponse } from 'swr'

/**
 * A query has "information" when it either has data or an error.
 *
 * @param queries Either a single query, or a list of queries
 * @returns (boolean) Whether every supplied query has information (data or an error)
 */

export const queryHasInfo = (queries: SWRResponse | SWRResponse[]) => {
  const queryList = isArray(queries) ? queries : [queries]

  return queryList.every(({ data, error }) => {
    return !!data || !!error
  })
}
