import React from 'react'
import styled from 'styled-components'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Typography } from '@/swell-ui/Typography'

interface PageTitleProps {
  title: string | React.ReactNode
  subtitle: string | React.ReactNode
  align?: 'center' | 'flex-start'
}

const Wrapper = styled.div`
  margin: 40px 0 64px;
`

export function PageTitle({
  title,
  subtitle,
  align = 'flex-start',
}: PageTitleProps) {
  return (
    <Wrapper>
      <FlexRow direction="column" gap="16" align={align}>
        <Typography
          variant="headline"
          xs="h6"
          md="h2"
          size="h2"
          fstyle="bold"
          letterSpacing="small"
        >
          {title}
        </Typography>
        <Typography
          variant="body"
          xs="xsmall"
          md="medium"
          size="medium"
          letterSpacing="small"
        >
          {subtitle}
        </Typography>
      </FlexRow>
    </Wrapper>
  )
}
