import React from 'react'
import styled from 'styled-components/macro'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import bgHeroUrl from '@/assets/images/voyage-map/bg-hero.png'
import bgBotUrl from '@/assets/images/voyage-map/bg-bot.png'

const BgImgContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #010023;
`

const BgInner = styled.div`
  position: relative;
  max-width: 2024px;
  margin: 0 auto;
`

const BgDiv = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const BgHero = styled(BgDiv)`
  height: 1231px;
  background-image: url(${bgHeroUrl});
`

const NewBgMid = styled(BgDiv)`
  height: 1885px;
  background: rgba(4, 2, 27, 1);
`

const NewBgMid2 = styled(BgDiv)`
  height: 473px;
  background: rgba(4, 2, 27, 1);

  @media (max-width: 899px) {
    height: 449px;
  }

  @media (max-width: 599px) {
    height: 470px;
  }
`

const LeftGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, #010023 0%, rgba(1, 0, 35, 0) 100%);
  width: 479px;
  z-index: 6;
`

const RightGradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(90deg, rgba(1, 0, 35, 0) 0%, #010023 100%);
  width: 479px;
  z-index: 6;
`

const PosWrapper = styled.div`
  position: relative;
  max-width: 2024px;
`

const SwellCity = styled.img`
  width: 100%;
  max-width: 2024px;
  margin: 0 auto;
`

const TransparentCover = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 17.15%;
  background: linear-gradient(180deg, #001a29 0%, rgba(0, 26, 41, 0) 100%);
  opacity: 0.7;
`

const OverlayTop = styled.div`
  position: absolute;
  top: -354px;
  width: 100%;
  height: 500px;
  background: linear-gradient(0deg, rgba(3, 4, 23, 0.89) 48.66%, rgba(3, 4, 23, 0.67) 92.49%, rgba(3, 4, 23, 0) 99.66%);

  
  @media (min-width: 2024px) {
    left: 0;
    right 0;
    width: 100%;
  }
`

const CityOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    1.24deg,
    rgba(3, 4, 23, 0.93) -1.73%,
    rgba(3, 4, 23, 0.76) 98.97%
  );
`

// const MapOverlay = styled.div`
//   position: absolute;
//   top: 2438px;
//   left: 0;
//   right: 0;
//   z-index: 5;
//   width: 100%;
//   height: 678px;
//   background: linear-gradient(
//     0deg,
//     rgba(4, 2, 27, 0.89) 48.66%,
//     rgba(4, 2, 27, 0.67) 92.49%,
//     rgba(4, 2, 27, 0) 99.66%
//   );

//   @media (max-width: 1039px) {
//     top: 2491px;
//     height: 625px;
//   }

//   @media (max-width: 899px) {
//     top: 2569px;
//     height: 574px;
//   }

//   @media (max-width: 599px) {
//     top: 2637px;
//     height: 577px;
//   }
// `

function VoyageBgImg() {
  const is899Down = useMediaQuery('(max-width: 899px)')
  const is2025Up = useMediaQuery('(min-width:2025px)')

  return (
    <BgImgContainer>
      <BgInner>
        <BgHero />
        <NewBgMid />
        {is899Down && (
          <PosWrapper>
            <NewBgMid2 />
            <OverlayTop />
          </PosWrapper>
        )}
        <PosWrapper>
          <SwellCity src={bgBotUrl} />
          <CityOverlay />
        </PosWrapper>
        <TransparentCover />
        {/*<MapOverlay />*/}
        {is2025Up && (
          <>
            <LeftGradient />
            <RightGradient />
          </>
        )}
      </BgInner>
    </BgImgContainer>
  )
}

export { VoyageBgImg }
