import React from 'react'
import styled from 'styled-components/macro'
import { Typography } from '@swell-ui/Typography'

const StyledSectionBoxLabel = styled(Typography)`
  color: ${({ theme }) => theme.box.label.color};
  font-weight: 600;
`

function SectionBoxLabel({ children }: { children: React.ReactNode }) {
  return (
    <StyledSectionBoxLabel variant="body" size="medium">
      {children}
    </StyledSectionBoxLabel>
  )
}

export { SectionBoxLabel }
