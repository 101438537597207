import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { FlexRow } from '@swell-ui/FlexRow'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { useSwellUiTheme } from '@swell-ui/theme'
import { TokenPoolExternal } from '@/types/tokens'
import { PoolCard } from './PoolCard'

interface PoolHighlightWidgetProps {
  loading: boolean
  featured: TokenPoolExternal[]
  topApy: TokenPoolExternal[]
}

interface PoolHighlightButtonConfig {
  id: string
  label: string
}

const Wrapper = styled.div`
  margin-bottom: 67px;

  @media (max-width: 1200px) {
    width: calc(100% + 16px);
    overflow-x: scroll;
  }
`

const Pill = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 32px;
  padding: 4px 8px;
  border-radius: 100px;
  border: 1px solid ${({ theme }) => theme.colors.white['50']};
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;

  ${({ selected }) =>
    !selected &&
    `
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  `}

  ${({ theme, selected }) =>
    selected &&
    `
    background: ${theme.colors.lightBlue['100']};
    border: none;
  `}
`

const PoolCardListWrapper = styled.div`
  margin-top: 37px;
`

const POOL_HIGHLIGHT_BUTTONS = {
  FEATURED: {
    id: 'featured',
    label: 'Featured',
  },
  TOP: {
    id: 'topAPY',
    label: 'Top APY',
  },
}

function ViewSelect({
  viewId,
  onButtonClick,
}: {
  viewId: string
  onButtonClick: (viewId: string) => any
}) {
  const BUTTONS = [POOL_HIGHLIGHT_BUTTONS.FEATURED, POOL_HIGHLIGHT_BUTTONS.TOP]

  return (
    <FlexRow gap="8">
      {BUTTONS.map((button: PoolHighlightButtonConfig) => {
        const selected = viewId === button.id

        return (
          <Pill
            key={button.id}
            selected={selected}
            onClick={() => onButtonClick(button.id)}
          >
            {button.label}
          </Pill>
        )
      })}
    </FlexRow>
  )
}

function PoolCardList({ list }: { list: TokenPoolExternal[] }) {
  const { theme } = useSwellUiTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const gap = isMdUp ? '24' : '4'

  return (
    <PoolCardListWrapper>
      <FlexRow gap={gap} align="stretch">
        {list.map((pool: TokenPoolExternal) => {
          return <PoolCard key={pool.id} pool={pool} />
        })}
      </FlexRow>
    </PoolCardListWrapper>
  )
}

export function PoolHighlightWidget({
  loading,
  featured,
  topApy,
}: PoolHighlightWidgetProps) {
  const [viewId, setViewId] = useState<string>(
    POOL_HIGHLIGHT_BUTTONS.FEATURED.id
  )

  const list = useMemo(() => {
    if (loading) {
      return []
    }

    if (viewId === POOL_HIGHLIGHT_BUTTONS.FEATURED.id) {
      return featured
    }

    return topApy
  }, [loading, featured, topApy, viewId])

  const handleViewSelectButtonClick = (viewId: string) => {
    setViewId(viewId)
  }

  return (
    <Wrapper>
      <ViewSelect viewId={viewId} onButtonClick={handleViewSelectButtonClick} />
      <PoolCardList list={list} />
    </Wrapper>
  )
}
