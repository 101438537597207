import { DepositProblem } from './DepositProblem'
import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'
import { DepositCollection } from '@/types/deposits'

export type IndexedDepositProblem = DepositProblem & {
  /**
   * The (zero-based) index of the deposit which had a problem
   *
   * The index is based on the entire set of deposits (unrelated to how the deposit data was chunked)
   */
  datumIndex: number

  /**
   * The public key of the deposit which had a problem.
   *
   * Might not be defined because the problem might be that the public key is missing.
   */
  pubkey?: string
}

export interface IDepositCollectionValidationResult {
  valid: boolean

  // problems with the user input
  inputProblem?: DepositProblem

  // problems with individual data
  dataProblems?: IndexedDepositProblem[]

  depositCollection?: DepositCollection
}

export const DepositCollectionValidationResult = {
  _create: ({
    dataProblems,
    depositCollection,
    inputProblem,
  }: Omit<
    IDepositCollectionValidationResult,
    'valid'
  >): IDepositCollectionValidationResult => {
    const derived = {
      get valid() {
        if (inputProblem) return false
        if (dataProblems?.length) return false

        return true
      },
    }

    if (derived.valid && !depositCollection)
      throw new Error(`Valid result must have deposit data`)

    return {
      dataProblems,
      depositCollection,
      inputProblem,
      valid: derived.valid,
    }
  },
  fromValidData: (depositCollection: DepositCollection) => {
    return DepositCollectionValidationResult._create({
      depositCollection,
    })
  },
  fromInputProblem: (inputProblem: DepositProblem) => {
    return DepositCollectionValidationResult._create({ inputProblem })
  },
  fromDataProblems: (dataProblems: IndexedDepositProblem[]) => {
    return DepositCollectionValidationResult._create({ dataProblems })
  },
  /**
   * Given deposit data validation results can come from multiple sources
   *  (e.g. frontend validation, backend validation), this function provides
   *  a means for merging these results into a single result.
   *
   * @param result1 result from validation source 1
   * @param result2 result from validation source 2
   * @returns Composite DepositCollectionValidationResult
   */
  merge: (
    result1: IDepositCollectionValidationResult,
    result2: IDepositCollectionValidationResult
  ): IDepositCollectionValidationResult => {
    if (
      result1.depositCollection?.length &&
      result2.depositCollection?.length
    ) {
      result1.depositCollection?.forEach((datum1, idx) => {
        const datum2 = result2.depositCollection![idx]

        if (!isEqual(datum1, datum2))
          throw new Error(
            `Cannot merge two valid results concerning different data`
          )
      })
    }

    const inputProblem = result1.inputProblem ?? result2.inputProblem

    const problems1 = result1.dataProblems ?? []
    const problems2 = result2.dataProblems ?? []

    const mergedProblems = uniqBy(
      problems1.concat(problems2),
      ({ code, datumIndex }) => `${code}_${datumIndex}`
    )

    return DepositCollectionValidationResult._create({
      dataProblems: mergedProblems,
      inputProblem,
      depositCollection: (() => {
        if (mergedProblems.length || inputProblem) return undefined
        return result1.depositCollection
      })(),
    })
  },
}
