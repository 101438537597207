import { ZapCall } from '@/state/zap/hooks'
import { ZapRoute } from '@/state/zap/types'
import { BigNumber } from 'ethers'

type ValidatedArgs<T> =
  | {
      args?: never
      error: string | null
    }
  | {
      args: T
      error?: null
    }

export function prepareZap({
  route,
  allowance,
  fromTokenBalance,
  slippage,
}: {
  route: ZapRoute | null | undefined
  allowance: BigNumber | undefined
  fromTokenBalance: BigNumber | undefined
  slippage: number
}): ValidatedArgs<Parameters<ZapCall['call']>> {
  if (
    route === undefined ||
    allowance === undefined ||
    fromTokenBalance === undefined
  ) {
    return { error: null }
  }

  if (route === null) {
    return { error: 'No route available' }
  }

  if (allowance.lt(route.fromAmount)) {
    return { error: 'Insufficient allowance' }
  }

  if (fromTokenBalance.lt(route.fromAmount)) {
    return { error: 'Insufficient balance' }
  }

  if (slippage < 0 || slippage > 1) {
    return { error: 'Invalid slippage' }
  }

  return { args: [{ route, slippage }] }
}
export type PreparedZap = ReturnType<typeof prepareZap>
