import { displayCryptoLocale } from '@/util/displayCrypto'
import styled from 'styled-components'
import { Token } from '@/types/tokens'
import { useNowMs, useTimeCountdown } from '@/hooks/useTimeCountdown'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useEffect, useMemo, useState } from 'react'
import { ThemeData } from '@/swell-ui/theme/branding'
import { YearnCancelWithdrawInfo } from '../../ExchangeInfo/YearnCancelWithdrawInfo'
import { Divider } from '@/swell-ui/Divider'
import { yearnWithdrawTypographyCSS } from './common'
import {
  YearnWithdrawAsset,
  YearnWithdrawRequest,
} from '@/state/yearnVault/types'
import { assetsReceivedFromWithdrawal } from '../yearnConversions'
import { BigNumber } from 'ethers'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { yearnWithdrawCountdown } from '../yearnWithdraw'

const TOKEN_IMG_SIZE_PX = 32

export function YearnWithdrawPending({
  withdrawAsset,
  withdrawRequest,
  vaultToken,
  withdrawAssetToken,
  pricePerShare,
}: {
  withdrawRequest: YearnWithdrawRequest
  withdrawAsset: YearnWithdrawAsset | undefined
  vaultToken: Token
  withdrawAssetToken: Token
  pricePerShare: BigNumber | undefined
}) {
  const { assetsAtTimeOfRequest, maturityUnix, shares } = withdrawRequest

  let receivingAmountStr: string | undefined
  if (pricePerShare && withdrawAsset) {
    const receiveAssets = assetsReceivedFromWithdrawal({
      assetsAtTimeOfRequest,
      shares,
      pricePerShare,
      withdrawFeeBasisPoints: withdrawAsset.withdrawFeeBasisPoints,
      vaultTokenDecimals: vaultToken.decimals,
    })
    receivingAmountStr = displayCryptoLocale(
      receiveAssets,
      withdrawAssetToken.decimals,
      { btc: true }
    )
  }
  const withdrawingAmountStr = displayCryptoLocale(
    shares,
    vaultToken.decimals,
    { btc: true }
  )

  const deadlineMs = maturityUnix * 1000

  let withdrawRequestDelayMs: number | undefined
  if (withdrawAsset) {
    withdrawRequestDelayMs = withdrawAsset.withdrawDelaySeconds * 1000
  }

  const hidden = withdrawRequestDelayMs === undefined

  return (
    <Layout style={{ visibility: hidden ? 'hidden' : 'visible' }}>
      <span className="title">Pending</span>
      <PendingViz
        withdrawingToken={vaultToken}
        receivingToken={withdrawAssetToken}
        withdrawingAmount={withdrawingAmountStr}
        receivingAmount={receivingAmountStr}
        deadlineMs={deadlineMs}
        withdrawRequestDelayMs={withdrawRequestDelayMs ?? 0}
      />
      <Divider />
      <YearnCancelWithdrawInfo withdrawAsset={withdrawAsset} />
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${yearnWithdrawTypographyCSS}

  .target-price {
    color: var(--white-125, #b0b0b0);
    /* Body/xsmall bold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 19.2px */
    height: 24px;
  }
`

function PendingViz({
  withdrawingToken,
  deadlineMs,
  receivingAmount,
  receivingToken,
  withdrawingAmount,
  withdrawRequestDelayMs,
}: {
  withdrawingToken: Token
  receivingToken: Token
  withdrawingAmount: string
  receivingAmount: string | undefined
  deadlineMs: number
  withdrawRequestDelayMs: number
}) {
  const countdown = useTimeCountdown(deadlineMs)
  const nowMs = useNowMs()
  const remainingDuration = deadlineMs - nowMs
  const progress = 1 - remainingDuration / withdrawRequestDelayMs

  const { countdownLeft, countdownMiddle, countdownRight } = useMemo(() => {
    if (!countdown)
      return { countdownLeft: '', countdownMiddle: '', countdownRight: '' }

    return yearnWithdrawCountdown({ countdown, withdrawRequestDelayMs })
  }, [countdown, withdrawRequestDelayMs])

  return (
    <VizLayout>
      <FlexRow direction="row" justify="space-between">
        <FlexRow direction="column" align="start" gap="8" width="auto">
          <span className="subheading">Withdrawing</span>
          <span className="big">{withdrawingAmount}</span>
          <FlexRow gap="8" justify="start">
            <TokenIcon
              width={TOKEN_IMG_SIZE_PX}
              height={TOKEN_IMG_SIZE_PX}
              src={withdrawingToken.logoURI}
            />
            <span className="med">{withdrawingToken.symbol}</span>
          </FlexRow>
        </FlexRow>
        <div>
          <ArrowRight />
        </div>
        <FlexRow
          direction="column"
          align="start"
          gap="8"
          width="auto"
          style={{ minWidth: '130px' }}
        >
          <span className="subheading">Receiving</span>
          <AsyncDiv loading={!receivingAmount}>
            {() => <span className="big">{receivingAmount}</span>}
          </AsyncDiv>
          <FlexRow gap="8" justify="start">
            <TokenIcon
              width={TOKEN_IMG_SIZE_PX}
              height={TOKEN_IMG_SIZE_PX}
              src={receivingToken.logoURI}
            />
            <span className="med">{receivingToken.symbol}</span>
          </FlexRow>
        </FlexRow>
      </FlexRow>
      <Divider />
      <FlexRow
        style={{ visibility: countdown ? 'visible' : 'hidden' }}
        direction="column"
        gap="12"
        justify="start"
      >
        <span className="subheading">Time remaining</span>
        <ProgressBar progress={progress} />
        <FlexRow justify="space-between">
          <span>{countdownLeft}</span>
          <span className="emphasis">{countdownMiddle}</span>
          <span>{countdownRight}</span>
        </FlexRow>
      </FlexRow>
    </VizLayout>
  )
}

function ProgressBar({ progress: progressProp }: { progress: number }) {
  const [progress, setProgress] = useState(progressProp)

  useEffect(() => {
    let done = false
    const animate = () => {
      if (done) return

      setProgress((p) => {
        const diff = progressProp - p
        let v = p + diff * 0.1
        v = Math.min(1, v)
        v = Math.max(0, v)
        return v
      })

      requestAnimationFrame(animate)
    }
    animate()
    return () => {
      done = true
    }
  }, [progressProp])

  return (
    <StyledBar>
      <div style={{ clipPath: `inset(0 ${100 - progress * 100}% 0 0)` }} />
    </StyledBar>
  )
}

const StyledBar = styled.div`
  width: 100%;
  height: 16px;
  border: 1px solid var(--black-50, #4a4a4a);
  border-radius: 12px;

  position: relative;
  > div {
    background: ${ThemeData.Symbiotic.symbioticBtcOrange};
    position: absolute;
    inset: 0;
    border-radius: 12px;
  }
`

const VizLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  white-space: nowrap;

  /* center content in loading wrappers */
  *[aria-busy='false'] {
    min-width: unset;
  }
`

const TokenIcon = styled.img`
  width: ${TOKEN_IMG_SIZE_PX}px;
  height: ${TOKEN_IMG_SIZE_PX}px;
  border-radius: 50%;
`

function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="6"
      viewBox="0 0 32 6"
      fill="none"
    >
      <path
        d="M32 3L27 0.113249V5.88675L32 3ZM0 3.5H27.5V2.5H0V3.5Z"
        fill="#F7931A"
      />
    </svg>
  )
}
