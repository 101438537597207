import { useDepositSubmissionDetailsContext } from '@/state/depositSubmission/hooks'
import React, { useMemo } from 'react'

/**
 * When submitting deposits, we wish to update the tab title with the progress
 *
 * @returns tabTitleOverride (if not-null this can be used to overwrite tab title)
 */
export const useDepositSubmissionTabTitle = () => {
  const { details } = useDepositSubmissionDetailsContext()
  const nChunks = details.batches.length

  const currentChunkIdx = React.useMemo(
    () =>
      details.batches.findIndex(
        (c) => c.status === 'prompting' || c.status === 'pending'
      ),
    [details.batches]
  )

  const tabTitleOverride = useMemo<string | undefined>(() => {
    if (currentChunkIdx < 0) return undefined
    return `Submitting keys (${currentChunkIdx + 1}/${nChunks})`
  }, [currentChunkIdx, nChunks])

  return { tabTitleOverride }
}

export default useDepositSubmissionTabTitle
