import React, { useState } from 'react'
import styled from 'styled-components'
import { CaretDownIcon } from '@swell-ui/icons/CaretDownIcon'
import { Chip } from '@swell-ui/Chip'
import { FlexRow } from '@swell-ui/FlexRow'
import { TextInput } from '@swell-ui/inputs/TextInput'
import { WarningIcon } from '@swell-ui/icons/WarningIcon'

interface SlippageToleranceProps {
  slippagePercent: number
  setSlippagePercent: (p: number) => void
  defaultSlippagePercent: number // for resetting the slippage tolerance
  lowSlippagePercentWarning?: number
  highSlippagePercentWarning?: number
}

interface SlippageOption {
  value: number
}

const SlippageKey = styled.div`
  font-weight: 600;
`

const SlippagePickWrapper = styled(FlexRow)`
  margin: 12px 0;
`

const StyledCarat = styled(CaretDownIcon)`
  position: relative;
  top: -1px;
  path {
    fill: ${({ theme }) => theme.colors.white['125']};
  }

  &:hover {
    cursor: pointer;
  }
`

const StyledChip = styled<any>(Chip)`
  ${({ active, theme }) =>
    active &&
    `
    color: ${theme.mainColor};
    border-color: ${theme.mainColor};
  `}
`

const StyledTextInput = styled(TextInput)<{ active: boolean }>`
  width: 70px;

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.mainColor};
    background: transparent;
    border-radius: 100px;
    opacity: 0.4;
  }

  input {
    height: 24px;
    padding: 4px 12px;
    font-size: 12px;
  }

  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.mainColor};
      opacity: 0.7;
    }
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.mainColor};
      opacity: 1;
    }
  }

  ${({ active }) =>
    active &&
    `
    .MuiOutlinedInput-notchedOutline {
      opacity: 1;
    }
  `}
`

const WarningRow = styled(FlexRow)`
  color: ${({ theme }) => theme.colors.lightBlue['50']};

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.lightBlue['50']};
    }
  }
`

const SLIPPAGE_OPTIONS: SlippageOption[] = [
  {
    value: 0.1,
  },
  {
    value: 0.5,
  },
  {
    value: 1,
  },
]

function SlippageTolerance({
  setSlippagePercent,
  slippagePercent,
  defaultSlippagePercent,
  highSlippagePercentWarning = 1,
  lowSlippagePercentWarning = 0.1,
}: SlippageToleranceProps) {
  const [showSelect, setShowSelect] = useState<boolean>(false)

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = parseFloat(e.target.value)
    if (!isNaN(amount)) setSlippagePercent(amount)
  }

  const onInputFocus = (e: any) => {
    const amount = parseFloat(e.target.value)
    if (amount) {
      setSlippagePercent(amount)
    }
  }

  const onInputBlur = (e: any) => {
    if (e.target.value === '') {
      setSlippagePercent(defaultSlippagePercent)
    }
  }

  const handleSelectShow = () => {
    setShowSelect((prev) => !prev)
  }

  let slippageWarning: string | undefined
  if (slippagePercent > highSlippagePercentWarning) {
    slippageWarning =
      'Your transaction may be frontrun and result in an unfavorable swap.'
  }
  if (slippagePercent <= lowSlippagePercentWarning) {
    slippageWarning =
      'Your transaction may fail due to restrictive slippage settings.'
  }

  return (
    <>
      <FlexRow justify="space-between" align="center">
        <SlippageKey>Slippage tolerance</SlippageKey>
        <div>
          {slippagePercent}% <StyledCarat onClick={handleSelectShow} />
        </div>
      </FlexRow>
      {showSelect && (
        <>
          <SlippagePickWrapper align="center" gap="4">
            {SLIPPAGE_OPTIONS.map((option: SlippageOption) => {
              return (
                <StyledChip
                  key={option.value}
                  active={slippagePercent === option.value}
                  label={`${option.value}%`}
                  onClick={() => setSlippagePercent(option.value)}
                  variant="outlined"
                />
              )
            })}
            <StyledTextInput
              active={
                slippagePercent !== 0.1 &&
                slippagePercent !== 0.5 &&
                slippagePercent !== 1
              }
              placeholder="Custom"
              onChange={onInputChange}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
            />
          </SlippagePickWrapper>
          {slippageWarning && (
            <WarningRow align="center" gap="8">
              <WarningIcon />
              <div>{slippageWarning}</div>
            </WarningRow>
          )}
        </>
      )}
    </>
  )
}

export { SlippageTolerance }
