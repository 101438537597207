import React from 'react'
import styled from 'styled-components/macro'
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { CloseIcon } from '../icons/CloseIcon'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'

interface IconButtonProps extends MuiIconButtonProps {
  href?: string
  children?: React.ReactNode
}

const StyledMuiIconButton = styled(MuiIconButton)`
  ${({ theme }) => `
    color: ${theme.button.primary.color};
  `}
`

const ButtonLinkInner = styled.a`
  display: block;
  color: inherit;
`

/* additional prop href: string */
function IconButton({ href, ...props }: IconButtonProps) {
  if (href) {
    const { children, ...restProps }: IconButtonProps = props

    return (
      <StyledMuiIconButton
        disableRipple={true}
        disableFocusRipple={true}
        {...restProps}
      >
        <ButtonLinkInner href={href} target="_blank">
          {children}
        </ButtonLinkInner>
      </StyledMuiIconButton>
    )
  }

  return (
    <StyledMuiIconButton
      disableRipple={true}
      disableFocusRipple={true}
      {...props}
    />
  )
}

function BackIconButton(props: IconButtonProps) {
  return (
    <IconButton {...props}>
      <ArrowBackIcon fontSize="small" />
    </IconButton>
  )
}

function CloseIconButton(props: IconButtonProps) {
  return (
    <IconButton {...props}>
      <CloseIcon fontSize="small" />
    </IconButton>
  )
}

function CopyIconButton(props: IconButtonProps) {
  return (
    <IconButton {...props}>
      <ContentCopyIcon fontSize="small" />
    </IconButton>
  )
}

function EtherscanIconButton(props: IconButtonProps) {
  return (
    <IconButton {...props}>
      <OpenInNewIcon fontSize="small" />
    </IconButton>
  )
}

function DisconnectIconButton(props: IconButtonProps) {
  return (
    <IconButton {...props}>
      <PowerSettingsNewIcon fontSize="small" />
    </IconButton>
  )
}

export {
  IconButton,
  BackIconButton,
  CloseIconButton,
  CopyIconButton,
  EtherscanIconButton,
  DisconnectIconButton,
}
