import { SelectOption } from '@swell-ui/inputs/SelectInput'
import { SORT_DIR, SORT_SEPARATOR } from '@swell-ui/Table/constants'
import { SortOption } from './SortOption'

export const ALL_CHAINS_OPTION: SelectOption = {
  key: -1,
  label: 'All',
}

export const SORT_KEY = {
  CATEGORY: 'category',
  MAX_APY: 'maxApy',
  POSITION_NAME: 'positionName',
  PROTOCOL: 'protocol',
  TVL: 'tvl',
}

export const SORT_OPTIONS = [
  {
    key: `${SORT_KEY.CATEGORY}${SORT_SEPARATOR}${SORT_DIR.ASC}`,
    label: <SortOption label="Category" direction={SORT_DIR.ASC} />,
  },
  {
    key: `${SORT_KEY.CATEGORY}${SORT_SEPARATOR}${SORT_DIR.DESC}`,
    label: <SortOption label="Category" direction={SORT_DIR.DESC} />,
  },
  {
    key: `${SORT_KEY.MAX_APY}${SORT_SEPARATOR}${SORT_DIR.ASC}`,
    label: <SortOption label="Max APY" direction={SORT_DIR.ASC} />,
  },
  {
    key: `${SORT_KEY.MAX_APY}${SORT_SEPARATOR}${SORT_DIR.DESC}`,
    label: <SortOption label="Max APY" direction={SORT_DIR.DESC} />,
  },
  {
    key: `${SORT_KEY.POSITION_NAME}${SORT_SEPARATOR}${SORT_DIR.ASC}`,
    label: <SortOption label="Position name" direction={SORT_DIR.ASC} />,
  },
  {
    key: `${SORT_KEY.POSITION_NAME}${SORT_SEPARATOR}${SORT_DIR.DESC}`,
    label: <SortOption label="Position name" direction={SORT_DIR.DESC} />,
  },
  {
    key: `${SORT_KEY.PROTOCOL}${SORT_SEPARATOR}${SORT_DIR.ASC}`,
    label: <SortOption label="Protocol" direction={SORT_DIR.ASC} />,
  },
  {
    key: `${SORT_KEY.PROTOCOL}${SORT_SEPARATOR}${SORT_DIR.DESC}`,
    label: <SortOption label="Protocol" direction={SORT_DIR.DESC} />,
  },
  {
    key: `${SORT_KEY.TVL}${SORT_SEPARATOR}${SORT_DIR.ASC}`,
    label: <SortOption label="TVL" direction={SORT_DIR.ASC} />,
  },
  {
    key: `${SORT_KEY.TVL}${SORT_SEPARATOR}${SORT_DIR.DESC}`,
    label: <SortOption label="TVL" direction={SORT_DIR.DESC} />,
  },
]
