import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Accordion } from '@swell-ui/Accordion'
import { DownCaratIcon } from '@swell-ui/icons/DownCaratIcon'
import { TableConfig } from '@swell-ui/Table'
import { Typography } from '@swell-ui/Typography'
import { UpCaratIcon } from '@swell-ui/icons/UpCaratIcon'
import { AppStake } from '@/types/stake'
import { StakingPoolActivityTable } from './StakingPoolActivityTable'

interface StakingPoolActivityAccordionProps {
  title: string
  isLoading: boolean
  pagination?: TableConfig['pagination']
  stakes?: AppStake[]
}

const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.03em;

  svg {
    margin-left: 5px;
  }
`

const DetailsContainer = styled.div`
  margin-top: 28px;
`

function StakingPoolActivityAccordionSummary({
  open,
  title,
}: {
  open: boolean
  title: string
}) {
  return (
    <SummaryContainer>
      <Typography variant="body" size="small">
        {title}
      </Typography>
      {!open && <DownCaratIcon />}
      {open && <UpCaratIcon />}
    </SummaryContainer>
  )
}

function StakingPoolActivityAccordion({
  pagination,
  isLoading,
  stakes,
  title,
}: StakingPoolActivityAccordionProps) {
  const [open, setOpen] = useState<boolean>(false)

  const accordionConfig = {
    summary: <StakingPoolActivityAccordionSummary title={title} open={open} />,
    details: (
      <DetailsContainer>
        <StakingPoolActivityTable
          abbreviated={true}
          pagination={pagination}
          stakes={stakes}
          isLoading={isLoading}
        />
      </DetailsContainer>
    ),
  }

  function handleChange() {
    if (!open) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  return <Accordion config={accordionConfig} onChange={handleChange} />
}

export { StakingPoolActivityAccordion }
