import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { TableConfig } from '@swell-ui/Table'
import { Typography } from '@swell-ui/Typography'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { StakingPoolActivityTable } from '@/components/StakingPoolActivity/StakingPoolActivityTable'
import {
  useStakesV3Backend,
  StakesV3BackendParams,
} from '@/services/V3BackendService/hooks'
import { StakeTransactionType } from '@/submodules/v3-shared/ts/connect/swell/v3/staker_pb'

const Wrapper = styled.div`
  padding-top: 70px;
`

const StakerHistoryBox = styled.div`
  min-height: 396px;
  margin-top: 24px;
  padding: 24px 32px;
  border: 1px solid ${({ theme }) => theme.colors.auxGrey};
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.oceanBlue['1255']};
`

const ConnectZone = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
  height: 308px;

  > div:first-child {
    padding-bottom: 24px;
  }

  color: ${({ theme }) => theme.colors.white['150']};
`

const STAKES_PER_PAGE = 9

function StakerTransactionHistory() {
  const { account } = useSwellWeb3()

  const [stakesQueryParams, setStakesQueryParams] =
    useState<StakesV3BackendParams>({
      first: STAKES_PER_PAGE,
      address: account,
      paused: !account,
      stakeTransactionTypes: [
        StakeTransactionType.STAKE,
        StakeTransactionType.RESTAKE,
      ],
    })

  const [currentPage, setCurrentPage] = useState<number>(0)

  // needs to be all integrated into 1 API or will break pagination
  const stakesQuery = useStakesV3Backend(stakesQueryParams)

  useEffect(() => {
    if (account) {
      setStakesQueryParams((prevQueryParams: StakesV3BackendParams) => {
        return {
          ...prevQueryParams,
          address: account,
          paused: !account,
        }
      })
    }
  }, [account, setStakesQueryParams])

  const isLoading = useMemo<boolean>(() => {
    return stakesQuery.isLoading
  }, [stakesQuery.isLoading])

  const stakes = useMemo(() => {
    if (!stakesQuery.data || !account) {
      return undefined
    }

    return stakesQuery.data.stakes
  }, [account, stakesQuery.data])

  // TODO: pagination will break unless restakes includeded in useStakesV3Backend
  const pagination = useMemo<TableConfig['pagination']>(() => {
    if (!stakesQuery.data) {
      return undefined
    }
    return {
      boundaryCount: 1,
      siblingCount: 1,
      count: Math.ceil(stakesQuery.data.count / STAKES_PER_PAGE),
      page: currentPage,
      totalResults: stakesQuery.data.count,
      onChange: (page: number) => {
        setCurrentPage(page)
        setStakesQueryParams((prevQueryParams: StakesV3BackendParams) => {
          return {
            ...prevQueryParams,
            skip: STAKES_PER_PAGE * (page - 1),
          }
        })
      },
    }
  }, [currentPage, stakesQuery.data])

  return (
    <Wrapper>
      <Typography variant="body" size="large" letterSpacing="small">
        Your transaction history
      </Typography>
      <StakerHistoryBox>
        {account && (
          <>
            {(isLoading || (stakes && stakes.length > 0)) && (
              <StakingPoolActivityTable
                isLoading={false}
                stakes={stakes}
                pagination={pagination}
              />
            )}
            {!isLoading && stakes && stakes.length === 0 && (
              <ConnectZone>
                <Typography variant="body" size="medium">
                  No transactions available.
                </Typography>
              </ConnectZone>
            )}
          </>
        )}
        {!account && (
          <ConnectZone>
            <div>Connect wallet to see available data.</div>
            <div>
              <ConnectWalletButton variant="secondary" size="small">
                Connect wallet
              </ConnectWalletButton>
            </div>
          </ConnectZone>
        )}
      </StakerHistoryBox>
    </Wrapper>
  )
}

export { StakerTransactionHistory }
