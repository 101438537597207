import { ContractReceipt } from 'ethers'

export type SerializableContractReceipt = {
  blockHash: ContractReceipt['blockHash']
  blockNumber: ContractReceipt['blockNumber']
  confirmations: ContractReceipt['confirmations']
  contractAddress: ContractReceipt['contractAddress']
  cumulativeGasUsedWei: string
  effectiveGasPriceWei: string
  from: ContractReceipt['from']
  gasUsedWei: string
  to: ContractReceipt['to']
  transactionHash: ContractReceipt['transactionHash']
  transactionIndex: ContractReceipt['transactionIndex']
  type: ContractReceipt['type']
  status: ContractReceipt['status']
}

export const serializeContractReceipt = (
  receipt: ContractReceipt
): SerializableContractReceipt => {
  const {
    blockHash,
    blockNumber,
    confirmations,
    contractAddress,
    cumulativeGasUsed,
    effectiveGasPrice,
    from,
    gasUsed,
    to,
    transactionHash,
    transactionIndex,
    type,
    status,
  } = receipt

  return {
    blockHash,
    blockNumber,
    confirmations,
    contractAddress,
    cumulativeGasUsedWei: cumulativeGasUsed._hex,
    effectiveGasPriceWei: effectiveGasPrice._hex,
    from,
    gasUsedWei: gasUsed._hex,
    to,
    transactionHash,
    transactionIndex,
    type,
    status,
  }
}
