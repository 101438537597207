import {
  useGlobalNotification,
  GLOBAL_NOTIFICATION_TYPES,
} from '@/swell-ui/GlobalNotification'
import { useEffect } from 'react'
import { Web3Call } from './useWeb3Call'

export function useWeb3CallErrorNotify(w3Call: Web3Call) {
  const { error } = w3Call
  const { notify, removeNotification } = useGlobalNotification()

  useEffect(() => {
    if (!error) return
    const id = notify(error, GLOBAL_NOTIFICATION_TYPES.ERROR)
    return () => removeNotification(id)
  }, [error, notify, removeNotification])
}
