import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Stack } from '@swell-ui/Stack'
import { Typography } from '@swell-ui/Typography'
import { TopNavLink } from './TopNavLink'
import { useSwellUiTheme } from '@/swell-ui/theme'
import { useMediaQuery } from '@mui/material'
import { NavGroup, NavPopover } from '@/swell-ui/NavPopover'
import { NavDropdowns } from '@/swell-ui/NavDropdowns'

interface LinkStackLink {
  to: string
  label: React.ReactNode
}

const operatorLinks: LinkStackLink[] = [
  {
    to: '/operators/dashboard',
    label: 'Dashboard',
  },
  {
    to: '/operators/validator-keys',
    label: 'Validator keys',
  },
  {
    to: '/operators/all-operators',
    label: 'All operators',
  },
]

const restakingOperatorLinks: LinkStackLink[] = [
  {
    to: '/restaking-operators/dashboard',
    label: 'Dashboard',
  },
  {
    to: '/restaking-operators/validator-keys',
    label: 'Validator keys',
  },
  {
    to: '/restaking-operators/all-operators',
    label: 'All operators',
  },
]

function TopNavLinkStack({ links }: { links: LinkStackLink[] }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {links.map((link: LinkStackLink) => {
        return (
          <TopNavLink key={link.to} to={link.to}>
            <Typography variant="body" size="medium" fstyle="bold">
              {link.label}
            </Typography>
          </TopNavLink>
        )
      })}
    </Stack>
  )
}

export function TopNavBarLinkStack({ navGroups }: { navGroups: NavGroup[] }) {
  const { theme } = useSwellUiTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Routes>
      <Route
        path="/operators/*"
        element={<TopNavLinkStack links={operatorLinks} />}
      />
      <Route
        path="/restaking-operators/*"
        element={<TopNavLinkStack links={restakingOperatorLinks} />}
      />
      {isMdUp && (
        <Route path="/*" element={<NavDropdowns groups={navGroups} />} />
      )}
    </Routes>
  )
}
