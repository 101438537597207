import React from 'react'

function CreditCardIcon(props: any) {
  return (
    <svg
      {...props}
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 0.125H2.25C1.88533 0.125 1.53559 0.269866 1.27773 0.527728C1.01987 0.785591 0.875 1.13533 0.875 1.5V12.5C0.875 12.8647 1.01987 13.2144 1.27773 13.4723C1.53559 13.7301 1.88533 13.875 2.25 13.875H18.75C19.1147 13.875 19.4644 13.7301 19.7223 13.4723C19.9801 13.2144 20.125 12.8647 20.125 12.5V1.5C20.125 1.13533 19.9801 0.785591 19.7223 0.527728C19.4644 0.269866 19.1147 0.125 18.75 0.125ZM18.75 1.5V3.5625H2.25V1.5H18.75ZM18.75 12.5H2.25V4.9375H18.75V12.5ZM17.375 10.4375C17.375 10.6198 17.3026 10.7947 17.1736 10.9236C17.0447 11.0526 16.8698 11.125 16.6875 11.125H13.9375C13.7552 11.125 13.5803 11.0526 13.4514 10.9236C13.3224 10.7947 13.25 10.6198 13.25 10.4375C13.25 10.2552 13.3224 10.0803 13.4514 9.95136C13.5803 9.82243 13.7552 9.75 13.9375 9.75H16.6875C16.8698 9.75 17.0447 9.82243 17.1736 9.95136C17.3026 10.0803 17.375 10.2552 17.375 10.4375ZM11.875 10.4375C11.875 10.6198 11.8026 10.7947 11.6736 10.9236C11.5447 11.0526 11.3698 11.125 11.1875 11.125H9.8125C9.63016 11.125 9.4553 11.0526 9.32636 10.9236C9.19743 10.7947 9.125 10.6198 9.125 10.4375C9.125 10.2552 9.19743 10.0803 9.32636 9.95136C9.4553 9.82243 9.63016 9.75 9.8125 9.75H11.1875C11.3698 9.75 11.5447 9.82243 11.6736 9.95136C11.8026 10.0803 11.875 10.2552 11.875 10.4375Z"
        fill="white"
      />
    </svg>
  )
}

export { CreditCardIcon }
