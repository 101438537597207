import { Multicall3, SWExit__factory } from '@/abis/types'

export type ExitBalanceList = { exitAddress: string; balance: number }[]
export type OwnedTokenIdsResult = { tokenIds: number[]; exitAddress: string }

export class ExitMulticaller {
  multicall: Multicall3
  constructor(multicall: Multicall3) {
    this.multicall = multicall
  }

  fetchExitBalances = async (
    account: string,
    exitAddresses: string[]
  ): Promise<ExitBalanceList> => {
    const calls: Multicall3.CallStruct[] = []
    for (const exitAddress of exitAddresses) {
      calls.push({
        target: exitAddress,
        callData: SWExit__factory.createInterface().encodeFunctionData(
          'balanceOf',
          [account]
        ),
      })
    }

    const results = await this.multicall.callStatic.tryAggregate(true, calls)

    const exitBalances: ExitBalanceList = []
    for (let i = 0; i < results.length; i++) {
      const balance = SWExit__factory.createInterface()
        .decodeFunctionResult('balanceOf', results[i].returnData)[0]
        .toNumber()
      exitBalances.push({ exitAddress: exitAddresses[i], balance })
    }

    return exitBalances
  }

  fetchOwnedTokenIds = async (
    account: string,
    exitBalanceList: ExitBalanceList
  ): Promise<OwnedTokenIdsResult[]> => {
    const calls: Multicall3.CallStruct[] = []
    const indexToExitAddress = new Map<number, string>()

    let callIndex = 0
    for (const { exitAddress, balance } of exitBalanceList) {
      for (let i = 0; i < balance; i++) {
        indexToExitAddress.set(callIndex, exitAddress)
        calls.push({
          target: exitAddress,
          callData: SWExit__factory.createInterface().encodeFunctionData(
            'tokenOfOwnerByIndex',
            [account, i]
          ),
        })
        callIndex++
      }
    }

    const results = await this.multicall.callStatic.tryAggregate(true, calls)

    const exitAddressToTokenIds = new Map<string, number[]>()
    for (let i = 0; i < results.length; i++) {
      const exitAddress = indexToExitAddress.get(i)
      if (!exitAddress) throw new Error('exit address not found')
      const tokenId = SWExit__factory.createInterface()
        .decodeFunctionResult('tokenOfOwnerByIndex', results[i].returnData)[0]
        .toNumber()

      if (exitAddressToTokenIds.has(exitAddress)) {
        exitAddressToTokenIds.get(exitAddress)?.push(tokenId)
      } else {
        exitAddressToTokenIds.set(exitAddress, [tokenId])
      }
    }

    const ownedTokenIds: OwnedTokenIdsResult[] = []
    exitAddressToTokenIds.forEach((tokenIds, exitAddress) => {
      ownedTokenIds.push({ tokenIds, exitAddress })
    })

    return ownedTokenIds
  }
}
