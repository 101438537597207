export type GeoFenceResult = {
  isRestricted: boolean
  countryCode: string
  region: string
  ip: string
  timestamp: string
}

export type GeofencePermission = 'ok' | 'blocked' | 'blocked_airdrops'
export const GeofencePermission = {
  Ok: 'ok' as GeofencePermission,
  Blocked: 'blocked' as GeofencePermission,
  BlockedAirdrops: 'blocked_airdrops' as GeofencePermission,
}
