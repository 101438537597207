import useSWRImmutable from 'swr/immutable'
import { useBtcLrtVaultApi } from './context'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useWeb3Call } from '@/hooks/useWeb3Call'
import {
  getApproveGasEstimate,
  getYearnCancelWithdrawGasEstimate,
  getYearnCompleteWithdrawGasEstimate,
  getYearnDepositGasEstimate,
  getYearnRequestWithdrawGasEstimate,
} from '@/constants/gasEstimates'

type UseYearnVaultAPi = typeof useBtcLrtVaultApi

function makeYearnVaultHooks(_key: string, useYearnVaultApi: UseYearnVaultAPi) {
  // preconfigured assets, no assurances for supported states
  function useStaticTokens() {
    const { depositAsset, vaultToken } = useYearnVaultApi()
    return {
      depositAsset,
      vaultToken,
    }
  }

  // global API
  function useVaultStats() {
    const { chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable([_key, 'vaultStats', chainId], () =>
      api.read.vaultStats()
    )
  }
  function useSupportedAssets() {
    const { chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable([_key, 'supportedAssets', chainId], () =>
      api.read.supportedAssets()
    )
  }
  function useRates() {
    const { chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable([_key, 'rates', chainId], () => api.read.rates())
  }
  function usePaused() {
    const { chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable([_key, 'paused', chainId], () => api.read.paused())
  }

  // user API
  function useBalances() {
    const { account, chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable(
      account ? [_key, 'balances', account, chainId] : null,
      () => api.read.balances()
    )
  }
  function useAllowances() {
    const { account, chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable(
      account ? [_key, 'allowances', account, chainId] : null,
      () => api.read.allowances()
    )
  }
  function useAuth() {
    const { account, chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable(
      account ? [_key, 'auth', account, chainId] : null,
      () => api.read.auth()
    )
  }
  function useWithdrawRequest() {
    const { account, chainId } = useSwellWeb3()
    const api = useYearnVaultApi()
    return useSWRImmutable(
      account ? [_key, 'withdrawRequest', account, chainId] : null,
      () => api.read.withdrawRequest()
    )
  }

  // web3 calls
  function useDeposit() {
    const api = useYearnVaultApi()
    return useWeb3Call({
      estimateGas: api.write.depositEstimateGas,
      fn: api.write.deposit,
      staticGasEstimate: () => getYearnDepositGasEstimate(),
      validate: async (params) => {
        if (!params.amount) throw new Error('no amount')
        const ok = await api.read.checkDeposit(params)
        if (!ok) throw new Error('external validation failed')
        return null
      },
    })
  }
  function useApproveAssetForDeposit() {
    const api = useYearnVaultApi()
    return useWeb3Call({
      estimateGas: api.write.approveAssetForDepositEstimateGas,
      fn: api.write.approveAssetForDeposit,
      staticGasEstimate: () => getApproveGasEstimate(),
      validate: async (params) => {
        if (!params.amount) throw new Error('no amount')
        return null
      },
    })
  }
  function useRequestWithdraw() {
    const api = useYearnVaultApi()
    return useWeb3Call({
      estimateGas: api.write.requestWithdrawEstimateGas,
      fn: api.write.requestWithdraw,
      staticGasEstimate: () => getYearnRequestWithdrawGasEstimate(),
      validate: async (params) => {
        if (!params.shares) throw new Error('no shares')
        if (typeof params.maxLossBasisPoints !== 'number')
          throw new Error('no maxLossBasisPoints')
        return null
      },
    })
  }
  function useApproveVaultTokenForWithdraw() {
    const api = useYearnVaultApi()
    return useWeb3Call({
      estimateGas: api.write.approveVaultTokenForWithdrawEstimateGas,
      fn: api.write.approveVaultTokenForWithdraw,
      staticGasEstimate: () => getApproveGasEstimate(),
      validate: async (params) => {
        if (!params.amount) throw new Error('no amount')
        return null
      },
    })
  }
  function useCancelWithdraw() {
    const api = useYearnVaultApi()
    return useWeb3Call({
      estimateGas: api.write.cancelWithdrawEstimateGas,
      fn: api.write.cancelWithdraw,
      staticGasEstimate: () => getYearnCancelWithdrawGasEstimate(),
      validate: async () => null,
    })
  }
  function useCompleteWithdraw() {
    const api = useYearnVaultApi()
    return useWeb3Call({
      estimateGas: api.write.completeWithdrawEstimateGas,
      fn: api.write.completeWithdraw,
      staticGasEstimate: () => getYearnCompleteWithdrawGasEstimate(),
      validate: async () => null,
    })
  }

  return {
    useStaticTokens,
    useVaultStats,
    useSupportedAssets,
    useRates,
    usePaused,
    useBalances,
    useAllowances,
    useAuth,
    useWithdrawRequest,
    useDeposit,
    useApproveAssetForDeposit,
    useRequestWithdraw,
    useApproveVaultTokenForWithdraw,
    useCancelWithdraw,
    useCompleteWithdraw,
  }
}

export const {
  useStaticTokens: useBtcLrtStaticTokens,
  useVaultStats: useBtcLrtVaultStats,
  useSupportedAssets: useBtcLrtSupportedAssets,
  useRates: useBtcLrtRates,
  usePaused: useBtcLrtPaused,
  useBalances: useBtcLrtBalances,
  useAllowances: useBtcLrtAllowances,
  useAuth: useBtcLrtAuth,
  useWithdrawRequest: useBtcLrtWithdrawRequest,
  useDeposit: useBtcLrtDeposit,
  useApproveAssetForDeposit: useBtcLrtApproveAssetForDeposit,
  useRequestWithdraw: useBtcLrtRequestWithdraw,
  useApproveVaultTokenForWithdraw: useBtcLrtApproveVaultTokenForWithdraw,
  useCancelWithdraw: useBtcLrtCancelWithdraw,
  useCompleteWithdraw: useBtcLrtCompleteWithdraw,
} = makeYearnVaultHooks('btc-lrt', useBtcLrtVaultApi)

export type YearnDeposit = ReturnType<typeof useBtcLrtDeposit>
export type YearnApproveAssetForDeposit = ReturnType<
  typeof useBtcLrtApproveAssetForDeposit
>
export type YearnRequestWithdraw = ReturnType<typeof useBtcLrtRequestWithdraw>
export type YearnApproveVaultTokenForWithdraw = ReturnType<
  typeof useBtcLrtApproveVaultTokenForWithdraw
>
export type YearnCancelWithdraw = ReturnType<typeof useBtcLrtCancelWithdraw>
export type YearnCompleteWithdraw = ReturnType<typeof useBtcLrtCompleteWithdraw>
