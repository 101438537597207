import React, { createContext, useCallback, useContext, useState } from 'react'
import { Alert, ALERT_LEVELS } from '../Alert'
import { uniqueId } from '../lib/uniqueId'

interface NotificationConfig {
  id: string
  text: string | React.ReactNode
  type: 'error' | 'success'
}

interface GlobalNotifcationContextType {
  notificationList: NotificationConfig[]
  notify: (arg1: string | React.ReactNode, arg2: 'error' | 'success') => string
  removeNotification: (arg: string) => void
}

interface GlobalNotificationProviderProps {
  children: React.ReactNode
}

const GLOBAL_NOTIFICATION_TYPES = ALERT_LEVELS

const initialContext: GlobalNotifcationContextType = {
  notificationList: [],
  notify: () => {
    return ''
  },
  removeNotification: () => {},
}

const GlobalNotificationContext = createContext(initialContext)

function GlobalNotificationProvider({
  children,
}: GlobalNotificationProviderProps) {
  const [notificationList, setNotificationList] = useState<
    NotificationConfig[]
  >([])

  const notify = useCallback(
    (text: string | React.ReactNode, type: 'error' | 'success') => {
      const id = uniqueId()
      const notificationConfig: NotificationConfig = {
        id,
        text,
        type,
      }

      setNotificationList((prevList) => [notificationConfig, ...prevList])

      return id
    },
    []
  )

  const removeNotification = useCallback((id: string) => {
    setNotificationList((prevList) => {
      const index = prevList.findIndex((notification) => {
        return (notification.id = id)
      })

      const newList = prevList.slice(0, index).concat(prevList.slice(index + 1))

      return newList
    })
  }, [])

  return (
    <GlobalNotificationContext.Provider
      value={{
        notificationList,
        notify,
        removeNotification,
      }}
    >
      {children}
    </GlobalNotificationContext.Provider>
  )
}

function useGlobalNotification() {
  return useContext(GlobalNotificationContext)
}

function GlobalNotificationContainer(props: any) {
  const { notificationList, removeNotification } = useGlobalNotification()

  return (
    <div {...props}>
      {notificationList.map((config: NotificationConfig) => {
        return (
          <Alert
            key={config.id}
            level={config.type}
            onClose={() => {
              removeNotification(config.id)
            }}
          >
            <div>{config.text}</div>
          </Alert>
        )
      })}
    </div>
  )
}

export {
  GlobalNotificationContext,
  GlobalNotificationProvider,
  GlobalNotificationContainer,
  useGlobalNotification,
  GLOBAL_NOTIFICATION_TYPES,
}
