import React from 'react'
import styled from 'styled-components/macro'

const BG = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: #020202; */
  background: linear-gradient(180deg, #090d14 0%, #1b2445 131.8%);
`

export function BtcLrtBackground({ pattern }: { pattern?: boolean }) {
  return (
    <>
      <BG />
      {pattern && <Pattern />}
    </>
  )
}

function Pattern() {
  return <div></div>
}
