import React, { ReactNode, useMemo } from 'react'
import { formatUnits } from 'ethers/lib/utils'
import { Typography } from '@swell-ui/Typography'
import { displayFiat } from '@/util/displayFiat'
import { BigNumber } from 'ethers'
import { TokenList } from '@/types/tokens'

export function TotalDeposited({
  rates,
  tokenList,
  tokenStats,
}: {
  tokenList: TokenList
  tokenStats: Record<string, { totalStaked: BigNumber }>
  rates: Record<string, number>
}) {
  const addrToDecimalMap = new Map<string, number>()
  tokenList?.tokens.forEach((token) => {
    addrToDecimalMap.set(token.address, token.decimals)
  })
  const addrToRateMap = new Map<string, number>(Object.entries(rates))
  const addrToTotalStakedMap = new Map<string, BigNumber>()
  Object.entries(tokenStats).forEach(([tokenAddress, tokenStat]) => {
    addrToTotalStakedMap.set(tokenAddress, tokenStat.totalStaked)
  })

  const depositedAddrs = Array.from(addrToTotalStakedMap.keys())

  let total = 0
  for (const addr of depositedAddrs) {
    const decimals = addrToDecimalMap.get(addr)
    if (!decimals) {
      console.warn('missing decimals for token', addr, addrToDecimalMap)
      continue
    }

    const deposited = addrToTotalStakedMap.get(addr)
    if (!deposited) {
      console.warn('missing deposited for token', addr)
      continue
    }

    const rate = addrToRateMap.get(addr)
    if (!rate) {
      console.warn('missing rate for token', addr)
      continue
    }

    const depositedFloat = parseFloat(formatUnits(deposited, decimals))
    const depositedUsd = depositedFloat * rate
    total += depositedUsd
  }

  return (
    <Typography variant="headline" size="h3">
      {displayFiat(total)}
    </Typography>
  )
}
