import React from 'react'
import styled from 'styled-components/macro'
import l2ShapeUrl from '@/assets/images/L2-shape.png'
import noiseUrl from '@/assets/images/noise.png'
import { useSwellUiTheme } from '@/swell-ui/theme'
import { useMediaQuery } from '@mui/material'

const BG = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #020202;
`

const HeroBG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
`

// left-most
const EllipseOne = styled.div`
  display: none;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: inherit;
  }

  position: absolute;
  width: 1034.887px;
  height: 642.705px;

  top: 0;
  left: 50%;

  border-radius: 1034.887px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(89, 78, 200, 0.5) 0%,
    rgba(89, 78, 200, 0) 100%
  );
  /* background: cyan; */
  filter: blur(100px);

  transform: translateX(-50%) translateY(-50%) translateX(-42%) translateY(42%)
    rotate(12.535deg);
`

// right-most
const EllipseTwo = styled.div`
  position: absolute;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(89, 78, 200, 0.5) 0%,
    rgba(89, 78, 200, 0) 100%
  );

  transform: translateX(50%) translateY(-50%) rotate(-17.91deg);
  top: 4em;
  right: 4em;
  width: 512.695px;
  height: 355.042px;

  border-radius: 512.695px;
  filter: blur(55px);

  ${({ theme }) => theme.breakpoints.up('md')} {
    top: 0;
    right: 0;
    transform: translateX(-50%) translateY(-50%) translateX(80%) translateY(18%)
      rotate(-17.91deg);
    width: 900.037px;
    height: 623.277px;

    border-radius: 900.037px;
    filter: blur(100px);
  }
`

const L2Shape = styled.img`
  mix-blend-mode: screen;
`

const L2ShapeShade = styled.div`
  position: absolute;
  top: 24px;
  left: 71px;
  width: 303px;
  height: 449px;
  background: linear-gradient(91deg, #070609 0.59%, rgba(7, 6, 9, 0) 99.33%);

  /* prevent square from being visible */
  -webkit-mask: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0) 100%
  );
`

const ShapePositioner = styled.div`
  position: absolute;

  > div {
    position: relative;
  }

  top: 100px;
  left: 50%;

  width: 547px;
  height: 506px;
  transform: translateX(-50%) translateY(-1em) scale(0.55); /* 304/547 from figma */
  transform-origin: 50% 0%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    transform-origin: unset;

    width: 547px;
    height: 506px;
    transform: translateX(66px);
  }
`

const Noise = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.4;
`

const FaqEllipse = styled.div<{ pageHeight: number }>`
  display: ${(props) => (props.pageHeight === 0 ? 'none' : 'inherit')};
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  height: ${(props) => props.pageHeight}px;

  overflow: hidden;

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1456.168px;
    height: 304.763px;
    transform: translate(-50%, 50%) translateY(-150%) rotate(-1.284deg);
    border-radius: 1456.168px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(89, 78, 200, 0.5) 0%,
      rgba(89, 78, 200, 0) 100%
    );
    filter: blur(100px);
  }
`

export function L2Background() {
  const { theme } = useSwellUiTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const [pageHeight, setPageHeight] = React.useState(0)
  React.useEffect(() => {
    let done = false

    const update = () => {
      setPageHeight(document.body.scrollHeight)
      if (!done) {
        return requestAnimationFrame(update)
      }
    }

    requestAnimationFrame(update)
    return () => {
      done = true
    }
  }, [])

  const [lockedHeight, setLockedHeight] = React.useState(0)
  React.useEffect(() => {
    if (lockedHeight !== 0) {
      if (pageHeight < lockedHeight) {
        setLockedHeight(pageHeight)
      }

      return
    }

    setLockedHeight(pageHeight)
  }, [lockedHeight, pageHeight])

  const isMdUpRef = React.useRef(isMdUp)

  React.useEffect(() => {
    if (isMdUpRef.current !== isMdUp) {
      setLockedHeight(0) // reset height if breakpoint changes majorly
    }

    isMdUpRef.current = isMdUp
  }, [isMdUp])

  return (
    <>
      <BG />
      <HeroBG>
        <ShapePositioner>
          <div>
            <L2Shape src={l2ShapeUrl} />
            <L2ShapeShade />
          </div>
        </ShapePositioner>
        <EllipseOne />
        <EllipseTwo />
      </HeroBG>
      {/* position blobs based on first observed page height to prevent movement if resized */}
      {lockedHeight && <FaqEllipse pageHeight={lockedHeight} />}
      <Noise src={noiseUrl} />
    </>
  )
}
