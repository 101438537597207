import React from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import {
  useUserOperatorFeesEarnedTransactions,
  useUserOperatorId,
} from '@/state/restakingUserOperator/hooks'
import { FeesEarnedCommon } from './FeesEarnedCommon'
import { IFeesEarnedTableProps } from './FeesEarnedCommon/FeesEarnedTable'

export function RestakingFeesEarned() {
  const { account } = useSwellWeb3()
  const userOperatorIdQuery = useUserOperatorId()
  const userOperatorFeesEarnedQuery = useUserOperatorFeesEarnedTransactions({
    account,
  })

  const userOperatorId = userOperatorIdQuery.data?.operatorId.toNumber()

  const tableProps = React.useMemo<IFeesEarnedTableProps>(() => {
    if (!account) return { feesEarnedTransactions: [] }

    return {
      feesEarnedTransactions:
        userOperatorFeesEarnedQuery.data?.feesEarnedTransactions,
    }
  }, [account, userOperatorFeesEarnedQuery.data?.feesEarnedTransactions])

  return (
    <FeesEarnedCommon tableProps={tableProps} userOperatorId={userOperatorId} />
  )
}
