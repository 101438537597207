import { useEffect } from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import {
  GLOBAL_NOTIFICATION_TYPES,
  useGlobalNotification,
} from '@/swell-ui/GlobalNotification'
import { useWhitelistForAddress } from '@/state/staking/hooks'

export enum StakingEnabledMessage {
  NOT_WHITELISTED = 'NOT_WHITELISTED',
}

export type StakingEnabledInfo =
  | {
      enabled: false
      message?: string
    }
  | {
      enabled: true
      message?: undefined
    }

export const useStakingEnabled = () => {
  const { account } = useSwellWeb3()
  const { data, ...whitelistForAddressQuery } = useWhitelistForAddress(account)

  return {
    ...whitelistForAddressQuery,
    get data(): StakingEnabledInfo | undefined {
      if (data === undefined) return undefined
      const { whitelistEnabled, whitelisted } = data

      if (whitelistEnabled && !whitelisted) {
        return {
          enabled: false,
          message: StakingEnabledMessage.NOT_WHITELISTED,
        }
      }

      return { enabled: true }
    },
  }
}

export function useStakingEnabledWithWarning() {
  const stakingEnabledQuery = useStakingEnabled()
  const { notify, removeNotification } = useGlobalNotification()
  const { data: stakingEnabledData } = stakingEnabledQuery

  useEffect(() => {
    // distinct from undefined, which implies the enabled state has not loaded
    if (stakingEnabledData?.enabled === false) {
      let msg: string
      switch (stakingEnabledData.message) {
        case StakingEnabledMessage.NOT_WHITELISTED: {
          msg = 'This address is not a whitelisted staking address.'
          break
        }
        default:
          msg = 'Staking is currently disabled.'
          break
      }

      const nId = notify(msg, GLOBAL_NOTIFICATION_TYPES.ERROR)

      return () => {
        removeNotification(nId)
      }
    }
  }, [
    notify,
    removeNotification,
    stakingEnabledData?.message,
    stakingEnabledData?.enabled,
  ])

  return stakingEnabledQuery
}
