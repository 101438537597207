import { DepositInfo } from '@/types/deposits'
import { ZodError } from 'zod'

export type DepositProblemCode =
  | 'invalid-json'
  | 'not-array'
  | 'too-big'
  | 'too-small'
  // Backend performs schema validation, but does not specify why there was a failure
  //  (e.g. invalid-string-length will result in invalid-data-format on backend)
  | 'invalid-data-format'
  // Schema validation on the frontend provides more information regarding validation errors
  | 'schema-fail'
  // data checks
  | 'invalid-string-length'
  | 'invalid-amount'
  | 'invalid-cli-version'
  | 'incorrect-fork-version'
  | 'incorrect-withdrawal-credentials'
  | 'no-assigned-withdrawal-addresses'
  // datum specific (async)
  | 'deposit-roots-fail'
  | 'signature-fail'
  | 'duplication-fail' // backend-only
  // catch-all
  | 'something-went-wrong'

type InvalidStringLengthMeta = {
  invalidPairs: {
    key: string
    expected: number
    received: number
  }[]
}

export type DepositProblem =
  | {
      code: Exclude<DepositProblemCode, 'invalid-string-length' | 'schema-fail'>
      meta?: any
    }
  | {
      code: 'schema-fail'
      meta: ZodError<DepositInfo>['errors'][number]
    }
  | {
      code: 'invalid-string-length'
      meta: InvalidStringLengthMeta
    }

export const depositProblemToMessage = (config: DepositProblem): string => {
  switch (config.code) {
    case 'no-assigned-withdrawal-addresses':
      return 'No withdrawal addresses assigned for operator'
    case 'invalid-json':
      return 'Invalid JSON'
    case 'not-array':
      return 'Data must be an array'
    case 'too-big':
      return `Provided data is too big.`
    case 'too-small':
      return 'An empty list was provided.'
    case 'invalid-data-format':
      return 'Invalid data format'
    case 'schema-fail': {
      const { meta } = config

      if (meta.path.length) return `${meta.message} (${meta.path.join('.')})`

      return meta.message
    }
    case 'invalid-string-length': {
      const { invalidPairs } = config.meta
      // just use the first
      const { expected, key, received } = invalidPairs[0]
      return `Key ${key} is of length ${received} (expected ${expected} without 0x).`
    }
    case 'invalid-amount':
      return `Invalid amount of ether deposited`
    case 'invalid-cli-version':
      return 'Invalid CLI version'
    case 'deposit-roots-fail':
      return 'Checking deposit data roots failed'
    case 'signature-fail':
      return 'Signature verification failed'
    case 'duplication-fail':
      return 'These deposit keys are already in use'
    case 'incorrect-fork-version':
      return 'Incorrect fork version'
    case 'incorrect-withdrawal-credentials':
      return 'Incorrect withdrawal credentials'

    case 'something-went-wrong':
      return 'Something went wrong'
    default:
      throw new Error(`invalid config.code: ${(config as any).code}`)
  }
}
