function buildChains(rpcUrl: string) {
  const ethMainnet = {
    id: '0x1',
    token: 'ETH',
    label: 'Ethereum',
    rpcUrl: rpcUrl,
  }

  const ethGoerli = {
    id: '0x5',
    token: 'GoerliETH',
    label: 'Goerli',
    rpcUrl: rpcUrl,
  }

  return [ethMainnet, ethGoerli]
}

export { buildChains }
