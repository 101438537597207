import {
  TOKEN_LIST_ETH,
  TOKEN_LIST_RSWETH,
  TOKEN_LIST_SWETH,
} from '@/constants/tokens'
import { Token } from '@/types/tokens'

export function isEthAddressAlias(address: string) {
  return address.toLowerCase() === TOKEN_LIST_ETH.address.toLowerCase()
}

export function isEth(token: Token) {
  return token.address.toLowerCase() === TOKEN_LIST_ETH.address.toLowerCase()
}

export function isProtocolToken(token: Token) {
  return (
    token.address.toLowerCase() === TOKEN_LIST_SWETH.address.toLowerCase() ||
    token.address.toLowerCase() === TOKEN_LIST_RSWETH.address.toLowerCase()
  )
}

export function isProtocolTokenAddress(address: string) {
  return (
    address.toLowerCase() === TOKEN_LIST_SWETH.address.toLowerCase() ||
    address.toLowerCase() === TOKEN_LIST_RSWETH.address.toLowerCase()
  )
}
