import React from 'react'
import TimeAgo from 'react-timeago'
import enStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

// TODO: construct based on i18n settings
const formatter = buildFormatter(enStrings)

export interface ITimeDeltaProps {
  date: string | number | Date
}

function TimeDelta({ date }: ITimeDeltaProps) {
  return <TimeAgo date={date} formatter={formatter} />
}

export { TimeDelta }
