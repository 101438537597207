import React from 'react'
import styled from 'styled-components'
import { FlexRow } from '../FlexRow'

const LogoTypography = styled.div`
  color: ${({ theme }) => theme.mainColor};
  font-size: 27.39px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

function LogoSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
    >
      <path
        d="M48.8564 30.9017L48.792 30.9694C48.7619 31.0005 48.7314 31.0314 48.7006 31.0621L31.0382 48.6961C27.9614 51.768 22.9729 51.768 19.8961 48.6961L15.0186 43.8267C14.6728 43.4828 14.6721 42.9236 15.0163 42.5782C15.1176 42.4766 15.2421 42.4012 15.379 42.3584C20.6069 40.7446 24.8548 38.8817 28.1225 36.7699C35.9047 31.7403 42.816 29.7843 48.8564 30.9017ZM45.1535 16.3958C51.6746 18.4507 50.9135 23.2479 51 25.1391C43.8584 21.3943 35.2669 22.7669 25.2254 29.2566C17.741 34.0937 11.4716 36.088 6.41729 35.2395C1.36298 34.391 0.226028 28.2183 0 26.578C5.55377 29.3861 12.9966 27.7744 22.3283 21.7434C31.024 16.1235 38.6324 14.3409 45.1535 16.3958ZM31.0382 2.30389L35.6212 6.87887C35.9664 7.2233 35.9669 7.78255 35.6225 8.12778C35.4996 8.25096 35.3431 8.33506 35.1726 8.36942C28.538 9.706 23.232 11.6596 19.2546 14.2302C12.4389 18.6351 6.63077 20.6825 1.83029 20.3724L1.83081 20.3711L19.8961 2.30389C22.9729 -0.767963 27.9614 -0.767963 31.0382 2.30389Z"
        fill="url(#paint0_linear_3693_120)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3693_120"
          x1="25.5"
          y1="-1.49012e-08"
          x2="25"
          y2="86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3068EF" />
          <stop offset="1" stopColor="#1322AC" />
        </linearGradient>
      </defs>
    </svg>
  )
}

function Logo() {
  return (
    <FlexRow gap="12">
      <LogoSvg />
      <LogoTypography>Swell</LogoTypography>
    </FlexRow>
  )
}

export { Logo }
