import React from 'react'
import styled from 'styled-components/macro'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import cityImageUrl from '@/assets/images/voyage/tge-bg.png'

const IMG_OFFSET_Y = 0

const Layout = styled.div`
  background-color: #00000e;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-image: url(${cityImageUrl});
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-attachment: local;
    height: calc(100% + ${IMG_OFFSET_Y}px);

    transform: translate(0, -${IMG_OFFSET_Y}px);
    overflow: visible;
  }
`

const BG = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const CenteredOverlays = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  > div {
    position: relative;
    max-width: 2024px;
    margin: 0 auto;
    height: 100%;
  }
`

const LeftGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, #00000e 0%, rgba(1, 0, 35, 0) 100%);
  width: 479px;
  z-index: 6;
`
const LeftSolid = styled.div`
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  background: #00000e;
  width: 50vw;
  z-index: 6;
`

const RightGradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(90deg, rgba(1, 0, 35, 0) 0%, #00000e 100%);
  width: 479px;
  z-index: 6;
`
const RightSolid = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  background: #00000e;
  width: 50vw;
  z-index: 6;
`

function VoyageBackground() {
  const is2025Up = useMediaQuery('(min-width:2025px)')

  return (
    <Layout data-qa="bg">
      <div>
        <BG />
        <CenteredOverlays>
          <div>
            {is2025Up && (
              <>
                <LeftGradient />
                <LeftSolid />
                <RightGradient />
                <RightSolid />
              </>
            )}
          </div>
        </CenteredOverlays>
      </div>
    </Layout>
  )
}

export { VoyageBackground }
