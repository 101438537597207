import { DepositCollection } from '@/types/deposits'
import { SerializableContractReceipt } from '@/util/transactionSerialization'

/**
 * The submission status of a single chunk of deposits
 */
export enum DepositSubmissionStatus {
  NOT_STARTED = 'not-started',
  PROMPTING = 'prompting',
  PENDING = 'pending',
  ERROR = 'error',
  SUBMITTED = 'submitted',
  EXTERNALLY_MANAGED = 'externally-managed', // e.g. gnosis
}

export interface IDepositSubmissionDetails {
  chunk: DepositCollection
  chunkIdx: number
  txHash?: string
  receipt?: SerializableContractReceipt
  error?: string
  status: DepositSubmissionStatus
}

/**
 * Lens-layer logic which pulls together disparate data from the deposit submission store to
 *  provide contextual information about a chunk in one data structure
 */
export const DepositSubmissionDetails = {
  _create: ({
    chunkIdx,
    chunk,
    txHash,
    prompting,
    error,
    receipt,
    external,
  }: {
    chunkIdx: number
    txHash?: string
    receipt?: SerializableContractReceipt
    chunk: DepositCollection
    prompting: boolean
    error?: string
    external?: boolean
  }): IDepositSubmissionDetails => {
    const { status: receiptStatus } = receipt ?? {}

    return {
      chunk,
      chunkIdx,
      txHash,
      receipt,
      get error() {
        if (error) return error

        if (receiptStatus === 0) {
          // Not enough information to determine why
          return 'TX Failed'
        }

        return undefined
      },
      get status(): DepositSubmissionStatus {
        if (external) return DepositSubmissionStatus.EXTERNALLY_MANAGED
        if (this.error) return DepositSubmissionStatus.ERROR
        if (prompting) return DepositSubmissionStatus.PROMPTING
        if (!this.txHash) return DepositSubmissionStatus.NOT_STARTED
        if (!receipt) return DepositSubmissionStatus.PENDING
        return DepositSubmissionStatus.SUBMITTED
      },
    }
  },
  fromTransaction: ({
    chunkIdx,
    txHash,
    chunk,
  }: {
    chunkIdx: number
    txHash: string
    chunk: DepositCollection
  }) => {
    return DepositSubmissionDetails._create({
      chunkIdx,
      txHash,
      chunk,
      prompting: false,
    })
  },
  fromReceipt: ({
    chunkIdx,
    txHash,
    chunk,
    receipt,
  }: {
    chunkIdx: number
    txHash: string
    receipt: SerializableContractReceipt
    chunk: DepositCollection
  }) => {
    return DepositSubmissionDetails._create({
      chunkIdx,
      txHash,
      chunk,
      prompting: false,
      receipt,
    })
  },
  asNotStarted: ({
    chunkIdx,
    chunk,
  }: {
    chunkIdx: number
    chunk: DepositCollection
  }) => {
    return DepositSubmissionDetails._create({
      chunkIdx,
      chunk,
      prompting: false,
    })
  },
  asPrompting: ({
    chunkIdx,
    chunk,
  }: {
    chunkIdx: number
    chunk: DepositCollection
  }) => {
    return DepositSubmissionDetails._create({
      chunkIdx,
      chunk,
      prompting: true,
    })
  },
  fromError: ({
    chunkIdx,
    chunk,
    error,
  }: {
    chunkIdx: number
    chunk: DepositCollection
    error: string
  }) => {
    return DepositSubmissionDetails._create({
      chunkIdx,
      chunk,
      prompting: false,
      error,
    })
  },
  asExternal: ({
    chunk,
    chunkIdx,
  }: {
    chunkIdx: number
    chunk: DepositCollection
  }) => {
    return DepositSubmissionDetails._create({
      chunk,
      chunkIdx,
      prompting: false,
      external: true,
    })
  },
}
