import {
  useRestakingDepositSubmissionHistoricState,
  useRestakingDepositSubmissionActions,
  useBatchRestakingDepositSubmissionStatus,
  BatchDepositSubmissionStatus,
} from '@/state/restakingDepositSubmission/hooks'
import { useEffect } from 'react'

/**
 * Responsible for updating the state for whether a user has viewed the final
 *  outcome of a submission (either historic or successfully completed)
 */
export const useViewedRestakingDepositSubmissionUpdater = () => {
  const { submissionStatus } = useBatchRestakingDepositSubmissionStatus()
  const { isHistoric, hasViewedResult } =
    useRestakingDepositSubmissionHistoricState()
  const { onViewedFinalSubmission } = useRestakingDepositSubmissionActions()

  useEffect(() => {
    if (hasViewedResult) return
    if (
      isHistoric ||
      submissionStatus === BatchDepositSubmissionStatus.FINISHED
    ) {
      onViewedFinalSubmission()
    }
  }, [hasViewedResult, isHistoric, onViewedFinalSubmission, submissionStatus])
}
