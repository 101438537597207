import { useGetAllStatsV3Backend } from '@/services/V3BackendService/hooks'
import { useRswEthUsdMarketRate } from '@/state/fiat/hooks'
import { useRswEthTotalSupply } from './useRswEthTotalSupply'
import { useConvertRswEthToFiat } from './useCurrencyConversions'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useRswEthMarketCapOnChain = () => {
  const { data: rswEthUsdMarketRateData, ...rswEthUsdMarketRateQuery } =
    useRswEthUsdMarketRate()
  const { data: rswEthTotalSupplyData, ...rswEthTotalSupplyQuery } =
    useRswEthTotalSupply()

  const convertRswEthToFiat = useConvertRswEthToFiat()

  return {
    error: rswEthUsdMarketRateQuery.error ?? rswEthTotalSupplyQuery.error,
    isLoading:
      rswEthUsdMarketRateQuery.isLoading || rswEthTotalSupplyQuery.isLoading,
    isValidating:
      rswEthUsdMarketRateQuery.isValidating ||
      rswEthTotalSupplyQuery.isValidating,
    get data() {
      if (!rswEthUsdMarketRateData || !rswEthTotalSupplyData) return undefined
      const { rate } = rswEthUsdMarketRateData
      const { totalSupply } = rswEthTotalSupplyData

      return { marketCapUsd: convertRswEthToFiat(totalSupply, rate) }
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useRswEthMarketCapV3Backend = () => {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const { data, ...query } = useGetAllStatsV3Backend(v3BackendLrtUrl)

  return {
    ...query,
    get data() {
      if (!data) return undefined
      // TODO: update to rswEthMarketCapCents when available
      const { swEthMarketCapCents } = data
      return {
        marketCapUsd: parseFloat(swEthMarketCapCents) / 100,
      }
    },
  }
}

// TODO: restaking switch to API when available
// export { useRswEthMarketCapV3Backend as useRswEthMarketCap }
export { useRswEthMarketCapOnChain as useRswEthMarketCap }
