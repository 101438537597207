import React from 'react'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@swell-ui/KeyValueList'
import { displayFiat } from '@/util/displayFiat'
import { formatUnits } from 'ethers/lib/utils'
import { formatWithConfig } from '@/util/number'
import { SlippageTolerance } from './SlippageTolerance'
import { ZapRoute } from '@/state/zap/types'

interface SwapInfoProps {
  isLoading?: boolean
  bestRouteInfo: ZapRoute | undefined
  srcTokenSymbol: string
  destTokenSymbol: string
  slippagePercent: number
  setSlippagePercent: (tolerance: number) => void
  defaultSlippagePercent: number
}

function buildExchageRateValue(
  bestRouteInfo: ZapRoute,
  srcTokenSymbol: string,
  destTokenSymbol: string
) {
  const fromAmountFloat = formatUnits(
    bestRouteInfo.fromAmount,
    bestRouteInfo.fromToken.decimals
  )
  const toAmountFloat = formatUnits(
    bestRouteInfo.toAmount,
    bestRouteInfo.toToken.decimals
  )
  const exchangeRate = Number(toAmountFloat) / Number(fromAmountFloat)
  const formattedExchangeRate = formatWithConfig(exchangeRate, {
    precision: 4,
  })
  return `1 ${srcTokenSymbol} = ${formattedExchangeRate} ${destTokenSymbol}`
}

function buildFeesValue(steps: ZapRoute['steps']) {
  const step = steps[0]
  const gasCost = step.estimate.gasCosts[0].amountUSD
  return `~${displayFiat(Number(gasCost))}`
}

function SwapInfo({
  isLoading,
  bestRouteInfo,
  srcTokenSymbol,
  destTokenSymbol,
  setSlippagePercent,
  slippagePercent,
  defaultSlippagePercent,
}: SwapInfoProps) {
  const swapInfoData = {
    exchangeRate: bestRouteInfo
      ? buildExchageRateValue(bestRouteInfo, srcTokenSymbol, destTokenSymbol)
      : undefined,
    fees: bestRouteInfo?.steps
      ? buildFeesValue(bestRouteInfo.steps)
      : undefined,
    priceImpact: undefined,
  }

  const swapInfoListConfig = {
    list: [
      {
        label: 'Exchange rate',
        value: (
          <AsyncDiv loading={isLoading || false}>
            {() => {
              return <>{swapInfoData.exchangeRate || '-'}</>
            }}
          </AsyncDiv>
        ),
      },
      {
        label: !swapInfoData ? 'Slippage tolerance' : '',
        value: !swapInfoData ? '-' : '',
        component: swapInfoData && (
          <SlippageTolerance
            slippagePercent={slippagePercent}
            setSlippagePercent={setSlippagePercent}
            defaultSlippagePercent={defaultSlippagePercent}
          />
        ),
      },
      // {
      //   label: 'Price impact',
      //   value: (
      //     <AsyncDiv loading={isLoading || false}>
      //       {() => {
      //         return <>{swapInfoData.priceImpact || '-'}</>
      //       }}
      //     </AsyncDiv>
      //   ),
      // },
      {
        label: 'Fees',
        value: (
          <AsyncDiv loading={isLoading || false}>
            {() => {
              return <>{swapInfoData.fees || '-'}</>
            }}
          </AsyncDiv>
        ),
      },
    ],
  }

  return <KeyValueList config={swapInfoListConfig} />
}

export { SwapInfo }
