import { processInputDepositCollection } from './util/processInputDepositCollection'
import { ITaskRunner, OnProgressFn, Task } from '../TaskRunner/ITaskRunner'
import { wrapOperatorValidatorKeys } from './util/v3BackendValidateKeysAdapter'
import { ChainDepositConfig } from '@/state/deployments/types'
import { OperatorClient } from '../V3BackendService/types'
import { validateDepositInfo } from './util/validateDepositInfo'
import { DepositCollection, DepositInfo } from '@/types/deposits'
import {
  IDepositCollectionValidationResult,
  IndexedDepositProblem,
  DepositCollectionValidationResult,
} from '@/state/depositValidation/models/DepositCollectionValidationResult'

export type ValidateAllFn = (args: {
  /**
   * The data to be validated (at best, we know it is an array)
   */
  depositCollection: unknown[]

  /**
   * Callback to notify of validation progress (e.g. for a loading bar)
   */
  setValidationProgress: OnProgressFn

  /**
   * The name of the node operator (used to verify withdrawal address on the backend)
   */
  nodeOperatorName: string
}) => Promise<IDepositCollectionValidationResult>

export interface IDepositCollectionValidator {
  /**
   * Parse a JSON string and make basic assertions on its shape.
   *
   * Returns an object with:
   *  - depositCollection?: the parsed JSON (array)
   *  - problemCode?: a problem code
   */
  processInput: typeof processInputDepositCollection

  /**
   * Perform data validation (including crypto validation) on the output
   *  of processInput
   */
  validateAll: ValidateAllFn
}

export type DepositCollectionValidatorArgs = {
  taskRunner: ITaskRunner
  maxJobTimeMillis?: number
  chainDepositConfig: ChainDepositConfig
  validateKeys: OperatorClient['validateKeys'] // validateKeys RPC (v3-backend)
}

/**
 * Provides:
 *  - a function to parse a string into deposit data, providing input validation
 *  - a function to validate the deposit data on the frontend & backend (in parallel,
 *     giving merged results)
 */
export const DepositCollectionValidator = {
  create: ({
    taskRunner,
    validateKeys,
    maxJobTimeMillis = 3000,
    chainDepositConfig,
  }: DepositCollectionValidatorArgs): IDepositCollectionValidator => {
    return {
      /**
       * Given some string, return deposit data or input validation problems
       */
      processInput: processInputDepositCollection,

      /**
       * Given some deposit data, return the composite result of validating on
       *  the frontend and backend.
       *
       * @param param0 Arguments (deposit data and validation progress callback)
       * @returns Composite validation result of frontend+backend
       */
      validateAll: async ({
        depositCollection,
        setValidationProgress,
        nodeOperatorName,
      }) => {
        const validateKeysStandardized = wrapOperatorValidatorKeys(validateKeys)
        const validateBackend = () =>
          validateKeysStandardized(depositCollection, nodeOperatorName)

        const validateFrontend =
          async (): Promise<IDepositCollectionValidationResult> => {
            const indexedProblems: IndexedDepositProblem[] = []

            const tasks: Task[] = depositCollection.map(
              (deposit, datumIndex) => {
                const pubkey =
                  typeof (deposit as DepositInfo)?.pubkey === 'string'
                    ? (deposit as DepositInfo).pubkey
                    : undefined

                return async () => {
                  try {
                    const problem = await validateDepositInfo(
                      deposit,
                      chainDepositConfig,
                      { maxJobTimeMillis }
                    )

                    if (problem) {
                      indexedProblems.push({
                        ...problem,
                        datumIndex,
                        pubkey,
                      })
                    }
                  } catch (err) {
                    console.error('Error validating datum (client-side)', err)
                    indexedProblems.push({
                      code: 'something-went-wrong',
                      datumIndex,
                      pubkey,
                    })
                  }
                }
              }
            )

            await taskRunner.run(tasks, setValidationProgress)

            if (indexedProblems.length > 0) {
              return DepositCollectionValidationResult.fromDataProblems(
                indexedProblems
              )
            }

            return DepositCollectionValidationResult.fromValidData(
              depositCollection as DepositCollection
            )
          }

        // Kick off backend validation straight away.
        // Do not await yet; we can do frontend validation in parallel.
        // It is beneficial to do both, since frontend validation yields more information
        //  in certain cases (e.g. schema failures)
        const backendValidationP = validateBackend()
        const frontendValidation = await validateFrontend()
        const backendValidation = await backendValidationP

        return DepositCollectionValidationResult.merge(
          frontendValidation,
          backendValidation
        )
      },
    }
  },
}
