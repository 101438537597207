import useSWR from 'swr'
import { Network, Alchemy } from 'alchemy-sdk'
import { BigNumber } from 'ethers'
import { Token, TokenMap } from '@/types/tokens'

const settings = {
  apiKey: 'k65F1pnFsC2MLjVrBuJK28WqsyOVioJY',
  network: Network.ETH_MAINNET,
}

const alchemy = new Alchemy(settings)

const alchemyTokenBalancesFetcher = async ([account, tokenMap]: [
  string,
  TokenMap
]) => {
  async function getTokenBalancesRecursive(
    tokenBalances: any[],
    pageKey?: string
  ): Promise<any[]> {
    const res = await alchemy.core.getTokenBalances(account, {
      type: 'erc20' as any,
      pageKey: pageKey,
    })

    const newTokenBalances = tokenBalances.concat(res.tokenBalances)

    if (!res.pageKey) {
      return newTokenBalances
    }

    return getTokenBalancesRecursive(newTokenBalances, res.pageKey)
  }

  const tokenBalances = await getTokenBalancesRecursive([])

  const tokensWithBalance = tokenBalances.filter((token: any) => {
    return parseInt(token.tokenBalance!, 16) !== 0
  })

  const tokenPromises = []
  const filteredBalances = []
  for (const token of tokensWithBalance) {
    if (tokenMap[token.contractAddress]) {
      const promise = alchemy.core.getTokenMetadata(token.contractAddress)

      tokenPromises.push(promise)
      filteredBalances.push(token)
    }
  }

  const filteredTokens = await Promise.all(tokenPromises)

  const formattedTokens: any[] = []
  for (let i = 0; i < filteredTokens.length; i++) {
    const token = filteredTokens[i]
    const balance = filteredBalances[i]

    const formattedToken: Token = {
      address: balance.contractAddress,
      balance: BigNumber.from(balance.tokenBalance),
      chainId: 1,
      decimals: token.decimals!,
      logoURI: token.logo!,
      name: token.name!,
      symbol: token.symbol!,
    }

    formattedTokens.push(formattedToken)
  }

  return formattedTokens
}

export function useAlchemyTokenBalancesQuery(account?: string, tokens?: any[]) {
  let key = null
  if (account && tokens) {
    key = [account, tokens]
  }

  const { data, ...query } = useSWR(key, alchemyTokenBalancesFetcher, {
    refreshInterval: 12000,
  })

  return {
    ...query,
    get data() {
      if (!data) return undefined

      return {
        tokenBalances: data,
      }
    },
  }
}
