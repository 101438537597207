/**
 * Copied: https://github.com/ethereum/staking-launchpad/blob/042612b78a1c616a98a64d039ac45d0010df8863/src/utils/verifySignature.ts
 * Changes:
 *  - Formatting rules; name of interface of DepositInfo (was DepositKeyInterface)
 *  - Inlined definitions for DOMAIN_DEPOSIT, EMPTY_ROOT
 *  - Fork version needs to be computed based on environment/hostname, rather than from an env var
 *  - genesis fork version is an argument to verifySignature because it is no longer globally accessible
 *  - Strictly ensure a buffer is used as the data type for genesisForkVersion, because strings do not work for non-mainnet fork versions
 *  - Change the input type from DepositInfo to DepositInfoCryptoFormat (i.e. more explicit data requirements)
 */
import { DepositInfoCryptoFormat } from '@/types/deposits'
import { verify } from '@chainsafe/bls'
import { ForkData, SigningData } from './SSZ'

const DOMAIN_DEPOSIT = Buffer.from('03000000', 'hex')
const EMPTY_ROOT = Buffer.from(
  '0000000000000000000000000000000000000000000000000000000000000000',
  'hex'
)

const computeForkDataRoot = (
  currentVersion: Uint8Array,
  genesisValidatorsRoot: Uint8Array
): Uint8Array => {
  const forkData: ForkData = {
    currentVersion: currentVersion as Uint8Array,
    genesisValidatorsRoot,
  }
  return ForkData.hashTreeRoot(forkData)
}

const computeDomain = (
  domainType: Buffer,
  forkVersion: Buffer,
  genesisValidatorsRoot: Buffer = EMPTY_ROOT
): Uint8Array => {
  const forkDataRoot = computeForkDataRoot(
    forkVersion as Uint8Array,
    genesisValidatorsRoot
  )
  const domain = new Uint8Array(32)
  domain.set(domainType)
  domain.set(forkDataRoot.subarray(0, 28), 4)
  return domain
}

const computeSigningRoot = (
  sszObjectRoot: Uint8Array,
  domain: Uint8Array
): Uint8Array => {
  const signingData: SigningData = {
    objectRoot: sszObjectRoot,
    domain,
  }
  return SigningData.hashTreeRoot(signingData)
}

// Note: usage of this method requires awaiting the initBLS() method from "@chainsafe/bls";
export const verifySignature = (
  deposit: DepositInfoCryptoFormat,
  genesisForkVersion: Buffer
): boolean => {
  const pubkeyBuffer = deposit.pubkey
  const signatureBuffer = deposit.signature
  const depositMessageBuffer = deposit.deposit_message_root
  const domain = computeDomain(DOMAIN_DEPOSIT, genesisForkVersion)
  const signingRoot = computeSigningRoot(depositMessageBuffer, domain)

  return verify(pubkeyBuffer, signingRoot, signatureBuffer)
}
