import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { ExitClaim } from '@/types/claims'
import axios from 'axios'

const AssetIDs = {
  ETH: '0',
}

const NFTAttributes = [
  'Asset Amount',
  'Token Amount',
  'Status',
  'Queue Position',
  'Total In Queue',
  'Request Timestamp',
  'Exchange Rate',
] as const
type NFTAttributes = (typeof NFTAttributes)[number]

const NFT_METADATA_ATTR_TO_CLAIM_KEY: Record<NFTAttributes, keyof ExitClaim> = {
  'Asset Amount': 'assetAmount',
  'Token Amount': 'tokenAmount',
  Status: 'status',
  'Queue Position': 'queuePosition',
  'Total In Queue': 'totalInQueue',
  'Request Timestamp': 'requestTimestamp',
  'Exchange Rate': 'exchangeRate',
}

type TokenMetadata = {
  attributes: { trait_type: string; value: any }[]
}

export const rswethClaimAdapter = (
  requestId: number,
  tokenMetadata: TokenMetadata
): ExitClaim => {
  const claim = {} as any
  tokenMetadata.attributes.forEach((attribute) => {
    const traitType = attribute.trait_type as NFTAttributes
    if (!(traitType in NFT_METADATA_ATTR_TO_CLAIM_KEY)) {
      return
    }
    const key = NFT_METADATA_ATTR_TO_CLAIM_KEY[traitType]
    claim[key] = attribute.value
  })
  claim.requestId = requestId
  return claim as ExitClaim
}

async function fetchClaim(
  v3BackendLrtUrl: string,
  assetId: string,
  tokenId: number
) {
  const res = await axios.get(
    `${v3BackendLrtUrl}/api/exit/${assetId}/metadata/${tokenId}`
  )
  return rswethClaimAdapter(tokenId, res.data)
}

export class RswEXITClaimFetcher {
  private backendUrl: string

  constructor(backendUrl: string) {
    this.backendUrl = backendUrl
  }

  public fetchClaim = async (
    lstAddress: string | null,
    requestId: number
  ): Promise<ExitClaim> => {
    let assetId: string // backend asset id
    if (!lstAddress || lstAddress === TOKEN_LIST_ETH.address) {
      assetId = AssetIDs.ETH
    } else {
      throw new Error('Unsupported token') // LSTs not supported yet
    }

    return fetchClaim(this.backendUrl, assetId, requestId)
  }
}
