import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { DownCaratIcon } from '@swell-ui/icons/DownCaratIcon'
import { shortenAddress } from '@/util/hexStrings'
import { AccountInfoButton } from './AccountInfoButton'
import { SvgFromString } from '../SvgFromString'
import CoinbaseWalletIcon from '@/assets/svg/coinbase-wallet-icon.svg'
import WalletConnectIcon from '@/assets/svg/wallet-connect-icon.svg'
import { WalletType } from '@/swell-web3/types/wallet'
import { Popover } from '@/swell-ui/Popover'
import { FlexRow } from '@/swell-ui/FlexRow'
import { getChainIcon } from '@/constants/chainInfo'
import { DisconnectIconButton } from '@/swell-ui/IconButton'

const StyledDownCaratIcon = styled(DownCaratIcon)`
  path {
    fill: ${({ theme }) => theme.mainColor};
  }
`

const StyledPopover = styled(Popover)``

function EthAccountInfoWidget() {
  const { account, ENSName, chainId, disconnect } = useSwellWeb3()
  const shortenedAccount = shortenAddress(account || '', 4, 4)

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const handleAccountClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const isOpen = !!anchorEl

  const chainIcon = getChainIcon(chainId)
  return (
    <>
      <AccountInfoButton color="accent" onClick={handleAccountClick}>
        <WalletIcon />
        {ENSName || shortenedAccount}
        <StyledDownCaratIcon />
      </AccountInfoButton>

      <StyledPopover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: -11,
        }}
      >
        <FlexRow padding="16px" gap="8" align="center">
          <ChainLogo
            width={24}
            height={24}
            src={chainIcon.uri}
            round={chainIcon.shape === 'circle'}
          />
          <DisconnectBtn onClick={disconnect} role="button">
            Disconnect <DisconnectIconButton />
          </DisconnectBtn>
        </FlexRow>
      </StyledPopover>
    </>
  )
}

export { EthAccountInfoWidget }

const DisconnectBtn = styled.span`
  cursor: pointer;
  button {
    padding: 0;
  }

  svg {
    fill: ${(props) => props.theme.mainColor};
  }
`
const ChainLogo = styled.img<{ round: boolean }>`
  border-radius: ${({ round }) => (round ? '50%' : '0')};
  width: 24px;
  height: 24px;
`

function WalletIcon() {
  const { icon, type } = useSwellWeb3()

  if (type === WalletType.WALLET_CONNECT) {
    return <img src={WalletConnectIcon} width={24} height={24} />
  }
  if (type === WalletType.COINBASE_WALLET) {
    return <img src={CoinbaseWalletIcon} width={24} height={24} />
  }

  return <SvgFromString svgString={icon} size={24} />
}
