import React from 'react'

function L2Icon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.8421 0C3.95875 0 0 3.95875 0 8.84211V33.1579C0 38.0413 3.95875 42 8.84211 42H33.1579C38.0413 42 42 38.0413 42 33.1579V8.8421C42 3.95875 38.0413 0 33.1579 0H8.8421ZM8.57812 14V30H18.5234V27.5703H11.4766V14H8.57812ZM21.9828 27.9062V30H32.9516V27.5781H25.9828V27.4688L28.7406 24.6641C29.7771 23.6641 30.5818 22.8125 31.1547 22.1094C31.7328 21.4062 32.1365 20.7656 32.3656 20.1875C32.5948 19.6094 32.7094 19.0208 32.7094 18.4219C32.7094 17.526 32.4828 16.7292 32.0297 16.0312C31.5818 15.3333 30.9516 14.7839 30.1391 14.3828C29.3266 13.9818 28.3839 13.7812 27.3109 13.7812C26.2536 13.7812 25.3161 13.987 24.4984 14.3984C23.6859 14.8099 23.0479 15.3906 22.5844 16.1406C22.1208 16.8906 21.8891 17.7734 21.8891 18.7891H24.6469C24.6469 18.2422 24.7536 17.7708 24.9672 17.375C25.1859 16.9792 25.4932 16.6745 25.8891 16.4609C26.2849 16.2422 26.7458 16.1328 27.2719 16.1328C27.7823 16.1328 28.238 16.2318 28.6391 16.4297C29.0401 16.6276 29.3552 16.9089 29.5844 17.2734C29.8135 17.6328 29.9281 18.0651 29.9281 18.5703C29.9281 19.013 29.8396 19.4297 29.6625 19.8203C29.4854 20.2109 29.2198 20.6146 28.8656 21.0312C28.5115 21.4479 28.0688 21.9245 27.5375 22.4609L21.9828 27.9062Z"
        fill="url(#paint0_linear_7139_4989)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7139_4989"
          x1="21"
          y1="0"
          x2="21"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB901" />
          <stop offset="1" stopColor="#F9CB53" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { L2Icon as L2 }
