// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/stats.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.StatsServiceAllRequest
 */
export class StatsServiceAllRequest extends Message<StatsServiceAllRequest> {
  constructor(data?: PartialMessage<StatsServiceAllRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.StatsServiceAllRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatsServiceAllRequest {
    return new StatsServiceAllRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatsServiceAllRequest {
    return new StatsServiceAllRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatsServiceAllRequest {
    return new StatsServiceAllRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StatsServiceAllRequest | PlainMessage<StatsServiceAllRequest> | undefined, b: StatsServiceAllRequest | PlainMessage<StatsServiceAllRequest> | undefined): boolean {
    return proto3.util.equals(StatsServiceAllRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.StatsServiceAllResponse
 */
export class StatsServiceAllResponse extends Message<StatsServiceAllResponse> {
  /**
   * @generated from field: string staking_apr_percent = 1;
   */
  stakingAprPercent = "";

  /**
   * @generated from field: string staker_count_users = 2;
   */
  stakerCountUsers = "";

  /**
   * @generated from field: string total_eth_staked_wei = 3;
   */
  totalEthStakedWei = "";

  /**
   * @generated from field: string sw_eth_market_cap_cents = 4;
   */
  swEthMarketCapCents = "";

  /**
   * @generated from field: string suggested_gas_price_wei = 5;
   */
  suggestedGasPriceWei = "";

  /**
   * @generated from field: string node_operator_reward_percentage = 6;
   */
  nodeOperatorRewardPercentage = "";

  /**
   * @generated from field: string swell_treasury_reward_percentage = 7;
   */
  swellTreasuryRewardPercentage = "";

  constructor(data?: PartialMessage<StatsServiceAllResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.StatsServiceAllResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "staking_apr_percent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "staker_count_users", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "total_eth_staked_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sw_eth_market_cap_cents", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "suggested_gas_price_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "node_operator_reward_percentage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "swell_treasury_reward_percentage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatsServiceAllResponse {
    return new StatsServiceAllResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatsServiceAllResponse {
    return new StatsServiceAllResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatsServiceAllResponse {
    return new StatsServiceAllResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StatsServiceAllResponse | PlainMessage<StatsServiceAllResponse> | undefined, b: StatsServiceAllResponse | PlainMessage<StatsServiceAllResponse> | undefined): boolean {
    return proto3.util.equals(StatsServiceAllResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnAPY
 */
export class EarnAPY extends Message<EarnAPY> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string protocol = 2;
   */
  protocol = "";

  /**
   * @generated from field: string logo = 3;
   */
  logo = "";

  /**
   * @generated from field: int32 chain_id = 4;
   */
  chainId = 0;

  /**
   * @generated from field: string chain_logo = 5;
   */
  chainLogo = "";

  /**
   * @generated from field: string chain_name = 6;
   */
  chainName = "";

  /**
   * @generated from field: string position_name = 7;
   */
  positionName = "";

  /**
   * @generated from field: repeated string position_tokens = 8;
   */
  positionTokens: string[] = [];

  /**
   * @generated from field: repeated string token_icon_list = 9;
   */
  tokenIconList: string[] = [];

  /**
   * @generated from field: string category = 10;
   */
  category = "";

  /**
   * @generated from field: float tvl = 11;
   */
  tvl = 0;

  /**
   * @generated from field: repeated float apy = 12;
   */
  apy: number[] = [];

  /**
   * @generated from field: string link = 13;
   */
  link = "";

  /**
   * @generated from field: bool is_visible = 14;
   */
  isVisible = false;

  /**
   * @generated from field: bool include_tvl = 15;
   */
  includeTvl = false;

  constructor(data?: PartialMessage<EarnAPY>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnAPY";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "protocol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "logo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "chain_logo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "chain_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "position_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "position_tokens", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "token_icon_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "tvl", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 12, name: "apy", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
    { no: 13, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "is_visible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "include_tvl", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnAPY {
    return new EarnAPY().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnAPY {
    return new EarnAPY().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnAPY {
    return new EarnAPY().fromJsonString(jsonString, options);
  }

  static equals(a: EarnAPY | PlainMessage<EarnAPY> | undefined, b: EarnAPY | PlainMessage<EarnAPY> | undefined): boolean {
    return proto3.util.equals(EarnAPY, a, b);
  }
}

/**
 * @generated from message swell.v3.RemainingEigenlayerSwethRequest
 */
export class RemainingEigenlayerSwethRequest extends Message<RemainingEigenlayerSwethRequest> {
  constructor(data?: PartialMessage<RemainingEigenlayerSwethRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RemainingEigenlayerSwethRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemainingEigenlayerSwethRequest {
    return new RemainingEigenlayerSwethRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemainingEigenlayerSwethRequest {
    return new RemainingEigenlayerSwethRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemainingEigenlayerSwethRequest {
    return new RemainingEigenlayerSwethRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemainingEigenlayerSwethRequest | PlainMessage<RemainingEigenlayerSwethRequest> | undefined, b: RemainingEigenlayerSwethRequest | PlainMessage<RemainingEigenlayerSwethRequest> | undefined): boolean {
    return proto3.util.equals(RemainingEigenlayerSwethRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.RemainingEigenlayerSwethResponse
 */
export class RemainingEigenlayerSwethResponse extends Message<RemainingEigenlayerSwethResponse> {
  /**
   * @generated from field: string remaining_eigenlayer_sweth = 1;
   */
  remainingEigenlayerSweth = "";

  constructor(data?: PartialMessage<RemainingEigenlayerSwethResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RemainingEigenlayerSwethResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "remaining_eigenlayer_sweth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemainingEigenlayerSwethResponse {
    return new RemainingEigenlayerSwethResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemainingEigenlayerSwethResponse {
    return new RemainingEigenlayerSwethResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemainingEigenlayerSwethResponse {
    return new RemainingEigenlayerSwethResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RemainingEigenlayerSwethResponse | PlainMessage<RemainingEigenlayerSwethResponse> | undefined, b: RemainingEigenlayerSwethResponse | PlainMessage<RemainingEigenlayerSwethResponse> | undefined): boolean {
    return proto3.util.equals(RemainingEigenlayerSwethResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnAPYsRequest
 */
export class EarnAPYsRequest extends Message<EarnAPYsRequest> {
  constructor(data?: PartialMessage<EarnAPYsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnAPYsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnAPYsRequest {
    return new EarnAPYsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnAPYsRequest {
    return new EarnAPYsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnAPYsRequest {
    return new EarnAPYsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EarnAPYsRequest | PlainMessage<EarnAPYsRequest> | undefined, b: EarnAPYsRequest | PlainMessage<EarnAPYsRequest> | undefined): boolean {
    return proto3.util.equals(EarnAPYsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnAPYsResponse
 */
export class EarnAPYsResponse extends Message<EarnAPYsResponse> {
  /**
   * @generated from field: repeated swell.v3.EarnAPY featured = 1;
   */
  featured: EarnAPY[] = [];

  /**
   * @generated from field: repeated swell.v3.EarnAPY top_apr = 2;
   */
  topApr: EarnAPY[] = [];

  /**
   * @generated from field: repeated swell.v3.EarnAPY available = 3;
   */
  available: EarnAPY[] = [];

  constructor(data?: PartialMessage<EarnAPYsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnAPYsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "featured", kind: "message", T: EarnAPY, repeated: true },
    { no: 2, name: "top_apr", kind: "message", T: EarnAPY, repeated: true },
    { no: 3, name: "available", kind: "message", T: EarnAPY, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnAPYsResponse {
    return new EarnAPYsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnAPYsResponse {
    return new EarnAPYsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnAPYsResponse {
    return new EarnAPYsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EarnAPYsResponse | PlainMessage<EarnAPYsResponse> | undefined, b: EarnAPYsResponse | PlainMessage<EarnAPYsResponse> | undefined): boolean {
    return proto3.util.equals(EarnAPYsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.StatsServiceBtcLrtRequest
 */
export class StatsServiceBtcLrtRequest extends Message<StatsServiceBtcLrtRequest> {
  constructor(data?: PartialMessage<StatsServiceBtcLrtRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.StatsServiceBtcLrtRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatsServiceBtcLrtRequest {
    return new StatsServiceBtcLrtRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatsServiceBtcLrtRequest {
    return new StatsServiceBtcLrtRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatsServiceBtcLrtRequest {
    return new StatsServiceBtcLrtRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StatsServiceBtcLrtRequest | PlainMessage<StatsServiceBtcLrtRequest> | undefined, b: StatsServiceBtcLrtRequest | PlainMessage<StatsServiceBtcLrtRequest> | undefined): boolean {
    return proto3.util.equals(StatsServiceBtcLrtRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.StatsServiceBtcLrtResponse
 */
export class StatsServiceBtcLrtResponse extends Message<StatsServiceBtcLrtResponse> {
  /**
   * @generated from field: string vault_apr_percent = 1;
   */
  vaultAprPercent = "";

  /**
   * @generated from field: string depositor_count_users = 2;
   */
  depositorCountUsers = "";

  /**
   * @generated from field: string tvl_wei = 3;
   */
  tvlWei = "";

  /**
   * @generated from field: string swbtc_market_cap_cents = 4;
   */
  swbtcMarketCapCents = "";

  /**
   * @generated from field: string fees_percentage = 5;
   */
  feesPercentage = "";

  /**
   * @generated from field: float symbiotic_points_vault = 6;
   */
  symbioticPointsVault = 0;

  constructor(data?: PartialMessage<StatsServiceBtcLrtResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.StatsServiceBtcLrtResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vault_apr_percent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "depositor_count_users", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tvl_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "swbtc_market_cap_cents", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "fees_percentage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "symbiotic_points_vault", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatsServiceBtcLrtResponse {
    return new StatsServiceBtcLrtResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatsServiceBtcLrtResponse {
    return new StatsServiceBtcLrtResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatsServiceBtcLrtResponse {
    return new StatsServiceBtcLrtResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StatsServiceBtcLrtResponse | PlainMessage<StatsServiceBtcLrtResponse> | undefined, b: StatsServiceBtcLrtResponse | PlainMessage<StatsServiceBtcLrtResponse> | undefined): boolean {
    return proto3.util.equals(StatsServiceBtcLrtResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.WavedropStatsRequest
 */
export class WavedropStatsRequest extends Message<WavedropStatsRequest> {
  constructor(data?: PartialMessage<WavedropStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.WavedropStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WavedropStatsRequest {
    return new WavedropStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WavedropStatsRequest {
    return new WavedropStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WavedropStatsRequest {
    return new WavedropStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WavedropStatsRequest | PlainMessage<WavedropStatsRequest> | undefined, b: WavedropStatsRequest | PlainMessage<WavedropStatsRequest> | undefined): boolean {
    return proto3.util.equals(WavedropStatsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.WavedropStatsResponse
 */
export class WavedropStatsResponse extends Message<WavedropStatsResponse> {
  /**
   * @generated from field: int32 nextWavedropEndUnix = 1;
   */
  nextWavedropEndUnix = 0;

  /**
   * @generated from field: int32 currentWaveNumber = 2;
   */
  currentWaveNumber = 0;

  constructor(data?: PartialMessage<WavedropStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.WavedropStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nextWavedropEndUnix", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "currentWaveNumber", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WavedropStatsResponse {
    return new WavedropStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WavedropStatsResponse {
    return new WavedropStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WavedropStatsResponse {
    return new WavedropStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: WavedropStatsResponse | PlainMessage<WavedropStatsResponse> | undefined, b: WavedropStatsResponse | PlainMessage<WavedropStatsResponse> | undefined): boolean {
    return proto3.util.equals(WavedropStatsResponse, a, b);
  }
}

