import { useEveryNthBlock } from '@/hooks/useBlockNumber'
import { useCallback } from 'react'
import { useDataLivenessConfig } from '../deployments/hooks/useDataLivenessConfig'
import {
  useSwEthBalance,
  useEthBalance,
  useSwExitBalance,
  useClaims,
} from './hooks'

export default function UserUpdater(): null {
  const { mutate: swEthBalanceMutate, data: swEthBalanceData } =
    useSwEthBalance()
  const { mutate: ethBalanceMutate, data: ethBalanceData } = useEthBalance()
  const { mutate: swExitBalanceMutate, data: swExitBalanceData } =
    useSwExitBalance()
  const { mutate: claimsMutate, data: claimsData } = useClaims()

  const { userTokenBalancesLiveness } = useDataLivenessConfig()

  const dataToRefresh =
    !!swEthBalanceData || !!ethBalanceData || swEthBalanceData || !!claimsData

  const mutate = useCallback(() => {
    swEthBalanceMutate()
    ethBalanceMutate()
    claimsMutate()
    swExitBalanceMutate()
  }, [ethBalanceMutate, swEthBalanceMutate, swExitBalanceMutate, claimsMutate])

  useEveryNthBlock({
    blocksPerFetch: userTokenBalancesLiveness.blocksPerFetch,
    fetch: mutate,
    skip: !dataToRefresh, // if there is no data to refresh, then deactivate block listener
  })

  return null
}
