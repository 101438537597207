import { IERC20__factory, Multicall3 } from '@/abis/types'
import { BigNumber, ethers } from 'ethers'

export type TokenSpenderList = { token: string; spender: string }[]
type FetchAllowancesResult = {
  token: string
  spender: string
  allowance: BigNumber
}[]

type FetchBalancesResult = {
  token: string
  balance: BigNumber
}[]

interface ITokenMulticalls {
  fetchAllowances(
    tokenSpender: TokenSpenderList,
    account: string
  ): Promise<FetchAllowancesResult>
  fetchBalances(tokens: string[], account: string): Promise<FetchBalancesResult>
}

export class TokenMulticalls implements ITokenMulticalls {
  multicall: Multicall3

  constructor(multicall: Multicall3) {
    this.multicall = multicall
  }

  async fetchAllowances(
    tokenSpender: TokenSpenderList,
    account: string
  ): Promise<FetchAllowancesResult> {
    const calls: Multicall3.Call3Struct[] = []

    for (let i = 0; i < tokenSpender.length; i++) {
      const { token, spender } = tokenSpender[i]
      calls.push({
        target: token,
        callData: IERC20__factory.createInterface().encodeFunctionData(
          'allowance',
          [account, spender]
        ),
        allowFailure: false,
      })
    }

    const results = await this.multicall.callStatic.tryAggregate(true, calls)

    const allowances: FetchAllowancesResult = []
    for (let i = 0; i < results.length; i++) {
      const { token, spender } = tokenSpender[i]
      const result = results[i]
      const allowance = IERC20__factory.createInterface().decodeFunctionResult(
        'allowance',
        result.returnData
      )[0]
      allowances.push({ token, spender, allowance })
    }

    const resp: FetchAllowancesResult = []
    for (let i = 0; i < allowances.length; i++) {
      const { token, spender, allowance } = allowances[i]
      resp.push({ token, spender, allowance })
    }

    return resp
  }

  async fetchBalances(
    tokens: string[],
    account: string
  ): Promise<FetchBalancesResult> {
    const calls: Multicall3.Call3Struct[] = []

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i]
      calls.push({
        target: token,
        callData: IERC20__factory.createInterface().encodeFunctionData(
          'balanceOf',
          [account]
        ),
        allowFailure: false,
      })
    }

    const results = await this.multicall.callStatic.tryAggregate(true, calls)

    const balances: FetchBalancesResult = []
    for (let i = 0; i < results.length; i++) {
      const token = tokens[i]
      const result = results[i]
      const balance = IERC20__factory.createInterface().decodeFunctionResult(
        'balanceOf',
        result.returnData
      )[0]
      balances.push({ token, balance })
    }

    const resp: FetchBalancesResult = []
    for (let i = 0; i < balances.length; i++) {
      const { token, balance } = balances[i]
      resp.push({ token, balance })
    }

    return resp
  }
}
