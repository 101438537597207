import React from 'react'
import styled from 'styled-components/macro'
import { CheckCircleIcon } from '../icons/CheckCircleIcon'
import { WarningIcon } from '../icons/WarningIcon'
import { CloseIconButton } from '../IconButton'

interface AlertProps {
  children: React.ReactNode
  onClose?: () => any
  level: 'error' | 'success'
}

const ALERT_LEVELS = {
  ERROR: 'error' as 'error' | 'success',
  SUCCESS: 'success' as 'error' | 'success',
}

const StyledAlert = styled.div<AlertProps>`
  display: flex;
  align-items: center;
  padding: 11px 38px;
  letter-spacing: -0.03em;
  font-weight: 500;

  ${({ theme, level }) => `
    background-color: ${theme.alert.success.bgColor};
    color: ${theme.colors.white['50']};

    ${
      level === 'error'
        ? `
      background-color: ${theme.alert.error.bgColor};
    `
        : ``
    }

    a:hover {
      color: ${theme.alert.success.linkHoverColor};
    }
  `}
`

const AlertContent = styled.div`
  padding-left: 17px;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
`

function Alert(props: AlertProps) {
  return (
    <StyledAlert {...props}>
      {props.level === 'success' && <CheckCircleIcon />}
      {props.level === 'error' && <WarningIcon />}
      <AlertContent>{props.children}</AlertContent>
      {props.onClose && <CloseIconButton onClick={props.onClose} />}
    </StyledAlert>
  )
}

export { Alert, ALERT_LEVELS }
export type { AlertProps }
