import React from 'react'

function CloseIcon(props: any) {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 10L10 22"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M22 22L10 10"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { CloseIcon }
