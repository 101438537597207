/*
  Expects options: { plugins: { mouseLine: { color: string } } }
*/
const mouseLinePlugin = {
  id: 'mouseLine',
  afterEvent: function (chart: any, args: any, options: any) {
    if (
      chart.tooltip &&
      chart.tooltip.dataPoints &&
      chart.tooltip.dataPoints[0]
    ) {
      options.x = chart.tooltip.dataPoints[0].element.x
    }
  },
  afterDraw: function (chart: any, easing: any, options: any) {
    if (options.x) {
      const chartArea = chart.chartArea
      const ctx = chart.ctx
      ctx.save()
      ctx.beginPath()
      ctx.globalCompositeOperation = 'destination-over'
      ctx.strokeStyle = options.color
      ctx.lineWidth = 1
      ctx.moveTo(options.x, chartArea.bottom)
      ctx.lineTo(options.x, chartArea.top)
      ctx.stroke()
      ctx.restore()
    }
  },
}

export { mouseLinePlugin }
