import useSWRImmutable from 'swr/immutable'
import { isProtocolToken } from '@/util/tokens'
import { Token, TokenMap } from '@/types/tokens'
import { tokenListAdapter } from './adapters'

const COINGECKO_TOKEN_LIST_URL =
  'https://tokens.coingecko.com/ethereum/all.json'
const UNISWAP_DEFAULT_TOKEN_LIST_URL = 'https://ipfs.io/ipns/tokens.uniswap.org'

const fetchData = async (url: string) => {
  const res = await fetch(url)
  const data = await res.json()
  return data
}

export function useCoingeckoTokenListQuery() {
  const query = useSWRImmutable(COINGECKO_TOKEN_LIST_URL, fetchData)

  let tokens: Token[] = []
  let tokenMap: TokenMap = {}

  if (query.data) {
    const adaptedData = tokenListAdapter(query.data)
    tokens = adaptedData.tokens
    tokenMap = adaptedData.tokenMap
  }

  return {
    ...query,
    get data() {
      if (!query.data) return undefined

      return {
        ...query.data,
        tokens,
        tokenMap,
      }
    },
  }
}

export function useUniswapDefaultTokenListQuery() {
  const query = useSWRImmutable(UNISWAP_DEFAULT_TOKEN_LIST_URL, fetchData)

  let mutatedTokens: any = []
  if (query.data) {
    mutatedTokens = query.data.tokens.filter(
      (token: any) => token.chainId === 1 || isProtocolToken(token)
    )
  }

  return {
    ...query,
    get data() {
      if (query.data) return undefined

      return {
        ...query.data,
        tokens: mutatedTokens,
      }
    },
  }
}
