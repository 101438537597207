import React from 'react'

function ArrowUpIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M8.5 15.1875L8.5 15.6875L9.5 15.6875L9.5 15.1875L8.5 15.1875ZM9.5 2.8125C9.5 2.53636 9.27614 2.3125 9 2.3125C8.72386 2.3125 8.5 2.53636 8.5 2.8125L9.5 2.8125ZM9.5 15.1875L9.5 2.8125L8.5 2.8125L8.5 15.1875L9.5 15.1875Z"
        fill="white"
      />
      <path
        d="M3.9375 7.875L9 2.8125L14.0625 7.875"
        stroke="white"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { ArrowUpIcon }
