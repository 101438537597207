import { createContext, useContext } from 'react'
import { PredepositApi } from './types'

const MISSING_PROVIDER = Symbol()

export type PredepositContext = PredepositApi

export const PredepositContext = createContext<
  PredepositContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function usePredepositApi() {
  const ctx = useContext(PredepositContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'PredepositContext hooks must be wrapped in a <PredepositContext.Provider>'
    )
  }
  return ctx
}
