/**
 * Given some error object, indicates whether the error is associated with
 *  a smart contract rejection due to an operator not being found for a given
 *  address.
 *
 * @param error The error object to be classified
 * @returns boolean, error is NoOperatorFound
 */
export const isNoOperatorFoundError = (error: unknown): boolean => {
  return !!error && (error as any).errorSignature === 'NoOperatorFound(address)'
}
