import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { DatePicker } from '@swell-ui/inputs/DatePicker'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { SwEthEthLineChart } from '@/components/SwEthEthLineChart'
import {
  useDisplayNativeCurrencyInSwEth,
  useDisplayNativeCurrencyPriceFiat,
} from '@/hooks/useNativeCurrencyDisplay'
import {
  useEthUsdMarketRate,
  useSwEthEthMarketRate,
  // useSwEthUsdMarketRate,
  // useRswEthUsdMarketRate,
} from '@/state/fiat/hooks'
import { AppReprice } from '@/types/rates'

const FIRST_REPRICE_DATE = new Date('2023-04-18')

// TODO: type prices properly
interface ExchangeRateChartProps {
  account: any
  prices?: any[]
}

const ExchangeRateChartInner = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  > div:first-child {
    padding-bottom: 24px;
  }
`

const InnerBox = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.auxGrey};
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.oceanBlue['1255']};
  color: ${({ theme }) => theme.colors.white['50']};

  > div:not(:last-child) {
    padding-bottom: 12px;
  }
`

const ConnectZone = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;

  > div:first-child {
    padding-bottom: 24px;
  }

  color: ${({ theme }) => theme.colors.white['150']};
`

const ChartContainer = styled.div`
  flex-grow: 1;
`

const StyledDatePicker = styled(DatePicker)`
  width: 84px;
  height: 35px;

  .MuiInputBase-root {
    font-size: 12px;
    line-height: 160%;
    padding-right: 8px;
  }

  input {
    height: 19px;
    padding: 8px;
    padding-right: 0;
  }
`

const DatePickerSeparator = styled.div`
  width: 7px;
  height: 1px;
  background: ${({ theme }) => theme.colors.white['150']};
  margin: 20px 6px 0;
`

const Legend = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 4px;
`

const SwEthLegend = styled(Legend)`
  background: linear-gradient(180.33deg, #3068ef 0.29%, #1322ac 167.95%);
`

const EthLegend = styled(Legend)`
  background: #a4abf1;
`

// const RswEthLegend = styled(Legend)`
//   background: #ffb901;
// `

const AsyncUsdContainer = styled.div`
  display: inline-block;
  min-width: 76.56px;
  color: ${({ theme }) => theme.colors.lightBlue['50']};
`

function buildPriceData(prices: AppReprice[]) {
  const priceData = []

  for (let i = 0; i < prices.length; i++) {
    const price = prices[i]
    const date = new Date(price.timestamp)
    const formattedDate = date.toLocaleDateString(navigator.language, {
      day: '2-digit',
      month: '2-digit',
    })

    const ethToSwethRate = Number(formatUnits(price.rate, 18))
    const ethPrice = Number(formatUnits(price.wethUsdc, 18))
    const swethPrice = ethPrice * ethToSwethRate
    // TODO: calculate rswethPrice properly
    // const rswethPrice = ethPrice * ethToSwethRate + 200

    priceData.push({
      date: formattedDate,
      ethPrice: ethPrice,
      swEthPrice: swethPrice,
      // rswEthPrice: rswethPrice,
    })
  }

  return priceData
}

function ExchangeRateChart({ account, prices }: ExchangeRateChartProps) {
  const [startDate, setStartDate] = useState<Date>(FIRST_REPRICE_DATE)
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [allPrices, setAllPrices] = useState<any>([])
  const [priceData, setPriceData] = useState<any>([])

  // hooks to help calculate current exchange rates
  const ethUsdMarketRate = useEthUsdMarketRate()
  // TODO: restaking: uncomment and change if we want to not use the same USD value for all
  const swEthEthMarketRate = useSwEthEthMarketRate()
  // const swEthUsdMarketRate = useSwEthUsdMarketRate()
  // const displaySwEthPriceFiat = useDisplaySwEthPriceFiat()
  // const rswEthEthMarketRate = useRswEthEthMarketRate()
  // const rswEthUsdMarketRate = useRswEthUsdMarketRate()
  // const displayRswEthPriceFiat = useDisplayRswEthPriceFiat()

  // hooks to help render exchange rates
  const displayNativeCurrencyPriceFiat = useDisplayNativeCurrencyPriceFiat()
  const displayNativeCurrencyInSwEth = useDisplayNativeCurrencyInSwEth()
  // const displayNativeCurrencyInRswEth = useDisplayNativeCurrencyInRswEth()

  useEffect(() => {
    if (prices) {
      setAllPrices(prices)
    }
  }, [prices])

  useEffect(() => {
    if (endDate.getTime() > startDate.getTime()) {
      const prices = allPrices.filter((price: any) => {
        const timestamp = price.timestamp
        return (
          timestamp >= startDate.getTime() && timestamp <= endDate.getTime()
        )
      })

      buildPriceData(prices)
      setPriceData(buildPriceData(prices))
    }
  }, [allPrices, startDate, endDate])

  const nativeCurrencyPriceDisplay = () => {
    return (
      <AsyncDiv loading={!ethUsdMarketRate.data} Container={AsyncUsdContainer}>
        {() => {
          const amount = BigNumber.from('1000000000000000000')
          const { rate } = ethUsdMarketRate.data!
          return `1 (${displayNativeCurrencyPriceFiat(amount, rate, true)})`
        }}
      </AsyncDiv>
    )
  }

  const swethPriceDisplay = () => {
    return (
      <AsyncDiv
        loading={!swEthEthMarketRate.data || !ethUsdMarketRate.data}
        Container={AsyncUsdContainer}
      >
        {() => {
          const ethAmount = BigNumber.from('1000000000000000000')
          const { rate } = swEthEthMarketRate.data!
          const { rate: ethRate } = ethUsdMarketRate.data!

          const swethAmount = displayNativeCurrencyInSwEth(
            ethAmount,
            1 / rate,
            { precision: 2, numberOnly: true }
          )

          const usdAmount = displayNativeCurrencyPriceFiat(
            ethAmount,
            ethRate,
            true
          )
          return <>{`${swethAmount} (${usdAmount})`}</>
        }}
      </AsyncDiv>
    )
  }

  // const rswethPriceDisplay = () => {
  //   return (
  //     <AsyncDiv
  //       loading={!rswEthEthMarketRate.data || !ethUsdMarketRate.data}
  //       Container={AsyncUsdContainer}
  //     >
  //       {() => {
  //         const ethAmount = BigNumber.from('1000000000000000000')
  //         const { rate } = rswEthEthMarketRate.data!
  //         const { rate: ethRate } = ethUsdMarketRate.data!

  //         const rswethAmount = displayNativeCurrencyInRswEth(
  //           ethAmount,
  //           1 / rate,
  //           { precision: 2, numberOnly: true }
  //         )

  //         const usdAmount = displayNativeCurrencyPriceFiat(
  //           ethAmount,
  //           ethRate,
  //           true
  //         )
  //         return <>{`${rswethAmount} (${usdAmount})`}</>
  //       }}
  //     </AsyncDiv>
  //   )
  // }

  return (
    <ExchangeRateChartInner>
      <Typography variant="body" size="large" letterSpacing="small">
        Exchange rate chart
      </Typography>
      <InnerBox>
        {account && (
          <>
            <FlexRow justify="space-between" align="center">
              <div>
                <FlexRow>
                  <EthLegend />
                  <Typography variant="body" size="small" letterSpacing="small">
                    ETH = {nativeCurrencyPriceDisplay()}
                  </Typography>
                </FlexRow>
                <FlexRow align="center">
                  <SwEthLegend />
                  <Typography variant="body" size="small" letterSpacing="small">
                    swETH = {swethPriceDisplay()}
                  </Typography>
                </FlexRow>
                {/* <FlexRow align="center">
                  <RswEthLegend />
                  <Typography variant="body" size="small" letterSpacing="small">
                    rswETH = {rswethPriceDisplay()}
                  </Typography>
                </FlexRow> */}
              </div>
              <div>
                <FlexRow align="center">
                  <div>
                    <Typography
                      variant="body"
                      size="xsmall"
                      letterSpacing="small"
                    >
                      Start date
                    </Typography>
                    <StyledDatePicker
                      value={startDate}
                      onChange={(value: any) => {
                        setStartDate(value)
                      }}
                      shouldDisableDate={(date: any) => {
                        return (
                          date.getTime() < FIRST_REPRICE_DATE.getTime() ||
                          date.getTime() > endDate.getTime()
                        )
                      }}
                    />
                  </div>
                  <DatePickerSeparator />
                  <div>
                    <Typography
                      variant="body"
                      size="xsmall"
                      letterSpacing="small"
                    >
                      End date
                    </Typography>
                    <StyledDatePicker
                      value={endDate}
                      onChange={(value: any) => {
                        setEndDate(value)
                      }}
                      shouldDisableDate={(date: any) => {
                        return date.getTime() < startDate.getTime()
                      }}
                    />
                  </div>
                </FlexRow>
              </div>
            </FlexRow>
            <ChartContainer>
              <SwEthEthLineChart priceData={priceData} />
            </ChartContainer>
          </>
        )}
        {!account && (
          <ConnectZone>
            <div>Connect wallet to see available data.</div>
            <div>
              <ConnectWalletButton variant="secondary" size="small">
                Connect wallet
              </ConnectWalletButton>
            </div>
          </ConnectZone>
        )}
      </InnerBox>
    </ExchangeRateChartInner>
  )
}

export { ExchangeRateChart }
