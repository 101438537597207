import { useState } from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { BigNumber } from 'ethers'
import { usePopulateTransactionData } from '@/services/LifiService'
import { Token } from '@/types/tokens'

export interface ZapTransactionResponse {
  tx?: any
  error?: {
    message: string
  }
}

export interface UseZap {
  isLoading: boolean
  clear: () => void
  sendTransaction: (route: any) => Promise<ZapTransactionResponse>
  fromToken: Token | null
  fromAmount: BigNumber
  toToken: Token | null
  toAmount: BigNumber
  setStatus: (status: string) => void
  status: string
  STATUS: {
    [key: string]: string
  }
  tx: any
}

export function useZap(): UseZap {
  const { account, provider } = useSwellWeb3()
  const populateTransactionData = usePopulateTransactionData()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fromToken, setFromToken] = useState<Token | null>(null)
  const [fromAmount, setFromAmount] = useState<BigNumber>(BigNumber.from(0))
  const [toToken, setToToken] = useState<Token | null>(null)
  const [toAmount, setToAmount] = useState<BigNumber>(BigNumber.from(0))

  const STATUS = {
    IDLE: 'idle',
    GET_ROUTE: 'getroute',
    BUILDING: 'building',
    PROMPTING: 'prompting',
    PENDING: 'pending',
    FULFILLED: 'fulfilled',
  }
  const [status, setStatus] = useState<string>(STATUS.IDLE)
  const [tx, setTx] = useState<any>(undefined)

  const clear = () => {
    setStatus(STATUS.IDLE)
    setTx(undefined)
  }

  const sendTransaction = async (route: any) => {
    if (!route) {
      return {
        error: { message: 'No zap route specified.' },
      }
    }

    setFromToken(route.fromToken)
    setFromAmount(BigNumber.from(route.fromAmount))
    setToToken(route.toToken)
    setToAmount(BigNumber.from(route.toAmount))

    setIsLoading(true)

    const populateParams = {
      stepRaw: btoa(JSON.stringify(route.steps[0])),
      forAddress: account,
    }

    setStatus(STATUS.BUILDING)
    const transactionRequest = await populateTransactionData(populateParams)

    if (!transactionRequest) {
      setStatus(STATUS.IDLE)
      return {
        error: { message: 'Error building transaction data.' },
      }
    }

    setStatus(STATUS.PROMPTING)
    try {
      const signer = provider.getSigner()
      const tx = await signer.sendTransaction(transactionRequest as any)
      setTx(tx)
      setStatus(STATUS.PENDING)
      await tx.wait()

      setIsLoading(false)
      setStatus(STATUS.FULFILLED)
      return {
        tx: tx,
      }
    } catch (error: any) {
      setIsLoading(false)
      return {
        error: { message: error.reason || 'Unknown error' },
      }
    }
  }

  return {
    isLoading,
    clear,
    sendTransaction,
    fromToken,
    fromAmount,
    toToken,
    toAmount,
    setStatus,
    status,
    STATUS,
    tx,
  }
}
