import React from 'react'
import { useRestakingDepositCollectionValidationInput } from '@/state/restakingDepositValidation/hooks'
import useRestakingDepositCollectionValidation from '@/hooks/useRestakingDepositCollectionValidation'
import { DepositInfo } from '@/types/deposits'
import { UploadDepositCollectionCommon } from './UploadDepositCollectionCommon'
import { useUserOperator } from '@/state/restakingUserOperator/hooks'
import { useRestakingUserOperatorStatus } from '@/hooks/useUserOperatorStatus'

interface RestakingUploadDepositCollectionWidgetProps {
  onSubmit: (chunks: DepositInfo[][]) => void
  preventInteraction: boolean
  setTextAreaRef: any
}

export function RestakingUploadDepositCollectionWidget(
  props: RestakingUploadDepositCollectionWidgetProps
) {
  const depositCollectionValidationInput =
    useRestakingDepositCollectionValidationInput()
  const depositCollectionValidation = useRestakingDepositCollectionValidation()
  const { isOperator } = useRestakingUserOperatorStatus()

  return (
    <UploadDepositCollectionCommon
      depositCollectionValidationInput={depositCollectionValidationInput}
      depositCollectionValidation={depositCollectionValidation}
      isOperator={isOperator}
      {...props}
    />
  )
}
