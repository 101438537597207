import { useAppDispatch, useAppSelector } from '../../hooks'
import { bindActionCreators } from 'redux'
import { useMemo } from 'react'
import {
  DEPLOYMENT_NAMES,
  DEPLOYMENT_SETS,
} from '@/configuration/deploymentSets'
import { DeploymentSet } from '@/types/deployments'
import { setSelectedDeploymentSetName } from '../reducer'

// build a memo that allows us to look up deployment set by name
const deploymentSetLookup: Record<string, DeploymentSet> = {}

DEPLOYMENT_NAMES.forEach((name, idx) => {
  deploymentSetLookup[name] = DEPLOYMENT_SETS[idx]
})

export const useDeploymentSetConfig = () => {
  return deploymentSetLookup[
    useAppSelector((state) => state.deployments.selectedDeploymentSetName)
  ]
}

export const useDeploymentSetSelect = () => {
  const { selectedDeploymentSetName } = useAppSelector(
    (state) => state.deployments
  )

  const dispatch = useAppDispatch()

  const actions = useMemo(
    () => bindActionCreators({ setSelectedDeploymentSetName }, dispatch),
    [dispatch]
  )

  return {
    deploymentNames: DEPLOYMENT_NAMES,
    selectedDeploymentSetName,
    ...actions,
  }
}
