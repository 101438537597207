// Copied: https://github.com/Uniswap/interface/blob/ad2472eac638b389316ba1f3c3f1ed08fbbb12cd/src/state/application/updater.ts
import { useSwellWeb3 } from '@swell-web3/core'
import { useEffect, useState } from 'react'
import useDebounce from '../../hooks/useDebounce'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { useAppDispatch } from '../hooks'
import { updateChainId } from './reducer'

export default function ApplicationUpdater(): null {
  const { chainId, provider } = useSwellWeb3()
  const dispatch = useAppDispatch()
  const windowVisible = useIsWindowVisible()

  const { chainId: deploymentChainId } = useDeploymentSetConfig()

  const [activeChainId, setActiveChainId] = useState(chainId)

  useEffect(() => {
    if (provider && chainId && windowVisible) {
      setActiveChainId(chainId)
    }
  }, [dispatch, chainId, provider, windowVisible])

  const debouncedChainId = useDebounce(activeChainId, 100)

  useEffect(() => {
    const determineNextChainId = () => {
      if (!debouncedChainId) return null
      if (debouncedChainId === deploymentChainId) return debouncedChainId
      return null
    }

    const nextChainId = determineNextChainId()
    dispatch(updateChainId({ chainId: nextChainId }))
  }, [dispatch, debouncedChainId, deploymentChainId])

  return null
}
