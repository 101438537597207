import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BigNumber } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { ArrowDownIcon } from '@swell-ui/icons/ArrowDownIcon'
import { Box } from '@swell-ui/Box'
import { Button } from '@swell-ui/Button'
import { CircularProgress } from '@swell-ui/CircularProgress'
import { Divider } from '@swell-ui/Divider'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { SwethIcon } from '@swell-ui/icons/SwethIcon'
import { EthInput, SwEthInput } from '@swell-ui/inputs'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { TokenSelectWidget } from '@/components/TokenSelectWidget'
import { SwellStatistics } from '@/components/SwellStatistics'
import { StakingConfirmationProgressWidget } from '@/components/StakingConfirmationProgressWidget'
import { useStakingRate } from '@/state/stakingStats/hooks'
import {
  useEthBalance,
  useSwEthBalance,
  useSwExitBalance,
} from '@/state/user/hooks'
import { useIERC20Contract } from '@/hooks/useContract'
import {
  useFormatNativeCurrency,
  useParseNativeCurrency,
} from '@/hooks/useNativeCurrencyFormatting'
import { useAllowance } from '@/hooks/erc20/useAllowance'
import { useApprove } from '@/hooks/erc20/useApprove'
import { useCreateWithdrawRequest } from '@/hooks/useCreateWithdrawRequest'
import { useTokenBalances } from '@/hooks/erc20/useTokenBalances'
import { useFormatSwEth, useParseSwEth } from '@/hooks/useSwEthFormatting'
import {
  useStakingActions,
  useStakeNativeCurrency,
} from '@/state/staking/hooks'
import { useStakingDisplayStrings } from '@/hooks/useStakingDisplayStrings'
import { StakingSubmissionStatus, useStakeLens } from '@/hooks/useStakeLens'
import { useZap } from '@/hooks/useZap'
import {
  GLOBAL_NOTIFICATION_TYPES,
  useGlobalNotification,
} from '@/swell-ui/GlobalNotification'
import { useChainInfo, useDeploymentSetConfig } from '@/state/deployments/hooks'
import { useEthUsdMarketRate, useSwEthUsdMarketRate } from '@/state/fiat/hooks'
import { useDisplaySwEthPriceFiat } from '@/hooks/useSwEthDisplay'
import { useDisplayNativeCurrencyPriceFiat } from '@/hooks/useNativeCurrencyDisplay'
import { useGetRoutesQuery } from '@/services/LifiService'
import { displayCrypto } from '@/util/displayCrypto'
import { displayFiat } from '@/util/displayFiat'
import { trimDecimalPlaces } from '@/util/number'
import { TOKEN_LIST_ETH, TOKEN_LIST_SWETH } from '@/constants/tokens'
import { Token } from '@/types/tokens'
import { ActionChooser } from './ActionChooser'
import { AvailableChip } from './AvailableChip'
import { ClaimView } from './ClaimView'
import { CryptoUtilities } from './CryptoUtilities'
import { ExchangeInfo } from './ExchangeInfo'
import { SwapInfo } from './SwapInfo'
import { UnstakeConfirmModal } from './Exit/UnstakeConfirmModal'
import { VaultView } from './VaultView'
import { ACTIONS, TRANSACTION_TOAST_TITLE } from './constants'
import {
  applyBigNumberRateBN,
  getNativeCurrencyToExchangeForSweth,
  getSwethReceivedForNativeCurrency,
} from '@/util/big'

const DEFAULT_SLIPPAGE_PERCENT = 0.5

const StakingWidgetBox = styled(Box)`
  ${({ theme }) => `
    position: relative;
    width: 340px;
    margin: 0 auto;
    padding: 24px 24px 12px;
    margin-top: 18px;
    color: ${theme.colors.white['50']};
    background: rgba(11, 20, 27, 0.8);
    border: none;

    > div {
      margin-bottom: 12px;
    }

    ${theme.breakpoints.up('sm')} {
      width: 420px;
      padding: 24px 32px 12px;


      > div {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 12px;
        }
      }
    }}
  `}

  .Mui-disabled.MuiInputBase-root:before {
    border-bottom-style: solid;
    opacity: 0.6;
  }
`

const UtilitiesContainer = styled.div`
  position: absolute;
  top: 99px;
  right: 32px;
`

const EthInputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  .MuiFormControl-root {
    width: 100%;
  }

  input,
  div > div > p {
    font-size: ${({ theme }) => theme.typography.body.large.fontSize};
    font-weight: 600;
  }
`

const UsdTypography = styled(Typography)`
  height: 19.2px;
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  margin-bottom: 16px;
  margin-top: 2px;
  letter-spacing: -0.03em;
`

const StakingWidgetEthInput = styled(EthInput)`
  ${({ theme }) => `

    .MuiFormHelperText-root {
      margin-top: 2px;
      margin-bottom: 16px;
    }

    ${theme.breakpoints.up('sm')} {
      max-width: unset;
    }
  `}
`

const UnstakeButton = styled.div<any>`
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: 6px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: ${({ theme }) => theme.colors.oceanBlue['100']};

  svg {
    transition: rotate 300ms;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;

    svg {
      rotate: 180deg;
    }
  }

  &:active {
    opacity: 0.5;
  }
`

const SymbolWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white['50']};
`

const StyledEthIcon = styled(EthIcon)`
  width: 35px;
  height: 35px;
  margin-right: 8px;
`

const StyledSwethIcon = styled(SwethIcon)`
  width: 35px;
  height: 35px;
  margin-right: 8px;
`

const StakeWidgetButton = styled(Button)`
  position: relative;
  width: 100%;
`

const StakeConnectButton = styled(ConnectWalletButton)`
  width: 100%;
`

const InputCircularProgress = styled(CircularProgress)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`

const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`

const ButtonInner = styled.div`
  position: relative;
`

const ButtonProgressPlacer = styled.div`
  position: absolute;
  top: -7px;
  left: -32px;
`

type Loadable<T> =
  | {
      isLoading: false
      value: T
    }
  | { isLoading: true }

function inputValueToCryptoAmount(
  inputValue: string,
  decimals: number
): string {
  const decimalPlaces = Math.min(decimals, 12)
  const cryptoAmountString = trimDecimalPlaces(inputValue, decimalPlaces)
  return parseUnits(cryptoAmountString, decimals).toString()
}

type StakingWidgetProps = {
  stakingEnabled: Loadable<boolean>
}

function StakingWidget({ stakingEnabled }: StakingWidgetProps) {
  // Get user, config data, helpers
  const { account, chainId } = useSwellWeb3()
  const { chainId: deploymentChainId, addresses } = useDeploymentSetConfig()
  const { explorer } = useChainInfo()
  const { notify, removeNotification } = useGlobalNotification()

  // Get token balances
  const ethBalance = useEthBalance()
  const swethBalance = useSwEthBalance()
  const swexitBalance = useSwExitBalance()
  const tokenBalancesQuery = useTokenBalances()
  const tokenBalancesData = tokenBalancesQuery.data

  // Get referrer address if exists
  const [searchParams] = useSearchParams()
  const referrerAddress = searchParams.get('ref') || undefined

  // Action chooser state
  const [action, setAction] = useState<string>(ACTIONS.STAKE)
  const [toastAction, setToastAction] = useState<string>(ACTIONS.STAKE)

  // Build actions array
  const actions = [ACTIONS.STAKE]
  if (swexitBalance.data && swexitBalance.data.balance > 0) {
    actions.push(ACTIONS.CLAIM)
  }

  // Form State
  const [touched, setTouched] = useState<boolean>(false)
  const [tokenSelectOpen, setTokenSelectOpen] = useState<boolean>(false)
  const [srcTokenInputValue, setSrcTokenInputValue] = useState<string>('')
  const [destTokenInputValue, setDestTokenInputValue] = useState<string>('')

  // Instantiate formatters and parsers
  const formatSwEth = useFormatSwEth()
  const parseSwEth = useParseSwEth()
  const formatNativeCurrency = useFormatNativeCurrency()
  const parseNativeCurrency = useParseNativeCurrency()
  const displaySwEthPriceFiat = useDisplaySwEthPriceFiat()
  const displayNativeCurrencyPriceFiat = useDisplayNativeCurrencyPriceFiat()

  // Swell staking function
  const stakeNativeCurrency = useStakeNativeCurrency()
  const { clearStaking } = useStakingActions()

  // Get ETH/swETH rates for staking
  const ethUsdMarketRate = useEthUsdMarketRate()
  const swEthUsdMarketRate = useSwEthUsdMarketRate()
  const stakingRate = useStakingRate()

  // State / data for staking
  const { nativeCurrencyDisplayStr, swEthAmountDisplayStr } =
    useStakingDisplayStrings(stakingRate.data?.swETHToETHRate)

  const { status: stakingSubmissionStatus, txHash, error } = useStakeLens()

  /**
   * Responsible for watching the status of the submission and displaying
   * a progress bar upon a pending stake,
   * a banner to the user upon successful staking,
   * or encountering an error while staking.
   */
  useEffect(() => {
    if (stakingSubmissionStatus === StakingSubmissionStatus.FULFILLED) {
      setTouched(false)
      setSrcTokenInputValue('')
      setDestTokenInputValue('')

      return
    }

    if (stakingSubmissionStatus === StakingSubmissionStatus.ERROR && error) {
      const nId = notify(error, GLOBAL_NOTIFICATION_TYPES.ERROR)

      return () => {
        removeNotification(nId)
      }
    }
  }, [
    error,
    explorer,
    nativeCurrencyDisplayStr,
    notify,
    removeNotification,
    setSrcTokenInputValue,
    setDestTokenInputValue,
    setTouched,
    stakingSubmissionStatus,
    swEthAmountDisplayStr,
    txHash,
  ])

  /* Unstake logic */
  const [isUnstakeView, setIsUnstakeView] = useState<boolean>(false)
  const [unstakeModalOpen, setUnstakeModalOpen] = useState<boolean>(false)

  const createWithdrawRequest = useCreateWithdrawRequest()

  /* Unstake side effects */
  useEffect(() => {
    if (isUnstakeView) {
      setToastAction(ACTIONS.UNSTAKE)
      setSrcToken(TOKEN_LIST_SWETH)
      setDestToken(TOKEN_LIST_ETH)
    } else {
      setToastAction(ACTIONS.STAKE)
      setSrcToken(TOKEN_LIST_ETH)
      setDestToken(TOKEN_LIST_ETH)
    }
  }, [isUnstakeView])

  useEffect(() => {
    if (swexitBalance.data && swexitBalance.data.balance === 0) {
      setTimeout(() => {
        setAction(ACTIONS.STAKE)
      }, 5000)
    }
  }, [swexitBalance.data])

  /* Zap/Swap state and logic */
  const zap = useZap()
  const [srcToken, setSrcToken] = useState<any>(null)
  const [destToken, setDestToken] = useState<Token>(TOKEN_LIST_SWETH)
  const swapSrcTokenContract = useIERC20Contract(srcToken?.address)
  const [slippageTolerance, setSlippageTolerance] = useState<number>(0.5)

  const srcTokenIsEth = srcToken && srcToken.symbol === TOKEN_LIST_ETH.symbol
  const srcTokenIsSwEth =
    srcToken && srcToken.symbol === TOKEN_LIST_SWETH.symbol

  const srcTokenBalance = useMemo<BigNumber>(() => {
    if (!srcToken || !tokenBalancesData) {
      return BigNumber.from(0)
    }

    const token = tokenBalancesData.tokenBalances.find(
      (t: any) => t.address === srcToken.address
    )
    if (!token) {
      return BigNumber.from(0)
    }
    return token.balance
  }, [srcToken, tokenBalancesData])

  // li.fi get swap routes logic
  const getRoutesQueryParams = {
    fromChainId: chainId,
    fromAmount: inputValueToCryptoAmount(
      srcTokenInputValue || '0',
      srcToken?.decimals || 18
    ),
    fromTokenAddress: srcToken?.address,
    toChainId: chainId,
    toTokenAddress: destToken?.address,
    options: {
      slippage: (slippageTolerance / 100).toString(),
      maxPriceImpact: '0.4',
    },
    fromAddress: account,
    toAddress: account,
  }

  let getRoutesPaused =
    !srcToken || !destToken || (!srcTokenInputValue && !destTokenInputValue)
  if (action !== ACTIONS.ZAP) {
    getRoutesPaused = true
  }
  const getRoutesQuery = useGetRoutesQuery(
    getRoutesQueryParams,
    getRoutesPaused
  )
  const getRoutesData = getRoutesQuery.data

  /* Swap side effects */
  // set dest token input value if src token input value is changed
  useEffect(() => {
    if (getRoutesData && action === ACTIONS.ZAP) {
      const destTokenAmountStr = Number(formatSwEth(getRoutesData.toAmount))
        .toPrecision(6)
        .toString()
      setDestTokenInputValue(destTokenAmountStr)
    }
  }, [action, getRoutesData, srcTokenInputValue, formatSwEth])

  /* Allowance and Approval logic */
  const allowance = useAllowance(swapSrcTokenContract, addresses.swExit)
  const approve = useApprove(swapSrcTokenContract, addresses.swExit)

  // UI logic and state
  useEffect(() => {
    if (srcTokenInputValue) setTouched(true)
  }, [srcTokenInputValue])

  const ethValueErrorMsg = (): string | null => {
    if (action === ACTIONS.STAKE || srcTokenIsEth) {
      if (!ethBalance.data || !swethBalance.data || !touched) return null

      if (srcTokenInputValue === '') return 'Must enter a value'

      const ethAmountBN = parseNativeCurrency(srcTokenInputValue)
      if (ethAmountBN.eq(0)) return 'Value cannot be 0'
      if (isUnstakeView) {
        if (ethAmountBN.gt(swethBalance.data.balance))
          return 'Insufficient balance'
      } else {
        if (ethAmountBN.gt(ethBalance.data.balance))
          return 'Insufficient balance'
      }
      if (isUnstakeView) {
        if (Number(srcTokenInputValue) < 0.005) {
          return 'Cannot unstake less than 0.005 swETH'
        }
        if (Number(srcTokenInputValue) > 500) {
          return 'Cannot unstake more than 500 swETH'
        }
      }
    } else if (action === ACTIONS.ZAP) {
      if (!tokenBalancesData || !touched) return null

      if (srcTokenInputValue === '') return 'Must enter a value'

      const tokenAmount = inputValueToCryptoAmount(
        srcTokenInputValue,
        srcToken.decimals
      )
      const tokenAmountBN = BigNumber.from(tokenAmount)
      if (tokenAmountBN.eq(0)) return 'Value cannot be 0'
      if (tokenAmountBN.gt(srcTokenBalance)) return 'Insufficient balance'
    }

    return null
  }

  const preventInteraction = (): boolean => {
    if (
      stakingRate.isLoading ||
      ethBalance.isLoading ||
      stakingEnabled.isLoading ||
      tokenBalancesQuery.isLoading
    ) {
      return true
    }
    if (!stakingEnabled.value) return true
    if (chainId !== deploymentChainId) {
      return true
    }
    if (action === ACTIONS.ZAP && !srcToken) {
      return true
    }
    return false
  }

  const stakeDisabled = (): boolean => {
    if (preventInteraction()) return true
    if (ethValueErrorMsg()) return true
    if (srcTokenInputValue === '') return true

    return false
  }

  const showApprove = (): boolean => {
    const compareValue = srcTokenInputValue
      ? BigNumber.from(
          inputValueToCryptoAmount(srcTokenInputValue, srcToken.decimals)
        )
      : -1
    const hasEnoughAllowance = allowance.amount.gte(compareValue)

    return !srcTokenIsEth && !hasEnoughAllowance
  }

  const approveDisabled = () => {
    return (
      allowance.isLoading ||
      approve.isLoading ||
      !srcToken ||
      !srcTokenInputValue ||
      !destTokenInputValue ||
      !!ethValueErrorMsg()
    )
  }

  const zapDisabled = () => {
    if (ethValueErrorMsg()) return true
    if (srcTokenInputValue === '') return true
    if (zap.isLoading) return true
    if (!getRoutesData) return true
    if (getRoutesQuery.isLoading || getRoutesQuery.isValidating) return true

    return false
  }

  const routeLoading = () => {
    return (
      action === ACTIONS.ZAP &&
      (getRoutesQuery.isLoading || getRoutesQuery.isValidating)
    )
  }

  /* Interaction handlers */

  const handleActionClick = (action: string) => {
    if (action === ACTIONS.STAKE) {
      setSrcToken(TOKEN_LIST_ETH)
    } else if (action === ACTIONS.ZAP) {
      setSrcToken(undefined)
    }

    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setAction(action)
  }

  const handleTokenSelect = (token: any) => {
    setSrcToken(token)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
  }

  const handleEthInputChange = (event: any) => {
    if (!stakingRate.data) throw new Error(`No staking data`)

    const value = event.target.value
    if (value === '') {
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
      return
    }

    let decimalPlaces = 18
    if (action === ACTIONS.ZAP) {
      decimalPlaces = Math.min(srcToken.decimals, 12)
    }

    const valueClean = trimDecimalPlaces(value, decimalPlaces)
    setSrcTokenInputValue(valueClean)

    if (action === ACTIONS.STAKE) {
      const { swETHToETHRate } = stakingRate.data!

      if (isUnstakeView) {
        const swETHAmount = parseSwEth(valueClean)
        const nativeAmount = getNativeCurrencyToExchangeForSweth({
          swethToEthRate: swETHToETHRate,
          toReceiveSweth: swETHAmount,
        })
        setDestTokenInputValue(formatNativeCurrency(nativeAmount))
      } else {
        const nativeAmount = parseNativeCurrency(valueClean)
        const swETHAmount = getSwethReceivedForNativeCurrency({
          swethToEthRate: swETHToETHRate,
          toSendNativeCurrency: nativeAmount,
        })
        setDestTokenInputValue(formatSwEth(swETHAmount))
      }
    }
  }

  const handleMaxClick = () => {
    if (action === ACTIONS.STAKE || srcTokenIsEth || srcTokenIsSwEth) {
      if (isUnstakeView) {
        if (!swethBalance.data) return
        const { balance } = swethBalance.data
        handleEthInputChange({ target: { value: formatSwEth(balance) } })
      } else {
        if (!ethBalance.data) return
        const { balance } = ethBalance.data
        handleEthInputChange({
          target: { value: formatNativeCurrency(balance) },
        })
      }
    } else if (action === ACTIONS.ZAP) {
      if (!srcTokenBalance) return
      handleEthInputChange({
        target: { value: formatUnits(srcTokenBalance, srcToken.decimals) },
      })
    }
  }

  const handleSwEthInputChange = (event: any) => {
    if (!stakingRate.data) throw new Error(`No staking data`)

    const value = event.target.value
    if (value === '') {
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
      return
    }

    let decimals = 18
    if (action === ACTIONS.ZAP) {
      decimals = Math.min(destToken.decimals, 12)
    }

    const valueClean = trimDecimalPlaces(value, decimals)
    setDestTokenInputValue(valueClean)

    if (action === ACTIONS.STAKE) {
      const { swETHToETHRate } = stakingRate.data!

      if (isUnstakeView) {
        const nativeCurrencyAmount = parseNativeCurrency(valueClean)
        const swETHAmount = getSwethReceivedForNativeCurrency({
          swethToEthRate: swETHToETHRate,
          toSendNativeCurrency: nativeCurrencyAmount,
        })
        setSrcTokenInputValue(formatSwEth(swETHAmount))
      } else {
        const swETHAmount = parseSwEth(valueClean)
        const nativeAmount = applyBigNumberRateBN(swETHAmount, swETHToETHRate)

        setSrcTokenInputValue(formatNativeCurrency(nativeAmount))
      }
    }
  }

  const handleUnstakeClick = () => {
    setIsUnstakeView(!isUnstakeView)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setTouched(false)
  }

  const handleSlippageToleranceSelect = (tolerance: number) => {
    setSlippageTolerance(tolerance)
  }

  const handleStakeClick = () => {
    const ethAmountBN = parseNativeCurrency(srcTokenInputValue)
    stakeNativeCurrency(ethAmountBN, referrerAddress)
  }

  const handleRequestUnstakeClick = () => {
    setUnstakeModalOpen(true)
  }

  const onUnstakeModalClose = (confirmed: boolean) => {
    if (confirmed) {
      const swethAmountBN = parseSwEth(srcTokenInputValue)
      createWithdrawRequest.sendTransaction(swethAmountBN)
    }
    setUnstakeModalOpen(false)
  }

  const handleApproveClick = async () => {
    // TODO: using same approve for both unstaking and zapping. Need to reconcile
    if (!isUnstakeView) {
      setToastAction(ACTIONS.ZAP)
    }
    const approved = await approve.sendTransaction(srcTokenInputValue, srcToken)
    if (approved) {
      allowance.set(approved)
    }
  }

  const handleZapClick = async () => {
    if (!getRoutesData) {
      return
    }

    setToastAction(ACTIONS.ZAP)
    const result = await zap.sendTransaction(getRoutesData.route)

    if (result.error) {
      notify(result.error.message, GLOBAL_NOTIFICATION_TYPES.ERROR)
      return
    }

    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setTouched(false)
  }

  /* Transaction toast logic */
  useEffect(() => {
    if (
      stakingSubmissionStatus === StakingSubmissionStatus.FULFILLED ||
      approve.status === approve.STATUS.FULFILLED ||
      zap.status === zap.STATUS.FULFILLED
    ) {
      setTimeout(() => {
        clearStaking()
        approve.clear()
        zap.clear()
      }, 5000)
    }
  }, [approve, clearStaking, stakingSubmissionStatus, zap])

  const toastOpen = (): boolean => {
    if (stakingSubmissionStatus !== StakingSubmissionStatus.IDLE) {
      if (stakingSubmissionStatus === StakingSubmissionStatus.ERROR) {
        return false
      }
      return true
    }

    return (
      createWithdrawRequest.status !== createWithdrawRequest.STATUS.IDLE ||
      approve.status !== approve.STATUS.IDLE ||
      zap.status !== zap.STATUS.IDLE
    )
  }

  const toastTitle = (): string => {
    if (approve.status === approve.STATUS.PROMPTING) {
      return TRANSACTION_TOAST_TITLE.APPROVE_PROMPTING
    } else if (approve.status === approve.STATUS.PENDING) {
      return TRANSACTION_TOAST_TITLE.APPROVE_PENDING
    } else if (zap.status === zap.STATUS.BUILDING) {
      return TRANSACTION_TOAST_TITLE.ZAP_BUILDING
    } else if (zap.status === zap.STATUS.PROMPTING) {
      return TRANSACTION_TOAST_TITLE.ZAP_PROMPTING
    } else if (zap.status === zap.STATUS.PENDING) {
      return TRANSACTION_TOAST_TITLE.ZAP_PENDING
    } else if (
      createWithdrawRequest.status === createWithdrawRequest.STATUS.PROMPTING
    ) {
      return TRANSACTION_TOAST_TITLE.UNSTAKE_PENDING
    } else if (
      createWithdrawRequest.status === createWithdrawRequest.STATUS.PENDING
    ) {
      return TRANSACTION_TOAST_TITLE.UNSTAKE_PROMPTING
    } else if (
      createWithdrawRequest.status === createWithdrawRequest.STATUS.FULFILLED
    ) {
      return TRANSACTION_TOAST_TITLE.UNSTAKE_COMPLETED
    } else if (stakingSubmissionStatus === StakingSubmissionStatus.PROMPTING) {
      return TRANSACTION_TOAST_TITLE.STAKE_PROMPTING
    } else if (stakingSubmissionStatus === StakingSubmissionStatus.PENDING) {
      return TRANSACTION_TOAST_TITLE.STAKE_PENDING
    }
    return TRANSACTION_TOAST_TITLE.COMPLETED
  }

  const toastMessage = (): React.ReactNode => {
    if (approve.isLoading) {
      const formattedApproveAmount = displayCrypto(
        approve.amount,
        approve.token.decimals,
        {
          precision: 4,
          localize: true,
        }
      )
      return `Approve ${formattedApproveAmount} ${approve.token.symbol}`
    } else if (toastAction === ACTIONS.ZAP) {
      if (approve.status !== approve.STATUS.IDLE) {
        const formattedApproveAmount = displayCrypto(
          approve.amount,
          approve.token.decimals,
          {
            precision: 4,
            localize: true,
          }
        )

        return `Approve ${formattedApproveAmount} ${approve.token.symbol}`
      } else if (zap.status !== zap.STATUS.IDLE) {
        if (!zap.toToken || !zap.fromToken) {
          return `Zap for swETH`
        }

        const formattedFromAmount = displayCrypto(
          zap.fromAmount,
          zap.fromToken.decimals,
          {
            precision: 4,
            localize: true,
          }
        )

        const formattedToAmount = displayCrypto(
          zap.toAmount,
          zap.toToken.decimals,
          {
            precision: 4,
            localize: true,
          }
        )

        return `Zap ${formattedFromAmount} ${zap.fromToken.symbol} for ${formattedToAmount} swETH`
      }
    } else if (isUnstakeView) {
      if (
        createWithdrawRequest.status === createWithdrawRequest.STATUS.FULFILLED
      ) {
        return (
          <span>
            Check{' '}
            <Link
              onClick={() => {
                setAction(ACTIONS.CLAIM)
              }}
            >
              claim tab
            </Link>
            .
          </span>
        )
      }

      return `Unstaking ${srcTokenInputValue} swETH for ${Number(
        destTokenInputValue
      ).toFixed(3)} ETH`
    }

    return `Stake ${nativeCurrencyDisplayStr} for ${swEthAmountDisplayStr}`
  }

  const toastTxHash = () => {
    if (toastAction === ACTIONS.ZAP && zap.tx) {
      return zap.tx.hash
    }
    return txHash
  }

  const transactionConfirming = (): boolean => {
    return (
      stakingSubmissionStatus === StakingSubmissionStatus.PROMPTING ||
      createWithdrawRequest.status === createWithdrawRequest.STATUS.PROMPTING ||
      approve.status === approve.STATUS.PROMPTING ||
      zap.status === zap.STATUS.BUILDING ||
      zap.status === zap.STATUS.PROMPTING
    )
  }

  const transactionPending = (): boolean => {
    return (
      stakingSubmissionStatus === StakingSubmissionStatus.PENDING ||
      createWithdrawRequest.status === createWithdrawRequest.STATUS.PENDING ||
      approve.status === approve.STATUS.PENDING ||
      zap.status === zap.STATUS.PENDING
    )
  }

  const transactionComplete = (): boolean => {
    return (
      stakingSubmissionStatus === StakingSubmissionStatus.FULFILLED ||
      createWithdrawRequest.status === createWithdrawRequest.STATUS.FULFILLED ||
      approve.status === approve.STATUS.FULFILLED ||
      zap.status === zap.STATUS.FULFILLED
    )
  }

  const onToastClose = () => {
    clearStaking()
    createWithdrawRequest.clear()
    approve.clear()
    zap.clear()
  }

  const ApproveButton = () => {
    return (
      <StakeWidgetButton
        disabled={approveDisabled()}
        onClick={handleApproveClick}
      >
        <ButtonInner>
          {(approve.status === approve.STATUS.PROMPTING ||
            approve.status === approve.STATUS.PENDING) && (
            <ButtonProgressPlacer>
              <ButtonCircularProgress size={24} />
            </ButtonProgressPlacer>
          )}
          {approve.status === approve.STATUS.PROMPTING && 'Pending...'}
          {approve.status === approve.STATUS.PENDING && 'Confirming...'}
          {approve.status !== approve.STATUS.PROMPTING &&
            approve.status !== approve.STATUS.PENDING &&
            'Approve'}
        </ButtonInner>
      </StakeWidgetButton>
    )
  }

  const tokenDepositValueUsd = () => {
    if (action !== ACTIONS.ZAP) return null
    if (!getRoutesData?.route) return null
    return displayFiat(Number(getRoutesData.route.fromAmountUSD))
  }

  const zapReceivedValueUsd = () => {
    if (action !== ACTIONS.ZAP) return null
    if (!getRoutesData?.route) return null
    return displayFiat(Number(getRoutesData.route.toAmountUSD))
  }

  const ethDepositValueUsd = () => {
    if (action !== ACTIONS.STAKE) return null
    if (isUnstakeView) return null
    if (!ethUsdMarketRate.data) return null
    return displayNativeCurrencyPriceFiat(
      parseNativeCurrency(srcTokenInputValue || '0'),
      ethUsdMarketRate.data.rate
    )
  }

  const swEthWithdrawalValueUsd = () => {
    if (action !== ACTIONS.STAKE) return null
    if (!isUnstakeView) return null
    if (!swEthUsdMarketRate.data) return null
    const swethAmount = parseSwEth(srcTokenInputValue || '0')
    return displaySwEthPriceFiat(swethAmount, swEthUsdMarketRate.data.rate)
  }

  const ethWithdrawalValueUsd = () => {
    if (action !== ACTIONS.STAKE) return null
    if (!isUnstakeView) return null
    if (!ethUsdMarketRate.data) return null
    return displayNativeCurrencyPriceFiat(
      parseNativeCurrency(destTokenInputValue || '0'),
      ethUsdMarketRate.data.rate
    )
  }

  const swETHReceivedValueUsd = () => {
    if (action !== ACTIONS.STAKE) return null
    if (isUnstakeView) return null
    if (!swEthUsdMarketRate.data) return null
    const swethAmount = parseSwEth(destTokenInputValue || '0')
    return displaySwEthPriceFiat(swethAmount, swEthUsdMarketRate.data.rate)
  }

  let usdStringTop: string | null = null
  let usdStringBottom: string | null = null
  if (action === ACTIONS.STAKE) {
    if (isUnstakeView) {
      usdStringTop = swEthWithdrawalValueUsd()
      usdStringBottom = ethWithdrawalValueUsd()
    } else {
      usdStringTop = ethDepositValueUsd()
      usdStringBottom = swETHReceivedValueUsd()
    }
  }

  if (action === ACTIONS.ZAP) {
    usdStringTop = tokenDepositValueUsd()
    usdStringBottom = zapReceivedValueUsd()
  }

  return (
    <StakingWidgetBox>
      <ActionChooser actions={actions} onActionClick={handleActionClick} />
      {action === ACTIONS.STAKE && (
        <>
          <AvailableChip
            token={srcToken}
            tokenBalances={tokenBalancesData?.tokenBalances}
          />
          <UtilitiesContainer>
            <CryptoUtilities />
          </UtilitiesContainer>
          <div>
            {/* Can i make this more reusable somehow? TokenInputLabel */}
            <FlexRow justify="space-between" align="center">
              <Typography variant="body" size="xlarge" fstyle="bold">
                {isUnstakeView ? 'Unstake' : action}
              </Typography>
              {action === ACTIONS.STAKE && (
                <SymbolWrapper>
                  {!isUnstakeView && (
                    <>
                      <StyledEthIcon />
                      <Typography variant="body" size="large" fstyle="bold">
                        ETH
                      </Typography>
                    </>
                  )}
                  {isUnstakeView && (
                    <>
                      <StyledSwethIcon />
                      <Typography variant="body" size="large" fstyle="bold">
                        swETH
                      </Typography>
                    </>
                  )}
                </SymbolWrapper>
              )}
              {action === ACTIONS.ZAP && (
                <TokenSelectWidget
                  isOpen={tokenSelectOpen}
                  onClose={() => setTokenSelectOpen(false)}
                  onTokenSelect={handleTokenSelect}
                  tokenBalances={tokenBalancesData?.tokenBalances}
                />
              )}
            </FlexRow>
            <EthInputWrapper>
              <StakingWidgetEthInput
                variant="standard"
                value={srcTokenInputValue}
                onChange={handleEthInputChange}
                error={!!ethValueErrorMsg()}
                helperText={ethValueErrorMsg()}
                disabled={preventInteraction()}
                onMaxClick={handleMaxClick}
              />
            </EthInputWrapper>
            {/* TODO: price should be displayed as 'xx USD' */}
            {!ethValueErrorMsg() && usdStringTop && (
              <UsdTypography variant="body" size="xsmall">
                {usdStringTop}
              </UsdTypography>
            )}
            <FlexRow justify="center">
              <UnstakeButton onClick={handleUnstakeClick}>
                <ArrowDownIcon />
              </UnstakeButton>
            </FlexRow>
            <FlexRow justify="space-between" align="center">
              <Typography variant="body" size="xlarge" fstyle="bold">
                Receive
              </Typography>
              <SymbolWrapper>
                {isUnstakeView && (
                  <>
                    <StyledEthIcon />
                    <Typography variant="body" size="large" fstyle="bold">
                      ETH
                    </Typography>
                  </>
                )}
                {!isUnstakeView && (
                  <>
                    <StyledSwethIcon />
                    <Typography variant="body" size="large" fstyle="bold">
                      swETH
                    </Typography>
                  </>
                )}
              </SymbolWrapper>
            </FlexRow>
            <EthInputWrapper>
              {(action !== ACTIONS.ZAP || !routeLoading()) && (
                <SwEthInput
                  variant="standard"
                  value={destTokenInputValue}
                  onChange={handleSwEthInputChange}
                  disabled={preventInteraction() || action === ACTIONS.ZAP}
                />
              )}
              {routeLoading() && (
                <>
                  <SwEthInput variant="standard" disabled={true} />
                  <InputCircularProgress size={35} />
                </>
              )}
            </EthInputWrapper>
            {usdStringBottom && (
              <UsdTypography variant="body" size="xsmall">
                {usdStringBottom}
              </UsdTypography>
            )}
          </div>
          <Divider />
          {action === ACTIONS.STAKE && <ExchangeInfo unstake={isUnstakeView} />}
          {action === ACTIONS.ZAP && (
            <SwapInfo
              isLoading={
                getRoutesQuery?.isLoading || getRoutesQuery?.isValidating
              }
              srcTokenSymbol={srcToken?.symbol || ''}
              destTokenSymbol={destToken?.symbol || ''}
              bestRouteInfo={getRoutesData?.swapInfo}
              setSlippagePercent={handleSlippageToleranceSelect}
              slippagePercent={slippageTolerance}
              defaultSlippagePercent={DEFAULT_SLIPPAGE_PERCENT}
            />
          )}
          <div>
            {account && action === ACTIONS.STAKE && (
              <>
                {!isUnstakeView && (
                  <StakeWidgetButton
                    disabled={stakeDisabled()}
                    onClick={handleStakeClick}
                  >
                    <ButtonInner>
                      {(stakingSubmissionStatus ===
                        StakingSubmissionStatus.PROMPTING ||
                        stakingSubmissionStatus ===
                          StakingSubmissionStatus.PENDING) && (
                        <ButtonProgressPlacer>
                          <ButtonCircularProgress size={24} />
                        </ButtonProgressPlacer>
                      )}
                      {stakingSubmissionStatus ===
                        StakingSubmissionStatus.PROMPTING && 'Pending...'}
                      {stakingSubmissionStatus ===
                        StakingSubmissionStatus.PENDING && 'Confirming...'}
                      {stakingSubmissionStatus !==
                        StakingSubmissionStatus.PROMPTING &&
                        stakingSubmissionStatus !==
                          StakingSubmissionStatus.PENDING &&
                        'Stake'}
                    </ButtonInner>
                  </StakeWidgetButton>
                )}
                {isUnstakeView && (
                  <>
                    {showApprove() && <ApproveButton />}
                    {!showApprove() && (
                      <StakeWidgetButton
                        disabled={stakeDisabled()}
                        onClick={handleRequestUnstakeClick}
                      >
                        Request unstake
                      </StakeWidgetButton>
                    )}
                  </>
                )}
              </>
            )}
            {account && action === ACTIONS.ZAP && (
              <>
                {!srcToken && (
                  <StakeWidgetButton onClick={() => setTokenSelectOpen(true)}>
                    Select Token
                  </StakeWidgetButton>
                )}
                {srcToken && showApprove() && <ApproveButton />}
                {srcToken && !showApprove() && (
                  <StakeWidgetButton
                    disabled={zapDisabled()}
                    onClick={handleZapClick}
                  >
                    {routeLoading() && <ButtonCircularProgress size={35} />}
                    {!routeLoading() && (
                      <ButtonInner>
                        {(zap.status === zap.STATUS.BUILDING ||
                          zap.status === zap.STATUS.PROMPTING ||
                          zap.status === zap.STATUS.PENDING) && (
                          <ButtonProgressPlacer>
                            <ButtonCircularProgress size={24} />
                          </ButtonProgressPlacer>
                        )}
                        {zap.status === zap.STATUS.BUILDING && 'Building...'}
                        {zap.status === zap.STATUS.PROMPTING && 'Pending...'}
                        {zap.status === zap.STATUS.PENDING && 'Confirming...'}
                        {zap.status !== zap.STATUS.BUILDING &&
                          zap.status !== zap.STATUS.PROMPTING &&
                          zap.status !== zap.STATUS.PENDING &&
                          'Zap'}
                      </ButtonInner>
                    )}
                  </StakeWidgetButton>
                )}
              </>
            )}
            {!account && (
              <StakeConnectButton>Connect wallet</StakeConnectButton>
            )}
          </div>
          <StakingConfirmationProgressWidget
            complete={transactionComplete()}
            confirming={transactionConfirming()}
            message={toastMessage()}
            onClose={onToastClose}
            pending={transactionPending()}
            txHash={toastTxHash()}
            open={toastOpen()}
            title={toastTitle()}
          />
          <div>
            <SwellStatistics />
          </div>
          <UnstakeConfirmModal
            open={unstakeModalOpen}
            onClose={onUnstakeModalClose}
            withdrawalDelayDurationDays={12}
            delayExplainerLink={'https://www.validatorqueue.com/'}
          />
        </>
      )}
      {action === ACTIONS.VAULT && <VaultView />}
      {action === ACTIONS.CLAIM && <ClaimView />}
    </StakingWidgetBox>
  )
}

export { StakingWidget }

const Link = styled.a`
  color: ${({ theme }) => theme.colors.white['50']};
  text-decoration: underline !important;

  &:hover {
    color: ${({ theme }) => theme.colors.white['150']};
  }
  cursor: pointer;
`
