import ms from 'ms.macro'
import { useDeploymentSetConfig } from './useDeploymentSetConfig'

export const useDataLivenessConfig = () => {
  const { chainId } = useDeploymentSetConfig()

  return {
    fiatDataLiveness: {
      get primaryRefreshIntervalMs() {
        return ms`12s`
      },
      get fallbackBlocksPerFetch() {
        switch (chainId) {
          default:
            return 1
        }
      },
    },
    gasPriceLiveness: {
      get blocksPerFetch() {
        switch (chainId) {
          default:
            return 1
        }
      },
    },
    userTokenBalancesLiveness: {
      get blocksPerFetch() {
        switch (chainId) {
          default:
            return 1
        }
      },
    },
  }
}
