import React from 'react'
import styled from 'styled-components/macro'
import MuiSkeleton, { SkeletonProps } from '@mui/material/Skeleton'

const StyledMuiSkeleton = styled(MuiSkeleton)`
  ${({ theme }) => `
    background-color: ${theme.accentBgColor};
  `}
`

function Skeleton(props: SkeletonProps) {
  return <StyledMuiSkeleton {...props} />
}

export { Skeleton }
