import { css } from 'styled-components'

export const yearnWithdrawTypographyCSS = css`
  .title {
    color: var(--white-50, #fff);
    /* Body/xlarge bold */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 44.8px */
  }

  .big {
    color: #fff;
    /* Body/xlarge bold */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 44.8px */
  }

  .med {
    color: #fff;

    /* Body/large */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 38.4px */
    letter-spacing: -0.72px;
  }

  .subheading {
    color: var(--white-125, #b0b0b0);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 19.2px */
    letter-spacing: 0.9px;
    text-transform: uppercase;
  }

  .emphasis {
    color: #fff;

    /* Body/small bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 22.4px */
  }

  .body {
    color: var(--white-50, #fff);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.176px;
  }
`
