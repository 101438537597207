import styled from 'styled-components'
import { useSwellWeb3 } from '@swell-web3/core'
import { PageTitle as RPageTitle } from '@/components/PageTitle'
import { useDisplaySwEth } from '@/hooks/useSwEthDisplay'
import { useSwEthBalance } from '@/state/user/hooks'

const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
`

const TITLE = 'Earn with Swell'

export function PageTitle() {
  const { account } = useSwellWeb3()
  const swEthBalance = useSwEthBalance()
  const displaySwEth = useDisplaySwEth()

  const subtitle = () => {
    let balanceDisplay: any = 'swETH'
    if (account && swEthBalance.data && swEthBalance.data.balance.gt(0)) {
      balanceDisplay = (
        <Highlight>
          {displaySwEth(swEthBalance.data.balance, {
            precision: 4,
            localize: true,
          })}
        </Highlight>
      )
    }

    return (
      <>
        Deploy your {balanceDisplay} across the Swell ecosystem. Utilize swETH
        to provide liquidity, mint stables, restake on EigenLayer, and much
        more.
      </>
    )
  }
  return <RPageTitle title={TITLE} subtitle={subtitle()} />
}
