import React from 'react'

function CaretDownIcon(props: any) {
  return (
    <svg
      {...props}
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.73448 4.51552L0.984478 0.765521C0.949637 0.730679 0.922 0.689317 0.903144 0.643794C0.884288 0.598272 0.874582 0.549481 0.874582 0.500208C0.874582 0.450935 0.884288 0.402144 0.903144 0.356622C0.922 0.3111 0.949637 0.269737 0.984478 0.234895C1.01932 0.200054 1.06068 0.172417 1.1062 0.153561C1.15173 0.134705 1.20052 0.125 1.24979 0.125C1.29906 0.125 1.34786 0.134705 1.39338 0.153561C1.4389 0.172417 1.48026 0.200054 1.5151 0.234895L4.99979 3.72005L8.48448 0.234896C8.55484 0.164531 8.65028 0.125 8.74979 0.125C8.8493 0.125 8.94474 0.164531 9.0151 0.234896C9.08547 0.305261 9.125 0.400697 9.125 0.500208C9.125 0.59972 9.08547 0.695156 9.0151 0.765521L5.2651 4.51552C5.23028 4.55039 5.18892 4.57805 5.14339 4.59692C5.09787 4.61579 5.04907 4.6255 4.99979 4.6255C4.95051 4.6255 4.90171 4.61579 4.85619 4.59692C4.81066 4.57805 4.76931 4.55039 4.73448 4.51552Z"
        fill="white"
      />
    </svg>
  )
}

export { CaretDownIcon }
