export function formatYearnWithdrawDelay(withdrawDelayUnix: number) {
  if (withdrawDelayUnix === 0) {
    return 'None'
  }

  const oneHour = 60 * 60
  const oneDay = oneHour * 24
  const hoursThreshold = oneHour
  const daysThreshold = oneDay

  if (withdrawDelayUnix >= daysThreshold) {
    const days = Math.round(withdrawDelayUnix / oneDay)
    if (days === 1) {
      return `${days} day`
    }
    return `${days} days`
  }

  if (withdrawDelayUnix >= hoursThreshold) {
    const hours = Math.round(withdrawDelayUnix / oneHour)
    if (hours === 1) {
      return `${hours} hour`
    }
    if (hours === 24) {
      return '1 day'
    }

    return `${hours} hours`
  }

  const seconds = withdrawDelayUnix
  if (seconds === 1) {
    return `${seconds} second`
  }
  return `${seconds} seconds`
}
