import { AddressMap } from '@/constants/addresses'
import { SupportedChainId } from '@/constants/chains'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { TestnetAddresses } from '@/types/deployments'

export const useContractAddresses = () => {
  return useDeploymentSetConfig().addresses
}
export const useTestnetAddresses = (): Partial<
  Record<SupportedChainId, TestnetAddresses>
> => {
  const { testnets } = useDeploymentSetConfig()
  const res: Partial<Record<SupportedChainId, TestnetAddresses>> = {}
  for (const { chainId, addresses } of testnets) {
    res[chainId as SupportedChainId] = addresses
  }
  return res
}

export type TestnetAddressMaps = {
  [K in keyof TestnetAddresses]: AddressMap
}
export const useTestnetAddressMaps = () => {
  const testnetAddresses = useTestnetAddresses()

  const res: TestnetAddressMaps = {} as TestnetAddressMaps
  for (const pair0 of Object.entries(testnetAddresses)) {
    const chainId = parseInt(pair0[0], 10) as SupportedChainId
    const addresses = pair0[1] as TestnetAddresses

    for (const pair1 of Object.entries(addresses)) {
      const [name, address] = pair1 as [keyof TestnetAddresses, string]

      if (!res[name]) {
        res[name] = {} as any
      }

      res[name][chainId] = address
    }
  }

  return res
}
