import useSWR from 'swr/immutable'
import { BigNumber, ethers } from 'ethers'
import { useSwellWeb3 } from '@swell-web3/core'
import { useMulticallContract } from '@/hooks/useContract'
import { isEthAddressAlias } from '@/util/tokens'
import IERC20_ABI from '@/abis/IERC20.json'
import { IERC20, Multicall3 } from '@/abis/types'
import { uniq } from 'lodash'

async function fetchTokenBalances(
  tokenAddresses: string[],
  multicall: Multicall3,
  account: string
) {
  const indexToTokenAddress = new Map<number, string>()
  const calls: Multicall3.CallStruct[] = []
  tokenAddresses.forEach((tokenAddress, index) => {
    indexToTokenAddress.set(index, tokenAddress)
    const contract = new ethers.Contract(tokenAddress, IERC20_ABI) as IERC20

    calls.push({
      target: tokenAddress,
      callData: contract.interface.encodeFunctionData('balanceOf', [account]),
    })
  })

  const results = await multicall.callStatic.tryAggregate(true, calls)

  const balances: Record<string, BigNumber> = {}

  results.forEach((result, index) => {
    const tokenAddress = indexToTokenAddress.get(index)
    if (!tokenAddress) throw new Error('Token address not found')

    const contract = new ethers.Contract(tokenAddress, IERC20_ABI) as IERC20
    const balance = contract.interface.decodeFunctionResult(
      'balanceOf',
      result.returnData
    )[0]

    balances[tokenAddress] = balance
  })

  return balances
}

export function useTokenBalancesMulticall(tokenAddresses: string[]) {
  // filter out addresses that aren't on-chain (e.g. 0xee..ee for ETH)
  let addresses = uniq(tokenAddresses)
  if (addresses.some((address) => isEthAddressAlias(address))) {
    console.warn('useTokenBalancesMulticall cannot handle address aliases', {
      tokenAddresses,
    })
    addresses = addresses.filter((address) => !isEthAddressAlias(address))
  }

  const { account } = useSwellWeb3()
  const multicall = useMulticallContract()

  return useSWR(
    account ? ['balances', account, ...addresses] : null,
    async () => {
      if (!account) throw new Error('account is required')
      return fetchTokenBalances(addresses, multicall!, account)
    }
  )
}
