import React from 'react'
import styled from 'styled-components/macro'
import { Tooltip } from '@swell-ui/Tooltip'
import { useSwellUiTheme } from '@swell-ui/theme'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { MapItem } from './MapItem'
import auraLogoUrl from '@/assets/images/voyage-map/icons/aura-logo.svg'
import balancerLogoUrl from '@/assets/images/voyage-map/icons/balancer-logo.svg'
import bunniLogoUrl from '@/assets/images/voyage-map/icons/bunni-logo.svg'
import communityIconUrl from '@/assets/images/voyage-map/icons/community-icon.svg'
import lightningUrl from '@/assets/images/voyage-map/icons/lightning.svg'
import mavericLogoUrl from '@/assets/images/voyage-map/icons/maveric-logo.svg'
import megaphoneUrl from '@/assets/images/voyage-map/megaphone.png'
import pendleLogoUrl from '@/assets/images/voyage-map/icons/pendle-logo.svg'
import questionMarkUrl from '@/assets/images/voyage-map/icons/question-mark.svg'
import restakeUrl from '@/assets/images/voyage-map/icons/restake.svg'
import swethLogoUrl from '@/assets/images/voyage-map/icons/sweth-logo.svg'
import stepUrl from '@/assets/images/voyage-map/icons/step.png'
import uniswapLogoUrl from '@/assets/images/voyage-map/icons/uniswap-logo.svg'
import bubbleUrl from '@/assets/images/voyage-map/bubble.svg'
import mapPathBgUrl from '@/assets/images/voyage-map/path-bg.png'
import submarineUrl from '@/assets/images/voyage-map/submarine.png'

const PathContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 116px;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      margin-top: 24px;
    }
  `}
`

const PathInner = styled.div`
  position: relative;
  width: max-content;
  margin: 0 auto;
`

const PathBgImg = styled.div`
  position: relative;
  width: 646px;
  height: 1289px;
  z-index: -2;

  background-image: url(${mapPathBgUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      width: 269px;
      height: 538px;
    }
  `}
`

const PathBgTopGradient = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 63px;
  z-index: -1;
  background: linear-gradient(180deg, #020316 0%, rgba(2, 3, 22, 0) 100%);
`

const PathBgBotGradient = styled.div`
  position: absolute;
  bottom: -14px;
  width: 100%;
  height: 70px;
  z-index: 5;
  background: linear-gradient(180deg, #03031a 0%, rgba(3, 3, 26, 0) 100%),
    linear-gradient(180deg, #03031a 0%, rgba(3, 3, 26, 0) 100%),
    linear-gradient(180deg, #03031a 0%, rgba(3, 3, 26, 0) 100%);
  rotate: 180deg;
`

const ItemPositioner = styled.div<any>`
  position: absolute;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      > img {
        width: 82.5px;
      }
    }
  `}
`

const SubPositioner = styled.div`
  position: absolute;
  top: 82%;
  left: -45%;
  width: 52.78%;
  z-index: 3;

  img {
    width: 100%;
  }
`

const ImgContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const BubbleImg = styled.img<any>`
  position: absolute;
  ${({ left }) => (left !== undefined ? `left: ${left}%;` : ``)}
  ${({ right }) => (right !== undefined ? `right: ${right}%;` : ``)}
  top: ${({ top }) => top}%;
  width: ${({ width }) => width}px;
`

function Bubble({
  left,
  top,
  right,
  width = 19,
}: {
  left?: number
  right?: number
  top: number
  width?: number
}) {
  return (
    <BubbleImg
      src={bubbleUrl}
      left={left}
      right={right}
      top={top}
      width={width}
    />
  )
}

const items = [
  {
    position: {
      left: 183,
      top: -33,
    },
    positionMobile: {
      left: 39,
      top: -22,
    },
    mapItemConfig: {
      imgUrl: swethLogoUrl,
      href: 'https://app.swellnetwork.io/',
      label: 'swETH',
      pearlValue: 2,
    },
  },
  {
    position: {
      left: 355,
      top: -1,
    },
    positionMobile: {
      left: 151,
      top: 5,
    },
    mapItemConfig: {
      imgUrl: balancerLogoUrl,
      href: 'https://app.balancer.fi/#/ethereum',
      label: 'BALANCER',
      pearlValue: 3,
    },
  },
  {
    position: {
      left: 271,
      top: 103,
    },
    positionMobile: {
      left: 98,
      top: 39,
    },
    mapItemConfig: {
      imgUrl: auraLogoUrl,
      href: 'https://app.aura.finance/',
      label: 'AURA',
      pearlValue: 3,
    },
  },
  {
    position: {
      left: 130,
      top: 198,
    },
    positionMobile: {
      left: 39,
      top: 74,
    },
    mapItemConfig: {
      imgUrl: mavericLogoUrl,
      href: 'https://app.mav.xyz/boosted-positions?chain=1',
      label: 'MAVERICK',
      pearlValue: 3,
    },
  },
  {
    position: {
      left: 266,
      top: 260,
    },
    positionMobile: {
      left: 105,
      top: 109,
    },
    mapItemConfig: {
      imgUrl: bunniLogoUrl,
      href: 'https://bunni.pro/pools',
      label: 'BUNNI',
      pearlValue: 3,
    },
  },
  {
    position: {
      left: 434,
      top: 307,
    },
    positionMobile: {
      left: 169,
      top: 154,
    },
    mapItemConfig: {
      imgUrl: uniswapLogoUrl,
      href: 'https://info.uniswap.org/#/tokens/0xf951e335afb289353dc249e82926178eac7ded78',
      label: 'UNISWAP',
      pearlValue: 3,
    },
  },
  {
    position: {
      left: 369,
      top: 429,
    },
    positionMobile: {
      left: 114,
      top: 187,
    },
    mapItemConfig: {
      imgUrl: pendleLogoUrl,
      href: 'https://app.pendle.finance/trade/pools',
      label: 'PENDLE',
      pearlValue: 3,
    },
  },
  {
    position: {
      left: 224,
      top: 482,
    },
    positionMobile: {
      left: 50,
      top: 217,
    },
    mapItemConfig: {
      imgUrl: communityIconUrl,
      label: 'COMMUNITY',
      pearlValue: 1,
      tooltip: 'Old community and partner protocol participation',
    },
  },
  {
    position: {
      left: 272,
      top: 618,
    },
    positionMobile: {
      left: 131,
      top: 255,
    },
    mapItemConfig: {
      imgUrl: megaphoneUrl,
      label: 'REFERRAL',
      pearlValue: 2,
      tooltip:
        'Every swETH minted with your referral link earns you and your referee 10 pearls',
    },
  },
  {
    position: {
      left: 434,
      top: 691,
    },
    positionMobile: {
      left: 161,
      top: 311,
    },
    mapItemConfig: {
      imgUrl: stepUrl,
      label: 'L2',
      hidePearls: true,
      tooltip: 'Voyagers begin their journey on L2s',
    },
  },
  {
    position: {
      left: 320,
      top: 792,
    },
    positionMobile: {
      left: 105,
      top: 339,
    },
    mapItemConfig: {
      imgUrl: lightningUrl,
      label: 'Zap',
      hidePearls: true,
    },
  },
  {
    position: {
      left: 138,
      top: 864,
    },
    positionMobile: {
      left: 54,
      top: 405,
    },
    mapItemConfig: {
      imgUrl: restakeUrl,
      pearlValue: 3,
      label: 'Restaking',
    },
  },
  {
    position: {
      left: 382,
      top: 1097,
    },
    positionMobile: {
      left: 144,
      top: 454,
    },
    imgUrl: questionMarkUrl,
  },
]

function MapPath({ totalVoyagers }: { totalVoyagers: string }) {
  const { theme } = useSwellUiTheme()
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'))

  let subTooltipOffset: [number, number] = [0, 120]
  if (isMdDown) {
    subTooltipOffset = [0, 45]
  }

  return (
    <PathContainer>
      <PathInner>
        <PathBgImg>
          <PathBgTopGradient />
        </PathBgImg>
        {items.map((item: any, index: number) => {
          const posKey = isMdDown ? 'positionMobile' : 'position'
          return (
            <ItemPositioner
              key={index}
              left={item[posKey].left}
              top={item[posKey].top}
            >
              {item.mapItemConfig && <MapItem {...item.mapItemConfig} />}
              {item.imgUrl && <img src={item.imgUrl} />}
            </ItemPositioner>
          )
        })}
        <SubPositioner>
          <Tooltip
            title={`${totalVoyagers} voyagers`}
            placement="top"
            arrow={true}
            offset={subTooltipOffset}
          >
            <img src={submarineUrl} />
          </Tooltip>
        </SubPositioner>
        <PathBgBotGradient />
      </PathInner>
      <ImgContainer>
        <Bubble top={16} left={14} />
        <Bubble top={19} left={9.5} />
        <Bubble top={24} left={12} />
        <Bubble top={-7} right={29} width={11} />
        <Bubble top={-3} right={32} width={11} />
        <Bubble top={-1} right={30} />
        <Bubble top={34} right={21} width={11} />
        <Bubble top={37} right={19} />
        <Bubble top={40} right={20} />
        <Bubble top={47} right={16} />
      </ImgContainer>
    </PathContainer>
  )
}

export { MapPath }
