import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BigNumber } from 'ethers'
import {
  formatEther,
  formatUnits,
  parseEther,
  parseUnits,
} from 'ethers/lib/utils'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Box } from '@swell-ui/Box'
import { Button } from '@swell-ui/Button'
import { CircularProgress } from '@swell-ui/CircularProgress'
import { Divider } from '@swell-ui/Divider'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { RswethIcon } from '@swell-ui/icons/RswethIcon'
import { EthInput, SwEthInput } from '@swell-ui/inputs'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { TokenSelectWidget } from '@/components/TokenSelectWidget'
import { SwellStatistics } from '@/components/SwellStatistics'
import { StakingConfirmationProgressWidget } from '@/components/StakingConfirmationProgressWidget'
import { useRestakingRate } from '@/state/restakingStats/hooks'
import {
  useFormatNativeCurrency,
  useParseNativeCurrency,
} from '@/hooks/useNativeCurrencyFormatting'
import { useFormatRswEth, useParseRswEth } from '@/hooks/useRswEthFormatting'
import {
  useRestakingActions,
  useRestakeNativeCurrency,
} from '@/state/restaking/hooks'
import { useRestakingDisplayStrings } from '@/hooks/useRestakingDisplayStrings'
import { StakingSubmissionStatus, useRestakeLens } from '@/hooks/useRestakeLens'
import {
  GLOBAL_NOTIFICATION_TYPES,
  useGlobalNotification,
} from '@/swell-ui/GlobalNotification'
import { useChainInfo, useDeploymentSetConfig } from '@/state/deployments/hooks'
import { useEthUsdMarketRate, useRswEthUsdMarketRate } from '@/state/fiat/hooks'
import { displayCrypto } from '@/util/displayCrypto'
import { trimDecimalPlaces } from '@/util/number'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { ActionChooser } from './ActionChooser'
import { AvailableChip } from './AvailableChip'
import { CryptoUtilities } from './CryptoUtilities'
import { SwapInfo } from './SwapInfo'
import { VaultView } from './VaultView'
import { ACTIONS, TRANSACTION_TOAST_TITLE } from './constants'
import eigenUrl from '@/assets/images/eigenlayer-long-lg.png'
import {
  getNativeCurrencyToExchangeForRsweth,
  getRswethReceivedForNativeCurrency,
} from '@/util/big'
import {
  ZapCall,
  useZapBuilding,
  useZapContractAddress,
  useZapRoutes,
} from '@/state/zap/hooks'
import { Token } from '@/types/tokens'
import { useAllowance } from '@/hooks/erc20/useAllowance'
import { useApprove } from '@/hooks/erc20/useApprove'
import {
  ethDepositValueUsd,
  ethWithdrawalValueUsd,
  rswETHReceivedValueUsd,
  rswEthWithdrawalValueUsd,
  zapDepositValueUsd,
  zapReceivedValueUsd,
} from './usd'
import { ArrowDownIcon } from '@/swell-ui/icons/ArrowDownIcon'
import { RestakingClaimView } from './Restaking/RestakingClaimView'
import { RswethExchangeInfo } from './ExchangeInfo/RswethExchangeInfo'
import {
  ApproveRswETHForWithdrawalButton,
  CreateWithdrawRequestRswETHButton,
  FinalizeWithdrawalRswETHButton,
} from './buttons'
import {
  ApproveRswETHForWithdrawalToast,
  CreateWithdrawRequestRswETHToast,
  FinalizeWithdrawalRswETHToast,
} from './Restaking/RestakingToasts'
import {
  EigenLayerStakedropResult,
  ExitAllowanceMap,
  ExitClaimMap,
} from '@/state/rsweth/types'
import {
  ApproveRswETHForWithdrawal,
  ClaimEigenStakedrop,
  CreateWithdrawRequestRswETH,
  FinalizeWithdrawalRswETH,
} from '@/state/rsweth/hooks'
import { prepareZap } from './Zap/zapCalls'
import { useZapInputBehaviour, useZapRouteParams } from './Zap/zapHooks'
import { useClaimSelect } from './Exit/exitHooks'
import {
  ExitErrors,
  prepareApproveRswETHForWithdrawal,
  prepareCreateWithdrawRequestRswETH,
  prepareFinalizeWithdrawalRswETH,
} from './Exit/exitCalls'
import { ExitAsset, ExitAssets } from '@/types/claims'
import { useIERC20Contract } from '@/hooks/useContract'
import { EXIT_ASSET_RSWETH_ETH } from '@/constants/exits'
import { ClaimEigenWidget } from '../ClaimEigenWidget'
import { MerkleContractsState } from '@/types/merkle'

const MAX_PRICE_IMPACT_RATIO = 0.4
const DEFAULT_SLIPPAGE_PERCENT = 0.5

const FloatingEigenLogo = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;

  transform: translate(-50%, 50%) translate(0, 10px);
`

const StakingWidgetBox = styled(Box)`
  ${({ theme }) => `
    position: relative;
    width: 340px;
    margin: 0 auto;
    padding: 24px 24px 12px;
    margin-top: 18px;
    color: ${theme.mainColor};
    border: none;

    > div {
      margin-bottom: 12px;
    }

    ${theme.breakpoints.up('sm')} {
      width: 420px;
      padding: 24px 32px 12px;


      > div {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 12px;
        }
      }
    }}
  `}

  .Mui-disabled.MuiInputBase-root:before {
    border-bottom-style: solid;
    opacity: 0.6;
  }
`

const UtilitiesContainer = styled.div`
  position: absolute;
  top: 99px;
  right: 32px;
`

const EthInputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  .MuiFormControl-root {
    width: 100%;
  }

  input,
  div > div > p {
    font-size: ${({ theme }) => theme.typography.body.large.fontSize};
    font-weight: 600;
  }
`

const UsdTypography = styled(Typography)`
  height: 19.2px;
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  margin-bottom: 16px;
  margin-top: 2px;
  letter-spacing: -0.03em;
`

const StakingWidgetEthInput = styled(EthInput)`
  ${({ theme }) => `
    .MuiFormHelperText-root {
      margin-top: 2px;
      margin-bottom: 16px;
    }

    ${theme.breakpoints.up('sm')} {
      max-width: unset;
    }
  `}
`

const SymbolWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledEthIcon = styled(EthIcon)`
  width: 35px;
  height: 35px;
  margin-right: 8px;
`

const StyledRswethIcon = styled(RswethIcon)`
  width: 35px;
  height: 35px;
  margin-right: 8px;
`

const StakeWidgetButton = styled(Button)`
  position: relative;
  width: 100%;
`

const StakeConnectButton = styled(ConnectWalletButton)`
  width: 100%;
`

const InputCircularProgress = styled(CircularProgress)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`

const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`

const ButtonInner = styled.div`
  position: relative;
`

const ButtonProgressPlacer = styled.div`
  position: absolute;
  top: -7px;
  left: -32px;
`

type Loadable<T> =
  | {
      isLoading: false
      value: T
    }
  | { isLoading: true }

function inputValueToCryptoAmount(
  inputValue: string,
  decimals: number
): string {
  const decimalPlaces = Math.min(decimals, 12)
  const cryptoAmountString = trimDecimalPlaces(inputValue, decimalPlaces)
  return parseUnits(cryptoAmountString, decimals).toString()
}

type StakingWidgetProps = {
  lrtToken: Token
  eigenToken: Token
  defaultWithdrawAsset: ExitAsset
  defaultDepositAsset: Token
  nativeCurrency: Token
  exitAssets: ExitAssets
  restakeAssets: Token[]

  minUnstakeAmount: BigNumber
  maxUnstakeAmount: BigNumber
  withdrawalDelayDurationDays: number

  stakingEnabled: Loadable<boolean>
  ethBalance: BigNumber | undefined
  lrtBalance: BigNumber | undefined
  tokenBalances: Token[] | undefined
  exitClaimMap: ExitClaimMap | undefined
  exitAllowanceMap: ExitAllowanceMap | undefined

  zap: ZapCall
  createWithdrawRequest: CreateWithdrawRequestRswETH
  finalizeWithdrawal: FinalizeWithdrawalRswETH
  approveLrtForWithdrawal: ApproveRswETHForWithdrawal

  eigenStakedropResult: EigenLayerStakedropResult | undefined
  eigenStakedropContractsState: MerkleContractsState | undefined
  claimEigenStakedrop: ClaimEigenStakedrop
}

function RestakingWidget({
  withdrawalDelayDurationDays,
  stakingEnabled,
  zap,
  ethBalance,
  tokenBalances,
  lrtBalance,
  maxUnstakeAmount,
  minUnstakeAmount,
  lrtToken,
  defaultWithdrawAsset,
  defaultDepositAsset,
  nativeCurrency,
  eigenToken,
  exitAllowanceMap,
  exitClaimMap,
  approveLrtForWithdrawal,
  createWithdrawRequest,
  finalizeWithdrawal,
  exitAssets,
  restakeAssets,
  claimEigenStakedrop,
  eigenStakedropContractsState,
  eigenStakedropResult,
}: StakingWidgetProps) {
  const [touched, setTouched] = useState<boolean>(false)
  const [srcTokenInputValue, setSrcTokenInputValue] = useState('')
  const [destTokenInputValue, setDestTokenInputValue] = useState('')
  const [srcToken, setSrcToken] = useState<Token>(defaultDepositAsset)
  const [destToken, setDestToken] = useState<Token>(defaultWithdrawAsset)
  const [action, setAction] = useState(ACTIONS.RESTAKE)
  const [tokenSelectOpen, setTokenSelectOpen] = useState<boolean>(false)
  const [slippagePercent, setSlippagePercent] = useState<number>(
    DEFAULT_SLIPPAGE_PERCENT
  )

  const isRestake = () => {
    if (action !== ACTIONS.RESTAKE) {
      return false
    }
    for (const asset of restakeAssets) {
      if (srcToken.address === asset.address) {
        return true
      }
    }
    return false
  }
  const isZap = () => {
    if (action !== ACTIONS.RESTAKE) {
      return false
    }
    for (const asset of restakeAssets) {
      if (srcToken.address === asset.address) {
        return false
      }
    }

    return true
  }
  const isUnstake = () => action === ACTIONS.UNSTAKE
  const isClaim = () => action === ACTIONS.CLAIM
  const isVault = () => action === ACTIONS.VAULT
  const isClaimEigen = () => action === ACTIONS.CLAIM_EIGEN

  const switchToRestake = useCallback(() => {
    setTouched(false)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setSrcToken(defaultDepositAsset)
    setDestToken(lrtToken)
    setAction(ACTIONS.RESTAKE)
  }, [defaultDepositAsset, lrtToken])
  const switchToUnstake = () => {
    setTouched(false)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setSrcToken(lrtToken)
    setDestToken(defaultWithdrawAsset)
    setAction(ACTIONS.UNSTAKE)
  }
  const switchToClaim = () => {
    setTouched(false)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setSrcToken(defaultDepositAsset)
    setDestToken(lrtToken)
    setAction(ACTIONS.CLAIM)
  }
  const switchToClaimEigen = () => {
    setTouched(false)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setSrcToken(defaultDepositAsset)
    setDestToken(lrtToken)
    setAction(ACTIONS.CLAIM_EIGEN)
  }
  const selectRestakeToken = (token: Token) => {
    setTouched(false)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setSrcToken(token)
    setDestToken(lrtToken)
    setAction(ACTIONS.RESTAKE)
  }

  // UI state for claim selection
  const claimSelect = useClaimSelect({
    exitClaimMap,
    finalizeWithdrawal,
  })

  let fromAmount: BigNumber | undefined
  if (srcTokenInputValue) {
    fromAmount = parseUnits(
      trimDecimalPlaces(srcTokenInputValue, srcToken.decimals),
      srcToken.decimals
    )
  }
  let toAmount: BigNumber | undefined
  if (destTokenInputValue) {
    toAmount = parseUnits(
      trimDecimalPlaces(destTokenInputValue, destToken.decimals),
      destToken.decimals
    )
  }

  let srcTokenBalance: BigNumber | undefined
  if (srcToken.symbol === lrtToken.symbol) {
    srcTokenBalance = lrtBalance
  } else if (srcToken.symbol === nativeCurrency.symbol) {
    srcTokenBalance = ethBalance
  } else if (tokenBalances) {
    const token = tokenBalances.find((t) => t.address === srcToken.address)
    if (token) {
      srcTokenBalance = token.balance
    }
  }

  let routesPaused = !srcTokenInputValue && !destTokenInputValue
  if (!isZap()) {
    routesPaused = true
  }

  // Zap route fetching logic (inline)
  const routeParams = useZapRouteParams({
    fromAmount,
    fromToken: srcToken,
    maxPriceImpact: MAX_PRICE_IMPACT_RATIO,
    slippage: slippagePercent / 100,
    toToken: destToken,
  })
  const routeQuery = useZapRoutes(routeParams, { paused: routesPaused })
  const zapRoute = routeQuery.data
  const routeRefetching = routeQuery.isValidating
  const zapBuilding = useZapBuilding()

  // TODO: zap API should provide allowance data / approve functionality
  const zapContractAddr = useZapContractAddress()
  const swapSrcTokenContract = useIERC20Contract(srcToken?.address)
  const allowance = useAllowance(swapSrcTokenContract, zapContractAddr)
  const approve = useApprove(swapSrcTokenContract, zapContractAddr)
  const preparedZap = prepareZap({
    allowance: allowance.amount,
    fromTokenBalance: srcTokenBalance,
    route: zapRoute,
    slippage: slippagePercent / 100,
  })

  // Get user, config data, helpers
  const { account, chainId } = useSwellWeb3()
  const { chainId: deploymentChainId } = useDeploymentSetConfig()
  const { explorer } = useChainInfo()
  const { notify, removeNotification } = useGlobalNotification()

  // Try prepare arguments for web3 calls
  const preparedCreateWithdrawRequest = prepareCreateWithdrawRequestRswETH({
    maxUnstakeAmount,
    minUnstakeAmount,
    rswETHAllowance: exitAllowanceMap?.[defaultWithdrawAsset.exitAddress],
    rswETHAmount: fromAmount,
    rswETHBalance: lrtBalance,
    withdrawAsset: defaultWithdrawAsset,
    chainId,
  })
  const preparedFinalizeWithdrawal = prepareFinalizeWithdrawalRswETH({
    selectedClaim: claimSelect.selectedClaim,
    chainId,
  })
  const preparedApproveLrtForWithdrawal = prepareApproveRswETHForWithdrawal({
    amount: fromAmount,
    rswETHBalance: lrtBalance,
    withdrawAsset: defaultWithdrawAsset,
    chainId,
  })

  // Get referrer address if exists
  const [searchParams] = useSearchParams()
  const referrerAddress = searchParams.get('ref') || undefined

  // Action chooser state
  const [toastAction, setToastAction] = useState<string>(ACTIONS.ZAP)

  // Form State
  const zapTokenSelectDisabled = zap.status !== zap.STATUS.IDLE

  // Instantiate formatters and parsers
  const formatRswEth = useFormatRswEth()
  const parseRswEth = useParseRswEth()
  const formatNativeCurrency = useFormatNativeCurrency()
  const parseNativeCurrency = useParseNativeCurrency()

  // Swell staking function
  const restakeNativeCurrency = useRestakeNativeCurrency()
  const { clearRestaking } = useRestakingActions()

  // Get ETH/swETH rates for staking
  const ethUsdMarketRate = useEthUsdMarketRate()
  const rswEthUsdMarketRate = useRswEthUsdMarketRate()
  const restakingRate = useRestakingRate()

  // State / data for staking
  const { nativeCurrencyDisplayStr, rswEthAmountDisplayStr } =
    useRestakingDisplayStrings(restakingRate.data?.rswETHToETHRate)

  const { status: stakingSubmissionStatus, txHash, error } = useRestakeLens()

  /**
   * Responsible for watching the status of the submission and displaying
   * a progress bar upon a pending stake,
   * a banner to the user upon successful staking,
   * or encountering an error while staking.
   */
  useEffect(() => {
    if (stakingSubmissionStatus === StakingSubmissionStatus.FULFILLED) {
      setTouched(false)
      setSrcTokenInputValue('')
      setDestTokenInputValue('')

      return
    }

    if (stakingSubmissionStatus === StakingSubmissionStatus.ERROR && error) {
      const nId = notify(error, GLOBAL_NOTIFICATION_TYPES.ERROR)

      return () => {
        removeNotification(nId)
      }
    }
  }, [
    error,
    explorer,
    nativeCurrencyDisplayStr,
    notify,
    removeNotification,
    setSrcTokenInputValue,
    setDestTokenInputValue,
    setTouched,
    stakingSubmissionStatus,
    txHash,
  ])

  // switch to restake tab if no claims
  useEffect(() => {
    if (action === ACTIONS.CLAIM && !claimSelect.flattenedClaims?.length) {
      switchToRestake()
    }
  }, [claimSelect.flattenedClaims, action, switchToRestake])

  // update destToken input value when route changes
  useZapInputBehaviour({
    zapRoute,
    srcToken,
    setDestTokenInputValue,
    rswethDecimals: destToken.decimals,
    paused: !isZap(),
  })

  const srcTokenIsEth = srcToken && srcToken.symbol === TOKEN_LIST_ETH.symbol

  const mustApproveUnstake = () => {
    if (!isUnstake()) return false
    return (
      preparedCreateWithdrawRequest.error === ExitErrors.InsufficientAllowance
    )
  }

  useEffect(() => {
    if (srcTokenInputValue) setTouched(true)
  }, [srcTokenInputValue])

  const ethValueErrorMsg = (): string | null => {
    if (isRestake()) {
      if (!ethBalance || !touched) return null

      if (srcTokenInputValue === '') return 'Must enter a value'

      const ethAmountBN = parseNativeCurrency(srcTokenInputValue)
      if (ethAmountBN.eq(0)) return 'Value cannot be 0'
      if (ethAmountBN.gt(ethBalance)) return 'Insufficient balance'
    } else if (isZap()) {
      if (!tokenBalances || !srcTokenBalance || !touched) return null

      if (srcTokenInputValue === '') return 'Must enter a value'

      const tokenAmount = inputValueToCryptoAmount(
        srcTokenInputValue,
        srcToken.decimals
      )
      const tokenAmountBN = BigNumber.from(tokenAmount)
      if (tokenAmountBN.eq(0)) return 'Value cannot be 0'
      if (tokenAmountBN.gt(srcTokenBalance)) return 'Insufficient balance'
    } else if (isUnstake()) {
      if (!touched) return null
      if (mustApproveUnstake()) {
        return preparedApproveLrtForWithdrawal.error ?? null
      }
      let err = preparedCreateWithdrawRequest.error ?? null
      if (err === ExitErrors.UnstakeAmountTooLow) {
        err = `Cannot unstake less than ${formatEther(minUnstakeAmount)} rswETH`
      }
      if (err === ExitErrors.UnstakeAmountTooHigh) {
        err = `Cannot unstake more than ${formatEther(maxUnstakeAmount)} rswETH`
      }
      return err
    }

    return null
  }

  const preventInteraction = (): boolean => {
    if (restakingRate.isLoading || stakingEnabled.isLoading) {
      return true
    }
    if (account) {
      if (ethBalance === undefined) return true
      if (isZap() && tokenBalances === undefined) return true
    }
    if (!stakingEnabled.value) return true
    if (chainId !== deploymentChainId) {
      return true
    }
    return false
  }
  const ethInputDisabled = () => {
    return preventInteraction() || (isZap() && zap.status !== zap.STATUS.IDLE)
  }

  const restakeDisabled = (): boolean => {
    if (preventInteraction()) return true
    if (ethValueErrorMsg()) return true
    if (srcTokenInputValue === '') return true

    return false
  }

  const showApprove = (): boolean => {
    const compareValue = srcTokenInputValue
      ? BigNumber.from(
          inputValueToCryptoAmount(srcTokenInputValue, srcToken.decimals)
        )
      : -1
    const hasEnoughAllowance = allowance.amount.gte(compareValue)

    return !srcTokenIsEth && !hasEnoughAllowance
  }

  const approveDisabled = () => {
    return (
      allowance.isLoading ||
      approve.isLoading ||
      !srcToken ||
      !srcTokenInputValue ||
      !destTokenInputValue ||
      !!ethValueErrorMsg()
    )
  }

  const zapDisabled = () => {
    if (ethValueErrorMsg()) return true
    if (zapRouteLoading()) return true
    if (preparedZap.args === undefined) return true
    if (zap.status !== zap.STATUS.IDLE) return true
    return false
  }

  const zapRouteLoading = () => {
    return (
      isZap() &&
      srcTokenInputValue !== '' &&
      (zapRoute === undefined || routeRefetching)
    )
  }

  /* Interaction handlers */

  const handleActionClick = (action: string) => {
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    if (action === ACTIONS.RESTAKE) {
      switchToRestake()
    }
    if (action === ACTIONS.CLAIM) {
      switchToClaim()
    }
    if (action === ACTIONS.CLAIM_EIGEN) {
      switchToClaimEigen()
    }
  }

  const handleEthInputChange = (event: any) => {
    if (!restakingRate.data) return

    const value = event.target.value
    if (value === '') {
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
      return
    }

    let decimalPlaces = 18
    if (isZap()) {
      decimalPlaces = Math.min(srcToken.decimals, 12)
    }

    const valueClean = trimDecimalPlaces(value, decimalPlaces)
    setSrcTokenInputValue(valueClean)

    if (isRestake()) {
      const { rswETHToETHRate } = restakingRate.data!
      const nativeAmount = parseNativeCurrency(valueClean)
      const rswEthAmount = getRswethReceivedForNativeCurrency({
        rswethToEthRate: rswETHToETHRate,
        toSendNativeCurrency: nativeAmount,
      })
      setDestTokenInputValue(formatRswEth(rswEthAmount))
    }
    if (isUnstake()) {
      const { rswETHToETHRate } = restakingRate.data!
      const rswETHAmount = parseUnits(valueClean, decimalPlaces)
      const nativeAmount = getNativeCurrencyToExchangeForRsweth({
        toReceiveRsweth: rswETHAmount,
        rswethToEthRate: rswETHToETHRate,
      })
      const ethAmount = formatNativeCurrency(nativeAmount)
      setDestTokenInputValue(ethAmount)
    }
  }

  const handleMaxClick = () => {
    if (isRestake()) {
      if (!ethBalance) return
      handleEthInputChange({
        target: { value: formatNativeCurrency(ethBalance) },
      })
    } else if (isZap()) {
      if (!srcTokenBalance) return
      handleEthInputChange({
        target: { value: formatUnits(srcTokenBalance, srcToken.decimals) },
      })
    } else if (isUnstake()) {
      if (!lrtBalance) return
      handleEthInputChange({
        target: { value: formatRswEth(lrtBalance) },
      })
      return
    }
  }

  const handleSwEthInputChange = (event: any) => {
    if (!restakingRate.data) throw new Error(`No staking data`)

    const value = event.target.value
    if (value === '') {
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
      return
    }

    const valueClean = trimDecimalPlaces(value, 18)
    setDestTokenInputValue(valueClean)

    if (isRestake()) {
      const { rswETHToETHRate } = restakingRate.data!
      const rswEthAmount = parseRswEth(valueClean)
      const nativeAmount = getNativeCurrencyToExchangeForRsweth({
        rswethToEthRate: rswETHToETHRate,
        toReceiveRsweth: rswEthAmount,
      })
      setSrcTokenInputValue(formatNativeCurrency(nativeAmount))
    }

    if (isUnstake()) {
      const { rswETHToETHRate } = restakingRate.data!
      const receiveAssetAmount = parseUnits(valueClean, destToken.decimals)
      // ETH withdrawal only
      const nativeAmount = receiveAssetAmount
      const rswEthAmount = getRswethReceivedForNativeCurrency({
        rswethToEthRate: rswETHToETHRate,
        toSendNativeCurrency: nativeAmount,
      })
      setSrcTokenInputValue(formatRswEth(rswEthAmount))
    }
  }

  const handleStakeClick = () => {
    const ethAmountBN = parseNativeCurrency(srcTokenInputValue)
    restakeNativeCurrency(ethAmountBN, referrerAddress)
  }

  const handleApproveClick = async () => {
    setToastAction(ACTIONS.ZAP)
    const approved = await approve.sendTransaction(srcTokenInputValue, srcToken)
    if (approved) {
      allowance.set(approved)
    }
  }

  const handleZapClick = async () => {
    if (!preparedZap.args) return
    setToastAction(ACTIONS.ZAP)
    await zap.call(...preparedZap.args)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setTouched(false)
  }

  /* Transaction toast logic */
  // TODO: transaction toast needs to get fully revamped at some point
  useEffect(() => {
    if (
      stakingSubmissionStatus === StakingSubmissionStatus.FULFILLED ||
      approve.status === approve.STATUS.FULFILLED ||
      zap.status === zap.STATUS.FULFILLED ||
      createWithdrawRequest.status === createWithdrawRequest.STATUS.FULFILLED ||
      finalizeWithdrawal.status === finalizeWithdrawal.STATUS.FULFILLED ||
      approveLrtForWithdrawal.status ===
        approveLrtForWithdrawal.STATUS.FULFILLED
    ) {
      setTimeout(() => {
        clearRestaking()
        approve.clear()
        zap.clear()
        createWithdrawRequest.clear()
        finalizeWithdrawal.clear()
        approveLrtForWithdrawal.clear()
      }, 5000)
    }
  }, [
    approve,
    clearRestaking,
    stakingSubmissionStatus,
    zap,
    createWithdrawRequest,
    finalizeWithdrawal,
    approveLrtForWithdrawal,
  ])

  const toastOpen = (): boolean => {
    if (isZap()) {
      return (
        approve.status !== approve.STATUS.IDLE || zap.status !== zap.STATUS.IDLE
      )
    }

    if (stakingSubmissionStatus === StakingSubmissionStatus.ERROR) return false

    return stakingSubmissionStatus !== StakingSubmissionStatus.IDLE
  }

  const toastTitle = (): string => {
    if (approve.status === approve.STATUS.PROMPTING) {
      return TRANSACTION_TOAST_TITLE.APPROVE_PROMPTING
    } else if (approve.status === approve.STATUS.PENDING) {
      return TRANSACTION_TOAST_TITLE.APPROVE_PENDING
    } else if (zapBuilding) {
      return TRANSACTION_TOAST_TITLE.ZAP_BUILDING
    } else if (zap.status === zap.STATUS.PROMPTING) {
      return TRANSACTION_TOAST_TITLE.ZAP_PROMPTING
    } else if (zap.status === zap.STATUS.PENDING) {
      return TRANSACTION_TOAST_TITLE.ZAP_PENDING
    } else if (stakingSubmissionStatus === StakingSubmissionStatus.PROMPTING) {
      return TRANSACTION_TOAST_TITLE.RESTAKE_PROMPTING
    } else if (stakingSubmissionStatus === StakingSubmissionStatus.PENDING) {
      return TRANSACTION_TOAST_TITLE.RESTAKE_PENDING
    }
    return TRANSACTION_TOAST_TITLE.COMPLETED
  }

  const toastMessage = (): string => {
    if (approve.isLoading) {
      const formattedApproveAmount = displayCrypto(
        approve.amount,
        approve.token.decimals,
        {
          precision: 4,
          localize: true,
        }
      )
      return `Approve ${formattedApproveAmount} ${approve.token.symbol}`
    } else if (toastAction === ACTIONS.ZAP) {
      if (approve.status !== approve.STATUS.IDLE) {
        const formattedApproveAmount = displayCrypto(
          approve.amount,
          approve.token.decimals,
          {
            precision: 4,
            localize: true,
          }
        )

        return `Approve ${formattedApproveAmount} ${approve.token.symbol}`
      } else if (zap.status !== zap.STATUS.IDLE) {
        if (!zapRoute || !fromAmount || !toAmount) {
          return `Zap for rswETH`
        }

        const formattedFromAmount = displayCrypto(
          fromAmount,
          zapRoute.fromToken.decimals,
          {
            precision: 4,
            localize: true,
          }
        )

        const formattedToAmount = displayCrypto(
          toAmount,
          zapRoute.toToken.decimals,
          {
            precision: 4,
            localize: true,
          }
        )

        return `Zap ${formattedFromAmount} ${srcToken.symbol} for ${formattedToAmount} rswETH`
      }
    }
    return `Restake ${nativeCurrencyDisplayStr} for ${rswEthAmountDisplayStr}`
  }

  const toastTxHash = () => {
    if (toastAction === ACTIONS.ZAP && zap.txHash) {
      return zap.txHash
    }
    return txHash
  }

  const transactionConfirming = (): boolean => {
    return (
      stakingSubmissionStatus === StakingSubmissionStatus.PROMPTING ||
      approve.status === approve.STATUS.PROMPTING ||
      zapBuilding ||
      zap.status === zap.STATUS.PROMPTING
    )
  }

  const transactionPending = (): boolean => {
    return (
      stakingSubmissionStatus === StakingSubmissionStatus.PENDING ||
      approve.status === approve.STATUS.PENDING ||
      zap.status === zap.STATUS.PENDING
    )
  }

  const transactionComplete = (): boolean => {
    return (
      stakingSubmissionStatus === StakingSubmissionStatus.FULFILLED ||
      approve.status === approve.STATUS.FULFILLED ||
      zap.status === zap.STATUS.FULFILLED
    )
  }

  const onToastClose = () => {
    clearRestaking()
    approve.clear()
    zap.clear()
  }

  let verb: string
  if (isRestake()) {
    verb = 'Restake'
  } else if (isUnstake()) {
    verb = 'Unstake'
  } else {
    verb = 'Zap'
  }

  let topUsdValue: string | null = null
  if (isRestake()) {
    topUsdValue = ethDepositValueUsd({
      ethUsdMarketRate: ethUsdMarketRate.data?.rate,
      nativeCurrencyAmount: fromAmount,
      nativeCurrencyDecimals: 18,
    })
  }
  if (isZap()) {
    topUsdValue = zapDepositValueUsd({ route: zapRoute })
  }
  if (isUnstake()) {
    topUsdValue = rswEthWithdrawalValueUsd({
      rswETHAmount: fromAmount,
      rswETHDecimals: 18,
      rswEthUsdMarketRate: rswEthUsdMarketRate.data?.rate,
    })
  }

  let bottomUsdValue: string | null = null
  if (isRestake()) {
    bottomUsdValue = rswETHReceivedValueUsd({
      rswETHAmount: toAmount,
      rswETHDecimals: 18,
      rswEthUsdMarketRate: rswEthUsdMarketRate.data?.rate,
    })
  }
  if (isZap()) {
    bottomUsdValue = zapReceivedValueUsd({ route: zapRoute })
  }
  if (isUnstake()) {
    bottomUsdValue = ethWithdrawalValueUsd({
      ethUsdMarketRate: ethUsdMarketRate.data?.rate,
      nativeCurrencyAmount: toAmount,
      nativeCurrencyDecimals: 18,
    })
  }

  const handleUnstakeButtonClick = () => {
    // handle view change
    setSrcTokenInputValue('')
    setDestTokenInputValue('')

    if (isUnstake()) {
      switchToRestake()
    } else {
      switchToUnstake()
    }
  }

  const anyTransactionInProgress =
    stakingSubmissionStatus === StakingSubmissionStatus.PENDING ||
    stakingSubmissionStatus === StakingSubmissionStatus.PROMPTING ||
    [
      approve,
      zap,
      approveLrtForWithdrawal,
      createWithdrawRequest,
      finalizeWithdrawal,
    ].some(
      (x) => x.status === x.STATUS.PENDING || x.status === x.STATUS.PROMPTING
    )

  const actions = [ACTIONS.RESTAKE, ACTIONS.CLAIM_EIGEN]
  if (claimSelect.flattenedClaims?.length) {
    actions.push(ACTIONS.CLAIM)
  }

  return (
    <StakingWidgetBox>
      <FloatingEigenLogo src={eigenUrl} width={62} height={26} />
      <ActionChooser
        actions={actions}
        onActionClick={handleActionClick}
        defaultAction={ACTIONS.RESTAKE}
      />
      {action === ACTIONS.CLAIM_EIGEN && (
        <FlexRow>
          <ClaimEigenWidget
            eigenStakedropResult={eigenStakedropResult}
            claimEigenStakedrop={claimEigenStakedrop}
            merkleState={eigenStakedropContractsState?.merkleDrop}
            eigenToken={eigenToken}
          />
        </FlexRow>
      )}
      {(action === ACTIONS.RESTAKE ||
        action === ACTIONS.UNSTAKE ||
        action === ACTIONS.ZAP) && (
        <>
          <AvailableChip token={srcToken} tokenBalances={tokenBalances} />
          <UtilitiesContainer>
            <CryptoUtilities />
          </UtilitiesContainer>
          <div>
            {/* Can i make this more reusable somehow? TokenInputLabel */}
            <FlexRow justify="space-between" align="center">
              <Typography variant="body" size="xlarge" fstyle="bold">
                {verb}
              </Typography>
              {(isZap() || isRestake()) && (
                <TokenSelectWidget
                  isOpen={tokenSelectOpen}
                  onClose={() => setTokenSelectOpen(false)}
                  onTokenSelect={selectRestakeToken}
                  tokenBalances={tokenBalances}
                  defaultToken={srcToken}
                  disabled={zapTokenSelectDisabled}
                />
              )}
              {isUnstake() && (
                <SymbolWrapper>
                  <StyledRswethIcon />
                  <Typography variant="body" size="large" fstyle="bold">
                    rswETH
                  </Typography>
                </SymbolWrapper>
              )}
            </FlexRow>
            <EthInputWrapper>
              <StakingWidgetEthInput
                variant="standard"
                value={srcTokenInputValue}
                onChange={handleEthInputChange}
                error={!!ethValueErrorMsg()}
                helperText={ethValueErrorMsg()}
                disabled={ethInputDisabled()}
                onMaxClick={handleMaxClick}
              />
            </EthInputWrapper>
            {/* TODO: price should be displayed as 'xx USD' */}
            {!ethValueErrorMsg() && topUsdValue && (
              <UsdTypography variant="body" size="xsmall">
                {topUsdValue}
              </UsdTypography>
            )}
            {!ethValueErrorMsg() && !topUsdValue && (
              <div style={{ height: '37.2px' }} /> /* prevent layout shift */
            )}
            <FlexRow justify="center">
              <UnstakeButton
                onClick={handleUnstakeButtonClick}
                // TODO: real button
                aria-disabled={preventInteraction()}
              >
                <ArrowDownIcon />
              </UnstakeButton>
            </FlexRow>
            <FlexRow justify="space-between" align="center">
              <Typography variant="body" size="xlarge" fstyle="bold">
                Receive
              </Typography>
              {!isUnstake() && (
                <SymbolWrapper>
                  <StyledRswethIcon />
                  <Typography
                    variant="headline"
                    size="h5"
                    fstyle="bold"
                    letterSpacing="small"
                  >
                    rswETH
                  </Typography>
                </SymbolWrapper>
              )}
              {isUnstake() && (
                <SymbolWrapper>
                  <StyledEthIcon />
                  <Typography
                    variant="headline"
                    size="h5"
                    fstyle="bold"
                    letterSpacing="small"
                  >
                    ETH
                  </Typography>
                </SymbolWrapper>
              )}
            </FlexRow>
            <EthInputWrapper>
              {(action !== ACTIONS.ZAP || !zapRouteLoading()) && (
                <SwEthInput
                  variant="standard"
                  value={destTokenInputValue}
                  onChange={handleSwEthInputChange}
                  disabled={preventInteraction() || isZap()}
                />
              )}
              {zapRouteLoading() && (
                <>
                  <SwEthInput variant="standard" disabled={true} />
                  <InputCircularProgress size={35} />
                </>
              )}
            </EthInputWrapper>
            {bottomUsdValue && (
              <UsdTypography variant="body" size="xsmall">
                {bottomUsdValue}
              </UsdTypography>
            )}
          </div>
          <Divider />
          {(isRestake() || isUnstake()) && (
            <RswethExchangeInfo
              withdrawalDelayDurationDays={withdrawalDelayDurationDays}
              lstEthExchangeRate={parseEther('1')} // LST not supported yet
              lstSymbol={'ETH'}
              primaryRestakingRate={restakingRate.data?.rswETHToETHRate}
              unstake={isUnstake()}
            />
          )}
          {isZap() && (
            <SwapInfo
              isLoading={!zapRoute || routeRefetching}
              srcTokenSymbol={srcToken?.symbol || ''}
              destTokenSymbol={destToken?.symbol || ''}
              bestRouteInfo={zapRoute}
              setSlippagePercent={setSlippagePercent}
              slippagePercent={slippagePercent}
              defaultSlippagePercent={DEFAULT_SLIPPAGE_PERCENT}
            />
          )}
          <div>
            {account && isRestake() && (
              <StakeWidgetButton
                disabled={restakeDisabled()}
                onClick={handleStakeClick}
              >
                <ButtonInner>
                  {(stakingSubmissionStatus ===
                    StakingSubmissionStatus.PROMPTING ||
                    stakingSubmissionStatus ===
                      StakingSubmissionStatus.PENDING) && (
                    <ButtonProgressPlacer>
                      <ButtonCircularProgress size={24} />
                    </ButtonProgressPlacer>
                  )}
                  {stakingSubmissionStatus ===
                    StakingSubmissionStatus.PROMPTING && 'Pending...'}
                  {stakingSubmissionStatus ===
                    StakingSubmissionStatus.PENDING && 'Confirming...'}
                  {stakingSubmissionStatus !==
                    StakingSubmissionStatus.PROMPTING &&
                    stakingSubmissionStatus !==
                      StakingSubmissionStatus.PENDING &&
                    'Restake'}
                </ButtonInner>
              </StakeWidgetButton>
            )}
            {account && isZap() && (
              <>
                {!srcToken && (
                  <StakeWidgetButton onClick={() => setTokenSelectOpen(true)}>
                    Select Token
                  </StakeWidgetButton>
                )}
                {srcToken && showApprove() && (
                  <StakeWidgetButton
                    disabled={approveDisabled()}
                    onClick={handleApproveClick}
                  >
                    <ButtonInner>
                      {(approve.status === approve.STATUS.PROMPTING ||
                        approve.status === approve.STATUS.PENDING) && (
                        <ButtonProgressPlacer>
                          <ButtonCircularProgress size={24} />
                        </ButtonProgressPlacer>
                      )}
                      {approve.status === approve.STATUS.PROMPTING &&
                        'Pending...'}
                      {approve.status === approve.STATUS.PENDING &&
                        'Confirming...'}
                      {approve.status !== approve.STATUS.PROMPTING &&
                        approve.status !== approve.STATUS.PENDING &&
                        'Approve'}
                    </ButtonInner>
                  </StakeWidgetButton>
                )}
                {srcToken && !showApprove() && (
                  <StakeWidgetButton
                    disabled={zapDisabled()}
                    onClick={handleZapClick}
                  >
                    {zapRouteLoading() && <ButtonCircularProgress size={35} />}
                    {!zapRouteLoading() && (
                      <ButtonInner>
                        {(zapBuilding ||
                          zap.status === zap.STATUS.PROMPTING ||
                          zap.status === zap.STATUS.PENDING) && (
                          <ButtonProgressPlacer>
                            <ButtonCircularProgress size={24} />
                          </ButtonProgressPlacer>
                        )}
                        {zapBuilding && 'Building...'}
                        {!zapBuilding &&
                          zap.status === zap.STATUS.PROMPTING &&
                          'Pending...'}
                        {zap.status === zap.STATUS.PENDING && 'Confirming...'}
                        {!zapBuilding &&
                          zap.status !== zap.STATUS.PROMPTING &&
                          zap.status !== zap.STATUS.PENDING &&
                          'Zap'}
                      </ButtonInner>
                    )}
                  </StakeWidgetButton>
                )}
              </>
            )}
            {account && isUnstake() && mustApproveUnstake() && (
              <ApproveRswETHForWithdrawalButton
                prepared={preparedApproveLrtForWithdrawal}
                approveRswETHForWithdrawal={approveLrtForWithdrawal}
                disabled={preventInteraction()}
              />
            )}
            {account && isUnstake() && !mustApproveUnstake() && (
              <CreateWithdrawRequestRswETHButton
                prepared={preparedCreateWithdrawRequest}
                createWithdrawRequest={createWithdrawRequest}
                disabled={preventInteraction()}
                withdrawalDelayDurationDays={withdrawalDelayDurationDays}
              />
            )}
            {!account && (
              <StakeConnectButton>Connect wallet</StakeConnectButton>
            )}
          </div>
          <StakingConfirmationProgressWidget
            complete={transactionComplete()}
            confirming={transactionConfirming()}
            message={toastMessage()}
            onClose={onToastClose}
            pending={transactionPending()}
            txHash={toastTxHash()}
            open={toastOpen()}
            title={toastTitle()}
          />
          <div>
            <SwellStatistics restake={true} />
          </div>
        </>
      )}
      {isClaim() && (
        <>
          <RestakingClaimView
            claimSelect={claimSelect}
            exitAssets={exitAssets}
          />
          <FinalizeWithdrawalRswETHButton
            finalizeWithdrawal={finalizeWithdrawal}
            prepared={preparedFinalizeWithdrawal}
            disabled={preventInteraction()}
          />
        </>
      )}
      {isVault() && <VaultView />}

      <ApproveRswETHForWithdrawalToast
        anyTransactionInProgress={anyTransactionInProgress}
        approveRswETHForWithdrawal={approveLrtForWithdrawal}
        rswETHToken={lrtToken}
      />
      <CreateWithdrawRequestRswETHToast
        anyTransactionInProgress={anyTransactionInProgress}
        createWithdrawRequestRswETH={createWithdrawRequest}
        primaryRestakingRate={restakingRate.data?.rswETHToETHRate}
        rswETHToken={lrtToken}
        exitAssets={[EXIT_ASSET_RSWETH_ETH]}
      />
      <FinalizeWithdrawalRswETHToast
        anyTransactionInProgress={anyTransactionInProgress}
        finalizeWithdrawalRswETH={finalizeWithdrawal}
        exitAsset={EXIT_ASSET_RSWETH_ETH}
        exitClaimMap={exitClaimMap}
      />
    </StakingWidgetBox>
  )
}

export { RestakingWidget }

// TODO: manage different styles with theme
const UnstakeButton = styled.div<any>`
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: 6px;
  /* border: 2px solid rgba(255, 255, 255, 0.2); */
  /* background: ${({ theme }) => theme.colors.oceanBlue['100']}; */
  color: ${({ theme }) => theme.mainColor};
  border: 2px solid ${({ theme }) => theme.mainColor};

  svg {
    transition: rotate 300ms;
    path:first-child {
      fill: ${({ theme }) => theme.mainColor};
    }
    path:last-child {
      stroke: ${({ theme }) => theme.mainColor};
    }
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;

    svg {
      rotate: 180deg;
    }
  }

  &:active {
    opacity: 0.5;
  }

  &[aria-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }
`
