import { ZapRoute } from '@/state/zap/types'
import { displayFiat } from '@/util/displayFiat'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'

export function zapDepositValueUsd({ route }: { route: ZapRoute | undefined }) {
  if (!route) return null
  return displayFiat(Number(route.fromAmountUSD))
}

export function zapReceivedValueUsd({
  route,
}: {
  route: ZapRoute | undefined
}) {
  if (!route) return null
  return displayFiat(Number(route.toAmountUSD))
}

export function ethDepositValueUsd({
  ethUsdMarketRate,
  nativeCurrencyDecimals,
  nativeCurrencyAmount,
}: {
  ethUsdMarketRate: number | undefined
  nativeCurrencyDecimals: number
  nativeCurrencyAmount: BigNumber | undefined
}) {
  if (!ethUsdMarketRate || !nativeCurrencyAmount) return null
  return displayFiat(
    Number(formatUnits(nativeCurrencyAmount, nativeCurrencyDecimals)) *
      ethUsdMarketRate
  )
}

export function rswEthWithdrawalValueUsd({
  rswETHAmount,
  rswETHDecimals,
  rswEthUsdMarketRate,
}: {
  rswEthUsdMarketRate: number | undefined
  rswETHAmount: BigNumber | undefined
  rswETHDecimals: number
}) {
  if (!rswEthUsdMarketRate || !rswETHAmount) return null

  return displayFiat(
    Number(formatUnits(rswETHAmount, rswETHDecimals)) * rswEthUsdMarketRate
  )
}

export function rswETHReceivedValueUsd({
  rswEthUsdMarketRate,
  rswETHAmount,
  rswETHDecimals,
}: {
  rswEthUsdMarketRate: number | undefined
  rswETHAmount: BigNumber | undefined
  rswETHDecimals: number
}) {
  if (!rswEthUsdMarketRate || !rswETHAmount) return null

  return displayFiat(
    Number(formatUnits(rswETHAmount, rswETHDecimals)) * rswEthUsdMarketRate
  )
}

export function ethWithdrawalValueUsd({
  ethUsdMarketRate,
  nativeCurrencyAmount,
  nativeCurrencyDecimals,
}: {
  ethUsdMarketRate: number | undefined
  nativeCurrencyDecimals: number
  nativeCurrencyAmount: BigNumber | undefined
}) {
  if (!ethUsdMarketRate || !nativeCurrencyAmount) return null
  return displayFiat(
    Number(formatUnits(nativeCurrencyAmount, nativeCurrencyDecimals)) *
      ethUsdMarketRate
  )
}
