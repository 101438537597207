// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/rates.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { EthUsdRequest, EthUsdResponse, RepricingHistoryRequest, RepricingHistoryResponse, SwethEthRequest, SwethEthResponse } from "./rates_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.RatesService
 */
export const RatesService = {
  typeName: "swell.v3.RatesService",
  methods: {
    /**
     * @generated from rpc swell.v3.RatesService.EthUsd
     */
    ethUsd: {
      name: "EthUsd",
      I: EthUsdRequest,
      O: EthUsdResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.RatesService.SwethEth
     */
    swethEth: {
      name: "SwethEth",
      I: SwethEthRequest,
      O: SwethEthResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.RatesService.RepricingHistory
     */
    repricingHistory: {
      name: "RepricingHistory",
      I: RepricingHistoryRequest,
      O: RepricingHistoryResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

