import React from 'react'
import { useUserOperator, useUserOperatorId } from '@/state/userOperator/hooks'
import { AccountInfoButton } from './AccountInfoButton'

function OperatorAccountButton() {
  const userOperator = useUserOperator()
  const userOperatorId = useUserOperatorId()

  // No account / not an operator -> show nothing
  if (!userOperator.data || !userOperatorId.data) return null

  const {
    operator: { name },
  } = userOperator.data
  const { operatorId } = userOperatorId.data

  return (
    <AccountInfoButton color="accent">
      #{operatorId.toNumber()} {name}
    </AccountInfoButton>
  )
}

export { OperatorAccountButton }
