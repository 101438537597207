import { useMemo } from 'react'
import styled from 'styled-components'
import { FlexRow } from '@swell-ui/FlexRow'
import { Table, TableConfig } from '@swell-ui/Table'
import { Typography } from '@swell-ui/Typography'
import { PoolTableRow } from '.'

const StyledTable = styled(Table)`
  .positionName-cell {
    min-width: 221px;
  }

  .MuiTable-root {
    border-collapse: initial;
    border-spacing: 0 12px;
  }

  .MuiTableCell-head {
    color: ${({ theme }) => theme.colors.lightBlue['50']};
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    line-height: 160%; /* 22.4px */
    letter-spacing: 2.52px;
    text-transform: uppercase;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.lightBlue['50']};
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableCell-root {
      background-color: ${({ theme }) => theme.colors.oceanBlue['100']};
      border-top: 1px solid #3068ef;
      border-bottom: 1px solid #3068ef;

      &:first-child {
        border-left: 1px solid #3068ef;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      &:last-child {
        border-right: 1px solid #3068ef;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }

    .MuiTableRow-root:hover {
      .MuiTableCell-root {
        background-color: ${({ theme }) => theme.colors.oceanBlue['125']};
      }
    }
  }
`

const ProtocolLogo = styled.img`
  width: 46px;
  height: 46px;
`

const ChainLogo = styled.img`
  width: 16px;
  height: 16px;
`

const TokenIconWrapper = styled.div`
  white-space: nowrap;
`

const TokenIcon = styled.img<{ index: number }>`
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 64px;
  ${({ index }) => index !== 0 && `margin-left: -12px;`}
  z-index: ${({ index }) => 10 - index};
`

function ProtocolCell({ row }: { row: PoolTableRow }) {
  const pool = row

  return (
    <FlexRow gap="10">
      <ProtocolLogo src={pool.logo} />
      <FlexRow direction="column" align="flex-start">
        <Typography
          variant="headline"
          size="h6"
          fstyle="bold"
          letterSpacing="small"
        >
          {pool.protocol}
        </Typography>
        <FlexRow gap="4">
          <Typography variant="body" size="xsmall" letterSpacing="small">
            {pool.chainName}
          </Typography>
          <ChainLogo src={pool.chainLogo} />
        </FlexRow>
      </FlexRow>
    </FlexRow>
  )
}

function PoolNameCell({ row }: { row: PoolTableRow }) {
  const pool = row

  return (
    <FlexRow gap="14">
      <TokenIconWrapper>
        {pool.tokenIconList.map((url: string, index: number) => {
          return <TokenIcon key={index} src={url} index={index} />
        })}
      </TokenIconWrapper>
      <Typography variant="body" size="medium" letterSpacing="small">
        {pool.positionName}
      </Typography>
    </FlexRow>
  )
}

export function PoolTableFull({
  config,
  loading,
}: {
  config: TableConfig
  loading: boolean
}) {
  const finalizedConfig = useMemo<TableConfig>(() => {
    const customizedColumns: TableConfig['columns'] = []

    for (let i = 0; i < config.columns.length; i++) {
      const col = config.columns[i]

      switch (col.key) {
        case 'protocol':
          customizedColumns.push({ ...col, component: ProtocolCell })
          break
        case 'positionName':
          customizedColumns.push({ ...col, component: PoolNameCell })
          break
        default:
          customizedColumns.push(col)
      }
    }

    return {
      ...config,
      columns: customizedColumns,
    }
  }, [config])

  return <StyledTable loading={loading} config={finalizedConfig} />
}
