import { useSwEthToken } from '@/state/deployments/hooks'
import { displayFiat } from '@/util/displayFiat'
import { BigNumber } from 'ethers'
import { useCallback } from 'react'
import {
  useConvertSwEthToFiat,
  useConvertSwEthToNativeCurrency,
} from '../useCurrencyConversions'
import { useDisplayNativeCurrency } from '../useNativeCurrencyDisplay'
import { useToSwEthFloat } from '../useSwEthFormatting'
import { formatWithConfig } from '@/util/number'

export const useDisplaySwEth = () => {
  const toSwEthFloat = useToSwEthFloat()
  const swEthToken = useSwEthToken()

  return useCallback(
    (
      amountBN: BigNumber,
      {
        precision = 4,
        localize = true,
        numberOnly,
      }: {
        precision?: number
        localize?: boolean
        numberOnly?: boolean
      } = {}
    ) => {
      const floatAmount = toSwEthFloat(amountBN)
      const formattedFloat = formatWithConfig(floatAmount, {
        precision,
        localize,
      })

      if (numberOnly) {
        return `${formattedFloat}`
      }

      return `${formattedFloat} ${swEthToken.symbol}`
    },
    [swEthToken.symbol, toSwEthFloat]
  )
}

export const useDisplaySwEthPriceFiat = () => {
  const convertSwEthToFiat = useConvertSwEthToFiat()

  return useCallback(
    (amountBN: BigNumber, toFiatRate: number) => {
      return displayFiat(convertSwEthToFiat(amountBN, toFiatRate))
    },
    [convertSwEthToFiat]
  )
}

export const useDisplaySwEthInNativeCurrency = () => {
  const displayNativeCurrency = useDisplayNativeCurrency()
  const convertSwEthToNativeCurrency = useConvertSwEthToNativeCurrency()

  return useCallback(
    (
      swEthAmountBN: BigNumber,
      swEthToNativeRate: number,
      {
        precision,
        localize,
        numberOnly,
      }: {
        precision?: number
        localize?: boolean
        numberOnly?: boolean
      } = {}
    ) => {
      return displayNativeCurrency(
        convertSwEthToNativeCurrency(swEthAmountBN, swEthToNativeRate),
        { precision, localize, numberOnly }
      )
    },
    [convertSwEthToNativeCurrency, displayNativeCurrency]
  )
}
