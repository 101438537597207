import { useSwellWeb3 } from '@/swell-web3/core'
import { useSwellDaoApi } from './context'
import useSWRImmutable from 'swr/immutable'

export function useSwellDaoBalances() {
  const { account } = useSwellWeb3()
  const api = useSwellDaoApi()
  return useSWRImmutable(account ? ['balances', account] : null, () =>
    api.read.balances()
  )
}
export function useSwellDaoWavedrop() {
  const { account } = useSwellWeb3()
  const api = useSwellDaoApi()
  return useSWRImmutable(account ? ['wavedrop', account] : null, () =>
    api.read.wavedrop()
  )
}
export function useSwellDaoWaveDropStats() {
  const api = useSwellDaoApi()
  return useSWRImmutable(['wavedrop-stats'], () => api.read.waveDropStats())
}
