import {
  useGetRatesEthUsdV3Backend,
  useGetRatesSwEthEthV3Backend,
} from '@/services/V3BackendService/hooks'
import { useMemo } from 'react'
import { useStakingRate } from '../stakingStats/hooks'
import { useRestakingRate } from '../restakingStats/hooks'
import {
  useEthUsdMarketRateChainlink,
  useSwEthEthMarketRateOnChain,
  useRswEthEthMarketRateOnChain,
} from './util/onChainFiat'
import { useSwethEthRedstone } from '@/services/Redstone/hooks'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { formatEther } from 'ethers/lib/utils'
import { bigNumberRateToFloat } from '@/util/big'

/**
 * Queries the v3-backend rates service for ETH/USD rates, and uses on-chain values
 *  from chainlink as a fallback (redundancy).
 */
export function useEthUsdMarketRateRedundant({
  primaryRefreshIntervalMs,
}: {
  primaryRefreshIntervalMs?: number
} = {}) {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  // either backend could provide this service
  const backendQuery = useGetRatesEthUsdV3Backend(v3BackendLrtUrl, {
    refreshInterval: primaryRefreshIntervalMs,
  })

  const fallbackActive = useMemo<boolean>(() => {
    return !!backendQuery.error
  }, [backendQuery.error])

  const fallbackQuery = useEthUsdMarketRateChainlink({ active: fallbackActive })

  const { data, error, isLoading, isValidating } = useMemo(() => {
    if (fallbackActive) {
      return {
        ...fallbackQuery,
        get data() {
          if (!fallbackQuery.data) return undefined
          return { rate: fallbackQuery.data.rate }
        },
      }
    }

    return {
      ...backendQuery,
      get data() {
        if (!backendQuery.data) return undefined
        return { rate: parseFloat(backendQuery.data.rate) }
      },
    }
  }, [backendQuery, fallbackActive, fallbackQuery])

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutateFallback: fallbackQuery.mutate,
    fallbackActive,
  }
}

export const useEthUsdMarketRate = useEthUsdMarketRateRedundant

/**
 * Queries the v3-backend rates service for swETH/ETH rates, and uses on-chain values
 *  from chainlink as a fallback (redundancy).
 *
 * TODO: backend value and on-chain value is stubbed
 */
export function useSwEthEthMarketRateRedundant({
  primaryRefreshIntervalMs,
}: {
  // Refresh interval (ms) of the backend data source
  // (Not applicable when on chain fallback is active)
  primaryRefreshIntervalMs?: number
} = {}) {
  const backendQuery = useGetRatesSwEthEthV3Backend({
    refreshInterval: primaryRefreshIntervalMs,
  })

  const fallbackActive = useMemo<boolean>(() => {
    return !!backendQuery.error
  }, [backendQuery.error])

  const fallbackQuery = useSwEthEthMarketRateOnChain({
    active: fallbackActive,
  })

  const { data, error, isLoading, isValidating } = useMemo(() => {
    if (fallbackActive) {
      return {
        ...fallbackQuery,
        get data() {
          if (!fallbackQuery.data) return undefined
          return { rate: fallbackQuery.data.rate }
        },
      }
    }

    return {
      ...backendQuery,
      get data() {
        if (!backendQuery.data) return undefined
        return { rate: parseFloat(backendQuery.data.rate) }
      },
    }
  }, [backendQuery, fallbackActive, fallbackQuery])

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutateFallback: fallbackQuery.mutate,
    fallbackActive,
  }
}

/**
 * Composes redundant ETH/USD and swETH/ETH rate information to produce
 *  swETH/USD exchange rate information.
 *
 * TODO: construct redundant queries for swETH/USD sources when secondary market
 *  data for this rates is available
 */
export function useSwEthUsdMarketRateRedundant() {
  const ethUsd = useEthUsdMarketRateRedundant()
  const swEthEth = useSwEthEthMarketRateRedundant()

  return {
    get data() {
      if (!ethUsd.data || !swEthEth.data) return undefined
      return { rate: ethUsd.data.rate * swEthEth.data.rate }
    },
    error: ethUsd.error ?? swEthEth.error,
    isLoading: ethUsd.isLoading || swEthEth.isLoading,
    isValidating: ethUsd.isValidating || swEthEth.isValidating,
  }
}

/**
 * Uses primary market data (staking / fair value rate) to calculate an effective
 *  swETH/ETH exchange rate that can be used in absence of secondary
 *  market data
 */
export function useSwEthEthMarketRatePrimary() {
  const { data, ...query } = useStakingRate()

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { swETHToETHRate } = data
      return { rate: bigNumberRateToFloat(swETHToETHRate) }
    },
  }
}

/**
 * Uses primary market data (staking / fair value rate) to calculate an effective
 *  swETH/USD exchange rate that can be used in absence of secondary
 *  market data
 */
export function useSwEthUsdMarketRatePrimary() {
  const swEthEthRatePrimary = useSwEthEthMarketRatePrimary()
  const ethUsdQuery = useEthUsdMarketRate()

  return {
    get isLoading() {
      return swEthEthRatePrimary.isLoading || ethUsdQuery.isLoading
    },
    get error() {
      return swEthEthRatePrimary.error ?? ethUsdQuery.error
    },
    get isValidating() {
      return swEthEthRatePrimary.isValidating || ethUsdQuery.isValidating
    },
    get data() {
      if (!swEthEthRatePrimary.data || !ethUsdQuery.data) return undefined
      const { rate: swEthEth } = swEthEthRatePrimary.data
      const { rate: ethUsd } = ethUsdQuery.data
      return { rate: swEthEth * ethUsd }
    },
  }
}

export const useSwEthEthMarketRate = useSwEthEthMarketRatePrimary
export const useSwEthUsdMarketRate = useSwEthUsdMarketRatePrimary

export const useStakingPegMetrics = () => {
  const primaryRateSwethEth = useSwEthEthMarketRatePrimary()
  const secondaryRateSwethEth = useSwethEthRedstone()

  return {
    get isLoading() {
      return primaryRateSwethEth.isLoading || secondaryRateSwethEth.isLoading
    },
    get error() {
      return primaryRateSwethEth.error ?? secondaryRateSwethEth.error
    },
    get isValidating() {
      return (
        primaryRateSwethEth.isValidating || secondaryRateSwethEth.isValidating
      )
    },
    get data() {
      if (!primaryRateSwethEth.data || !secondaryRateSwethEth.data)
        return undefined

      const { rate: ratePrimary } = primaryRateSwethEth.data
      const { rate: rateSecondary } = secondaryRateSwethEth.data

      let secondaryDiscountPercent: number
      if (ratePrimary === 0) secondaryDiscountPercent = 0
      else {
        secondaryDiscountPercent =
          ((ratePrimary - rateSecondary) / ratePrimary) * 100
      }

      return { secondaryDiscountPercent }
    },
  }
}

/**
 * Queries the v3-backend rates service for rswETH/ETH rates, and uses on-chain values
 *  from chainlink as a fallback (redundancy).
 *
 * TODO: backend value and on-chain value is stubbed
 */
export function useRswEthEthMarketRateRedundant({
  primaryRefreshIntervalMs,
}: {
  // Refresh interval (ms) of the backend data source
  // (Not applicable when on chain fallback is active)
  primaryRefreshIntervalMs?: number
} = {}) {
  // TODO: useGetRatesRswEthEthV3Backend
  const backendQuery = useGetRatesSwEthEthV3Backend({
    refreshInterval: primaryRefreshIntervalMs,
  })

  const fallbackActive = useMemo<boolean>(() => {
    return !!backendQuery.error
  }, [backendQuery.error])

  const fallbackQuery = useRswEthEthMarketRateOnChain({
    active: fallbackActive,
  })

  const { data, error, isLoading, isValidating } = useMemo(() => {
    if (fallbackActive) {
      return {
        ...fallbackQuery,
        get data() {
          if (!fallbackQuery.data) return undefined
          return { rate: fallbackQuery.data.rate }
        },
      }
    }

    return {
      ...backendQuery,
      get data() {
        if (!backendQuery.data) return undefined
        return { rate: parseFloat(backendQuery.data.rate) }
      },
    }
  }, [backendQuery, fallbackActive, fallbackQuery])

  return {
    data,
    error,
    isLoading,
    isValidating,
    mutateFallback: fallbackQuery.mutate,
    fallbackActive,
  }
}

/**
 * Composes redundant ETH/USD and rswETH/ETH rate information to produce
 *  rswETH/USD exchange rate information.
 *
 * TODO: construct redundant queries for swETH/USD sources when secondary market
 *  data for this rates is available
 */
export function useRswEthUsdMarketRateRedundant() {
  const ethUsd = useEthUsdMarketRateRedundant()
  const rswEthEth = useRswEthEthMarketRateRedundant()

  return {
    get data() {
      if (!ethUsd.data || !rswEthEth.data) return undefined
      return { rate: ethUsd.data.rate * rswEthEth.data.rate }
    },
    error: ethUsd.error ?? rswEthEth.error,
    isLoading: ethUsd.isLoading || rswEthEth.isLoading,
    isValidating: ethUsd.isValidating || rswEthEth.isValidating,
  }
}

/**
 * Uses primary market data (staking / fair value rate) to calculate an effective
 *  rswETH/ETH exchange rate that can be used in absence of secondary
 *  market data
 */
export function useRswEthEthMarketRatePrimary() {
  const { data, ...query } = useRestakingRate()

  return {
    ...query,
    get data() {
      if (!data) return undefined
      const { rswETHToETHRate } = data
      return { rate: bigNumberRateToFloat(rswETHToETHRate) }
    },
  }
}

/**
 * Uses primary market data (staking / fair value rate) to calculate an effective
 *  rswETH/USD exchange rate that can be used in absence of secondary
 *  market data
 */
export function useRswEthUsdMarketRatePrimary() {
  const rswEthEthRatePrimary = useRswEthEthMarketRatePrimary()
  const ethUsdQuery = useEthUsdMarketRate()

  return {
    get isLoading() {
      return rswEthEthRatePrimary.isLoading || ethUsdQuery.isLoading
    },
    get error() {
      return rswEthEthRatePrimary.error ?? ethUsdQuery.error
    },
    get isValidating() {
      return rswEthEthRatePrimary.isValidating || ethUsdQuery.isValidating
    },
    get data() {
      if (!rswEthEthRatePrimary.data || !ethUsdQuery.data) return undefined
      const { rate: rswEthEth } = rswEthEthRatePrimary.data
      const { rate: ethUsd } = ethUsdQuery.data
      return { rate: rswEthEth * ethUsd }
    },
  }
}

export const useRswEthEthMarketRate = useRswEthEthMarketRatePrimary
export const useRswEthUsdMarketRate = useRswEthUsdMarketRatePrimary
