import { ServiceType } from '@bufbuild/protobuf'
import { PromiseClient } from '@bufbuild/connect'
import useSWRMutation from 'swr/mutation'
import { protobufToUrl } from '@/util/protobuf'

const makeProtobufMutation = (useSWRMutationHook: typeof useSWRMutation) => {
  const useProtobufMutationHook = <
    ST extends ServiceType,
    PC extends PromiseClient<ST>,
    Method extends keyof PromiseClient<ST>
  >(
    backendUrl: string,
    {
      service,
      method,
      client,
    }: {
      service: ST
      method: Method
      client: PC
    }
  ): any => {
    return useSWRMutationHook(
      protobufToUrl({
        service,
        method,
        prefix: backendUrl,
      }),
      (url: string, { arg }: { arg: any }) => {
        return Promise.resolve(client[method](arg))
      }
    ) as any
  }

  return useProtobufMutationHook
}

export const useProtobufMutation = makeProtobufMutation(useSWRMutation)
