import { useEveryNthBlock } from '@/hooks/useBlockNumber'
import { useDataLivenessConfig } from '../deployments/hooks/useDataLivenessConfig'
import { GAS_PRICE_NEEDS_BLOCK_LISTENER, useGasPrice } from './hooks'

export default function GasUpdater(): null {
  const { mutate } = useGasPrice()
  const { gasPriceLiveness } = useDataLivenessConfig()

  useEveryNthBlock({
    blocksPerFetch: gasPriceLiveness.blocksPerFetch,
    fetch: mutate,
    skip: !GAS_PRICE_NEEDS_BLOCK_LISTENER,
  })

  return null
}
