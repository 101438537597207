import { useDeploymentSetConfig } from '@/state/deployments/hooks'

// w30 todo: put chain id constants in var, move this to helpers/network.ts
function makeRpcUrls() {
  const { rpcUrl } = useDeploymentSetConfig()

  return {
    1: rpcUrl.mainnet,
    5: rpcUrl.goerli,
    1337: 'http://localhost:8545',
  } as any
}

function useRpcUrl() {
  const { chainId } = useDeploymentSetConfig()
  return makeRpcUrls()[chainId as any]
}

function useWalletConnectV2ProjectId() {
  const { walletConnectV2 } = useDeploymentSetConfig()
  return walletConnectV2.projectId
}

export { useRpcUrl, useWalletConnectV2ProjectId }
