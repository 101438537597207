import { useState } from 'react'
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import { useSwellWeb3 } from '@swell-web3/core'

export function useApprove(erc20TokenContract: any, spenderAddress: string) {
  const { account } = useSwellWeb3()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [amount, setAmount] = useState<BigNumber>(BigNumber.from(0))
  const [token, setToken] = useState<any>(null)
  const [tx, setTx] = useState<any>(null)

  const STATUS = {
    IDLE: 'idle',
    PROMPTING: 'prompting',
    PENDING: 'pending',
    FULFILLED: 'fulfilled',
  }

  const [status, setStatus] = useState<string>(STATUS.IDLE)

  const clear = () => {
    setStatus(STATUS.IDLE)
    setTx(null)
  }

  const sendTransaction = async (amount: string, token: any) => {
    if (!erc20TokenContract || !account || !spenderAddress) {
      return false
    }

    setStatus(STATUS.PROMPTING)
    setIsLoading(true)

    try {
      const allowanceAmount = parseUnits(amount, token.decimals)
      const allowanceAmountBN = BigNumber.from(allowanceAmount)
      setAmount(allowanceAmountBN)
      setToken(token)

      const approveTx = await erc20TokenContract.approve(
        spenderAddress,
        allowanceAmount
      )
      setTx(tx)
      setStatus(STATUS.PENDING)

      await approveTx.wait()

      setIsLoading(false)
      setStatus(STATUS.FULFILLED)
      return allowanceAmount
    } catch (err) {
      console.error(err)

      setIsLoading(false)
      setStatus(STATUS.IDLE)

      return null
    }
  }

  return {
    amount,
    isLoading,
    clear,
    sendTransaction,
    status,
    STATUS,
    token,
    tx,
  }
}
