/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { PreDepositZap, PreDepositZapInterface } from "../PreDepositZap";

const _abi = [
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_weth",
        type: "address",
      },
      {
        internalType: "address",
        name: "_wstETH",
        type: "address",
      },
      {
        internalType: "address",
        name: "_weETH",
        type: "address",
      },
      {
        internalType: "address",
        name: "_stakingContract",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "eETH",
    outputs: [
      {
        internalType: "contract ERC20",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "eETHZapIn",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "ethZapIn",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "stETH",
    outputs: [
      {
        internalType: "contract ERC20",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "stETHZapIn",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "stakingContract",
    outputs: [
      {
        internalType: "contract ISimpleStakingERC20",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "weETH",
    outputs: [
      {
        internalType: "contract IWeETH",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "weth",
    outputs: [
      {
        internalType: "contract WETH",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "wstETH",
    outputs: [
      {
        internalType: "contract IWstETH",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class PreDepositZap__factory {
  static readonly abi = _abi;
  static createInterface(): PreDepositZapInterface {
    return new utils.Interface(_abi) as PreDepositZapInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): PreDepositZap {
    return new Contract(address, _abi, signerOrProvider) as PreDepositZap;
  }
}
