import React from 'react'
import transakSDK from '@transak/transak-sdk'
import { env } from '@/env'
import { useSwellUiTheme } from '@swell-ui/theme'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'

const TRANSAK_API_KEY = env.REACT_APP_TRANSAK_API_KEY

if (typeof TRANSAK_API_KEY === 'undefined') {
  throw new Error(`TRANSAK_API_KEY must be a defined environment variable`)
}

interface TransakOnRampWidgetProps {
  label: string | React.ReactNode
  account?: string
}

// see available settings at https://docs.transak.com/docs/query-parameters
interface TransakSettings {
  apiKey: string
  environment: string
  cryptoCurrencyList: string
  network: string
  productsAvailed: string
  themeColor: string
  hostURL: string
  widgetHeight?: string
  widgetWidth?: string
  walletAddress?: string
}

function openTransak(settings: TransakSettings) {
  const transak = new transakSDK(settings)
  transak.init()

  // To get all the events
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transak.on(transak.ALL_EVENTS, (data: any) => {
    // todo: track events if we want to
  })

  // This will trigger when the user closed the widget
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (eventData: any) => {
    // todo: handle any on other close logic here
    transak.close()

    // transak SDK sets a document style we don't want on close.
    // wrap our style hack in setTimeout so call gets pushed last
    setTimeout(() => {
      document.documentElement.style.overflow = ''
    })
  })

  // This will trigger when the user marks payment is made.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData: any) => {
    // todo: handle any post order successful logic here
    transak.close()
  })
}

function TransakOnRampWidget({ label, account }: TransakOnRampWidgetProps) {
  const { transak } = useDeploymentSetConfig()
  const { theme } = useSwellUiTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  if (!transak) {
    return null
  }

  const settings = React.useMemo<TransakSettings>(() => {
    let widgetHeight = '700px'
    let widgetWidth = '500px'

    if (isSmDown) {
      widgetHeight = `${window.outerHeight - 48}px`
      widgetWidth = `${window.outerWidth - 48}px`
    }

    return {
      apiKey: transak.apiKey,
      environment: transak.env,
      cryptoCurrencyList: 'ETH',
      network: 'ethereum',
      productsAvailed: 'BUY',
      themeColor: '000000',
      hostURL: window.location.origin,
      widgetWidth,
      widgetHeight,
      walletAddress: account ? account : '',
    }
  }, [account, isSmDown, transak])

  return <div onClick={() => openTransak(settings)}>{label}</div>
}

export { TransakOnRampWidget }
