/**
 * Copied: https://github.com/Uniswap/interface/blob/ad2472eac638b389316ba1f3c3f1ed08fbbb12cd/src/hooks/useContract.ts
 * Changed:
 * - Similar structure, but different domain -> different contracts
 */
import { Contract } from '@ethersproject/contracts'
import { useMemo } from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { getContract } from '../util/contract'
import SWETH_ABI from '../abis/SwETH.json'
import SWEXIT_ABI from '../abis/SWExit.json'
import DEPOSIT_MANAGER_ABI from '../abis/DepositManager.json'
import NODE_OPERATOR_REGISTRY_ABI from '../abis/NodeOperatorRegistry.json'
import AGGREGATOR_V3_INTERFACE_ABI from '../abis/AggregatorV3Interface.json'
import RSWETH_ABI from '../abis/RswETH.json'
import RESTAKING_DEPOSIT_MANAGER_ABI from '../abis/RestakingDepositManager.json'
import RESTAKING_NODE_OPERATOR_REGISTRY_ABI from '../abis/RestakingNodeOperatorRegistry.json'
import SIMPLE_STAKING_ERC20_ABI from '../abis/SimpleStakingERC20.json'
import IERC20_ABI from '../abis/IERC20.json'
import ZAP_ABI from '../abis/Zap.json'
import MULTICALL3_ABI from '../abis/Multicall3.json'
import PRE_DEPOSIT_ZAP_ABI from '../abis/PreDepositZap.json'
import TELLER_WITH_MULTI_ASSET_SUPPORT_ABI from '../abis/TellerWithMultiAssetSupport.json'
import MOCK_TOKEN_ABI from '../abis/MockToken.json'
import YEARN_V3_VAULT_ABI from '../abis/YearnV3Vault.json'
import YEARN_DELAYED_WITHDRAW_ABI from '../abis/YearnDelayedWithdraw.json'
import {
  SwETH,
  SWExit,
  DepositManager,
  NodeOperatorRegistry,
  AggregatorV3Interface,
  RswETH,
  RestakingDepositManager,
  RestakingNodeOperatorRegistry,
  SimpleStakingERC20,
  IERC20,
  Zap,
  Multicall3,
  PreDepositZap,
  TellerWithMultiAssetSupport,
  YearnDelayedWithdraw,
  YearnV3Vault,
  MockToken,
} from '@/abis/types'
import { useContractAddresses } from '@/state/deployments/hooks'
import {
  CHAINLINK_ETH_USD_FEED_ADDRESSES,
  CHAINLINK_WBTC_ETH_ADDRESSES,
} from '@/constants/addresses'
import { CumulativeMerkleDrop } from '@/abis/types/CumulativeMerkleDrop'
import CUMULATIVE_MERKLE_DROP_ABI from '../abis/CumulativeMerkleDrop.json'

function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { account, chainId, provider } = useSwellWeb3()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(
        address,
        ABI,
        provider,
        withSignerIfPossible && account ? account : undefined
      )
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [
    addressOrAddressMap,
    ABI,
    provider,
    chainId,
    withSignerIfPossible,
    account,
  ]) as T | null
}

/* Staking contracts */
export function useSwETHContract() {
  const { swETH } = useContractAddresses()
  return useContract<SwETH>(swETH, SWETH_ABI, true)
}

export function useSwETHContractView() {
  const { swETH } = useContractAddresses()
  return useContract<SwETH>(swETH, SWETH_ABI, false)!
}

export function useSwExitContract() {
  const { swExit } = useContractAddresses()
  return useContract<SWExit>(swExit, SWEXIT_ABI, true)
}

export function useSwExitContractView() {
  const { swExit } = useContractAddresses()
  return useContract<SWExit>(swExit, SWEXIT_ABI, false)!
}

export function useDepositManagerContract() {
  const { depositManager } = useContractAddresses()
  return useContract<DepositManager>(depositManager, DEPOSIT_MANAGER_ABI, true)
}

export function useDepositManagerContractView() {
  const { depositManager } = useContractAddresses()
  return useContract<DepositManager>(
    depositManager,
    DEPOSIT_MANAGER_ABI,
    false
  )!
}

export function useNodeOperatorRegistryContract() {
  const { nodeOperatorRegistry } = useContractAddresses()
  return useContract<NodeOperatorRegistry>(
    nodeOperatorRegistry,
    NODE_OPERATOR_REGISTRY_ABI,
    true
  )
}

export function useNodeOperatorRegistryContractView() {
  const { nodeOperatorRegistry } = useContractAddresses()
  return useContract<NodeOperatorRegistry>(
    nodeOperatorRegistry,
    NODE_OPERATOR_REGISTRY_ABI,
    false
  )!
}

/* Restaking Contracts */

export function useRswETHContract() {
  const { rswETH } = useContractAddresses()
  return useContract<RswETH>(rswETH, RSWETH_ABI, true)
}

export function useRswETHContractView() {
  const { rswETH } = useContractAddresses()
  return useContract<RswETH>(rswETH, RSWETH_ABI, false)!
}

export function useRestakingNodeOperatorRegistryContract() {
  const { restakingNodeOperatorRegistry } = useContractAddresses()
  return useContract<RestakingNodeOperatorRegistry>(
    restakingNodeOperatorRegistry,
    RESTAKING_NODE_OPERATOR_REGISTRY_ABI,
    true
  )
}

export function useRestakingNodeOperatorRegistryContractView() {
  const { restakingNodeOperatorRegistry } = useContractAddresses()
  return useContract<RestakingNodeOperatorRegistry>(
    restakingNodeOperatorRegistry,
    RESTAKING_NODE_OPERATOR_REGISTRY_ABI,
    false
  )!
}

export function useRestakingDepositManagerContract() {
  const { restakingDepositManager } = useContractAddresses()
  return useContract<RestakingDepositManager>(
    restakingDepositManager,
    RESTAKING_DEPOSIT_MANAGER_ABI,
    true
  )
}

export function useRestakingDepositManagerContractView() {
  const { restakingDepositManager } = useContractAddresses()
  return useContract<DepositManager>(
    restakingDepositManager,
    RESTAKING_DEPOSIT_MANAGER_ABI,
    false
  )!
}

export function useEthUsdFeedChainlinkContract() {
  return useContract<AggregatorV3Interface>(
    CHAINLINK_ETH_USD_FEED_ADDRESSES,
    AGGREGATOR_V3_INTERFACE_ABI,
    false
  )!
}
export function useWbtcEthChainlinkContract() {
  return useContract<AggregatorV3Interface>(
    CHAINLINK_WBTC_ETH_ADDRESSES,
    AGGREGATOR_V3_INTERFACE_ABI,
    false
  )!
}

export function useZapContract() {
  const { zap } = useContractAddresses()
  return useContract<Zap>(zap, ZAP_ABI)
}

export function useMulticallContract() {
  const { multicall } = useContractAddresses()
  return useContract<Multicall3>(multicall, MULTICALL3_ABI, false)!
}

export function useIERC20Contract(address: string) {
  return useContract<IERC20>(address, IERC20_ABI, true)
}

export function usePreDepositStakingContract() {
  const { preDepositStaking } = useContractAddresses()
  return useContract<SimpleStakingERC20>(
    preDepositStaking,
    SIMPLE_STAKING_ERC20_ABI,
    true
  )
}

export function usePreDepositStakingContractView() {
  const { preDepositStaking } = useContractAddresses()
  return useContract<SimpleStakingERC20>(
    preDepositStaking,
    SIMPLE_STAKING_ERC20_ABI,
    false
  )!
}

export function usePreDepositZapContract() {
  const { preDepositZap } = useContractAddresses()
  return useContract<PreDepositZap>(preDepositZap, PRE_DEPOSIT_ZAP_ABI, true)!
}

export function useLiquidUSDVaultTellerContract() {
  const { liquidUSDVaultTeller } = useContractAddresses()
  return useContract<TellerWithMultiAssetSupport>(
    liquidUSDVaultTeller,
    TELLER_WITH_MULTI_ASSET_SUPPORT_ABI,
    false
  )!
}
export function useMockTokenContract(address: string) {
  return useContract<MockToken>(address, MOCK_TOKEN_ABI, true)!
}
export function useYearnV3VaultContract(address: string) {
  return useContract<YearnV3Vault>(address, YEARN_V3_VAULT_ABI, true)!
}
export function useYearnDelayedWithdrawContract(address: string) {
  return useContract<YearnDelayedWithdraw>(
    address,
    YEARN_DELAYED_WITHDRAW_ABI,
    true
  )!
}

export function useMerkleDropContract(address: string | undefined) {
  return useContract<CumulativeMerkleDrop>(
    address,
    CUMULATIVE_MERKLE_DROP_ABI,
    true
  )
}
