import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { ZapRoute, ZapRouteParams } from '@/state/zap/types'
import { useSwellWeb3 } from '@/swell-web3/core'
import { Token } from '@/types/tokens'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { useEffect, useMemo } from 'react'

// set dest token input value if src token input value is changed
export function useZapInputBehaviour({
  zapRoute,
  srcToken,
  setDestTokenInputValue,
  rswethDecimals,
  paused,
}: {
  srcToken: Token
  zapRoute: ZapRoute | undefined
  setDestTokenInputValue: (value: string) => void
  rswethDecimals: number
  paused: boolean
}) {
  useEffect(() => {
    if (paused) return
    if (!zapRoute) return
    if (srcToken.symbol === TOKEN_LIST_ETH.symbol) return
    const { toAmount } = zapRoute
    setDestTokenInputValue(formatUnits(toAmount, rswethDecimals))
  }, [zapRoute, srcToken, setDestTokenInputValue, rswethDecimals, paused])
}

export function useZapRouteParams({
  fromAmount,
  fromToken,
  toToken,
  maxPriceImpact,
  slippage,
}: {
  fromToken: Token | undefined
  toToken: Token | undefined
  fromAmount: BigNumber | undefined
  slippage: number // ratio
  maxPriceImpact: number // ratio
}) {
  const { chainId } = useSwellWeb3()
  const { chainId: deploymentChainId } = useDeploymentSetConfig()

  return useMemo<ZapRouteParams | undefined>(() => {
    if (!fromToken || !toToken || !fromAmount) {
      return undefined
    }

    if (chainId !== deploymentChainId) return undefined

    return {
      fromChainId: chainId, // cross-chain routes not supported yet
      toChainId: chainId,
      fromAmount: fromAmount.toString(),
      fromToken: {
        address: fromToken.address,
        decimals: fromToken.decimals,
      },
      toToken: {
        address: toToken.address,
        decimals: toToken.decimals,
      },
      options: {
        slippage,
        maxPriceImpact,
      },
    } as ZapRouteParams
  }, [
    chainId,
    deploymentChainId,
    fromAmount,
    fromToken,
    maxPriceImpact,
    slippage,
    toToken,
  ])
}
