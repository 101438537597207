import { AggregatorV3Interface } from '@/abis/types'
import { BigNumber, ethers } from 'ethers'

export class SimpleGetRate {
  private provider: ethers.providers.JsonRpcProvider
  private getRateName: string
  private oracle: string

  constructor({
    oracle,
    provider,
    getRateName,
  }: {
    provider: ethers.providers.JsonRpcProvider
    getRateName: string // getRate(), exchangeRate, etc. brackets optional
    oracle: string
  }) {
    this.provider = provider
    this.oracle = oracle
    this.getRateName = getRateName
  }

  getRate = async (): Promise<BigNumber> => {
    const name = this.getRateName.replace('()', '')

    const fragment = ethers.utils.Fragment.from({
      name,
      type: 'function',
      inputs: [],
      outputs: [ethers.utils.ParamType.from('uint256')],
      stateMutability: 'view',
    })
    const sig = ethers.utils.Interface.getSighash(fragment)

    const callData = ethers.utils.defaultAbiCoder.encode(['string'], [sig])
    const result = await this.provider.call({
      to: this.oracle,
      data: callData,
    })
    return ethers.utils.defaultAbiCoder.decode(['uint256'], result)[0]
  }
}

export class ChainlinkGetRate {
  private aggIface: AggregatorV3Interface

  constructor(aggIface: AggregatorV3Interface) {
    this.aggIface = aggIface
  }

  getRate = async (): Promise<BigNumber> => {
    const result = await this.aggIface.latestRoundData()
    return result.answer
  }
}

export class HardcodedGetRate {
  private rate: BigNumber
  constructor(rate?: BigNumber) {
    this.rate = rate || ethers.utils.parseUnits('1', 18)
  }

  getRate = async (): Promise<BigNumber> => {
    return this.rate
  }
}

export class MockGetRate {
  private rate: BigNumber
  private delay: number
  constructor(rate?: BigNumber, delay?: number) {
    this.rate = rate || ethers.utils.parseUnits('1', 18)
    this.delay = delay || 0
  }

  getRate = async (): Promise<BigNumber> => {
    await new Promise((resolve) => setTimeout(resolve, this.delay))
    return this.rate
  }
}
