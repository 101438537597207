import React from 'react'

function InfoIcon(props: any) {
  return (
    <svg
      {...props}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1783_14616)">
        <path
          d="M6.19995 11.4983C8.96137 11.4983 11.2 9.25971 11.2 6.49829C11.2 3.73687 8.96137 1.49829 6.19995 1.49829C3.43853 1.49829 1.19995 3.73687 1.19995 6.49829C1.19995 9.25971 3.43853 11.4983 6.19995 11.4983Z"
          stroke="#F1F1F1"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.19995 8.49927V6.49927"
          stroke="#F1F1F1"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.19995 4.49829H6.20528"
          stroke="#F1F1F1"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1783_14616">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.199951 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export { InfoIcon }
