// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/voyage.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { VoyageLeaderboardRequest, VoyageLeaderboardResponse, VoyageStatusRequest, VoyageStatusResponse, VoyageUserRequest, VoyageUserResponse } from "./voyage_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.VoyageService
 */
export const VoyageService = {
  typeName: "swell.v3.VoyageService",
  methods: {
    /**
     * @generated from rpc swell.v3.VoyageService.VoyageStatus
     */
    voyageStatus: {
      name: "VoyageStatus",
      I: VoyageStatusRequest,
      O: VoyageStatusResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.VoyageService.VoyageLeaderboard
     */
    voyageLeaderboard: {
      name: "VoyageLeaderboard",
      I: VoyageLeaderboardRequest,
      O: VoyageLeaderboardResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.VoyageService.VoyageUser
     */
    voyageUser: {
      name: "VoyageUser",
      I: VoyageUserRequest,
      O: VoyageUserResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

