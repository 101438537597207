import { getAddress } from '@ethersproject/address'

export const strip0x = (value: string) => {
  return value.replace('0x', '')
}

export const prefix0x = (value: string) => {
  return `0x${strip0x(value)}`
}

export const compareHex = (value1: string, value2: string) => {
  return strip0x(value1).toLowerCase() === strip0x(value2).toLowerCase()
}

// ----
// below copied from: https://github.com/Uniswap/interface/blob/ad2472eac638b389316ba1f3c3f1ed08fbbb12cd/src/utils/index.ts

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    // Alphabetical letters must be made lowercase for getAddress to work.
    // See documentation here: https://docs.ethers.io/v5/api/utils/address/
    return getAddress(value.toLowerCase())
  } catch {
    return false
  }
}

/**
 * Shortens an Ethereum address by N characters
 * @param address blockchain address
 * @param charsStart amount of character to shorten (from both ends / in the beginning)
 * @param charsEnd amount of characters to shorten in the end
 * @returns formatted string
 */
export function shortenAddress(
  address: string,
  charsStart = 4,
  charsEnd?: number
): string {
  const parsed = isAddress(address)
  if (!parsed) throw Error(`Invalid 'address' parameter '${address}'.`)

  return `${address.substring(0, charsStart + 2)}...${address.substring(
    42 - (charsEnd || charsStart)
  )}`
}

export function shortenHash(hash: string, charsStart = 4, charsEnd?: number) {
  return `${prefix0x(hash).substring(0, charsStart + 2)}...${hash.substring(
    hash.length - (charsEnd || charsStart)
  )}`
}
