import React from 'react'

function CaretUpIcon(props: any) {
  return (
    <svg
      {...props}
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.26552 0.484479L9.01552 4.23448C9.05036 4.26932 9.078 4.31068 9.09686 4.35621C9.11571 4.40173 9.12542 4.45052 9.12542 4.49979C9.12542 4.54906 9.11571 4.59786 9.09686 4.64338C9.078 4.6889 9.05036 4.73026 9.01552 4.7651C8.98068 4.79995 8.93932 4.82758 8.8938 4.84644C8.84827 4.8653 8.79948 4.875 8.75021 4.875C8.70094 4.875 8.65214 4.8653 8.60662 4.84644C8.5611 4.82758 8.51974 4.79995 8.4849 4.7651L5.00021 1.27995L1.51552 4.7651C1.44516 4.83547 1.34972 4.875 1.25021 4.875C1.1507 4.875 1.05526 4.83547 0.984896 4.7651C0.914531 4.69474 0.875 4.5993 0.875 4.49979C0.875 4.40028 0.914531 4.30484 0.984896 4.23448L4.7349 0.484479C4.76972 0.449613 4.81108 0.421953 4.85661 0.403081C4.90213 0.38421 4.95093 0.374497 5.00021 0.374497C5.04949 0.374497 5.09829 0.38421 5.14381 0.403081C5.18934 0.421953 5.23069 0.449613 5.26552 0.484479Z"
        fill="white"
      />
    </svg>
  )
}

export { CaretUpIcon }
