import { Stack } from '@/swell-ui/Stack'
import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { Typography } from '@/swell-ui/Typography'
import { Link } from 'react-router-dom'
import { NavGroup, NavIcon } from '../NavPopover'
import MuiDrawer from '@mui/material/Drawer'
import { css } from 'styled-components'
import { HamburgerIcon } from '../icons/HamburgerIcon'
import { Logo } from '../branding/Logo'
import { Divider } from '../Divider'
import { FlexRow } from '../FlexRow'
import { CloseIcon } from '../icons/CloseIcon'
import { Button } from '../Button'
import { Accordion } from '../Accordion'
import { useSwellUiTheme } from '../theme'

const NewIndicator = styled.div`
  margin-top: 3px;
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.navigation.newIndicator.borderRadius};
  background: ${({ theme }) => theme.navigation.newIndicator.bgColor};
  border: ${({ theme }) => theme.navigation.newIndicator.border};
  color: ${({ theme }) => theme.navigation.newIndicator.color};
  pointer-events: none;
  width: 37px;
  height: 20px;

  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 16px */
  letter-spacing: 0.75px;
`

const DrawerLogo = styled(Logo)`
  height: 40px;
  width: 108px;
`
const DrawerButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    justify-content: left;
    ${theme.navigation.linkTypography}
  `}
`

const StyledMuiDrawer = styled(MuiDrawer)`
  ${({ theme }) => css`
    .MuiModal-backdrop {
      background-color: ${theme.navigation.dialog.backdrop.bgColor};
      backdrop-filter: ${theme.navigation.dialog.backdrop.backdropFilter};
    }

    .MuiDrawer-paper {
      width: 100%;
      background-color: ${theme.navigation.dialog.bgColor};
      padding: 16px;
    }

    ${theme.breakpoints.up('sm')} {
      .MuiDrawer-paper {
        max-width: 360px;
      }
    }
  `}

  ${Divider} {
    border-color: ${({ theme }) => theme.navigation.dividerColor};
  }
`
const DrawerInner = styled.div`
  padding-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`

const StyledHamburgerIcon = styled(HamburgerIcon)`
  width: 40px;
  height: 40px;

  path {
    fill: ${({ theme }) => theme.mainColor};
  }
`
const HeadingRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
`
const HeadingTypography = styled(Typography)`
  ${({ theme }) => theme.navigation.drawerHeadingTypography}
`
const StyledLink = styled(Link)`
  min-width: 175px;

  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  padding: 6px 12px;

  border-radius: ${({ theme }) => theme.navigation.linkHoverBorderRadius};
  :hover {
    background-color: ${({ theme }) => theme.navigation.linkHoverBgColor};
  }

  .icons {
    align-self: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    align-items: flex-end;
    img.major {
      width: 22px;
      height: 22px;
      z-index: 2;
    }
    img.minor {
      margin-left: -4px;
      width: 16px;
      height: 16px;
      z-index: 1;
    }
    img.circle {
      border-radius: 50%;
    }
    .rect-lg-holder {
      width: 30.35px;
      height: 22px;
      position: relative;
      margin-right: 4px;
      img.rect-lg {
        width: 30.35px;
        height: 22px;
        position: absolute;
        transform: scale(1.45) translate(-6px);
        transform-origin: left;
      }
    }
  }
`

const LinkTypography = styled(Typography)`
  ${({ theme }) => theme.navigation.linkTypography}
`

const StyledAccordion = styled(Accordion)`
  width: 100%;
  min-height: 52px;

  .MuiAccordionSummary-content.Mui-expanded {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 21px;
  }

  .MuiAccordionDetails-root {
    margin-bottom: 0;
  }
`

export type NavAction = {
  label: ReactNode
  type: string
  href?: string
  externalLink?: boolean
  onClick?: () => void
}

// Mobile counterpart of NavPopover - uses the same types / theme
export function NavHamburgerMobile({
  groups,
  actions,
}: {
  groups: NavGroup[]
  actions?: NavAction[]
}) {
  const [open, setOpen] = useState<boolean>(false)

  const toggleDrawer =
    (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setOpen(isOpen)
    }

  return (
    <div>
      <div>
        <StyledHamburgerIcon onClick={toggleDrawer(true)} />
      </div>
      <StyledMuiDrawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <FlexRow justify="space-between" align="center">
          <DrawerLogo />
          <CloseIcon onClick={toggleDrawer(false)} />
        </FlexRow>
        <DrawerInner>
          {groups.map((group, gi) => {
            const isLastGroup = gi === groups.length - 1

            return (
              <>
                <Stack key={group.title} direction="column" spacing={2}>
                  <AccordionItem title={group.title}>
                    <FlexRow direction="column" gap={'16'} align="stretch">
                      {group.leaves.map((leaf) => {
                        return (
                          <StyledLink
                            key={leaf.to}
                            to={leaf.to}
                            onClick={() => {
                              setOpen(false)
                            }}
                          >
                            {leaf.icons && (
                              <div className="icons">
                                {leaf.icons.map((icon, idx) => {
                                  return (
                                    <NavIconImage
                                      icon={icon}
                                      idx={idx}
                                      key={idx}
                                    />
                                  )
                                })}
                              </div>
                            )}
                            <LinkTypography
                              variant="body"
                              size="medium"
                              fstyle="bold"
                            >
                              {leaf.label}
                            </LinkTypography>
                            {leaf.isNew && <NewIndicator>NEW</NewIndicator>}
                          </StyledLink>
                        )
                      })}
                    </FlexRow>
                  </AccordionItem>
                </Stack>
                {!isLastGroup && <Divider />}
              </>
            )
          })}
          {actions?.length && (
            <>
              <Divider />
              {actions.map((item: any, index: number) => {
                if (item.type === 'outsideLink') {
                  return (
                    <DrawerButton key={index} variant="tertiary">
                      <a href={item.href} target="_blank" rel="noreferrer">
                        {item.label}
                      </a>
                    </DrawerButton>
                  )
                }
                return (
                  <DrawerButton
                    key={index}
                    variant="tertiary"
                    onClick={item.onClick}
                  >
                    {item.label}
                  </DrawerButton>
                )
              })}
            </>
          )}
        </DrawerInner>
      </StyledMuiDrawer>
    </div>
  )
}

function NavIconImage({ icon, idx }: { icon: NavIcon; idx: number }) {
  let uri = ''
  if (typeof icon === 'string') {
    uri = icon
  } else {
    uri = icon.uri
  }

  if (typeof icon !== 'string' && icon.shape === 'rect-lg') {
    return (
      <div className="rect-lg-holder">
        <img src={uri} className="rect-lg" />
      </div>
    )
  }

  const isMajor = idx === 0
  let className = isMajor ? 'major' : 'minor'
  if (typeof icon === 'string' || icon.shape === 'circle') {
    className += ' circle'
  }

  return <img src={uri} className={className} />
}

function AccordionItem({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) {
  const [expanded, setExpanded] = useState(false)

  return (
    <StyledAccordion
      config={{
        summary: (
          <HeadingRow>
            <HeadingTypography
              variant="headline"
              size="h6"
              letterSpacing="small"
            >
              {title}
            </HeadingTypography>
            {expanded && <UpChevron />}
            {!expanded && <DownChevron />}
          </HeadingRow>
        ),
        details: children,
      }}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    />
  )
}

function UpChevron() {
  const { theme } = useSwellUiTheme()

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5306 6.96939L20.0306 14.4694C20.1002 14.5391 20.1555 14.6218 20.1932 14.7128C20.2309 14.8039 20.2503 14.9015 20.2503 15C20.2503 15.0986 20.2309 15.1961 20.1932 15.2872C20.1555 15.3782 20.1002 15.461 20.0306 15.5306C19.9609 15.6003 19.8781 15.6556 19.7871 15.6933C19.6961 15.731 19.5985 15.7504 19.4999 15.7504C19.4014 15.7504 19.3038 15.731 19.2128 15.6933C19.1217 15.6556 19.039 15.6003 18.9693 15.5306L11.9999 8.56032L5.03055 15.5306C4.88982 15.6714 4.69895 15.7504 4.49993 15.7504C4.30091 15.7504 4.11003 15.6714 3.9693 15.5306C3.82857 15.3899 3.74951 15.199 3.74951 15C3.74951 14.801 3.82857 14.6101 3.9693 14.4694L11.4693 6.96939C11.539 6.89965 11.6217 6.84433 11.7127 6.80659C11.8038 6.76885 11.9014 6.74942 11.9999 6.74942C12.0985 6.74942 12.1961 6.76885 12.2871 6.80659C12.3782 6.84433 12.4609 6.89965 12.5306 6.96939Z"
        fill={theme.navigation.drawerChevronColor}
      />
    </svg>
  )
}
function DownChevron() {
  const { theme } = useSwellUiTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.469 17.0306L3.96896 9.53062C3.89927 9.46093 3.844 9.37821 3.80629 9.28716C3.76858 9.19612 3.74916 9.09854 3.74916 8.99999C3.74916 8.90144 3.76858 8.80386 3.80629 8.71282C3.844 8.62177 3.89927 8.53905 3.96896 8.46936C4.03864 8.39968 4.12136 8.34441 4.21241 8.30669C4.30345 8.26898 4.40104 8.24957 4.49958 8.24957C4.59813 8.24957 4.69571 8.26898 4.78675 8.30669C4.8778 8.34441 4.96052 8.39968 5.03021 8.46936L11.9996 15.4397L18.969 8.46936C19.1097 8.32863 19.3006 8.24957 19.4996 8.24957C19.6986 8.24957 19.8895 8.32863 20.0302 8.46936C20.1709 8.61009 20.25 8.80097 20.25 8.99999C20.25 9.19901 20.1709 9.38988 20.0302 9.53062L12.5302 17.0306C12.4606 17.1003 12.3778 17.1557 12.2868 17.1934C12.1957 17.2312 12.0981 17.2506 11.9996 17.2506C11.901 17.2506 11.8034 17.2312 11.7124 17.1934C11.6213 17.1557 11.5386 17.1003 11.469 17.0306Z"
        fill={theme.navigation.drawerChevronColor}
      />
    </svg>
  )
}
