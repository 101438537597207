import { useChainInfo } from '@/state/deployments/hooks'
import { shortenHash } from '@/util/hexStrings'
import React from 'react'

interface IExplorerLinkProps {
  hexString: string
  kind: 'address' | 'tx'
}

const ExplorerLink: React.FC<IExplorerLinkProps> = ({ hexString, kind }) => {
  const { explorer } = useChainInfo()

  const derived = {
    get href() {
      return `${explorer.replace(/\/$/, '')}/${kind}/${hexString}`
    },
    get childString() {
      return shortenHash(hexString)
    },
  }

  return (
    <a href={derived.href} target="_blank" rel="noopener noreferrer">
      {derived.childString}
    </a>
  )
}

export default ExplorerLink
