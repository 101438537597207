import {
  ApproveRswETHForWithdrawal,
  CreateWithdrawRequestRswETH,
  FinalizeWithdrawalRswETH,
} from '@/state/rsweth/hooks'
import { BigNumber } from 'ethers'
import { RestakeClaimSelect } from './exitHooks'
import { ExitAsset } from '@/types/claims'
import { getStaticExitWithdrawAsset as getStaticExitAsset } from '@/constants/exits'

export const ExitErrors = {
  AmountMustBeGreaterThanZero: 'Amount must be greater than 0',
  InsufficientBalance: 'Insufficient balance',
  InsufficientAllowance: 'Insufficient allowance',
  UnstakeAmountTooLow: 'Unstake amount too low',
  UnstakeAmountTooHigh: 'Unstake amount too high',
}

type ValidatedArgs<T> =
  | {
      args?: never
      error: string | null
    }
  | {
      args: T
      error?: never
    }

export function prepareApproveRswETHForWithdrawal({
  amount,
  rswETHBalance,
  withdrawAsset,
  chainId,
}: {
  amount: BigNumber | undefined
  rswETHBalance: BigNumber | undefined
  withdrawAsset: ExitAsset
  chainId: number
}): ValidatedArgs<Parameters<ApproveRswETHForWithdrawal['call']>> {
  if (amount === undefined || rswETHBalance === undefined) {
    return { error: null }
  }
  if (amount.gt(rswETHBalance)) {
    return { error: ExitErrors.InsufficientBalance }
  }
  if (amount.lte(0)) {
    return { error: ExitErrors.AmountMustBeGreaterThanZero }
  }
  const assetAddress = withdrawAsset.address
  const configuredAsset = getStaticExitAsset(withdrawAsset.exitAddress, chainId)
  if (!configuredAsset) {
    return { error: 'Unknown withdraw asset' } // internal
  }
  if (configuredAsset.address !== withdrawAsset.address) {
    return { error: 'Invalid withdraw asset' } // internal
  }
  return {
    args: [{ amount, assetAddress }],
  }
}
export type PreparedApproveRswETHForWithdrawal = ReturnType<
  typeof prepareApproveRswETHForWithdrawal
>

export function prepareCreateWithdrawRequestRswETH({
  rswETHAmount,
  rswETHAllowance,
  rswETHBalance,
  maxUnstakeAmount,
  minUnstakeAmount,
  withdrawAsset,
  chainId,
}: {
  rswETHAmount: BigNumber | undefined
  rswETHAllowance: BigNumber | undefined
  rswETHBalance: BigNumber | undefined
  minUnstakeAmount: BigNumber
  maxUnstakeAmount: BigNumber
  withdrawAsset: ExitAsset
  chainId: number
}): ValidatedArgs<Parameters<CreateWithdrawRequestRswETH['call']>> {
  if (
    rswETHAmount === undefined ||
    rswETHAllowance === undefined ||
    rswETHBalance === undefined
  ) {
    return { error: null }
  }

  if (rswETHAmount.lte(0)) {
    return { error: ExitErrors.AmountMustBeGreaterThanZero }
  }
  if (rswETHAmount.gt(rswETHBalance)) {
    return { error: ExitErrors.InsufficientBalance }
  }
  if (rswETHAmount.lt(minUnstakeAmount)) {
    return { error: ExitErrors.UnstakeAmountTooLow }
  }
  if (rswETHAmount.gt(maxUnstakeAmount)) {
    return { error: ExitErrors.UnstakeAmountTooHigh }
  }
  if (rswETHAmount.gt(rswETHAllowance)) {
    return { error: ExitErrors.InsufficientAllowance }
  }

  const assetAddress = withdrawAsset.address

  const configuredAsset = getStaticExitAsset(withdrawAsset.exitAddress, chainId)
  if (!configuredAsset) {
    return { error: 'Unknown withdraw asset' } // internal - Unknown withdraw asset
  }
  if (configuredAsset.address !== assetAddress) {
    return { error: 'Invalid withdraw asset' } // internal
  }

  return {
    args: [{ rswETHAmount, assetAddress }],
  }
}
export type PreparedCreateWithdrawRequestRswETH = ReturnType<
  typeof prepareCreateWithdrawRequestRswETH
>

export function prepareFinalizeWithdrawalRswETH({
  selectedClaim,
  chainId,
}: {
  selectedClaim: RestakeClaimSelect['selectedClaim']
  chainId: number
}): ValidatedArgs<Parameters<FinalizeWithdrawalRswETH['call']>> {
  if (selectedClaim === undefined) {
    return { error: null }
  }
  const assetAddress = selectedClaim.address

  let requestId: BigNumber
  try {
    requestId = BigNumber.from(selectedClaim.requestId)
  } catch (e) {
    return { error: 'Invalid withdrawal request' } // internal
  }

  if (requestId.lte(0)) {
    return { error: 'Invalid withdrawal request' } // internal
  }

  if (selectedClaim.status.toLowerCase() !== 'claimable') {
    return { error: 'Invalid withdrawal request' } // internal
  }

  const configuredAsset = getStaticExitAsset(selectedClaim.exitAddress, chainId)
  if (!configuredAsset) {
    return { error: 'Unknown withdraw asset' } // internal
  }
  if (assetAddress !== configuredAsset.address) {
    return { error: 'Invalid withdraw asset' } // internal
  }

  return {
    args: [{ assetAddress, requestId }],
  }
}
export type PreparedFinalizeWithdrawalRswETH = ReturnType<
  typeof prepareFinalizeWithdrawalRswETH
>
