import { useDepositSettings } from '@/state/deployments/hooks/useDepositSettings'
import { DepositCollection } from '@/types/deposits'
import chunkify from 'lodash/chunk'
import { useCallback } from 'react'

// Wrapper around lodash chunk which injects the appropriate chunk size (given dev settings)
// used for preparing chunked validator deposits before submission
export const useChunkDepositCollection = () => {
  const { maxDepositKeyChunkSize } = useDepositSettings()

  return useCallback(
    (depositList: DepositCollection) => {
      if (depositList.length === 0)
        throw new Error(`Must supply a non-empty list`)

      return chunkify(depositList, maxDepositKeyChunkSize)
    },
    [maxDepositKeyChunkSize]
  )
}
