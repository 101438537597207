import React from 'react'
import { Box } from '@/swell-ui/Box'
import { FlexRow } from '@/swell-ui/FlexRow'
import { DurationBreakdown } from '@/util/datetime'
import styled, { css } from 'styled-components'

export function CountdownBoxClock({
  countdown,
  compact,
}: {
  countdown: DurationBreakdown
  compact?: boolean
}) {
  const { days, hours, minutes, seconds } = countdown

  const daysStr = days.toString().padStart(2, '0')
  const hoursStr = hours.toString().padStart(2, '0')
  const minutesStr = minutes.toString().padStart(2, '0')
  const secondsStr = seconds.toString().padStart(2, '0')

  const gap = compact ? '4' : '8'

  return (
    <Layout width="auto" gap={gap} compact={compact}>
      <FlexRow direction="column" gap={gap}>
        <ClockBox>
          <span className="clock-text">{daysStr}</span>
        </ClockBox>
        <span className="label-colored">days</span>
      </FlexRow>
      <Separator>:</Separator>
      <FlexRow direction="column" gap={gap}>
        <ClockBox>
          <span className="clock-text">{hoursStr}</span>
        </ClockBox>
        <span className="label-colored">hours</span>
      </FlexRow>
      <Separator>:</Separator>
      <FlexRow direction="column" gap={gap}>
        <ClockBox>
          <span className="clock-text">{minutesStr}</span>
        </ClockBox>
        <span className="label-colored">minutes</span>
      </FlexRow>
      <Separator>:</Separator>
      <FlexRow direction="column" gap={gap}>
        <ClockBox>
          <span className="clock-text">{secondsStr}</span>
        </ClockBox>
        <span className="label-colored">seconds</span>
      </FlexRow>
    </Layout>
  )
}

const Separator = styled.span`
  color: var(--white-100, #e7e7e7);
  text-align: center;
  /* Headlines/H4 */
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 48px */
  letter-spacing: -1.2px;

  display: flex;
  width: 19px;
  height: 82px;
  flex-direction: column;
  justify-content: center;

  align-self: flex-start;
`

const ClockBox = styled(Box)`
  width: 82px;
  height: 82px;
  display: flex;
  padding: 20.039px 22.341px 20.961px 21.659px;
  justify-content: center;
  align-items: center;
  border-radius: 10.118px;
  background: #031022;
  border: none;
`

const Layout = styled(FlexRow)<{ compact?: boolean }>`
  .clock-text {
    color: var(--white-100, #e7e7e7);
    text-align: center;
    font-family: Inter;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 40.8px */
    letter-spacing: -1.02px;
  }

  .label-colored {
    color: var(--lightblue-50, #a4abf1);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 13.2px */
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  /* scale everything but colored labels */
  ${(props) =>
    props.compact &&
    css`
      ${Separator} {
        font-size: 24px;
        width: 9px;
        height: 48px;
      }
      ${ClockBox} {
        width: 48px;
        height: 48px;
        padding: 10.039px 11.341px 10.961px 11.659px;
        border-radius: 5.118px;
      }

      .clock-text {
        font-size: 24px;
      }
    `}
`
