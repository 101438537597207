import React from 'react'
import { Button, ButtonProps } from '@swell-ui/Button'
import useChainDetection from '@/hooks/useChainDetection'

function SwitchChainButton(props: Omit<ButtonProps, 'children'>) {
  const { switchToDeploymentChain } = useChainDetection()
  const onClick = async () => {
    switchToDeploymentChain()
  }

  return (
    <Button onClick={onClick} {...props}>
      Switch Chain
    </Button>
  )
}

export { SwitchChainButton }
