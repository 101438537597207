import styled from 'styled-components/macro'

const Divider = styled.hr<{
  margin?: string
}>`
  ${({ theme, margin }) => `
    width: 100%;
    border-top: 0px;
    border-color: ${theme.divider.lightColor};
    margin: ${margin + 'px' || '12px'} 0;

    ${theme.breakpoints.up('sm')} {
      margin: ${margin + 'px' || '24px'} 0;
    }
  `}
`

export { Divider }
