import { strip0x } from '@/util/hexStrings'
import { z } from 'zod'

// creating a schema for strings
export const DepositInfoSchema = z.object({
  account: z.string().optional(),
  pubkey: z.string(),
  withdrawal_credentials: z.string(),
  signature: z.string(),
  amount: z.number(),
  deposit_data_root: z.string(),
  deposit_message_root: z.string(),
  fork_version: z.string(),
  name: z.string().optional(),
  version: z.number().optional(),
})

export type DepositInfo = z.infer<typeof DepositInfoSchema>

export type DepositCollection = DepositInfo[]

const hexStringKeys = [
  'pubkey',
  'withdrawal_credentials',
  'signature',
  'deposit_message_root',
  'deposit_data_root',
  'fork_version',
] as const

const nonStringKeys = ['amount'] as const

export const DEPOSIT_INFO_KEYS = {
  hexStringKeys,
  nonStringKeys,
  allKeys: [...hexStringKeys, ...nonStringKeys],
}

// --- alternative representations ---

type DepositInfoAdapter<T> = (deposit: DepositInfo) => T

// To perform crypto math all hex strings must be converted to buffers
export type DepositInfoCryptoFormat = {
  [Key in keyof DepositInfo]: Key extends (typeof DEPOSIT_INFO_KEYS.hexStringKeys)[number]
    ? Buffer
    : DepositInfo[Key]
}

export const depositInfoCryptoFormatAdapter: DepositInfoAdapter<
  DepositInfoCryptoFormat
> = (deposit) => {
  const transformed = { ...deposit } as unknown as DepositInfoCryptoFormat

  hexStringKeys.forEach((key) => {
    transformed[key] = Buffer.from(strip0x(deposit[key]), 'hex') as any
  })

  return transformed
}
