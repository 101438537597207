import fromExponential from 'from-exponential'

function ensureStandardNotationString(input: string) {
  return fromExponential(input.trim())
}

function removeTrailingZeroes(value: string) {
  const num = Number(value)
  if (num === Math.floor(num)) {
    return num.toString()
  }
  return value.replace(/(\d+\.\d+?)0+$/, '$1').replace(/\.$/, '')
}

function getNumDecimalPlaces(value: string): number {
  const decimalIndex = value.indexOf('.')

  if (decimalIndex === -1) {
    // If there is no decimal point, the number has 0 decimal places
    return 0
  }

  // Calculate the number of decimal places by counting digits after the decimal point
  return value.length - decimalIndex - 1
}

export function trimDecimalPlaces(value: string, decimals: number): string {
  const numDecimalPlaces = getNumDecimalPlaces(value)

  if (numDecimalPlaces > decimals) {
    return value.substr(0, value.indexOf('.') + 1 + decimals)
  }

  return value
}

export function formatWithConfig(
  value: number,
  { precision, localize }: { precision?: number; localize?: boolean } = {}
): string {
  let number = value

  if (number >= 1000) {
    number = Math.floor(number)
  } else if (precision || precision === 0) {
    const re = new RegExp(`^-?\\d+(?:\\.\\d{0,${precision}})?`)
    number = +ensureStandardNotationString(`${number}`).match(re)![0]

    if (localize) {
      // localize to english to get proper precision
      const localizedNumber = number.toLocaleString('en', {
        minimumFractionDigits: precision + 3,
      })
      // remove zeroes
      const removedZeroesString = removeTrailingZeroes(
        localizedNumber.substr(0, localizedNumber.length - 3)
      )

      // finally localize to browser language
      return Number(removedZeroesString).toLocaleString(navigator.language, {
        maximumFractionDigits: 4,
      })
    }
  }

  if (localize) {
    return number.toLocaleString(navigator.language)
  }

  return String(number)
}
