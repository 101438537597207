import styled from 'styled-components/macro'
import { KeyValueList } from '@swell-ui/KeyValueList'
import { StakingPoolActivity } from '@/components/StakingPoolActivity'
import {
  useCommissionRate,
  useRestakerCountUsers,
  useTotalEthRestaked,
} from '@/state/restakingStats/hooks'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { useDisplayNativeCurrency } from '@/hooks/useNativeCurrencyDisplay'
import { useRswEthMarketCap } from '@/hooks/useRswEthMarketCap'
import { displayFiat } from '@/util/displayFiat'
import { StakeTransactionType } from '@/submodules/v3-shared/ts/connect/swell/v3/staker_pb'

const ActivityContainer = styled.div`
  margin-top: 24px;
`

export function RestakingStatisticsAccordionDetails() {
  const totalEthRestaked = useTotalEthRestaked()
  const restakerCountUsers = useRestakerCountUsers()

  const commissionRate = useCommissionRate()

  const rswEthMarketCapQuery = useRswEthMarketCap()
  const displayNativeCurrency = useDisplayNativeCurrency()

  const commissionRateConfig = {
    label: 'Commission rate',
    value: (
      <AsyncDiv loading={!commissionRate.data}>
        {() =>
          `${
            commissionRate.data!.nodeOperatorRewardPercentage +
            commissionRate.data!.swellTreasuryRewardPercentage
          }%`
        }
      </AsyncDiv>
    ),
  }

  const totalEthRestakedConfig = {
    label: 'Total ETH restaked',
    value: (
      <AsyncDiv loading={!totalEthRestaked.data}>
        {() => {
          return displayNativeCurrency(totalEthRestaked.data!.value, {
            precision: 0,
            localize: true,
          })
        }}
      </AsyncDiv>
    ),
  }

  const restakersConfig = {
    label: 'Restakers',
    value: (
      <AsyncDiv loading={!restakerCountUsers.data}>
        {() => restakerCountUsers.data!.value}
      </AsyncDiv>
    ),
  }

  const rswEthMarketCapConfig = {
    label: 'rswETH market cap',
    value: (
      <AsyncDiv loading={!rswEthMarketCapQuery.data}>
        {() => {
          const { marketCapUsd } = rswEthMarketCapQuery.data!
          return `${displayFiat(marketCapUsd)} USD`
        }}
      </AsyncDiv>
    ),
  }

  const keyValueListConfig = {
    list: [
      commissionRateConfig,
      totalEthRestakedConfig,
      restakersConfig,
      rswEthMarketCapConfig,
    ],
  }

  return (
    <>
      <KeyValueList config={keyValueListConfig} />
      <ActivityContainer>
        <StakingPoolActivity
          title="Restaking pool activity"
          stakeTransactionType={StakeTransactionType.RESTAKE}
        />
      </ActivityContainer>
    </>
  )
}
