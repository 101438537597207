import { createContext, useContext } from 'react'
import { IZapApiRead, IZapApiWrite } from './types'

const MISSING_PROVIDER = Symbol()

export type ZapContext = {
  read: IZapApiRead
  write: IZapApiWrite
  building: boolean
}
export const ZapContext = createContext<ZapContext | typeof MISSING_PROVIDER>(
  MISSING_PROVIDER
)

export function useZapApi() {
  const ctx = useContext(ZapContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error('ZapContext hooks must be wrapped in a <ZapProvider>')
  }
  return ctx
}
