import React, { forwardRef, useRef } from 'react'
import styled, { css } from 'styled-components'
import { FlexRow } from '@/swell-ui/FlexRow'
import { BorderGradient } from '../BorderGradient'

type DecoBoxProps = React.ComponentProps<typeof FlexRow> & {
  width?: string
  height?: string
  opaque?: boolean
  children?: React.ReactNode
}

// Copied https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx 2024-07-17
function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T> | undefined | null>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        ;(ref as React.MutableRefObject<T | null>).current = value
      }
    })
  }
}

const _DecoBox = (props: DecoBoxProps, ref: React.Ref<HTMLDivElement>) => {
  const boxRef = useRef<HTMLDivElement>(null)
  const mergedRef = mergeRefs([ref, boxRef])

  return (
    <Layout {...props} ref={mergedRef}>
      <BorderGradient
        containerRef={boxRef}
        stops={[
          { offset: '0%', color: 'rgba(255, 255, 255, 0.4)' },
          { offset: '100%', color: 'rgba(255, 255, 255, 0)' },
        ]}
        classNames={{ svg: 'border-gradient' }}
      />
      {props.children}
    </Layout>
  )
}

export const DecoBox = forwardRef(_DecoBox)

const Layout = styled(FlexRow)<DecoBoxProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  flex-direction: ${(props) => props.direction ?? 'column'};

  padding: 24px;
  border-radius: 12px;
  border-width: 0.8px solid transparent;

  position: relative;
  .border-gradient {
    position: absolute;
    inset: 0;
  }

  ${({ opaque }) =>
    opaque
      ? css`
          background: rgba(1, 3, 20, 0.9);
        `
      : css`
          background: rgba(1, 3, 20, 0.4);
        `}
  backdrop-filter: blur(12px);
`
