import { RestakingNodeOperatorRegistry } from '@/abis/types'
import { getValidatorKeyGasEstimate } from '@/constants/gasEstimates'
import { TaskRunnerSerial } from '@/services/TaskRunner/TaskRunnerSerial'
import { DepositInfo } from '@/types/deposits'
import { serializeContractReceipt } from '@/util/transactionSerialization'
import { web3ErrorAdapter } from '@/util/web3ErrorAdapter'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ContractTransaction } from 'ethers'
import { chunkToOnChainValidatorDetails } from './adapters'
import {
  onChunkError,
  onFinishedSubmission,
  onStartedSubmission,
  onTransactionCreated,
  onTransactionReceipt,
  onWalletInteraction,
  onWalletPrompt,
  RestakingDepositSubmissionState,
} from './reducer'
import noop from 'lodash/noop'
import { calculateGasMargin } from '@/util/calculateGasMargin'

// TODO: contains stop-gap fix for cancellation bug in the form of
//  "isSameRun" and "dispatchIfSameRun"
// A better method/architecture should be devised

/**
 * Thunk responsible for submitting keys to the blockchain in chunks.
 *
 * This approach waits for each transaction to resolve before prompting the user
 *  with the next transaction.
 *
 * Detects when the user cancels a run by checking the submission timestamp before
 *  modifying data in the store.
 */
export const batchSubmitAwaitInLoop = createAsyncThunk<
  void,
  {
    chunks: DepositInfo[][]
    nodeOperatorRegistry: RestakingNodeOperatorRegistry
    isGnosis: boolean
  },
  {
    state: { depositSubmission: RestakingDepositSubmissionState }
  }
>(
  'depositSubmission/batchSubmitAwaitInLoop',
  async (
    { chunks, nodeOperatorRegistry, isGnosis },
    { getState, dispatch }
  ) => {
    dispatch(onStartedSubmission(chunks))

    const {
      depositSubmission: { submissionStartTimestamp },
    } = getState()

    const isSameRun = () =>
      getState().depositSubmission.submissionStartTimestamp ===
      submissionStartTimestamp

    const dispatchIfSameRun = (...args: Parameters<typeof dispatch>) => {
      if (isSameRun()) dispatch(...args)
    }

    const {
      addNewValidatorDetails,
      estimateGas,
      interface: iface,
    } = nodeOperatorRegistry

    const taskRunner = TaskRunnerSerial.create()

    const tasks = chunks.map((chunk, chunkIdx) => {
      return async () => {
        if (!isSameRun()) return

        const chunkSize = chunk.length

        dispatchIfSameRun(onWalletPrompt({ chunkIdx }))

        const calldata = chunkToOnChainValidatorDetails(chunk)

        let tx: ContractTransaction | null = null

        try {
          const gasEstimateBN = await estimateGas.addNewValidatorDetails(
            calldata
          )

          const upperEstimateBN = getValidatorKeyGasEstimate(chunkSize)

          // We generally expect the upper estimate to be larger.
          // Just in case, we take the largest of the upper-bound estimate and the true real-time estimate.
          const gasLimit = upperEstimateBN.gt(gasEstimateBN)
            ? upperEstimateBN
            : gasEstimateBN

          if (!isSameRun()) return

          tx = await addNewValidatorDetails(calldata, {
            gasLimit: calculateGasMargin(gasLimit),
          })
        } catch (err: any) {
          const message = web3ErrorAdapter(err, iface)
          console.warn('Error submitting chunk', { err, chunkIdx, message })
          dispatchIfSameRun(onChunkError({ chunkIdx, message }))
        } finally {
          dispatchIfSameRun(onWalletInteraction({ chunkIdx }))
        }

        if (!tx) {
          console.error('No transaction object received', { chunkIdx })
          return
        }
        const { hash: txHash } = tx

        if (!isSameRun()) return

        dispatchIfSameRun(
          onTransactionCreated({
            chunkIdx,
            transaction: { txHash },
          })
        )

        if (isGnosis) return

        await tx.wait().then((receipt) => {
          dispatchIfSameRun(
            onTransactionReceipt({
              chunkIdx,
              receipt: serializeContractReceipt(receipt),
            })
          )
        })
      }
    })

    taskRunner.run(tasks, noop).finally(() => {
      dispatchIfSameRun(onFinishedSubmission())
    })
  }
)
