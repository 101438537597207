import { createContext, useContext } from 'react'
import { IGeoFenceApi } from './types'

const MISSING_PROVIDER = Symbol()

export const GeoFenceContext = createContext<
  IGeoFenceApi | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useGeoFenceApi() {
  const connectors = useContext(GeoFenceContext)
  if (connectors === MISSING_PROVIDER) {
    throw new Error(
      'GeoFenceContext hooks must be wrapped in a <GeoFenceProvider>'
    )
  }
  return connectors
}
