import {
  YearnApproveAssetForDeposit,
  YearnApproveVaultTokenForWithdraw,
  YearnCancelWithdraw,
  YearnCompleteWithdraw,
  YearnDeposit,
  YearnRequestWithdraw,
} from '@/state/yearnVault/hooks'
import {
  YearnAllowances,
  YearnAssets,
  YearnAuthResult,
  YearnBalances,
  YearnPausedResult,
  YearnTokenRates,
  YearnVaultStats,
  YearnWithdrawRequestResult,
} from '@/state/yearnVault/types'
import {
  YearnErrors,
  prepareYearnApproveAssetForDeposit,
  prepareYearnApproveVaultTokenForWithdraw,
  prepareYearnCancelWithdraw,
  prepareYearnCompleteWithdraw,
  prepareYearnDeposit,
  prepareYearnRequestWithdraw,
} from './yearn/yearnCalls'
import { useEffect, useState } from 'react'
import { Token } from '@/types/tokens'
import { ACTIONS } from './constants'
import {
  useYearnActiveWithdrawal,
  useYearnMaxLossSettings,
} from './yearn/yearnHooks'
import { parseUnits } from 'ethers/lib/utils'
import { BigNumber } from 'ethers'
import { trimDecimalPlaces } from '@/util/number'
import { useSwellWeb3 } from '@/swell-web3/core'
import {
  YearnApproveAssetForDepositButton,
  YearnApproveVaultTokenForWithdrawButton,
  YearnCancelWithdrawButton,
  YearnCompleteWithdrawButton,
  YearnConnectButton,
  YearnDepositButton,
  YearnRequestWithdrawButton,
} from './yearn/YearnButtons'
import { YearnWithdrawalStatus } from './yearn/yearnWithdraw'
import {
  yearnDepositAssetUsdLabel,
  yearnDepositReceiveVaultTokenUsdLabel,
  yearnWithdrawReceiveAssetUsdLabel,
  yearnWithdrawVaultTokenUsdLabel,
} from './yearn/YearnUsd'
import { useEthUsdMarketRate } from '@/state/fiat/hooks'
import { AvailableChip } from './AvailableChip'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Typography } from '@/swell-ui/Typography'
import { TokenLogo } from '../TokenLogo'
import styled from 'styled-components'
import { Box } from '@/swell-ui/Box'
import { css } from 'styled-components'
import {
  DepositAssetInput,
  DepositReceiveVaultTokenInput,
  WithdrawReceiveAssetInput,
  WithdrawVaultTokenInput,
} from './yearn/YearnInputs'
import { Divider } from '@/swell-ui/Divider'
import { YearnDepositExchangeInfo } from './ExchangeInfo/YearnDepositExchangeInfo'
import { YearnWithdrawPending } from './yearn/YearnWithdraw/YearnWithdrawPending'
import { YearnWithdrawExpired } from './yearn/YearnWithdraw/YearnWithdrawExpired'
import { YearnWithdrawClaimable } from './yearn/YearnWithdraw/YearnWithdrawClaimable'
import { YearnRequestWithdrawExchangeInfo } from './ExchangeInfo/YearnRequestWithdrawExchangeInfo'
import { ActionChooser } from './ActionChooser'
import {
  YearnApproveAssetForDepositToast,
  YearnApproveVaultTokenForWithdrawToast,
  YearnCancelWithdrawToast,
  YearnCompleteWithdrawToast,
  YearnDepositToast,
  YearnRequestWithdrawToast,
} from './yearn/YearnToasts'
import { useMediaQuery } from '@mui/material'

export function YearnVaultWidget({
  allowances,
  auth,
  balances,
  paused,
  rates,
  vaultStats,
  approveAssetForDeposit,
  approveVaultTokenForWithdraw,
  cancelWithdraw,
  completeWithdraw,
  deposit,
  assets,
  requestWithdraw,
  defaultDepositAsset,
  defaultWithdrawAsset,
  vaultToken,
  withdrawRequest,
}: {
  defaultDepositAsset: Token
  defaultWithdrawAsset: Token
  vaultToken: Token

  assets: YearnAssets | undefined
  allowances: YearnAllowances | undefined
  balances: YearnBalances | undefined
  auth: YearnAuthResult | undefined
  paused: YearnPausedResult | undefined
  withdrawRequest: YearnWithdrawRequestResult | undefined
  rates: YearnTokenRates | undefined
  vaultStats: YearnVaultStats | undefined

  // web3 calls
  deposit: YearnDeposit
  approveAssetForDeposit: YearnApproveAssetForDeposit
  requestWithdraw: YearnRequestWithdraw
  approveVaultTokenForWithdraw: YearnApproveVaultTokenForWithdraw
  cancelWithdraw: YearnCancelWithdraw
  completeWithdraw: YearnCompleteWithdraw
}) {
  const isDesktop = useMediaQuery('(min-width: 1391px)')

  const ethUsdMarketRate = useEthUsdMarketRate().data?.rate

  const [touched, setTouched] = useState<boolean>(false)
  const [srcTokenInputValue, setSrcTokenInputValue] = useState('')
  const [destTokenInputValue, setDestTokenInputValue] = useState('')
  const [srcToken, setSrcToken] = useState<Token>(defaultDepositAsset)
  const [destToken, setDestToken] = useState<Token>(vaultToken)
  const [action, setAction] = useState(ACTIONS.VAULT)
  const isDeposit = () => action === ACTIONS.VAULT
  const isWithdraw = () => action === ACTIONS.WITHDRAW

  const { maxLossBasisPoints, overrideMaxLoss } = useYearnMaxLossSettings()
  const activeWithdrawal = useYearnActiveWithdrawal({
    withdrawAsset: assets?.withdrawAsset,
    withdrawRequest,
  })

  const switchToDeposit = () => {
    setTouched(false)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setSrcToken(defaultDepositAsset)
    setDestToken(vaultToken)
    setAction(ACTIONS.VAULT)
  }
  const switchToWithdraw = () => {
    setTouched(false)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setSrcToken(vaultToken)
    setDestToken(defaultWithdrawAsset)
    setAction(ACTIONS.WITHDRAW)
  }

  useEffect(() => {
    if (requestWithdraw.status === requestWithdraw.STATUS.FULFILLED) {
      setTouched(false)
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
    }
  }, [requestWithdraw])
  useEffect(() => {
    if (deposit.status === deposit.STATUS.FULFILLED) {
      setTouched(false)
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
    }
  }, [deposit])

  let fromAmount: BigNumber | undefined
  if (srcTokenInputValue) {
    fromAmount = parseUnits(
      trimDecimalPlaces(srcTokenInputValue, srcToken.decimals),
      srcToken.decimals
    )
  }
  let toAmount: BigNumber | undefined
  if (destTokenInputValue) {
    toAmount = parseUnits(
      trimDecimalPlaces(destTokenInputValue, destToken.decimals),
      destToken.decimals
    )
  }

  const tokenBalances = [
    { ...defaultDepositAsset, balance: balances?.depositAsset },
    { ...vaultToken, balance: balances?.vaultToken },
  ]

  const preparedDeposit = prepareYearnDeposit({
    assetBalance: balances?.depositAsset,
    assetAllowance: allowances?.depositAssetForVault,
    assetAmount: fromAmount,
    auth,
    paused,
  })
  const preparedApproveAssetForDeposit = prepareYearnApproveAssetForDeposit({
    assetAmount: fromAmount,
    assetBalance: balances?.depositAsset,
  })
  const preparedRequestWithdraw = prepareYearnRequestWithdraw({
    activeWithdrawal: activeWithdrawal,
    maxLossBasisPoints,
    overrideMaxLoss,
    shares: fromAmount,
    vaultTokenAllowanceForWithdraw: allowances?.vaultTokenForWithdrawals,
    auth,
    paused,
    vaultTokenBalance: balances?.vaultToken,
    withdrawAsset: assets?.withdrawAsset,
  })
  const preparedApproveVaultTokenForWithdraw =
    prepareYearnApproveVaultTokenForWithdraw({
      vaultTokenAmount: fromAmount,
      vaultTokenBalance: balances?.vaultToken,
    })
  const preparedCancelWithdraw = prepareYearnCancelWithdraw({
    activeWithdrawal,
    auth,
    paused,
  })
  const preparedCompleteWithdraw = prepareYearnCompleteWithdraw({
    activeWithdrawal,
    auth,
    paused,
  })

  const { account } = useSwellWeb3()

  const mustApproveDeposit = () => {
    if (!isDeposit()) return false
    return preparedDeposit.error === YearnErrors.InsufficientAllowance
  }
  const mustApproveWithdraw = () => {
    if (!isWithdraw()) return false
    return preparedRequestWithdraw.error === YearnErrors.InsufficientAllowance
  }

  function depositErrorMessage() {
    if (!touched) return null
    if (mustApproveDeposit()) {
      return preparedApproveAssetForDeposit.error ?? null
    }
    return preparedDeposit.error ?? null
  }

  function withdrawErrorMessage() {
    if (!touched) return null
    if (mustApproveWithdraw()) {
      return preparedApproveVaultTokenForWithdraw.error ?? null
    }
    return preparedRequestWithdraw.error ?? null
  }

  const handleActionClick = (a: string) => {
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    if (a === ACTIONS.VAULT) {
      switchToDeposit()
    }
    if (a === ACTIONS.WITHDRAW) {
      switchToWithdraw()
    }
  }

  const preventInteraction = () => {
    if (account) {
      if (isDeposit()) {
        if (!balances) return true
      }
      if (isWithdraw()) {
        if (!balances) return true
        if (!activeWithdrawal) return true
      }
    }
    if (!rates) return true
    return false
  }

  const renderButton = () => {
    if (!account) {
      return <YearnConnectButton>Connect Wallet</YearnConnectButton>
    }

    if (isDeposit()) {
      if (mustApproveDeposit()) {
        return (
          <YearnApproveAssetForDepositButton
            approveAssetForDeposit={approveAssetForDeposit}
            prepared={preparedApproveAssetForDeposit}
          />
        )
      }
      return (
        <YearnDepositButton
          deposit={deposit}
          prepared={preparedDeposit}
          preventInteraction={preventInteraction()}
        />
      )
    }

    if (isWithdraw()) {
      if (activeWithdrawal?.status === YearnWithdrawalStatus.Requesting) {
        return (
          <YearnCancelWithdrawButton
            cancelWithdraw={cancelWithdraw}
            prepared={preparedCancelWithdraw}
            preventInteraction={preventInteraction()}
          />
        )
      }
      if (activeWithdrawal?.status === YearnWithdrawalStatus.Claimable) {
        return (
          <YearnCompleteWithdrawButton
            completeWithdraw={completeWithdraw}
            prepared={preparedCompleteWithdraw}
            preventInteraction={preventInteraction()}
          />
        )
      }

      if (activeWithdrawal?.status === YearnWithdrawalStatus.Expired) {
        return (
          <YearnCancelWithdrawButton
            cancelWithdraw={cancelWithdraw}
            prepared={preparedCancelWithdraw}
            preventInteraction={preventInteraction()}
          />
        )
      }

      if (mustApproveWithdraw()) {
        return (
          <YearnApproveVaultTokenForWithdrawButton
            approveVaultTokenForWithdraw={approveVaultTokenForWithdraw}
            prepared={preparedApproveVaultTokenForWithdraw}
            preventInteraction={preventInteraction()}
          />
        )
      }
      return (
        <YearnRequestWithdrawButton
          requestWithdraw={requestWithdraw}
          prepared={preparedRequestWithdraw}
          preventInteraction={preventInteraction()}
        />
      )
    }

    console.error('button', { action })
    return null
  }

  const renderDepositView = () => {
    const topUsdLabel = yearnDepositAssetUsdLabel({
      depositAssetAmount: fromAmount,
      depositAssetEthRate: rates?.depositAssetEthRate,
      ethUsdMarketRate,
      depositAssetDecimals: defaultDepositAsset.decimals,
    })
    const bottomUsdLabel = yearnDepositReceiveVaultTokenUsdLabel({
      depositAssetEthRate: rates?.depositAssetEthRate,
      ethUsdMarketRate,
      pricePerShare: rates?.pricePerShare,
      vaultTokenAmount: toAmount,
      vaultTokenDecimals: vaultToken.decimals,
      depositAssetDecimals: defaultDepositAsset.decimals,
    })

    return (
      <>
        <AvailableChip token={srcToken} tokenBalances={tokenBalances} />
        <div>
          <FlexRow justify="space-between" align="center">
            <Typography variant="body" size="xlarge" fstyle="bold">
              Deposit
            </Typography>
            <SymbolWrapper>
              <StyledTokenLogo size={35} token={defaultDepositAsset} />
              <Typography variant="body" size="large" fstyle="bold">
                {defaultDepositAsset.symbol}
              </Typography>
            </SymbolWrapper>
          </FlexRow>
          <EthInputWrapper>
            <DepositAssetInput
              setTouched={setTouched}
              srcTokenInputValue={srcTokenInputValue}
              setSrcTokenInputValue={setSrcTokenInputValue}
              setDestTokenInputValue={setDestTokenInputValue}
              errorMessage={depositErrorMessage()}
              disabled={preventInteraction()}
              rates={rates}
              balances={balances}
              depositAsset={defaultDepositAsset}
              vaultToken={vaultToken}
            />
          </EthInputWrapper>
          {!depositErrorMessage() && topUsdLabel && (
            <UsdTypography variant="body" size="xsmall">
              {topUsdLabel}
            </UsdTypography>
          )}
          {!depositErrorMessage() && !topUsdLabel && (
            <HiddenTypography variant="body" size="xsmall">
              $0.00
            </HiddenTypography>
          )}
          <FlexRow justify="space-between" align="center">
            <Typography variant="body" size="xlarge" fstyle="bold">
              Receive
            </Typography>
            <SymbolWrapper>
              <StyledTokenLogo size={35} token={vaultToken} />
              <Typography
                variant="headline"
                size="h5"
                fstyle="bold"
                letterSpacing="small"
              >
                {vaultToken.symbol}
              </Typography>
            </SymbolWrapper>
          </FlexRow>
          <EthInputWrapper>
            <DepositReceiveVaultTokenInput
              setTouched={setTouched}
              destTokenInputValue={destTokenInputValue}
              setSrcTokenInputValue={setSrcTokenInputValue}
              setDestTokenInputValue={setDestTokenInputValue}
              disabled={preventInteraction()}
              rates={rates}
              depositAsset={defaultDepositAsset}
              vaultToken={vaultToken}
            />
          </EthInputWrapper>
          {bottomUsdLabel && (
            <UsdTypography variant="body" size="xsmall">
              {bottomUsdLabel}
            </UsdTypography>
          )}
          {!bottomUsdLabel && (
            <HiddenTypography variant="body" size="xsmall">
              $0.00
            </HiddenTypography>
          )}
        </div>
        <Divider />
        <YearnDepositExchangeInfo
          apyPercent={vaultStats?.apyPercent}
          depositAsset={defaultDepositAsset}
          pricePerShare={rates?.pricePerShare}
          vaultToken={vaultToken}
        />
      </>
    )
  }

  const renderWithdrawView = () => {
    if (activeWithdrawal?.status === YearnWithdrawalStatus.Requesting) {
      return (
        <YearnWithdrawPending
          vaultToken={vaultToken}
          withdrawAsset={assets?.withdrawAsset}
          withdrawAssetToken={defaultWithdrawAsset}
          withdrawRequest={activeWithdrawal.request}
          pricePerShare={rates?.pricePerShare}
        />
      )
    }
    if (activeWithdrawal?.status === YearnWithdrawalStatus.Expired) {
      return <YearnWithdrawExpired withdrawAsset={assets?.withdrawAsset} />
    }
    if (activeWithdrawal?.status === YearnWithdrawalStatus.Claimable) {
      return (
        <YearnWithdrawClaimable
          depositAsset={defaultDepositAsset}
          withdrawRequest={activeWithdrawal.request}
          pricePerShare={rates?.pricePerShare}
          vaultToken={vaultToken}
          withdrawAsset={assets?.withdrawAsset}
        />
      )
    }

    const topUsdLabel = yearnWithdrawVaultTokenUsdLabel({
      ethUsdMarketRate,
      withdrawVaultTokenAmount: fromAmount,
      depositAssetEthRate: rates?.depositAssetEthRate,
      pricePerShare: rates?.pricePerShare,
      vaultTokenDecimals: vaultToken.decimals,
      depositAssetDecimals: defaultDepositAsset.decimals,
    })
    const bottomUsdLabel = yearnWithdrawReceiveAssetUsdLabel({
      ethUsdMarketRate,
      depositAssetEthRate: rates?.depositAssetEthRate,
      receiveAssetAmount: toAmount,
      depositAssetDecimals: defaultDepositAsset.decimals,
    })

    const errorMessage = withdrawErrorMessage()
    return (
      <>
        <AvailableChip token={srcToken} tokenBalances={tokenBalances} />
        <div>
          <FlexRow justify="space-between" align="center">
            <Typography variant="body" size="xlarge" fstyle="bold">
              Withdraw
            </Typography>
            <SymbolWrapper>
              <StyledTokenLogo size={35} token={vaultToken} />
              <Typography variant="body" size="large" fstyle="bold">
                {vaultToken.symbol}
              </Typography>
            </SymbolWrapper>
          </FlexRow>
          <EthInputWrapper>
            <WithdrawVaultTokenInput
              setTouched={setTouched}
              srcTokenInputValue={srcTokenInputValue}
              setSrcTokenInputValue={setSrcTokenInputValue}
              setDestTokenInputValue={setDestTokenInputValue}
              errorMessage={errorMessage}
              disabled={preventInteraction()}
              balances={balances}
              maxLossBasisPoints={maxLossBasisPoints}
              rates={rates}
              vaultToken={vaultToken}
              withdrawAsset={assets?.withdrawAsset}
            />
          </EthInputWrapper>
          {!errorMessage && topUsdLabel && (
            <UsdTypography variant="body" size="xsmall">
              {topUsdLabel}
            </UsdTypography>
          )}
          {!errorMessage && !topUsdLabel && (
            <HiddenTypography variant="body" size="xsmall">
              $0.00
            </HiddenTypography>
          )}
          <FlexRow justify="space-between" align="center">
            <Typography variant="body" size="xlarge" fstyle="bold">
              Receive
            </Typography>
            <SymbolWrapper>
              <StyledTokenLogo size={35} token={defaultWithdrawAsset} />
              <Typography variant="body" size="large" fstyle="bold">
                {defaultWithdrawAsset.symbol}
              </Typography>
            </SymbolWrapper>
          </FlexRow>
          <EthInputWrapper>
            <WithdrawReceiveAssetInput
              setTouched={setTouched}
              destTokenInputValue={destTokenInputValue}
              setSrcTokenInputValue={setSrcTokenInputValue}
              setDestTokenInputValue={setDestTokenInputValue}
              disabled={preventInteraction()}
              rates={rates}
              vaultToken={vaultToken}
              withdrawAsset={assets?.withdrawAsset}
            />
          </EthInputWrapper>
          {bottomUsdLabel && (
            <UsdTypography variant="body" size="xsmall">
              {bottomUsdLabel}
            </UsdTypography>
          )}
          {!bottomUsdLabel && (
            <HiddenTypography variant="body" size="xsmall">
              $0.00
            </HiddenTypography>
          )}
        </div>
        <Divider />
        <YearnRequestWithdrawExchangeInfo
          withdrawAsset={assets?.withdrawAsset}
        />
      </>
    )
  }

  const anyTransactionInProgress = [
    deposit,
    approveAssetForDeposit,
    requestWithdraw,
    approveVaultTokenForWithdraw,
    cancelWithdraw,
    completeWithdraw,
  ].some(
    (x) => x.status === x.STATUS.PENDING || x.status === x.STATUS.PROMPTING
  )

  return (
    <StakingWidgetBox tall={isDesktop}>
      <ActionChooser
        actions={[ACTIONS.VAULT, ACTIONS.WITHDRAW]}
        onActionClick={handleActionClick}
        defaultAction={ACTIONS.VAULT}
      />
      {isDeposit() && renderDepositView()}
      {isWithdraw() && renderWithdrawView()}
      <ButtonWrapper>{renderButton()}</ButtonWrapper>

      <YearnApproveAssetForDepositToast
        approveAssetForDeposit={approveAssetForDeposit}
        anyTransactionInProgress={anyTransactionInProgress}
        depositAsset={defaultDepositAsset}
      />
      <YearnApproveVaultTokenForWithdrawToast
        approveVaultTokenForWithdraw={approveVaultTokenForWithdraw}
        anyTransactionInProgress={anyTransactionInProgress}
        vaultToken={vaultToken}
      />
      <YearnDepositToast
        deposit={deposit}
        anyTransactionInProgress={anyTransactionInProgress}
        depositAsset={defaultDepositAsset}
        rates={rates}
        vaultToken={vaultToken}
      />
      <YearnRequestWithdrawToast
        requestWithdraw={requestWithdraw}
        anyTransactionInProgress={anyTransactionInProgress}
        vaultToken={vaultToken}
        rates={rates}
        withdrawAsset={assets?.withdrawAsset}
      />
      <YearnCancelWithdrawToast
        cancelWithdraw={cancelWithdraw}
        anyTransactionInProgress={anyTransactionInProgress}
        withdrawAsset={defaultWithdrawAsset}
      />
      <YearnCompleteWithdrawToast
        completeWithdraw={completeWithdraw}
        anyTransactionInProgress={anyTransactionInProgress}
        withdrawAsset={defaultWithdrawAsset}
      />
    </StakingWidgetBox>
  )
}

const SymbolWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.white['50']};
`
const EthInputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  .MuiFormControl-root {
    width: 100%;
  }

  input,
  div > div > p {
    font-size: ${({ theme }) => theme.typography.body.large.fontSize};
    font-weight: 600;
  }
`
const StyledTokenLogo = styled(TokenLogo)``
const UsdTypography = styled(Typography)`
  height: 19.2px;
  color: ${({ theme }) => theme.fiatColor};
  /* opacity: 0.6; */
  margin-bottom: 16px;
  margin-top: 2px;
  letter-spacing: -0.03em;
`
const HiddenTypography = styled(UsdTypography)`
  visibility: hidden;
`

const ButtonWrapper = styled(FlexRow)`
  margin-top: auto;
`

const StakingWidgetBox = styled(Box)<{ tall: boolean }>`
  min-height: ${({ tall }) => (tall ? '691px' : '')};
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
    padding: 24px 24px 12px;

    > div {
      margin-bottom: 12px;
    }

    ${theme.breakpoints.up('sm')} {
      width: 460px;
      padding: 24px 32px 12px;


      > div {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 12px;
        }
      }
    }}
  `}

  .Mui-disabled.MuiInputBase-root:before {
    border-bottom-style: solid;
    opacity: 0.6;
  }
`
