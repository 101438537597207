import React from 'react'
import styled from 'styled-components'
import { Button } from '@swell-ui/Button'
import { Chip } from '@swell-ui/Chip'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { VirtualizedTable } from '@swell-ui/VirtualizedTable'
import { FormattedEthBalance } from '@/components/FormattedEthBalance'
import { TokenLogo } from '@/components/TokenLogo'
import { displayCrypto } from '@/util/displayCrypto'
import { Token } from '@/types/tokens'

interface TokenListSectionProps {
  tokens: any
  onTokenSelect: (token: any) => void
  importableTokens: any
  onImportableTokenSelect: (token: any) => void
}

const TokenRow = styled(FlexRow)`
  height: 62px;
  border-bottom: 1px solid rgba(66, 69, 87, 0.5);

  &:hover {
    background: ${({ theme }) => theme.tokenList.rowHoverColor};
    cursor: pointer;
  }
`

const NameTypography = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SymbolTypography = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white['150']};
  line-height: 9px;
`

const ListNameChip = styled(Chip)`
  letter-spacing: -0.03em;
  &.MuiChip-filled {
    color: ${({ theme }) => theme.colors.white['125']};
  }
`

const BalanceDiv = styled.div`
  padding-right: 12px;
`

function TokenListSection({
  tokens,
  onTokenSelect,
  importableTokens,
  onImportableTokenSelect,
}: TokenListSectionProps) {
  const tokensToRender = tokens.concat(importableTokens)

  const handleImportClick = (e: React.MouseEvent, token: Token) => {
    e.stopPropagation()
    onImportableTokenSelect(token)
  }

  return (
    <VirtualizedTable
      style={{ height: 305 }}
      totalCount={tokensToRender.length}
      itemContent={(index: number) => {
        const token = tokensToRender[index]
        const isEth = token.symbol === 'ETH'
        return (
          <TokenRow
            key={token.symbol}
            justify="space-between"
            align="center"
            onClick={() => onTokenSelect(token)}
          >
            <FlexRow align="center" gap="8">
              <TokenLogo token={token} size={30} />
              <div>
                <NameTypography
                  variant="body"
                  size="medium"
                  letterSpacing="small"
                >
                  {token.name}
                </NameTypography>
                <SymbolTypography
                  variant="body"
                  size="xsmall"
                  letterSpacing="small"
                >
                  {token.symbol}
                </SymbolTypography>
              </div>
              {token.importable && (
                <ListNameChip variant="filled" label="Coingecko" />
              )}
            </FlexRow>
            <FlexRow justify="flex-end" align="center">
              {token.importable && (
                <Button
                  variant="secondary"
                  size="small"
                  onClick={(e: React.MouseEvent) => handleImportClick(e, token)}
                >
                  Import
                </Button>
              )}
              {!token.importable && (
                <BalanceDiv>
                  {token.balance &&
                    displayCrypto(token.balance, token.decimals, {
                      precision: 4,
                      localize: false,
                    })}
                  {!token.balance && isEth && (
                    <FormattedEthBalance numberOnly />
                  )}
                  {!token.balance && !isEth && '0'}
                </BalanceDiv>
              )}
            </FlexRow>
          </TokenRow>
        )
      }}
    />
  )
}

export { TokenListSection }
