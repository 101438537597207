import React from 'react'

function WarningIcon(props: any) {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 13V18"
        stroke="white"
        strokeWidth="1.33"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M14.2758 5.00007L3.27577 24.0001C3.10053 24.3036 3.00812 24.6478 3.00781 24.9983C3.0075 25.3488 3.0993 25.6932 3.274 25.997C3.4487 26.3009 3.70018 26.5534 4.00324 26.7295C4.3063 26.9055 4.65029 26.9988 5.00077 27.0001H27.0008C27.3512 26.9988 27.6952 26.9055 27.9983 26.7295C28.3014 26.5534 28.5528 26.3009 28.7275 25.997C28.9022 25.6932 28.994 25.3488 28.9937 24.9983C28.9934 24.6478 28.901 24.3036 28.7258 24.0001L17.7258 5.00007C17.5518 4.69616 17.3007 4.44359 16.9978 4.26793C16.6949 4.09227 16.3509 3.99976 16.0008 3.99976C15.6506 3.99976 15.3067 4.09227 15.0037 4.26793C14.7008 4.44359 14.4497 4.69616 14.2758 5.00007Z"
        stroke="white"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 23C16.5523 23 17 22.5523 17 22C17 21.4477 16.5523 21 16 21C15.4477 21 15 21.4477 15 22C15 22.5523 15.4477 23 16 23Z"
        fill="white"
      />
    </svg>
  )
}

export { WarningIcon }
