import { useMemo } from 'react'
import { useConvertNativeCurrencyToSwEth } from './useCurrencyConversions'
import { useDisplayNativeCurrency } from './useNativeCurrencyDisplay'
import { useStakeLens } from './useStakeLens'
import { useDisplaySwEth } from './useSwEthDisplay'
import { BigNumber } from 'ethers'
import { bigNumberRateToFloat } from '@/util/big'
import { parseEther } from 'ethers/lib/utils'

/**
 * Prepares the necessary display strings for the conversion that will occur during the staking workflow.
 * This is purely informational / read-only. The strings are formatted and include the token symbol.
 *
 * These strings are:
 * - swEthAmountDisplayStr     - The amount of swETH that will be received (e.g. "1.05 swETH")
 * - nativeCurrencyDisplayStr  - The amount of native currency that will be staked (e.g. "1.05 goETH")
 *
 * In the case where the information required to construct these strings is not present, the strings will be undefined.
 */
export const useStakingDisplayStrings = (
  swETHToETHRate: BigNumber | undefined
) => {
  const { nativeCurrencyAmount } = useStakeLens()

  const displaySwEth = useDisplaySwEth()
  const displayNativeCurrency = useDisplayNativeCurrency()
  const convertNativeCurrencyToSwEth = useConvertNativeCurrencyToSwEth()

  let nativeToSwEthRate: number | undefined = undefined
  if (swETHToETHRate) {
    nativeToSwEthRate = bigNumberRateToFloat(
      parseEther('1').mul(parseEther('1')).div(swETHToETHRate)
    )
  }

  return useMemo(() => {
    if (!nativeCurrencyAmount || !nativeToSwEthRate) {
      return {
        swEthAmountDisplayStr: undefined,
        nativeCurrencyDisplayStr: undefined,
      }
    }

    const swEthAmount = convertNativeCurrencyToSwEth(
      nativeCurrencyAmount,
      nativeToSwEthRate
    )

    const swEthAmountDisplayStr = displaySwEth(swEthAmount)
    const nativeCurrencyDisplayStr = displayNativeCurrency(nativeCurrencyAmount)

    return {
      swEthAmountDisplayStr,
      nativeCurrencyDisplayStr,
    }
  }, [
    convertNativeCurrencyToSwEth,
    displayNativeCurrency,
    displaySwEth,
    nativeCurrencyAmount,
    nativeToSwEthRate,
  ])
}
