import React, { ReactNode, useCallback } from 'react'
import styled from 'styled-components/macro'
import { Box } from '@swell-ui/Box'
import { Grid } from '@swell-ui/Grid'
import { Table, TableConfig } from '@swell-ui/Table'
import { shortenHash, strip0x } from '@/util/hexStrings'
import { SectionBoxLabel } from '@/components/SectionBoxLabel'
import { IDepositCollectionValidationResult } from '@/state/depositValidation/models/DepositCollectionValidationResult'
import {
  DepositProblemCode,
  depositProblemToMessage,
} from '@/state/depositValidation/models/DepositProblem'

type RowType = {
  pubkey?: string
  code: ReactNode
  description: ReactNode
}

type OnRowClick = NonNullable<TableConfig['onRowClick']>

interface DepositCollectionValidationDetailProps {
  validationResult?: IDepositCollectionValidationResult
  textAreaRef: React.RefObject<HTMLTextAreaElement>
}

const ContainerBox = styled(Box)`
  padding: 38px;
`

const columns: TableConfig['columns'] = [
  {
    key: 'pubkey',
    label: 'Pub key',
  },
  {
    key: 'code',
    label: 'Error type',
  },
  {
    key: 'description',
    label: 'Description',
  },
]
const ValidationDetailTable = styled(Table)`
  .MuiTableRow-head {
    .MuiTableCell-root {
      color: ${({ theme }) => theme.table.head.secondaryColor};
    }
  }

  .pubkey-cell {
    padding-left: 0;
  }
`

const KEY_LENGTH = 96
const WITHDRAWAL_CREDENTIALS_LENGTH = 66
const DEPOSIT_DATA_ROOT_LENGTH = 66
const SIGNATURE_LENGTH = 194
const FORK_VERSION_LENGTH = 10

const buildRows = (
  validationResult: IDepositCollectionValidationResult
): RowType[] => {
  const rows: RowType[] = []

  // build the list of problems to display as rows
  // "input problems" (i.e. those relating to the entirety of the data and not
  //  a single public key) are displayed first
  if (validationResult.inputProblem) {
    const { code } = validationResult.inputProblem

    rows.push({
      pubkey: '',
      code,
      description: depositProblemToMessage(validationResult.inputProblem),
    })
  }

  for (const problem of validationResult.dataProblems ?? []) {
    rows.push({
      pubkey:
        typeof problem.pubkey === 'string'
          ? shortenHash(problem.pubkey)
          : 'Missing',
      code: problem.code,
      description: depositProblemToMessage(problem),
    })
  }

  return rows
}

function DepositCollectionValidationDetail({
  validationResult,
  textAreaRef,
}: DepositCollectionValidationDetailProps) {
  const focusPubKeyForRowInTextArea: OnRowClick = useCallback(
    (row) => {
      if (!textAreaRef?.current) {
        return
      }

      if (!row?.pubkey) return
      const code = row.code as DepositProblemCode

      const elem = textAreaRef.current
      let selectionStartIndex = elem.value.indexOf(
        strip0x(row.pubkey).slice(0, 4)
      )
      let selectionLength = KEY_LENGTH

      // some errors highlight different parts of the row
      if (code === 'incorrect-withdrawal-credentials') {
        // find the start of the withdrawal credentials
        let valueSliced = elem.value.slice(selectionStartIndex)
        const objEndIndex = valueSliced.indexOf('}')
        if (objEndIndex === -1) {
          return
        }
        valueSliced = valueSliced.slice(0, objEndIndex + 1)
        const withdrawCredentialsLabel = 'withdrawal_credentials'
        const withdrawalCredentialsLabelIndex = valueSliced.indexOf(
          withdrawCredentialsLabel
        )
        if (withdrawalCredentialsLabelIndex === -1) {
          return
        }
        valueSliced = valueSliced.slice(withdrawalCredentialsLabelIndex)
        let hexStartIndex = valueSliced.indexOf('0x')
        if (hexStartIndex === -1) {
          hexStartIndex = valueSliced.indexOf('0')
        }
        if (hexStartIndex === -1) {
          return
        }
        selectionStartIndex += withdrawalCredentialsLabelIndex + hexStartIndex
        selectionLength = WITHDRAWAL_CREDENTIALS_LENGTH
      }
      if (code === 'signature-fail') {
        // find the start of the signature
        let valueSliced = elem.value.slice(selectionStartIndex)
        const objEndIndex = valueSliced.indexOf('}')
        if (objEndIndex === -1) {
          return
        }
        valueSliced = valueSliced.slice(0, objEndIndex + 1)
        const signatureLabel = 'signature'
        const signatureLabelIndex = valueSliced.indexOf(signatureLabel)
        if (signatureLabelIndex === -1) {
          return
        }
        valueSliced = valueSliced.slice(signatureLabelIndex)
        let hexStartIndex = valueSliced.indexOf('0x')
        if (hexStartIndex === -1) {
          hexStartIndex = valueSliced.indexOf('0')
        }
        if (hexStartIndex === -1) {
          return
        }
        selectionStartIndex += signatureLabelIndex + hexStartIndex
        selectionLength = SIGNATURE_LENGTH
      }
      if (code === 'deposit-roots-fail') {
        // find the start of the deposit root
        let valueSliced = elem.value.slice(selectionStartIndex)
        const objEndIndex = valueSliced.indexOf('}')
        if (objEndIndex === -1) {
          return
        }
        valueSliced = valueSliced.slice(0, objEndIndex + 1)
        const depositRootLabel = 'deposit_data_root'
        const depositRootLabelIndex = valueSliced.indexOf(depositRootLabel)
        if (depositRootLabelIndex === -1) {
          return
        }
        valueSliced = valueSliced.slice(depositRootLabelIndex)
        let hexStartIndex = valueSliced.indexOf('0x')
        if (hexStartIndex === -1) {
          hexStartIndex = valueSliced.indexOf('0')
        }
        if (hexStartIndex === -1) {
          return
        }
        selectionStartIndex += depositRootLabelIndex + hexStartIndex
        selectionLength = DEPOSIT_DATA_ROOT_LENGTH
      }
      if (code === 'incorrect-fork-version') {
        // find the start of the fork version
        let valueSliced = elem.value.slice(selectionStartIndex)
        const objEndIndex = valueSliced.indexOf('}')
        if (objEndIndex === -1) {
          return
        }
        valueSliced = valueSliced.slice(0, objEndIndex + 1)
        const forkVersionLabel = 'fork_version'
        const forkVersionLabelIndex = valueSliced.indexOf(forkVersionLabel)
        if (forkVersionLabelIndex === -1) {
          return
        }
        valueSliced = valueSliced.slice(forkVersionLabelIndex)
        let hexStartIndex = valueSliced.indexOf('0x')
        if (hexStartIndex === -1) {
          hexStartIndex = valueSliced.indexOf('0')
        }
        if (hexStartIndex === -1) {
          return
        }
        selectionStartIndex += forkVersionLabelIndex + hexStartIndex
        selectionLength = FORK_VERSION_LENGTH
      }

      if (selectionStartIndex === -1) {
        return
      }

      // check whether the associated input included 0x to decide the selection range
      const has0x = textAreaRef.current.value[selectionStartIndex - 1] === 'x'
      if (has0x) {
        selectionStartIndex -= 2
        selectionLength += 2
      } else {
        selectionLength -= 2
      }

      elem.selectionStart = elem.selectionEnd = selectionStartIndex
      elem.blur()
      elem.focus()
      elem.setSelectionRange(
        selectionStartIndex,
        selectionStartIndex + selectionLength
      )
    },
    [textAreaRef]
  )

  const config = React.useMemo<TableConfig>(() => {
    if (!validationResult || validationResult.valid)
      return {
        columns,
        rows: [],
        selectableRows: true,
      }

    return {
      columns,
      rows: buildRows(validationResult),
      onRowClick: (row) => {
        focusPubKeyForRowInTextArea(row)
      },
      selectableRows: true,
    }
  }, [focusPubKeyForRowInTextArea, validationResult])

  return (
    <ContainerBox>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <SectionBoxLabel>Validation details</SectionBoxLabel>
        </Grid>
        <Grid item>
          <ValidationDetailTable config={config} />
        </Grid>
      </Grid>
    </ContainerBox>
  )
}

export { DepositCollectionValidationDetail }
