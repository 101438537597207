import React from 'react'

function ArrowDownIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9.5 2.8125L9.5 2.3125L8.5 2.3125L8.5 2.8125L9.5 2.8125ZM8.5 15.1875C8.5 15.4636 8.72386 15.6875 9 15.6875C9.27614 15.6875 9.5 15.4636 9.5 15.1875L8.5 15.1875ZM8.5 2.8125L8.5 15.1875L9.5 15.1875L9.5 2.8125L8.5 2.8125Z"
        fill="white"
      />
      <path
        d="M14.0625 10.125L9 15.1875L3.9375 10.125"
        stroke="white"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { ArrowDownIcon }
