import useSWRImmutable from 'swr/immutable'
import { BigNumber } from 'ethers'
import { formatEther, parseEther } from 'ethers/lib/utils'
import { useSwellWeb3 } from '@swell-web3/core'
import {
  useDepositManagerContractView,
  useSwETHContractView,
} from '@/hooks/useContract'
import {
  useGetAllStatsV3Backend,
  useGetRatesSwEthEthV3Backend,
  useGetSwethAprV3Backend,
} from '@/services/V3BackendService/hooks'
import { parseWeiSafe } from '@/util/big'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { SWRResponse } from 'swr'

interface CommissionRates {
  nodeOperatorRewardPercentage: number
  swellTreasuryRewardPercentage: number
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useCommissionRateOnChain = () => {
  const swEth = useSwETHContractView()

  return useSWRImmutable(
    swEth ? [swEth.address, 'rewardPercentage'] : null,
    async () => {
      let nodeOperatorRewardPercentage: BigNumber | null = null
      let swellTreasuryRewardPercentage: BigNumber | null = null

      return Promise.all([
        swEth!.nodeOperatorRewardPercentage().then((res) => {
          nodeOperatorRewardPercentage = res
        }),
        swEth!.swellTreasuryRewardPercentage().then((res) => {
          swellTreasuryRewardPercentage = res
        }),
      ]).then(() => {
        // reward percentages are expressed as a fraction of ether
        // e.g. 5% = 0.05*1e18
        return {
          nodeOperatorRewardPercentage:
            parseFloat(formatEther(nodeOperatorRewardPercentage!)) * 100,
          swellTreasuryRewardPercentage: parseFloat(
            formatEther(swellTreasuryRewardPercentage!)
          ),
        } as CommissionRates
      })
    }
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useCommissionRateV3Backend = () => {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const query = useGetAllStatsV3Backend(v3BackendLstUrl)

  return {
    ...query,
    get data(): CommissionRates | undefined {
      if (!query.data) return undefined
      const { swellTreasuryRewardPercentage, nodeOperatorRewardPercentage } =
        query.data
      // reward percentages are expressed as a fraction of ether
      // e.g. 5% = 0.05*1e18

      const oneETH = parseEther('1')

      // backend provides values as wei string, hence need to parseWei
      return {
        nodeOperatorRewardPercentage: parseWeiSafe(nodeOperatorRewardPercentage)
          .mul(100)
          .div(oneETH)
          .toNumber(),
        swellTreasuryRewardPercentage: parseWeiSafe(
          swellTreasuryRewardPercentage
        )
          .mul(100)
          .div(oneETH)
          .toNumber(),
      }
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useCommissionRateHardcoded = () => {
  return {
    error: undefined,
    isLoading: false,
    isValidating: false,
    mutate: () => {},
    get data(): CommissionRates | undefined {
      return {
        nodeOperatorRewardPercentage: 0,
        swellTreasuryRewardPercentage: 0,
      }
    },
  } as SWRResponse<CommissionRates, any>
}

export { useCommissionRateV3Backend as useCommissionRate }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStakingRateOnChain = () => {
  const swEth = useSwETHContractView()
  return useSWRImmutable(
    swEth ? [swEth.address, 'getRate'] : null,
    async () => {
      return swEth!.getRate().then((swETHToETHRate) => {
        return { swETHToETHRate }
      })
    }
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStakingRateV3Backend = () => {
  const query = useGetRatesSwEthEthV3Backend()
  return {
    ...query,
    get data() {
      if (!query.data) return undefined
      const { rate } = query.data
      return {
        swETHToETHRate: BigNumber.from(rate),
      }
    },
  }
}

// staking rate is currently only sourced via the backend.
// Note that this staking rate is only used for display purposes
// Staking rate has several uses:
// - Rate info for ETH conversion for staking (main use)
// - Stand-in for secondary market data during bootstrapping phase (while no such data exists)
//
// TODO: resiliency (important due to wide usage)
export { useStakingRateOnChain as useStakingRate }
// export { useStakingRateOnChain as useStakingRate }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useTotalEthStakedOnChain = () => {
  const depositManager = useDepositManagerContractView()
  const { provider } = useSwellWeb3()

  return useSWRImmutable(
    depositManager && provider ? [depositManager.address, 'getBalance'] : null,
    async () => {
      return {
        value: await provider!.getBalance(depositManager!.address),
      }
    }
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useTotalEthStakedV3Backend = () => {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const query = useGetAllStatsV3Backend(v3BackendLstUrl)

  return {
    ...query,
    get data() {
      if (!query.data) return undefined

      return { value: parseWeiSafe(query.data.totalEthStakedWei) }
    },
  }
}

export { useTotalEthStakedV3Backend as useTotalEthStaked }
// export { useTotalEthStakedOnChain as useTotalEthStaked }

export const useStakerCountUsers = () => {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const query = useGetAllStatsV3Backend(v3BackendLstUrl)

  return {
    ...query,
    get data() {
      if (!query.data) return undefined
      return { value: query.data.stakerCountUsers }
    },
  }
}

const useStakingAprV3Backend = () => {
  const query = useGetSwethAprV3Backend()

  return {
    ...query,
    get data() {
      if (!query.data) return undefined
      return { value: query.data.toFixed(2) }
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStakingAprHardcoded = () => {
  return {
    error: undefined,
    isLoading: false,
    isValidating: false,
    mutate: () => {},
    get data() {
      return { value: 4.5 }
    },
  } as SWRResponse<{ value: number }, any>
}

export { useStakingAprV3Backend as useStakingApr }
