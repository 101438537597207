import React from 'react'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Box } from '@swell-ui/Box'
import { Grid } from '@swell-ui/Grid'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { SectionBoxLabel } from '@/components/SectionBoxLabel'
import {
  DepositManagerDataBoxGrid,
  DepositManagerDataBoxGridProps,
} from './DepositManagerDataBoxGrid'
import {
  YourInfoDataBoxGrid,
  YourInfoDataBoxGridProps,
} from './YourInfoDataBoxGrid'

interface DepositManagerCommonProps {
  depositManagerProps: DepositManagerDataBoxGridProps
  infoLabelPrefix: string
  yourInfoProps: YourInfoDataBoxGridProps
}

const ContainerBox = styled(Box)`
  padding: 24px 32px;

  button {
    width: 100%;
    max-width: 392px;
  }
`

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
`

export function DepositManagerCommon({
  depositManagerProps,
  infoLabelPrefix,
  yourInfoProps,
}: DepositManagerCommonProps) {
  const { account } = useSwellWeb3()

  return (
    <ContainerBox>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <SectionBoxLabel>Swell deposit manager</SectionBoxLabel>
        </Grid>
        <Grid item>
          <div>
            <DepositManagerDataBoxGrid {...depositManagerProps} />
          </div>
        </Grid>
        <Grid item>
          <SectionBoxLabel>{infoLabelPrefix} info</SectionBoxLabel>
        </Grid>
        <Grid item>
          {!account && (
            <>
              <CenteredContent>
                <ConnectWalletButton>
                  Connect wallet to view
                </ConnectWalletButton>
              </CenteredContent>
            </>
          )}
          {account && <YourInfoDataBoxGrid {...yourInfoProps} />}
        </Grid>
      </Grid>
    </ContainerBox>
  )
}
