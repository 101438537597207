// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/twitter.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum swell.v3.VerificationStatus
 */
export enum VerificationStatus {
  /**
   * @generated from enum value: VERIFICATION_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: VERIFICATION_STATUS_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: VERIFICATION_STATUS_VERIFIED = 2;
   */
  VERIFIED = 2,

  /**
   * @generated from enum value: VERIFICATION_STATUS_FAILED = 3;
   */
  FAILED = 3,

  /**
   * @generated from enum value: VERIFICATION_STATUS_UNVERIFIED = 4;
   */
  UNVERIFIED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(VerificationStatus)
proto3.util.setEnumType(VerificationStatus, "swell.v3.VerificationStatus", [
  { no: 0, name: "VERIFICATION_STATUS_UNSPECIFIED" },
  { no: 1, name: "VERIFICATION_STATUS_PENDING" },
  { no: 2, name: "VERIFICATION_STATUS_VERIFIED" },
  { no: 3, name: "VERIFICATION_STATUS_FAILED" },
  { no: 4, name: "VERIFICATION_STATUS_UNVERIFIED" },
]);

/**
 * @generated from message swell.v3.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: string twitter_code = 2;
   */
  twitterCode = "";

  /**
   * @generated from field: swell.v3.VerificationStatus verification_status = 3;
   */
  verificationStatus = VerificationStatus.UNSPECIFIED;

  /**
   * Included in response when verification_status is VERIFICATION_STATUS_FAILED
   *
   * @generated from field: string verification_error = 4;
   */
  verificationError = "";

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "twitter_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "verification_status", kind: "enum", T: proto3.getEnumType(VerificationStatus) },
    { no: 4, name: "verification_error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message swell.v3.TwitterUserRequest
 */
export class TwitterUserRequest extends Message<TwitterUserRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<TwitterUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TwitterUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TwitterUserRequest {
    return new TwitterUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TwitterUserRequest {
    return new TwitterUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TwitterUserRequest {
    return new TwitterUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TwitterUserRequest | PlainMessage<TwitterUserRequest> | undefined, b: TwitterUserRequest | PlainMessage<TwitterUserRequest> | undefined): boolean {
    return proto3.util.equals(TwitterUserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.TwitterUserResponse
 */
export class TwitterUserResponse extends Message<TwitterUserResponse> {
  /**
   * @generated from field: swell.v3.User user = 1;
   */
  user?: User;

  /**
   * Used for signing via EIP-4361
   *
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * @generated from field: int32 queue_position = 3;
   */
  queuePosition = 0;

  constructor(data?: PartialMessage<TwitterUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TwitterUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "queue_position", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TwitterUserResponse {
    return new TwitterUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TwitterUserResponse {
    return new TwitterUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TwitterUserResponse {
    return new TwitterUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TwitterUserResponse | PlainMessage<TwitterUserResponse> | undefined, b: TwitterUserResponse | PlainMessage<TwitterUserResponse> | undefined): boolean {
    return proto3.util.equals(TwitterUserResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.TwitterVerifyRequest
 */
export class TwitterVerifyRequest extends Message<TwitterVerifyRequest> {
  /**
   * Call TwitterAuth to get the message and nonce
   * Create signature via EIP-4361
   *
   * @generated from field: string message = 1;
   */
  message = "";

  /**
   * @generated from field: string signature = 2;
   */
  signature = "";

  /**
   * @generated from field: string wallet_address = 4;
   */
  walletAddress = "";

  /**
   * @generated from field: string tweet_link = 5;
   */
  tweetLink = "";

  constructor(data?: PartialMessage<TwitterVerifyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TwitterVerifyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "signature", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "tweet_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TwitterVerifyRequest {
    return new TwitterVerifyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TwitterVerifyRequest {
    return new TwitterVerifyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TwitterVerifyRequest {
    return new TwitterVerifyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TwitterVerifyRequest | PlainMessage<TwitterVerifyRequest> | undefined, b: TwitterVerifyRequest | PlainMessage<TwitterVerifyRequest> | undefined): boolean {
    return proto3.util.equals(TwitterVerifyRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.TwitterVerifyResponse
 */
export class TwitterVerifyResponse extends Message<TwitterVerifyResponse> {
  /**
   * @generated from field: swell.v3.User user = 1;
   */
  user?: User;

  constructor(data?: PartialMessage<TwitterVerifyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TwitterVerifyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TwitterVerifyResponse {
    return new TwitterVerifyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TwitterVerifyResponse {
    return new TwitterVerifyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TwitterVerifyResponse {
    return new TwitterVerifyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TwitterVerifyResponse | PlainMessage<TwitterVerifyResponse> | undefined, b: TwitterVerifyResponse | PlainMessage<TwitterVerifyResponse> | undefined): boolean {
    return proto3.util.equals(TwitterVerifyResponse, a, b);
  }
}

