import styled from 'styled-components/macro'
import { Button } from '@swell-ui/Button'

const AccountInfoButton = styled(Button)`
  position: relative;
  font-weight: 500;
  border-radius: 40px;
  padding-left: 26px;
  align-items: left;
  white-space: nowrap;
`

export { AccountInfoButton }
