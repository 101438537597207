import { init } from '@web3-onboard/react'
import { appMetadata } from './appMetadata'
import { buildChains } from './chains'
import { buildWallets } from './wallets'
import { i18n } from './i18n'

// https://onboard.blocknative.com/docs/modules/react#using-the-web3onboardprovider
// full example at https://github.com/blocknative/react-demo/blob/master/src/services.js

interface InitSwellWeb3Options {
  walletConnectV2ProjectId: string
  rpcUrl: string
}

const customTheme = {
  '--w3o-background-color': '#0b141b',
  '--w3o-foreground-color': 'unset',
  '--w3o-text-color': '#ffffff',
  '--w3o-border-color': '#424557',
  '--w3o-action-color': '#a4abf1',
  '--w3o-border-radius': '16px',
  '--w3o-font-family': 'unset',
}

export function initWeb3Onboard({
  walletConnectV2ProjectId,
  rpcUrl,
}: InitSwellWeb3Options) {
  return init({
    i18n,
    accountCenter: {
      desktop: {
        enabled: false,
      },
      mobile: {
        enabled: false,
      },
    },
    connect: {
      autoConnectAllPreviousWallet: true,
    },
    wallets: buildWallets({
      walletConnectV2ProjectId,
    }),
    chains: buildChains(rpcUrl),
    appMetadata,
    disableFontDownload: true,
    theme: customTheme,
  })
}
