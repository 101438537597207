// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/predeposit.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.TokenListToken
 */
export class TokenListToken extends Message<TokenListToken> {
  /**
   * @generated from field: int32 chain_id = 1;
   */
  chainId = 0;

  /**
   * @generated from field: string address = 2;
   */
  address = "";

  /**
   * @generated from field: string symbol = 3;
   */
  symbol = "";

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: int32 decimals = 5;
   */
  decimals = 0;

  /**
   * @generated from field: string logo_uri = 6;
   */
  logoUri = "";

  /**
   * @generated from field: repeated string tags = 7;
   */
  tags: string[] = [];

  constructor(data?: PartialMessage<TokenListToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenListToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "logo_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenListToken {
    return new TokenListToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenListToken {
    return new TokenListToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenListToken {
    return new TokenListToken().fromJsonString(jsonString, options);
  }

  static equals(a: TokenListToken | PlainMessage<TokenListToken> | undefined, b: TokenListToken | PlainMessage<TokenListToken> | undefined): boolean {
    return proto3.util.equals(TokenListToken, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenListTag
 */
export class TokenListTag extends Message<TokenListTag> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  constructor(data?: PartialMessage<TokenListTag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenListTag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenListTag {
    return new TokenListTag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenListTag {
    return new TokenListTag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenListTag {
    return new TokenListTag().fromJsonString(jsonString, options);
  }

  static equals(a: TokenListTag | PlainMessage<TokenListTag> | undefined, b: TokenListTag | PlainMessage<TokenListTag> | undefined): boolean {
    return proto3.util.equals(TokenListTag, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenList
 */
export class TokenList extends Message<TokenList> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int32 timestamp = 2;
   */
  timestamp = 0;

  /**
   * @generated from field: repeated swell.v3.TokenListToken tokens = 3;
   */
  tokens: TokenListToken[] = [];

  /**
   * @generated from field: repeated swell.v3.TokenListTag tags = 4;
   */
  tags: TokenListTag[] = [];

  constructor(data?: PartialMessage<TokenList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "timestamp", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "tokens", kind: "message", T: TokenListToken, repeated: true },
    { no: 4, name: "tags", kind: "message", T: TokenListTag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenList {
    return new TokenList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenList {
    return new TokenList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenList {
    return new TokenList().fromJsonString(jsonString, options);
  }

  static equals(a: TokenList | PlainMessage<TokenList> | undefined, b: TokenList | PlainMessage<TokenList> | undefined): boolean {
    return proto3.util.equals(TokenList, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenSupportedRecord
 */
export class TokenSupportedRecord extends Message<TokenSupportedRecord> {
  /**
   * @generated from field: string token_address = 1;
   */
  tokenAddress = "";

  /**
   * @generated from field: bool deposit_supported = 2;
   */
  depositSupported = false;

  /**
   * @generated from field: bool withdraw_supported = 3;
   */
  withdrawSupported = false;

  constructor(data?: PartialMessage<TokenSupportedRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenSupportedRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deposit_supported", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "withdraw_supported", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenSupportedRecord {
    return new TokenSupportedRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenSupportedRecord {
    return new TokenSupportedRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenSupportedRecord {
    return new TokenSupportedRecord().fromJsonString(jsonString, options);
  }

  static equals(a: TokenSupportedRecord | PlainMessage<TokenSupportedRecord> | undefined, b: TokenSupportedRecord | PlainMessage<TokenSupportedRecord> | undefined): boolean {
    return proto3.util.equals(TokenSupportedRecord, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenListRequest
 */
export class TokenListRequest extends Message<TokenListRequest> {
  constructor(data?: PartialMessage<TokenListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenListRequest {
    return new TokenListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenListRequest {
    return new TokenListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenListRequest {
    return new TokenListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TokenListRequest | PlainMessage<TokenListRequest> | undefined, b: TokenListRequest | PlainMessage<TokenListRequest> | undefined): boolean {
    return proto3.util.equals(TokenListRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenListResponse
 */
export class TokenListResponse extends Message<TokenListResponse> {
  /**
   * @generated from field: swell.v3.TokenList token_list = 1;
   */
  tokenList?: TokenList;

  /**
   * @generated from field: repeated swell.v3.TokenSupportedRecord token_supported = 2;
   */
  tokenSupported: TokenSupportedRecord[] = [];

  constructor(data?: PartialMessage<TokenListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token_list", kind: "message", T: TokenList },
    { no: 2, name: "token_supported", kind: "message", T: TokenSupportedRecord, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenListResponse {
    return new TokenListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenListResponse {
    return new TokenListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenListResponse {
    return new TokenListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TokenListResponse | PlainMessage<TokenListResponse> | undefined, b: TokenListResponse | PlainMessage<TokenListResponse> | undefined): boolean {
    return proto3.util.equals(TokenListResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.PreDepositTokenStatRecord
 */
export class PreDepositTokenStatRecord extends Message<PreDepositTokenStatRecord> {
  /**
   * @generated from field: string token_address = 1;
   */
  tokenAddress = "";

  /**
   * net of deposit and withdraw amounts
   *
   * @generated from field: string total_staked = 2;
   */
  totalStaked = "";

  constructor(data?: PartialMessage<PreDepositTokenStatRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PreDepositTokenStatRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total_staked", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreDepositTokenStatRecord {
    return new PreDepositTokenStatRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreDepositTokenStatRecord {
    return new PreDepositTokenStatRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreDepositTokenStatRecord {
    return new PreDepositTokenStatRecord().fromJsonString(jsonString, options);
  }

  static equals(a: PreDepositTokenStatRecord | PlainMessage<PreDepositTokenStatRecord> | undefined, b: PreDepositTokenStatRecord | PlainMessage<PreDepositTokenStatRecord> | undefined): boolean {
    return proto3.util.equals(PreDepositTokenStatRecord, a, b);
  }
}

/**
 * @generated from message swell.v3.PreDepositStatsRequest
 */
export class PreDepositStatsRequest extends Message<PreDepositStatsRequest> {
  constructor(data?: PartialMessage<PreDepositStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PreDepositStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreDepositStatsRequest {
    return new PreDepositStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreDepositStatsRequest {
    return new PreDepositStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreDepositStatsRequest {
    return new PreDepositStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PreDepositStatsRequest | PlainMessage<PreDepositStatsRequest> | undefined, b: PreDepositStatsRequest | PlainMessage<PreDepositStatsRequest> | undefined): boolean {
    return proto3.util.equals(PreDepositStatsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.PreDepositStatsResponse
 */
export class PreDepositStatsResponse extends Message<PreDepositStatsResponse> {
  /**
   * @generated from field: repeated swell.v3.PreDepositTokenStatRecord token_stats = 1;
   */
  tokenStats: PreDepositTokenStatRecord[] = [];

  constructor(data?: PartialMessage<PreDepositStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PreDepositStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token_stats", kind: "message", T: PreDepositTokenStatRecord, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreDepositStatsResponse {
    return new PreDepositStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreDepositStatsResponse {
    return new PreDepositStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreDepositStatsResponse {
    return new PreDepositStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PreDepositStatsResponse | PlainMessage<PreDepositStatsResponse> | undefined, b: PreDepositStatsResponse | PlainMessage<PreDepositStatsResponse> | undefined): boolean {
    return proto3.util.equals(PreDepositStatsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.PreDepositVoyageUserRequest
 */
export class PreDepositVoyageUserRequest extends Message<PreDepositVoyageUserRequest> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  constructor(data?: PartialMessage<PreDepositVoyageUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PreDepositVoyageUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreDepositVoyageUserRequest {
    return new PreDepositVoyageUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreDepositVoyageUserRequest {
    return new PreDepositVoyageUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreDepositVoyageUserRequest {
    return new PreDepositVoyageUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PreDepositVoyageUserRequest | PlainMessage<PreDepositVoyageUserRequest> | undefined, b: PreDepositVoyageUserRequest | PlainMessage<PreDepositVoyageUserRequest> | undefined): boolean {
    return proto3.util.equals(PreDepositVoyageUserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.PreDepositVoyageUserResponse
 */
export class PreDepositVoyageUserResponse extends Message<PreDepositVoyageUserResponse> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: float points = 2;
   */
  points = 0;

  constructor(data?: PartialMessage<PreDepositVoyageUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PreDepositVoyageUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreDepositVoyageUserResponse {
    return new PreDepositVoyageUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreDepositVoyageUserResponse {
    return new PreDepositVoyageUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreDepositVoyageUserResponse {
    return new PreDepositVoyageUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PreDepositVoyageUserResponse | PlainMessage<PreDepositVoyageUserResponse> | undefined, b: PreDepositVoyageUserResponse | PlainMessage<PreDepositVoyageUserResponse> | undefined): boolean {
    return proto3.util.equals(PreDepositVoyageUserResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.PreDepositRateRecord
 */
export class PreDepositRateRecord extends Message<PreDepositRateRecord> {
  /**
   * @generated from field: string token_address = 1;
   */
  tokenAddress = "";

  /**
   * @generated from field: int32 rateCents = 2;
   */
  rateCents = 0;

  constructor(data?: PartialMessage<PreDepositRateRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PreDepositRateRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rateCents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreDepositRateRecord {
    return new PreDepositRateRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreDepositRateRecord {
    return new PreDepositRateRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreDepositRateRecord {
    return new PreDepositRateRecord().fromJsonString(jsonString, options);
  }

  static equals(a: PreDepositRateRecord | PlainMessage<PreDepositRateRecord> | undefined, b: PreDepositRateRecord | PlainMessage<PreDepositRateRecord> | undefined): boolean {
    return proto3.util.equals(PreDepositRateRecord, a, b);
  }
}

/**
 * @generated from message swell.v3.PreDepositRatesRequest
 */
export class PreDepositRatesRequest extends Message<PreDepositRatesRequest> {
  constructor(data?: PartialMessage<PreDepositRatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PreDepositRatesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreDepositRatesRequest {
    return new PreDepositRatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreDepositRatesRequest {
    return new PreDepositRatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreDepositRatesRequest {
    return new PreDepositRatesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PreDepositRatesRequest | PlainMessage<PreDepositRatesRequest> | undefined, b: PreDepositRatesRequest | PlainMessage<PreDepositRatesRequest> | undefined): boolean {
    return proto3.util.equals(PreDepositRatesRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.PreDepositRatesResponse
 */
export class PreDepositRatesResponse extends Message<PreDepositRatesResponse> {
  /**
   * @generated from field: repeated swell.v3.PreDepositRateRecord rates_usd = 1;
   */
  ratesUsd: PreDepositRateRecord[] = [];

  constructor(data?: PartialMessage<PreDepositRatesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PreDepositRatesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rates_usd", kind: "message", T: PreDepositRateRecord, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreDepositRatesResponse {
    return new PreDepositRatesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreDepositRatesResponse {
    return new PreDepositRatesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreDepositRatesResponse {
    return new PreDepositRatesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PreDepositRatesResponse | PlainMessage<PreDepositRatesResponse> | undefined, b: PreDepositRatesResponse | PlainMessage<PreDepositRatesResponse> | undefined): boolean {
    return proto3.util.equals(PreDepositRatesResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.PreDepositSummaryRequest
 */
export class PreDepositSummaryRequest extends Message<PreDepositSummaryRequest> {
  constructor(data?: PartialMessage<PreDepositSummaryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PreDepositSummaryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreDepositSummaryRequest {
    return new PreDepositSummaryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreDepositSummaryRequest {
    return new PreDepositSummaryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreDepositSummaryRequest {
    return new PreDepositSummaryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PreDepositSummaryRequest | PlainMessage<PreDepositSummaryRequest> | undefined, b: PreDepositSummaryRequest | PlainMessage<PreDepositSummaryRequest> | undefined): boolean {
    return proto3.util.equals(PreDepositSummaryRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.PreDepositSummaryResponse
 */
export class PreDepositSummaryResponse extends Message<PreDepositSummaryResponse> {
  /**
   * @generated from field: int64 total_deposited_cents = 1;
   */
  totalDepositedCents = protoInt64.zero;

  /**
   * @generated from field: int32 total_stakers = 2;
   */
  totalStakers = 0;

  constructor(data?: PartialMessage<PreDepositSummaryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PreDepositSummaryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_deposited_cents", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "total_stakers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreDepositSummaryResponse {
    return new PreDepositSummaryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreDepositSummaryResponse {
    return new PreDepositSummaryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreDepositSummaryResponse {
    return new PreDepositSummaryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PreDepositSummaryResponse | PlainMessage<PreDepositSummaryResponse> | undefined, b: PreDepositSummaryResponse | PlainMessage<PreDepositSummaryResponse> | undefined): boolean {
    return proto3.util.equals(PreDepositSummaryResponse, a, b);
  }
}

