import React from 'react'
import useTransactionFee from '@/hooks/useTransactionFee'
import { useStakingApr, useStakingRate } from '@/state/stakingStats/hooks'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@/swell-ui/KeyValueList'
import {
  useDisplaySwEth,
  useDisplaySwEthInNativeCurrency,
} from '@/hooks/useSwEthDisplay'
import { useParseSwEth } from '@/hooks/useSwEthFormatting'
import { useDisplayNativeCurrencyPriceFiat } from '@/hooks/useNativeCurrencyDisplay'
import { getStakingUpperGasEstimate } from '@/constants/gasEstimates'
import { formatEther } from 'ethers/lib/utils'
import { bigNumberRateToFloat } from '@/util/big'

export function SwethExchangeInfo({ unstake }: { unstake?: boolean }) {
  const stakingApr = useStakingApr()
  const stakingRate = useStakingRate()
  const stakingTransactionFee = useTransactionFee({
    gasAmount: getStakingUpperGasEstimate().toNumber(),
  })

  const parseSwEth = useParseSwEth()
  const displaySwEth = useDisplaySwEth()
  const displaySwEthInNativeCurrency = useDisplaySwEthInNativeCurrency()
  const displayNativeCurrencyPriceFiat = useDisplayNativeCurrencyPriceFiat()

  const swethAprConfig = {
    label: 'swETH APR',
    value: (
      <AsyncDiv loading={!stakingApr.data}>
        {() => `${stakingApr.data!.value}%`}
      </AsyncDiv>
    ),
    infoText: (
      <span>
        <a
          href="https://dune.com/swell-network/swell-network"
          target="_blank"
          rel="noreferrer"
        >
          Total pool 7 day moving average
        </a>
      </span>
    ),
    hasInfo: true,
  }

  const exchangeRateConfig = {
    label: 'Exchange rate',
    value: (
      <AsyncDiv loading={!stakingRate.data}>
        {() => {
          const { swETHToETHRate } = stakingRate.data!

          const oneSwEth = parseSwEth('1')

          const oneSwEthDisplay = displaySwEth(oneSwEth, {
            precision: 4,
            localize: true,
          })
          const equivalentEthDisplay = displaySwEthInNativeCurrency(
            oneSwEth,
            bigNumberRateToFloat(swETHToETHRate),
            { precision: 4, localize: true }
          )

          return `${oneSwEthDisplay} = ${equivalentEthDisplay}`
        }}
      </AsyncDiv>
    ),
    hasInfo: true,
    infoText: 'Represents the accruing value of swETH vs ETH',
  }

  const exchangeInfoConfig = {
    label: 'Transaction fee',
    value: (
      <AsyncDiv loading={!stakingTransactionFee.data}>
        {() => {
          const { ethUsdRate, feeWei } = stakingTransactionFee.data!
          return `${displayNativeCurrencyPriceFiat(feeWei, ethUsdRate)} USD`
        }}
      </AsyncDiv>
    ),
  }

  const processingTimeConfig = {
    label: 'Processing time',
    value: <div>~12 days</div>,
    hasInfo: true,
    infoText: (
      <a
        href="https://docs.swellnetwork.io/swell/sweth-liquid-staking/sweth-v1.0-system-design/withdrawals"
        target="_blank"
        rel="noreferrer"
      >
        Visit docs for more info
      </a>
    ),
  }

  const list = [swethAprConfig, exchangeRateConfig, exchangeInfoConfig]

  if (unstake) {
    list.push(processingTimeConfig)
  }

  const exchangeInfoListConfig = {
    list,
  }

  return <KeyValueList config={exchangeInfoListConfig} />
}
