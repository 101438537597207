import React from 'react'
import styled from 'styled-components'
import { useUserOperator } from '@/state/userOperator/hooks'
import { Grid } from '@swell-ui/Grid'
import { StakingFeesEarned } from '@/components/FeesEarned'
import { PageTitle } from '@/components/PageTitleOld'
import { StakingRecentTransactions } from '@/components/RecentTransactions'
import { StakingDepositManager } from '@/components/SwellDepositManager'
import { useUserOperatorStatusWithWarning } from '@/hooks/useUserOperatorStatus'

const SectionGrid = styled(Grid)`
  max-width: 100% !important;
`

function OperatorDashboard() {
  const userOperator = useUserOperator()

  // this hook produces a side effect which shows a warning banner if the user is not an operator
  useUserOperatorStatusWithWarning()

  let pageTitlePrefix = ''

  if (userOperator.data) {
    const {
      operator: { name },
    } = userOperator.data
    pageTitlePrefix = `${name} `
  }

  return (
    <>
      <PageTitle text={`${pageTitlePrefix}Dashboard`} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <StakingDepositManager />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container direction="column" spacing={2}>
            <SectionGrid item>
              <StakingFeesEarned />
            </SectionGrid>
            <SectionGrid item>
              <StakingRecentTransactions />
            </SectionGrid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export { OperatorDashboard }
