import { ServiceType } from '@bufbuild/protobuf'

export const protobufToUrl = <ST extends ServiceType>({
  service,
  method,
  prefix,
}: {
  service: ST
  method: keyof ST['methods']
  prefix: string
}): string => {
  return `${prefix}/${service.typeName}/${String(method)}`
}
