import styled, { css } from 'styled-components'
import { FlexRow } from '@/swell-ui/FlexRow'
import eigenLayerGreyLogo from '@/assets/images/eigen-grey-80x80.png'
import karakLogo from '@/assets/images/karak-80x80.png'
import blackPearlLogo from '@/assets/images/black-pearl-80x80.png'
import { Box } from '@/swell-ui/Box'
import { Tooltip } from '@/swell-ui/Tooltip'

const symbioticLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg'
const ICON_SIZE = 42 // px

const StyledTooltipContainer = styled(Tooltip)`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
`

export function BtcLrtRewards() {
  return (
    <Layout gap="24">
      <StyledBox padding="24px" gap="24" width="252px">
        <FlexRow direction="column" gap="12" align="center">
          <span className="r-title">Restaking Yield</span>
          <FlexRow gap="12" justify="center">
            <img
              width={ICON_SIZE}
              height={ICON_SIZE}
              src={symbioticLogo}
              className="r-icon"
            />
            <StyledTooltipContainer title="Coming soon">
              <img
                width={ICON_SIZE}
                height={ICON_SIZE}
                src={eigenLayerGreyLogo}
                className="r-icon soon"
              />
            </StyledTooltipContainer>
            <StyledTooltipContainer title="Coming soon">
              <img
                width={ICON_SIZE}
                height={ICON_SIZE}
                src={karakLogo}
                className="r-icon soon"
              />
            </StyledTooltipContainer>
          </FlexRow>
        </FlexRow>
      </StyledBox>
      <StyledBox padding="24px">
        <ProtocolsLayout>
          <ProtocolPts
            title="Swell Black Pearls"
            multiplier="3x"
            iconURI={blackPearlLogo}
          />
          <ProtocolPts
            title="Symbiotic Points"
            multiplier="1x"
            iconURI={symbioticLogo}
          />
          <ProtocolPts
            title="EigenLayer Points"
            multiplier="1x"
            iconURI={eigenLayerGreyLogo}
            soon
          />
          <ProtocolPts
            title="Karak Points"
            multiplier="1x"
            iconURI={karakLogo}
            soon
          />
        </ProtocolsLayout>
      </StyledBox>
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  flex-direction: column;
  align-items: center;
  @media (min-width: 580px) {
    align-items: start;
  }
  @media (min-width: 900px) {
    flex-direction: row;
  }

  .r-title {
    white-space: nowrap;

    color: var(--Swell-White-50, #fff);
    /* Body/small */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.42px;
  }

  .r-multiplier {
    color: var(--Swell-White-50, #fff);
    /* Headlines/H7 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 26px */
    letter-spacing: -0.6px;
  }

  .r-icon {
    width: ${ICON_SIZE};
    height: ${ICON_SIZE};
    &.soon {
      filter: grayscale(100%);
    }
    border-radius: 50%;
  }
`

const StyledBox = styled(Box)`
  overflow: visible;
  border-radius: 16px;
  border: 1px solid var(--Bitcoin-Orange, #f7931a);
  background: var(--Bitcoin-Dark, #090d14);
  box-shadow: 0px 0px 24px #f7941a55;
`

const ProtocolsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  @media (min-width: 580px) {
    display: flex;
    flex-flow: row nowrap;
  }
`

function ProtocolPts({
  iconURI,
  multiplier,
  title,
  soon,
}: {
  title: string
  multiplier: string
  iconURI: string
  soon?: boolean
}) {
  let imgClass = 'r-icon'
  if (soon) {
    imgClass += ' soon'
  }

  let imgNode = (
    <img
      src={iconURI}
      width={ICON_SIZE}
      height={ICON_SIZE}
      className={imgClass}
    />
  )
  if (soon) {
    imgNode = (
      <StyledTooltipContainer title="Coming soon">
        {imgNode}
      </StyledTooltipContainer>
    )
  }

  return (
    <FlexRow direction="column" gap={'12'} align="center">
      <span className="r-title">{title}</span>
      <FlexRow gap="12" justify="center">
        <span className="r-multiplier">{multiplier}</span>
        {imgNode}
      </FlexRow>
    </FlexRow>
  )
}
