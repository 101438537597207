function buildIntentUrl({
  text,
  url = '',
  via = '',
}: {
  text: string | string[]
  url?: string
  via?: string
}) {
  const baseUrl = 'https://twitter.com/intent/tweet'
  const queryParams = []

  const tweetText = typeof text === 'string' ? text : text.join('%0a')
  queryParams.push(`text=${tweetText}`)

  if (url) {
    queryParams.push(`url=${url}`)
  }

  if (via) {
    queryParams.push(`via=${via}`)
  }

  return `${baseUrl}?${queryParams.join('&')}`
}

function isTweetUrl(url: string) {
  const tweetRegex =
    /https?:\/\/(?:www\.)?twitter\.com\/[A-Za-z0-9_]{1,15}\/status\/\d+/
  if (tweetRegex.test(url)) {
    return true
  }

  return false
}

export { buildIntentUrl, isTweetUrl }
