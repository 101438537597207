import React from 'react'
import { useRestakingDepositSubmissionDetailsContext } from '@/state/restakingDepositSubmission/hooks'
import useRestakingDepositSubmissionFailNotify from '@/hooks/useRestakingDepositSubmissionFailNotify'
import { DepositCollectionSubmissionTableCommon } from './DepositCollectionSubmissionTableCommon'

interface RestakingDepositCollectionSubmissionTableProps {
  isHistoric: boolean
}

export function RestakingDepositCollectionSubmissionTable({
  isHistoric,
}: RestakingDepositCollectionSubmissionTableProps) {
  return (
    <DepositCollectionSubmissionTableCommon
      useDepositSubmissionDetailsContext={
        useRestakingDepositSubmissionDetailsContext
      }
      useDepositSubmissionFailNotify={useRestakingDepositSubmissionFailNotify}
      isHistoric={isHistoric}
    />
  )
}
