import { calculateGasMargin } from '@/util/calculateGasMargin'
import { extractErrorData, parseErrorData } from '@/util/errors'
import { web3ErrorAdapter2 } from '@/util/web3ErrorAdapter'
import {
  BigNumber,
  BigNumberish,
  ContractReceipt,
  ContractTransaction,
} from 'ethers'
import { useCallback, useState } from 'react'

const STATUS = {
  IDLE: 'idle' as const,
  PROMPTING: 'prompting' as const,
  PENDING: 'pending' as const,
  FULFILLED: 'fulfilled' as const,
}

export { STATUS as WEB3_CALL_STATUS }

export type CallStatus = (typeof STATUS)[keyof typeof STATUS]

// useWeb3Call wraps a function that broadcasts a transaction on-chain to provide common UI state.
// It handles estimating gas, validating inputs, prompting the signer, and error handling.
// The status of the transaction is tracked in the `status` variable. Possible values are: 'idle', 'prompting', 'pending', 'fulfilled'.
// The most recent error is tracked independently in the `error` variable and is reset upon resubmission.
// It exposes functions to broadcast the transaction (`call`), clear the state (`clear`), and reset the error (`resetError`).
// It exposes the transaction hash (for in-flight tracking) and the final receipt.
// It exposes the args that were used to call the transaction, for display purposes.
// Types are inferred from the `estimateGas` function, which is used to statically verify types of the broadcasting function `fn`.
// A static gas estimate can be provided, which will be used as a baseline vs. the realtime estimate.
export function useWeb3Call<
  EstimateGas extends (...args: any[]) => Promise<BigNumber>
>({
  fn,
  estimateGas,
  validate: maybeValidate,
  staticGasEstimate: maybeStaticGasEstimate,
}: {
  fn: (
    ...args: [
      ...Parameters<EstimateGas>,
      { gasLimit?: BigNumberish } | undefined
    ]
  ) => Promise<ContractTransaction>
  estimateGas: EstimateGas
  validate?: (...args: Parameters<EstimateGas>) => Promise<string | null>
  staticGasEstimate?: (...args: Parameters<EstimateGas>) => BigNumberish
}) {
  const [status, setStatus] = useState<CallStatus>(STATUS.IDLE)
  const [args, setArgs] = useState<Parameters<EstimateGas> | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [txHash, setTxHash] = useState<string | undefined>(undefined)
  const [receipt, setReceipt] = useState<ContractReceipt | null>(null)

  const clear = () => {
    setStatus(STATUS.IDLE)
    setArgs(null)
    setError(null)
    setTxHash(undefined)
    setReceipt(null)
  }
  const resetError = () => setError(null)

  const call = useCallback(
    async (...args: Parameters<EstimateGas>) => {
      setError(null)

      const validate = maybeValidate ?? (() => Promise.resolve(null as null))

      let validationMessage: string | null = null
      try {
        validationMessage = await validate(...args)
      } catch (err: any) {
        console.error('validate panic', { err })
        return
      }

      if (validationMessage) {
        console.warn('invalid input', validationMessage)
        setError(validationMessage)
        setStatus(STATUS.IDLE)
        return
      }

      setArgs(args)
      setStatus(STATUS.PROMPTING)

      let gasLimit: BigNumber
      try {
        gasLimit = await estimateGas(...args)
      } catch (err: any) {
        const msg = interpretError(err)
        setError(msg)
        console.error('estimate gas', msg, { err })
        setStatus(STATUS.IDLE)
        return
      }

      if (
        maybeStaticGasEstimate &&
        gasLimit.lt(maybeStaticGasEstimate(...args))
      ) {
        gasLimit = BigNumber.from(maybeStaticGasEstimate(...args))
      }

      let tx: ContractTransaction | undefined
      try {
        tx = await fn(...args, { gasLimit: calculateGasMargin(gasLimit) })
      } catch (err: any) {
        const msg = interpretError(err)
        setError(msg)
        console.error('call', msg, { err })
        setStatus(STATUS.IDLE)
        return
      }

      setStatus(STATUS.PENDING)
      setTxHash(tx.hash)

      let receipt: ContractReceipt | undefined
      try {
        receipt = await tx.wait()
      } catch (err: any) {
        const msg = interpretError(err)
        console.error('wait', msg, { err })
        setError(msg)
        setStatus(STATUS.IDLE)
        return
      }

      setStatus(STATUS.FULFILLED)
      setReceipt(receipt)
    },
    [maybeValidate, maybeStaticGasEstimate, estimateGas, fn]
  )

  return {
    status,
    call,
    args,
    error,
    receipt,
    clear,
    STATUS,
    resetError,
    get txHash() {
      return receipt?.transactionHash ?? txHash
    },
  }
}

export type Web3Call = ReturnType<typeof useWeb3Call>

function interpretError(err: any) {
  if (err?.code === 4001) {
    return 'User rejected the request'
  }

  const fallback = () => {
    console.error('fallback', { err })
    const errStr = web3ErrorAdapter2(err)
    return errStr ?? 'Internal'
  }

  const errorData = extractErrorData(err)
  if (!errorData) {
    return fallback()
  }

  const breakdown = parseErrorData(errorData)

  switch (breakdown?.type) {
    case 'panic':
      return `Transaction failed`
    case 'revert':
    case 'custom':
      return `Transaction failed: ${breakdown.msg}`
    case 'unknown':
      return `Transaction failed: Raw (${breakdown.msg})`
    default:
      return fallback()
  }
}
