import { OperatorClient } from '@/services/V3BackendService/types'
import {
  IndexedDepositProblem,
  IDepositCollectionValidationResult,
  DepositCollectionValidationResult,
} from '@/state/depositValidation/models/DepositCollectionValidationResult'
import { DepositProblem } from '@/state/depositValidation/models/DepositProblem'
import { DepositCollection } from '@/types/deposits'
import { strip0x } from '@/util/hexStrings'

enum BackendDepositProblemType {
  NONE = 'NONE',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  DUPLICATE_KEY = 'DUPLICATE_KEY',
  WRONG_FORK_VERSION = 'WRONG_FORK_VERSION',
  INVALID_PUBLIC_KEY = 'INVALID_PUBLIC_KEY',
  INVALID_DEPOSIT_DATA_ROOT = 'INVALID_DEPOSIT_DATA_ROOT',
  INVALID_DEPOSIT_DATA_MESSAGE = 'INVALID_DEPOSIT_DATA_MESSAGE',
  INVALID_SIGNATURE = 'INVALID_SIGNATURE',
  INCORRECT_AMOUNT = 'INCORRECT_AMOUNT',
  INVALID_WITHDRAW_CREDENTIALS = 'INVALID_WITHDRAW_CREDENTIALS',
}

/**
 * Converts deposit data into a form that the backend expects (validateKeys method)
 *
 * @param depositCollection Client-side representation of deposit data
 * @returns Object containing buffer serialization of deposit data
 */
const v3BackendValidateKeysRequestAdapter = (
  depositCollection: unknown[],
  nodeOperatorName: string
): Parameters<OperatorClient['validateKeys']>[0] => {
  return {
    depositData: Buffer.from(JSON.stringify(depositCollection)),
    nodeOperatorName,
  }
}

/**
 * Converts the validateKeys validation results received by the backend into the common form
 *  used in the frontend (IndexedDepositInfoProblem)
 *
 * @param res The response of operators.validateKeys
 * @param pubKeyToIndexMap A mapping from public key -> index in the data (required to shape the data correctly)
 * @returns an array of IndexedDepositInfoProblem (usable client-side data format)
 */
const v3BackendValidateKeysResponseAdapter = (
  res: Awaited<ReturnType<OperatorClient['validateKeys']>>,
  pubKeyToIndexMap: Record<string, number>
): IndexedDepositProblem[] => {
  const { invalids } = res

  const transformed = invalids
    // We want to only capture problems reported by the backend
    // A type of "NONE" indicates that there was no issue with this deposit
    .filter(({ type }) => type !== BackendDepositProblemType.NONE)
    .map(({ pubkey, type }) => {
      const derived = {
        get codeAndMeta(): Pick<DepositProblem, 'code' | 'meta'> {
          switch (type) {
            case BackendDepositProblemType.INTERNAL_ERROR:
              return {
                code: 'something-went-wrong',
              }

            case BackendDepositProblemType.DUPLICATE_KEY:
              return {
                code: 'duplication-fail',
              }

            case BackendDepositProblemType.WRONG_FORK_VERSION:
              return {
                code: 'incorrect-fork-version',
              }

            case BackendDepositProblemType.INVALID_PUBLIC_KEY:
            case BackendDepositProblemType.INVALID_DEPOSIT_DATA_ROOT:
            case BackendDepositProblemType.INVALID_DEPOSIT_DATA_MESSAGE:
              return {
                code: 'deposit-roots-fail',
              }

            case BackendDepositProblemType.INVALID_SIGNATURE:
              return {
                code: 'signature-fail',
              }

            case BackendDepositProblemType.INCORRECT_AMOUNT:
              return {
                code: 'invalid-amount',
              }

            case BackendDepositProblemType.INVALID_WITHDRAW_CREDENTIALS:
              return {
                code: 'incorrect-withdrawal-credentials',
              }

            default: {
              console.error(
                'Received unexpected failure type from backend validation',
                { type }
              )
              return {
                code: 'something-went-wrong',
              }
            }
          }
        },
      }

      const {
        codeAndMeta: { code, meta },
      } = derived

      return {
        code,
        datumIndex: pubKeyToIndexMap[strip0x(pubkey.toLowerCase())],
        pubkey,
        meta,
      }
    })

  return transformed
}

/**
 * Wraps the operator client's validate keys method to work with client-side data types.
 * Binds adapters under the hood.
 *
 * @param validateKeys The validateKeys method of the operator client
 * @returns The wrapped validateKeys method with a usable client-side signature
 */
export const wrapOperatorValidatorKeys = (
  validateKeys: OperatorClient['validateKeys']
) => {
  return (
    depositCollection: any[],
    nodeOperatorName: string
  ): Promise<IDepositCollectionValidationResult> => {
    // used to correlate problems expressed in terms of public keys, to indices in the data
    const pubKeyToIndexMap: Record<string, number> = {}

    // build the mapping of public key -> index of the deposit key in the submission data
    depositCollection.forEach((deposit, idx) => {
      if (!deposit?.pubkey) {
        // user input is invalid (did not include a pubkey)
        // (do not throw; just let backend report problems)
        return
      }

      pubKeyToIndexMap[strip0x(deposit.pubkey).toLowerCase()] = idx
    })

    const req = v3BackendValidateKeysRequestAdapter(
      depositCollection,
      nodeOperatorName
    )

    return validateKeys(req)
      .then((res) => {
        const dataProblems = v3BackendValidateKeysResponseAdapter(
          res,
          pubKeyToIndexMap
        )

        if (dataProblems.length) {
          return DepositCollectionValidationResult.fromDataProblems(
            dataProblems
          )
        }

        return DepositCollectionValidationResult.fromValidData(
          depositCollection as DepositCollection
        )
      })
      .catch((err) => {
        console.error('[V3Backend] Operator.validateKeys failed', { err })

        if (err.message.includes('unknown deposit data format')) {
          return DepositCollectionValidationResult.fromInputProblem({
            code: 'invalid-data-format',
          })
        }

        if (err.message.includes('operator not found')) {
          return DepositCollectionValidationResult.fromInputProblem({
            code: 'no-assigned-withdrawal-addresses',
          })
        }

        return DepositCollectionValidationResult.fromInputProblem({
          code: 'something-went-wrong',
        })
      })
  }
}
