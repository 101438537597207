import React from 'react'

function UploadIcon(props: any) {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 10.25L16 5L21.25 10.25"
        stroke="white"
        strokeWidth="1.33"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M15.335 19V19.665H16.665V19H15.335ZM16.665 5C16.665 4.63273 16.3673 4.335 16 4.335C15.6327 4.335 15.335 4.63273 15.335 5H16.665ZM16.665 19V5H15.335V19H16.665Z"
        fill="white"
      />
      <path
        d="M27 19V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V19"
        stroke="white"
        strokeWidth="1.33"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { UploadIcon }
