import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { PredepositContext } from './context'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { IPredepositReadApi } from './types'
import { useSwellWeb3 } from '@/swell-web3/core'

export function usePredepositApiImpl(): PredepositContext {
  return {
    read: usePredepositReadApi(),
  }
}

function usePredepositReadApi(): IPredepositReadApi {
  const { account: maybeAccount } = useSwellWeb3()
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const walletClient =
    useV3BackendClient(v3BackendLstUrl).v3BackendClient.wallet

  const account = maybeAccount!
  return {
    ecosystemPoints: async () => {
      const response = await walletClient.swellL2EcosystemUser({
        walletAddress: account,
      })
      const { points } = response
      return { points }
    },
  }
}
