import { env } from '@/env'
import { DeploymentSet } from '@/types/deployments'
import { HOST_ENV, HostEnv } from './hostEnv'

enum KnownDeploymentSets {
  MAINNET = 'mainnet',
  GOERLI_PROD = 'goerli-prod',
  GOERLI_STAGING = 'goerli-staging',
}

class DeploymentSetGlobLoader {
  private _deploymentSetsLookup: Record<string, DeploymentSet> | null = null
  public get deploymentSetsLookup(): Record<string, DeploymentSet> {
    if (this._deploymentSetsLookup == null) {
      this._deploymentSetsLookup = ((ctx) => {
        const keys = ctx.keys()
        const values = keys.map(ctx)

        this._deploymentSetsLookup = {}

        keys.forEach((k, i) => {
          if (!k.endsWith('.json')) return
          // "./my-deployment.json" -> "my-deployment"
          const cleanedKey = k.replace(/^\.\//, '').replace(/\.json$/, '')
          const value = values[i]

          this._deploymentSetsLookup![cleanedKey] = value as DeploymentSet
        })

        return this._deploymentSetsLookup
      })(
        require.context(
          // Path: Path to the directory containing deployment set JSON
          // This value cannot be supplied dynamically; webpack requires
          //  the string be declared inline.
          '@/../deployments',
          // Deep: Do not glob nested directories
          false,
          // Pattern: Match all files in the specified directory
          /.*/
        )
      )
    }

    return this._deploymentSetsLookup
  }
}

// Deployment Sets exist in the `deployments` folder in the root of the project as JSON files
// They are loaded via a flat globbing process
const { deploymentSetsLookup } = new DeploymentSetGlobLoader()

type DeploymentSetLoader = () => {
  DEPLOYMENT_NAMES: string[]
  INITIAL_DEPLOYMENT_SET_NAME: string
}

/**
 * In production the active deployment set is driven by the hostname:
 * - app-v2.swellnetwork.io     -> mainnet
 * - testnet-v2.swellnetwork.io -> goerli-prod
 *
 * There is no mechanism to mutate the active deployment set
 */
const loadDeploymentSetProd: DeploymentSetLoader = () => {
  const { hostname } = window.location

  if (hostname === 'app.swellnetwork.io')
    return {
      INITIAL_DEPLOYMENT_SET_NAME: KnownDeploymentSets.MAINNET,
      DEPLOYMENT_NAMES: [KnownDeploymentSets.MAINNET],
    }
  if (hostname === 'guarded.swellnetwork.io')
    return {
      INITIAL_DEPLOYMENT_SET_NAME: KnownDeploymentSets.MAINNET,
      DEPLOYMENT_NAMES: [KnownDeploymentSets.MAINNET],
    }
  if (hostname === 'testnet.swellnetwork.io')
    return {
      INITIAL_DEPLOYMENT_SET_NAME: KnownDeploymentSets.GOERLI_PROD,
      DEPLOYMENT_NAMES: [KnownDeploymentSets.GOERLI_PROD],
    }

  throw new Error(`Unexpected hostname (production): ${hostname}`)
}

const loadDeploymentNamesFromEnv = () => {
  const REACT_APP_DEPLOYMENT_SETS = env.REACT_APP_DEPLOYMENT_SETS

  if (!REACT_APP_DEPLOYMENT_SETS)
    throw new Error(`Missing REACT_APP_DEPLOYMENT_SETS`)

  const DEPLOYMENT_NAMES = REACT_APP_DEPLOYMENT_SETS
    // remove trailing comma for convenience
    .replace(/,$/, '')
    .split(',')

  return { DEPLOYMENT_NAMES }
}

/**
 * In development (local dev, preview deployments, storybook) the app can be figured
 *  with any number of viable deployment sets.
 *
 * The first deployment set in the list is selected by default
 */
const loadDeploymentSetEnv: DeploymentSetLoader = () => {
  const { DEPLOYMENT_NAMES } = loadDeploymentNamesFromEnv()

  return { INITIAL_DEPLOYMENT_SET_NAME: DEPLOYMENT_NAMES[0], DEPLOYMENT_NAMES }
}

let loader: DeploymentSetLoader

switch (HOST_ENV) {
  case HostEnv.PRODUCTION:
    loader = loadDeploymentSetProd
    break
  case HostEnv.STAGING:
    loader = loadDeploymentSetEnv
    break
  case HostEnv.DEVELOPMENT:
    loader = loadDeploymentSetEnv
    break
  default:
    throw new Error(`invalid HOST_ENV: ${HOST_ENV}`)
}

const { INITIAL_DEPLOYMENT_SET_NAME, DEPLOYMENT_NAMES } = loader()

const DEPLOYMENT_SETS = DEPLOYMENT_NAMES.map((name) => {
  const deploymentSet = deploymentSetsLookup[name]
  if (!deploymentSet) throw new Error(`Missing deployment set: ${name}`)
  return deploymentSet
})

export { DEPLOYMENT_NAMES, DEPLOYMENT_SETS, INITIAL_DEPLOYMENT_SET_NAME }
