import React from 'react'
import styled from 'styled-components/macro'
import { Box } from '@swell-ui/Box'

import { OperatorsTable } from '@/components/OperatorsTable'
import { PageTitle } from '@/components/PageTitleOld'
import { useProtocolAllOperators } from '@/state/protocolOperators/hooks'

const ContainerBox = styled(Box)`
  padding: 24px 42px;
  max-width: 100%;
  overflow: hidden;
`

function AllOperators() {
  const allOperatorsQuery = useProtocolAllOperators()

  return (
    <>
      <PageTitle text="All operators" />
      <ContainerBox>
        <OperatorsTable allOperators={allOperatorsQuery.data?.allOperators} />
      </ContainerBox>
    </>
  )
}

export { AllOperators }
