import { compareHex, strip0x } from '@/util/hexStrings'
import { hexlify } from 'ethers/lib/utils'
import { IDepositInfoVerifier } from './IDepositInfoVerifier'

export const forkVersionVerifier: IDepositInfoVerifier = {
  verify: async (deposit, chainDepositConfig) => {
    const { genesisForkVersion } = chainDepositConfig
    const validForkVersionStr = strip0x(hexlify(genesisForkVersion))
    return !compareHex(deposit.fork_version, validForkVersionStr)
  },
}
