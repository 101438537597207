import {
  TOKEN_LIST_BTCLRT,
  TOKEN_LIST_BTCLRT_HOLESKY,
  TOKEN_LIST_BTCLRT_SEPOLIA,
  TOKEN_LIST_WBTC,
  TOKEN_LIST_WBTC_HOLESKY,
  TOKEN_LIST_WBTC_SEPOLIA,
} from '@/constants/tokens'
import useChainDetection from '@/hooks/useChainDetection'
import { Token } from '@/types/tokens'
import { IRateFetcher, IYearnVaultStatsFetcher, useYearnVaultImpl } from './api'
import { YearnVaultContext } from './context'
import {
  useContractAddresses,
  useDeploymentSetConfig,
  useTestnetAddressMaps,
} from '../deployments/hooks'
import { useMemo } from 'react'
import { ChainlinkGetRate } from '@/services/Tokens/getRate'
import { getContract } from '@/util/contract'
import { CHAINLINK_WBTC_ETH_ADDRESSES } from '@/constants/addresses'
import {
  AggregatorV3Interface,
  AggregatorV3Interface__factory,
} from '@/abis/types'
import { ethers } from 'ethers'
import { SupportedChainId } from '@/constants/chains'
import { useV3BackendClient } from '@/services/V3BackendService/hooks'

const BOOTSTRAP_APY = 3 // 3%

const invalidChain = () => {
  throw new Error('invalid chain')
}

const NO_API: YearnVaultContext = {
  depositAsset: TOKEN_LIST_WBTC,
  vaultToken: TOKEN_LIST_BTCLRT,
  read: {
    allowances: invalidChain,
    auth: invalidChain,
    balances: invalidChain,
    checkDeposit: invalidChain,
    paused: invalidChain,
    rates: invalidChain,
    supportedAssets: invalidChain,
    vaultStats: invalidChain,
    withdrawRequest: invalidChain,
    checkRequestWithdraw: invalidChain,
  },
  write: {
    approveAssetForDeposit: invalidChain,
    approveAssetForDepositEstimateGas: invalidChain,
    approveVaultTokenForWithdraw: invalidChain,
    approveVaultTokenForWithdrawEstimateGas: invalidChain,
    cancelWithdraw: invalidChain,
    cancelWithdrawEstimateGas: invalidChain,
    deposit: invalidChain,
    depositEstimateGas: invalidChain,
    completeWithdraw: invalidChain,
    completeWithdrawEstimateGas: invalidChain,
    requestWithdraw: invalidChain,
    requestWithdrawEstimateGas: invalidChain,
  },
}

function useWBtcEthRateFetcher(): IRateFetcher {
  // Temporarily use mainnet oracle even if on testnet
  const { rpcUrl } = useDeploymentSetConfig()
  return useMemo(() => {
    const mainnetAddr = CHAINLINK_WBTC_ETH_ADDRESSES[SupportedChainId.MAINNET]
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl.mainnet)
    const wbtcEthChainlinkOracle = getContract(
      mainnetAddr,
      AggregatorV3Interface__factory.abi,
      provider
    ) as AggregatorV3Interface

    return new ChainlinkGetRate(wbtcEthChainlinkOracle)
  }, [rpcUrl])
}

function useBtcLrtStatsFetcher(): IYearnVaultStatsFetcher {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const statsClient = useV3BackendClient(v3BackendLrtUrl).v3BackendClient.stats

  const { isTestnetChain, allowTestnet } = useChainDetection()

  return useMemo(() => {
    return {
      stats: async () => {
        if (isTestnetChain && allowTestnet) {
          // stubbed testnet
          return {
            apy: BOOTSTRAP_APY,
            totalDepositors: 1234,
          }
        }

        const { depositorCountUsers, vaultAprPercent } =
          await statsClient.btcLrt({})

        let totalDepositors = parseInt(depositorCountUsers, 10)
        if (isNaN(totalDepositors)) {
          totalDepositors = 0
        }

        let apy = parseFloat(vaultAprPercent)
        if (isNaN(apy)) {
          apy = BOOTSTRAP_APY
        }

        return {
          apy,
          totalDepositors,
        }
      },
    }
  }, [allowTestnet, isTestnetChain, statsClient])
}

export function useBtcLrtVaultApiImpl(): YearnVaultContext {
  const { chainId, deploymentChainId, isTestnetChain, allowTestnet } =
    useChainDetection()
  const addresses = useContractAddresses()
  const testnetAddressMaps = useTestnetAddressMaps()
  const depositAssetRateFetcher = useWBtcEthRateFetcher()
  const statsFetcher = useBtcLrtStatsFetcher()

  let delayedWithdrawalsAddress: string | undefined
  let rolesAuthorityAddress: string | undefined
  let vaultToken: Token | undefined
  let depositAsset: Token | undefined
  if (chainId === deploymentChainId) {
    delayedWithdrawalsAddress = addresses.btcLrtDelayedWithdrawals
    rolesAuthorityAddress = addresses.btcLrtRolesAuthority

    if (chainId === SupportedChainId.MAINNET) {
      vaultToken = TOKEN_LIST_BTCLRT
      depositAsset = TOKEN_LIST_WBTC
    } else {
      console.error('Invalid deployment chain id for BTC-LRT vault', chainId)
    }
  }

  if (allowTestnet && isTestnetChain) {
    delayedWithdrawalsAddress =
      testnetAddressMaps.btcLrtDelayedWithdrawals[chainId as SupportedChainId]
    rolesAuthorityAddress =
      testnetAddressMaps.btcLrtRolesAuthority[chainId as SupportedChainId]

    if (chainId === SupportedChainId.SEPOLIA) {
      vaultToken = TOKEN_LIST_BTCLRT_SEPOLIA
      depositAsset = TOKEN_LIST_WBTC_SEPOLIA
    } else if (chainId === SupportedChainId.HOLESKY) {
      vaultToken = TOKEN_LIST_BTCLRT_HOLESKY
      depositAsset = TOKEN_LIST_WBTC_HOLESKY
    } else {
      console.error('Invalid testnet chain id for BTC-LRT vault', chainId)
    }
  }

  let valid = true
  if (
    !vaultToken ||
    !depositAsset ||
    !delayedWithdrawalsAddress ||
    !rolesAuthorityAddress
  ) {
    valid = false
    console.error('Invalid deployment chain id for BTC-LRT vault', chainId)
    vaultToken = TOKEN_LIST_BTCLRT
    depositAsset = TOKEN_LIST_WBTC
    delayedWithdrawalsAddress = addresses.btcLrtDelayedWithdrawals
    rolesAuthorityAddress = addresses.btcLrtRolesAuthority
  }

  const api = useYearnVaultImpl({
    delayedWithdrawalsAddress,
    depositAsset,
    depositAssetRateFetcher,
    rolesAuthorityAddress,
    vaultToken,
    statsFetcher,
  })

  return valid ? api : NO_API
}
