import React from 'react'
import styled from 'styled-components/macro'
import { InfoIcon } from '../icons/InfoIcon'
import { Tooltip } from '../Tooltip'

interface InfoTooltipIconProps {
  text: string | React.ReactNode
}

interface KeyValueListItemType {
  label: string | React.ReactNode
  value: string | React.ReactNode
  hasInfo?: boolean
  infoText?: string | React.ReactNode
  component?: React.ReactNode
}

interface KeyValueListProps {
  config?: {
    list: KeyValueListItemType[]
  }
}

const KeyValueListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const KeyValueListWrapper = styled.div`
  ${({ theme }) => `
    font-size: ${theme.typography.body.xsmall.fontSize};
    line-height: ${theme.typography.body.xsmall.lineHeight};
  `}

  ${KeyValueListItem} {
    margin-bottom: 4px;
  }
`

const ComponentWrapper = styled.div`
  margin-bottom: 4px;
`

const KeyDiv = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;

  svg {
    position: relative;
    top: 2px;

    path {
      stroke: ${({ theme }) => theme.colors.white['125']};
    }
  }
`

const KeyLabel = styled.span`
  margin-right: 4px;
`

const ValueDiv = styled.div`
  text-align: right;
  font-weight: 500;
`

function InfoTooltipIcon(props: InfoTooltipIconProps) {
  return (
    <Tooltip title={props.text} placement="top" offset={[0, -10]} arrow>
      <InfoIcon />
    </Tooltip>
  )
}

function KeyValueList({ config, ...props }: KeyValueListProps) {
  if (!config) {
    console.error("KeyValueList must have 'config' prop.")
    return null
  }

  return (
    <KeyValueListWrapper {...props}>
      {config.list.map((item, index) => {
        if (item.component) {
          return (
            <ComponentWrapper key={index}>{item.component}</ComponentWrapper>
          )
        }

        return (
          <KeyValueListItem key={index}>
            <KeyDiv>
              <KeyLabel>{item.label}</KeyLabel>
              {item.hasInfo && item.infoText && (
                <InfoTooltipIcon text={item.infoText} />
              )}
            </KeyDiv>
            <ValueDiv>{item.value}</ValueDiv>
          </KeyValueListItem>
        )
      })}
    </KeyValueListWrapper>
  )
}

export { KeyValueList }
