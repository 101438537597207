import useSWRImmutable from 'swr/immutable'
import { getStakingUpperGasEstimate } from '@/constants/gasEstimates'
import { useSwETHContract, useSwETHContractView } from '@/hooks/useContract'
import { calculateGasMargin } from '@/util/calculateGasMargin'
import { serializeContractReceipt } from '@/util/transactionSerialization'
import { web3ErrorAdapter } from '@/util/web3ErrorAdapter'
import { BigNumber } from 'ethers'
import { useCallback, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useAppDispatch, useAppSelector } from '../hooks'
import * as stakingSlice from './reducer'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { default: _default, ...actions } = stakingSlice

export const useStakeNativeCurrencyState = () =>
  useAppSelector((state) => state.staking)

export const useStakingActions = () => {
  const dispatch = useAppDispatch()
  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch])
}

/**
 * Provides a callback for initiating the staking workflow. Takes the amount of native currency
 *  that will be staked as an input, then begins the staking workflow.
 */
export const useStakeNativeCurrency = () => {
  const swEthContract = useSwETHContract()
  const {
    clearStaking,
    onStake,
    setStakingError,
    setStakingReceipt,
    setStakingTxHash,
  } = useStakingActions()

  return useCallback(
    async (nativeCurrencyAmount: BigNumber, referrerAddress?: string) => {
      if (!swEthContract) return
      // Clear all previous data associated with a prior staking engagement
      clearStaking()

      onStake({ nativeCurrencyAmountHex: nativeCurrencyAmount._hex })

      try {
        const gasEstimateBN = await swEthContract.estimateGas.deposit({
          value: nativeCurrencyAmount,
        })
        const upperEstimateBN = getStakingUpperGasEstimate()

        // We generally expect the upper estimate to be larger.
        // Just in case, we take the largest of the upper-bound estimate and the true real-time estimate.
        const gasLimit = upperEstimateBN.gt(gasEstimateBN)
          ? upperEstimateBN
          : gasEstimateBN

        let tx
        // if a referrer address exists, we need to call the depositWithRefferal function
        if (referrerAddress) {
          tx = await swEthContract.depositWithReferral(referrerAddress, {
            value: nativeCurrencyAmount,
            gasLimit: calculateGasMargin(gasLimit),
          })
        } else {
          tx = await swEthContract.deposit({
            value: nativeCurrencyAmount,
            gasLimit: calculateGasMargin(gasLimit),
          })
        }

        const txHash = tx.hash

        setStakingTxHash({ txHash })

        // wait for the transaction to be confirmed on-chain, then serialize the receipt
        // The serialized receipt is the final output of this thunk (indicating success)
        const receipt = await tx.wait().then(serializeContractReceipt)

        setStakingReceipt({ receipt })
      } catch (err) {
        // convert the error to a human readable form
        setStakingError({
          error: web3ErrorAdapter(err, swEthContract.interface),
        })
      }
    },
    [
      swEthContract,
      clearStaking,
      onStake,
      setStakingTxHash,
      setStakingReceipt,
      setStakingError,
    ]
  )
}

const useStakingWhitelistEnabled = () => {
  const swEth = useSwETHContractView()

  return useSWRImmutable([swEth.address, 'whitelistEnabled'], async () => {
    return { whitelistEnabled: await swEth!.whitelistEnabled() }
  })
}

const useAddressIsWhitelisted = (address?: string) => {
  const swEth = useSwETHContractView()

  return useSWRImmutable(
    address ? [swEth.address, 'whitelistEnabled', address] : null,
    async () => {
      return { whitelisted: await swEth!.whitelistedAddresses(address!) }
    }
  )
}

// TODO: temporarily stubbed to prevent unnecessary calls while there is no plan for the whitelist
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useWhitelistForAddress = (address?: string) => {
  const { data: whitelistEnabledData, ...whitelistEnabledQuery } =
    useStakingWhitelistEnabled()

  const { data: whitelistData, ...addressIsWhitelistedQuery } =
    useAddressIsWhitelisted(address)

  return {
    get data() {
      if (whitelistEnabledData === undefined || whitelistData === undefined)
        return undefined
      const { whitelistEnabled } = whitelistEnabledData
      const { whitelisted } = whitelistData
      return {
        whitelistEnabled,
        whitelisted,
      }
    },
    error: addressIsWhitelistedQuery.error ?? whitelistEnabledQuery.error,
    isValidating:
      addressIsWhitelistedQuery.isValidating ||
      whitelistEnabledQuery.isValidating,
    isLoading:
      addressIsWhitelistedQuery.isLoading || whitelistEnabledQuery.isLoading,
  }
}

/**
 * This hook makes it as though swETH doesn't have a whitelist (user is always allowed to stake).
 * Eliminates the need to send a network request; more efficient
 */
const useWhitelistForAddressStub = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  address?: string
) => {
  return {
    get data() {
      return {
        whitelistEnabled: false,
        whitelisted: true,
      }
    },
    error: undefined,
    isValidating: false,
    isLoading: false,
  }
}

export { useWhitelistForAddressStub as useWhitelistForAddress }
