import React, { ReactNode, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { Table, TableConfig } from '@swell-ui/Table'
import { NodeOperatorInfo } from '@/types/operators'
import { useChainInfo } from '@/state/deployments/hooks'

type RowType = {
  opNum: ReactNode
  name: ReactNode
  address: ReactNode
  totalKeys: ReactNode
  usedKeys: ReactNode
  unusedKeys: ReactNode
}

const COLUMNS: TableConfig['columns'] = [
  {
    key: 'opNum',
    label: 'Operator number',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'address',
    label: 'Address',
  },
  {
    key: 'totalKeys',
    label: 'Total keys',
  },
  {
    key: 'usedKeys',
    label: 'Used keys',
  },
  {
    key: 'unusedKeys',
    label: 'Unused keys',
  },
]

const LOADING_LENGTH = 8

const StyledTable = styled(Table)`
  .MuiTableRow-head {
    .MuiTableCell-root {
      color: ${({ theme }) => theme.table.head.secondaryColor};
    }
  }

  .MuiTableCell-root {
    background: transparent;
  }
  .opNum-cell {
    padding-left: 0;
    min-width: 219px;
  }

  .name-cell {
    min-width: 157px;
  }

  .address-cell {
    min-width: 283px;

    &:hover {
      color: ${({ theme }) => theme.colors.lightBlue['50']};
      cursor: pointer;
    }
  }

  .totalKeys-cell {
    min-width: 206px;
  }

  .usedKeys-cell {
    min-width: 152px;
  }

  .unusedKeys-cell {
    min-width: 130px;
  }
`

export type OperatorsTableProps = {
  allOperators?: NodeOperatorInfo[]
}

function OperatorsTable({ allOperators }: OperatorsTableProps) {
  const { explorer } = useChainInfo()

  const loading = allOperators === undefined

  const mapOperatorToRow = useCallback(
    (operator: NodeOperatorInfo): RowType => {
      return {
        opNum: (
          <OperatorSpan enabled={operator.enabled}>
            {operator.operatorId}
          </OperatorSpan>
        ),
        address: (
          <OperatorSpan
            enabled={operator.enabled}
            as={'a'}
            href={`${explorer}/address/${operator.address}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {operator.address}
          </OperatorSpan>
        ),
        name: (
          <OperatorSpan enabled={operator.enabled}>
            {operator.name}
          </OperatorSpan>
        ),
        totalKeys: (
          <OperatorSpan enabled={operator.enabled}>
            {operator.totalKeys}
          </OperatorSpan>
        ),
        unusedKeys: (
          <OperatorSpan enabled={operator.enabled}>
            {operator.unusedKeys}
          </OperatorSpan>
        ),
        usedKeys: (
          <OperatorSpan enabled={operator.enabled}>
            {operator.usedKeys}
          </OperatorSpan>
        ),
      }
    },
    [explorer]
  )

  const config = useMemo<TableConfig>(() => {
    if (loading) {
      return {
        columns: COLUMNS,
        rows: [],
        loadingLength: LOADING_LENGTH,
        stickyHeader: true,
      }
    }

    return {
      columns: COLUMNS,
      rows: allOperators.map(mapOperatorToRow),
      loadingLength: LOADING_LENGTH,
      stickyHeader: true,
    }
  }, [allOperators, loading, mapOperatorToRow])

  return <StyledTable loading={loading} config={config} />
}

export { OperatorsTable }

const OperatorSpan = styled.span<{ enabled: boolean }>`
  opacity: ${(props) => (props.enabled ? 1 : 0.6)};
`
