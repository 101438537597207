import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { isAddress } from '@/util/hexStrings'
import { getAddress } from 'ethers/lib/utils'
import { Token } from '@/types/tokens'

const LogoImgContainer = styled.div<any>`
  width: 24px;
  height: 24px;

  ${({ size }) => size && `height: ${size}px;`}
  ${({ size }) => size && `width: ${size}px;`}
`

const LogoImg = styled.img<any>`
  height: 24px;
  border-radius: 50%;

  ${({ size }) => size && `height: ${size}px;`}
`

const MissingLogoImg = styled.div<any>`
  border-radius: 100px;
  height: 24px;
  width: 24px;
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.black['50']};

  ${({ size }) =>
    size &&
    css`
      height: ${size}px;
      width: ${size}px;
    `}
`

const StyledEthIcon = styled<any>(EthIcon)`
  height: 24px;
  width: 24px;

  ${({ size }) =>
    size &&
    css`
      height: ${size}px;
      width: ${size}px;
    `}
`

function TokenLogo({ token, size, ...props }: { token: Token; size?: number }) {
  const tokenAddress = token.address
  const logoURI = token.logoURI

  const urlOptions: string[] = []
  if (logoURI) {
    urlOptions.push(logoURI)
  }
  if (isAddress(tokenAddress)) {
    urlOptions.push(
      `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/${getAddress(
        tokenAddress
      )}/logo.png`
    )
  }

  const [fallbackIndex, setFallbackIndex] = useState<number>(0)
  useEffect(() => {
    setFallbackIndex(0)
  }, [tokenAddress])

  const src =
    fallbackIndex < urlOptions.length ? urlOptions[fallbackIndex] : undefined

  const onImgError = () => {
    setFallbackIndex(fallbackIndex + 1)
  }

  if (token.symbol === 'ETH') {
    return <StyledEthIcon size={size} {...props} />
  }

  return (
    <LogoImgContainer
      size={size}
      data-qa="token-img"
      data-index={fallbackIndex}
    >
      {src && <LogoImg src={src} onError={onImgError} size={size} {...props} />}
      {!src && (
        <MissingLogoImg size={size}>{token.symbol.substr(0, 3)}</MissingLogoImg>
      )}
    </LogoImgContainer>
  )
}

export { TokenLogo }
