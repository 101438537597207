const typography = {
  fontFamily: 'Inter',
  body: {
    xsmall: {
      fontSize: '12px',
      lineHeight: '160%',
    },
    small: {
      fontSize: '14px',
      lineHeight: '160%',
    },
    medium: {
      fontSize: '16px',
      lineHeight: '160%',
    },
    large: {
      fontSize: '24px',
      lineHeight: '160%',
    },
    xlarge: {
      fontSize: '32px',
      lineHeight: '160%',
    },
  },
  headline: {
    display: {
      fontSize: '80px',
      lineHeight: '110%',
    },
    h1: {
      fontSize: '60px',
      lineHeight: '120%',
    },
    h2: {
      fontSize: '48px',
      lineHeight: '120%',
    },
    h3: {
      fontSize: '40px',
      lineHeight: '120%',
    },
    h4: {
      fontSize: '32px',
      lineHeight: '120%',
    },
    h5: {
      fontSize: '26px',
      lineHeight: '130%',
    },
    h6: {
      fontSize: '20px',
      lineHeight: '130%',
    },
  },
}

export { typography }
