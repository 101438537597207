import React from 'react'

function ArrowUpRightIcon(props: any) {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.52977 23.5298L7.05955 24L8 24.9405L8.47023 24.4702L7.52977 23.5298ZM24.4702 8.47023C24.7299 8.21053 24.7299 7.78947 24.4702 7.52977C24.2105 7.27008 23.7895 7.27008 23.5298 7.52977L24.4702 8.47023ZM8.47023 24.4702L24.4702 8.47023L23.5298 7.52977L7.52977 23.5298L8.47023 24.4702Z"
        fill="white"
      />
      <path
        d="M11 8H24V21"
        stroke="white"
        strokeWidth="1.33"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { ArrowUpRightIcon }
