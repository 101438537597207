import { css } from 'styled-components'

export const labelStyle = css`
  color: var(--white-125, #b0b0b0);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.9px;
  text-transform: uppercase;

  ::after {
    content: ':';
  }
`
export const valueStyle = css`
  color: var(--white-50, #fff);
  /* Body/medium bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
`

export const vScrollStyle = css`
  ${({ theme }) =>
    theme.scrollbar &&
    css`
      &::-webkit-scrollbar {
        width: ${theme.scrollbar.width};
      }

      &::-webkit-scrollbar-track {
        background: ${theme.scrollbar.trackBg};
        border-radius: ${theme.scrollbar.borderRadius};
      }

      &::-webkit-scrollbar-thumb {
        background: ${theme.scrollbar.thumbBg};
        border-radius: ${theme.scrollbar.borderRadius};
      }
    `}
`

export const hScrollStyle = css`
  ${({ theme }) =>
    theme.scrollbar &&
    css`
      &::-webkit-scrollbar {
        height: ${theme.scrollbar.width};
      }

      &::-webkit-scrollbar-track {
        background: ${theme.scrollbar.trackBg};
        border-radius: ${theme.scrollbar.borderRadius};
      }

      &::-webkit-scrollbar-thumb {
        background: ${theme.scrollbar.thumbBg};
        border-radius: ${theme.scrollbar.borderRadius};
      }
    `}
`
