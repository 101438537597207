import { ReactNode, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { Divider } from '@swell-ui/Divider'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { ExternalLinkIcon } from '@swell-ui/icons/ExternalLinkIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { Popper } from '@swell-ui/Popper'
import { RswethIcon } from '@swell-ui/icons/RswethIcon'
import { Typography } from '@swell-ui/Typography'
import { formatWithConfig } from '@/util/number'
import { ExitAsset, ExitAssets, ExitClaim } from '@/types/claims'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { RadioButton } from '@/swell-ui/RadioButton'
import { useChainInfo } from '@/state/deployments/hooks'
import { RestakeClaimSelect } from '../Exit/exitHooks'

type ExitClaimAssetSummary = {
  numClaimable: number
  assetAmountClaimable: number
}

type ExitClaimSummary = {
  numClaimable: number
  claimableByAsset: Record<string, ExitClaimAssetSummary>
  numPending: number
  rswethAmountPending: string
}

const ClaimViewContainer = styled(FlexRow)`
  padding-top: 16px;
`

const BorderedFlex = styled<any>(FlexRow)`
  position: relative;
  padding: 10px 12px;
  border: 1px solid ${({ theme }) => theme.colors.auxGrey};
  border-radius: 8px;
  align-self: stretch;
`

const ClaimRowsFlex = styled<any>(FlexRow)`
  min-height: 314px;
  ${({ numRows }) => numRows > 6 && `overflow-y: scroll;`}
`

const Grow = styled.div`
  flex-grow: 1;
`

const GreenDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: rgba(26, 255, 75, 1);
`

const YellowDot = styled(GreenDot)`
  background: ${({ theme }) => theme.colors.eigenLayer['yellow']};
`

const StyledEthIcon = styled(EthIcon)`
  width: 16px;
  height: 16px;
`

const StyledRswethIcon = styled(RswethIcon)`
  width: 16px;
  height: 16px;
`
const TokenIcon = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 40px;
`

const ExternalA = styled.a`
  display: flex;
`

const StyledLinkIcon = styled(ExternalLinkIcon)`
  height: 16px;
  width: 19px;

  &:hover {
    opacity: 0.7;
  }

  path {
    stroke: ${({ theme }) => theme.mainColor};
  }
`

const Status = styled.div`
  text-transform: capitalize;
`

const StyledPopper = styled(Popper)`
  z-index: 50;
  color: ${({ theme }) => theme.mainColor};
`

const ClaimTooltip = styled.div`
  position: relative;
  left: 30px;
  top: 8px;
  width: 240px;
  height: 117px;
`

const TooltipText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
  padding-top: 16px;
  z-index: 2;
  font-size: 12px;
  color: #180d68;
  white-space: nowrap;
`

const TooltipLabel = styled.span``

function formatNumber(amount: number) {
  return formatWithConfig(amount, {
    localize: true,
    precision: 4,
  })
}

// formatClaimDate formats a unix timestamp to the form "2023.12.25 12:12"
function formatClaimDate(ts: number) {
  const d = new Date(ts * 1000)
  const year = d.getFullYear()
  const month = d.getMonth() + 1
  const day = d.getDate()
  const hours = String(d.getHours()).padStart(2, '0')
  const minutes = String(d.getMinutes()).padStart(2, '0')
  return `${year}.${month}.${day} ${hours}:${minutes}`
}

const isPending = (claim: ExitClaim): boolean => {
  return claim.status.toLowerCase() === 'pending'
}

const isClaimable = (claim: ExitClaim): boolean => {
  return claim.status.toLowerCase() === 'claimable'
}

function ClaimRow({
  claim,
  exitAsset,
  onClaimSelect,
  selected,
}: {
  claim: ExitClaim
  exitAsset: ExitAsset
  onClaimSelect: () => any
  selected: boolean
}) {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const anchorRef = useRef<HTMLElement | null>(null)
  const { explorer } = useChainInfo()

  const assetSymbol = exitAsset.symbol
  const assetLogoURI = exitAsset.logoURI
  const exchangeRate = claim.exchangeRate
  const scannerUrl = `${explorer}/nft/${exitAsset.exitAddress}/${claim.requestId}`

  return (
    <>
      <BorderedFlex ref={anchorRef}>
        <Grow>
          <FlexRow gap="8">
            {isPending(claim) && (
              <>
                <StyledRswethIcon />
                <div>{formatNumber(claim.tokenAmount)} rswETH</div>
              </>
            )}
            {isClaimable(claim) && assetSymbol && (
              <>
                {assetSymbol === TOKEN_LIST_ETH.symbol ? (
                  <StyledEthIcon />
                ) : (
                  <TokenIcon src={assetLogoURI} />
                )}
                <div>
                  {formatNumber(claim.assetAmount)} {assetSymbol}
                </div>
              </>
            )}
          </FlexRow>
        </Grow>
        <div>
          <FlexRow gap="9">
            {isClaimable(claim) && (
              <RadioButton
                onClick={onClaimSelect}
                selected={selected}
                isRestakeTheme
              />
            )}
            <FlexRow gap="8">
              {isPending(claim) ? <YellowDot /> : <GreenDot />}
              <Status
                onMouseEnter={() => setShowTooltip(true)}
                onMouseOut={() => setShowTooltip(false)}
              >
                {claim.status}
              </Status>
            </FlexRow>
            <ExternalA href={scannerUrl} target="_blank" rel="noreferrer">
              <StyledLinkIcon />
            </ExternalA>
          </FlexRow>
        </div>
      </BorderedFlex>
      {isPending(claim) && showTooltip && (
        <StyledPopper
          id={claim.requestId}
          open={true}
          anchorEl={anchorRef.current}
        >
          <ClaimTooltip>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="240"
              height="117"
              viewBox="0 0 240 117"
              fill="none"
              overflow="visible"
            >
              <path
                transform={'scale(1.07)'}
                d="M1 112V11.4919C1 9.2828 2.79086 7.49194 4.99999 7.49194H195.869C196.93 7.49194 197.948 7.06994 198.699 6.3189L203.18 1.83281C204.742 0.268768 207.277 0.268769 208.84 1.83282L213.321 6.3189C214.071 7.06994 215.09 7.49194 216.151 7.49194H235C237.209 7.49194 239 9.2828 239 11.4919V112C239 114.209 237.209 116 235 116H5C2.79086 116 1 114.209 1 112Z"
                fill={'#edf2fd'}
              />
            </svg>
            <TooltipText>
              <Typography variant="body" size="small" letterSpacing="small">
                <div style={{ fontWeight: 600 }}>
                  <TooltipLabel>Queue position:</TooltipLabel>&nbsp;
                  {claim.queuePosition} of {claim.totalInQueue}
                </div>
                <div>
                  <TooltipLabel>Request timestamp:</TooltipLabel>&nbsp;
                  {formatClaimDate(claim.requestTimestamp)}
                </div>
                <div>
                  <TooltipLabel>rswETH-{assetSymbol} rate:</TooltipLabel>&nbsp;
                  {formatNumber(exchangeRate)}
                </div>
                <div>
                  <TooltipLabel>{assetSymbol}:</TooltipLabel>&nbsp;
                  {formatNumber(claim.assetAmount)}
                </div>
              </Typography>
            </TooltipText>
          </ClaimTooltip>
        </StyledPopper>
      )}
    </>
  )
}

function ClaimableAssetSummary({
  assetSymbol,
  claimableAssetAmount,
  numClaimable,
  logoURI,
}: {
  numClaimable: number
  claimableAssetAmount: number
  assetSymbol: string
  logoURI: string
}) {
  let logo = <TokenIcon src={logoURI} />
  if (assetSymbol === 'ETH') {
    logo = <StyledEthIcon />
  }

  return (
    <Typography variant="body" size="small" letterSpacing="small">
      <FlexRow gap="6">
        <div>{numClaimable}</div>
        <div>|</div>
        <FlexRow gap="3">
          {logo}
          <div>
            {formatNumber(claimableAssetAmount)} {assetSymbol}
          </div>
        </FlexRow>
      </FlexRow>
    </Typography>
  )
}

export function RestakingClaimView({
  claimSelect,
  exitAssets,
}: {
  claimSelect: RestakeClaimSelect
  exitAssets: ExitAssets
}) {
  const claims = claimSelect.flattenedClaims!
  const { selectedIndex, setSelectedIndex } = claimSelect

  const numClaimable = useMemo<number>(() => {
    if (!claims) {
      return 0
    }
    const claimableClaims = claims.filter((claim: any) => {
      return isClaimable(claim)
    })

    return claimableClaims.length
  }, [claims])

  const claimSummary = useMemo<ExitClaimSummary>(() => {
    const claimableByAsset: ExitClaimSummary['claimableByAsset'] = {}
    let numPending = 0
    let rswethAmountPending = 0

    if (claims) {
      claims.forEach((claim) => {
        const { assetAmount, address: assetAddr } = claim
        if (isClaimable(claim)) {
          claimableByAsset[assetAddr] = claimableByAsset[assetAddr] ?? {
            assetAmountClaimable: 0,
            numClaimable: 0,
          }
          claimableByAsset[assetAddr].assetAmountClaimable += assetAmount
          claimableByAsset[assetAddr].numClaimable += 1
        } else {
          numPending++
          rswethAmountPending += claim.tokenAmount
        }
      })

      if (numClaimable === 1) {
        setSelectedIndex(0)
      }
    }

    return {
      numClaimable,
      claimableByAsset,
      rswethAmountPending: formatNumber(rswethAmountPending),
      numPending,
    }
  }, [claims, numClaimable, setSelectedIndex])

  const assetSummaryNodes: ReactNode[] = []
  for (const exitAsset of exitAssets) {
    assetSummaryNodes.push(
      <ClaimableAssetSummary
        key={exitAsset.exitAddress}
        assetSymbol={exitAsset.symbol}
        claimableAssetAmount={
          claimSummary.claimableByAsset[exitAsset.address]
            ?.assetAmountClaimable ?? 0
        }
        numClaimable={
          claimSummary.claimableByAsset[exitAsset.address]?.numClaimable ?? 0
        }
        logoURI={exitAsset.logoURI}
      />
    )
  }

  return (
    <ClaimViewContainer direction="column" align="flex-start" gap="16">
      <Typography variant="body" size="large">
        Claim unstaked rswETH
      </Typography>
      {claims && (
        <>
          <BorderedFlex justify="space-between" align="flex-start">
            <Grow>
              <FlexRow direction="column" align="flex-start" gap="12">
                <FlexRow gap="7">
                  <GreenDot />
                  <Typography
                    variant="body"
                    size="medium"
                    letterSpacing="small"
                  >
                    Claimable
                  </Typography>
                </FlexRow>
                {assetSummaryNodes}
              </FlexRow>
            </Grow>
            <div>
              <FlexRow direction="column" align="flex-start" gap="12">
                <FlexRow gap="7">
                  <YellowDot />
                  <Typography
                    variant="body"
                    size="medium"
                    letterSpacing="small"
                  >
                    Pending
                  </Typography>
                </FlexRow>
                <Typography variant="body" size="small" letterSpacing="small">
                  <FlexRow gap="6">
                    <div>{claimSummary.numPending}</div>
                    <div>|</div>
                    <FlexRow gap="3">
                      <StyledRswethIcon />
                      <div>{claimSummary.rswethAmountPending} rswETH</div>
                    </FlexRow>
                  </FlexRow>
                </Typography>
              </FlexRow>
            </div>
          </BorderedFlex>
          <Divider margin="0" />
          <ClaimRowsFlex
            direction="column"
            gap="12"
            numRows={claims ? claims.length : 0}
          >
            {claims &&
              claims.map((claim, index) => {
                const exitAddress = claim.exitAddress
                if (!exitAddress) return null
                return (
                  <ClaimRow
                    key={claim.requestId + String(claim.exitAddress)}
                    claim={claim}
                    exitAsset={claim}
                    onClaimSelect={() => setSelectedIndex(index)}
                    selected={selectedIndex === index}
                  />
                )
              })}
          </ClaimRowsFlex>
        </>
      )}
    </ClaimViewContainer>
  )
}
