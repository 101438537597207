import { useWeb3Button } from '@/hooks/useWeb3Button'
import { Button } from '@/swell-ui/Button'
import { CircularProgress } from '@/swell-ui/CircularProgress'
import { useState } from 'react'
import styled from 'styled-components'
import { UnstakeConfirmModal } from './Exit/UnstakeConfirmModal'
import {
  PreparedApproveRswETHForWithdrawal,
  PreparedCreateWithdrawRequestRswETH,
  PreparedFinalizeWithdrawalRswETH,
} from './Exit/exitCalls'
import {
  ApproveRswETHForWithdrawal,
  CreateWithdrawRequestRswETH,
  FinalizeWithdrawalRswETH,
} from '@/state/rsweth/hooks'

const UNSTAKE_DELAY_EXPLAINER_LINK =
  'https://docs.swellnetwork.io/swell-staking/rsweth-liquid-restaking/rsweth-v1.0-system-design/withdrawals'

const StyledButton = styled(Button)`
  position: relative;
  width: 100%;
`
const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`
const ButtonInner = styled.span`
  position: relative;
  > div {
    position: absolute;
    top: -7px;
    left: -32px;
  }
`

export function ApproveRswETHForWithdrawalButton({
  prepared,
  approveRswETHForWithdrawal,
  disabled: disabledOverride,
}: {
  prepared: PreparedApproveRswETHForWithdrawal
  approveRswETHForWithdrawal: ApproveRswETHForWithdrawal
  disabled?: boolean
}) {
  const btn = useWeb3Button(
    approveRswETHForWithdrawal,
    approveRswETHForWithdrawal.call,
    prepared.args,
    {
      idleLabel: 'Approve',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Approve',
    }
  )

  return (
    <StyledButton
      disabled={disabledOverride || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </StyledButton>
  )
}

export function CreateWithdrawRequestRswETHButton({
  prepared,
  createWithdrawRequest,
  disabled: disabledOverride,
  withdrawalDelayDurationDays,
}: {
  prepared: PreparedCreateWithdrawRequestRswETH
  createWithdrawRequest: CreateWithdrawRequestRswETH
  disabled?: boolean
  withdrawalDelayDurationDays: number
}) {
  const [modalOpen, setModalOpen] = useState(false)

  const btn = useWeb3Button(
    createWithdrawRequest,
    createWithdrawRequest.call,
    prepared.args,
    {
      idleLabel: 'Unstake',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Unstake',
    }
  )

  const onClose = (accepted: boolean) => {
    setModalOpen(false)
    if (accepted) {
      btn.onClick()
    }
  }
  const open = () => {
    if (createWithdrawRequest.status === createWithdrawRequest.STATUS.IDLE) {
      setModalOpen(true)
    }
  }

  return (
    <>
      <StyledButton disabled={disabledOverride || btn.disabled} onClick={open}>
        {btn.label}
      </StyledButton>
      <UnstakeConfirmModal
        open={modalOpen}
        onClose={onClose}
        withdrawalDelayDurationDays={withdrawalDelayDurationDays}
        isRestakeTheme
        delayExplainerLink={UNSTAKE_DELAY_EXPLAINER_LINK}
      />
    </>
  )
}

export function FinalizeWithdrawalRswETHButton({
  prepared,
  finalizeWithdrawal,
  disabled: disabledOverride,
}: {
  prepared: PreparedFinalizeWithdrawalRswETH
  finalizeWithdrawal: FinalizeWithdrawalRswETH
  disabled?: boolean
}) {
  const btn = useWeb3Button(
    finalizeWithdrawal,
    finalizeWithdrawal.call,
    prepared.args,
    {
      idleLabel: 'Claim',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Claim',
    }
  )

  return (
    <StyledButton
      disabled={disabledOverride || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </StyledButton>
  )
}
