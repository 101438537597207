import { Button, ButtonProps } from '@/swell-ui/Button'
import { CircularProgress } from '@/swell-ui/CircularProgress'
import styled from 'styled-components'
import {
  YearnApproveAssetForDeposit,
  YearnApproveVaultTokenForWithdraw,
  YearnCancelWithdraw,
  YearnCompleteWithdraw,
  YearnDeposit,
  YearnRequestWithdraw,
} from '@/state/yearnVault/hooks'
import {
  PreparedYearnApproveAssetForDeposit,
  PreparedYearnApproveVaultTokenForWithdraw,
  PreparedYearnCancelWithdraw,
  PreparedYearnCompleteWithdraw,
  PreparedYearnDeposit,
  PreparedYearnRequestWithdraw,
} from './yearnCalls'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { SwitchChainButton } from '@/components/SwitchChainButton'
import { useWeb3Button } from '@/hooks/useWeb3Button'

const StyledButton = styled(Button)`
  position: relative;
  width: 100%;
`
const StyledConnectButton = styled(ConnectWalletButton)`
  width: 100%;
`
const StyledSwitchChainButton = styled(SwitchChainButton)`
  width: 100%;
`

export function YearnButton(props: ButtonProps) {
  return (
    <StyledButton
      variant="primary"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </StyledButton>
  )
}

export function YearnConnectButton(props: ButtonProps) {
  return <StyledConnectButton {...props} variant="primary" />
}
export function YearnSwitchChainButton() {
  return <StyledSwitchChainButton variant="primary" />
}
const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: currentColor;
  }
`
const ButtonInner = styled.span`
  position: relative;
  > div {
    position: absolute;
    top: -7px;
    left: -32px;
  }
`
export function YearnApproveAssetForDepositButton({
  approveAssetForDeposit,
  prepared,
  preventInteraction,
}: {
  approveAssetForDeposit: YearnApproveAssetForDeposit
  prepared: PreparedYearnApproveAssetForDeposit
  preventInteraction?: boolean
}) {
  const btn = useWeb3Button(
    approveAssetForDeposit,
    approveAssetForDeposit.call,
    prepared.args,
    {
      idleLabel: 'Approve',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Approve',
    }
  )

  return (
    <YearnButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </YearnButton>
  )
}
export function YearnApproveVaultTokenForWithdrawButton({
  approveVaultTokenForWithdraw,
  prepared,
  preventInteraction,
}: {
  approveVaultTokenForWithdraw: YearnApproveVaultTokenForWithdraw
  prepared: PreparedYearnApproveVaultTokenForWithdraw
  preventInteraction: boolean
}) {
  const btn = useWeb3Button(
    approveVaultTokenForWithdraw,
    approveVaultTokenForWithdraw.call,
    prepared.args,
    {
      idleLabel: 'Approve',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Approve',
    }
  )
  return (
    <YearnButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </YearnButton>
  )
}

export function YearnDepositButton({
  deposit,
  prepared,
  preventInteraction,
}: {
  deposit: YearnDeposit
  prepared: PreparedYearnDeposit
  preventInteraction: boolean
}) {
  const btn = useWeb3Button(deposit, deposit.call, prepared.args, {
    idleLabel: 'Deposit',
    pendingLabel: (
      <ButtonInner>
        <div>
          <ButtonCircularProgress size={24} />
        </div>
        <span>Confirming...</span>
      </ButtonInner>
    ),
    fulfilledLabel: 'Deposit',
  })

  return (
    <YearnButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </YearnButton>
  )
}
export function YearnRequestWithdrawButton({
  requestWithdraw,
  prepared,
  preventInteraction,
}: {
  requestWithdraw: YearnRequestWithdraw
  prepared: PreparedYearnRequestWithdraw
  preventInteraction: boolean
}) {
  const btn = useWeb3Button(
    requestWithdraw,
    requestWithdraw.call,
    prepared.args,
    {
      idleLabel: 'Request withdrawal',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Requested withdrawal',
    }
  )

  return (
    <YearnButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </YearnButton>
  )
}

export function YearnCancelWithdrawButton({
  cancelWithdraw,
  prepared,
  preventInteraction,
}: {
  cancelWithdraw: YearnCancelWithdraw
  prepared: PreparedYearnCancelWithdraw
  preventInteraction: boolean
}) {
  const btn = useWeb3Button(
    cancelWithdraw,
    cancelWithdraw.call,
    prepared.args,
    {
      idleLabel: 'Cancel withdrawal',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Canceled',
    }
  )

  return (
    <YearnButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </YearnButton>
  )
}

export function YearnCompleteWithdrawButton({
  completeWithdraw,
  prepared,
  preventInteraction,
}: {
  completeWithdraw: YearnCompleteWithdraw
  prepared: PreparedYearnCompleteWithdraw
  preventInteraction: boolean
}) {
  const btn = useWeb3Button(
    completeWithdraw,
    completeWithdraw.call,
    prepared.args,
    {
      idleLabel: 'Claim withdrawal',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Claimed',
    }
  )

  return (
    <YearnButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </YearnButton>
  )
}
