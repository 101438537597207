import styled from 'styled-components/macro'
import { KeyValueList } from '@swell-ui/KeyValueList'
import { StakingPoolActivity } from '@/components/StakingPoolActivity'
import {
  useCommissionRate,
  useStakerCountUsers,
  useTotalEthStaked,
} from '@/state/stakingStats/hooks'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { useDisplayNativeCurrency } from '@/hooks/useNativeCurrencyDisplay'
import { useSwEthMarketCap } from '@/hooks/useSwEthMarketCap'
import { displayFiat } from '@/util/displayFiat'
import { StakeTransactionType } from '@/submodules/v3-shared/ts/connect/swell/v3/staker_pb'

const ActivityContainer = styled.div`
  margin-top: 24px;
`

export function StakingStatisticsAccordionDetails() {
  const totalEthStaked = useTotalEthStaked()
  const stakerCountUsers = useStakerCountUsers()

  const commissionRate = useCommissionRate()

  const swEthMarketCapQuery = useSwEthMarketCap()
  const displayNativeCurrency = useDisplayNativeCurrency()

  const commissionRateConfig = {
    label: 'Commission rate',
    value: (
      <AsyncDiv loading={!commissionRate.data}>
        {() =>
          `${
            commissionRate.data!.nodeOperatorRewardPercentage +
            commissionRate.data!.swellTreasuryRewardPercentage
          }%`
        }
      </AsyncDiv>
    ),
  }

  const totalEthStakedConfig = {
    label: 'Total ETH staked',
    value: (
      <AsyncDiv loading={!totalEthStaked.data}>
        {() => {
          return displayNativeCurrency(totalEthStaked.data!.value, {
            precision: 0,
            localize: true,
          })
        }}
      </AsyncDiv>
    ),
  }

  const stakersConfig = {
    label: 'Stakers',
    value: (
      <AsyncDiv loading={!stakerCountUsers.data}>
        {() => stakerCountUsers.data!.value}
      </AsyncDiv>
    ),
  }

  const swEthMarketCapConfig = {
    label: 'swETH market cap',
    value: (
      <AsyncDiv loading={!swEthMarketCapQuery.data}>
        {() => {
          const { marketCapUsd } = swEthMarketCapQuery.data!
          return `${displayFiat(marketCapUsd)} USD`
        }}
      </AsyncDiv>
    ),
  }

  const keyValueListConfig = {
    list: [
      commissionRateConfig,
      totalEthStakedConfig,
      stakersConfig,
      swEthMarketCapConfig,
    ],
  }

  return (
    <>
      <KeyValueList config={keyValueListConfig} />
      <ActivityContainer>
        <StakingPoolActivity
          title="Staking pool activity"
          stakeTransactionType={StakeTransactionType.STAKE}
        />
      </ActivityContainer>
    </>
  )
}
