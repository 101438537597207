import React from 'react'
import {
  useUserOperator,
  useUserOperatorActiveValidatorKeys,
  useUserOperatorPendingValidatorKeys,
} from '@/state/restakingUserOperator/hooks'
import { isNoOperatorFoundError } from '@/state/userOperator/util'
import {
  useProtocolBufferedEth,
  useProtocolLastDepositAt,
  useProtocolUnusedKeysCount,
} from '@/state/restakingProtocolOperators/hooks'
import { queryHasInfo } from '@/util/queries'
import { DepositManagerCommon } from './DepositManagerCommon'
import { DepositManagerDataBoxGridProps } from './DepositManagerCommon/DepositManagerDataBoxGrid'
import { YourInfoDataBoxGridProps } from './DepositManagerCommon/YourInfoDataBoxGrid'

export function RestakingDepositManager() {
  const protocolBufferedEthQuery = useProtocolBufferedEth()
  const protocolLastDepositAtQuery = useProtocolLastDepositAt()
  const protocolUnusedKeysCountQuery = useProtocolUnusedKeysCount()

  // user operator queries
  const userOperator = useUserOperator()
  const activeValidatorKeysQuery = useUserOperatorActiveValidatorKeys()
  const pendingValidatorKeysQuery = useUserOperatorPendingValidatorKeys()

  // presentational component props
  const depositManagerProps =
    React.useMemo<DepositManagerDataBoxGridProps>(() => {
      return {
        get bufferedEth() {
          if (!protocolBufferedEthQuery.data) return { loading: true as const }
          return {
            loading: false as const,
            value: protocolBufferedEthQuery.data.bufferedEth,
          }
        },
        get lastDepositAt() {
          if (!protocolLastDepositAtQuery.data)
            return { loading: true as const }
          return {
            loading: false as const,
            value: protocolLastDepositAtQuery.data.lastDepositAt,
          }
        },
        get unusedKeysCount() {
          if (!protocolUnusedKeysCountQuery.data)
            return { loading: true as const }
          return {
            loading: false as const,
            value: protocolUnusedKeysCountQuery.data.unusedKeysCount.toNumber(),
          }
        },
      }
    }, [
      protocolBufferedEthQuery,
      protocolLastDepositAtQuery,
      protocolUnusedKeysCountQuery,
    ])

  const yourInfoProps = React.useMemo<YourInfoDataBoxGridProps>(() => {
    if (!queryHasInfo([activeValidatorKeysQuery, pendingValidatorKeysQuery]))
      return { kind: 'loading' }

    const error =
      activeValidatorKeysQuery.error ?? pendingValidatorKeysQuery.error

    if (error) {
      // special case: non-operator accounts have a different view
      if (isNoOperatorFoundError(error)) return { kind: 'non-operator-account' }
      return { kind: 'hidden' }
    }

    const { activeValidatorKeys } = activeValidatorKeysQuery.data!
    const { pendingValidatorKeys } = pendingValidatorKeysQuery.data!

    const usedKeys = activeValidatorKeys.length
    const unusedKeys = pendingValidatorKeys.length
    const totalKeys = usedKeys + unusedKeys

    return { kind: 'operator', totalKeys, unusedKeys, usedKeys }
  }, [activeValidatorKeysQuery, pendingValidatorKeysQuery])

  let infoLabelPrefix = 'Your'

  if (userOperator.data) {
    const {
      operator: { name },
    } = userOperator.data
    infoLabelPrefix = `${name} `
  }

  return (
    <DepositManagerCommon
      depositManagerProps={depositManagerProps}
      infoLabelPrefix={infoLabelPrefix}
      yourInfoProps={yourInfoProps}
    />
  )
}
