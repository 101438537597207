import { useState } from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { BigNumber } from 'ethers'
import { useSwExitContract } from '@/hooks/useContract'
import { calculateGasMargin } from '@/util/calculateGasMargin'
import {
  getStakingUpperGasEstimate,
  getSwETHCreateWithdrawRequestGasEstimate,
} from '@/constants/gasEstimates'

export interface CreateWithdrawRequestTransactionResponse {
  tx?: any
  error?: {
    message: string
  }
}

export interface UseCreateWithdrawRequest {
  isLoading: boolean
  clear: () => void
  sendTransaction: (
    swethAmount: BigNumber
  ) => Promise<CreateWithdrawRequestTransactionResponse>
  amount: BigNumber
  setStatus: (status: string) => void
  status: string
  STATUS: {
    [key: string]: string
  }
  tx: any
}

export function useCreateWithdrawRequest(): UseCreateWithdrawRequest {
  const { account, provider } = useSwellWeb3()
  const swExitContract = useSwExitContract()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [amount, setAmount] = useState<BigNumber>(BigNumber.from(0))

  const STATUS = {
    IDLE: 'idle',
    PROMPTING: 'prompting',
    PENDING: 'pending',
    FULFILLED: 'fulfilled',
    ERROR: 'error',
  }
  const [status, setStatus] = useState<string>(STATUS.IDLE)
  const [tx, setTx] = useState<any>(undefined)

  const clear = () => {
    setStatus(STATUS.IDLE)
    setTx(undefined)
  }

  const sendTransaction = async (swethAmount: BigNumber) => {
    if (!swExitContract) {
      return {
        error: { message: 'SWExit contract undefined.' },
      }
    }

    if (!swethAmount) {
      return {
        error: { message: 'No swETH amount specified.' },
      }
    }

    setAmount(amount)

    setIsLoading(true)

    let tx
    try {
      // const gasEstimateBN =
      // await swExitContract.estimateGas.createWithdrawRequest(swethAmount)
      const gasLimit = getSwETHCreateWithdrawRequestGasEstimate()
      // const gasLimit = upperEstimateBN.gt(gasEstimateBN)
      // ? upperEstimateBN
      // : gasEstimateBN

      setStatus(STATUS.PROMPTING)

      const tx = await swExitContract.createWithdrawRequest(swethAmount, {
        gasLimit: calculateGasMargin(gasLimit),
      })
      setTx(tx)
      setStatus(STATUS.PENDING)
      await tx.wait()

      setIsLoading(false)
      setStatus(STATUS.FULFILLED)
      return {
        tx: tx,
      }
    } catch (error: any) {
      setIsLoading(false)
      setStatus(STATUS.IDLE)
      return {
        error: { message: error.reason || 'Unknown error' },
      }
    }
  }

  return {
    isLoading,
    clear,
    sendTransaction,
    amount,
    setStatus,
    status,
    STATUS,
    tx,
  }
}
