import React from 'react'
import styled from 'styled-components/macro'
import MuiChip, { ChipProps } from '@mui/material/Chip'

const StyledMuiChip = styled(MuiChip)`
  ${({ theme }) => `
    color: ${theme.chip.color};
    font-weight: 500;
    font-size: ${theme.typography.body.xsmall.fontSize};

    &.MuiChip-filled {
      background: ${theme.chip.filledBgColor};
      color: ${theme.chip.filledColor};
    }
  `}
`

function Chip(props: ChipProps) {
  return <StyledMuiChip {...props} />
}

export { Chip }
