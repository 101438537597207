import {
  useDepositSubmissionHistoricState,
  useDepositSubmissionActions,
  useBatchDepositSubmissionStatus,
  BatchDepositSubmissionStatus,
} from '@/state/depositSubmission/hooks'
import { useEffect } from 'react'

/**
 * Responsible for updating the state for whether a user has viewed the final
 *  outcome of a submission (either historic or successfully completed)
 */
export const useViewedDepositSubmissionUpdater = () => {
  const { submissionStatus } = useBatchDepositSubmissionStatus()
  const { isHistoric, hasViewedResult } = useDepositSubmissionHistoricState()
  const { onViewedFinalSubmission } = useDepositSubmissionActions()

  useEffect(() => {
    if (hasViewedResult) return
    if (
      isHistoric ||
      submissionStatus === BatchDepositSubmissionStatus.FINISHED
    ) {
      onViewedFinalSubmission()
    }
  }, [hasViewedResult, isHistoric, onViewedFinalSubmission, submissionStatus])
}
