import { Web3Call } from '@/hooks/useWeb3Call'
import { useRswETHApi } from '@/state/rsweth/context'
import { ExitClaimMap } from '@/state/rsweth/types'
import { Token } from '@/types/tokens'
import { ExitAsset, ExitClaim } from '@/types/claims'
import sortBy from 'lodash/sortBy'
import { useEffect, useState } from 'react'

// flattens all rswEXIT claims into a single array, sorting by timestamp
export type FlattenedExitClaims = (ExitClaim & ExitAsset)[]

function flattenExitClaims(
  exitClaimMap: ExitClaimMap | undefined,
  exitToWithdrawAsset: Map<string, Token>
): FlattenedExitClaims | undefined {
  if (!exitClaimMap) return undefined

  let res: FlattenedExitClaims = []
  for (const rswEXITAddress in exitClaimMap) {
    const claims = exitClaimMap[rswEXITAddress]
    const withdrawAsset = exitToWithdrawAsset.get(rswEXITAddress)
    if (!withdrawAsset) continue

    for (const claim of claims) {
      const asset: ExitAsset = {
        ...withdrawAsset,
        exitAddress: rswEXITAddress,
      }

      res.push({ ...claim, ...asset })
    }
  }

  res = sortBy(res, (claim) => claim.requestTimestamp)

  return res
}

export function useClaimSelect({
  finalizeWithdrawal,
  exitClaimMap,
}: {
  finalizeWithdrawal: Web3Call
  exitClaimMap: ExitClaimMap | undefined
}) {
  const { exitToWithdrawAsset } = useRswETHApi()
  const flattenedClaims = flattenExitClaims(exitClaimMap, exitToWithdrawAsset)
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)

  const resetSelection = () => setSelectedIndex(-1)

  useEffect(() => {
    if (finalizeWithdrawal.status === finalizeWithdrawal.STATUS.FULFILLED) {
      resetSelection()
    }
  }, [finalizeWithdrawal.STATUS.FULFILLED, finalizeWithdrawal.status])

  let selectedClaim: FlattenedExitClaims[number] | undefined
  if (selectedIndex >= 0 && flattenedClaims) {
    selectedClaim = flattenedClaims[selectedIndex]
  }

  return {
    selectedIndex,
    setSelectedIndex,
    selectedClaim,
    flattenedClaims,
    resetSelection,
  }
}
export type RestakeClaimSelect = ReturnType<typeof useClaimSelect>
