import React, { useMemo } from 'react'
import { DataBoxGrid, DataBoxGridItem } from '@swell-ui/DataBoxGrid'
import { displayTime } from '@/util/displayTime'
import { useDisplayNativeCurrency } from '@/hooks/useNativeCurrencyDisplay'
import { useParseNativeCurrency } from '@/hooks/useNativeCurrencyFormatting'
import { BigNumber } from 'ethers'

type Loadable<T> =
  | {
      loading: false
      value: T
    }
  | { loading: true }

export type DepositManagerDataBoxGridProps = {
  bufferedEth: Loadable<BigNumber>
  unusedKeysCount: Loadable<number>
  lastDepositAt: Loadable<number | undefined>
}

function DepositManagerDataBoxGrid(props: DepositManagerDataBoxGridProps) {
  const parseNativeCurrency = useParseNativeCurrency()
  const displayNativeCurrency = useDisplayNativeCurrency()

  const gridItems = useMemo<DataBoxGridItem[]>(() => {
    const { bufferedEth, lastDepositAt, unusedKeysCount } = props

    return [
      {
        label: 'Buffered',
        loading: bufferedEth.loading,
        get data() {
          if (bufferedEth.loading) return undefined
          return displayNativeCurrency(bufferedEth.value, {
            precision: 4,
            localize: true,
          })
        },
      },
      {
        label: 'Current capacity',
        loading: unusedKeysCount.loading,
        get data() {
          if (unusedKeysCount.loading) return undefined
          const thirtyTwoEth = parseNativeCurrency('32')
          return displayNativeCurrency(
            thirtyTwoEth.mul(unusedKeysCount.value),
            { precision: 4, localize: true }
          )
        },
      },
      {
        label: 'Last deposit',
        loading: lastDepositAt.loading,
        get data() {
          if (lastDepositAt.loading) return undefined

          return lastDepositAt.value
            ? displayTime(lastDepositAt.value, {
                preset: 'time-with-seconds',
              })
            : 'N/A'
        },
      },
      {
        label: 'Unused keys',
        loading: unusedKeysCount.loading,
        get data() {
          if (unusedKeysCount.loading) return undefined
          return unusedKeysCount.value
            .toLocaleString(navigator.language)
            .replace(',', '')
        },
      },
    ]
  }, [displayNativeCurrency, parseNativeCurrency, props])

  const config = {
    gridSpacing: 2,
    gridItemWidth: {
      xs: 12,
      sm: 6,
    },
    items: gridItems,
    loadingLength: 4,
  }

  return (
    <>
      <DataBoxGrid config={config} />
    </>
  )
}

export { DepositManagerDataBoxGrid }
