import { INITIAL_DEPLOYMENT_SET_NAME } from '@/configuration/deploymentSets'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type DeploymentsState = {
  selectedDeploymentSetName: string
  chunkSizeOverride?: number
}

const initialState: DeploymentsState = {
  selectedDeploymentSetName: INITIAL_DEPLOYMENT_SET_NAME,
  chunkSizeOverride: undefined,
}

const deploymentsSlice = createSlice({
  name: 'deployments',
  initialState,
  reducers: {
    setSelectedDeploymentSetName: (state, action: PayloadAction<string>) => {
      state.selectedDeploymentSetName = action.payload
    },
    setChunkSizeOverride: (state, action: PayloadAction<number>) => {
      state.chunkSizeOverride = action.payload
    },
  },
})

export const { setSelectedDeploymentSetName, setChunkSizeOverride } =
  deploymentsSlice.actions
export default deploymentsSlice.reducer
