/**
 * Copied: https://github.com/ethereum/staking-launchpad/blob/042612b78a1c616a98a64d039ac45d0010df8863/src/utils/SSZ.ts
 * Changes:
 * - Formatting rules; name of interface of DepositInfo (was DepositKeyInterface)
 * - moved verifyDepositRoots into its own file for easier mocking
 */
import { ByteVector, ByteVectorType, ContainerType } from '@chainsafe/ssz'

export const bufferHex = (x: string): Buffer => Buffer.from(x, 'hex')

export const SigningData = new ContainerType({
  fields: {
    objectRoot: new ByteVectorType({
      length: 32,
    }), // Ideally this would be a RootType, but AFIK, there is no generic expanded type for roots in @chainsafe/ssz
    domain: new ByteVectorType({
      length: 32,
    }),
  },
})

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface SigningData {
  objectRoot: ByteVector
  domain: ByteVector
}

export const ForkData = new ContainerType({
  fields: {
    currentVersion: new ByteVectorType({
      length: 4,
    }),
    genesisValidatorsRoot: new ByteVectorType({
      length: 32,
    }),
  },
})

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface ForkData {
  currentVersion: ByteVector
  genesisValidatorsRoot: ByteVector
}
