import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { formatWithConfig } from './number'

type Opts = {
  precision: number
  localize: boolean
}

function displayCrypto(
  amountBN: BigNumber,
  decimals = 18,
  options: Opts = { precision: 4, localize: false }
) {
  const floatAmount = parseFloat(formatUnits(amountBN, decimals))

  const formattedFloat = formatWithConfig(floatAmount, {
    precision: options.precision,
    localize: options.localize,
  })

  return formattedFloat
}

export { displayCrypto }

// applies localization by default
export function displayCryptoLocale(
  amountBN: BigNumber,
  decimals: number,
  opts: {
    precision?: number
    btc?: boolean
  } = {}
) {
  if (amountBN.isZero()) return '0'

  const precision = opts.precision ?? 4
  const localize = true
  const options: Opts = { precision, localize }

  const btc = opts.btc ?? false
  const threshold = BigNumber.from(10).pow(decimals - options.precision)
  if (amountBN.lt(threshold)) {
    if (btc && decimals === 8) {
      const sats = formatWithConfig(amountBN.toNumber(), {
        localize: true,
        precision: 0,
      })
      if (sats === '1') return '1 sat'
      return `${sats} sats`
    }

    return `<${displayCrypto(threshold, decimals, options)}`
  }
  return displayCrypto(amountBN, decimals, options)
}
