import { BigNumber } from 'ethers'

export type MerkleClaims = {
  claimableAmount: BigNumber
  vestedAmount: BigNumber
}

export function merkleClaimable({
  cumulativeAmount,
  totalAmount,
  cumulativeClaimed,
}: {
  cumulativeAmount: BigNumber
  totalAmount: BigNumber
  cumulativeClaimed: BigNumber
}) {
  let claimableAmount = cumulativeAmount.sub(cumulativeClaimed)
  if (claimableAmount.lt(0)) {
    claimableAmount = BigNumber.from(0)
  }
  let vestedAmount = totalAmount.sub(cumulativeAmount)
  if (vestedAmount.lt(0)) {
    vestedAmount = BigNumber.from(0)
  }

  return {
    claimableAmount,
    vestedAmount,
  }
}
