import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Box } from '@swell-ui/Box'
import { Button } from '@swell-ui/Button'
import { ChainIcon } from '@swell-ui/icons/ChainIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { InfoInvertedIcon } from '@swell-ui/icons/InfoInvertedIcon'
import { RswethIcon } from '@swell-ui/icons/RswethIcon'
import { Tooltip } from '@swell-ui/Tooltip'
import { TwitterIcon } from '@swell-ui/icons/TwitterIcon'
import { Typography } from '@swell-ui/Typography'
import { useVoyageUserV3Backend } from '@/services/V3BackendService/hooks'
import { buildIntentUrl } from '@/util/twitter'
import { Link } from 'react-router-dom'
import { ROUTES } from '@/constants/routes'

const REFERRAL_ENDED_TOOLTIP = 'Referral program has ended after snapshot'

const VoyageBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 28px;
  background-color: rgba(5, 26, 43, 0.5);
  backdrop-filter: blur(10px);
  color: ${({ theme }) => theme.colors.white['50']};
  margin-top: 24px;
  border-radius: 24px;
  border: none;
  letter-spacing: -0.03em;
  text-align: center;
`

const SectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  letter-spacing: 0.075em;
  text-transform: uppercase;
  margin-bottom: 12px;
  line-height: 38px;
`

const ReferralSection = styled.div`
  margin-top: 40px;
`

const ButtonSection = styled.div`
  width: 100%;
  margin-top: 40px;
`

const ReferralButton = styled(Button)`
  width: 100%;
`

const ReferralButtonTop = styled(ReferralButton)`
  margin-bottom: 18px;
`

const StyledTwitterIcon = styled(TwitterIcon)`
  width: 15px;
  margin-right: -4px;
`

const StyledChainIcon = styled(ChainIcon)`
  margin-right: -4px;
`

const StyledRswethIcon = styled(RswethIcon)`
  height: 32px;
  width: 32px;
`

const COPY_TEXT = {
  COPY: 'Copy',
  COPIED: 'Copied!',
}

const CONNECT_TOOLTIP_TITLE = 'Please connect wallet'

const SHARE_TOOLTIP_TITLE = 'Share your referral link with your followers'

function buildReferralLink(account: string) {
  return `https://app.swellnetwork.io${ROUTES.StakeRswETH}?ref=${account}`
}

function InfoTooltipTitle() {
  return (
    <div>
      Please see <Link to="/bonus-offers">bonus offer page</Link> for more
      details
    </div>
  )
}

function VoyageReferral({ account }: { account?: string }) {
  const [createTooltipTitle, setCreateTooltipTitle] = useState<string>(
    account ? COPY_TEXT.COPY : CONNECT_TOOLTIP_TITLE
  )

  const voyageUserQuery = useVoyageUserV3Backend(account)
  let swethReferred = '-'
  if (
    voyageUserQuery.data?.swethReferred &&
    typeof voyageUserQuery.data.swethReferred === 'number'
  ) {
    swethReferred = `${
      Math.round(voyageUserQuery.data.swethReferred * 100) / 100
    }`
  }
  const numReferrals = voyageUserQuery.data?.numReferrals ?? '-'

  useEffect(() => {
    if (account) {
      setCreateTooltipTitle(COPY_TEXT.COPY)
    }
  }, [account])

  const createReferral = () => {
    if (!account) {
      return
    }

    const referralLink = buildReferralLink(account)
    navigator.clipboard.writeText(referralLink)

    setCreateTooltipTitle(COPY_TEXT.COPIED)
    setTimeout(() => {
      setCreateTooltipTitle(COPY_TEXT.COPY)
    }, 1500)
  }

  const shareToTwitter = () => {
    if (!account) {
      return
    }

    const intentUrl = buildIntentUrl({
      text: [buildReferralLink(account)],
    })

    window.open(intentUrl, '_blank')
  }

  return (
    <div>
      <Typography variant="headline" size="h5">
        Your Referrals&nbsp;
        <Tooltip title={<InfoTooltipTitle />} placement="top" offset={[0, -15]}>
          <InfoInvertedIcon />
        </Tooltip>
      </Typography>
      <VoyageBox>
        <div>
          <Tooltip title={REFERRAL_ENDED_TOOLTIP}>
            <SectionTitle variant="body" size="medium">
              <FlexRow gap="8">
                <StyledRswethIcon />
                rswETH Referred
                <InfoInvertedIcon />
              </FlexRow>
            </SectionTitle>
          </Tooltip>
          <Typography variant="body" size="large">
            {swethReferred}
          </Typography>
        </div>
        <ReferralSection>
          <Tooltip title={REFERRAL_ENDED_TOOLTIP}>
            <SectionTitle variant="body" size="medium">
              <FlexRow gap="8">
                Referrals
                <InfoInvertedIcon />
              </FlexRow>
            </SectionTitle>
          </Tooltip>
          <Typography variant="body" size="large">
            {numReferrals}
          </Typography>
        </ReferralSection>
        <ButtonSection>
          <span>
            <ReferralButtonTop
              variant="secondary"
              size="small"
              onClick={createReferral}
              disabled
            >
              <StyledChainIcon /> Create Link
            </ReferralButtonTop>
          </span>
          <span>
            <ReferralButton
              variant="secondary"
              size="small"
              onClick={shareToTwitter}
              disabled
            >
              <StyledTwitterIcon /> Share
            </ReferralButton>
          </span>
        </ButtonSection>
      </VoyageBox>
    </div>
  )
}

export { VoyageReferral }
