import styled from 'styled-components/macro'
import { BigNumber } from 'ethers'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Skeleton } from '@/swell-ui/Skeleton'
import { Typography } from '@/swell-ui/Typography'
import { SwethIcon } from '@/swell-ui/icons/SwethIcon'
import { useStakingRate } from '@/state/stakingStats/hooks'
import { AccountInfoButton } from './AccountInfoButton'
import { useDisplaySwEth } from '@/hooks/useSwEthDisplay'

interface StakerAccountButtonProps {
  swEthBalance: BigNumber | undefined
  loading: boolean
}

const Btn = styled(AccountInfoButton)`
  pointer-events: none;
  padding: 6px 12px;
`

const FieldContainer = styled.div`
  display: flex;
  min-width: 78px;
`

const StyledSwEthIcon = styled(SwethIcon)`
  width: 32px;
  height: 32px;
`

function StakerAccountButton({
  swEthBalance,
  loading,
}: StakerAccountButtonProps) {
  const stakingRate = useStakingRate()
  const displaySwEth = useDisplaySwEth()

  return (
    <Btn color="accent">
      <FlexRow gap="12">
        <StyledSwEthIcon />
        <AsyncDiv
          loading={loading || !stakingRate.data || !swEthBalance}
          Container={FieldContainer}
          loader={() => <Skeleton width={100} />}
        >
          {() => {
            const swEthBalanceDisplay = displaySwEth(
              swEthBalance as BigNumber,
              {
                precision: 4,
                localize: true,
              }
            )

            return (
              <Typography variant="body" size="medium" letterSpacing="small">
                {swEthBalanceDisplay}
              </Typography>
            )
          }}
        </AsyncDiv>
      </FlexRow>
    </Btn>
  )
}

export { StakerAccountButton }
