import { BigNumber } from 'ethers'
import { useCallback } from 'react'
import { useRswEthToken } from '@/state/deployments/hooks'
import { displayFiat } from '@/util/displayFiat'
import { formatWithConfig } from '@/util/number'
import {
  useConvertRswEthToFiat,
  useConvertRswEthToNativeCurrency,
} from './useCurrencyConversions'
import { useDisplayNativeCurrency } from './useNativeCurrencyDisplay'
import { useToRswEthFloat } from './useRswEthFormatting'

// DRY: unite some code with useDisplaySwEth somehow
export const useDisplayRswEth = () => {
  const toRswEthFloat = useToRswEthFloat()
  const rswEthToken = useRswEthToken()

  return useCallback(
    (
      amountBN: BigNumber,
      {
        precision = 4,
        localize = true,
        numberOnly,
      }: {
        precision?: number
        localize?: boolean
        numberOnly?: boolean
      } = {}
    ) => {
      const floatAmount = toRswEthFloat(amountBN)
      const formattedFloat = formatWithConfig(floatAmount, {
        precision,
        localize,
      })

      if (numberOnly) {
        return `${formattedFloat}`
      }

      return `${formattedFloat} ${rswEthToken.symbol}`
    },
    [rswEthToken.symbol, toRswEthFloat]
  )
}

export const useDisplayRswEthPriceFiat = () => {
  const convertRswEthToFiat = useConvertRswEthToFiat()

  return useCallback(
    (amountBN: BigNumber, toFiatRate: number) => {
      return displayFiat(convertRswEthToFiat(amountBN, toFiatRate))
    },
    [convertRswEthToFiat]
  )
}

export const useDisplayRswEthInNativeCurrency = () => {
  const displayNativeCurrency = useDisplayNativeCurrency()
  const convertRswEthToNativeCurrency = useConvertRswEthToNativeCurrency()

  return useCallback(
    (
      rswEthAmountBN: BigNumber,
      rswEthToNativeRate: number,
      {
        precision,
        localize,
        numberOnly,
      }: {
        precision?: number
        localize?: boolean
        numberOnly?: boolean
      } = {}
    ) => {
      return displayNativeCurrency(
        convertRswEthToNativeCurrency(rswEthAmountBN, rswEthToNativeRate),
        { precision, localize, numberOnly }
      )
    },
    [convertRswEthToNativeCurrency, displayNativeCurrency]
  )
}
