// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/voyage.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.VoyageLeaderboardRecord
 */
export class VoyageLeaderboardRecord extends Message<VoyageLeaderboardRecord> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: float points = 2;
   */
  points = 0;

  constructor(data?: PartialMessage<VoyageLeaderboardRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.VoyageLeaderboardRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoyageLeaderboardRecord {
    return new VoyageLeaderboardRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoyageLeaderboardRecord {
    return new VoyageLeaderboardRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoyageLeaderboardRecord {
    return new VoyageLeaderboardRecord().fromJsonString(jsonString, options);
  }

  static equals(a: VoyageLeaderboardRecord | PlainMessage<VoyageLeaderboardRecord> | undefined, b: VoyageLeaderboardRecord | PlainMessage<VoyageLeaderboardRecord> | undefined): boolean {
    return proto3.util.equals(VoyageLeaderboardRecord, a, b);
  }
}

/**
 * @generated from message swell.v3.VoyageLeaderboardRequest
 */
export class VoyageLeaderboardRequest extends Message<VoyageLeaderboardRequest> {
  constructor(data?: PartialMessage<VoyageLeaderboardRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.VoyageLeaderboardRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoyageLeaderboardRequest {
    return new VoyageLeaderboardRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoyageLeaderboardRequest {
    return new VoyageLeaderboardRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoyageLeaderboardRequest {
    return new VoyageLeaderboardRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VoyageLeaderboardRequest | PlainMessage<VoyageLeaderboardRequest> | undefined, b: VoyageLeaderboardRequest | PlainMessage<VoyageLeaderboardRequest> | undefined): boolean {
    return proto3.util.equals(VoyageLeaderboardRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.VoyageLeaderboardResponse
 */
export class VoyageLeaderboardResponse extends Message<VoyageLeaderboardResponse> {
  /**
   * @generated from field: repeated swell.v3.VoyageLeaderboardRecord scores = 1;
   */
  scores: VoyageLeaderboardRecord[] = [];

  constructor(data?: PartialMessage<VoyageLeaderboardResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.VoyageLeaderboardResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scores", kind: "message", T: VoyageLeaderboardRecord, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoyageLeaderboardResponse {
    return new VoyageLeaderboardResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoyageLeaderboardResponse {
    return new VoyageLeaderboardResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoyageLeaderboardResponse {
    return new VoyageLeaderboardResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VoyageLeaderboardResponse | PlainMessage<VoyageLeaderboardResponse> | undefined, b: VoyageLeaderboardResponse | PlainMessage<VoyageLeaderboardResponse> | undefined): boolean {
    return proto3.util.equals(VoyageLeaderboardResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.VoyageUserRequest
 */
export class VoyageUserRequest extends Message<VoyageUserRequest> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  constructor(data?: PartialMessage<VoyageUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.VoyageUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoyageUserRequest {
    return new VoyageUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoyageUserRequest {
    return new VoyageUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoyageUserRequest {
    return new VoyageUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VoyageUserRequest | PlainMessage<VoyageUserRequest> | undefined, b: VoyageUserRequest | PlainMessage<VoyageUserRequest> | undefined): boolean {
    return proto3.util.equals(VoyageUserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.VoyageUserResponse
 */
export class VoyageUserResponse extends Message<VoyageUserResponse> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: float points = 2;
   */
  points = 0;

  /**
   * @generated from field: float sweth_referred = 3;
   */
  swethReferred = 0;

  /**
   * @generated from field: int32 num_referrals = 4;
   */
  numReferrals = 0;

  /**
   * @generated from field: float pending_super_sweth_points = 5;
   */
  pendingSuperSwethPoints = 0;

  constructor(data?: PartialMessage<VoyageUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.VoyageUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "sweth_referred", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "num_referrals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "pending_super_sweth_points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoyageUserResponse {
    return new VoyageUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoyageUserResponse {
    return new VoyageUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoyageUserResponse {
    return new VoyageUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VoyageUserResponse | PlainMessage<VoyageUserResponse> | undefined, b: VoyageUserResponse | PlainMessage<VoyageUserResponse> | undefined): boolean {
    return proto3.util.equals(VoyageUserResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.VoyageUserBreakdown
 */
export class VoyageUserBreakdown extends Message<VoyageUserBreakdown> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: float points = 2;
   */
  points = 0;

  /**
   * @generated from field: float sweth_referred = 3;
   */
  swethReferred = 0;

  /**
   * @generated from field: int32 num_referrals = 4;
   */
  numReferrals = 0;

  /**
   * @generated from field: float pending_super_sweth_points = 5;
   */
  pendingSuperSwethPoints = 0;

  /**
   * @generated from field: float pre_eigen_points = 6;
   */
  preEigenPoints = 0;

  /**
   * @generated from field: float minting_campaign_points = 7;
   */
  mintingCampaignPoints = 0;

  /**
   * @generated from field: float zap_points = 8;
   */
  zapPoints = 0;

  /**
   * @generated from field: float super_sweth_points = 9;
   */
  superSwethPoints = 0;

  /**
   * @generated from field: float airdrop_points = 10;
   */
  airdropPoints = 0;

  /**
   * @generated from field: float og_points = 11;
   */
  ogPoints = 0;

  /**
   * @generated from field: float diver_points = 12;
   */
  diverPoints = 0;

  /**
   * @generated from field: float referral_consumer_points = 13;
   */
  referralConsumerPoints = 0;

  /**
   * @generated from field: float referral_producer_points = 14;
   */
  referralProducerPoints = 0;

  constructor(data?: PartialMessage<VoyageUserBreakdown>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.VoyageUserBreakdown";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "sweth_referred", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "num_referrals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "pending_super_sweth_points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "pre_eigen_points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "minting_campaign_points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "zap_points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "super_sweth_points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 10, name: "airdrop_points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "og_points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 12, name: "diver_points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 13, name: "referral_consumer_points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 14, name: "referral_producer_points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoyageUserBreakdown {
    return new VoyageUserBreakdown().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoyageUserBreakdown {
    return new VoyageUserBreakdown().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoyageUserBreakdown {
    return new VoyageUserBreakdown().fromJsonString(jsonString, options);
  }

  static equals(a: VoyageUserBreakdown | PlainMessage<VoyageUserBreakdown> | undefined, b: VoyageUserBreakdown | PlainMessage<VoyageUserBreakdown> | undefined): boolean {
    return proto3.util.equals(VoyageUserBreakdown, a, b);
  }
}

/**
 * @generated from message swell.v3.VoyageStatusRequest
 */
export class VoyageStatusRequest extends Message<VoyageStatusRequest> {
  constructor(data?: PartialMessage<VoyageStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.VoyageStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoyageStatusRequest {
    return new VoyageStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoyageStatusRequest {
    return new VoyageStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoyageStatusRequest {
    return new VoyageStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VoyageStatusRequest | PlainMessage<VoyageStatusRequest> | undefined, b: VoyageStatusRequest | PlainMessage<VoyageStatusRequest> | undefined): boolean {
    return proto3.util.equals(VoyageStatusRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.VoyageStatusResponse
 */
export class VoyageStatusResponse extends Message<VoyageStatusResponse> {
  /**
   * @generated from field: string total_pearls = 1;
   */
  totalPearls = "";

  /**
   * @generated from field: string total_users = 2;
   */
  totalUsers = "";

  constructor(data?: PartialMessage<VoyageStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.VoyageStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_pearls", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total_users", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VoyageStatusResponse {
    return new VoyageStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VoyageStatusResponse {
    return new VoyageStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VoyageStatusResponse {
    return new VoyageStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VoyageStatusResponse | PlainMessage<VoyageStatusResponse> | undefined, b: VoyageStatusResponse | PlainMessage<VoyageStatusResponse> | undefined): boolean {
    return proto3.util.equals(VoyageStatusResponse, a, b);
  }
}

