import fromExponential from 'from-exponential'
import { formatEther, parseEther, parseUnits } from 'ethers/lib/utils'
import { BigNumber } from 'ethers'

export function ensureStandardNotationString(input: string) {
  return fromExponential(input.trim())
}

/**
 * Truncates the fractional units of a number string up to the specified decimal units.
 * @param {string} input - The input number string.
 * @param {number} decimalUnits - The number of decimal units to keep.
 * @returns {string} The truncated number string.
 */
function truncateFractionalUnits(input: string, decimalUnits: number): string {
  const decimalIndex: number = input.indexOf('.')
  if (decimalIndex !== -1) {
    const endIndex: number = decimalIndex + decimalUnits + 1 // Calculate the end index to include desired decimal units and the decimal point
    const truncatedString: string = input.substring(0, endIndex)
    return truncatedString
  }
  return input // Return the original string if there are no fractional units
}

export const parseUnitsSafe = (input: string, decimals: number) => {
  const inputSafe = truncateFractionalUnits(
    ensureStandardNotationString(input),
    decimals
  )
  return parseUnits(inputSafe, decimals)
}

export const parseWeiSafe = (v: string) => parseUnitsSafe(v, 0)

export const toFloatBigNumber = (value: BigNumber) =>
  parseFloat(formatEther(value))

export const applyRateBigNumber = (
  value: BigNumber,
  rate: number
): BigNumber => {
  const oneEth = parseEther('1')
  const rateBN = parseUnitsSafe(String(rate), 18)

  const withRateHighPrecision = value.mul(rateBN)
  const afterDecimalConversion = withRateHighPrecision.div(oneEth)

  return afterDecimalConversion
}

export const applyRateBigNumberToFloat = (value: BigNumber, rate: number) => {
  return toFloatBigNumber(applyRateBigNumber(value, rate))
}

// applies a BigNumber rate (1 ETH = 1.0) to a BigNumber value
export function applyBigNumberRateBN(
  value: BigNumber,
  rate: BigNumber
): BigNumber {
  return value.mul(rate).div(parseEther('1'))
}

// converts a BigNumber rate to a float
// note: precision is lost at this stage. This rate should not feed into any onchain inputs.
export function bigNumberRateToFloat(value: BigNumber): number {
  return parseFloat(formatEther(value))
}

export function getSwethReceivedForNativeCurrency({
  toSendNativeCurrency,
  swethToEthRate,
}: {
  toSendNativeCurrency: BigNumber
  swethToEthRate: BigNumber
}): BigNumber {
  return toSendNativeCurrency.mul(parseEther('1')).div(swethToEthRate)
}

export function getNativeCurrencyToExchangeForSweth({
  toReceiveSweth,
  swethToEthRate,
}: {
  toReceiveSweth: BigNumber
  swethToEthRate: BigNumber
}): BigNumber {
  let nativeAmount = toReceiveSweth.mul(swethToEthRate).div(parseEther('1'))
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const swethReceived = getSwethReceivedForNativeCurrency({
      toSendNativeCurrency: nativeAmount,
      swethToEthRate,
    })
    if (swethReceived.gte(toReceiveSweth)) {
      break
    }
    nativeAmount = nativeAmount.add(1)
  }
  return nativeAmount
}

export function getRswethReceivedForNativeCurrency({
  toSendNativeCurrency,
  rswethToEthRate,
}: {
  toSendNativeCurrency: BigNumber
  rswethToEthRate: BigNumber
}): BigNumber {
  const ethToRswethRate = parseEther('1')
    .mul(parseEther('1'))
    .div(rswethToEthRate)
  return toSendNativeCurrency.mul(ethToRswethRate).div(parseEther('1'))
}

export function getNativeCurrencyToExchangeForRsweth({
  toReceiveRsweth,
  rswethToEthRate,
}: {
  toReceiveRsweth: BigNumber
  rswethToEthRate: BigNumber
}): BigNumber {
  let nativeAmount = toReceiveRsweth.mul(rswethToEthRate).div(parseEther('1'))
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const rswethReceived = getRswethReceivedForNativeCurrency({
      toSendNativeCurrency: nativeAmount,
      rswethToEthRate,
    })
    if (rswethReceived.gte(toReceiveRsweth)) {
      break
    }
    nativeAmount = nativeAmount.add(1)
  }
  return nativeAmount
}
