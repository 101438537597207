import {
  GEOFENCE_AIRDROP_ROUTES,
  GEOFENCE_AIRDROP_BLOCKED_COUNTRIES,
} from '@/constants/geofence'
import { GeofencePermission, GeoFenceResult } from '@/types/geofence'

export function evaluateGeofencePermission(
  result: GeoFenceResult
): GeofencePermission {
  const { isRestricted, countryCode } = result

  if (!isRestricted) return GeofencePermission.Ok
  if (GEOFENCE_AIRDROP_BLOCKED_COUNTRIES.includes(countryCode)) {
    return GeofencePermission.BlockedAirdrops
  }
  return GeofencePermission.Blocked
}

export function shouldGeoRestrict(
  result: GeoFenceResult,
  pathname: string
): { shouldRestrict: boolean; permission: GeofencePermission } {
  const permission = evaluateGeofencePermission(result)

  if (permission === GeofencePermission.Ok) {
    return { shouldRestrict: false, permission }
  }
  if (permission === GeofencePermission.Blocked) {
    return { shouldRestrict: true, permission }
  }

  const shouldRestrict = GEOFENCE_AIRDROP_ROUTES.map((r) =>
    r.toLowerCase()
  ).includes(pathname.toLowerCase())

  return { shouldRestrict, permission }
}
