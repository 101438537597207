import React from 'react'
import styled from 'styled-components/macro'
import { Button } from '../../Button'
import { TextInput, TextFieldProps } from '../TextInput'
import { InputAdornment } from '../InputAdornment'

type EthInputProps = TextFieldProps & {
  onMaxClick: (arg1: any) => any
}

const MaxButton = styled(Button)`
  border-radius: 100px;
  padding: 4px 10px;
  height: 32px;
  margin-top: -10px;
  font-size: 12px;
`

function EthInput({ onMaxClick, ...props }: EthInputProps) {
  function handleKeyDown(event: any) {
    if (
      event.key === 'e' ||
      event.key === 'E' ||
      event.key === '-' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      // IE/Edge need 'Up' and 'Down'
      event.key === 'Up' ||
      event.key === 'Down'
    ) {
      event.preventDefault()
    }
  }
  return (
    <>
      <TextInput
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <MaxButton
                disabled={props.disabled}
                variant="secondary"
                onClick={onMaxClick}
              >
                MAX
              </MaxButton>
            </InputAdornment>
          ),
        }}
        type="number"
        onKeyDown={handleKeyDown}
        {...props}
      />
    </>
  )
}

export { EthInput }
