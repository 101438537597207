import {
  L2_AUDITED_BY,
  L2_BACKED_BY,
  L2_ECOSYSTEM_AVS,
  L2_ECOSYSTEM_PROJECTS,
  L2_INFRASTRUCTURE_PARTNERS,
  L2_POWERED_BY_PARTNERS,
} from '@/state/predeposit/constants'
import { useSwellUiTheme } from '@/swell-ui/theme'
import { useMediaQuery } from '@mui/material'
import { chunk } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { EcosystemAvatar } from './EcosystemAvatar'

type CategoryConfig = {
  title: string
  rows: React.ComponentProps<typeof EcosystemAvatar>[][]
}

const categoriesDesktop: CategoryConfig[] = [
  {
    title: 'Swell L2 Powered By',
    rows: chunk(L2_POWERED_BY_PARTNERS, 6),
  },
  {
    title: 'Infrastructure Partners',
    rows: chunk(L2_INFRASTRUCTURE_PARTNERS, 6),
  },
  {
    title: 'Ecosystem Dapp Projects',
    rows: chunk(
      [...L2_ECOSYSTEM_PROJECTS, { name: 'Next?', category: 'Stay tuned...' }],
      6
    ),
  },
  {
    title: 'Ecosystem AVS Projects',
    rows: chunk(
      [...L2_ECOSYSTEM_AVS, { name: 'Next?', category: 'Stay tuned...' }],
      6
    ),
  },
  {
    title: 'Audited By',
    rows: [L2_AUDITED_BY],
  },
  {
    title: 'Backed By',
    rows: chunk(L2_BACKED_BY, 6),
  },
]

const categoriesMobile: CategoryConfig[] = [
  {
    title: 'Swell L2 Powered By',
    rows: chunk(L2_POWERED_BY_PARTNERS, 3),
  },
  {
    title: 'Infrastructure Partners',
    rows: chunk(L2_INFRASTRUCTURE_PARTNERS, 3),
  },
  {
    title: 'Ecosystem Dapp Projects',
    rows: chunk(
      [...L2_ECOSYSTEM_PROJECTS, { name: 'Next?', category: 'Stay tuned...' }],
      3
    ),
  },
  {
    title: 'Ecosystem AVS Projects',
    rows: chunk(
      [...L2_ECOSYSTEM_AVS, { name: 'Next?', category: 'Stay tuned...' }],
      3
    ),
  },
  {
    title: 'Audited By',
    rows: chunk(L2_AUDITED_BY, 3),
  },
  {
    title: 'Backed By',
    rows: chunk(L2_BACKED_BY, 3),
  },
]

export function EcosystemSection() {
  const { theme } = useSwellUiTheme()

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const categories = isMdUp ? categoriesDesktop : categoriesMobile

  const categoryNodes = categories.map((category, idx, og) => {
    const isLastCategory = idx === og.length - 1
    const node = (
      <CategorySection key={category.title}>
        <h2>{category.title}</h2>
        {category.rows.map((row, i) => (
          <AvatarRow key={i}>
            {row.map((partner) => (
              <EcosystemAvatar key={partner.name} {...partner} />
            ))}
          </AvatarRow>
        ))}
      </CategorySection>
    )

    if (isLastCategory) {
      return node
    }
    return (
      <React.Fragment key={category.title}>
        {node}
        <LineSpacer />
      </React.Fragment>
    )
  })

  return <Layout>{categoryNodes}</Layout>
}

const CategorySection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
`

const AvatarRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 62px;
`

const Layout = styled.div`
  display: flex;
  padding: 42px;
  flex-direction: column;
  align-self: stretch;

  border-radius: 8px;
  background: rgba(12, 8, 39, 0.9);
  backdrop-filter: blur(2px);

  text-align: center;

  h2 {
    margin: 0;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 22.4px */
    letter-spacing: 1.05px;
    text-transform: uppercase;
  }
`

const LineSpacer = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #845aff 0%, #4943e0 100%);
  margin: 42px 0;
`
