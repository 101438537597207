import React, { useCallback } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import Particles from 'react-tsparticles'
import type { Engine } from 'tsparticles-engine'
import { loadFull } from 'tsparticles'
import particleOptions from './particles.json'
import lightRayUrl from '@/assets/images/lightray-rectangle.png'
import lightRay500Url from '@/assets/images/lightray-rectangle-p-500.png'

const lightRay1Keyframe = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(-80px, 0, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }

  75% {
    transform: translate3d(80px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0)
  }
`

const lightRay2Keyframe = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(60px, 0, 0);
  }

  50% {
    transform: translate3d(60px, 0, 0);
  }

  75% {
    transform: translate3d(-20px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0)
  }
`

const lightRay3Keyframe = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(-80px, 0, 0);
  }

  50% {
    transform: translate3d(-80px, 0, 0);
  }

  75% {
    transform: translate3d(-80px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0)
  }
`

const OceanBackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const LightRayContainer = styled.div`
  position: static;
  z-index: 2;
  overflow: hidden;
  float: none;
`

const LightRay = styled.div`
  position: absolute;
  right: auto;
  bottom: auto;
  z-index: 1;
  overflow: hidden;
  max-width: 100%;
  transform-style: preserve-3d;
  will-change: transform;
`

const LightRay1 = styled(LightRay)`
  left: 50%;
  top: -20%;
  animation: ${lightRay1Keyframe} 15s linear 0s infinite normal;
`

const LightRay2 = styled(LightRay)`
  left: 38%;
  top: -25%;
  animation: ${lightRay2Keyframe} 15s linear 0s infinite normal;
`

const LightRay3 = styled(LightRay)`
  left: 68%;
  top: -25%;
  animation: ${lightRay3Keyframe} 10s linear 0s infinite normal;
`

function OceanBackground() {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine)
  }, [])

  return (
    <OceanBackgroundContainer>
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={particleOptions as any}
      />
      <LightRayContainer>
        <LightRay1>
          <img
            src={lightRayUrl}
            loading="lazy"
            sizes="(max-width: 991px) 62vw, 604px"
            srcSet={`${lightRay500Url} 500w, ${lightRayUrl} 604w`}
            alt=""
          />
        </LightRay1>
        <LightRay2>
          <img
            src={lightRayUrl}
            loading="lazy"
            sizes="(max-width: 991px) 62vw, 604px"
            srcSet={`${lightRay500Url} 500w, ${lightRayUrl} 604w`}
            alt=""
          />
        </LightRay2>
        <LightRay3>
          <img
            src={lightRayUrl}
            loading="lazy"
            sizes="(max-width: 991px) 62vw, 604px"
            srcSet={`${lightRay500Url} 500w, ${lightRayUrl} 604w`}
            alt=""
          />
        </LightRay3>
      </LightRayContainer>
    </OceanBackgroundContainer>
  )
}

export { OceanBackground }
