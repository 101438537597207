import { env } from '@/env'

export enum HostEnv {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

export const HOST_ENV = env.REACT_APP_HOST_ENV as HostEnv
if (!HOST_ENV) throw new Error(`Missing REACT_APP_HOST_ENV`)

if (
  ![HostEnv.PRODUCTION, HostEnv.STAGING, HostEnv.DEVELOPMENT].includes(HOST_ENV)
) {
  throw new Error(`Invalid REACT_APP_HOST_ENV=${HOST_ENV}`)
}
