import {
  yearnDepositAssetValueUsd,
  yearnVaultTokenToUsd,
} from '@/components/StakingWidget/yearn/yearnConversions'
import { displayFiat } from '@/util/displayFiat'
import { BigNumber } from 'ethers'

export function yearnDepositAssetUsdLabel({
  depositAssetAmount,
  ethUsdMarketRate,
  depositAssetEthRate,
  depositAssetDecimals,
}: {
  depositAssetAmount: BigNumber | undefined
  ethUsdMarketRate: number | undefined
  depositAssetEthRate: BigNumber | undefined
  depositAssetDecimals: number
}) {
  if (!depositAssetAmount || !ethUsdMarketRate || !depositAssetEthRate) {
    return null
  }

  return displayFiat(
    yearnDepositAssetValueUsd({
      depositAssetAmount,
      depositAssetEthRate,
      ethUsdMarketRate,
      depositAssetDecimals,
    })
  )
}

export function yearnDepositReceiveVaultTokenUsdLabel({
  vaultTokenAmount,
  ethUsdMarketRate,
  pricePerShare,
  depositAssetEthRate,
  vaultTokenDecimals,
  depositAssetDecimals,
}: {
  vaultTokenAmount: BigNumber | undefined
  ethUsdMarketRate: number | undefined
  pricePerShare: BigNumber | undefined
  depositAssetEthRate: BigNumber | undefined
  vaultTokenDecimals: number
  depositAssetDecimals: number
}) {
  if (
    !vaultTokenAmount ||
    !ethUsdMarketRate ||
    !pricePerShare ||
    !depositAssetEthRate
  ) {
    return null
  }

  return displayFiat(
    yearnVaultTokenToUsd({
      ethUsdMarketRate,
      vaultTokenAmount,
      depositAssetEthRate,
      pricePerShare,
      vaultTokenDecimals,
      depositAssetDecimals,
    })
  )
}

export function yearnWithdrawVaultTokenUsdLabel({
  withdrawVaultTokenAmount,
  ethUsdMarketRate,
  pricePerShare,
  depositAssetEthRate,
  vaultTokenDecimals,
  depositAssetDecimals,
}: {
  withdrawVaultTokenAmount: BigNumber | undefined
  ethUsdMarketRate: number | undefined
  pricePerShare: BigNumber | undefined
  depositAssetEthRate: BigNumber | undefined
  vaultTokenDecimals: number
  depositAssetDecimals: number
}) {
  if (
    !withdrawVaultTokenAmount ||
    !ethUsdMarketRate ||
    !pricePerShare ||
    !depositAssetEthRate
  ) {
    return null
  }

  return displayFiat(
    yearnVaultTokenToUsd({
      depositAssetEthRate,
      ethUsdMarketRate,
      pricePerShare,
      vaultTokenAmount: withdrawVaultTokenAmount,
      vaultTokenDecimals,
      depositAssetDecimals,
    })
  )
}

export function yearnWithdrawReceiveAssetUsdLabel({
  receiveAssetAmount,
  ethUsdMarketRate,
  depositAssetEthRate,
  depositAssetDecimals,
}: {
  receiveAssetAmount: BigNumber | undefined
  ethUsdMarketRate: number | undefined
  depositAssetEthRate: BigNumber | undefined
  depositAssetDecimals: number
}) {
  if (!receiveAssetAmount || !ethUsdMarketRate || !depositAssetEthRate) {
    return null
  }

  return displayFiat(
    yearnDepositAssetValueUsd({
      depositAssetAmount: receiveAssetAmount,
      ethUsdMarketRate,
      depositAssetEthRate,
      depositAssetDecimals,
    })
  )
}
