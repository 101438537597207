function abbreviateTimeWords(datetimeStr: string) {
  let result = datetimeStr.replace(/hour/g, 'hr')
  result = result.replace(/minute/g, 'min')
  result.replace(/sec/g, 'sec')

  return result
}

export { abbreviateTimeWords }

export interface DurationBreakdown {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export function getDurationBreakdown(
  durationMs: number
): DurationBreakdown | undefined {
  if (durationMs < 0) {
    return undefined
  }
  if (durationMs == 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  const days = Math.floor(durationMs / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (durationMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((durationMs % (1000 * 60)) / 1000)

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  }
}
