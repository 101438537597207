/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  RestakingNodeOperatorRegistry,
  RestakingNodeOperatorRegistryInterface,
} from "../RestakingNodeOperatorRegistry";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "AmountOfValidatorDetailsExceedsLimit",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "existingKey",
        type: "bytes",
      },
    ],
    name: "CannotAddDuplicatePubKey",
    type: "error",
  },
  {
    inputs: [],
    name: "CannotBeZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "CannotSetOperatorControllingAddressToSameAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "CannotUseDisabledOperator",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidArrayLengthOfZero",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidMethodCall",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidPubKeyLength",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidPubKeySetupCaller",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidSignatureLength",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "pubKey",
        type: "bytes",
      },
    ],
    name: "MissingActiveValidatorDetails",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "pubKey",
        type: "bytes",
      },
    ],
    name: "MissingPendingValidatorDetails",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "foundPubKey",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "providedPubKey",
        type: "bytes",
      },
    ],
    name: "NextOperatorPubKeyMismatch",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "NoOperatorFound",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "pubKey",
        type: "bytes",
      },
    ],
    name: "NoPubKeyFound",
    type: "error",
  },
  {
    inputs: [],
    name: "NoTokensToWithdraw",
    type: "error",
  },
  {
    inputs: [],
    name: "OperatorAlreadyDisabled",
    type: "error",
  },
  {
    inputs: [],
    name: "OperatorAlreadyEnabled",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "OperatorAlreadyExists",
    type: "error",
  },
  {
    inputs: [],
    name: "OperatorMethodsPaused",
    type: "error",
  },
  {
    inputs: [],
    name: "OperatorOutOfPendingKeys",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes[]",
        name: "pubKeys",
        type: "bytes[]",
      },
    ],
    name: "ActivePubKeysDeleted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "version",
        type: "uint8",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "operatorAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "rewardAddress",
        type: "address",
      },
    ],
    name: "OperatorAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        components: [
          {
            internalType: "bytes",
            name: "pubKey",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        indexed: false,
        internalType: "struct INodeOperatorRegistry.ValidatorDetails[]",
        name: "pubKeys",
        type: "tuple[]",
      },
    ],
    name: "OperatorAddedValidatorDetails",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "OperatorDisabled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "OperatorEnabled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes[]",
        name: "pubKeys",
        type: "bytes[]",
      },
    ],
    name: "PendingPubKeysDeleted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes[]",
        name: "pubKeys",
        type: "bytes[]",
      },
    ],
    name: "PubKeysUsedForValidatorSetup",
    type: "event",
  },
  {
    stateMutability: "nonpayable",
    type: "fallback",
  },
  {
    inputs: [],
    name: "AccessControlManager",
    outputs: [
      {
        internalType: "contract IAccessControlManager",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes",
            name: "pubKey",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct INodeOperatorRegistry.ValidatorDetails[]",
        name: "_validatorDetails",
        type: "tuple[]",
      },
    ],
    name: "addNewValidatorDetails",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_name",
        type: "string",
      },
      {
        internalType: "address",
        name: "_operatorAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_rewardAddress",
        type: "address",
      },
    ],
    name: "addOperator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes[]",
        name: "_pubKeys",
        type: "bytes[]",
      },
    ],
    name: "deleteActiveValidators",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes[]",
        name: "_pubKeys",
        type: "bytes[]",
      },
    ],
    name: "deletePendingValidators",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_operatorAddress",
        type: "address",
      },
    ],
    name: "disableOperator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_operatorAddress",
        type: "address",
      },
    ],
    name: "enableOperator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_numNewValidators",
        type: "uint256",
      },
    ],
    name: "getNextValidatorDetails",
    outputs: [
      {
        components: [
          {
            internalType: "bytes",
            name: "pubKey",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct INodeOperatorRegistry.ValidatorDetails[]",
        name: "validatorDetails",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "foundValidators",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_operatorAddress",
        type: "address",
      },
    ],
    name: "getOperator",
    outputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "enabled",
            type: "bool",
          },
          {
            internalType: "address",
            name: "rewardAddress",
            type: "address",
          },
          {
            internalType: "address",
            name: "controllingAddress",
            type: "address",
          },
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "uint128",
            name: "activeValidators",
            type: "uint128",
          },
        ],
        internalType: "struct INodeOperatorRegistry.Operator",
        name: "operator",
        type: "tuple",
      },
      {
        internalType: "uint128",
        name: "totalValidatorDetails",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "operatorId",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint128",
        name: "",
        type: "uint128",
      },
    ],
    name: "getOperatorForOperatorId",
    outputs: [
      {
        internalType: "bool",
        name: "enabled",
        type: "bool",
      },
      {
        internalType: "address",
        name: "rewardAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "controllingAddress",
        type: "address",
      },
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "uint128",
        name: "activeValidators",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "getOperatorIdForAddress",
    outputs: [
      {
        internalType: "uint128",
        name: "",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "getOperatorIdForPubKey",
    outputs: [
      {
        internalType: "uint128",
        name: "",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_operatorAddress",
        type: "address",
      },
    ],
    name: "getOperatorsActiveValidatorDetails",
    outputs: [
      {
        components: [
          {
            internalType: "bytes",
            name: "pubKey",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct INodeOperatorRegistry.ValidatorDetails[]",
        name: "validatorDetails",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_operatorAddress",
        type: "address",
      },
    ],
    name: "getOperatorsPendingValidatorDetails",
    outputs: [
      {
        components: [
          {
            internalType: "bytes",
            name: "pubKey",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct INodeOperatorRegistry.ValidatorDetails[]",
        name: "validatorDetails",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_startIndex",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_endIndex",
        type: "uint256",
      },
    ],
    name: "getPoRAddressList",
    outputs: [
      {
        internalType: "string[]",
        name: "",
        type: "string[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getPoRAddressListLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint128",
        name: "_operatorId",
        type: "uint128",
      },
    ],
    name: "getRewardDetailsForOperatorId",
    outputs: [
      {
        internalType: "address",
        name: "rewardAddress",
        type: "address",
      },
      {
        internalType: "uint128",
        name: "activeValidators",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IAccessControlManager",
        name: "_accessControlManager",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "numOperators",
    outputs: [
      {
        internalType: "uint128",
        name: "",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "numPendingValidators",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_operatorAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_newOperatorAddress",
        type: "address",
      },
    ],
    name: "updateOperatorControllingAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_operatorAddress",
        type: "address",
      },
      {
        internalType: "string",
        name: "_name",
        type: "string",
      },
    ],
    name: "updateOperatorName",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_operatorAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_newRewardAddress",
        type: "address",
      },
    ],
    name: "updateOperatorRewardAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes[]",
        name: "_pubKeys",
        type: "bytes[]",
      },
    ],
    name: "usePubKeysForValidatorSetup",
    outputs: [
      {
        components: [
          {
            internalType: "bytes",
            name: "pubKey",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct INodeOperatorRegistry.ValidatorDetails[]",
        name: "validatorDetails",
        type: "tuple[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20",
        name: "_token",
        type: "address",
      },
    ],
    name: "withdrawERC20",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class RestakingNodeOperatorRegistry__factory {
  static readonly abi = _abi;
  static createInterface(): RestakingNodeOperatorRegistryInterface {
    return new utils.Interface(_abi) as RestakingNodeOperatorRegistryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): RestakingNodeOperatorRegistry {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as RestakingNodeOperatorRegistry;
  }
}
