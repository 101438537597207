import { EIP1193Provider } from '@web3-onboard/common'

interface WatchAssetParams {
  address: string // The address that the token is at.
  symbol: string // A ticker symbol or shorthand, up to 5 chars.
  decimals: number // The number of decimals in the token
  image: string // A string url of the token logo
}

interface Connector {
  switchChain(targetChainId: number): any
  watchAsset(params: WatchAssetParams): any
}

function createConnector(provider?: EIP1193Provider): Connector {
  const switchChain = async (targetChainId: number) => {
    if (!provider) {
      console.error('No provider with which to switch chains.')
      return
    }

    const targetChainIdHex = `0x${targetChainId.toString(16)}`

    return provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: targetChainIdHex }],
    })
  }

  const watchAsset = async ({
    address,
    symbol,
    decimals,
    image,
  }: WatchAssetParams) => {
    if (!provider) {
      console.error('No provider with which to watch asset.')
      return
    }

    return (provider as any).request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address,
          symbol,
          decimals,
          image,
        },
      },
    })
  }

  return {
    switchChain,
    watchAsset,
  }
}

export { createConnector }
