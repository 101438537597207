const i18n = {
  en: {
    connect: {
      selectingWallet: {
        agreement: {
          terms: 'Terms of Service',
        },
      },
    },
  },
}

export { i18n }
