import { ReactNode, createContext, useContext } from 'react'
import { IPreDepositZap } from './IPredepositZap'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { usePreDepositZapContract } from '@/hooks/useContract'
import { ETH_ALIAS } from '../constants'
import { BigNumber, ContractReceipt, ContractTransaction, ethers } from 'ethers'
import { formatEther } from 'ethers/lib/utils'

const MISSING_PROVIDER = Symbol()

/**
 * PreDepositZapContext is a context that provides a mapping of token contract to Zap interface.
 * If a zap interface is present, it means that it is required to accept the token as a deposit.
 */
const PreDepositZapContext = createContext<
  | {
      zapInterfaces: {
        [address: string]: IPreDepositZap | undefined
      }
      zapTokenToAcceptedToken: {
        [address: string]: string
      }
    }
  | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function usePreDepositZapContext() {
  const connectors = useContext(PreDepositZapContext)
  if (connectors === MISSING_PROVIDER) {
    throw new Error(
      'PreDepositZapContext hooks must be wrapped in a <PreDepositZapProvider>'
    )
  }
  return connectors
}

function PreDepositZapProvider({ children }: { children: ReactNode }) {
  const { addresses } = useDeploymentSetConfig()
  const zap = usePreDepositZapContract()

  const eETH = ethers.utils.getAddress(addresses.eETH)
  const stETH = ethers.utils.getAddress(addresses.stETH)
  const weETH = ethers.utils.getAddress(addresses.weETH)
  const wstETH = ethers.utils.getAddress(addresses.wstETH)
  const WETH = ethers.utils.getAddress(addresses.WETH)

  const ctx: React.ContextType<typeof PreDepositZapContext> = {
    zapInterfaces: {
      [eETH]: {
        zapIn: (amount: BigNumber) => zap.eETHZapIn(amount),
        zapInEstimateGas: (amount: BigNumber) =>
          zap.estimateGas.eETHZapIn(amount),
      },
      [stETH]: {
        zapIn: (amount: BigNumber) => zap.stETHZapIn(amount),
        zapInEstimateGas: (amount: BigNumber) =>
          zap.estimateGas.stETHZapIn(amount),
      },
      [ETH_ALIAS]: {
        zapIn: (amount: BigNumber) => zap.ethZapIn({ value: amount }),
        zapInEstimateGas: (amount: BigNumber) =>
          zap.estimateGas.ethZapIn({ value: amount }),
      },
    },
    zapTokenToAcceptedToken: {
      [eETH]: weETH,
      [stETH]: wstETH,
      [ETH_ALIAS]: WETH,
    },
  }

  return (
    <PreDepositZapContext.Provider value={ctx}>
      {children}
    </PreDepositZapContext.Provider>
  )
}

function PreDepositZapProviderMock({ children }: { children: ReactNode }) {
  const { addresses } = useDeploymentSetConfig()

  const { eETH, stETH, weETH, wstETH, WETH } = addresses

  const ctx: React.ContextType<typeof PreDepositZapContext> = {
    zapInterfaces: {
      [eETH]: {
        zapIn: async (amount: BigNumber) => {
          console.info('mock zap.eETHZapIn', formatEther(amount))
          return {
            wait: async () => {
              await new Promise((resolve) => setTimeout(resolve, 1000))

              return {
                transactionHash: '0xffff',
              } as ContractReceipt
            },
          } as unknown as ContractTransaction
        },
        zapInEstimateGas: async (amount: BigNumber) => {
          console.info('mock zap.eETHZapInEstimateGas', formatEther(amount))
          return BigNumber.from(100000)
        },
      },
      [stETH]: {
        zapIn: async (amount: BigNumber) => {
          console.info('mock zap.stETHZapIn', formatEther(amount))
          await new Promise((resolve) => setTimeout(resolve, 4000))
          return {
            wait: async () => {
              return {
                transactionHash: '0xffff',
              } as ContractReceipt
            },
          } as unknown as ContractTransaction
        },
        zapInEstimateGas: async (amount: BigNumber) => {
          console.info('mock zap.stETHZapInEstimateGas', formatEther(amount))
          return BigNumber.from(100000)
        },
      },
      [ETH_ALIAS]: {
        zapIn: async (amount: BigNumber) => {
          console.info('mock zap.ethZapIn', formatEther(amount))
          return {
            wait: async () => {
              await new Promise((resolve) => setTimeout(resolve, 8000))
              return {
                transactionHash: '0xffff',
              } as ContractReceipt
            },
          } as unknown as ContractTransaction
        },
        zapInEstimateGas: async (amount: BigNumber) => {
          console.info('mock zap.ethZapInEstimateGas', formatEther(amount))
          return BigNumber.from(100000)
        },
      },
    },
    zapTokenToAcceptedToken: {
      [eETH]: weETH,
      [stETH]: wstETH,
      [ETH_ALIAS]: WETH,
    },
  }

  return (
    <PreDepositZapContext.Provider value={ctx}>
      {children}
    </PreDepositZapContext.Provider>
  )
}

export { PreDepositZapProvider }
// export { PreDepositZapProviderMock as PreDepositZapProvider }
