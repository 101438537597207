import {
  depositAssetRequiredForVaultToken,
  vaultTokenReceivedForDepositAsset,
  withdrawAssetReceivedForVaultToken,
  vaultTokenRequiredForAsset,
} from '@/components/StakingWidget/yearn/yearnConversions'
import {
  YearnBalances,
  YearnTokenRates,
  YearnWithdrawAsset,
} from '@/state/yearnVault/types'
import { EthInput, SwEthInput } from '@/swell-ui/inputs'
import { ThemeData } from '@/swell-ui/theme/branding'
import { Token } from '@/types/tokens'
import { trimDecimalPlaces } from '@/util/number'
import { parseUnits, formatUnits } from 'ethers/lib/utils'
import styled, { css } from 'styled-components'

const StyledEthInput = styled(EthInput)`
  ${({ theme }) => css`
    .MuiFormHelperText-root {
      margin-top: 2px;
      margin-bottom: 16px;
    }

    ${theme.breakpoints.up('sm')} {
      max-width: unset;
    }
  `}
  .MuiInputBase-root:after {
    border-color: ${ThemeData.Symbiotic.symbioticBtcOrange};
  }
`
const StyledSwEthInput = styled(SwEthInput)`
  .MuiInputBase-root:after {
    border-color: ${ThemeData.Symbiotic.symbioticBtcOrange};
  }
`

// Deposit: top input
export function DepositAssetInput({
  errorMessage,
  setDestTokenInputValue,
  setSrcTokenInputValue,
  srcTokenInputValue,
  rates,
  balances,
  disabled,
  vaultToken,
  depositAsset,
  setTouched,
}: {
  setTouched: (touched: boolean) => void
  srcTokenInputValue: string
  setSrcTokenInputValue: (value: string) => void
  setDestTokenInputValue: (value: string) => void
  errorMessage: string | null
  rates: YearnTokenRates | undefined
  balances: YearnBalances | undefined
  disabled: boolean
  vaultToken: Token
  depositAsset: Token
}) {
  const onChange = (event: any) => {
    if (!rates) {
      console.error('No rates')
      return
    }

    const value = event.target.value
    if (value === '') {
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
      return
    }

    setTouched(true)

    const decimalPlaces = depositAsset.decimals
    const valueClean = trimDecimalPlaces(value, decimalPlaces)
    setSrcTokenInputValue(valueClean)

    const depositAssetAmount = parseUnits(valueClean, depositAsset.decimals)
    const receiveVaultTokenAmount = vaultTokenReceivedForDepositAsset({
      toSendAsset: depositAssetAmount,
      pricePerShare: rates.pricePerShare,
      vaultTokenDecimals: vaultToken.decimals,
    })
    const destAmountStr = formatUnits(
      receiveVaultTokenAmount,
      vaultToken.decimals
    )
    setDestTokenInputValue(destAmountStr)
  }

  const handleMaxClick = () => {
    if (!balances) return
    onChange({
      target: {
        value: formatUnits(balances.depositAsset, depositAsset.decimals),
      },
    })
  }

  return (
    <StyledEthInput
      variant="standard"
      value={srcTokenInputValue}
      onChange={onChange}
      error={!!errorMessage}
      helperText={errorMessage}
      disabled={disabled}
      onMaxClick={handleMaxClick}
    />
  )
}

// Deposit: bottom input
export function DepositReceiveVaultTokenInput({
  setDestTokenInputValue,
  setSrcTokenInputValue,
  setTouched,
  destTokenInputValue,
  rates,
  disabled,
  depositAsset,
  vaultToken,
}: {
  setTouched: (touched: boolean) => void
  destTokenInputValue: string
  setSrcTokenInputValue: (value: string) => void
  setDestTokenInputValue: (value: string) => void
  rates: YearnTokenRates | undefined
  disabled: boolean
  vaultToken: Token
  depositAsset: Token
}) {
  const onChange = (event: any) => {
    if (!rates) {
      console.error('No rates')
      return
    }

    const value = event.target.value
    if (value === '') {
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
      return
    }

    setTouched(true)

    const decimalPlaces = vaultToken.decimals
    const valueClean = trimDecimalPlaces(value, decimalPlaces)
    setDestTokenInputValue(valueClean)

    const toReceiveVaultToken = parseUnits(valueClean, vaultToken.decimals)
    const depositAssetAmount = depositAssetRequiredForVaultToken({
      toReceiveVaultToken,
      pricePerShare: rates.pricePerShare,
      vaultTokenDecimals: vaultToken.decimals,
    })
    const srcAmountStr = formatUnits(depositAssetAmount, depositAsset.decimals)
    setSrcTokenInputValue(srcAmountStr)
  }

  return (
    <StyledSwEthInput
      variant="standard"
      value={destTokenInputValue}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

// Withdraw: top input
export function WithdrawVaultTokenInput({
  errorMessage,
  setDestTokenInputValue,
  setSrcTokenInputValue,
  setTouched,
  srcTokenInputValue,
  rates,
  balances,
  disabled,
  maxLossBasisPoints,
  vaultToken,
  withdrawAsset,
}: {
  setTouched: (touched: boolean) => void
  srcTokenInputValue: string
  setSrcTokenInputValue: (value: string) => void
  setDestTokenInputValue: (value: string) => void
  errorMessage: string | null
  rates: YearnTokenRates | undefined
  balances: YearnBalances | undefined
  disabled: boolean
  vaultToken: Token
  withdrawAsset: YearnWithdrawAsset | undefined
  maxLossBasisPoints: number | undefined
}) {
  const onChange = (event: any) => {
    if (!rates) {
      console.error('No withdraw rates')
      return
    }
    if (typeof maxLossBasisPoints !== 'number') {
      console.error('No max loss basis points')
      return
    }
    if (!withdrawAsset) {
      console.error('No withdraw asset')
      return
    }

    const value = event.target.value
    if (value === '') {
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
      return
    }

    setTouched(true)

    const decimalPlaces = vaultToken.decimals
    const valueClean = trimDecimalPlaces(value, decimalPlaces)
    setSrcTokenInputValue(valueClean)

    const toSendVaultToken = parseUnits(valueClean, vaultToken.decimals)
    const receivedAssets = withdrawAssetReceivedForVaultToken({
      pricePerShare: rates.pricePerShare,
      toSendVaultToken: toSendVaultToken,
      withdrawFeeBasisPoints: withdrawAsset.withdrawFeeBasisPoints,
      vaultTokenDecimals: vaultToken.decimals,
    })
    const destAmountStr = formatUnits(receivedAssets, withdrawAsset.decimals)
    setDestTokenInputValue(destAmountStr)
  }

  const handleMaxClick = () => {
    if (!balances) return
    onChange({
      target: {
        value: formatUnits(balances.vaultToken, vaultToken.decimals),
      },
    })
  }

  return (
    <StyledEthInput
      variant="standard"
      value={srcTokenInputValue}
      onChange={onChange}
      error={!!errorMessage}
      helperText={errorMessage}
      disabled={disabled}
      onMaxClick={handleMaxClick}
    />
  )
}

// Withdraw: bottom input
export function WithdrawReceiveAssetInput({
  setDestTokenInputValue,
  setSrcTokenInputValue,
  destTokenInputValue,
  rates,
  disabled,
  setTouched,
  vaultToken,
  withdrawAsset,
}: {
  setTouched: (touched: boolean) => void
  destTokenInputValue: string
  setSrcTokenInputValue: (value: string) => void
  setDestTokenInputValue: (value: string) => void
  rates: YearnTokenRates | undefined
  disabled: boolean
  withdrawAsset: YearnWithdrawAsset | undefined
  vaultToken: Token
}) {
  const onChange = (event: any) => {
    if (!rates) {
      console.error('No withdraw rates')
      return
    }
    if (!withdrawAsset) {
      console.error('No withdraw asset')
      return
    }
    const value = event.target.value
    if (value === '') {
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
      return
    }

    setTouched(true)

    const decimalPlaces = withdrawAsset.decimals
    const valueClean = trimDecimalPlaces(value, decimalPlaces)
    setDestTokenInputValue(valueClean)

    const toReceiveAsset = parseUnits(valueClean, withdrawAsset.decimals)
    const vaultTokenRequired = vaultTokenRequiredForAsset({
      toReceiveAsset: toReceiveAsset,
      pricePerShare: rates.pricePerShare,
      withdrawFeeBasisPoints: withdrawAsset.withdrawFeeBasisPoints,
      vaultTokenDecimals: vaultToken.decimals,
    })
    const srcAmountStr = formatUnits(vaultTokenRequired, vaultToken.decimals)
    setSrcTokenInputValue(srcAmountStr)
  }

  return (
    <StyledSwEthInput
      variant="standard"
      value={destTokenInputValue}
      onChange={onChange}
      disabled={disabled}
    />
  )
}
