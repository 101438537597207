import React from 'react'

function InfoInvertedIcon(props: any) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 16C12.4183 16 16 12.4183 16 8.00001C16 3.58172 12.4183 0 8.00001 0C3.58172 0 0 3.58172 0 8.00001C0 12.4183 3.58172 16 8.00001 16ZM7.33878 5.87878V12.6667H8.66012V5.87878H7.33878ZM7.41391 4.60164C7.57889 4.75484 7.77628 4.83144 8.00608 4.83144C8.23588 4.83144 8.4318 4.75484 8.59384 4.60164C8.75882 4.44549 8.84131 4.25989 8.84131 4.04482C8.84131 3.8268 8.75882 3.6412 8.59384 3.488C8.4318 3.33185 8.23588 3.25378 8.00608 3.25378C7.77628 3.25378 7.57889 3.33185 7.41391 3.488C7.25187 3.6412 7.17085 3.8268 7.17085 4.04482C7.17085 4.25989 7.25187 4.44549 7.41391 4.60164Z"
        fill="#A4ABF1"
      />
    </svg>
  )
}

export { InfoInvertedIcon }
