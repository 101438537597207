import React from 'react'

function CheckCircleIcon(props: any) {
  return (
    <svg
      {...props}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 10L11.1625 17L7.5 13.5"
        stroke="white"
        strokeWidth="1.33"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
        stroke="white"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { CheckCircleIcon }
