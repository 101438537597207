import React from 'react'

function PlusIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M25.3332 15.3334V16.6667C25.3332 17.0349 25.0347 17.3334 24.6665 17.3334H17.3332V24.6667C17.3332 25.0349 17.0347 25.3334 16.6665 25.3334H15.3332C14.965 25.3334 14.6665 25.0349 14.6665 24.6667V17.3334H7.33317C6.96498 17.3334 6.6665 17.0349 6.6665 16.6667V15.3334C6.6665 14.9652 6.96498 14.6667 7.33317 14.6667H14.6665V7.33335C14.6665 6.96516 14.965 6.66669 15.3332 6.66669H16.6665C17.0347 6.66669 17.3332 6.96516 17.3332 7.33335V14.6667H24.6665C25.0347 14.6667 25.3332 14.9652 25.3332 15.3334Z"
        fill="url(#paint0_linear_7139_4807)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7139_4807"
          x1="6.6665"
          y1="16"
          x2="25.3332"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#845AFF" />
          <stop offset="1" stopColor="#4943E0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { PlusIcon }
