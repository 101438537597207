import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { matchSorter } from 'match-sorter'
import { ExternalLinkIcon } from '@/swell-ui/icons/ExternalLinkIcon'
import { TableConfig } from '@swell-ui/Table'
import { SORT_DIR } from '@swell-ui/Table/constants'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { useSwellUiTheme } from '@swell-ui/theme'
import { abbreviateNumber, displayPercent } from '@/util/displayNumbers'
import { TokenPoolExternal } from '@/types/tokens'
import { PoolTableFull } from './PoolTableFull'
import { PoolTableMobile } from './PoolTableMobile'
import { PoolTableUtilities } from './PoolTableUtilities'
import { ALL_CHAINS_OPTION, SORT_KEY } from './constants'

export interface PoolTableRow extends TokenPoolExternal {
  formattedApy: string
  maxApy: string
  formattedTvl: string
  linkIcon: React.ReactNode
}

const TableWrapper = styled.div`
  padding: 24px 8px;
  border-radius: 16px;
  margin-top: 18px;
  background: rgba(11, 20, 27, 0.5);

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      padding: 48px;
      margin-top: 24px;
    }
  `}
`

const COLUMNS: TableConfig['columns'] = [
  {
    key: 'protocol',
    label: 'Protocol',
    sortKey: SORT_KEY.PROTOCOL,
  },
  {
    key: 'positionName',
    label: 'Pool / Token Name',
    sortKey: SORT_KEY.POSITION_NAME,
  },
  {
    key: 'category',
    label: 'Category',
    sortKey: SORT_KEY.CATEGORY,
  },
  {
    key: 'formattedTvl',
    label: 'TVL',
    sortKey: SORT_KEY.TVL,
    defaultSortDirection: SORT_DIR.DESC,
  },
  {
    key: 'formattedApy',
    label: 'APY',
    sortKey: SORT_KEY.MAX_APY,
    defaultSortDirection: SORT_DIR.DESC,
  },
  {
    key: 'linkIcon',
    label: '',
  },
]

const LOADING_LENGTH = 27

const DEFAULT_SORT_BY = `${SORT_KEY.TVL}_${SORT_DIR.DESC}`

export function PoolTable({
  pools,
  loading,
}: {
  pools: TokenPoolExternal[]
  loading: boolean
}) {
  const { theme } = useSwellUiTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const [filterValue, setFilterValue] = useState<string>('')
  const [chainId, setChainId] = useState<number>(ALL_CHAINS_OPTION.key)
  const [sortBy, setSortBy] = useState<string>(DEFAULT_SORT_BY)

  const tableConfig = useMemo<TableConfig>(() => {
    if (pools.length === 0) {
      return {
        columns: COLUMNS,
        rows: [],
        loadingLength: LOADING_LENGTH,
        defaultSortBy: DEFAULT_SORT_BY,
      }
    }

    let rows = pools.map((pool: TokenPoolExternal): PoolTableRow => {
      return {
        ...pool,
        get formattedApy() {
          if (this.apy.length === 0) {
            return '-'
          }
          if (this.apy.length === 1) {
            if (this.apy[0] < 0) return 'N/A'
            return displayPercent(this.apy[0])
          }
          return `${displayPercent(this.apy[0])} - ${displayPercent(
            this.apy[this.apy.length - 1]
          )}`
        },
        get maxApy() {
          if (this.apy.length === 0) {
            return '-'
          }
          if (this.apy[0] < 0) {
            return 'N/A'
          }
          return `${displayPercent(this.apy[this.apy.length - 1])}`
        },
        get formattedTvl() {
          return `$${abbreviateNumber(this.tvl)}`
        },
        linkIcon: <ExternalLinkIcon />,
      }
    })

    // filtering logic
    if (chainId !== ALL_CHAINS_OPTION.key) {
      rows = rows.filter((pool: TokenPoolExternal) => {
        return pool.chainId === chainId
      })
    }

    if (filterValue) {
      rows = matchSorter(rows, filterValue, {
        keys: ['category', 'positionName', 'protocol'],
        threshold: matchSorter.rankings.CONTAINS,
      })
    }

    // sorting logic
    const [sortKey, sortDir] = sortBy.split('_')

    if (sortKey === SORT_KEY.TVL) {
      rows = rows.sort((poolA: TokenPoolExternal, poolB: TokenPoolExternal) => {
        if (sortDir === SORT_DIR.ASC) {
          return poolA.tvl - poolB.tvl
        }

        return poolB.tvl - poolA.tvl
      })
    } else if (sortKey === SORT_KEY.MAX_APY) {
      rows = rows.sort((poolA: TokenPoolExternal, poolB: TokenPoolExternal) => {
        if (sortDir === SORT_DIR.ASC) {
          return (
            poolA.apy[poolA.apy.length - 1] - poolB.apy[poolB.apy.length - 1]
          )
        }

        return poolB.apy[poolB.apy.length - 1] - poolA.apy[poolA.apy.length - 1]
      })
    } else if (
      [SORT_KEY.CATEGORY, SORT_KEY.POSITION_NAME, SORT_KEY.PROTOCOL].includes(
        sortKey
      )
    ) {
      rows = rows.sort((poolA: TokenPoolExternal, poolB: TokenPoolExternal) => {
        const direction = sortDir === SORT_DIR.ASC ? 1 : -1
        const key = sortKey as keyof TokenPoolExternal
        const poolAValue = poolA[key] as string
        const poolBValue = poolB[key] as string

        return poolAValue.localeCompare(poolBValue) * direction
      })
    }

    return {
      columns: COLUMNS,
      rows: rows,
      loadingLength: LOADING_LENGTH,
      onRowClick: (row: PoolTableRow) => {
        window.open(row.link, '_blank')
      },
      onSort: (newSortBy: string) => {
        setSortBy(newSortBy)
      },
      defaultSortBy: DEFAULT_SORT_BY,
    }
  }, [chainId, filterValue, pools, sortBy])

  const handleChainChange = (newChainId: number) => {
    setChainId(newChainId)
  }

  const handleFilterChange = (newFilterValue: string) => {
    setFilterValue(newFilterValue)
  }

  const handleSortChange = (newSortBy: string) => {
    setSortBy(newSortBy)
  }

  const table = () => {
    if (isMdUp) {
      return <PoolTableFull config={tableConfig} loading={loading} />
    }

    return <PoolTableMobile config={tableConfig} loading={loading} />
  }

  return (
    <>
      <PoolTableUtilities
        pools={pools}
        onChainChange={handleChainChange}
        onFilterChange={handleFilterChange}
        onSortChange={handleSortChange}
        loading={loading}
      />
      <TableWrapper>{table()}</TableWrapper>
    </>
  )
}
