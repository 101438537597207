import React from 'react'
import styled from 'styled-components'
import { css } from 'styled-components'

const InnerCircle = styled.div`
  position: absolute;
  width: 7px;
  height: 7px;
  border: none;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const restakeCSS = css`
  border: 1px solid ${({ theme }) => theme.mainColor};
  ${InnerCircle} {
    background: ${({ theme }) => theme.mainColor};
  }
`
const oceanCSS = css`
  border: 1px solid ${({ theme }) => theme.colors.white['50']};
  ${InnerCircle} {
    background: ${({ theme }) => theme.colors.white['50']};
  }
`

const Layout = styled.div<{ isRestakeTheme?: boolean }>`
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;

  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }

  ${({ isRestakeTheme }) => (isRestakeTheme ? restakeCSS : oceanCSS)}
`

export function RadioButton({
  isRestakeTheme,
  onClick,
  selected,
}: {
  onClick?: () => void
  selected?: boolean
  isRestakeTheme?: boolean // TODO: handle styles at theme layer
}) {
  return (
    <div>
      <Layout onClick={onClick} isRestakeTheme={isRestakeTheme}>
        {selected && <InnerCircle />}
      </Layout>
    </div>
  )
}
