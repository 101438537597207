import styled from 'styled-components'
import { YearnVaultStats } from '@/state/yearnVault/types'
import { Token } from '@/types/tokens'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { BigNumber } from 'ethers'
import { formatWithConfig } from '@/util/number'
import { displayPercentFromPercent } from '@/util/displayNumbers'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { displayBtcLrtTVL, displayBtcLrtVaultTokenValue } from '../usd'
import { labelStyle, valueStyle } from './common'
import { useSwellWeb3 } from '@/swell-web3/core'
import { Tooltip } from '@/swell-ui/Tooltip'
import blackPearlLogo from '@/assets/images/black-pearl-80x80.png'

const ROW_HEIGHT = '25.6px'
const ICON_SIZE_PX = 28

const symbioticLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg'

export function BtcLrtStatistics({
  vaultStats,
  vaultToken,
  depositAssetEthRate,
  ethUsdMarketRate,
  depositAsset,
  karakPoints,
  eigenLayerPoints,
  blackPearls,
  vaultSymbioticPoints,
  vaultTokenBalance,
  pricePerShare,
}: {
  vaultToken: Token
  depositAsset: Token
  vaultStats: YearnVaultStats | undefined
  ethUsdMarketRate: number | undefined
  depositAssetEthRate: BigNumber | undefined
  karakPoints: string | undefined
  eigenLayerPoints: string | undefined
  vaultSymbioticPoints: number | undefined
  blackPearls: number | undefined
  vaultTokenBalance: BigNumber | undefined
  pricePerShare: BigNumber | undefined
}) {
  const { account } = useSwellWeb3()
  const noAccount = !account

  let formattedTVL = ''
  if (vaultStats && ethUsdMarketRate && depositAssetEthRate) {
    formattedTVL = displayBtcLrtTVL({
      ethUsdMarketRate,
      depositAssetEthRate,
      totalAssets: vaultStats.totalAssets,
      depositAssetDecimals: depositAsset.decimals,
    })
  }

  let totalDepositorsStr = ''
  if (vaultStats) {
    totalDepositorsStr = formatWithConfig(vaultStats.totalDepositors, {
      localize: true,
      precision: 0,
    })
  }

  let apyPercentStr = ''
  if (vaultStats) {
    apyPercentStr = displayPercentFromPercent(vaultStats.apyPercent, {
      precision: 2,
    })
  }
  return (
    <Layout className={noAccount ? 'no-account' : ''}>
      <div>
        <span className="label">Total Value Locked</span>
        <div>
          <AsyncDiv loading={!formattedTVL}>
            {() => <span className="value">{formattedTVL}</span>}
          </AsyncDiv>
        </div>
        <span className="label">Total Depositors</span>
        <div>
          <AsyncDiv loading={!totalDepositorsStr}>
            {() => <span className="value">{totalDepositorsStr}</span>}
          </AsyncDiv>
        </div>
        <span className="label">Vault APR</span>
        <div>
          {/* <AsyncDiv loading={!apyPercentStr}>
            {() => <span className="value">{apyPercentStr}</span>}
          </AsyncDiv> */}
          <Tooltip title="Yield to come when restaking protocols go live">
            <span className="value">TBD</span>
          </Tooltip>
        </div>
        <span className="label">Fees</span>
        <div>
          <Tooltip title="Fee will switch to 1% when yields stabilize">
            <span className="value">0%</span>
          </Tooltip>
        </div>
      </div>
      <div className="a-holdings">
        <Holdings
          noAccount={noAccount}
          vaultToken={vaultToken}
          vaultTokenBalance={vaultTokenBalance}
          depositAsset={depositAsset}
          depositAssetEthRate={depositAssetEthRate}
          pricePerShare={pricePerShare}
          ethUsdMarketRate={ethUsdMarketRate}
          blackPearls={blackPearls}
          vaultSymbioticPoints={vaultSymbioticPoints}
          karakPoints={karakPoints}
          eigenLayerPoints={eigenLayerPoints}
        />
      </div>
    </Layout>
  )
}

const Layout = styled.div`
  white-space: nowrap;

  display: grid;
  grid-template-columns: 3fr 5fr;
  gap: 24px;

  &.no-account {
    .a-holdings {
      img,
      .value {
        opacity: 0.5;
      }
    }
  }

  > div {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-auto-rows: ${ROW_HEIGHT};
    gap: 24px;
    align-items: center;

    span.label {
      ${labelStyle}
    }
    span.value {
      ${valueStyle}
    }

    .two-rows {
      align-self: start;
      grid-row: span 2;
    }

    .three-rows {
      align-self: start;
      grid-row: span 3;
    }

    .col {
      display: flex;
      flex-flow: column nowrap;
      gap: 12px;
    }

    .row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 6px;
    }

    img.round {
      border-radius: 50%;
    }
    img.grey {
      filter: grayscale(100%);
    }
  }

  [aria-busy='true'] {
    max-width: 70px;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-flow: column nowrap;
    white-space: unset;

    span.label {
      width: 175px;
    }
  }
`

function Holdings({
  vaultToken,
  vaultTokenBalance,
  depositAsset,
  depositAssetEthRate,
  pricePerShare,
  ethUsdMarketRate,
  blackPearls,
  vaultSymbioticPoints,
  karakPoints,
  noAccount,
  eigenLayerPoints,
}: {
  noAccount: boolean
  vaultToken: Token
  vaultTokenBalance: BigNumber | undefined
  depositAsset: Token
  depositAssetEthRate: BigNumber | undefined
  pricePerShare: BigNumber | undefined
  ethUsdMarketRate: number | undefined
  blackPearls: number | undefined
  vaultSymbioticPoints: number | undefined
  karakPoints: string | undefined
  eigenLayerPoints: string | undefined
}) {
  let vaultTokenBalanceStr = ''
  if (vaultTokenBalance) {
    vaultTokenBalanceStr = displayCryptoLocale(
      vaultTokenBalance,
      vaultToken.decimals,
      { btc: true }
    )
  }

  let vaultTokenValueStr = ''
  if (
    ethUsdMarketRate &&
    vaultTokenBalance &&
    depositAssetEthRate &&
    pricePerShare
  ) {
    vaultTokenValueStr = displayBtcLrtVaultTokenValue({
      ethUsdMarketRate,
      vaultTokenBalance,
      depositAssetEthRate,
      pricePerShare,
      vaultTokenDecimals: vaultToken.decimals,
      depositAssetDecimals: depositAsset.decimals,
    })
  }

  let vaultTokenStr = ''
  if (vaultTokenBalanceStr && vaultTokenValueStr) {
    vaultTokenStr = `${vaultTokenBalanceStr} (${vaultTokenValueStr})`
  } else if (vaultTokenBalanceStr) {
    vaultTokenStr = vaultTokenBalanceStr
  }

  let blackPearlsStr = ''
  if (blackPearls !== undefined) {
    blackPearlsStr = formatWithConfig(blackPearls, {
      localize: true,
      precision: 0,
    })
    blackPearlsStr = `${blackPearlsStr} (3x Boost)`
  }

  let vaultSymbioticPointsStr = ''
  if (vaultSymbioticPoints !== undefined) {
    vaultSymbioticPointsStr = formatWithConfig(vaultSymbioticPoints, {
      localize: true,
      precision: 0,
    })
  }

  let karakPointsStr = ''
  if (karakPoints) {
    karakPointsStr = karakPoints
  }

  let eigenLayerPointsStr = ''
  if (eigenLayerPoints) {
    eigenLayerPointsStr = eigenLayerPoints
  }

  if (noAccount) {
    vaultTokenBalanceStr = '-'
    vaultTokenValueStr = '-'
    vaultTokenStr = '-'
    blackPearlsStr = '-'
    karakPointsStr = '-'
    eigenLayerPointsStr = '-'
  }

  return (
    <>
      <span className="label">Your {vaultToken.symbol}</span>
      <div className="row">
        <img
          className="round"
          src={vaultToken.logoURI}
          width={ICON_SIZE_PX}
          height={ICON_SIZE_PX}
        />
        <AsyncDiv loading={!vaultTokenStr}>
          {() => <span className="value">{vaultTokenStr}</span>}
        </AsyncDiv>
      </div>
      <span className="label">Your Pearls</span>
      <div className="col">
        <span className="value row">
          <img
            className="round"
            src={blackPearlLogo}
            width={ICON_SIZE_PX}
            height={ICON_SIZE_PX}
          />{' '}
          <AsyncDiv loading={!blackPearlsStr}>{() => blackPearlsStr}</AsyncDiv>
        </span>
      </div>
      <span className="label">Vault Points</span>
      <div className="col">
        <span className="value row">
          <img
            className="round"
            src={symbioticLogo}
            width={ICON_SIZE_PX}
            height={ICON_SIZE_PX}
          />{' '}
          <AsyncDiv loading={!vaultSymbioticPointsStr}>
            {() => vaultSymbioticPointsStr}
          </AsyncDiv>
        </span>
      </div>
    </>
  )
}
