export const ACTIONS = {
  CLAIM: 'Claim',
  STAKE: 'Stake',
  SWAP: 'Swap',
  RESTAKE: 'Restake',
  UNSTAKE: 'Unstake',
  VAULT: 'Vault',
  WITHDRAW: 'Withdraw',
  ZAP: 'Zap',
  CLAIM_EIGEN: 'Claim Eigen',
}

export const TRANSACTION_TOAST_TITLE = {
  COMPLETED: 'Transaction completed!',
  STAKE_PROMPTING: 'Stake pending',
  STAKE_PENDING: 'Stake confirming',
  UNSTAKE_PROMPTING: 'Unstake request pending',
  UNSTAKE_PENDING: 'Unstake request confirming',
  UNSTAKE_COMPLETED: 'Unstake request completed!',
  RESTAKE_PROMPTING: 'Restake pending',
  RESTAKE_PENDING: 'Stake confirming',
  APPROVE_PROMPTING: 'Approve pending',
  APPROVE_PENDING: 'Approve confirming',
  ZAP_BUILDING: 'Zap building',
  ZAP_PROMPTING: 'Zap pending',
  ZAP_PENDING: 'Zap confirming',
  YEARN_DEPOSIT_PROMPTING: 'Deposit pending',
  YEARN_DEPOSIT_PENDING: 'Deposit confirming',
  YEARN_DEPOSIT_COMPLETED: 'Deposit completed!',
  YEARN_REQUEST_WITHDRAWAL_PROMPTING: 'Withdrawal request pending',
  YEARN_REQUEST_WITHDRAWAL_PENDING: 'Withdrawal request confirming',
  YEARN_REQUEST_WITHDRAWAL_COMPLETED: 'Withdrawal request created!',
  YEARN_CANCEL_WITHDRAWAL_PROMPTING: 'Withdrawal cancel pending',
  YEARN_CANCEL_WITHDRAWAL_PENDING: 'Withdrawal cancel confirming',
  YEARN_CANCEL_WITHDRAWAL_COMPLETED: 'Withdrawal cancel completed!',
  YEARN_COMPLETE_WITHDRAWAL_PROMPTING: 'Complete withdrawal pending',
  YEARN_COMPLETE_WITHDRAWAL_PENDING: 'Complete withdrawal confirming',
  YEARN_COMPLETE_WITHDRAWAL_COMPLETED: 'Withdrawal completed!',
  CLAIM_PENDING: 'Claim confirming',
  CLAIM_PROMPTING: 'Claim pending',
  CLAIM_COMPLETED: 'Claim completed!',
}
