// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/lifi.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.RoutesRequest
 */
export class RoutesRequest extends Message<RoutesRequest> {
  /**
   * @generated from field: int32 from_chain_id = 1;
   */
  fromChainId = 0;

  /**
   * @generated from field: string from_amount = 2;
   */
  fromAmount = "";

  /**
   * @generated from field: string from_token_address = 3;
   */
  fromTokenAddress = "";

  /**
   * @generated from field: int32 to_chain_id = 4;
   */
  toChainId = 0;

  /**
   * @generated from field: string to_token_address = 5;
   */
  toTokenAddress = "";

  /**
   * @generated from field: swell.v3.RoutesOptions options = 6;
   */
  options?: RoutesOptions;

  constructor(data?: PartialMessage<RoutesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RoutesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "from_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "to_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "to_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "options", kind: "message", T: RoutesOptions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoutesRequest {
    return new RoutesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoutesRequest {
    return new RoutesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoutesRequest {
    return new RoutesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RoutesRequest | PlainMessage<RoutesRequest> | undefined, b: RoutesRequest | PlainMessage<RoutesRequest> | undefined): boolean {
    return proto3.util.equals(RoutesRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.RoutesOptions
 */
export class RoutesOptions extends Message<RoutesOptions> {
  /**
   * @generated from field: string slippage = 1;
   */
  slippage = "";

  /**
   * @generated from field: string max_price_impact = 2;
   */
  maxPriceImpact = "";

  /**
   * @generated from field: string integrator = 3;
   */
  integrator = "";

  /**
   * @generated from field: repeated string exchanges = 4;
   */
  exchanges: string[] = [];

  constructor(data?: PartialMessage<RoutesOptions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RoutesOptions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "slippage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "max_price_impact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "integrator", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "exchanges", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoutesOptions {
    return new RoutesOptions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoutesOptions {
    return new RoutesOptions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoutesOptions {
    return new RoutesOptions().fromJsonString(jsonString, options);
  }

  static equals(a: RoutesOptions | PlainMessage<RoutesOptions> | undefined, b: RoutesOptions | PlainMessage<RoutesOptions> | undefined): boolean {
    return proto3.util.equals(RoutesOptions, a, b);
  }
}

/**
 * @generated from message swell.v3.RoutesResponse
 */
export class RoutesResponse extends Message<RoutesResponse> {
  /**
   * @generated from field: bytes steps_raw = 1;
   */
  stepsRaw = new Uint8Array(0);

  /**
   * @generated from field: string from_amount = 2;
   */
  fromAmount = "";

  /**
   * @generated from field: string from_amount_usd = 3;
   */
  fromAmountUsd = "";

  /**
   * @generated from field: swell.v3.Token from_token = 4;
   */
  fromToken?: Token;

  /**
   * @generated from field: string to_amount = 5;
   */
  toAmount = "";

  /**
   * @generated from field: string to_amount_usd = 6;
   */
  toAmountUsd = "";

  /**
   * @generated from field: swell.v3.Token to_token = 7;
   */
  toToken?: Token;

  constructor(data?: PartialMessage<RoutesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RoutesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "steps_raw", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "from_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from_amount_usd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "from_token", kind: "message", T: Token },
    { no: 5, name: "to_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "to_amount_usd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "to_token", kind: "message", T: Token },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoutesResponse {
    return new RoutesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoutesResponse {
    return new RoutesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoutesResponse {
    return new RoutesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RoutesResponse | PlainMessage<RoutesResponse> | undefined, b: RoutesResponse | PlainMessage<RoutesResponse> | undefined): boolean {
    return proto3.util.equals(RoutesResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.Token
 */
export class Token extends Message<Token> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: int32 chain_id = 2;
   */
  chainId = 0;

  /**
   * @generated from field: string symbol = 3;
   */
  symbol = "";

  /**
   * @generated from field: int32 decimals = 4;
   */
  decimals = 0;

  /**
   * @generated from field: string name = 5;
   */
  name = "";

  /**
   * @generated from field: string coin_key = 6;
   */
  coinKey = "";

  /**
   * @generated from field: string logo_uri = 7;
   */
  logoUri = "";

  /**
   * @generated from field: string price_usd = 8;
   */
  priceUsd = "";

  constructor(data?: PartialMessage<Token>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.Token";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "coin_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "logo_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "price_usd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Token {
    return new Token().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Token {
    return new Token().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Token {
    return new Token().fromJsonString(jsonString, options);
  }

  static equals(a: Token | PlainMessage<Token> | undefined, b: Token | PlainMessage<Token> | undefined): boolean {
    return proto3.util.equals(Token, a, b);
  }
}

/**
 * @generated from message swell.v3.PopulateTransactionRequest
 */
export class PopulateTransactionRequest extends Message<PopulateTransactionRequest> {
  /**
   * @generated from field: bytes step_raw = 1;
   */
  stepRaw = new Uint8Array(0);

  /**
   * @generated from field: string for_address = 2;
   */
  forAddress = "";

  constructor(data?: PartialMessage<PopulateTransactionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PopulateTransactionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "step_raw", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "for_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PopulateTransactionRequest {
    return new PopulateTransactionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PopulateTransactionRequest {
    return new PopulateTransactionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PopulateTransactionRequest {
    return new PopulateTransactionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PopulateTransactionRequest | PlainMessage<PopulateTransactionRequest> | undefined, b: PopulateTransactionRequest | PlainMessage<PopulateTransactionRequest> | undefined): boolean {
    return proto3.util.equals(PopulateTransactionRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.PopulateTransactionResponse
 */
export class PopulateTransactionResponse extends Message<PopulateTransactionResponse> {
  /**
   * @generated from field: string data = 1;
   */
  data = "";

  /**
   * @generated from field: string to = 2;
   */
  to = "";

  /**
   * @generated from field: string value = 3;
   */
  value = "";

  /**
   * @generated from field: string from = 4;
   */
  from = "";

  /**
   * @generated from field: int32 chain_id = 5;
   */
  chainId = 0;

  /**
   * @generated from field: string gas_price = 6;
   */
  gasPrice = "";

  /**
   * @generated from field: string gas_limit = 7;
   */
  gasLimit = "";

  constructor(data?: PartialMessage<PopulateTransactionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PopulateTransactionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "to", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "gas_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "gas_limit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PopulateTransactionResponse {
    return new PopulateTransactionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PopulateTransactionResponse {
    return new PopulateTransactionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PopulateTransactionResponse {
    return new PopulateTransactionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PopulateTransactionResponse | PlainMessage<PopulateTransactionResponse> | undefined, b: PopulateTransactionResponse | PlainMessage<PopulateTransactionResponse> | undefined): boolean {
    return proto3.util.equals(PopulateTransactionResponse, a, b);
  }
}

