import { ServiceType } from '@bufbuild/protobuf'
import { PromiseClient } from '@bufbuild/connect'
import useSWR, { SWRResponse } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { protobufToUrl } from '@/util/protobuf'

export type UseProtobufQueryOpts = {
  refreshInterval?: number
  paused?: boolean
}

const makeProtobufQuery = (useSWRHook: typeof useSWR) => {
  const useProtobufQueryHook = <
    ST extends ServiceType,
    PC extends PromiseClient<ST>,
    Method extends keyof PromiseClient<ST>
  >(
    {
      service,
      method,
      client,
      params,
      backendUrl,
    }: {
      service: ST
      method: Method
      client: PC
      params: Parameters<PC[Method]>[0]
      backendUrl: string
    },
    { paused = false, refreshInterval }: UseProtobufQueryOpts = {}
  ): SWRResponse<Awaited<ReturnType<PC[Method]>>> => {
    return useSWRHook(
      // (pause) By changing the query key to null, the query will not execute (achieving the desired "paused" effect)
      // The SWR isPaused() option was not used, since it will not refetch upon unpause when used in tandem with SWRImmutable
      paused
        ? null
        : [
            protobufToUrl({
              service,
              method,
              prefix: backendUrl,
            }),
            // stableHash is used by swr to make a key from the params
            // guaranteed to be unique despite object ordering or nesting
            params,
          ],
      () => Promise.resolve(client[method](params as any)),
      {
        refreshInterval,
      }
    ) as any
  }

  return useProtobufQueryHook
}

export const useProtobufQuery = makeProtobufQuery(useSWR)
export const useProtobufQueryImmutable = makeProtobufQuery(useSWRImmutable)
