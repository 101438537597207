// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/paraswap.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.ParaswapRoutesRequest
 */
export class ParaswapRoutesRequest extends Message<ParaswapRoutesRequest> {
  /**
   * @generated from field: int32 from_chain_id = 1;
   */
  fromChainId = 0;

  /**
   * @generated from field: string from_amount = 2;
   */
  fromAmount = "";

  /**
   * @generated from field: string from_token_address = 3;
   */
  fromTokenAddress = "";

  /**
   * @generated from field: int32 from_token_decimals = 4;
   */
  fromTokenDecimals = 0;

  /**
   * @generated from field: int32 to_chain_id = 5;
   */
  toChainId = 0;

  /**
   * @generated from field: string to_token_address = 6;
   */
  toTokenAddress = "";

  /**
   * @generated from field: int32 to_token_decimals = 7;
   */
  toTokenDecimals = 0;

  /**
   * @generated from field: string from_address = 8;
   */
  fromAddress = "";

  /**
   * @generated from field: swell.v3.ParaswapRoutesOptions options = 9;
   */
  options?: ParaswapRoutesOptions;

  constructor(data?: PartialMessage<ParaswapRoutesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.ParaswapRoutesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "from_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "from_token_decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "to_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "to_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "to_token_decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "from_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "options", kind: "message", T: ParaswapRoutesOptions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParaswapRoutesRequest {
    return new ParaswapRoutesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParaswapRoutesRequest {
    return new ParaswapRoutesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParaswapRoutesRequest {
    return new ParaswapRoutesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ParaswapRoutesRequest | PlainMessage<ParaswapRoutesRequest> | undefined, b: ParaswapRoutesRequest | PlainMessage<ParaswapRoutesRequest> | undefined): boolean {
    return proto3.util.equals(ParaswapRoutesRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.ParaswapRoutesOptions
 */
export class ParaswapRoutesOptions extends Message<ParaswapRoutesOptions> {
  /**
   * @generated from field: string slippage = 1;
   */
  slippage = "";

  /**
   * @generated from field: string max_price_impact = 2;
   */
  maxPriceImpact = "";

  constructor(data?: PartialMessage<ParaswapRoutesOptions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.ParaswapRoutesOptions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "slippage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "max_price_impact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParaswapRoutesOptions {
    return new ParaswapRoutesOptions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParaswapRoutesOptions {
    return new ParaswapRoutesOptions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParaswapRoutesOptions {
    return new ParaswapRoutesOptions().fromJsonString(jsonString, options);
  }

  static equals(a: ParaswapRoutesOptions | PlainMessage<ParaswapRoutesOptions> | undefined, b: ParaswapRoutesOptions | PlainMessage<ParaswapRoutesOptions> | undefined): boolean {
    return proto3.util.equals(ParaswapRoutesOptions, a, b);
  }
}

/**
 * @generated from message swell.v3.ParaswapToken
 */
export class ParaswapToken extends Message<ParaswapToken> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: int32 chain_id = 2;
   */
  chainId = 0;

  /**
   * @generated from field: int32 decimals = 3;
   */
  decimals = 0;

  /**
   * @generated from field: string price_usd = 4;
   */
  priceUsd = "";

  constructor(data?: PartialMessage<ParaswapToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.ParaswapToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "price_usd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParaswapToken {
    return new ParaswapToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParaswapToken {
    return new ParaswapToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParaswapToken {
    return new ParaswapToken().fromJsonString(jsonString, options);
  }

  static equals(a: ParaswapToken | PlainMessage<ParaswapToken> | undefined, b: ParaswapToken | PlainMessage<ParaswapToken> | undefined): boolean {
    return proto3.util.equals(ParaswapToken, a, b);
  }
}

/**
 * @generated from message swell.v3.ParaswapRoutesResponse
 */
export class ParaswapRoutesResponse extends Message<ParaswapRoutesResponse> {
  /**
   * @generated from field: bytes price_route_raw = 1;
   */
  priceRouteRaw = new Uint8Array(0);

  /**
   * @generated from field: string from_amount = 2;
   */
  fromAmount = "";

  /**
   * @generated from field: string from_amount_usd = 3;
   */
  fromAmountUsd = "";

  /**
   * @generated from field: swell.v3.ParaswapToken from_token = 4;
   */
  fromToken?: ParaswapToken;

  /**
   * @generated from field: string to_amount = 5;
   */
  toAmount = "";

  /**
   * @generated from field: string to_amount_usd = 6;
   */
  toAmountUsd = "";

  /**
   * @generated from field: swell.v3.ParaswapToken to_token = 7;
   */
  toToken?: ParaswapToken;

  constructor(data?: PartialMessage<ParaswapRoutesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.ParaswapRoutesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "price_route_raw", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "from_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from_amount_usd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "from_token", kind: "message", T: ParaswapToken },
    { no: 5, name: "to_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "to_amount_usd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "to_token", kind: "message", T: ParaswapToken },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParaswapRoutesResponse {
    return new ParaswapRoutesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParaswapRoutesResponse {
    return new ParaswapRoutesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParaswapRoutesResponse {
    return new ParaswapRoutesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ParaswapRoutesResponse | PlainMessage<ParaswapRoutesResponse> | undefined, b: ParaswapRoutesResponse | PlainMessage<ParaswapRoutesResponse> | undefined): boolean {
    return proto3.util.equals(ParaswapRoutesResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.ParaswapPopulateTransactionRequest
 */
export class ParaswapPopulateTransactionRequest extends Message<ParaswapPopulateTransactionRequest> {
  /**
   * @generated from field: bytes price_route_raw = 1;
   */
  priceRouteRaw = new Uint8Array(0);

  /**
   * @generated from field: string for_address = 2;
   */
  forAddress = "";

  /**
   * @generated from field: int32 slippage_bp = 3;
   */
  slippageBp = 0;

  constructor(data?: PartialMessage<ParaswapPopulateTransactionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.ParaswapPopulateTransactionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "price_route_raw", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "for_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "slippage_bp", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParaswapPopulateTransactionRequest {
    return new ParaswapPopulateTransactionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParaswapPopulateTransactionRequest {
    return new ParaswapPopulateTransactionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParaswapPopulateTransactionRequest {
    return new ParaswapPopulateTransactionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ParaswapPopulateTransactionRequest | PlainMessage<ParaswapPopulateTransactionRequest> | undefined, b: ParaswapPopulateTransactionRequest | PlainMessage<ParaswapPopulateTransactionRequest> | undefined): boolean {
    return proto3.util.equals(ParaswapPopulateTransactionRequest, a, b);
  }
}

/**
 * TODO: shared types
 *
 * @generated from message swell.v3.ParaswapPopulateTransactionResponse
 */
export class ParaswapPopulateTransactionResponse extends Message<ParaswapPopulateTransactionResponse> {
  /**
   * @generated from field: string data = 1;
   */
  data = "";

  /**
   * @generated from field: string to = 2;
   */
  to = "";

  /**
   * @generated from field: string value = 3;
   */
  value = "";

  /**
   * @generated from field: string from = 4;
   */
  from = "";

  /**
   * @generated from field: int32 chain_id = 5;
   */
  chainId = 0;

  /**
   * @generated from field: string gas_price = 6;
   */
  gasPrice = "";

  /**
   * @generated from field: string gas_limit = 7;
   */
  gasLimit = "";

  constructor(data?: PartialMessage<ParaswapPopulateTransactionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.ParaswapPopulateTransactionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "to", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "gas_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "gas_limit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParaswapPopulateTransactionResponse {
    return new ParaswapPopulateTransactionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParaswapPopulateTransactionResponse {
    return new ParaswapPopulateTransactionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParaswapPopulateTransactionResponse {
    return new ParaswapPopulateTransactionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ParaswapPopulateTransactionResponse | PlainMessage<ParaswapPopulateTransactionResponse> | undefined, b: ParaswapPopulateTransactionResponse | PlainMessage<ParaswapPopulateTransactionResponse> | undefined): boolean {
    return proto3.util.equals(ParaswapPopulateTransactionResponse, a, b);
  }
}

