import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

export function BorderGradient({
  containerRef,
  stops,
  classNames,
}: {
  containerRef: React.RefObject<HTMLDivElement>
  stops: { offset: string; color: string }[]
  classNames?: { svg?: string }
}) {
  const ID = useMemo(() => {
    return {
      shape: `${uuid()}-s`,
      borderMask: `${uuid()}-bm`,
      borderGradient: `${uuid()}-bg`,
    }
  }, [])

  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)

  useEffect(() => {
    let done = false
    const animate = () => {
      if (containerRef.current && !done) {
        setWidth(containerRef.current.clientWidth)
        setHeight(containerRef.current.clientHeight)
        requestAnimationFrame(animate)
      }
    }
    animate()
    return () => {
      done = true
    }
  }, [containerRef])

  return (
    <Layout>
      <svg
        width={width ? width : '100%'}
        height={height ? height : '100%'}
        overflow="visible"
        pointerEvents="none"
        className={classNames?.svg}
      >
        <defs>
          <rect
            id={ID.shape}
            x="0"
            y="0"
            rx="12"
            ry="12"
            width="100%"
            height="100%"
          />
          <mask id={ID.borderMask}>
            <use
              href={`#${ID.shape}`}
              fillOpacity="0"
              stroke="white"
              strokeWidth="1"
            />
          </mask>
          <linearGradient
            id={ID.borderGradient}
            x1="50%"
            y1="0"
            x2="50%"
            y2="1"
          >
            {stops.map((stop, i) => (
              <stop key={i} offset={stop.offset} stopColor={stop.color} />
            ))}
          </linearGradient>
        </defs>
        <g mask={`url(#${ID.borderMask})`}>
          <use
            href={`#${ID.shape}`}
            fill={`url(#${ID.borderGradient})`}
            fillOpacity={1}
            transform="scale(1.5)"
            transform-origin="center"
          />
        </g>
      </svg>
    </Layout>
  )
}

const Layout = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;
`
