import styled from 'styled-components'
import { Button } from '@swell-ui/Button'
import { Typography } from '@swell-ui/Typography'
import { VaultBigIcon } from '@swell-ui/icons/VaultBigIcon'

const Layout = styled.div`
  display: flex;
  flex-flow: nowrap column;
  align-items: center;
  justify-content: center;
`

const VaultTextContainer = styled.div`
  color: ${({ theme }) => theme.colors.white['50']};
  text-align: center;
`

const StyledVaultBigIcon = styled(VaultBigIcon)`
  margin: 32px 0;
`

const UpperTypography = styled(Typography)`
  color: rgb(255, 255, 255);
  font-family: Inter, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 52.8px;
  text-align: center;

  margin-bottom: 16px;
`

const LowerTextTypography = styled(Typography)`
  color: rgba(255, 255, 255, 0.8);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: -0.48px;
  line-height: 38.4px;
  font-style: italic;

  margin-bottom: 16px;
`

const LearnMoreButton = styled(Button)`
  width: 100%;
  height: 30px;
  padding: 4px;
  margin: 16px 0 41px;
  font-size: 14px;
  opacity: 0.7;

  &:hover:not([disabled]) {
    opacity: 0.4;
  }
`

const ButtonAnchor = styled.a``

const UPPER_TEXT = 'Supercharge your yield and safeguard Ethereum'
const LOWER_TEXT =
  'Deposit stETH. Get swETH. Earn supercharged yield. Diversify Ethereum.'
const BUTTON_TEXT = 'Deposit stETH'
const LEARN_MORE_TEXT = 'Learn more'

function VaultView() {
  return (
    <Layout>
      <VaultTextContainer>
        <StyledVaultBigIcon />
        <UpperTypography variant="headline" size="h5" letterSpacing="small">
          {UPPER_TEXT}
        </UpperTypography>
        <LowerTextTypography variant="body" size="medium" letterSpacing="small">
          {LOWER_TEXT}
        </LowerTextTypography>
      </VaultTextContainer>
      <ButtonAnchor
        href="https://swellsupersweth.enzyme.community/vault/0x325a0e5c84b4d961b19161956f57ae8ba5bb3c26"
        target="_blank"
        rel="noreferrer"
      >
        <Button variant="primary">{BUTTON_TEXT}</Button>
      </ButtonAnchor>
      <ButtonAnchor
        href="https://www.swellnetwork.io/vault"
        target="_blank"
        rel="noreferrer"
      >
        <LearnMoreButton variant="tertiary">{LEARN_MORE_TEXT}</LearnMoreButton>
      </ButtonAnchor>
    </Layout>
  )
}

export { VaultView }
