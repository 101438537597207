// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/lifi.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { PopulateTransactionRequest, PopulateTransactionResponse, RoutesRequest, RoutesResponse } from "./lifi_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.LifiService
 */
export const LifiService = {
  typeName: "swell.v3.LifiService",
  methods: {
    /**
     * @generated from rpc swell.v3.LifiService.Routes
     */
    routes: {
      name: "Routes",
      I: RoutesRequest,
      O: RoutesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc swell.v3.LifiService.PopulateTransaction
     */
    populateTransaction: {
      name: "PopulateTransaction",
      I: PopulateTransactionRequest,
      O: PopulateTransactionResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

