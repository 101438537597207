import { ClaimEigenStakedrop } from '@/state/rsweth/hooks'
import { EigenLayerStakedropResult } from '@/state/rsweth/types'
import { Token } from '@/types/tokens'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { merkleClaimable } from '@/util/merkledrop'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'
import { StakingConfirmationProgressWidget } from '../StakingConfirmationProgressWidget'

export const TRANSACTION_TOAST_TITLE = {
  COMPLETED: 'Transaction completed!',
  CLAIM_EIGEN_STAKEDROP_PROMPTING: 'Claim request pending',
  CLAIM_EIGEN_STAKEDROP_PENDING: 'Claim request confirming',
}

export function ClaimEigenStakedropToast({
  claimEigenStakedrop,
  eigenToken,
  eigenStakedropResult,
}: {
  claimEigenStakedrop: ClaimEigenStakedrop
  eigenToken: Token
  eigenStakedropResult: EigenLayerStakedropResult | undefined
}) {
  const onClose = claimEigenStakedrop.clear

  const complete =
    claimEigenStakedrop.status === claimEigenStakedrop.STATUS.FULFILLED
  const confirming =
    claimEigenStakedrop.status === claimEigenStakedrop.STATUS.PROMPTING
  const pending =
    claimEigenStakedrop.status === claimEigenStakedrop.STATUS.PENDING
  const idle = claimEigenStakedrop.status === claimEigenStakedrop.STATUS.IDLE

  const [claimAmount, setClaimAmount] = useState<BigNumber | undefined>()
  useEffect(() => {
    if (idle) {
      setClaimAmount(undefined)
      return
    }

    if (!claimEigenStakedrop.args) return
    if (!eigenStakedropResult?.exists) return

    const { claimableAmount } = merkleClaimable({
      cumulativeAmount: eigenStakedropResult.data.cumulativeAmount,
      cumulativeClaimed: eigenStakedropResult.cumulativeClaimed,
      totalAmount: eigenStakedropResult.data.totalAmount,
    })
    setClaimAmount((prev) => {
      if (prev) return prev
      return claimableAmount
    })
  }, [eigenStakedropResult, eigenToken, claimEigenStakedrop.args, idle])

  let claimToWalletStr = ''
  if (claimEigenStakedrop.args && claimAmount) {
    const [{ amountToLock }] = claimEigenStakedrop.args

    const claimToWalletAmount = claimAmount.sub(amountToLock)
    claimToWalletStr = displayCryptoLocale(
      claimToWalletAmount,
      eigenToken.decimals
    )

    if (amountToLock.gt(0)) {
      return null // amountToLock is always 0
    }
  }

  let message = ''
  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
    message = `Claimed ${claimToWalletStr} ${eigenToken.symbol}`
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_EIGEN_STAKEDROP_PENDING
    message = `Claiming ${claimToWalletStr} ${eigenToken.symbol}`
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_EIGEN_STAKEDROP_PROMPTING
    message = `Claim request for ${claimToWalletStr} ${eigenToken.symbol}`
  }

  const txHash = claimEigenStakedrop.txHash
  const open = !!title && !!message

  return (
    <StakingConfirmationProgressWidget
      complete={complete}
      confirming={confirming}
      pending={pending}
      title={title}
      txHash={txHash}
      onClose={onClose}
      open={open}
      message={message}
    />
  )
}
