const SymbioticThemeData = {
  symbioticLime: '#C0FD5C',
  symbioticTitleGrey: '#F1F1F1',
  symbioticBackgroundSolid: '#080808',
  symbioticDarkGrey: '#4A4A4A',
  symbioticBlack: '#0B0B0B',
  symbioticLightGrey: '#B0B0B0',
  symbioticBorderRadius: '16px',
  symbioticBackdropColor: 'rgba(11, 11, 11, 0.60)',
  SymbioticBackdropFilter: 'blur(30px)',
  symbioticBodyFontFamily: 'Inter',
  symbioticButtonPrimaryTextColor: '#000',
  symbioticEthBlue: '#88AAF1',
  symbioticBtcOrange: '#F7931A',
  symbioticBitcoinDark: '#090D14',
  symbioticBitcoinNavy: '#14161F',
}

export const ThemeData = {
  Symbiotic: SymbioticThemeData,
}
