/**
 * Copied: https://github.com/Uniswap/smart-order-router/blob/adf5b62fe5264f72903571537861de327c850751/src/providers/eip-1559-gas-price-provider.ts
 * Changed:
 * - Remove logging
 */
import { BigNumber } from '@ethersproject/bignumber'
import { JsonRpcProvider } from '@ethersproject/providers'
import _ from 'lodash'
import { GasPrice, IGasPriceProvider } from './IGasPriceProvider'

export type RawFeeHistoryResponse = {
  baseFeePerGas: string[]
  gasUsedRatio: number[]
  oldestBlock: string
  reward: string[]
}

export type FeeHistoryResponse = {
  baseFeePerGas: BigNumber[]
  gasUsedRatio: number[]
  oldestBlock: BigNumber
  reward: BigNumber[]
}

// We get the Xth percentile of priority fees for transactions successfully included in previous blocks.
const DEFAULT_PRIORITY_FEE_PERCENTILE = 50
// Infura docs say only past 4 blocks guaranteed to be available: https://infura.io/docs/ethereum#operation/eth_feeHistory
const DEFAULT_BLOCKS_TO_LOOK_BACK = 4

/**
 * Computes a gas estimate using on-chain data from the eth_feeHistory RPC endpoint.
 *
 * Takes the average priority fee from the past `blocksToConsider` blocks, and adds it
 * to the current base fee.
 *
 * @export
 * @class EIP1559GasPriceProvider
 */
export class EIP1559GasPriceProvider implements IGasPriceProvider {
  constructor(
    protected provider: JsonRpcProvider,
    private priorityFeePercentile: number = DEFAULT_PRIORITY_FEE_PERCENTILE,
    private blocksToConsider: number = DEFAULT_BLOCKS_TO_LOOK_BACK
  ) {}

  public async getGasPrice(): Promise<GasPrice> {
    const feeHistoryRaw = (await this.provider.send('eth_feeHistory', [
      /**
       * @fix Use BigNumber.from(this.blocksToConsider).toHexString() after hardhat adds support
       * @see https://github.com/NomicFoundation/hardhat/issues/1585 .___.
       */
      BigNumber.from(this.blocksToConsider).toHexString().replace('0x0', '0x'),
      'latest',
      [this.priorityFeePercentile],
    ])) as RawFeeHistoryResponse

    const feeHistory: FeeHistoryResponse = {
      baseFeePerGas: _.map(feeHistoryRaw.baseFeePerGas, (b) =>
        BigNumber.from(b)
      ),
      gasUsedRatio: feeHistoryRaw.gasUsedRatio,
      oldestBlock: BigNumber.from(feeHistoryRaw.oldestBlock),
      reward: _.map(feeHistoryRaw.reward, (b) => BigNumber.from(b[0])),
    }

    const nextBlockBaseFeePerGas =
      feeHistory.baseFeePerGas[feeHistory.baseFeePerGas.length - 1]!

    const averagePriorityFeePerGas = _.reduce(
      feeHistory.reward,
      (sum: BigNumber, cur: BigNumber) => sum.add(cur),
      BigNumber.from(0)
    ).div(feeHistory.reward.length)

    const gasPriceWei = nextBlockBaseFeePerGas.add(averagePriorityFeePerGas)

    return { gasPriceWei: gasPriceWei }
  }
}
