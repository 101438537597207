import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'
import {
  useV3BackendClient,
  WalletClient,
} from '@/services/V3BackendService/hooks'
import { StatsClient } from '@/services/V3BackendService/types'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import {
  useBtcLrtApproveAssetForDeposit,
  useBtcLrtApproveVaultTokenForWithdraw,
  useBtcLrtCancelWithdraw,
  useBtcLrtCompleteWithdraw,
  useBtcLrtDeposit,
  useBtcLrtRequestWithdraw,
} from '@/state/yearnVault/hooks'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useEffect } from 'react'
import useSWR from 'swr'

async function fetchSwellSeason2Points(client: WalletClient, address: string) {
  const { blackPearls } = await client.btcLrtUser({ walletAddress: address })
  return blackPearls
}
export function useBlackPearlsBtcLrt({
  refreshInterval,
}: {
  refreshInterval: number
}) {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const walletClient =
    useV3BackendClient(v3BackendLstUrl).v3BackendClient.wallet
  const { account } = useSwellWeb3()

  return useSWR(
    account ? [account, 'blackPearlsBtcLrt'] : null,
    () => fetchSwellSeason2Points(walletClient, account!),
    {
      refreshInterval,
    }
  )
}
async function fetchVaultSymbioticPoints(statsClient: StatsClient) {
  const stats = await statsClient.btcLrt({})
  return stats.symbioticPointsVault
}
export function useVaultSymbioticPoints() {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const statsClient = useV3BackendClient(v3BackendLrtUrl).v3BackendClient.stats
  return useSWR('btcLrtVaultSymbioticPoints', () =>
    fetchVaultSymbioticPoints(statsClient)
  )
}
async function fetchEigenLayerPoints() {
  return 'Coming soon'
}
export function useEigenLayerPointsQuery() {
  return useSWR('eigenLayerPoints', fetchEigenLayerPoints)
}
async function fetchKarakPoints() {
  return 'Coming soon'
}
export function useKarakPoints() {
  return useSWR('karakPoints', fetchKarakPoints)
}

export function useBtcLrtVaultCalls() {
  const deposit = useBtcLrtDeposit()
  const approveAssetForDeposit = useBtcLrtApproveAssetForDeposit()
  const requestWithdraw = useBtcLrtRequestWithdraw()
  const approveVaultTokenForWithdraw = useBtcLrtApproveVaultTokenForWithdraw()
  const cancelWithdraw = useBtcLrtCancelWithdraw()
  const completeWithdraw = useBtcLrtCompleteWithdraw()

  useWeb3CallErrorNotify(deposit)
  useWeb3CallErrorNotify(approveAssetForDeposit)
  useWeb3CallErrorNotify(requestWithdraw)
  useWeb3CallErrorNotify(approveVaultTokenForWithdraw)
  useWeb3CallErrorNotify(cancelWithdraw)
  useWeb3CallErrorNotify(completeWithdraw)

  useEffect(() => {
    if (deposit.status === deposit.STATUS.FULFILLED) {
      const t = setTimeout(() => {
        deposit.clear()
      }, 5000)
    }
  }, [deposit])
  useEffect(() => {
    if (
      approveAssetForDeposit.status === approveAssetForDeposit.STATUS.FULFILLED
    ) {
      setTimeout(() => {
        approveAssetForDeposit.clear()
      }, 5000)
    }
  }, [approveAssetForDeposit])
  useEffect(() => {
    if (requestWithdraw.status === requestWithdraw.STATUS.FULFILLED) {
      setTimeout(() => {
        requestWithdraw.clear()
      }, 5000)
    }
  }, [requestWithdraw])
  useEffect(() => {
    if (
      approveVaultTokenForWithdraw.status ===
      approveVaultTokenForWithdraw.STATUS.FULFILLED
    ) {
      setTimeout(() => {
        approveVaultTokenForWithdraw.clear()
      }, 5000)
    }
  }, [approveVaultTokenForWithdraw])
  useEffect(() => {
    if (cancelWithdraw.status === cancelWithdraw.STATUS.FULFILLED) {
      setTimeout(() => {
        cancelWithdraw.clear()
      }, 5000)
    }
  }, [cancelWithdraw])
  useEffect(() => {
    if (completeWithdraw.status === completeWithdraw.STATUS.FULFILLED) {
      setTimeout(() => {
        completeWithdraw.clear()
      }, 5000)
    }
  }, [completeWithdraw])

  return {
    deposit,
    approveAssetForDeposit,
    requestWithdraw,
    approveVaultTokenForWithdraw,
    cancelWithdraw,
    completeWithdraw,
  }
}
