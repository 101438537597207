import { SolmateRolesAuth__factory, Multicall3 } from '@/abis/types'

export type CanCallQuery = {
  user: string
  target: string
  sig: string // 4 byte signature
}[]
export type CanCallResult = {
  user: string
  target: string
  sig: string
  canCall: boolean
}[]

export class SolmateRolesAuthorityService {
  multicall: Multicall3
  rolesAuthorityAddress: string

  constructor(multicall: Multicall3, rolesAuthorityAddress: string) {
    this.multicall = multicall
    this.rolesAuthorityAddress = rolesAuthorityAddress
  }

  fetchCanCall = async (canCallQuery: CanCallQuery): Promise<CanCallResult> => {
    const calls: Multicall3.Call3Struct[] = []

    for (let i = 0; i < canCallQuery.length; i++) {
      const { sig, target, user } = canCallQuery[i]
      calls.push({
        target: this.rolesAuthorityAddress,
        callData:
          SolmateRolesAuth__factory.createInterface().encodeFunctionData(
            'canCall',
            [user, target, sig]
          ),
        allowFailure: false,
      })
    }

    const results = await this.multicall.callStatic.tryAggregate(true, calls)

    const canCallResult: CanCallResult = []
    for (let i = 0; i < results.length; i++) {
      const { sig, target, user } = canCallQuery[i]
      const result = results[i]
      const canCall =
        SolmateRolesAuth__factory.createInterface().decodeFunctionResult(
          'canCall',
          result.returnData
        )[0]
      canCallResult.push({ canCall, sig, target, user })
    }

    return canCallResult
  }
}
