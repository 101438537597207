/**
 * Copied: https://github.com/ethereum/staking-launchpad/blob/042612b78a1c616a98a64d039ac45d0010df8863/src/utils/SSZ.ts
 * Changes:
 * - kept logic only relating to verifyDepositRoots
 * - Change the input type from DepositInfo to DepositInfoCryptoFormat (i.e. more explicit data requirements)
 */
import { DepositInfoCryptoFormat } from '@/types/deposits'
import {
  NumberUintType,
  ByteVector,
  ByteVectorType,
  ContainerType,
} from '@chainsafe/ssz'

const DepositMessage = new ContainerType({
  fields: {
    pubkey: new ByteVectorType({
      length: 48,
    }),
    withdrawalCredentials: new ByteVectorType({
      length: 32,
    }),
    amount: new NumberUintType({
      byteLength: 8,
    }),
  },
})

// eslint-disable-next-line @typescript-eslint/no-redeclare
interface DepositMessage {
  pubkey: ByteVector
  withdrawalCredentials: ByteVector
  // eslint-disable-next-line @typescript-eslint/ban-types
  amount: Number
}

const DepositData = new ContainerType({
  fields: {
    pubkey: new ByteVectorType({
      length: 48,
    }),
    withdrawalCredentials: new ByteVectorType({
      length: 32,
    }),
    amount: new NumberUintType({
      byteLength: 8,
    }),
    signature: new ByteVectorType({
      length: 96,
    }),
  },
})

// eslint-disable-next-line @typescript-eslint/no-redeclare
interface DepositData {
  pubkey: ByteVector
  withdrawalCredentials: ByteVector
  // eslint-disable-next-line @typescript-eslint/ban-types
  amount: Number
  signature: ByteVector
}

export const verifyDepositRoots = (
  deposit: DepositInfoCryptoFormat
): boolean => {
  const depositMessage: DepositMessage = {
    pubkey: deposit.pubkey,
    withdrawalCredentials: deposit.withdrawal_credentials,
    amount: Number(deposit.amount),
  }
  const depositData: DepositData = {
    pubkey: deposit.pubkey,
    withdrawalCredentials: deposit.withdrawal_credentials,
    amount: Number(deposit.amount),
    signature: deposit.signature,
  }
  if (
    deposit.deposit_message_root.compare(
      DepositMessage.hashTreeRoot(depositMessage)
    ) === 0 &&
    deposit.deposit_data_root.compare(DepositData.hashTreeRoot(depositData)) ===
      0
  ) {
    return true
  }
  return false
}
