import useSWRImmutable from 'swr/immutable'
import { useSwellWeb3 } from '@swell-web3/core'
import {
  useRestakingDepositManagerContractView,
  useRestakingNodeOperatorRegistryContractView,
} from '@/hooks/useContract'
import useSWR from 'swr'
import { useDepositManagerStatsV3Backend } from '@/services/V3BackendService/hooks'
import { Task } from '@/services/TaskRunner/ITaskRunner'
import { TaskRunnerParallel } from '@/services/TaskRunner/TaskRunnerParallel'
import noop from 'lodash/noop'
import range from 'lodash/range'
import orderBy from 'lodash/orderBy'
import { NodeOperatorInfo } from '@/types/operators'
import { useDeploymentSetConfig } from '../deployments/hooks'

export const useProtocolLastDepositAt = () => {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const { data, ...query } = useDepositManagerStatsV3Backend(v3BackendLrtUrl)

  return {
    ...query,
    get data() {
      if (data === undefined) return undefined
      const { lastDepositAt } = data
      return { lastDepositAt }
    },
  }
}

/**
 * Retrieves the number of operators in the protocol
 */
export const useProtocolNumOperators = () => {
  const { chainId } = useSwellWeb3()
  const restakingNodeOperatorRegistry =
    useRestakingNodeOperatorRegistryContractView()

  return useSWR(
    [chainId, restakingNodeOperatorRegistry.address, 'numOperators'],
    () =>
      restakingNodeOperatorRegistry
        .numOperators()
        .then((numOperators) => ({ numOperators: numOperators.toNumber() })),
    {
      refreshInterval: 6000,
    }
  )
}

export const useProtocolBufferedEth = () => {
  const { chainId, provider } = useSwellWeb3()
  const { address } = useRestakingDepositManagerContractView()
  return useSWRImmutable([chainId, 'getBalance', address], async () => {
    return {
      bufferedEth: await provider!.getBalance(address!),
    }
  })
}

export const useProtocolUnusedKeysCount = () => {
  const { chainId } = useSwellWeb3()

  const restakingNodeOperatorRegistry =
    useRestakingNodeOperatorRegistryContractView()
  return useSWR(
    [restakingNodeOperatorRegistry.address, chainId, 'numPendingValidators'],
    async () => ({
      unusedKeysCount:
        await restakingNodeOperatorRegistry.numPendingValidators(),
    }),
    {
      refreshInterval: 6000,
    }
  )
}

const useProtocolUsedKeysCount = () => {
  const { chainId } = useSwellWeb3()

  const restakingNodeOperatorRegistry =
    useRestakingNodeOperatorRegistryContractView()
  return useSWR(
    [restakingNodeOperatorRegistry.address, chainId, 'getPoRAddressListLength'],
    async () => ({
      usedKeysCount:
        await restakingNodeOperatorRegistry.getPoRAddressListLength(),
    }),
    {
      refreshInterval: 6000,
    }
  )
}

/**
 * Retrieves information about all restaking node operators in the protocol
 *
 * Information includes:
 *
 * - operatorNumber
 * - operatorId
 * - name
 * - address
 * - totalKeys
 * - usedKeys
 * - unusedKeys
 * - enabled
 */
export const useProtocolAllOperators = () => {
  const { chainId } = useSwellWeb3()
  const nodeOperatorRegistry = useRestakingNodeOperatorRegistryContractView()
  const numOperatorsQuery = useProtocolNumOperators()
  const usedKeysCountQuery = useProtocolUsedKeysCount()
  const unusedKeysCountQuery = useProtocolUnusedKeysCount()

  // TODO: data needs to be read directly otherwise this query won't execute
  // This is a stopgap fix, root cause is not understood
  const { numOperators } = numOperatorsQuery.data ?? {}
  const { unusedKeysCount } = unusedKeysCountQuery.data ?? {}
  const { usedKeysCount } = usedKeysCountQuery.data ?? {}

  // Immutable query -> still changes when the keys change (similar to dependency array)
  return useSWRImmutable(
    /* Without numOperators we cannot execute the query */
    numOperators !== undefined &&
      unusedKeysCount !== undefined &&
      usedKeysCount !== undefined
      ? /* in effect this is multicall, hence custom string key */
        [
          chainId,
          nodeOperatorRegistry.address,
          'PROTOCOL_ALL_OPERATORS',

          // recompute the information for all operators when:
          //  - The number of operators in the protocol changes
          //  - The number of validator keys (used or unused) in the protocol changes
          numOperators,
          unusedKeysCount.toNumber(),
          usedKeysCount.toNumber(),
        ]
      : null,
    async () => {
      const allOperators: NodeOperatorInfo[] = []

      const tasks = range(numOperators!).map((idx): Task => {
        const operatorId = idx + 1

        return async () => {
          const operator = await nodeOperatorRegistry.getOperatorForOperatorId(
            operatorId
          )

          const {
            activeValidators,
            name,
            enabled,
            controllingAddress: address,
          } = operator

          const pendingValidatorDetails =
            await nodeOperatorRegistry.getOperatorsPendingValidatorDetails(
              address
            )

          const unusedKeys = pendingValidatorDetails.length
          const usedKeys = activeValidators.toNumber()
          const totalKeys = usedKeys + unusedKeys

          const nodeOperator: NodeOperatorInfo = {
            address,
            enabled,
            name,
            operatorId,
            totalKeys,
            unusedKeys,
            usedKeys,
          }

          allOperators.push(nodeOperator)
        }
      })

      const taskRunner = TaskRunnerParallel.create()
      await taskRunner.run(tasks, noop)

      return { allOperators: orderBy(allOperators, 'operatorId') }
    }
  )
}
