import React from 'react'
import { Link, useLocation, createPath } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Button } from '@swell-ui/Button'
import { Tooltip } from '@swell-ui/Tooltip'

interface TopNavLinkWrapperProps {
  children: React.ReactNode
  text?: string
}

interface TopNavLinkProps {
  children: React.ReactNode
  disabled?: boolean
  to: string
  tooltipText?: string
}

const StyledTopNavLink = styled<any>(Link)`
  text-decoration: none;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  `}
`

const TopNavLinkButton = styled<any>(Button)`
  padding-left: 0;
  padding-right: 0;

  color: ${({ theme }) => theme.a.color};

  &:hover {
    color: ${({ theme }) => theme.a.hoverColor};
    opacity: 1;
  }

  ${({ theme, active }) =>
    active &&
    `
    color: ${theme.a.hoverColor};
  `}

  &:disabled {
    color: #888b97;
    opacity: 1;
    cursor: not-allowed;
  }
`

function TopNavLinkWrapper(props: TopNavLinkWrapperProps) {
  if (props.text) {
    return (
      <Tooltip title={props.text} arrow>
        {props.children}
      </Tooltip>
    )
  }

  return <>{props.children}</>
}

function TopNavLink({ to, disabled, tooltipText, children }: TopNavLinkProps) {
  const { pathname, search } = useLocation()
  const path = createPath({ pathname: to, search })

  return (
    <TopNavLinkWrapper text={tooltipText}>
      <StyledTopNavLink to={path} disabled={disabled}>
        <TopNavLinkButton
          variant="tertiary"
          active={pathname === to}
          disabled={disabled}
        >
          {children}
        </TopNavLinkButton>
      </StyledTopNavLink>
    </TopNavLinkWrapper>
  )
}

export { TopNavLink }
