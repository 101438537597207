import { ZapContext } from './context'
import {
  useContractAddresses,
  useDeploymentSetConfig,
} from '../deployments/hooks'
import { useCallback, useState } from 'react'
import { ZapRoute } from './types'
import { useSwellWeb3 } from '@/swell-web3/core'
import { TransactionRequest } from '@ethersproject/providers'
import { BigNumber, ethers } from 'ethers'
import {
  ParaswapPopulateTransactionRequest,
  ParaswapRoutesRequest,
} from '@/submodules/v3-shared/ts/connect/swell/v3/paraswap_pb'
import { useV3BackendClient } from '@/services/V3BackendService/hooks'

type ParaswapSwapExchange = {
  data: {
    gasUSD: string
    poolAddress: string
    zeroForOne: boolean
  }
  destAmount: string
  exchange: string
  percent: number
  poolAddresses: string[]
  srcAmount: string
}

type ParaswapRouteSwap = {
  srcToken: string
  srcDecimals: number
  destToken: string
  destDecimals: number
  swapExchanges: ParaswapSwapExchange[]
}

type ParaswapRoute = {
  percent: number
  swaps: ParaswapRouteSwap[]
}

type ParaswapPriceRoute = {
  blockNumber: number
  network: number
  srcToken: string
  srcDecimals: number
  srcAmount: string
  destToken: string
  destDecimals: number
  destAmount: string
  bestRoute: ParaswapRoute[]
  others: ParaswapRoute[]
  gasCostUSD: string
  gasCost: string
  side: string
  tokenTransferProxy: string
  contractAddress: string
  contractMethod: string
  srcUSD: string
  destUSD: string
  partner: string
  partnerFee: number
  maxImpactReached: boolean
  hmac: string
}

// maps a key created from paraswap paramaeters to transaction requests that need to be signed
// allows for estimateGas and the real tx to share api results
const buildCache = new Map<string, TransactionRequest>()

// allows for cancellation of routes requests
let controller: AbortController

function useZapBackendUrl() {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  return v3BackendLstUrl
  // return 'http://localhost:8080'
}

// Provides ZapContext functionality using Paraswap.
// @dev `building` flag only responds to `estimateGas` -- the result from `zap` uses the cached result
export function useZapApiParaswap(): ZapContext {
  const { account, provider } = useSwellWeb3()
  const { paraswapTokenTransferProxy } = useContractAddresses()
  const backendUrl = useZapBackendUrl()
  const {
    v3BackendClient: { paraswap: paraswapService },
  } = useV3BackendClient(backendUrl)

  const build = useCallback(
    async (
      route: ZapRoute,
      slippage: number,
      skipCache: boolean
    ): Promise<TransactionRequest> => {
      if (!route.steps?.length) throw new Error('paraswap: no steps in route')
      if (!account) throw new Error('paraswap: no account')

      const params = new ParaswapPopulateTransactionRequest({
        forAddress: account,
        priceRouteRaw: Buffer.from(route.raw), // expects the raw PriceRoute data from paraswap to be provided unmodified
        slippageBp: Math.round(slippage * 100 * 100), // 0.01 -> 1% -> 100 bp
      })

      const key = [
        ethers.utils.getAddress(params.forAddress),
        params.slippageBp.toString(),
        params.priceRouteRaw.toString(),
      ].join('-')

      const cached = buildCache.get(key)
      if (cached && !skipCache) return cached

      const resp = await paraswapService.populateTransaction(params)

      const txReq: TransactionRequest = {
        data: resp.data,
        to: resp.to,
        value: resp.value,
        from: account,
        chainId: resp.chainId,
        gasPrice: resp.gasPrice,
        gasLimit: resp.gasLimit,
      }

      buildCache.set(key, txReq)
      return txReq
    },
    [account, paraswapService]
  )

  const [building, setBuilding] = useState(false)
  return {
    building,
    write: {
      zap: async ({ route, slippage }, opts) => {
        const skipCache = false
        const txReq = await build(route, slippage, skipCache) // expect cache hit, so no building state
        txReq.gasLimit = opts?.gasLimit ?? txReq.gasLimit
        txReq.gasPrice = undefined // prefer to trust RPC
        return provider.getSigner().sendTransaction(txReq)
      },
      zapEstimateGas: async ({ route, slippage }) => {
        const skipCache = true

        // expect estimateGas to be called before zap
        let txReq: TransactionRequest
        setBuilding(true)
        try {
          txReq = await build(route, slippage, skipCache)
        } finally {
          setBuilding(false)
        }

        // prefer to trust RPC
        txReq.gasLimit = undefined
        txReq.gasPrice = undefined

        return provider.getSigner().estimateGas(txReq)
      },
    },
    read: {
      // https://developers.paraswap.network/smart-contracts
      // "Allowance should be given to TokenTransferProxy contract and not to AugustusSwapper, FUNDS MAY BE LOST OTHERWISE!"
      contractAddress: paraswapTokenTransferProxy,
      getRoute: async ({
        fromAmount,
        fromChainId,
        options,
        toChainId,
        fromToken,
        toToken,
      }) => {
        if (!account) throw new Error('paraswap: no account')

        const params = new ParaswapRoutesRequest({
          fromAmount: fromAmount.toString(),
          fromChainId,
          toChainId,
          options: {
            slippage: options.slippage.toString(),
            maxPriceImpact: options.maxPriceImpact.toString(),
          },
          fromTokenAddress: fromToken.address,
          fromTokenDecimals: fromToken.decimals,
          toTokenAddress: toToken.address,
          toTokenDecimals: toToken.decimals,
          fromAddress: account,
        })

        if (controller) {
          controller.abort()
        }
        controller = new AbortController()
        const signal = controller.signal

        const data = await paraswapService.routes(params, { signal })

        if (!data) throw new Error('No data')
        if (!data.priceRouteRaw) throw new Error('No pice route data')
        if (!data.fromToken || !data.toToken) throw new Error('No token data')

        const priceRoute: ParaswapPriceRoute = JSON.parse(
          Buffer.from(data.priceRouteRaw).toString()
        )

        const bestRoute = priceRoute.bestRoute[0]
        const priceRouteRaw = Buffer.from(data.priceRouteRaw).toString()

        const steps: ZapRoute['steps'] = []
        for (const swap of bestRoute.swaps) {
          const { destToken, srcToken, swapExchanges } = swap
          let fromAmount: BigNumber = BigNumber.from(0)
          let toAmount: BigNumber = BigNumber.from(0)
          const gasCosts: { amountUSD: string }[] = []

          for (const exchange of swapExchanges) {
            fromAmount = fromAmount.add(BigNumber.from(exchange.srcAmount))
            toAmount = toAmount.add(BigNumber.from(exchange.destAmount))
            gasCosts.push({ amountUSD: exchange.data.gasUSD })
          }

          const step: ZapRoute['steps'][0] = {
            from: srcToken,
            to: destToken,
            fromAmount: fromAmount.toString(),
            toAmount: toAmount.toString(),
            estimate: {
              gasCosts,
            },
          }

          steps.push(step)
        }
        const r: ZapRoute = {
          fromToken,
          toToken,
          fromAmount,
          toAmount: data.toAmount,
          raw: priceRouteRaw, // final submission expects the raw PriceRoute data from paraswap to be provided unmodified
          steps,
          fromAmountUSD: data.fromAmountUsd,
          toAmountUSD: data.toAmountUsd,
        }

        return r
      },
    },
  }
}
