import React from 'react'

function UpCaratIcon(props: any) {
  return (
    <svg
      {...props}
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 4L4 -5.96046e-08L-1.19209e-07 4L8 4Z" fill="#B0B0B0" />
    </svg>
  )
}

export { UpCaratIcon }
