import { DEPOSIT_INFO_KEYS, DepositInfo } from '@/types/deposits'
import { strip0x } from '@/util/hexStrings'
import { IDepositInfoVerifier } from './IDepositInfoVerifier'

export const stringLengthVerifier: IDepositInfoVerifier = {
  verify: async (deposit) => {
    const expectedLengths: Partial<Record<keyof DepositInfo, number>> = {
      pubkey: 96,
      withdrawal_credentials: 64,
      signature: 192,
      deposit_message_root: 64,
      deposit_data_root: 64,
      fork_version: 8,
    }

    const invalidPairs: {
      key: keyof DepositInfo
      expected: number
      received: number
    }[] = []

    DEPOSIT_INFO_KEYS.hexStringKeys.forEach((key) => {
      const expected = expectedLengths[key]
      if (!expected) return

      const received = strip0x(deposit[key] as string).length

      if (received !== expected) {
        invalidPairs.push({
          expected,
          key,
          received,
        })
      }
    })

    if (invalidPairs.length > 0) {
      return { invalidPairs }
    }

    return false
  },
}
