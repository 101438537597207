import { StakingConfirmationProgressWidget } from '@/components/StakingConfirmationProgressWidget'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { getNativeCurrencyToExchangeForRsweth } from '@/util/big'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { BigNumber } from 'ethers'
import { ExitAsset, ExitAssets, ExitClaim } from '@/types/claims'
import { parseUnits } from 'ethers/lib/utils'
import { useEffect, useState } from 'react'
import { TRANSACTION_TOAST_TITLE } from '../constants'
import {
  ApproveRswETHForWithdrawal,
  CreateWithdrawRequestRswETH,
  FinalizeWithdrawalRswETH,
} from '@/state/rsweth/hooks'
import { ExitClaimMap } from '@/state/rsweth/types'
import { Token } from '@/types/tokens'

export function ApproveRswETHForWithdrawalToast({
  approveRswETHForWithdrawal,
  anyTransactionInProgress,
  rswETHToken,
}: {
  approveRswETHForWithdrawal: ApproveRswETHForWithdrawal
  anyTransactionInProgress: boolean
  rswETHToken: Token
}) {
  const onClose = approveRswETHForWithdrawal.clear

  const complete =
    approveRswETHForWithdrawal.status ===
    approveRswETHForWithdrawal.STATUS.FULFILLED
  const confirming =
    approveRswETHForWithdrawal.status ===
    approveRswETHForWithdrawal.STATUS.PROMPTING
  const pending =
    approveRswETHForWithdrawal.status ===
    approveRswETHForWithdrawal.STATUS.PENDING

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_PENDING
  }

  let message = ''
  if (approveRswETHForWithdrawal.args) {
    const { amount } = approveRswETHForWithdrawal.args[0]
    const amountStr = displayCryptoLocale(amount, rswETHToken.decimals)
    message = `Approve ${amountStr} ${rswETHToken.symbol} for withdrawal`
  }

  const txHash = approveRswETHForWithdrawal.txHash
  const open = !!title && !!message

  return (
    <StakingConfirmationProgressWidget
      complete={complete}
      confirming={confirming}
      pending={pending}
      title={title}
      txHash={txHash}
      onClose={onClose}
      open={open}
      message={message}
    />
  )
}

export function CreateWithdrawRequestRswETHToast({
  anyTransactionInProgress,
  createWithdrawRequestRswETH,
  primaryRestakingRate,
  exitAssets,
  rswETHToken,
}: {
  createWithdrawRequestRswETH: CreateWithdrawRequestRswETH
  anyTransactionInProgress: boolean
  primaryRestakingRate: BigNumber | undefined
  exitAssets: ExitAssets
  rswETHToken: Token
}) {
  const onClose = createWithdrawRequestRswETH.clear

  const complete =
    createWithdrawRequestRswETH.status ===
    createWithdrawRequestRswETH.STATUS.FULFILLED
  const confirming =
    createWithdrawRequestRswETH.status ===
    createWithdrawRequestRswETH.STATUS.PROMPTING
  const pending =
    createWithdrawRequestRswETH.status ===
    createWithdrawRequestRswETH.STATUS.PENDING

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.UNSTAKE_COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.UNSTAKE_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.UNSTAKE_PENDING
  }

  let rswETHAmountStr = ''
  let receivedAmountStr = ''
  let receivedSymbol = ''
  if (createWithdrawRequestRswETH.args && primaryRestakingRate) {
    const { rswETHAmount, assetAddress } = createWithdrawRequestRswETH.args[0]
    rswETHAmountStr = displayCryptoLocale(rswETHAmount, rswETHToken.decimals)

    let withdrawToken: Token | undefined
    for (const asset of exitAssets) {
      if (asset.address === assetAddress) {
        withdrawToken = asset
        break
      }
    }
    if (!withdrawToken) {
      return null // cannot display
    }

    if (assetAddress === TOKEN_LIST_ETH.address) {
      const nativeAmount = getNativeCurrencyToExchangeForRsweth({
        rswethToEthRate: primaryRestakingRate,
        toReceiveRsweth: rswETHAmount,
      })
      receivedAmountStr = displayCryptoLocale(
        nativeAmount,
        withdrawToken.decimals
      )
      receivedSymbol = withdrawToken.symbol
    } else if (assetAddress) {
      // LSTs not supported yet
    }
  }

  let message = ''
  if (rswETHAmountStr && receivedAmountStr && receivedSymbol) {
    message = `Unstake ${rswETHAmountStr} ${rswETHToken.symbol} for ${receivedAmountStr} ${receivedSymbol}`
  }
  const txHash = createWithdrawRequestRswETH.txHash
  const open = !!title && !!message

  return (
    <StakingConfirmationProgressWidget
      complete={complete}
      confirming={confirming}
      pending={pending}
      title={title}
      txHash={txHash}
      onClose={onClose}
      open={open}
      message={message}
    />
  )
}

function getClaim(
  args: NonNullable<FinalizeWithdrawalRswETH['args']>[0],
  exitClaimMap: ExitClaimMap,
  exitAsset: ExitAsset
) {
  const { assetAddress, requestId } = args
  if (assetAddress !== exitAsset.address) {
    return undefined
  }

  const claims = exitClaimMap?.[exitAsset.exitAddress]

  let foundClaim: ExitClaim | undefined
  if (claims && claims.length) {
    foundClaim = claims.find(
      (claim) => claim.requestId === requestId.toString()
    )
  }

  return foundClaim
}

export function FinalizeWithdrawalRswETHToast({
  finalizeWithdrawalRswETH,
  exitClaimMap,
  anyTransactionInProgress,
  exitAsset,
}: {
  finalizeWithdrawalRswETH: FinalizeWithdrawalRswETH
  exitClaimMap: ExitClaimMap | undefined
  anyTransactionInProgress: boolean
  exitAsset: ExitAsset
}) {
  const onClose = finalizeWithdrawalRswETH.clear

  const complete =
    finalizeWithdrawalRswETH.status ===
    finalizeWithdrawalRswETH.STATUS.FULFILLED
  const confirming =
    finalizeWithdrawalRswETH.status ===
    finalizeWithdrawalRswETH.STATUS.PROMPTING
  const pending =
    finalizeWithdrawalRswETH.status === finalizeWithdrawalRswETH.STATUS.PENDING
  const idle =
    finalizeWithdrawalRswETH.status === finalizeWithdrawalRswETH.STATUS.IDLE

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  const [claim, setClaim] = useState<ExitClaim | undefined>(() => {
    if (!finalizeWithdrawalRswETH.args || !exitClaimMap) {
      return undefined
    }
    const found = getClaim(
      finalizeWithdrawalRswETH.args[0],
      exitClaimMap,
      exitAsset
    )
    return found
  })

  useEffect(() => {
    if (idle) {
      setClaim(undefined)
    }
    setClaim((c) => {
      if (c) return c // use cached value until toast closes

      if (!finalizeWithdrawalRswETH.args || !exitClaimMap) {
        return undefined
      }
      const found = getClaim(
        finalizeWithdrawalRswETH.args[0],
        exitClaimMap,
        exitAsset
      )
      return found
    })
  }, [idle, finalizeWithdrawalRswETH.args, exitClaimMap, exitAsset])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_PENDING
  }

  let message = ''
  if (claim) {
    const { assetAmount: assetAmountNum } = claim
    const assetAmount = parseUnits(
      assetAmountNum.toString(),
      exitAsset.decimals
    )
    const amountStr = displayCryptoLocale(assetAmount, exitAsset.decimals)
    message = `Claiming ${amountStr} ${exitAsset.symbol} from unstake.`
  }

  const txHash = finalizeWithdrawalRswETH.txHash
  const open = !!title && !!message

  return (
    <StakingConfirmationProgressWidget
      complete={complete}
      confirming={confirming}
      pending={pending}
      title={title}
      txHash={txHash}
      onClose={onClose}
      open={open}
      message={message}
    />
  )
}
