import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'

interface LineChartProps {
  config: any
}

function LineChart({ config }: LineChartProps) {
  const chartContainer = useRef<any>(null)

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chart(chartContainer.current, {
        type: 'line',
        ...config,
      })

      return () => {
        newChartInstance.destroy()
      }
    }
  }, [chartContainer, config])

  return <canvas ref={chartContainer} />
}

export { LineChart }
