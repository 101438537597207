import { useLiquidUSDVaultTellerContract } from '@/hooks/useContract'
import useSWRImmutable from 'swr/immutable'

export function useGetEtherFiLUsdShareUnlockTimeMs(
  account: string | undefined
) {
  const tellerContract = useLiquidUSDVaultTellerContract()

  return useSWRImmutable(
    account ? [tellerContract.address, account] : null,
    async () => {
      if (!tellerContract || !account) return null
      const { shareUnlockTime } = tellerContract
      const t = await shareUnlockTime(account)
      const unix = t.toNumber()
      return unix * 1000
    }
  )
}
