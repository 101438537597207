import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { IGeoFenceApi } from './types'

export function useGeoFenceApiImplV3Backend(): IGeoFenceApi {
  const { v3BackendPublicUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendPublicUrl)

  return {
    isGeoRestricted: async () => {
      // empty string IP is resolved as the peer IP address on the backend
      const resp = await v3BackendClient.wallet.checkIP({ ip: '' })

      return {
        isRestricted: resp.isRestricted,
        countryCode: resp.countryCode,
        region: resp.region,
        ip: resp.ip,
        timestamp: resp.timestamp,
      }
    },
  }
}
