import React from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import {
  useUserOperatorId,
  useUserOperatorRecentTransactions,
} from '@/state/restakingUserOperator/hooks'
import { RecentTransactionsCommon } from './RecentTransactionsCommon'
import { IRecentTransactionsTableProps } from './RecentTransactionsCommon/RecentTransactionsTable'

export function RestakingRecentTransactions() {
  const { account } = useSwellWeb3()
  const userOperatorIdQuery = useUserOperatorId()
  const userOperatorRecentTransactionsQuery = useUserOperatorRecentTransactions(
    { account }
  )

  const userOperatorId = userOperatorIdQuery.data?.operatorId.toNumber()

  const tableProps = React.useMemo<IRecentTransactionsTableProps>(() => {
    if (!account) return { recentTransactions: [] }

    return {
      recentTransactions:
        userOperatorRecentTransactionsQuery.data?.recentTransactions,
    }
  }, [account, userOperatorRecentTransactionsQuery.data?.recentTransactions])

  return (
    <RecentTransactionsCommon
      tableProps={tableProps}
      userOperatorId={userOperatorId}
    />
  )
}
