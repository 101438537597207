// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/stats.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { EarnAPYsRequest, EarnAPYsResponse, RemainingEigenlayerSwethRequest, RemainingEigenlayerSwethResponse, StatsServiceAllRequest, StatsServiceAllResponse, StatsServiceBtcLrtRequest, StatsServiceBtcLrtResponse, WavedropStatsRequest, WavedropStatsResponse } from "./stats_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.StatsService
 */
export const StatsService = {
  typeName: "swell.v3.StatsService",
  methods: {
    /**
     * @generated from rpc swell.v3.StatsService.All
     */
    all: {
      name: "All",
      I: StatsServiceAllRequest,
      O: StatsServiceAllResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.EarnAPYs
     */
    earnAPYs: {
      name: "EarnAPYs",
      I: EarnAPYsRequest,
      O: EarnAPYsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.RemainingEigenlayerSweth
     */
    remainingEigenlayerSweth: {
      name: "RemainingEigenlayerSweth",
      I: RemainingEigenlayerSwethRequest,
      O: RemainingEigenlayerSwethResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.BtcLrt
     */
    btcLrt: {
      name: "BtcLrt",
      I: StatsServiceBtcLrtRequest,
      O: StatsServiceBtcLrtResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.WavedropStats
     */
    wavedropStats: {
      name: "WavedropStats",
      I: WavedropStatsRequest,
      O: WavedropStatsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

