import { formatWithConfig } from './number'

const ONE_BILLION = 1e9
const ONE_MILLION = 1e6
const ONE_THOUSAND = 1e3

export function abbreviateNumber(value: number): string {
  let decimal = value
  let abbreviationLetter = ''

  if (value >= ONE_BILLION) {
    decimal = value / ONE_BILLION
    abbreviationLetter = 'B'
  }
  if (value >= ONE_MILLION) {
    decimal = value / ONE_MILLION
    abbreviationLetter = 'M'
  } else if (value >= ONE_THOUSAND) {
    decimal = value / ONE_THOUSAND
    abbreviationLetter = 'K'
  }

  const formatted = formatWithConfig(decimal, { localize: false, precision: 2 })

  return `${formatted}${abbreviationLetter}`
}

export const displayPearls = (amt: number) => {
  if (amt === 0) return '0'
  const rounded = Math.round(amt)
  if (rounded < 1) return '< 1'
  return formatWithConfig(rounded, { localize: true, precision: 0 })
}

export const displayPercent = (
  value: number,
  { precision }: { precision: number } = { precision: 2 }
) => {
  let n = value * 100
  n = n * 1e6
  n = Math.round(n)
  n = n / 1e6
  return `${formatWithConfig(n, { localize: false, precision })}%`
}

export const displayPercentFromBasisPoints = (
  basisPoints: number,
  { precision }: { precision: number } = { precision: 2 }
) => {
  return displayPercent(basisPoints / 1e4, { precision })
}

export const displayPercentFromPercent = (
  percent: number,
  { precision }: { precision: number } = { precision: 2 }
) => {
  return displayPercent(percent / 100, { precision })
}
