// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/twitter.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { TwitterUserRequest, TwitterUserResponse, TwitterVerifyRequest, TwitterVerifyResponse } from "./twitter_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.TwitterService
 */
export const TwitterService = {
  typeName: "swell.v3.TwitterService",
  methods: {
    /**
     * @generated from rpc swell.v3.TwitterService.TwitterVerify
     */
    twitterVerify: {
      name: "TwitterVerify",
      I: TwitterVerifyRequest,
      O: TwitterVerifyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * TwitterUser returns the User record, in addition to
     * parameters for EIP-4361 style signature to be used in TwitterVerify
     *
     * @generated from rpc swell.v3.TwitterService.TwitterUser
     */
    twitterUser: {
      name: "TwitterUser",
      I: TwitterUserRequest,
      O: TwitterUserResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

