import { useMemo } from 'react'
import uniswapDefaultList from '@uniswap/default-token-list'
import {
  useCoingeckoTokenListQuery,
  useUniswapDefaultTokenListQuery,
} from '@/services/TokenList'
import { isProtocolToken } from '@/util/tokens'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { Token } from '@/types/tokens'

function filterUserTokens(userTokens: any[], coingeckoTokens: any[]) {
  const filteredUserTokens = []

  for (let i = 0; i < userTokens.length; i++) {
    const token = userTokens[i]
    const coingeckoToken = coingeckoTokens.find(
      (t: any) => t.address.toLowerCase() === token.address.toLowerCase()
    )

    if (coingeckoToken && !isProtocolToken(token)) {
      filteredUserTokens.push(token)
    }
  }

  return filteredUserTokens
}

export function useVisibleTokens(tokenBalances?: any[]): Token[] {
  const uniswapListQuery = useUniswapDefaultTokenListQuery()
  const coingeckoListQuery = useCoingeckoTokenListQuery()

  const tokens = useMemo(() => {
    // show only tokens with balances if they exist
    if (tokenBalances && tokenBalances.length > 0) {
      // if coingecko data, filter user tokens to get rid of useless tokens
      if (coingeckoListQuery.data) {
        return [
          TOKEN_LIST_ETH,
          ...filterUserTokens(tokenBalances, coingeckoListQuery.data.tokens),
        ]
      }
      return [TOKEN_LIST_ETH, ...tokenBalances]
    }

    // show token list from query if exists, otherwise use data from npm package
    if (uniswapListQuery?.data) {
      return [TOKEN_LIST_ETH, ...uniswapListQuery.data.tokens]
    }

    const uniswapMainnetTokens = uniswapDefaultList.tokens.filter(
      (token: any) => token.chainId === 1 || isProtocolToken(token)
    )

    return [TOKEN_LIST_ETH, ...uniswapMainnetTokens]
  }, [coingeckoListQuery.data, uniswapListQuery.data, tokenBalances])

  return tokens
}
