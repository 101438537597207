import { ITaskRunner } from './ITaskRunner'

export const TaskRunnerParallel = {
  create: (): ITaskRunner => {
    return {
      run: async (tasks, onProgress) => {
        const numTotal = tasks.length
        let numCompleted = 0

        onProgress({ numCompleted, numTotal })

        // TODO: bottleneck to prevent API rate limiting
        await Promise.all(
          tasks.map((task) =>
            task().finally(() => {
              numCompleted++
              onProgress({ numCompleted, numTotal })
            })
          )
        )
      },
    }
  },
}
