import { Interface } from 'ethers/lib/utils'
import {
  ReturnValue,
  getParsedEthersError,
} from '@enzoferey/ethers-error-parser'

/**
 * Transforms an error resulting from a wallet interaction into a string
 *  suitable for display purposes.
 *
 * @param err The error thrown by the web3 interaction
 * @param iface The interface of the contract whose calling resulted in an error
 * @returns Display string
 */
export const web3ErrorAdapter = (
  err: any | undefined,
  iface: Interface
): string => {
  const parsedError = getParsedEthersError(err)
  console.warn('Web3 error', { parsedError, err })

  switch (parsedError.errorCode) {
    case 'CALL_REVERTED': {
      // TODO: Have not seen this case yet
      console.warn('Call reverted', { parsedError, err })
      return 'Call reverted'
    }

    case 'INSUFFICIENT_FUNDS_FOR_GAS':
      return 'Insufficient funds for gas'

    case 'MAX_FEE_PER_GAS_LESS_THAN_BLOCK_BASE_FEE':
      return 'Max gas fee setting is too low'

    case 'MAX_PRIORITY_FEE_PER_GAS_HIGHER_THAN_MAX_FEE_PER_GAS':
      return 'Max gas fee setting is too high'

    case 'NONCE_TOO_LOW':
      return 'Nonce too low'

    case 'REJECTED_TRANSACTION':
      return 'Transaction rejected'

    case 'TRANSACTION_RAN_OUT_OF_GAS':
      return 'Transaction ran out of gas'

    case 'TRANSACTION_UNDERPRICED':
      return 'Transaction underpriced'

    case 'UNKNOWN_ERROR':
    default: {
      console.warn('Reverted (unknown)', { parsedError, err })

      const getErrorData = () => {
        let error = err
        let errorData: string | undefined
        let reason: string | undefined

        while (error) {
          errorData = error.data ?? errorData
          reason = error.reason ?? error.message ?? reason
          error = error.error ?? error.data?.originalError
        }

        return { error, reason, errorData }
      }

      const { errorData, reason } = getErrorData()

      if (errorData) {
        try {
          const decodedErrorMsg = iface.parseError(errorData)
          return `${decodedErrorMsg.name}`
        } catch (parsingErr) {
          console.error('Failed to parse error', { parsingErr, err })
        }
      }

      // Execution reverted errors (dynamic error reason)
      return reason ? `${reason}` : 'Internal'
    }
  }
}

export const web3ErrorAdapter2 = (err: any | undefined): string | undefined => {
  const parsedError = getParsedEthersError(err)
  console.warn('Web3 error', { parsedError, err })
  let errStr: string | undefined

  switch (parsedError.errorCode) {
    case 'CALL_REVERTED':
      errStr = 'Call reverted'
      break
    case 'INSUFFICIENT_FUNDS_FOR_GAS':
      errStr = 'Insufficient funds for gas'
      break
    case 'MAX_FEE_PER_GAS_LESS_THAN_BLOCK_BASE_FEE':
      errStr = 'Max gas fee setting is too low'
      break
    case 'MAX_PRIORITY_FEE_PER_GAS_HIGHER_THAN_MAX_FEE_PER_GAS':
      errStr = 'Max gas fee setting is too high'
      break
    case 'NONCE_TOO_LOW':
      errStr = 'Nonce too low'
      break
    case 'REJECTED_TRANSACTION':
      errStr = 'Transaction rejected'
      break
    case 'TRANSACTION_RAN_OUT_OF_GAS':
      errStr = 'Transaction ran out of gas'
      break
    case 'TRANSACTION_UNDERPRICED':
      errStr = 'Transaction underpriced'
      break
    case 'EXECUTION_REVERTED':
      errStr = 'Execution reverted'
      break

    // handle revert with estimate gas: "UNPREDICTABLE_GAS_LIMIT" is an "UNKNOWN" error
    case 'UNKNOWN_ERROR': {
      errStr = parsedError.context
      if (errStr === 'execution reverted') {
        errStr = 'Execution reverted'
      }
      break
    }
    default: {
      // JSON-RPC errors have context=error_code
      errStr = parsedError.context
      break
    }
  }

  if (!errStr) {
    // could not decode an error message
    return undefined
  }

  let errNum: number
  try {
    // In the case of a JSON-RPC or provider error, the error message is a number
    errNum = parseInt(errStr, 10)
  } catch (e) {
    errNum = NaN
  }

  if (isNaN(errNum)) {
    // message is not a JSON-RPC or provider error code, must be a human readable error
    return errStr
  }

  switch (errNum) {
    // Convert JSON-RPC or provider error codes to human-readable strings
    // JSON-RPC errors
    // https://ethereum-json-rpc.com/errors
    case -32700:
      errStr = 'Invalid JSON (-32700)'
      break
    case -32600:
      errStr = 'Invalid request (-32600)'
      break
    case -32601:
      errStr = 'Method not found (-32601)'
      break
    case -32602:
      errStr = 'Invalid params (-32602)'
      break
    case -32603:
      errStr = 'Internal error (-32603)'
      break
    case -32000:
      errStr = 'Invalid input (-32000)'
      break
    case -32001:
      errStr = 'Resource not found (-32001)'
      break
    case -32002:
      errStr = 'Resource unavailable (-32002)'
      break
    case -32003:
      errStr = 'Transaction rejected (-32003)'
      break
    case -32004:
      errStr = 'Method not supported (-32004)'
      break
    case -32005:
      errStr = 'Limit exceeded (-32005)'
      break
    case -32006:
      errStr = 'JSON-RPC version not supported (-32006)'
      break
    // Provider errors
    // https://docs.metamask.io/snaps/reference/known-errors/
    case 4901:
      errStr = 'Chain disconnected (4901)'
      break
    case 4900:
      errStr = 'Provider disconnected (4900)'
      break
    case 4100:
      errStr = 'Unauthorized (4100)'
      break
    case 4200:
      errStr = 'Unsupported method (4200)'
      break
    case 4001:
      errStr = 'User rejected the request (4001)'
      break

    default:
      break
  }

  return errStr
}
