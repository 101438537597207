import React from 'react'
import styled, { css } from 'styled-components/macro'
import MuiBox, { BoxProps as MuiBoxProps } from '@mui/material/Box'

interface BoxProps extends MuiBoxProps {
  color?: string
  type?: string
}

const StyledBox = styled(MuiBox)<BoxProps>`
  ${({ theme, color, type }) => css`
    background-color: ${theme.box.bgColor};
    border-radius: ${theme.box.borderRadius};
    border: 1px solid ${theme.box.borderColor};
    color: ${theme.box.color};

    ${color === 'accent' &&
    css`
      background-color: ${theme.accentBgColor};
    `}

    ${type === 'clickable' &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
  `}
`

function Box(props: BoxProps) {
  return <StyledBox {...props} />
}

export { Box }
