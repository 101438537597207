import React, { useState } from 'react'
import styled from 'styled-components/macro'
import MuiTable from '@mui/material/Table'
import MuiTableBody from '@mui/material/TableBody'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableContainer from '@mui/material/TableContainer'
import MuiTableHead from '@mui/material/TableHead'
import MuiTableRow from '@mui/material/TableRow'
import { DownCaratIcon } from '../icons/DownCaratIcon'
import { UpCaratIcon } from '../icons/UpCaratIcon'
import { Pagination } from '../Pagination'
import { Skeleton } from '../Skeleton'
import { SORT_DIR, SORT_SEPARATOR } from './constants'

interface TableColumn {
  key: string
  label: any
  align?: string
  component?: (props: any) => React.ReactElement
  sortKey?: string
  defaultSortDirection?: string
}

interface TableProps {
  config: {
    columns: TableColumn[]
    rows: any[]
    loadingLength?: number
    stickyHeader?: boolean
    selectableRows?: boolean
    onRowClick?: (row?: any, index?: number) => any
    onSort?: (sortBy: string) => any
    defaultSortBy?: string
    pagination?: {
      boundaryCount?: number
      count: number
      onChange: (page: number) => void
      page?: number
      siblingCount?: number
      totalResults?: number
    }
  }
  loading?: boolean
}

export type TableConfig = TableProps['config']

const StyledMuiTableContainer = styled(MuiTableContainer)`
  ${({ theme }) => `
    .MuiTableCell-root {
      color: ${theme.mainColor};
      background: ${theme.table.cell.bgColor};
      font-family: "Inter", sans-serif;
      border: none;
      padding: 12px 16px;
    }

    .MuiTableCell-head {
      color: ${theme.table.head.color};
      background: transparent;
    }
  `}
`

const StyledMuiTableRow = styled<any>(MuiTableRow)`
  ${({ $selectable }) =>
    $selectable &&
    `
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  `}

  ${({ $active }) =>
    $active &&
    `
    .MuiTableCell-root {
      color: red;
    }
  `}
`

const StyledMuiTableCell = styled<any>(MuiTableCell)`
  position: relative;
  background: ${({ $clickable }) =>
    $clickable &&
    `
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  `};
`

const StyledDownCaratIcon = styled(DownCaratIcon)`
  position: absolute;
  top: 22px;
  left: 1px;
`

const StyledUpCaratIcon = styled(UpCaratIcon)`
  position: absolute;
  top: 22px;
  left: 1px;
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
`

const ResultsCount = styled.div`
  font-size: 12px;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.colors.white['125']};
`

function createLoadingArray(length?: number) {
  if (!length) {
    return []
  }

  const loadingArray = []
  for (let i = 0; i < length; i++) {
    loadingArray.push(i)
  }

  return loadingArray
}

export function Table({ config, loading, ...props }: TableProps) {
  const loadingArray = createLoadingArray(config.loadingLength)
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [sortBy, setSortBy] = useState<string>(config.defaultSortBy || '')

  const headerCellClickable = (col: TableColumn) => {
    return !!col.sortKey
  }

  const handleHeaderCellClick = (col: TableColumn) => {
    if (col.sortKey && config.onSort) {
      let newSortBy = ''
      const [sortKey, sortDir] = sortBy.split(SORT_SEPARATOR)

      if (!sortKey || !sortDir || sortKey !== col.sortKey) {
        const dir = col.defaultSortDirection || SORT_DIR.ASC
        newSortBy = `${col.sortKey}${SORT_SEPARATOR}${dir}`
      } else {
        const newSortDir =
          sortDir === SORT_DIR.DESC ? SORT_DIR.ASC : SORT_DIR.DESC
        newSortBy = `${col.sortKey}${SORT_SEPARATOR}${newSortDir}`
      }

      setSortBy(newSortBy)
      config.onSort(newSortBy)
    }
  }

  const handleRowClick = (row: any, index: number) => {
    if (config.selectableRows) {
      setSelectedRowIndex(index)
    }

    if (config.onRowClick) {
      config.onRowClick(row, index)
    }
  }

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    if (!config.pagination) {
      return
    }
    config.pagination.onChange(page)
  }

  return (
    <StyledMuiTableContainer {...props}>
      <MuiTable stickyHeader={config.stickyHeader}>
        <MuiTableHead>
          <MuiTableRow>
            {config.columns.map((col: any) => {
              const align = col.align || 'inherit'
              return (
                <StyledMuiTableCell
                  key={col.key}
                  align={align}
                  className={`${col.key}-cell`}
                  onClick={() => handleHeaderCellClick(col)}
                  $clickable={headerCellClickable(col)}
                >
                  {sortBy ===
                    `${col.sortKey}${SORT_SEPARATOR}${SORT_DIR.DESC}` && (
                    <StyledDownCaratIcon />
                  )}
                  {sortBy ===
                    `${col.sortKey}${SORT_SEPARATOR}${SORT_DIR.ASC}` && (
                    <StyledUpCaratIcon />
                  )}
                  {col.label}
                </StyledMuiTableCell>
              )
            })}
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          {loading &&
            loadingArray.map((i: number) => {
              return (
                <MuiTableRow key={i}>
                  {config.columns.map((col: any) => {
                    const align = col.align || 'inherit'
                    return (
                      <MuiTableCell
                        key={col.key}
                        align={align}
                        className={`${col.key}-cell`}
                      >
                        <Skeleton />
                      </MuiTableCell>
                    )
                  })}
                </MuiTableRow>
              )
            })}
          {!loading &&
            config.rows.map((row: any, index: number) => {
              return (
                <StyledMuiTableRow
                  key={index}
                  $selectable={config.selectableRows || !!config.onRowClick}
                  $active={selectedRowIndex === index}
                  onClick={() => handleRowClick(row, index)}
                >
                  {config.columns.map((col: any) => {
                    const align = col.align || 'inherit'
                    const Component = col.component
                    return (
                      <MuiTableCell
                        key={col.key}
                        align={align}
                        className={`${col.key}-cell`}
                      >
                        {Component && <Component row={row} />}
                        {!Component && <div>{row[col.key]}</div>}
                      </MuiTableCell>
                    )
                  })}
                </StyledMuiTableRow>
              )
            })}
        </MuiTableBody>
      </MuiTable>
      {config.pagination && (
        <PaginationContainer>
          <Pagination
            count={config.pagination.count}
            boundaryCount={config.pagination.boundaryCount || 0}
            page={config.pagination.page || 1}
            siblingCount={config.pagination.siblingCount || 0}
            onChange={handlePaginationChange}
          />
          {config.pagination.totalResults && (
            <ResultsCount>
              {config.pagination.totalResults} results
            </ResultsCount>
          )}
        </PaginationContainer>
      )}
    </StyledMuiTableContainer>
  )
}
