import { Token, TokenList, TokenMap } from '@/types/tokens'

export function tokenListAdapter(tokenList: TokenList): {
  tokens: Token[]
  tokenMap: TokenMap
} {
  const tokens = []
  const tokenMap: TokenMap = {}
  for (let i = 0; i < tokenList.tokens.length; i++) {
    const token = tokenList.tokens[i]
    if (token.chainId === 1) {
      tokens.push(token)
      tokenMap[token.address] = token
    }
  }

  return {
    tokens,
    tokenMap,
  }
}
