import React from 'react'

function VaultBigIcon(props: any) {
  return (
    <svg
      {...props}
      width="167"
      height="152"
      viewBox="0 0 167 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7" filter="url(#filter0_f_3385_1746)">
        <rect
          x="4.68359"
          y="9.44244"
          width="131.863"
          height="18.8076"
          fill="black"
        />
      </g>
      <rect
        x="0.894531"
        width="165.208"
        height="151.567"
        rx="6.68716"
        fill="url(#paint0_linear_3385_1746)"
      />
      <rect
        opacity="0.7"
        x="11.2431"
        y="11.106"
        width="143.755"
        height="128.598"
        rx="3.76153"
        fill="url(#paint1_linear_3385_1746)"
        stroke="#252537"
        strokeWidth="2.50769"
        strokeLinejoin="round"
      />
      <path
        d="M85.2429 38.6465C84.5827 39.7138 83.0303 39.7138 82.3701 38.6465L78.7704 32.8272C78.0743 31.702 78.8837 30.2497 80.2068 30.2497H87.4063C88.7294 30.2497 89.5387 31.702 88.8427 32.8272L85.2429 38.6465Z"
        fill="#29293E"
      />
      <circle cx="83.2955" cy="86.2739" r="39.8131" fill="#36364F" />
      <circle
        cx="83.2954"
        cy="86.2742"
        r="32.9995"
        fill="#484864"
        stroke="#29293E"
        strokeWidth="2.252"
      />
      <circle
        cx="83.2943"
        cy="86.2739"
        r="24.764"
        fill="#484864"
        stroke="#29293E"
        strokeWidth="4.504"
        strokeDasharray="2.25 2.25"
      />
      <circle cx="83.2948" cy="86.274" r="17.3544" fill="#5B5B7A" />
      <path
        d="M93.0172 88.489L92.99 88.5175C92.9773 88.5306 92.9645 88.5436 92.9515 88.5566L85.5059 95.9902C84.2089 97.2851 82.1061 97.2851 80.809 95.9902L78.7529 93.9375C78.6072 93.7925 78.6069 93.5568 78.752 93.4112C78.7947 93.3683 78.8472 93.3366 78.9049 93.3185C81.1087 92.6382 82.8993 91.853 84.2768 90.9627C87.5574 88.8425 90.4708 88.0179 93.0172 88.489ZM91.4562 82.3741C94.2051 83.2403 93.8843 85.2626 93.9208 86.0598C90.9103 84.4812 87.2885 85.0598 83.0556 87.7955C79.9005 89.8346 77.2577 90.6753 75.1271 90.3176C72.9964 89.9599 72.5172 87.3578 72.4219 86.6663C74.7631 87.8501 77.9006 87.1707 81.8343 84.6283C85.5 82.2593 88.7073 81.5078 91.4562 82.3741ZM85.5059 76.4337L87.4379 78.3622C87.5834 78.5074 87.5836 78.7432 87.4384 78.8887C87.3866 78.9406 87.3207 78.9761 87.2488 78.9906C84.452 79.554 82.2153 80.3775 80.5386 81.4611C77.6654 83.318 75.2171 84.1811 73.1934 84.0504L73.1936 84.0499L80.809 76.4337C82.1061 75.1387 84.2089 75.1387 85.5059 76.4337Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_f_3385_1746"
          x="-8.69073"
          y="-3.93188"
          width="158.612"
          height="45.5563"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6.68716"
            result="effect1_foregroundBlur_3385_1746"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3385_1746"
          x1="83.4986"
          y1="0"
          x2="83.4986"
          y2="151.567"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#41415D" />
          <stop offset="1" stopColor="#35354F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3385_1746"
          x1="83.1204"
          y1="9.85211"
          x2="83.1204"
          y2="140.958"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#515170" />
          <stop offset="1" stopColor="#3D3D58" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { VaultBigIcon }
