import styled from 'styled-components'
import { css } from 'styled-components'
import { useEthUsdMarketRate } from '@/state/fiat/hooks'
import {
  useBtcLrtVaultCalls,
  useEigenLayerPointsQuery,
  useKarakPoints,
  useBlackPearlsBtcLrt,
  useVaultSymbioticPoints,
} from './hooks'
import { Typography } from '@/swell-ui/Typography'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useEveryNthBlock } from '@/hooks/useBlockNumber'
import {
  useBtcLrtAllowances,
  useBtcLrtAuth,
  useBtcLrtBalances,
  useBtcLrtPaused,
  useBtcLrtRates,
  useBtcLrtStaticTokens,
  useBtcLrtSupportedAssets,
  useBtcLrtVaultStats,
  useBtcLrtWithdrawRequest,
} from '@/state/yearnVault/hooks'
import { useEffect } from 'react'
import { ThemeData } from '@/swell-ui/theme/branding'
import { BtcLrtRewards } from './BtcLrtRewards'
import { YearnVaultWidget } from '@/components/StakingWidget/YearnVaultWidget'
import { BtcLrtDetails } from './BtcLrtDetails'

const READ_MORE_HREF = 'https://www.swellnetwork.io/post/swbtc'
const VAULT_STRATEGY_ADDRESS_MAINNET =
  '0x8041bA598f0E656EBe80c67289efb42C09E86aE3'
const BLACK_PEARLS_REFRESH_INTERVAL = 120000 // 2 minutes

export function BtcLrtVault() {
  const { chainId } = useSwellWeb3()

  const { depositAsset, vaultToken } = useBtcLrtStaticTokens()

  const assetsQuery = useBtcLrtSupportedAssets()
  const allowancesQuery = useBtcLrtAllowances()
  const balancesQuery = useBtcLrtBalances()
  const ratesQuery = useBtcLrtRates()
  const withdrawRequestQuery = useBtcLrtWithdrawRequest()
  const vaultStatsQuery = useBtcLrtVaultStats()
  const authQuery = useBtcLrtAuth()
  const pausedQuery = useBtcLrtPaused()

  useEveryNthBlock({
    blocksPerFetch: 1,
    fetch: () => {
      allowancesQuery.mutate()
      balancesQuery.mutate()
      ratesQuery.mutate()
      withdrawRequestQuery.mutate()
    },
    skip: false,
  })

  useEveryNthBlock({
    blocksPerFetch: 4,
    fetch: () => {
      vaultStatsQuery.mutate()
    },
    skip: false,
  })

  const blackPearlsQuery = useBlackPearlsBtcLrt({
    refreshInterval: BLACK_PEARLS_REFRESH_INTERVAL,
  })
  const ethUsdMarketRateQuery = useEthUsdMarketRate() // liveness managed by app
  const vaultSymbioticPointsQuery = useVaultSymbioticPoints() // not live
  const eigenLayerPointsQuery = useEigenLayerPointsQuery() // not live
  const karakPointsQuery = useKarakPoints() // not live

  const {
    approveAssetForDeposit,
    approveVaultTokenForWithdraw,
    cancelWithdraw,
    deposit,
    requestWithdraw,
    completeWithdraw,
  } = useBtcLrtVaultCalls()

  useEffect(() => {
    if (
      approveAssetForDeposit.status === approveAssetForDeposit.STATUS.FULFILLED
    ) {
      allowancesQuery.mutate()
    }
  }, [
    allowancesQuery,
    approveAssetForDeposit.STATUS.FULFILLED,
    approveAssetForDeposit.status,
  ])
  useEffect(() => {
    if (
      approveVaultTokenForWithdraw.status ===
      approveVaultTokenForWithdraw.STATUS.FULFILLED
    ) {
      allowancesQuery.mutate()
    }
  }, [
    allowancesQuery,
    approveVaultTokenForWithdraw.STATUS.FULFILLED,
    approveVaultTokenForWithdraw.status,
  ])
  useEffect(() => {
    if (deposit.status === deposit.STATUS.FULFILLED) {
      balancesQuery.mutate()
    }
  }, [balancesQuery, deposit.STATUS.FULFILLED, deposit.status])
  useEffect(() => {
    if (requestWithdraw.status === requestWithdraw.STATUS.FULFILLED) {
      withdrawRequestQuery.mutate()
      balancesQuery.mutate()
    }
  }, [balancesQuery, requestWithdraw, withdrawRequestQuery])
  useEffect(() => {
    if (cancelWithdraw.status === cancelWithdraw.STATUS.FULFILLED) {
      withdrawRequestQuery.mutate()
      balancesQuery.mutate()
    }
  }, [balancesQuery, cancelWithdraw, withdrawRequestQuery])
  useEffect(() => {
    if (completeWithdraw.status === completeWithdraw.STATUS.FULFILLED) {
      balancesQuery.mutate()
    }
  }, [balancesQuery, completeWithdraw, completeWithdraw.status])

  const assets = assetsQuery.data
  const allowances = allowancesQuery.data
  const balances = balancesQuery.data
  const rates = ratesQuery.data
  const withdrawRequest = withdrawRequestQuery.data
  const vaultStats = vaultStatsQuery.data
  const auth = authQuery.data
  const paused = pausedQuery.data
  const ethUsdMarketRate = ethUsdMarketRateQuery.data?.rate
  const blackPearls = blackPearlsQuery?.data
  const vaultSymbioticPoints = vaultSymbioticPointsQuery?.data
  const eigenLayerPoints = eigenLayerPointsQuery?.data
  const karakPoints = karakPointsQuery?.data

  const vaultTokenBalance = balances?.vaultToken
  const depositAssetEthRate = rates?.depositAssetEthRate
  const pricePerShare = rates?.pricePerShare

  return (
    <Layout className="v-page-layout">
      <div className="v-title">
        <Title />
      </div>
      <div className="v-description">
        <Typography
          variant="body"
          xs="medium"
          md="medium"
          size="medium"
          letterSpacing="small"
        >
          An automated vault that restakes WBTC into restaking protocols like
          Symbiotic (Karak and Eigenlayer coming soon) to generate yield and
          secure BTCFi.{' '}
          <a href={READ_MORE_HREF} target="_blank" rel="noopener noreferrer">
            Read more here
          </a>
          .
        </Typography>
      </div>
      <div className="v-rewards">
        <HeadingTypography>Earn yield and rewards</HeadingTypography>
        <BtcLrtRewards />
      </div>
      <div className="v-details">
        <HeadingTypography>Vault details</HeadingTypography>
        <BtcLrtDetails
          vaultToken={vaultToken}
          depositAsset={depositAsset}
          vaultStrategyAddress={VAULT_STRATEGY_ADDRESS_MAINNET} // TODO
          depositAssetEthRate={depositAssetEthRate}
          ethUsdMarketRate={ethUsdMarketRate}
          vaultStats={vaultStats}
          karakPoints={karakPoints}
          pricePerShare={pricePerShare}
          blackPearls={blackPearls}
          eigenLayerPoints={eigenLayerPoints}
          vaultSymbioticPoints={vaultSymbioticPoints}
          vaultTokenBalance={vaultTokenBalance}
        />
      </div>
      <div className="v-widget">
        <YearnVaultWidget
          key={chainId} // force re-render on chain change
          allowances={allowances}
          auth={auth}
          balances={balances}
          paused={paused}
          rates={rates}
          vaultStats={vaultStats}
          approveAssetForDeposit={approveAssetForDeposit}
          approveVaultTokenForWithdraw={approveVaultTokenForWithdraw}
          cancelWithdraw={cancelWithdraw}
          deposit={deposit}
          assets={assets}
          requestWithdraw={requestWithdraw}
          defaultDepositAsset={depositAsset}
          defaultWithdrawAsset={depositAsset}
          vaultToken={vaultToken}
          withdrawRequest={withdrawRequest}
          completeWithdraw={completeWithdraw}
        />
      </div>
    </Layout>
  )
}

const TitleT = styled(Typography)`
  color: ${ThemeData.Symbiotic.symbioticTitleGrey};
  .btc {
    color: ${ThemeData.Symbiotic.symbioticBtcOrange};
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    white-space: nowrap;
    br {
      display: none;
    }
  }
`
function Title() {
  return (
    <TitleT
      variant="headline"
      xs="h3"
      md="h2"
      size="h2"
      fstyle="bold"
      letterSpacing="small"
    >
      Swell <span className="btc">BTC</span> LRT
    </TitleT>
  )
}

const HeadingTypography = styled.span`
  color: var(--Bitcoin-Orange, #f7931a);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 1.05px;
  text-transform: uppercase;
`

const mobileLayout = css`
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  align-items: center;
  justify-items: center;
  grid-template-areas:
    'title'
    'description'
    'rewards'
    'widget'
    'details';
  gap: 24px;

  .v-title,
  .v-description {
    text-align: left;
  }

  .v-title,
  .v-description {
    justify-self: start;
  }

  .v-widget {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 24px;
  }
`
const tabletLayout = css`
  max-width: 985px;
  margin: 0 auto;

  padding: 0 12px;

  grid-template-columns: 1fr auto;
  grid-auto-rows: auto;
  grid-template-areas:
    'title'
    'description'
    'rewards'
    'widget'
    'details';
  gap: unset;
  row-gap: 24px;

  .v-title,
  .v-description,
  .v-rewards {
    justify-self: start;
  }

  .v-description {
    max-width: unset;
    text-align: left;
    margin-bottom: 12px;
  }

  .v-widget {
    margin-left: 35px;
  }
`
const desktopLayout = css`
  max-width: unset;
  margin: unset;

  grid-template-columns: minmax(auto, 858px) auto;
  grid-auto-rows: auto;
  grid-template-areas:
    'title widget'
    'description widget'
    'rewards widget'
    'details widget';
  gap: unset;

  .v-title {
    margin-bottom: 16px;
  }

  .v-description {
    margin-bottom: 35px;
  }

  .v-rewards {
    margin-bottom: 24px;
  }

  .v-widget {
    margin-left: 35px;
  }
`

const Layout = styled.div`
  width: 100%;

  display: grid;

  .v-title {
    grid-area: title;
  }
  .v-description {
    grid-area: description;
    color: ${ThemeData.Symbiotic.symbioticLightGrey};
  }
  .v-rewards {
    grid-area: rewards;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;
    justify-self: stretch;
  }
  .v-widget {
    grid-area: widget;
    align-self: start;
  }
  .v-details {
    grid-area: details;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;
    justify-self: stretch;
  }

  ${mobileLayout}
  @media (min-width: 900px) {
    ${tabletLayout}
  }
  @media (min-width: 1391px) {
    ${desktopLayout}
  }
`
