import { useEffect, useState } from 'react'
import { BigNumber } from 'ethers'
import { useSwellWeb3 } from '@swell-web3/core'
import { TOKEN_LIST_ETH } from '@/constants/tokens'

export function useAllowance(erc20TokenContract: any, spenderAddress: string) {
  const { account } = useSwellWeb3()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [amount, setAmount] = useState<BigNumber>(BigNumber.from(0))

  const set = (newAmount: BigNumber) => {
    setAmount(newAmount)
  }

  useEffect(() => {
    const getAllowance = async () => {
      if (
        !erc20TokenContract ||
        !account ||
        erc20TokenContract.address === TOKEN_LIST_ETH.address
      ) {
        setAmount(BigNumber.from(0))
        return
      }

      setIsLoading(true)

      try {
        const amount = await erc20TokenContract.allowance(
          account,
          spenderAddress
        )

        setAmount(amount)
        setIsLoading(false)
      } catch (err) {
        console.error(err)
        setIsLoading(false)
      }
    }

    getAllowance()
  }, [account, erc20TokenContract, spenderAddress])

  return {
    isLoading,
    amount,
    set,
  }
}
