import React from 'react'
import styled from 'styled-components/macro'
import { AsyncDiv } from '../AsyncDiv'
import { Box } from '../Box'
import { Grid } from '../Grid'
import { Typography } from '../Typography'

export interface DataBoxGridItem {
  data?: any
  label: string
  loading: boolean
}

interface DataBoxGridProps {
  config: {
    gridSpacing: number
    gridItemWidth: {
      xs?: number
      sm?: number
      md?: number
      lg?: number
    }
    items: DataBoxGridItem[]
  }
}

export type DataBoxGridConfig = DataBoxGridProps['config']

const DataBox = styled(Box)`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: ${theme.dataBoxGrid.dataBox.bgColor};
    color: ${theme.dataBoxGrid.dataBox.color};
    font-weight: 600;
    padding: 24px 21.5px;
    align-items: center;
    height: 100%;
  `}
`

const ItemData = styled.div`
  color: ${({ theme }) => theme.dataBoxGrid.dataBox.dataColor};
  font-weight: 500;
  margin-top: 2px;
`

function DataBoxGrid({ config }: DataBoxGridProps) {
  const { gridSpacing, gridItemWidth, items } = config

  return (
    <Grid container spacing={gridSpacing} alignItems="stretch">
      {items.map((item: DataBoxGridItem, index: number) => {
        return (
          <Grid item {...gridItemWidth} key={index}>
            <DataBox color="accent">
              <div>
                <AsyncDiv loading={item.loading}>
                  {() => (
                    <Typography variant="body" size="medium">
                      {item.data}
                    </Typography>
                  )}
                </AsyncDiv>
                <ItemData>
                  <Typography variant="body" size="small">
                    {item.label}
                  </Typography>
                </ItemData>
              </div>
            </DataBox>
          </Grid>
        )
      })}
    </Grid>
  )
}

export { DataBoxGrid }
export type { DataBoxGridProps }
