import React from 'react'
import styled, { css } from 'styled-components'
import { L2 } from '@/swell-ui/icons/L2Icon'
import swellL2PointsIcon from '@/assets/images/swell-l2-logo-56x56.png'

const SUBTITLE =
  'The L2 for Restaking is coming soon. Deposit swETH, rswETH, and other supported assets in the pre-launch to get airdrops from projects building in this new restaking ecosystem.'

const SecondaryText = styled.span`
  padding-left: 6px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-left: 8px;
  }
`

// from figma
const mobileCss = css`
  h1 {
    margin: 0;
    color: #fff;
    /* Headlines/H5 */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 38.4px */
    letter-spacing: -0.96px;
  }

  img.l2,
  svg {
    width: 32px;
    height: 32px;
    transform: translateY(4px);
  }

  h2 {
    margin: 0;
    color: #e7e7e7;
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.48px;
  }
`

// from figma
const desktopCss = css`
  h1 {
    margin: 0;
    color: #fff;
    text-align: center;
    /* Headlines/H3 */
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 57.6px */
    letter-spacing: -1.44px;
  }

  img.l2,
  svg {
    width: 42px;
    height: 42px;
    transform: translateY(4px);
  }

  h2 {
    margin: 0;
    color: #e7e7e7;
    text-align: center;
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.48px;
  }
`

const MobileShade = styled.div`
  background: linear-gradient(0deg, #070609 0.59%, rgba(7, 6, 9, 0) 99.33%);
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 375px;
`

const Content = styled.div`
  max-width: 100%;
`

const Layout = styled.div`
  position: relative;

  min-height: 118px;
  height: auto;
  width: 100%;

  h1 {
    margin-bottom: 16px !important;
  }

  ${Content} {
    position: absolute;
    top: 47px;
    left: 0px;
    width: 342px;
  }

  ${mobileCss}
  ${({ theme }) => theme.breakpoints.up('md')} {
    ${desktopCss}

    height: auto;
    width: auto;

    ${MobileShade} {
      display: none;
    }
    ${Content} {
      position: unset;
      width: unset;
    }

    h2 {
      max-width: 760px;
    }
  }
`

export function PageTitle({ bigL2 }: { bigL2?: boolean }) {
  return (
    <Layout>
      <MobileShade data-qa="shade" />
      <Content>
        <h1>
          Swell{' '}
          {bigL2 ? (
            <L2 />
          ) : (
            <img
              src={swellL2PointsIcon}
              width="42"
              height="42"
              className="l2"
            />
          )}
        </h1>
        <h2>{SUBTITLE}</h2>
      </Content>
    </Layout>
  )
}
