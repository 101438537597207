import { Interface, Result, defaultAbiCoder } from 'ethers/lib/utils'

// attempts to extract ethereum error data from an error
export function extractErrorData(err: any): string | undefined {
  let error = err
  let errorData: string | undefined

  while (error) {
    errorData = error.data ?? errorData
    error = error.error ?? error.data?.originalError
  }

  return errorData
}

export type ErrorBreakdown = {
  type: 'panic' | 'revert' | 'custom' | 'unknown'
  msg: string
  args: Result | undefined
}

export const parseErrorData = (
  errorData: string
): ErrorBreakdown | undefined => {
  if (!errorData || typeof errorData !== 'string') {
    return undefined
  }
  // keccak256("Error(string)")
  if (errorData.startsWith('0x08c379a0')) {
    const content = `0x${errorData.substring(10)}`
    const reason = defaultAbiCoder.decode(['string'], content)
    return {
      args: [],
      msg: reason[0],
      type: 'revert',
    }
  }

  // keccak256("Panic(uint256)")
  if (errorData.startsWith('0x4e487b71')) {
    const content = `0x${errorData.substring(10)}`
    const result = defaultAbiCoder.decode(['uint256'], content)
    return {
      args: result,
      msg: 'panic',
      type: 'panic',
    }
  }

  try {
    const errDescription = iErrors.parseError(errorData)
    return {
      type: 'custom',
      msg: errDescription.name,
      args: errDescription.args,
    }
  } catch (e) {
    if (/0x[0-9a-fA-F]{0,}$/.test(errorData)) {
      return {
        type: 'unknown',
        msg: errorData,
        args: [],
      }
    }

    return undefined
  }
}

const iErrors = new Interface([
  'error RepriceTimestampDidNotIncrease()',
  'error TOKEN_NOT_ALLOWED(address)',
  'error AMOUNT_NULL()',
  'error INSUFFICIENT_BALANCE()',
  'error ADDRESS_NULL()',
  'error CLAIM_CLOSED()',
  'error AMOUNT_TO_LOCK_GT_AMOUNT_CLAIMED()',
  'error INVALID_PROOF()',
  'error INVALID_STATUS()',
  'error NOTHING_TO_CLAIM()',
  'error SAME_MERKLE_ROOT()',
  'error SAME_STAKING_CONTRACT()',
  'error STAKING_TOKEN_MISMATCH()',
  'error COOLDOWN_DURATION_EXCEEDS_MAX()',
  'error OperatorNotVerified()',
  'error InvalidETHWithdrawCaller()',
  'error InvalidDepositDataRoot()',
  'error InsufficientETHBalance()',
  'error OnlyRswEXITCanWithdrawETH()',
  'error NoRateProviderSet()',
  'error CannotDepositZero()',
  'error InvalidStakerId()',
  'error BatchSizeCannotBeZero()',
  'error ArrayLengthMismatch()',
  'error CannotSendToZeroAddress()',
  'error AddressAlreadySet()',
  'error NoZeroAddresses()',
  'error StrategyTokenMismatch()',
  'error NoPubKeysProvided()',
  'error EigenPodNotCreated()',
  'error NoOperatorFound(address)',
  'error OperatorAlreadyExists(address)',
  'error OperatorAlreadyEnabled()',
  'error OperatorAlreadyDisabled()',
  'error InvalidArrayLengthOfZero()',
  'error AmountOfValidatorDetailsExceedsLimit()',
  'error NextOperatorPubKeyMismatch(bytes, bytes)',
  'error OperatorOutOfPendingKeys()',
  'error NoPubKeyFound(bytes)',
  'error CannotUseDisabledOperator()',
  'error CannotAddDuplicatePubKey(bytes)',
  'error MissingPendingValidatorDetails(bytes)',
  'error MissingActiveValidatorDetails(bytes)',
  'error InvalidPubKeySetupCaller()',
  'error InvalidPubKeyLength()',
  'error InvalidSignatureLength()',
  'error InvalidCallerToDeleteActiveValidators()',
  'error CannotSetOperatorControllingAddressToSameAddress()',
  'error CannotUpdateOperatorControllingAddressToAlreadyAssignedAddress()',
  'error CannotBurnZeroRswETH()',
  'error WithdrawRequestTooLarge(uint256 amount, uint256 limit)',
  'error WithdrawRequestTooSmall(uint256 amount, uint256 minimum)',
  'error WithdrawalRequestDoesNotExist()',
  'error WithdrawalRequestNotProcessed()',
  'error CannotProcessWithdrawalsForNonExistentToken()',
  'error LastTokenIdToProcessMustBeGreaterOrEqualThanPrevious()',
  'error WithdrawalsPaused()',
  'error WithdrawRequestMinimumMustBeLessOrEqualToMaximum()',
  'error WithdrawRequestMaximumMustBeGreaterOrEqualToMinimum()',
  'error WithdrawalRequestFinalizationOnlyAllowedForNFTOwner()',
  'error CannotBurnZeroSwETH()',
  'error NotInWhitelist()',
  'error DelayedWithdraw__WithdrawFeeTooHigh()',
  'error DelayedWithdraw__MaxLossTooLarge()',
  'error DelayedWithdraw__AlreadySetup()',
  'error DelayedWithdraw__WithdrawsNotAllowed()',
  'error DelayedWithdraw__WithdrawNotMatured()',
  'error DelayedWithdraw__NoSharesToWithdraw()',
  'error DelayedWithdraw__MaxLossExceeded()',
  'error DelayedWithdraw__transferNotAllowed()',
  'error DelayedWithdraw__BadAddress()',
  'error DelayedWithdraw__ThirdPartyCompletionNotAllowed()',
  'error DelayedWithdraw__RequestPastCompletionWindow()',
  'error DelayedWithdraw__Paused()',
])
