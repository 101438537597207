import { Token } from '@/types/tokens'

const TOKEN_LIST_KEY = 'swell_customTokenList'
const TOKEN_LIST_ADDRESS_MAP_KEY = 'swell_customTokenList_addresses'

function getCustomTokenList(): Token[] {
  const customTokenListStr = localStorage.getItem(TOKEN_LIST_KEY)
  if (customTokenListStr) {
    return JSON.parse(customTokenListStr)
  }

  return []
}

function getCustomTokenAddressMap() {
  const customTokenAddressesStr = localStorage.getItem(
    TOKEN_LIST_ADDRESS_MAP_KEY
  )
  if (customTokenAddressesStr) {
    return JSON.parse(customTokenAddressesStr)
  }

  return {}
}

function addTokenToCustomTokenList(token: Token) {
  const customTokenList = getCustomTokenList()
  const customTokenAddressMap = getCustomTokenAddressMap()

  customTokenList.push(token)
  localStorage.setItem(TOKEN_LIST_KEY, JSON.stringify(customTokenList))
  customTokenAddressMap[token.address] = true
  localStorage.setItem(
    TOKEN_LIST_ADDRESS_MAP_KEY,
    JSON.stringify(customTokenAddressMap)
  )
}

export const customTokenList = {
  get: getCustomTokenList,
  getAddressMap: getCustomTokenAddressMap,
  push: addTokenToCustomTokenList,
}
