import { depositInfoCryptoFormatAdapter } from '@/types/deposits'
import { verifySignature } from '@/util/verifySignature'
import { IDepositInfoVerifier } from '../../IDepositInfoVerifier'

export const signatureVerifier: IDepositInfoVerifier = {
  verify: async (deposit, chainDepositConfig) => {
    const { genesisForkVersion } = chainDepositConfig

    return !verifySignature(
      // verifySignature requires the deposit in a crypto-friendly format
      depositInfoCryptoFormatAdapter(deposit),
      genesisForkVersion
    )
  },
}
