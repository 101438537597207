import { useCallback, useMemo } from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import useDebounce from './useDebounce'
import { HOST_ENV, HostEnv } from '@/configuration/hostEnv'
import { SupportedChainId } from '@/constants/chains'

const useSwitchChain = () => {
  return useCallback(async (connector: any, targetChainId: number) => {
    await connector.switchChain(targetChainId)
  }, [])
}

function useSwitchToDeploymentChain() {
  const { chainId: targetChainId } = useDeploymentSetConfig()
  const { connector } = useSwellWeb3()

  const switchChain = useSwitchChain()

  return useCallback(async () => {
    if (!connector) return
    try {
      await switchChain(connector, targetChainId)
    } catch (error: any) {
      console.error('Failed to switch networks', error)
    }
  }, [connector, switchChain, targetChainId])
}

function useSwitchToTestnetChain() {
  const { testnets } = useDeploymentSetConfig()
  const { connector } = useSwellWeb3()
  const testnetChains = useMemo(
    () => new Set(testnets.map(({ chainId }) => chainId)),
    [testnets]
  )

  const switchChain = useSwitchChain()

  return useCallback(
    async (chainId: SupportedChainId) => {
      if (!connector) return
      if (!testnetChains.has(chainId)) {
        throw new Error('Invalid testnet chainId')
      }
      try {
        await switchChain(connector, chainId)
      } catch (error: any) {
        console.error('Failed to switch networks', error)
      }
    },
    [connector, switchChain, testnetChains]
  )
}

const allowTestnet = HOST_ENV !== HostEnv.PRODUCTION
/**
 * Compares the current chainId to the deployment chainId
 * Exposes a callback to switch chains to the deployment chainId (switchToDeploymentChain)
 */
export const useChainDetection = () => {
  const { chainId } = useSwellWeb3()
  const { chainId: deploymentChainId, testnets } = useDeploymentSetConfig()

  const testnetChainIds = testnets.map(
    ({ chainId }) => chainId as SupportedChainId
  )
  const testnetChainIdSet = new Set(testnetChainIds)

  const debouncedChainId = useDebounce(chainId, 400)
  const switchToDeploymentChain = useSwitchToDeploymentChain()
  let switchToTestnetChain = useSwitchToTestnetChain()

  const isDeploymentChain = chainId === deploymentChainId
  const isTestnetChain = testnetChainIdSet.has(chainId)

  const isDeploymentChainDebounced = debouncedChainId === deploymentChainId
  const isTestnetChainDebounced = testnetChainIdSet.has(debouncedChainId)

  let isWrongChainId: boolean
  let isWrongChainIdDebounced: boolean
  if (allowTestnet) {
    isWrongChainId = !isDeploymentChain && !isTestnetChain
    isWrongChainIdDebounced =
      !isDeploymentChainDebounced && !isTestnetChainDebounced
  } else {
    isWrongChainId = !isDeploymentChain
    isWrongChainIdDebounced = !isDeploymentChainDebounced
    switchToTestnetChain = async () => {
      throw new Error('testnet not allowed')
    }
  }

  return {
    switchToDeploymentChain,
    switchToTestnetChain,
    chainId,
    deploymentChainId,
    testnetChainIds,
    isWrongChainId,
    /**
     * chainId might change rapidly in some circumstances, depending on the wallet provider
     *
     * A debounced chainId is provided to use with side effects which depend on the chainId
     *  being wrong.
     */
    isWrongChainIdDebounced,
    allowTestnet,
    isTestnetChain,
  }
}

export default useChainDetection
