import { useDisplayNativeCurrency } from '@/hooks/useNativeCurrencyDisplay'
import { useEthBalance } from '@/state/user/hooks'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { Skeleton } from '@/swell-ui/Skeleton'
import React from 'react'

interface FormattedEthBalanceProps {
  helperText?: string
  numberOnly?: boolean
}

const TextContainer = ({ children }: any) => <span>{children}</span>

function FormattedEthBalance({
  helperText,
  numberOnly,
}: FormattedEthBalanceProps) {
  const ethBalance = useEthBalance()

  const displayNativeCurrency = useDisplayNativeCurrency()
  return (
    <AsyncDiv
      loading={ethBalance.isLoading || !ethBalance.data}
      Container={TextContainer}
      loader={() => <Skeleton width={100} />}
    >
      {() => {
        const balance = displayNativeCurrency(ethBalance.data!.balance, {
          precision: 4,
          localize: true,
          numberOnly,
        })

        return `${balance}${helperText ? ` ${helperText}` : ''}`
      }}
    </AsyncDiv>
  )
}

export { FormattedEthBalance }
