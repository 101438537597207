import { Token } from '@uniswap/sdk-core'

export class FungibleToken extends Token {
  public logoURL?: string

  constructor({
    address,
    chainId,
    decimals,
    bypassChecksum,
    name,
    symbol,
    logoURL,
  }: {
    chainId: number
    address: string
    decimals: number
    symbol?: string
    name?: string
    bypassChecksum?: boolean
    logoURL?: string
  }) {
    super(chainId, address, decimals, symbol, name, bypassChecksum)

    this.logoURL = logoURL
  }
}
