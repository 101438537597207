import React from 'react'
import styled from 'styled-components/macro'
import MuiInputAdornment, {
  InputAdornmentProps,
} from '@mui/material/InputAdornment'

const StyledMuiInputAdornment = styled(MuiInputAdornment)`
  margin-left: 5px;
`

function InputAdornment(props: InputAdornmentProps) {
  return <StyledMuiInputAdornment {...props} />
}

export { InputAdornment }
