import React from 'react'
import useTransactionFee from '@/hooks/useTransactionFee'
import { useRestakingApr } from '@/state/restakingStats/hooks'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@/swell-ui/KeyValueList'
import {
  useDisplayRswEth,
  useDisplayRswEthInNativeCurrency,
} from '@/hooks/useRswEthDisplay'
import { useParseRswEth } from '@/hooks/useRswEthFormatting'
import { useDisplayNativeCurrencyPriceFiat } from '@/hooks/useNativeCurrencyDisplay'
import {
  getStakingUpperGasEstimate,
  getRswETHCreateWithdrawRequestGasEstimate,
} from '@/constants/gasEstimates'
import { bigNumberRateToFloat } from '@/util/big'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { formatWithConfig } from '@/util/number'

const withdrawalDelayInfoHref =
  'https://docs.swellnetwork.io/swell-staking/rsweth-liquid-restaking/rsweth-v1.0-system-design/withdrawals'

export function RswethExchangeInfo({
  lstEthExchangeRate,
  lstSymbol,
  unstake,
  primaryRestakingRate,
  withdrawalDelayDurationDays,
}: {
  lstEthExchangeRate: BigNumber | undefined
  lstSymbol: string | null
  unstake: boolean
  primaryRestakingRate: BigNumber | undefined
  withdrawalDelayDurationDays: number
}) {
  const restakingApr = useRestakingApr()
  const restakingTransactionFee = useTransactionFee({
    gasAmount: getStakingUpperGasEstimate().toNumber(),
  })
  const createWithdrawRequestTransactionFee = useTransactionFee({
    gasAmount: getRswETHCreateWithdrawRequestGasEstimate().toNumber(),
  })

  const parseRswEth = useParseRswEth()
  const displayRswEth = useDisplayRswEth()
  const displayRswEthInNativeCurrency = useDisplayRswEthInNativeCurrency()
  const displayNativeCurrencyPriceFiat = useDisplayNativeCurrencyPriceFiat()

  const rswethAprConfig = {
    label: 'rswETH APR',
    value: (
      <AsyncDiv loading={!restakingApr.data}>
        {() => `${restakingApr.data!.value}%`}
      </AsyncDiv>
    ),
    infoText: (
      <span>
        <a
          href="https://dune.com/swell-network/swell-network"
          target="_blank"
          rel="noreferrer"
        >
          Total pool 7 day moving average
        </a>
      </span>
    ),
    hasInfo: true,
  }

  let equivalentAssetDisplay: string | undefined = undefined
  if (lstSymbol) {
    if (lstEthExchangeRate && primaryRestakingRate) {
      const lstEthExchangeRateNum = Number(formatEther(lstEthExchangeRate))
      const valueFormatted = formatWithConfig(
        Number(formatEther(primaryRestakingRate)) * lstEthExchangeRateNum,
        { precision: 4, localize: true }
      )
      equivalentAssetDisplay = `${valueFormatted} ${lstSymbol}`
    }
  } else {
    if (primaryRestakingRate) {
      equivalentAssetDisplay = displayRswEthInNativeCurrency(
        parseRswEth('1'),
        bigNumberRateToFloat(primaryRestakingRate),
        { precision: 4, localize: true }
      )
    }
  }

  const exchangeRateConfig = {
    label: 'Exchange rate',
    value: (
      <AsyncDiv loading={!equivalentAssetDisplay}>
        {() => {
          const oneRswEth = parseRswEth('1')

          const oneRswEthDisplay = displayRswEth(oneRswEth, {
            precision: 4,
            localize: true,
          })

          return `${oneRswEthDisplay} = ${equivalentAssetDisplay!}`
        }}
      </AsyncDiv>
    ),
    hasInfo: true,
    infoText: 'Represents the accruing value of rswETH vs ETH',
  }

  let feeWei = restakingTransactionFee.data?.feeWei
  let ethUsdRate = restakingTransactionFee.data?.ethUsdRate
  if (unstake) {
    feeWei = createWithdrawRequestTransactionFee.data?.feeWei
    ethUsdRate = createWithdrawRequestTransactionFee.data?.ethUsdRate
  }

  const exchangeInfoConfig = {
    label: 'Transaction fee',
    value: (
      <AsyncDiv loading={!feeWei || !ethUsdRate}>
        {() => {
          return `${displayNativeCurrencyPriceFiat(feeWei!, ethUsdRate!)} USD`
        }}
      </AsyncDiv>
    ),
  }

  const processingTimeConfig = {
    label: 'Processing time',
    value: <div>~{withdrawalDelayDurationDays} days</div>,
    hasInfo: true,
    infoText: (
      <a href={withdrawalDelayInfoHref} target="_blank" rel="noreferrer">
        Visit docs for more info
      </a>
    ),
  }

  const list = [rswethAprConfig, exchangeRateConfig, exchangeInfoConfig]

  if (unstake) {
    list.push(processingTimeConfig)
  }

  const exchangeInfoListConfig = { list }

  return <KeyValueList config={exchangeInfoListConfig} />
}
