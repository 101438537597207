import { composeVerifiers } from '@/services/DepositCollectionValidator/util/composeVerifiers'
import { initBLS } from '@chainsafe/bls'
import { depositRootsVerifier } from '../verifier/depositRootsVerifier'
import { signatureVerifier } from '../verifier/signatureVerifier'

export enum BLSVerifierId {
  VERIFY_DEPOSIT_ROOTS = 'verify-deposit-roots',
  VERIFY_SIGNATURE = 'verify-signature',
}

/**
 * Creates a composite verifier which performs (as part of a single task):
 * - deposit roots check
 * - signature check
 */
export const validateBLS = composeVerifiers({
  beforeAll: async () => {
    await initBLS()
  },
  verifiers: [
    {
      id: BLSVerifierId.VERIFY_DEPOSIT_ROOTS,
      verifier: depositRootsVerifier,
    },
    {
      id: BLSVerifierId.VERIFY_SIGNATURE,
      verifier: signatureVerifier,
    },
  ],
})
