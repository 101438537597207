import { ITaskRunner } from './ITaskRunner'

// Makes a task runner which executes tasks in order, serially
export const TaskRunnerSerial = {
  create: (): ITaskRunner => {
    return {
      run: async (tasks, onProgress) => {
        const numTotal = tasks.length
        let numCompleted = 0

        onProgress({ numCompleted, numTotal })

        await tasks.reduce(async (accumP, task) => {
          await accumP
          try {
            await task()
          } finally {
            numCompleted++
            onProgress({ numCompleted, numTotal })
            // eslint-disable-next-line no-unsafe-finally
            return Promise.resolve()
          }
        }, Promise.resolve())
      },
    }
  },
}
