import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { SwellDaoContext } from './context'
import { useSwellWeb3 } from '@/swell-web3/core'
import { IDaoApiRead, IDaoApiWrite } from './types'
import { BigNumber } from 'ethers'

export function useDaoApiImpl(): SwellDaoContext {
  return {
    read: useDaoApiRead(),
    write: useDaoApiWrite(),
  }
}

function useDaoApiRead(): IDaoApiRead {
  const { account: maybeAccount } = useSwellWeb3()

  const { v3BackendLstUrl } = useDeploymentSetConfig()

  const walletClient =
    useV3BackendClient(v3BackendLstUrl).v3BackendClient.wallet

  const statsClient = useV3BackendClient(v3BackendLstUrl).v3BackendClient.stats

  const account = maybeAccount!
  return {
    balances: async () => {
      // TODO: update when SWELL is deployed.
      return { swellBalance: BigNumber.from(0) }
    },
    wavedrop: async () => {
      const { blackPearls, multiplier } = await walletClient.wavedropUser({
        walletAddress: account,
      })
      return { blackPearls, multiplier }
    },
    waveDropStats: async () => {
      const { currentWaveNumber, nextWavedropEndUnix } =
        await statsClient.wavedropStats({})
      return { nextWavedropEndUnix, currentWaveNumber }
    },
  }
}

function useDaoApiWrite(): IDaoApiWrite {
  return {
    claim: async () => {
      throw new Error('Not implemented')
    },
  }
}
