import useTransactionFee from '@/hooks/useTransactionFee'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@/swell-ui/KeyValueList'
import { useDisplayNativeCurrencyPriceFiat } from '@/hooks/useNativeCurrencyDisplay'
import { getYearnCancelWithdrawGasEstimate } from '@/constants/gasEstimates'
import { displayPercentFromBasisPoints } from '@/util/displayNumbers'
import { YearnWithdrawAsset } from '@/state/yearnVault/types'
import { formatYearnWithdrawDelay } from '../yearn/yearnFormatting'

export function YearnCancelWithdrawInfo({
  withdrawAsset,
}: {
  withdrawAsset: YearnWithdrawAsset | undefined
}) {
  const cancelWithdrawalTransactionFee = useTransactionFee({
    gasAmount: getYearnCancelWithdrawGasEstimate().toNumber(),
  })
  const displayNativeCurrencyPriceFiat = useDisplayNativeCurrencyPriceFiat()

  let withdrawFeeStr = ''
  if (withdrawAsset?.withdrawFeeBasisPoints !== undefined) {
    withdrawFeeStr = displayPercentFromBasisPoints(
      withdrawAsset.withdrawFeeBasisPoints
    )
  }

  let processingTimeStr = ''
  if (withdrawAsset !== undefined) {
    processingTimeStr = formatYearnWithdrawDelay(
      withdrawAsset.withdrawDelaySeconds
    )
    processingTimeStr = `~${processingTimeStr}`
  }

  const withdrawFeeConfig = {
    label: 'Withdrawal fee',
    value: (
      <AsyncDiv loading={!withdrawFeeStr}>{() => withdrawFeeStr}</AsyncDiv>
    ),
    hasInfo: false,
    // infoText: 'todo',
  }

  const processingTimeConfig = {
    label: 'Processing time',
    value: (
      <AsyncDiv loading={!processingTimeStr}>
        {() => processingTimeStr}
      </AsyncDiv>
    ),
  }

  const gasFeeConfig = {
    label: 'Transaction fee',
    value: (
      <AsyncDiv loading={!cancelWithdrawalTransactionFee.data}>
        {() => {
          const { ethUsdRate, feeWei } = cancelWithdrawalTransactionFee.data!
          return `${displayNativeCurrencyPriceFiat(feeWei, ethUsdRate)} USD`
        }}
      </AsyncDiv>
    ),
  }

  const exchangeInfoListConfig = {
    list: [withdrawFeeConfig, processingTimeConfig, gasFeeConfig],
  }

  return <KeyValueList config={exchangeInfoListConfig} />
}
