import React, { useMemo, useState } from 'react'
import { matchSorter } from 'match-sorter'
import styled from 'styled-components/macro'
import { ArrowLeftIcon } from '@swell-ui/icons/ArrowLeftIcon'
import { Button } from '@swell-ui/Button'
import { CloseIcon } from '@swell-ui/icons/CloseIcon'
import { Dialog } from '@swell-ui/Dialog'
import { Divider } from '@swell-ui/Divider'
import { InputAdornment } from '@swell-ui/inputs/InputAdornment'
import { FlexRow } from '@/swell-ui/FlexRow'
import { SearchIcon } from '@swell-ui/icons/SearchIcon'
import { TextInput } from '@swell-ui/inputs/TextInput'
import { Typography } from '@swell-ui/Typography'
import { WarningCircleIcon } from '@swell-ui/icons/WarningCircleIcon'
import { TokenLogo } from '@/components/TokenLogo'
import { customTokenList } from '@/state/customTokenList'
import { isAddress } from '@/util/hexStrings'
import { isProtocolTokenAddress } from '@/util/tokens'
import { Token } from '@/types/tokens'
import { BoostedTokenSection } from './BoostedTokenSection'
import { TokenListSection } from './TokenListSection'

const SelectTokenDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 424px;
    padding: 32px 22px;
  }

  .MuiDialogTitle-root {
    color: white;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  path {
    stroke-width: 0.666667px;
    color: white;
  }
`

const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const SectionWrapper = styled.div`
  margin-top: 24px;
`

const StyledWarningCirlceIcon = styled(WarningCircleIcon)`
  margin-bottom: 32px;
`

const CustomTokenInfoWrapper = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 18px 10px;
  margin-bottom: 24px;
  border-radius: 12px;
  text-align: center;
`

const StyledTokenLogo = styled(TokenLogo)`
  height: 62px;
  margin-bottom: 22px;
`

const CenterTypography = styled(Typography)`
  text-align: center;
`

const CustomTokenSymbolTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.white['150']};
`

const SourceTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
`

const SourceWarningIcon = styled(WarningCircleIcon)`
  height: 21px;
  width: 21px;

  path {
    fill: ${({ theme }) => theme.colors.lightBlue['50']};
  }
`

const NoResultsWrapper = styled(FlexRow)`
  height: 305px;
`

const VIEWS = {
  SELECT_TOKEN: 'SELECT_TOKEN',
  ADD_CUSTOM_TOKEN: 'ADD_CUSTOM_TOKEN',
}

const SEARCH_PLACEHOLDER = 'Search tokens or paste address'

function buildCurrentTokens(tokens: Token[], filterValue: string): Token[] {
  if (!filterValue) {
    return tokens
  }

  const customTokens = customTokenList.get()

  // first try to filter by address if filterValue is an address
  if (isAddress(filterValue)) {
    if (isProtocolTokenAddress(filterValue)) {
      return []
    }

    const filteredTokens = matchSorter(tokens, filterValue, {
      keys: ['address'],
    })
    const filteredCustomTokens = matchSorter(customTokens, filterValue, {
      keys: ['address'],
    })
    return [...filteredCustomTokens, ...filteredTokens]
  }

  // otherwise filter by symbol / name
  const filteredTokens = matchSorter(tokens, filterValue, {
    keys: ['symbol', 'name'],
    // if we remove this, get an even more in depth fuzzy search
    threshold: matchSorter.rankings.CONTAINS,
  })
  const filteredCustomTokens = matchSorter(customTokens, filterValue, {
    keys: ['symbol', 'name'],
    // if we remove this, get an even more in depth fuzzy search
    threshold: matchSorter.rankings.CONTAINS,
  })

  return [...filteredCustomTokens, ...filteredTokens]
}

function buildCurrentImportableTokens(
  tokens: Token[],
  filterValue: string
): Token[] {
  if (!filterValue || !isAddress(filterValue)) {
    return []
  }

  if (isProtocolTokenAddress(filterValue)) {
    return []
  }

  const customTokenAddressMap = customTokenList.getAddressMap()
  if (customTokenAddressMap[filterValue]) {
    return []
  }

  // only filter importable tokens by address, add an importable field to the token(s) so can distinguish
  const filteredImportableTokens = matchSorter(tokens, filterValue, {
    keys: ['address'],
  })
  return filteredImportableTokens.map((token: Token) => {
    return { ...token, importable: true }
  })
}

function TokenSelectModal({
  open,
  onClose,
  onTokenSelect,
  tokens,
  importableTokens,
}: {
  open: boolean
  onClose: () => void
  onTokenSelect: (token: Token) => void
  tokens: Token[]
  importableTokens: Token[]
}) {
  const [view, setView] = useState<string>(VIEWS.SELECT_TOKEN)
  const [filterValue, setFilterValue] = useState<string>('')
  const [customToken, setCustomToken] = useState<Token | undefined>(undefined)

  const currentTokens = useMemo(() => {
    return buildCurrentTokens(tokens, filterValue)
  }, [filterValue, tokens])

  const currentImportableTokens = useMemo(() => {
    return buildCurrentImportableTokens(importableTokens, filterValue)
  }, [filterValue, importableTokens])

  const noTokenResults = () => {
    return currentTokens.length === 0 && currentImportableTokens.length === 0
  }

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value)
  }

  const handleModalClose = () => {
    onClose()
    setFilterValue('')
  }

  const handleModalBack = () => {
    setView(VIEWS.SELECT_TOKEN)
    setCustomToken(undefined)
  }

  const handleTokenSelect = (token: Token) => {
    if (token.importable) {
      return
    }
    onTokenSelect(token)
    handleModalClose()
  }

  const handleImportableTokenSelect = (token: Token) => {
    setView(VIEWS.ADD_CUSTOM_TOKEN)
    setCustomToken(token)
  }

  const handleImportClick = () => {
    if (!customToken) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { importable, ...token } = customToken

    customTokenList.push(token)
    handleTokenSelect(token)
    handleModalBack()
  }

  return (
    <SelectTokenDialog open={open} onClose={handleModalClose}>
      {view === VIEWS.SELECT_TOKEN && (
        <>
          <FlexRow justify="space-between">
            <Typography variant="body" size="large" fstyle="bold">
              Select a token
            </Typography>
            <StyledCloseIcon aria-label="Close" onClick={handleModalClose} />
          </FlexRow>
          <SectionWrapper>
            <TextInput
              variant="outlined"
              fullWidth
              value={filterValue}
              onChange={handleFilterChange}
              placeholder={SEARCH_PLACEHOLDER}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </SectionWrapper>
          <SectionWrapper>
            {!noTokenResults() && (
              <TokenListSection
                tokens={currentTokens}
                onTokenSelect={handleTokenSelect}
                importableTokens={currentImportableTokens}
                onImportableTokenSelect={handleImportableTokenSelect}
              />
            )}
            {noTokenResults() && (
              <NoResultsWrapper justify="center" align="top">
                No results found.
              </NoResultsWrapper>
            )}
          </SectionWrapper>
        </>
      )}
      {view === VIEWS.ADD_CUSTOM_TOKEN && !!customToken && (
        <>
          <FlexRow justify="space-between" align="center">
            <StyledArrowLeftIcon onClick={handleModalBack} />
            <Typography variant="body" size="large" fstyle="bold">
              Add custom token
            </Typography>
            <StyledCloseIcon aria-label="Close" onClick={handleModalClose} />
          </FlexRow>
          <SectionWrapper>
            <FlexRow justify="center">
              <StyledWarningCirlceIcon />
            </FlexRow>
            <CenterTypography
              variant="body"
              size="medium"
              letterSpacing="small"
            >
              This token doesn’t appear on the active token list(s). Make sure
              this is the token that you wish to swap.
            </CenterTypography>
            <Divider margin="24" />
            <CustomTokenInfoWrapper>
              <StyledTokenLogo token={customToken} />
              <FlexRow direction="column" gap="12px">
                <Typography variant="body" size="large" letterSpacing="small">
                  {customToken.symbol}
                </Typography>
                <CustomTokenSymbolTypography
                  variant="body"
                  size="small"
                  letterSpacing="small"
                >
                  {customToken.symbol}
                </CustomTokenSymbolTypography>
                <Typography variant="body" size="small" letterSpacing="small">
                  {customToken.address}
                </Typography>
                <SourceTypography
                  variant="body"
                  size="medium"
                  letterSpacing="small"
                >
                  <FlexRow justify="center" align="center" gap="8.44">
                    <SourceWarningIcon /> Unknown source
                  </FlexRow>
                </SourceTypography>
              </FlexRow>
            </CustomTokenInfoWrapper>
            <Button
              variant="secondary"
              size="small"
              fullWidth
              onClick={handleImportClick}
            >
              Import
            </Button>
          </SectionWrapper>
        </>
      )}
    </SelectTokenDialog>
  )
}

export { TokenSelectModal }
