import { getMaxDepositKeyChunkSize } from '@/constants/deposits'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDeploymentSetConfig } from './useDeploymentSetConfig'
import { setChunkSizeOverride } from '../reducer'

/**
 * Provides the source of truth for the deposit key chunk size, with capability to change
 *  the value (capability is used in DevGui)
 */
export const useDepositSettings = () => {
  const { chainId } = useDeploymentSetConfig()
  const { chunkSizeOverride } = useAppSelector((state) => state.deployments)
  const defaultMaxDepositKeyChunkSize = useMemo<number>(() => {
    return getMaxDepositKeyChunkSize(chainId)
  }, [chainId])
  const dispatch = useAppDispatch()

  const actions = useMemo(
    () => bindActionCreators({ setChunkSizeOverride }, dispatch),
    [dispatch]
  )
  const maxDepositKeyChunkSize = useMemo(
    () => chunkSizeOverride ?? defaultMaxDepositKeyChunkSize,
    [chunkSizeOverride, defaultMaxDepositKeyChunkSize]
  )
  return { maxDepositKeyChunkSize, ...actions }
}
