import React, { useMemo } from 'react'
import { PoolHighlightWidget } from '@/components/PoolHighlightWidget'
import { useEarnAPRsV3Backend } from '@/services/V3BackendService/hooks'
import { PoolTable } from '@/components/PoolTable'
import { PageTitle } from './PageTitle'

function Earn() {
  const earnAPRsQuery = useEarnAPRsV3Backend()

  const { featured, topApy, pools } = useMemo(() => {
    if (earnAPRsQuery.data) {
      return earnAPRsQuery.data
    } else {
      return {
        featured: [],
        topApy: [],
        pools: [],
      }
    }
  }, [earnAPRsQuery.data])

  return (
    <div>
      <PageTitle />
      <PoolHighlightWidget
        loading={!earnAPRsQuery.data}
        featured={featured || []}
        topApy={topApy || []}
      />
      <PoolTable pools={pools || []} loading={!earnAPRsQuery.data} />
    </div>
  )
}

export { Earn }
