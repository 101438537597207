import React from 'react'
import styled from 'styled-components/macro'
import { Box } from '@swell-ui/Box'

import { OperatorsTable } from '@/components/OperatorsTable'
import { PageTitle } from '@/components/PageTitleOld'
import { useProtocolAllOperators } from '@/state/restakingProtocolOperators/hooks'
import eigenUrl from '@/assets/images/eigenlayer-long-lg.png'

const FloatingEigenLogo = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  pointer-events: none;

  transform: translate(0, -50%);
`

const TitlePositioner = styled.div`
  position: relative;
`

const ContainerBox = styled(Box)`
  padding: 24px 42px;
  max-width: 100%;
  overflow: hidden;
`

function AllOperators() {
  const allOperatorsQuery = useProtocolAllOperators()

  return (
    <>
      <TitlePositioner>
        <PageTitle text="All restaking operators" />
        <FloatingEigenLogo src={eigenUrl} width={107} height={43} />
      </TitlePositioner>
      <ContainerBox>
        <OperatorsTable allOperators={allOperatorsQuery.data?.allOperators} />
      </ContainerBox>
    </>
  )
}

export { AllOperators }
