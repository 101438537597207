import { useState } from 'react'
import styled from 'styled-components'
import { CloseIcon } from '@swell-ui/icons/CloseIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { Link } from 'react-router-dom'
import { SwBtcIcon } from '@/swell-ui/icons/SwBtcIcon'
import { ThemeData } from '@/swell-ui/theme/branding'
import { ROUTES } from '@/constants/routes'
import karakLogo from '@/assets/images/karak-80x80.png'
import eigenLogo from '@/assets/images/eigen-80x80.png'
import { useMediaQuery } from '@mui/material'

const symbioticLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg'

const RESTAKE_PROVIDER_LOGO_SIZE = 75 // px
const OVERLAP_X = 14
const OVERLAP_Y = 38

// derived consts
const TRI_LOGO_WIDTH = RESTAKE_PROVIDER_LOGO_SIZE * 2 - OVERLAP_X
const TRI_LOGO_HEIGHT = RESTAKE_PROVIDER_LOGO_SIZE * 2 - OVERLAP_Y

export function BannerSwBTC() {
  const isTablet = useMediaQuery('(max-width: 728px)')
  const isMobile = useMediaQuery('(max-width: 480px)')
  const [open, setOpen] = useState<boolean>(true)

  const handleClose = () => {
    setOpen(false)
  }

  if (!open) {
    return null
  }
  let content = <ContentDesktop />
  if (isTablet) {
    content = <ContentTablet />
  }
  if (isMobile) {
    content = <ContentMobile />
  }

  return (
    <Layout>
      <StyledCloseIcon onClick={handleClose} />
      {content}
    </Layout>
  )
}

function ContentDesktop() {
  return (
    <FlexRow gap={'24'}>
      <SwBtcIcon />
      <FlexRow gap={'24'}>
        <FlexRow gap={'2'} direction="column" align="start">
          <BannerTypographyTitle>
            Swell Bitcoin restaking (swBTC) is here!
          </BannerTypographyTitle>
          <BannerTypographyBody>
            Restake WBTC to get swBTC and earn restaking yield from Symbiotic
            (EigenLayer and Karak coming soon).{' '}
            <BannerTypographyLink to={ROUTES.StakeSwBTC}>
              Restake now
            </BannerTypographyLink>
            .
          </BannerTypographyBody>
        </FlexRow>
        <TriLogo logos={[eigenLogo, karakLogo, symbioticLogo]} />
      </FlexRow>
    </FlexRow>
  )
}

function ContentTablet() {
  return (
    <FlexRow gap={'24'}>
      <SwBtcIcon />
      <FlexRow gap={'24'}>
        <FlexRow gap={'2'} direction="column" align="start">
          <BannerTypographyTitle>
            Swell Bitcoin restaking (swBTC) is here!
          </BannerTypographyTitle>
          <BannerTypographyBody>
            Restake WBTC to get swBTC and earn restaking yield from Symbiotic
            (EigenLayer and Karak coming soon).{' '}
            <BannerTypographyLink to={ROUTES.StakeSwBTC}>
              Restake now
            </BannerTypographyLink>
            .
          </BannerTypographyBody>
        </FlexRow>
      </FlexRow>
    </FlexRow>
  )
}

function ContentMobile() {
  return (
    <FlexRow gap={'8'}>
      <FlexRow gap={'16'}>
        <SwBtcIcon />
        <FlexRow gap={'2'} direction="column" align="start">
          <BannerTypographyTitle>
            Swell Bitcoin restaking (swBTC) is here!
          </BannerTypographyTitle>
          <BannerTypographyBody>
            <BannerTypographyLink to={ROUTES.StakeSwBTC}>
              Restake now
            </BannerTypographyLink>
            .
          </BannerTypographyBody>
        </FlexRow>
      </FlexRow>
    </FlexRow>
  )
}

function TriLogo({ logos }: { logos: string[] }) {
  if (logos.length !== 3) return null

  return (
    <TriLogoLayout>
      {logos.map((logo, i) => (
        <img
          key={i}
          src={logo}
          width={RESTAKE_PROVIDER_LOGO_SIZE}
          height={RESTAKE_PROVIDER_LOGO_SIZE}
        />
      ))}
    </TriLogoLayout>
  )
}

const TriLogoLayout = styled.div`
  flex-shrink: 0;
  width: ${TRI_LOGO_WIDTH}px;
  height: ${TRI_LOGO_HEIGHT}px;
  position: relative;

  img {
    width: ${RESTAKE_PROVIDER_LOGO_SIZE}px;
    height: ${RESTAKE_PROVIDER_LOGO_SIZE}px;
    border-radius: 50%;
  }

  img:nth-child(1) {
    position: absolute;
    top: 0;
    right: 0;
  }
  img:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
  }
  img:nth-child(3) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 8px;
  right: 10px;

  path {
    stroke: #1a0c6d;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const Layout = styled.div`
  border-radius: 24px;
  padding: 24px;
  background: ${ThemeData.Symbiotic.symbioticBtcOrange};
  color: ${ThemeData.Symbiotic.symbioticBitcoinNavy};
  position: relative;
  max-width: 897px;
  margin: 0 auto;
`

const BannerTypographyTitle = styled.span`
  color: var(--Bitcoin-Navy, #14161f);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  letter-spacing: -0.342px;
`

const BannerTypographyBody = styled.span`
  color: var(--Bitcoin-Navy, #14161f);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.342px;
`

const BannerTypographyLink = styled(Link)`
  &,
  &:hover {
    color: var(--Bitcoin-Navy, #14161f);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.342px;
    text-decoration-line: underline !important;
  }
`
