import useTransactionFee from '@/hooks/useTransactionFee'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@/swell-ui/KeyValueList'
import { useDisplayNativeCurrencyPriceFiat } from '@/hooks/useNativeCurrencyDisplay'
import { Token } from '@/types/tokens'
import { displayCrypto } from '@/util/displayCrypto'
import { parseUnits } from 'ethers/lib/utils'
import { displayPercentFromPercent } from '@/util/displayNumbers'
import { BigNumber } from 'ethers'
import { getYearnDepositGasEstimate } from '@/constants/gasEstimates'
import { depositAssetRequiredForVaultToken } from '@/components/StakingWidget/yearn/yearnConversions'

export function YearnDepositExchangeInfo({
  depositAsset,
  apyPercent,
  pricePerShare,
  vaultToken,
}: {
  depositAsset: Token
  vaultToken: Token
  pricePerShare: BigNumber | undefined
  apyPercent: number | undefined
}) {
  const depositTransactionFee = useTransactionFee({
    gasAmount: getYearnDepositGasEstimate().toNumber(),
  })

  const displayNativeCurrencyPriceFiat = useDisplayNativeCurrencyPriceFiat()

  let apyPercentStr = ''
  if (apyPercent) {
    // apyPercentStr = displayPercentFromPercent(apyPercent, { precision: 2 })
    apyPercentStr = 'TBD' // temporary during bootstrapping phase
  }

  const aprConfig = {
    label: `${vaultToken.symbol} APR`,
    value: <AsyncDiv loading={!apyPercentStr}>{() => apyPercentStr}</AsyncDiv>,
    infoText: <span>7 day moving average</span>,
    hasInfo: true,
  }

  let exchangeRateStr = ''
  if (pricePerShare) {
    const oneVaultToken = parseUnits('1', vaultToken.decimals)
    const oneVaultTokenDisplay = displayCrypto(
      oneVaultToken,
      vaultToken.decimals,
      {
        precision: 4,
        localize: true,
      }
    )
    const requiredAssets = depositAssetRequiredForVaultToken({
      pricePerShare,
      toReceiveVaultToken: oneVaultToken,
      vaultTokenDecimals: vaultToken.decimals,
    })
    const requiredAssetsDisplay = displayCrypto(
      requiredAssets,
      depositAsset.decimals,
      {
        precision: 4,
        localize: true,
      }
    )
    exchangeRateStr = `${oneVaultTokenDisplay} ${vaultToken.symbol} = ${requiredAssetsDisplay} ${depositAsset.symbol}`
  }

  const exchangeRateConfig = {
    label: 'Exchange rate',
    value: (
      <AsyncDiv loading={!exchangeRateStr}>{() => exchangeRateStr}</AsyncDiv>
    ),
    hasInfo: true,
    infoText: `Represents the accruing value of ${vaultToken.symbol} vs ${depositAsset.symbol}`,
  }

  const exchangeInfoConfig = {
    label: 'Transaction fee',
    value: (
      <AsyncDiv loading={!depositTransactionFee.data}>
        {() => {
          const { ethUsdRate, feeWei } = depositTransactionFee.data!
          return `${displayNativeCurrencyPriceFiat(feeWei, ethUsdRate)} USD`
        }}
      </AsyncDiv>
    ),
  }

  const exchangeInfoListConfig = {
    list: [aprConfig, exchangeRateConfig, exchangeInfoConfig],
  }

  return <KeyValueList config={exchangeInfoListConfig} />
}
