import styled from 'styled-components'
import { Typography } from '../Typography'
import { FlexRow } from '../FlexRow'

export type TabConfig = {
  id: string
  label: string
}

export function Tabs({
  selectedTab,
  setTab,
  tabs,
}: {
  tabs: TabConfig[]
  setTab: (tab: string) => void
  selectedTab: string
}) {
  return (
    <TabsWrapper>
      <Typography variant="body" size="small" letterSpacing="small">
        <FlexRow align="center" gap="16">
          {tabs.map(({ id, label }) => {
            return (
              <StyledTab
                key={id}
                onClick={() => setTab(id)}
                aria-selected={id === selectedTab}
              >
                {label}
              </StyledTab>
            )
          })}
        </FlexRow>
      </Typography>
    </TabsWrapper>
  )
}

const TabsWrapper = styled.div`
  padding-bottom: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.divider.darkColor};
  margin-bottom: 16px !important;
`

const StyledTab = styled.div`
  color: ${({ theme }) => theme.tabs.color};

  &[aria-selected='true'] {
    color: ${({ theme }) => theme.tabs.hoverColor};
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.tabs.hoverColor};
  }
`
