// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/paraswap.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ParaswapPopulateTransactionRequest, ParaswapPopulateTransactionResponse, ParaswapRoutesRequest, ParaswapRoutesResponse } from "./paraswap_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.ParaswapService
 */
export const ParaswapService = {
  typeName: "swell.v3.ParaswapService",
  methods: {
    /**
     * @generated from rpc swell.v3.ParaswapService.Routes
     */
    routes: {
      name: "Routes",
      I: ParaswapRoutesRequest,
      O: ParaswapRoutesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc swell.v3.ParaswapService.PopulateTransaction
     */
    populateTransaction: {
      name: "PopulateTransaction",
      I: ParaswapPopulateTransactionRequest,
      O: ParaswapPopulateTransactionResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

