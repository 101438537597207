import React from 'react'
import styled, { css } from 'styled-components'

const Title = styled.span`
  color: #818181;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.9px;
  text-transform: uppercase;
`
const Subtitle = styled.span`
  color: #818181;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.75px;
  text-transform: uppercase;
`

export function EcosystemAvatar(props: {
  name: string
  category?: string
  logo?: string
}) {
  let image: React.ReactNode
  if (props.logo) {
    image = <img width={60} height={60} src={props.logo} alt={props.name} />
  } else {
    image = <Que />
  }

  return (
    <AvatarLayout>
      {image}
      <Title>{props.name}</Title>
      {props.category && <Subtitle>{props.category}</Subtitle>}
    </AvatarLayout>
  )
}

const AvatarLayout = styled.div`
  width: 65px;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-transform: uppercase;

  overflow: visible;
  white-space: nowrap;

  svg,
  img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-bottom: 12px;
  }
`

const QLayout = styled.div`
  margin-bottom: 12px;

  display: flex;
  position: relative;
  overflow: visible;

  border-radius: 100px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(4.599999904632568px);
  span {
    display: grid;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 60px;
    border-radius: 50%;

    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 200;
    line-height: 140%; /* 44.8px */
    letter-spacing: -0.96px;

    background: linear-gradient(
      191deg,
      #fff 0%,
      rgba(255, 255, 255, 0.11) 95.57%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    position: relative;

    ::before {
      content: '?';
      font-size: 24px;
      vertical-align: text-top;
    }
  }
`

const blobs = [
  css`
    width: 57px;
    height: 57px;
    position: absolute;
    left: -7.5px;
    top: -3px;
    background: linear-gradient(90deg, #845aff 0%, #4943e0 100%);
    /* background: red; */

    opacity: 0.4;
    filter: blur(16.850000381469727px);
  `,
  css`
    width: 42px;
    height: 42px;
    position: absolute;
    right: -3px;
    top: 18px;
    background: linear-gradient(90deg, #845aff 0%, #4943e0 100%);
    /* background: green; */

    opacity: 0.4;
    filter: blur(16.25px);
  `,
  css`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 9px;
    top: 1px;
    background: var(--lightblue-100, #2f43ec);
    opacity: 0.4;
    filter: blur(10.25px);
  `,
]

const Blob = styled.div<{ styles: any }>`
  ${({ styles }) => styles}
`

function Que() {
  return (
    <QLayout>
      <Blob styles={blobs[0]} />
      <Blob styles={blobs[1]} />
      <Blob styles={blobs[2]} />
      <span />
    </QLayout>
  )
}
