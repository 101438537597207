import { DepositProblemCode } from '@/state/depositValidation/models/DepositProblem'
import { isArray } from 'lodash'
import { MAX_DEPOSIT_KEYS } from '../../../constants/deposits'

export type ProcessInputDepositCollectionResult = {
  depositCollection?: unknown[]
  problemCode?: DepositProblemCode
}

export const processInputDepositCollection = (
  value: string
): ProcessInputDepositCollectionResult => {
  let parsed: any | null = null

  try {
    parsed = JSON.parse(value)
  } catch (err) {
    return { problemCode: `invalid-json` }
  }

  if (!isArray(parsed)) {
    return { problemCode: `not-array` }
  }

  if (parsed.length > MAX_DEPOSIT_KEYS) {
    return { problemCode: `too-big` }
  }

  if (parsed.length === 0) {
    return { problemCode: `too-small` }
  }

  return { depositCollection: parsed }
}
