export type DisplayTimeOpts = {
  preset: 'time-with-seconds'
}

/**
 * Displays time in a some readable format
 *
 * @param millis Time in milliseconds
 * @param param1 Options @see DisplayTimeOpts
 * @returns A formatted string displaying the provided time
 */
export const displayTime = (
  millis: number,
  { preset }: DisplayTimeOpts = { preset: 'time-with-seconds' }
): string => {
  switch (preset) {
    case 'time-with-seconds': {
      const formatter = new Intl.DateTimeFormat(navigator.language, {
        dateStyle: 'short',
        timeStyle: 'medium',
      })
      return formatter
        .format(new Date(millis))
        .replaceAll('/', '-')
        .replaceAll(',', '')
    }
    default:
      throw new Error(`invalid preset: ${preset}`)
  }
}
