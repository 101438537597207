/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  RestakingDepositManager,
  RestakingDepositManagerInterface,
} from "../RestakingDepositManager";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "BotMethodsPaused",
    type: "error",
  },
  {
    inputs: [],
    name: "CannotBeZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "EigenPodMaxLimitReached",
    type: "error",
  },
  {
    inputs: [],
    name: "EigenPodNotCreated",
    type: "error",
  },
  {
    inputs: [],
    name: "InsufficientETHBalance",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidDepositDataRoot",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidETHWithdrawCaller",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidMethodCall",
    type: "error",
  },
  {
    inputs: [],
    name: "NoPubKeysProvided",
    type: "error",
  },
  {
    inputs: [],
    name: "NoTokensToWithdraw",
    type: "error",
  },
  {
    inputs: [],
    name: "OperatorNotVerified",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "ETHReceived",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "eigenPod",
        type: "address",
      },
    ],
    name: "EigenPodCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "version",
        type: "uint8",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes[]",
        name: "pubKeys",
        type: "bytes[]",
      },
    ],
    name: "ValidatorsSetup",
    type: "event",
  },
  {
    stateMutability: "nonpayable",
    type: "fallback",
  },
  {
    inputs: [],
    name: "AccessControlManager",
    outputs: [
      {
        internalType: "contract IAccessControlManager",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DEPOSIT_CONTRACT",
    outputs: [
      {
        internalType: "contract IDepositContract",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "EigenPodManager",
    outputs: [
      {
        internalType: "contract IEigenPodManager",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "createEigenPod",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "eigenPod",
    outputs: [
      {
        internalType: "contract IEigenPod",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "generateWithdrawalCredentialsForEigenPod",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IAccessControlManager",
        name: "_accessControlManager",
        type: "address",
      },
      {
        internalType: "address",
        name: "_eigenManager",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes[]",
        name: "_pubKeys",
        type: "bytes[]",
      },
      {
        internalType: "bytes32",
        name: "_depositDataRoot",
        type: "bytes32",
      },
    ],
    name: "setupValidators",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20",
        name: "_token",
        type: "address",
      },
    ],
    name: "withdrawERC20",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
] as const;

export class RestakingDepositManager__factory {
  static readonly abi = _abi;
  static createInterface(): RestakingDepositManagerInterface {
    return new utils.Interface(_abi) as RestakingDepositManagerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): RestakingDepositManager {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as RestakingDepositManager;
  }
}
