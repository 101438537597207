import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Button } from '@swell-ui/Button'
import { EthInput } from '@swell-ui/inputs/EthInput'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { parseUnitsSafe } from '@/util/big'
import { trimDecimalPlaces } from '@/util/number'
import { Token } from '@/types/tokens'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { useApprove } from '@/hooks/erc20/useApprove'
import { IPreDepositZap } from '@/state/predeposit/zap/IPredepositZap'
import { displayFiat } from '@/util/displayFiat'
import { useDeposit } from '@/state/predeposit/hooks/useDeposit'
import { usePreDepositZap } from '@/state/predeposit/hooks/usePreDepositZap'
import { useWithdraw } from '@/state/predeposit/hooks/useWithdraw'
import { CircularProgress } from '@/swell-ui/CircularProgress'
import { displayCrypto } from '@/util/displayCrypto'
import { PreDepositView, TRANSACTION_TOAST_TITLE, VIEWS } from './constants'
import { ConfirmationProgressWidget } from './ConfirmationProgressWidget'
import { useIERC20Contract } from '@/hooks/useContract'

interface DepositAndWithdrawProps {
  view: PreDepositView
  setView: (view: PreDepositView) => void
  token: Token
  contractToApprove: string
  maxAmount?: BigNumber // user balance or staked balance amount depending on view
  allowance?: BigNumber
  rate?: number
  depositSupported?: boolean
  withdrawSupported?: boolean
  zap?: IPreDepositZap
  customError?: string | null
  afterApprove: () => void
  afterDeposit: () => void
  afterWithdraw: () => void
}

const Tab = styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  width: 50%;
  height: 32px;
  padding: 4px 7px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(1px);
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 200%;
  letter-spacing: 0.9px;

  &:first-child {
    border-radius: 100px 0px 0px 100px;
  }

  &:last-child {
    border-radius: 0px 100px 100px 0px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }

  ${({ selected }) =>
    selected &&
    css`
      border: none;
      background: linear-gradient(90deg, #845aff 0%, #4943e0 100%);
    `}
`

const ActionFlexContainer = styled(FlexRow)`
  padding: 42px 12px 24px 12px;
  border-radius: 8px;
  background: rgba(12, 8, 39, 0.8);
  backdrop-filter: blur(2px);

  input,
  div > div > p {
    font-size: ${({ theme }) => theme.typography.body.large.fontSize};
    font-weight: 600;
  }
`

const StyledEthInput = styled(EthInput)`
  width: 100%;

  .MuiFormHelperText-root {
    margin-top: 2px;
    margin-bottom: 16px;
  }

  .MuiInputBase-root:after {
    border-color: #4943e0;
  }

  .MuiFormHelperText-root {
    margin-top: 12px;
    margin-bottom: 0;
  }
`

const UsdTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
`

const TokenIcon = styled.img`
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 20px;
`

const InfoFlex = styled(FlexRow)`
  padding-top: 12px;
`

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors.eigenLayer['yellow']};
  color: #0a0720;

  &:disabled {
    background: ${({ theme }) => theme.colors.eigenLayer['yellow']};
    color: #0a0720;
    opacity: 0.3;
  }
`

function Tabs({
  onSelect,
  selected,
}: {
  selected: string
  onSelect: (view: string) => void
}) {
  return (
    <FlexRow>
      <Tab
        selected={selected === VIEWS.DEPOSIT}
        onClick={() => onSelect(VIEWS.DEPOSIT)}
      >
        DEPOSIT
      </Tab>
      <Tab
        selected={selected === VIEWS.WITHDRAW}
        onClick={() => onSelect(VIEWS.WITHDRAW)}
      >
        WITHDRAW
      </Tab>
    </FlexRow>
  )
}

export function DepositAndWithdraw({
  view,
  setView,
  token,
  contractToApprove,
  maxAmount,
  allowance,
  rate,
  afterApprove,
  afterDeposit,
  afterWithdraw,
  depositSupported,
  withdrawSupported,
  zap: zapInterface,
  customError,
}: DepositAndWithdrawProps) {
  const isWithdraw = view === VIEWS.WITHDRAW
  const isDeposit = view === VIEWS.DEPOSIT
  const isZap = isDeposit && typeof zapInterface !== 'undefined'

  const { chainId, account, connect } = useSwellWeb3()
  const noAccount = !account
  const { chainId: deploymentChainId } = useDeploymentSetConfig()

  const handleTabSelect = (newView: string) => {
    setView(newView)
  }

  const [tokenInputValue, setTokenInputValue] = useState<string>('')
  const [touched, setTouched] = useState<boolean>(false)

  let requiredToApprove = parseUnitsSafe(
    tokenInputValue || '0',
    token.decimals
  ).sub(allowance || BigNumber.from(0))
  if (requiredToApprove.lt(0)) {
    requiredToApprove = BigNumber.from(0)
  }

  const isApprove = isDeposit && requiredToApprove.gt(0)

  useEffect(() => {
    if (tokenInputValue) {
      setTouched(true)
    }
  }, [tokenInputValue])

  // reset the form if...
  // decimals changes
  useEffect(() => {
    setTokenInputValue('')
    setTouched(false)
  }, [token.decimals])

  // withdraw state changes
  useEffect(() => {
    setTokenInputValue('')
    setTouched(false)
  }, [isWithdraw])

  const tokenContract = useIERC20Contract(token.address)

  // web3 actions:
  const approve = useApprove(tokenContract, contractToApprove)
  const deposit = useDeposit()
  const zap = usePreDepositZap(zapInterface)
  const withdraw = useWithdraw()

  useEffect(() => {
    if (approve.status === approve.STATUS.FULFILLED) {
      afterApprove()
    }
  }, [approve.status, afterApprove, approve.STATUS.FULFILLED])
  useEffect(() => {
    if (deposit.status === deposit.STATUS.FULFILLED) {
      afterDeposit()
    }
  }, [deposit.status, afterDeposit, deposit.STATUS.FULFILLED])
  useEffect(() => {
    if (zap.status === zap.STATUS.FULFILLED) {
      afterDeposit()
    }
  }, [zap.status, afterDeposit, zap.STATUS.FULFILLED])
  useEffect(() => {
    if (withdraw.status === withdraw.STATUS.FULFILLED) {
      afterWithdraw()
    }
  }, [withdraw.status, afterWithdraw, withdraw.STATUS.FULFILLED])

  const isMismatchingChainId = useMemo((): boolean => {
    if (chainId !== deploymentChainId) {
      return true
    }
    return false
  }, [chainId, deploymentChainId])

  const errorMsg = useMemo((): string | null => {
    if (customError) {
      return customError
    }

    if (!touched) {
      return null
    }

    if (tokenInputValue === '') {
      return 'Must enter a value'
    }

    const tokenAmountBN = parseUnitsSafe(tokenInputValue, token.decimals)
    if (tokenAmountBN.eq(0)) {
      return 'Value cannot be 0'
    }

    switch (true) {
      case isWithdraw:
        if (!maxAmount) {
          return null
        }
        if (tokenAmountBN.gt(maxAmount)) {
          return `Cannot withdraw more than staked amount`
        }
        break
      case isApprove:
        if (!maxAmount) {
          return null
        }
        if (tokenAmountBN.gt(maxAmount)) {
          return 'Insufficient balance'
        }
        break
      case isZap:
        if (!maxAmount) {
          return null
        }
        if (tokenAmountBN.gt(maxAmount)) {
          return 'Insufficient balance'
        }
        break
      case isDeposit:
        if (!maxAmount) {
          return null
        }
        if (tokenAmountBN.gt(maxAmount)) {
          return 'Insufficient balance'
        }
        break
      case isMismatchingChainId:
        break
      default:
        // form is in an invalid state, surface error
        console.error('Invalid action')
        return 'Invalid action'
    }

    return null
  }, [
    touched,
    tokenInputValue,
    token.decimals,
    isWithdraw,
    isApprove,
    maxAmount,
    isDeposit,
    isZap,
    isMismatchingChainId,
    customError,
  ])

  const handleTokenInputChange = (event: any) => {
    const value = event.target.value

    if (value === '') {
      setTokenInputValue('')
      return
    }

    setTokenInputValue(trimDecimalPlaces(value, token.decimals))
  }

  function handleMaxClick() {
    if (!maxAmount) return

    const max = formatUnits(maxAmount, token.decimals)
    setTokenInputValue(max)
  }

  function renderUsdAmount() {
    if (!rate) return ''
    let amount = 0
    if (tokenInputValue) {
      amount = parseFloat(tokenInputValue)
    }

    return displayFiat(amount * rate)
  }

  function renderButton() {
    const tokenInputBN = parseUnitsSafe(tokenInputValue || '0', token.decimals)
    const inputIsZero = tokenInputBN.eq(0)

    // flags for button state
    // easier to grok than inverting booleans
    const noMaxAmount = !maxAmount
    const noAllowance = !allowance
    const withdrawNotSupported = !withdrawSupported
    const depositNotSupported = !depositSupported
    const errorMsgPresent = !!errorMsg
    const withdrawStatusNotIdle = withdraw.status !== withdraw.STATUS.IDLE
    const approveStatusNotIdle = approve.status !== approve.STATUS.IDLE
    const depositStatusNotIdle = deposit.status !== deposit.STATUS.IDLE
    const zapStatusNotIdle = zap.status !== zap.STATUS.IDLE

    switch (true) {
      case noAccount:
        return (
          <StyledButton
            variant="primary"
            size="small"
            fullWidth
            onClick={() => connect()}
          >
            Connect Wallet
          </StyledButton>
        )
      case isWithdraw: {
        let label: React.ReactNode
        switch (true) {
          case withdrawSupported === false:
            label = 'Withdraw Not Supported'
            break
          case withdraw.status === withdraw.STATUS.PROMPTING:
            label = 'Pending...'
            break
          case withdraw.status === withdraw.STATUS.PENDING:
            label = (
              <>
                <ButtonProgressPlacer>
                  <ButtonCircularProgress size={24} />
                </ButtonProgressPlacer>
                Confirming...
              </>
            )
            break
          default:
            label = 'Withdraw'
            break
        }

        const disabled =
          inputIsZero ||
          noMaxAmount ||
          withdrawNotSupported ||
          errorMsgPresent ||
          isMismatchingChainId ||
          withdrawStatusNotIdle

        const onClick = () => {
          withdraw.sendTransaction(token, tokenInputBN)
        }

        return (
          <StyledButton
            variant="primary"
            size="small"
            fullWidth
            disabled={disabled}
            onClick={onClick}
          >
            {label}
          </StyledButton>
        )
      }
      case isApprove: {
        let label: React.ReactNode
        switch (true) {
          case depositSupported === false:
            label = 'Deposit Not Supported'
            break
          case approve.status === approve.STATUS.PROMPTING:
            label = 'Pending...'
            break
          case approve.status === approve.STATUS.PENDING:
            label = (
              <>
                <ButtonProgressPlacer>
                  <ButtonCircularProgress size={24} />
                </ButtonProgressPlacer>
                Confirming...
              </>
            )
            break
          default:
            label = 'Approve'
            break
        }

        const disabled =
          inputIsZero ||
          noAllowance ||
          noMaxAmount ||
          depositNotSupported ||
          errorMsgPresent ||
          isMismatchingChainId ||
          approveStatusNotIdle

        return (
          <StyledButton
            variant="primary"
            size="small"
            fullWidth
            disabled={disabled}
            onClick={() => approve.sendTransaction(tokenInputValue, token)}
          >
            {label}
          </StyledButton>
        )
      }

      case isZap: {
        let label: React.ReactNode
        switch (true) {
          case depositSupported === false:
            label = 'Deposit Not Supported'
            break
          case zap.status === zap.STATUS.PROMPTING:
            label = 'Pending...'
            break
          case zap.status === zap.STATUS.PENDING:
            label = (
              <>
                <ButtonProgressPlacer>
                  <ButtonCircularProgress size={24} />
                </ButtonProgressPlacer>
                Confirming...
              </>
            )
            break
          default:
            label = 'Zap In'
            break
        }

        const disabled =
          inputIsZero ||
          noMaxAmount ||
          noAllowance ||
          depositNotSupported ||
          errorMsgPresent ||
          isMismatchingChainId ||
          zapStatusNotIdle

        const onZapIn = () => {
          zap.sendTransaction(token, tokenInputBN)
        }

        return (
          <StyledButton
            variant="primary"
            size="small"
            fullWidth
            disabled={disabled}
            onClick={onZapIn}
          >
            {label}
          </StyledButton>
        )
      }
      case isDeposit: {
        let label: React.ReactNode
        switch (true) {
          case depositSupported === false:
            label = 'Deposit Not Supported'
            break
          case deposit.status === deposit.STATUS.PROMPTING:
            label = 'Pending...'
            break
          case deposit.status === deposit.STATUS.PENDING:
            label = (
              <>
                <ButtonProgressPlacer>
                  <ButtonCircularProgress size={24} />
                </ButtonProgressPlacer>
                Confirming...
              </>
            )
            break
          default:
            label = 'Deposit'
            break
        }

        const disabled =
          inputIsZero ||
          noMaxAmount ||
          noAllowance ||
          depositNotSupported ||
          errorMsgPresent ||
          isMismatchingChainId ||
          depositStatusNotIdle

        const onClick = () => {
          deposit.sendTransaction(token, tokenInputBN)
        }

        return (
          <StyledButton
            variant="primary"
            size="small"
            fullWidth
            disabled={disabled}
            onClick={onClick}
          >
            {label}
          </StyledButton>
        )
      }

      default:
        // fallback
        return (
          <StyledButton
            variant="primary"
            size="small"
            fullWidth
            disabled={true}
            onClick={() => {}}
          >
            {'' as any}
          </StyledButton>
        )
    }
  }

  // reset the form upon successful deposit/zap/withdraw
  // approve state needs to stay for deposit/zap
  useEffect(() => {
    switch (true) {
      case deposit.status === deposit.STATUS.FULFILLED ||
        zap.status === zap.STATUS.FULFILLED ||
        withdraw.status === withdraw.STATUS.FULFILLED: {
        setTouched(false)
        setTokenInputValue('')
        break
      }
      default:
        break
    }
  }, [
    deposit.STATUS.FULFILLED,
    deposit.status,
    withdraw.STATUS.FULFILLED,
    withdraw.status,
    zap.STATUS.FULFILLED,
    zap.status,
  ])

  // Transaction toast logic
  useEffect(() => {
    if (
      approve.status === approve.STATUS.FULFILLED ||
      deposit.status === deposit.STATUS.FULFILLED ||
      zap.status === zap.STATUS.FULFILLED ||
      withdraw.status === withdraw.STATUS.FULFILLED
    ) {
      setTimeout(() => {
        approve.clear()
        deposit.clear()
        zap.clear()
        withdraw.clear()
      }, 5000)
    }
  }, [approve, deposit, withdraw, zap])

  const toastOpen = (): boolean => {
    return (
      approve.status !== approve.STATUS.IDLE ||
      deposit.status !== deposit.STATUS.IDLE ||
      zap.status !== zap.STATUS.IDLE ||
      withdraw.status !== withdraw.STATUS.IDLE
    )
  }

  const toastTitle = (): string => {
    switch (true) {
      case isWithdraw:
        switch (withdraw.status) {
          case withdraw.STATUS.PROMPTING:
            return TRANSACTION_TOAST_TITLE.WITHDRAW_PROMPTING
          case withdraw.STATUS.PENDING:
            return TRANSACTION_TOAST_TITLE.WITHDRAW_PENDING
          case withdraw.STATUS.FULFILLED:
            return TRANSACTION_TOAST_TITLE.WITHDRAW_COMPLETED
          default:
            return ''
        }

      case isApprove:
        switch (approve.status) {
          case approve.STATUS.PROMPTING:
            return TRANSACTION_TOAST_TITLE.APPROVE_PROMPTING
          case approve.STATUS.PENDING:
            return TRANSACTION_TOAST_TITLE.APPROVE_PENDING
          case approve.STATUS.FULFILLED:
            return TRANSACTION_TOAST_TITLE.COMPLETED
          default:
            return ''
        }

      case isZap:
        switch (zap.status) {
          case zap.STATUS.PROMPTING:
            return TRANSACTION_TOAST_TITLE.ZAP_PROMPTING
          case zap.STATUS.PENDING:
            return TRANSACTION_TOAST_TITLE.ZAP_PENDING
          case zap.STATUS.FULFILLED:
            return TRANSACTION_TOAST_TITLE.COMPLETED
          default:
            return ''
        }

      case isDeposit:
        switch (deposit.status) {
          case deposit.STATUS.PROMPTING:
            return TRANSACTION_TOAST_TITLE.DEPOSIT_PROMPTING
          case deposit.STATUS.PENDING:
            return TRANSACTION_TOAST_TITLE.DEPOSIT_PENDING
          case deposit.STATUS.FULFILLED:
            return TRANSACTION_TOAST_TITLE.COMPLETED
          default:
            return ''
        }

      default:
        return ''
    }
  }

  const toastMessage = (): string => {
    switch (true) {
      case isApprove && approve.status !== approve.STATUS.IDLE: {
        const { token, amount } = approve
        if (!token || !amount) return ''
        const formattedAmount = displayCrypto(amount, token.decimals, {
          precision: 4,
          localize: true,
        })
        return `Approve ${formattedAmount} ${token.symbol}`
      }
      case isDeposit && deposit.status !== deposit.STATUS.IDLE: {
        const { token, amount } = deposit
        if (!token || !amount) return ''
        const formattedAmount = displayCrypto(amount, token.decimals, {
          precision: 4,
          localize: true,
        })
        return `Deposit ${formattedAmount} ${token.symbol}`
      }
      case isWithdraw && withdraw.status !== withdraw.STATUS.IDLE: {
        const { token, amount } = withdraw
        if (!token || !amount) return ''
        const formattedAmount = displayCrypto(amount, token.decimals, {
          precision: 4,
          localize: true,
        })
        return `Withdraw ${formattedAmount} ${token.symbol}`
      }
      case isZap && zap.status !== zap.STATUS.IDLE: {
        const { token, amount } = zap
        if (!token || !amount) return ''
        const formattedAmount = displayCrypto(amount, token.decimals, {
          precision: 4,
          localize: true,
        })
        return `Zap In ${formattedAmount} ${token.symbol}`
      }
      default:
        return ''
    }
  }

  const toastTxHash = () => {
    if (approve.tx) {
      return approve.tx.hash
    } else if (zap.tx) {
      return zap.tx.hash
    } else if (deposit.tx) {
      return deposit.tx.hash
    } else if (withdraw.tx) {
      return withdraw.tx.hash
    }
    return ''
  }

  const transactionConfirming = (): boolean => {
    return (
      approve.status === approve.STATUS.PROMPTING ||
      deposit.status === deposit.STATUS.PROMPTING ||
      zap.status === zap.STATUS.PROMPTING ||
      withdraw.status === withdraw.STATUS.PROMPTING
    )
  }

  const transactionPending = (): boolean => {
    return (
      approve.status === approve.STATUS.PENDING ||
      deposit.status === deposit.STATUS.PENDING ||
      zap.status === zap.STATUS.PENDING ||
      withdraw.status === withdraw.STATUS.PENDING
    )
  }

  const transactionComplete = (): boolean => {
    return (
      approve.status === approve.STATUS.FULFILLED ||
      deposit.status === deposit.STATUS.FULFILLED ||
      zap.status === zap.STATUS.FULFILLED ||
      withdraw.status === withdraw.STATUS.FULFILLED
    )
  }

  const onToastClose = () => {
    approve.clear()
    deposit.clear()
    zap.clear()
    withdraw.clear()
  }

  return (
    <FlexRow direction="column" align="flex-start" gap="12">
      <Tabs onSelect={handleTabSelect} selected={view} />
      <ActionFlexContainer direction="column" gap="42">
        <div>
          <StyledEthInput
            variant="standard"
            value={tokenInputValue}
            onChange={handleTokenInputChange}
            error={!!errorMsg}
            helperText={errorMsg}
            disabled={isMismatchingChainId}
            onMaxClick={handleMaxClick}
          />
          {!errorMsg && (
            <InfoFlex justify="space-between">
              <UsdTypography variant="body" size="xsmall" letterSpacing="small">
                {renderUsdAmount()}
              </UsdTypography>
              <FlexRow gap="8" width="auto">
                <TokenIcon src={token.logoURI} />
                <Typography variant="body" size="xsmall" letterSpacing="small">
                  {token.symbol}
                </Typography>
              </FlexRow>
            </InfoFlex>
          )}
        </div>

        {renderButton()}
      </ActionFlexContainer>
      <ConfirmationProgressWidget
        complete={transactionComplete()}
        confirming={transactionConfirming()}
        message={toastMessage()}
        onClose={onToastClose}
        pending={transactionPending()}
        txHash={toastTxHash()}
        open={toastOpen()}
        title={toastTitle()}
      />
    </FlexRow>
  )
}

const ButtonProgressPlacer = styled.div``

const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`
