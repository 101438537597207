import { useDepositCollectionValidatorRestaking } from '@/services/DepositCollectionValidator/hooks'
import {
  useRestakingDepositCollectionValidationState,
  useValidateRestakingDepositCollection,
} from '@/state/restakingDepositValidation/hooks'
import { IDepositCollectionValidationResult } from '@/state/depositValidation/models/DepositCollectionValidationResult'
import { ProgressPayload } from '../../services/TaskRunner/ITaskRunner'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { useRestakingChainDepositConfig } from '@/state/deployments/hooks/useChainDepositConfig'

export interface IUseDepositCollectionValidationArgs {
  input: string
}

export interface IUseDepositCollectionValidationOutput {
  validationResult: IDepositCollectionValidationResult | undefined
  validating: boolean
  validationProgress: ProgressPayload | undefined

  validate: () => void
}

const useRestakingDepositCollectionValidation =
  (): IUseDepositCollectionValidationOutput => {
    const { v3BackendLrtUrl } = useDeploymentSetConfig()
    const chainDepositConfig = useRestakingChainDepositConfig()

    const { validating, validationProgress, validationResult } =
      useRestakingDepositCollectionValidationState()

    // This hook is used for verifying rswETH deposits, hence the LRT backend is hardcoded.
    // It is important to use the correct backend, because rswETH/swETH have different withdrawal credentials.
    const depositCollectionValidator = useDepositCollectionValidatorRestaking(
      v3BackendLrtUrl,
      chainDepositConfig
    )
    const validate = useValidateRestakingDepositCollection(
      depositCollectionValidator
    )

    return {
      validate,
      validating,
      validationProgress,
      validationResult,
    }
  }

export default useRestakingDepositCollectionValidation
