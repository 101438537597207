import React from 'react'

function RswethIcon(props: any) {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.581818"
        y="0.581818"
        width="30.8364"
        height="30.8364"
        rx="15.4182"
        fill="#180D68"
      />
      <rect
        x="0.581818"
        y="0.581818"
        width="30.8364"
        height="30.8364"
        rx="15.4182"
        stroke="url(#paint0_linear_5501_4023)"
        strokeWidth="1.16364"
      />
      <mask
        id="mask0_5501_4023"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="9"
        y="5"
        width="14"
        height="22"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5343 18.4728H15.4179H10.3012L16.0129 26.4802L22.7358 17.0183H16.5816L16.5816 18.4728L15.5343 18.4728ZM16.5816 14.691H21.5446L16.0129 5.54688L9.57617 16.1456H15.4179V14.691H16.5816Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5501_4023)">
        <path
          d="M16.0139 5.52051L15.8125 5.99065V16.0272H16.0139L23.3992 15.0005L16.0139 5.52051Z"
          fill="#FFBC01"
        />
        <path
          d="M16.0135 5.4668L9.54688 16.1827L16.0135 20.0001V13.2472V5.4668Z"
          fill="#FFE8A8"
        />
        <path
          d="M15.998 15.0548V21.3094L15.998 26.2548L15.998 26.5068L22.76 16.9603L21.1636 14.1094L15.998 15.0548Z"
          fill="#FFBC01"
        />
        <path
          d="M16.0135 26.507L16.0135 17.4185H9.54688L16.0135 26.507Z"
          fill="#FFE8A8"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5501_4023"
          x1="16"
          y1="0"
          x2="16"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB901" />
          <stop offset="1" stopColor="#F9CB53" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { RswethIcon }
