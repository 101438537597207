export const VIEWS = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
}

export type PreDepositView = (typeof VIEWS)[keyof typeof VIEWS]

export const TRANSACTION_TOAST_TITLE = {
  COMPLETED: 'Transaction completed!',
  DEPOSIT_PROMPTING: 'Deposit pending',
  DEPOSIT_PENDING: 'Deposit confirming',
  WITHDRAW_PROMPTING: 'Withdraw request pending',
  WITHDRAW_PENDING: 'Withdraw request confirming',
  WITHDRAW_COMPLETED: 'Withdraw request completed!',
  APPROVE_PROMPTING: 'Approve pending',
  APPROVE_PENDING: 'Approve confirming',
  ZAP_BUILDING: 'Zap building',
  ZAP_PROMPTING: 'Zap pending',
  ZAP_PENDING: 'Zap confirming',
}
