import { useMemo } from 'react'
import {
  DataBoxGrid,
  DataBoxGridConfig,
  DataBoxGridItem,
} from '@swell-ui/DataBoxGrid'

export type YourInfoDataBoxGridProps =
  | {
      kind: 'operator'
      totalKeys: number
      usedKeys: number
      unusedKeys: number
    }
  | {
      kind: 'non-operator-account'
    }
  | {
      kind: 'hidden'
    }
  | {
      kind: 'loading'
      totalKeys?: undefined
      usedKeys?: undefined
      unusedKeys?: undefined
    }

function YourInfoDataBoxGrid(props: YourInfoDataBoxGridProps) {
  const gridItems = useMemo<DataBoxGridItem[]>(() => {
    if (props.kind === 'hidden') return []

    if (props.kind === 'non-operator-account') {
      return [
        {
          label: 'Total keys',
          data: `N/A`,
          loading: false,
        },
        {
          label: 'Used keys',
          data: `N/A`,
          loading: false,
        },
        {
          label: 'Unused keys',
          data: `N/A`,
          loading: false,
        },
      ]
    }

    const { totalKeys, unusedKeys, usedKeys } = props

    return [
      {
        label: 'Total keys',
        data: `${totalKeys?.toLocaleString(navigator.language)}`,
        loading: totalKeys === undefined,
      },
      {
        label: 'Used keys',
        data: `${usedKeys?.toLocaleString(navigator.language)}`,
        loading: usedKeys === undefined,
      },
      {
        label: 'Unused keys',
        data: `${unusedKeys?.toLocaleString(navigator.language)}`,
        loading: unusedKeys === undefined,
      },
    ]
  }, [props])

  const config: DataBoxGridConfig = {
    gridSpacing: 2,
    gridItemWidth: {
      xs: 12,
      sm: 4,
    },
    items: gridItems,
  }

  return <DataBoxGrid config={config} />
}

export { YourInfoDataBoxGrid }
