import { useState } from 'react'
import styled from 'styled-components'
import { Accordion } from '@swell-ui/Accordion'
import { CaretDownIcon } from '@swell-ui/icons/CaretDownIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { TableConfig } from '@swell-ui/Table'
import { Typography } from '@swell-ui/Typography'
import { abbreviateNumber } from '@/util/displayNumbers'
import { PoolTableRow } from '.'

const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    margin-bottom: 8px !important;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiCollapse-entered {
    margin-top: 8px;
  }

  .MuiAccordionDetails-root {
    margin-bottom: 0;
  }
`

const PoolSummaryWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  gap: 13px;
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #3068ef;
  background: ${({ theme }) => theme.colors.oceanBlue['100']};
  ${({ expanded, theme }) =>
    expanded && `background: ${theme.colors.oceanBlue['125']};`}
`

const StyledCaretDownIcon = styled(CaretDownIcon)`
  position: absolute;
  right: 14.5px;
  top: 22px;
  width: 12px;
  height: 12px;
`

const ProtocolLogo = styled.img`
  width: 32px;
  height: 32px;
`

const ChainLogo = styled.img`
  width: 16px;
  height: 16px;
`

const PoolLabel = styled.div`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  leading-trim: both;
  text-edge: cap;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 16px */
  letter-spacing: 1.2px;
`

const TokenIconWrapper = styled.div`
  line-height: 12px;
`

const TokenIcon = styled.img<{ index: number }>`
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 24px;
  ${({ index }) => index !== 0 && `left: -2px;`}
  z-index: ${({ index }) => 10 - index};
`

function PoolSummary({
  pool,
  expanded,
}: {
  pool: PoolTableRow
  expanded: boolean
}) {
  return (
    <PoolSummaryWrapper expanded={expanded}>
      <StyledCaretDownIcon />
      <FlexRow direction="column" align="flex-start" gap="16">
        <FlexRow gap="10">
          <ProtocolLogo src={pool.logo} />
          <FlexRow direction="column" align="flex-start">
            <Typography
              variant="headline"
              size="h6"
              fstyle="bold"
              letterSpacing="small"
            >
              {pool.protocol}
            </Typography>
            <FlexRow gap="4">
              <Typography variant="body" size="xsmall" letterSpacing="small">
                {pool.chainName}
              </Typography>
              <ChainLogo src={pool.chainLogo} />
            </FlexRow>
          </FlexRow>
        </FlexRow>
        <FlexRow justify="space-between" align="flex-start">
          <FlexRow direction="column" align="flex-start" width="auto">
            <FlexRow gap="4">
              <PoolLabel>POOL / TOKEN</PoolLabel>
              <TokenIconWrapper>
                {pool.tokenIconList.map((url: string, index: number) => {
                  return <TokenIcon key={index} src={url} index={index} />
                })}
              </TokenIconWrapper>
            </FlexRow>
            <Typography variant="body" size="small" letterSpacing="small">
              {pool.positionName}
            </Typography>
          </FlexRow>
          <FlexRow direction="column" align="flex-start" width="auto">
            <PoolLabel>MAX APY</PoolLabel>
            <Typography variant="body" size="small" letterSpacing="small">
              {pool.maxApy}
            </Typography>
          </FlexRow>
          <FlexRow direction="column" align="flex-start" width="auto">
            <PoolLabel>TVL</PoolLabel>
            <Typography variant="body" size="small" letterSpacing="small">
              ${abbreviateNumber(pool.tvl)}
            </Typography>
          </FlexRow>
        </FlexRow>
      </FlexRow>
    </PoolSummaryWrapper>
  )
}

function PoolDetails({
  pool,
  expanded,
}: {
  pool: PoolTableRow
  expanded: boolean
}) {
  return (
    <PoolSummaryWrapper expanded={expanded}>
      <FlexRow direction="column" align="flex-start" gap="16">
        <FlexRow justify="space-between">
          <PoolLabel>CATEGORY</PoolLabel>
          <Typography variant="body" size="small" letterSpacing="small">
            {pool.category}
          </Typography>
        </FlexRow>
        <FlexRow justify="space-between">
          <PoolLabel>TVL</PoolLabel>
          <Typography variant="body" size="small" letterSpacing="small">
            ${abbreviateNumber(pool.tvl)}
          </Typography>
        </FlexRow>
        <FlexRow justify="space-between">
          <PoolLabel>APY</PoolLabel>
          <Typography variant="body" size="small" letterSpacing="small">
            {pool.formattedApy}
          </Typography>
        </FlexRow>
      </FlexRow>
    </PoolSummaryWrapper>
  )
}

export function PoolTableMobile({
  config,
  loading,
}: {
  config: TableConfig
  loading: boolean
}) {
  const [whichOpen, setWhichOpen] = useState<number>(-1)

  const handleAccordionChange = (index: number) => {
    if (whichOpen === index) {
      return setWhichOpen(-1)
    }

    setWhichOpen(index)
  }

  return (
    <div>
      {!loading &&
        config.rows.map((row: PoolTableRow, index: number) => {
          const accordionConfig = {
            summary: <PoolSummary pool={row} expanded={whichOpen === index} />,
            details: <PoolDetails pool={row} expanded={whichOpen === index} />,
          }

          return (
            <StyledAccordion
              key={index}
              expanded={whichOpen === index}
              config={accordionConfig}
              onChange={() => handleAccordionChange(index)}
            />
          )
        })}
    </div>
  )
}
