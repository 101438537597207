import { ExitAsset, ExitAssetWithChainId } from '@/types/claims'
import { TOKEN_LIST_ETH } from './tokens'
import { SupportedChainId } from './chains'

export const EXIT_ASSET_SWETH_ETH: ExitAssetWithChainId = {
  ...TOKEN_LIST_ETH,
  exitAddress: '0x48C11b86807627AF70a34662D4865cF854251663',
  chainId: SupportedChainId.MAINNET,
}

export const EXIT_ASSET_RSWETH_ETH: ExitAssetWithChainId = {
  ...TOKEN_LIST_ETH,
  exitAddress: '0x58749C46Ffe97e4d79508a2C781C440f4756f064',
  chainId: SupportedChainId.MAINNET,
}

const exitAssets: ExitAsset[] = [EXIT_ASSET_SWETH_ETH, EXIT_ASSET_RSWETH_ETH]

// maps chainId -> exitAddress -> ExitAsset
// exitAddress is unique per exit asset
const chainIdToExitAssetMap: Map<
  SupportedChainId,
  Map<string, ExitAsset>
> = new Map()

for (const exitAsset of exitAssets) {
  const { chainId, exitAddress } = exitAsset
  if (!chainIdToExitAssetMap.has(chainId)) {
    chainIdToExitAssetMap.set(chainId, new Map())
  }
  chainIdToExitAssetMap.get(chainId)!.set(exitAddress, exitAsset)
}

export function getStaticExitWithdrawAsset(
  exitAddress: string,
  chainId: SupportedChainId
): ExitAsset | undefined {
  const exitAssetMap = chainIdToExitAssetMap.get(chainId)
  if (!exitAssetMap) {
    return undefined
  }
  const exitAsset = exitAssetMap.get(exitAddress)
  if (!exitAsset) {
    return undefined
  }
  return exitAsset
}
