import React from 'react'
import styled from 'styled-components/macro'
import { Chip } from '@swell-ui/Chip'
import { FlexRow } from '@swell-ui/FlexRow'
import { Token } from '@/types/tokens'
import { orderBy } from 'lodash'

interface TokenTypeFilterProps {
  tokens: Token[]
  tags: Record<string, { name: string; description: string }>
  onSelect: (tag: string) => void
  onClear: () => void
  isFetching?: boolean
  selected?: string
}

const Layout = styled(FlexRow)`
  flex-wrap: wrap;
  padding: 19px 12px;
  border-radius: 8px;
  background: rgba(12, 8, 39, 0.9);
  backdrop-filter: blur(2px);

  .MuiChip-root {
    border-radius: 100px;
    color: ${({ theme }) => theme.colors.white['50']};
    border-color: 1px solid rgba(255, 255, 255, 0.5);
    font-weight: 600;
    line-height: 24px;

    > span {
      padding: 4px 8px;
    }
  }
`

const StyledChip = styled(Chip)<any>`
  ${({ selected }) =>
    selected &&
    `
    background: linear-gradient(90deg, #845AFF 0%, #4943E0 100%);
    border-color: transparent;
  `}
`

export function TokenTypeFilter({
  tokens,
  tags,
  isFetching,
  onClear,
  onSelect,
  selected,
}: TokenTypeFilterProps) {
  const counts: Record<string, number> = {}

  tokens.forEach((token) => {
    token.tags?.forEach((tag) => {
      if (counts[tag]) {
        counts[tag]++
      } else {
        counts[tag] = 1
      }
    })
  })

  const sortedTags = orderBy(Object.keys(counts), (key) => counts[key], [
    'desc',
  ])

  return (
    <Layout justify="center" gap="6">
      {tokens && (
        <>
          <StyledChip
            label={`All (${tokens.length})`}
            variant="outlined"
            onClick={() => onClear()}
            selected={!selected}
          />
          {sortedTags.map((key: string) => {
            const tag = tags[key]
            if (!tag) {
              console.warn('No tag found for key', key)
              return null
            }
            return (
              <StyledChip
                key={tag.name}
                label={`${tag.name}
                  (${counts[key]})`}
                variant="outlined"
                onClick={() => onSelect(key)}
                selected={selected === key}
              />
            )
          })}
        </>
      )}
    </Layout>
  )
}
