import React, { createContext, useContext, useEffect, useMemo } from 'react'
import {
  Web3OnboardProvider,
  useConnectWallet as w3oUseConnectWallet,
} from '@web3-onboard/react'
import { ethers } from 'ethers'
import { useRpcUrl, useWalletConnectV2ProjectId } from '../config/hooks'
import { createConnector } from '../helpers/connector'
import { WalletType } from '../../types/wallet'
import { initWeb3Onboard } from './init'
import type { ConnectOptions } from '@web3-onboard/core'

const SWELL_TOS_HREF = 'https://www.swellnetwork.io/legal/terms-of-service'

function isGnosisWallet(walletProvider: any) {
  if (!walletProvider) {
    return false
  }

  if (walletProvider.connector?._peerMeta?.name === 'WalletConnect Safe App') {
    return true
  }

  return false
}

export type SwellWeb3Context = {
  account: string | undefined
  chainId: number
  connect: (options?: ConnectOptions) => any
  connector: any // w3o todo: type this
  disconnect: () => any
  ENSName: string | undefined
  icon: string
  isGnosis: boolean
  provider: ethers.providers.Web3Provider
  type: WalletType
}

const MISSING_PROVIDER = Symbol()
const SwellWeb3Context = createContext<
  SwellWeb3Context | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

function _SwellWeb3Provider({ children }: { children: React.ReactNode }) {
  const [{ wallet }, connect, w3oDisconnect] = w3oUseConnectWallet()
  const rpcUrl = useRpcUrl()

  // query selector magic to remove 'powered by blocknative' icon
  useEffect(() => {
    const shadowRoot = document.querySelector('onboard-v2')?.shadowRoot

    if (!shadowRoot) {
      return
    }

    const updateModal = () => {
      const bg = shadowRoot.querySelector('.full-screen-background')
      if (bg) {
        ;(bg as HTMLElement).style['backdropFilter'] = 'blur(10.5px)'
      }

      const modalContainerMobile = shadowRoot.querySelector(
        '.modal-container-mobile'
      )
      if (modalContainerMobile) {
        ;(modalContainerMobile as HTMLElement).style['bottom'] = 'unset'
        if (window.innerWidth < 768) {
          ;(modalContainerMobile as HTMLElement).style['top'] = '50px'
        }
      }

      const mobileSafari = shadowRoot.querySelector('.mobile-safari')
      if (mobileSafari) {
        ;(mobileSafari as HTMLElement).style['paddingBottom'] = '0'
      }

      const modal = shadowRoot.querySelector('.modal-overflow')
      if (modal) {
        ;(modal as HTMLElement).style['border'] = '1px solid rgb(164, 171, 241)'
        ;(modal as HTMLElement).style['borderRadius'] = '16px'
      }

      const sidebar = shadowRoot.querySelector('.sidebar')
      if (!sidebar) {
        return
      }
      const sidebarSvgs = sidebar.querySelectorAll('svg')
      if (window.innerWidth < 768 && sidebarSvgs.length === 2) {
        sidebarSvgs[1].remove()
      } else if (window.innerWidth > 768 && sidebarSvgs.length === 3) {
        sidebarSvgs[2].remove()
      }

      const sidebarDescription = sidebar.querySelector('.description')
      if (!sidebarDescription) return
      sidebarDescription.innerHTML = `By connecting your wallet, you agree to the <a href="${SWELL_TOS_HREF}" target="_blank" rel="noopener noreferrer">Terms of Service</a> of Swell.`
    }

    const shadowRootObserver = new MutationObserver(updateModal)

    shadowRootObserver.observe(shadowRoot, { childList: true, subtree: false })

    return () => {
      shadowRootObserver.disconnect()
    }
  }, [])

  const account = wallet?.accounts?.length
    ? wallet.accounts[0].address
    : undefined
  const ENSName = wallet?.accounts?.length
    ? wallet.accounts[0].ens?.name
    : undefined
  const icon = wallet?.icon || ''
  const chainId = parseInt(wallet?.chains[0].id || '1')

  const disconnect = () => {
    if (!wallet) {
      return
    }
    return w3oDisconnect(wallet)
  }

  const provider = useMemo(() => {
    if (!wallet?.provider) {
      return new ethers.providers.JsonRpcProvider(
        rpcUrl
      ) as ethers.providers.Web3Provider
    }

    return new ethers.providers.Web3Provider(wallet.provider)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet?.provider, rpcUrl, chainId])

  const type = (wallet?.label as WalletType) || WalletType.DISCONNECTED

  const connector = createConnector(wallet?.provider)
  const isGnosis = isGnosisWallet(wallet?.provider)

  return (
    <SwellWeb3Context.Provider
      value={{
        account,
        chainId,
        connect,
        connector,
        disconnect,
        ENSName,
        icon,
        isGnosis,
        provider,
        type,
      }}
    >
      {children}
    </SwellWeb3Context.Provider>
  )
}

function SwellWeb3Provider({ children }: { children: React.ReactNode }) {
  const rpcUrl = useRpcUrl()
  const walletConnectV2ProjectId = useWalletConnectV2ProjectId()

  const web3Onboard = initWeb3Onboard({
    walletConnectV2ProjectId,
    rpcUrl,
  })

  return (
    <Web3OnboardProvider web3Onboard={web3Onboard}>
      <_SwellWeb3Provider>{children}</_SwellWeb3Provider>
    </Web3OnboardProvider>
  )
}

function useSwellWeb3() {
  const value = useContext(SwellWeb3Context)
  if (value === MISSING_PROVIDER) {
    throw new Error('@swell-web3 hooks must be wrapped in <SwellWeb3Provider>')
  }

  return value
}

export { SwellWeb3Provider, useSwellWeb3 }
