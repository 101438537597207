import useSWRImmutable from 'swr/immutable'
import { useSwellWeb3 } from '@swell-web3/core'
import { useRestakingNodeOperatorRegistryContractView } from '@/hooks/useContract'
import {
  useTransactionsNativeV3Backend,
  useTransactionsTokensV3Backend,
} from '@/services/V3BackendService/hooks'
import { ethers } from 'ethers'
import { useDeploymentSetConfig } from '../deployments/hooks'

/**
 * Queries the restaking node operator registry contract to get the restaking node operator
 *  id for a given address.
 *
 * In the case where id=0, the address is not associated with any node operator.
 *
 * @returns OperatorId query
 */
export const useUserOperatorId = () => {
  const { account } = useSwellWeb3()

  const restakingNodeOperatorRegistryContract =
    useRestakingNodeOperatorRegistryContractView()

  return useSWRImmutable(
    [
      restakingNodeOperatorRegistryContract.address,
      'getOperatorIdForAddress',
      account,
    ],
    async () => {
      return restakingNodeOperatorRegistryContract!
        .getOperatorIdForAddress(account!)
        .then((operatorId) => ({ operatorId }))
    }
  )
}

/**
 * Gets restaking operator information for the current connected account
 *
 * @returns Operator struct query
 */
export const useUserOperator = () => {
  const { account } = useSwellWeb3()

  const restakingNodeOperatorRegistryContract =
    useRestakingNodeOperatorRegistryContractView()

  return useSWRImmutable(
    [restakingNodeOperatorRegistryContract.address, 'getOperator', account],
    async () => {
      return restakingNodeOperatorRegistryContract!.getOperator(account!)
    }
  )
}

/**
 * Retrieves the active validator keys for a given address
 *
 * @returns active validator keys query
 */
export const useUserOperatorActiveValidatorKeys = () => {
  const { account } = useSwellWeb3()

  const restakingNodeOperatorRegistryContract =
    useRestakingNodeOperatorRegistryContractView()

  return useSWRImmutable(
    [
      restakingNodeOperatorRegistryContract.address,
      'getOperatorsActiveValidatorDetails',
      account,
    ],
    async () => {
      return restakingNodeOperatorRegistryContract!
        .getOperatorsActiveValidatorDetails(account!)
        .then((activeValidatorKeys) => ({ activeValidatorKeys }))
    }
  )
}

/**
 * Retrieves the pending validator keys for a given address
 *
 * @returns pending validator keys query
 */
export const useUserOperatorPendingValidatorKeys = () => {
  const { account } = useSwellWeb3()

  const restakingNodeOperatorRegistryContract =
    useRestakingNodeOperatorRegistryContractView()

  return useSWRImmutable(
    [
      restakingNodeOperatorRegistryContract.address,
      'getOperatorsPendingValidatorDetails',
      account,
    ],
    async () => {
      return restakingNodeOperatorRegistryContract!
        .getOperatorsPendingValidatorDetails(account!)
        .then((pendingValidatorKeys) => ({ pendingValidatorKeys }))
    }
  )
}

/**
 * Retrieves transaction info associated with fees earned for the current
 *  user operator.
 *
 * A transaction involving earned fees is a mint transaction, indicated by the
 *  transfer initiating from the zero address.
 *
 * @returns Query for a list of mint transactions
 */
export const useUserOperatorFeesEarnedTransactions = ({
  account,
}: {
  account?: string
}) => {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const { data, ...query } = useTransactionsTokensV3Backend(
    v3BackendLrtUrl,
    account
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return {
        feesEarnedTransactions: data.tokenTransactions.filter(
          // fees earned = mint transaction
          // i.e. originating from address zero
          ({ from }) => from === ethers.constants.AddressZero
        ),
      }
    },
  }
}

/**
 * Retrieves recent transactions involving the current user operator
 *
 * @returns Query for a list of recent native transactions
 */
export const useUserOperatorRecentTransactions = ({
  account,
}: {
  account?: string
}) => {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const { data, ...query } = useTransactionsNativeV3Backend(
    v3BackendLrtUrl,
    account
  )

  return {
    ...query,
    get data() {
      if (!data) return undefined
      return { recentTransactions: data.nativeTransactions }
    },
  }
}
