import React from 'react'
import MuiTooltip from '@mui/material/Tooltip'

interface TooltipProps {
  children: React.ReactNode
  title: string | React.ReactNode
  arrow?: boolean
  leaveDelay?: number
  offset?: [number, number]
  placement?: 'top' | 'right' | 'left' | 'bottom'
}

function Tooltip({ children, ...props }: TooltipProps) {
  const offset = props.offset || [0, 8]

  return (
    <MuiTooltip
      {...props}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: offset,
            },
          },
        ],
        sx: {
          '& .MuiTooltip-tooltip': {
            marginTop: '0 !important',
          },
        },
      }}
    >
      <span>{children}</span>
    </MuiTooltip>
  )
}

export { Tooltip }
