import { useState } from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { BigNumber, ethers } from 'ethers'
import { calculateGasMargin } from '@/util/calculateGasMargin'
import { getPreDepositWithdrawGasEstimate } from '@/constants/gasEstimates'
import { Token } from '@/types/tokens'
import { usePreDepositStakingContract } from '@/hooks/useContract'

export interface WithdrawTransactionResponse {
  tx?: any
  error?: {
    message: string
  }
}

export interface UseWithdraw {
  isLoading: boolean
  clear: () => void
  sendTransaction: (
    token: Token,
    amount: BigNumber
  ) => Promise<WithdrawTransactionResponse>
  setStatus: (status: string) => void
  status: string
  STATUS: {
    [key: string]: string
  }
  amount: BigNumber
  token: Token | undefined
  tx: any
}

export function useWithdraw(): UseWithdraw {
  const { account } = useSwellWeb3()
  const staking = usePreDepositStakingContract()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [amount, setAmount] = useState<BigNumber>(BigNumber.from(0))
  const [token, setToken] = useState<Token | undefined>(undefined)

  const STATUS = {
    IDLE: 'idle',
    PROMPTING: 'prompting',
    PENDING: 'pending',
    FULFILLED: 'fulfilled',
    ERROR: 'error',
  }
  const [status, setStatus] = useState<string>(STATUS.IDLE)
  const [tx, setTx] = useState<any>(undefined)

  const clear = () => {
    setStatus(STATUS.IDLE)
    setTx(undefined)
    setAmount(BigNumber.from(0))
    setToken(undefined)
  }

  const sendTransaction = async (token: Token, amount: BigNumber) => {
    if (!account) {
      return {
        error: { message: 'No account.' },
      }
    }

    if (!staking) {
      return {
        error: { message: 'staking contract undefined.' },
      }
    }

    try {
      const _ = ethers.utils.getAddress(token.address)
    } catch (error: any) {
      return {
        error: { message: 'Invalid token address.' },
      }
    }

    if (!amount) {
      return {
        error: { message: 'No amount specified.' },
      }
    }

    setIsLoading(true)

    const receiver = account
    setAmount(amount)
    setToken(token)

    let tx
    try {
      const gasLimitLowest = getPreDepositWithdrawGasEstimate()
      const gasLimitEst = await staking.estimateGas.withdraw(
        token.address,
        amount,
        receiver
      )
      let gasLimit = gasLimitLowest
      if (gasLimitEst.gt(gasLimitLowest)) {
        gasLimit = gasLimitEst
      }

      setStatus(STATUS.PROMPTING)

      const tx = await staking.withdraw(token.address, amount, receiver, {
        gasLimit: calculateGasMargin(gasLimit),
      })
      setTx(tx)
      setStatus(STATUS.PENDING)
      await tx.wait()

      setIsLoading(false)
      setStatus(STATUS.FULFILLED)
      return {
        tx: tx,
      }
    } catch (error: any) {
      setIsLoading(false)
      setStatus(STATUS.IDLE)
      console.error('withdraw:', error)
      return {
        error: { message: error.reason || 'Unknown error' },
      }
    }
  }

  return {
    isLoading,
    clear,
    sendTransaction,
    setStatus,
    status,
    STATUS,
    tx,
    amount,
    token,
  }
}
