import styled from 'styled-components'
import { ExternalLinkIcon } from '@/swell-ui/icons/ExternalLinkIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { abbreviateNumber, displayPercent } from '@/util/displayNumbers'
import { TokenPoolExternal } from '@/types/tokens'
import { css } from 'styled-components'

const CardA = styled.a`
  height: 100%;
`

const Card = styled.div`
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-shrink: 0;
  min-width: 244px;
  padding: 12px;
  border-radius: 12px;
  background: linear-gradient(180.33deg, #3068ef 0.29%, #1322ac 167.95%);
  color: ${({ theme }) => theme.colors.white['50']};
  transition: transform 0.3s ease;

  &:hover {
    opacity: 0.7;
    transform: scale(1.05);
  }

  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      min-width: 289px;
      padding: 24px;
    }
  `}
`

const PoolLogo = styled.img`
  width: 46px;
  height: 46px;
`

const ChainLogo = styled.img`
  width: 16px;
  height: 16px;
`

const PoolTokenText = styled.div`
  leading-trim: both;
  text-edge: cap;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 16px */
  letter-spacing: 1.2px;
`

const TokenIconWrapper = styled.div`
  line-height: 12px;
`

const TokenIcon = styled.img<{ index: number }>`
  position: relative;
  width: 12px;
  height: 12px;
  line-height: 12px;
  border-radius: 24px;
  ${({ index }) => index !== 0 && `left: -2px;`}
  z-index: ${({ index }) => 10 - index};
`

const Layout = styled.div`
  .col-l {
    max-width: 216px;
  }
  .col-r {
    width: max-content;
    white-space: nowrap;
    margin-left: 12px;
  }
`

export function PoolCard({ pool }: { pool: TokenPoolExternal }) {
  return (
    <Layout>
      <CardA href={pool.link} target="_blank" rel="noreferrer">
        <Card>
          <FlexRow
            direction="column"
            align="flex-start"
            justify="space-between"
            gap="24"
            className="col-l"
          >
            <FlexRow gap="10">
              <PoolLogo src={pool.logo} />
              <FlexRow direction="column" align="flex-start" gap="2">
                <FlexRow gap="4">
                  <Typography
                    variant="headline"
                    size="h6"
                    fstyle="bold"
                    letterSpacing="small"
                  >
                    {pool.protocol}
                  </Typography>
                  <ExternalLinkIcon />
                </FlexRow>
                <FlexRow gap="4">
                  <Typography
                    variant="body"
                    size="xsmall"
                    letterSpacing="small"
                  >
                    {pool.chainName}
                  </Typography>
                  <ChainLogo src={pool.chainLogo} />
                </FlexRow>
              </FlexRow>
            </FlexRow>
            <FlexRow direction="column" align="flex-start">
              <FlexRow gap="4">
                <PoolTokenText>POOL / TOKEN</PoolTokenText>
                <TokenIconWrapper>
                  {pool.tokenIconList.map((url: string, index: number) => {
                    return <TokenIcon key={index} src={url} index={index} />
                  })}
                </TokenIconWrapper>
              </FlexRow>
              <Typography variant="body" size="medium" letterSpacing="small">
                {pool.positionName}
              </Typography>
            </FlexRow>
          </FlexRow>
          <FlexRow
            direction="column"
            align="flex-start"
            justify="space-between"
            className="col-r"
          >
            <FlexRow direction="column" align="flex-end">
              <PoolTokenText>MAX APY</PoolTokenText>
              <Typography variant="body" size="medium" letterSpacing="small">
                {displayPercent(pool.apy[pool.apy.length - 1])}
              </Typography>
            </FlexRow>
            <FlexRow direction="column" align="flex-end">
              <PoolTokenText>TVL</PoolTokenText>
              <Typography variant="body" size="medium" letterSpacing="small">
                ${abbreviateNumber(pool.tvl)}
              </Typography>
            </FlexRow>
          </FlexRow>
        </Card>
      </CardA>
    </Layout>
  )
}
