// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/staker.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from enum swell.v3.OrderBy
 */
export enum OrderBy {
  /**
   * @generated from enum value: ORDER_BY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ORDER_BY_TIMESTAMP = 1;
   */
  TIMESTAMP = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(OrderBy)
proto3.util.setEnumType(OrderBy, "swell.v3.OrderBy", [
  { no: 0, name: "ORDER_BY_UNSPECIFIED" },
  { no: 1, name: "ORDER_BY_TIMESTAMP" },
]);

/**
 * @generated from enum swell.v3.StakeTransactionType
 */
export enum StakeTransactionType {
  /**
   * @generated from enum value: STAKE_TRANSACTION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: STAKE_TRANSACTION_TYPE_STAKE = 1;
   */
  STAKE = 1,

  /**
   * @generated from enum value: STAKE_TRANSACTION_TYPE_RESTAKE = 2;
   */
  RESTAKE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(StakeTransactionType)
proto3.util.setEnumType(StakeTransactionType, "swell.v3.StakeTransactionType", [
  { no: 0, name: "STAKE_TRANSACTION_TYPE_UNSPECIFIED" },
  { no: 1, name: "STAKE_TRANSACTION_TYPE_STAKE" },
  { no: 2, name: "STAKE_TRANSACTION_TYPE_RESTAKE" },
]);

/**
 * @generated from enum swell.v3.OrderDirection
 */
export enum OrderDirection {
  /**
   * @generated from enum value: ORDER_DIRECTION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ORDER_DIRECTION_ASC = 1;
   */
  ASC = 1,

  /**
   * @generated from enum value: ORDER_DIRECTION_DESC = 2;
   */
  DESC = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(OrderDirection)
proto3.util.setEnumType(OrderDirection, "swell.v3.OrderDirection", [
  { no: 0, name: "ORDER_DIRECTION_UNSPECIFIED" },
  { no: 1, name: "ORDER_DIRECTION_ASC" },
  { no: 2, name: "ORDER_DIRECTION_DESC" },
]);

/**
 * @generated from message swell.v3.StakesRequest
 */
export class StakesRequest extends Message<StakesRequest> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: swell.v3.OrderBy order_by = 2;
   */
  orderBy = OrderBy.UNSPECIFIED;

  /**
   * @generated from field: swell.v3.OrderDirection order_direction = 3;
   */
  orderDirection = OrderDirection.UNSPECIFIED;

  /**
   * @generated from field: int32 first = 4;
   */
  first = 0;

  /**
   * @generated from field: int32 skip = 5;
   */
  skip = 0;

  /**
   * @generated from field: repeated swell.v3.StakeTransactionType stake_transaction_types = 6;
   */
  stakeTransactionTypes: StakeTransactionType[] = [];

  constructor(data?: PartialMessage<StakesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.StakesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_by", kind: "enum", T: proto3.getEnumType(OrderBy) },
    { no: 3, name: "order_direction", kind: "enum", T: proto3.getEnumType(OrderDirection) },
    { no: 4, name: "first", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "skip", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "stake_transaction_types", kind: "enum", T: proto3.getEnumType(StakeTransactionType), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StakesRequest {
    return new StakesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StakesRequest {
    return new StakesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StakesRequest {
    return new StakesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StakesRequest | PlainMessage<StakesRequest> | undefined, b: StakesRequest | PlainMessage<StakesRequest> | undefined): boolean {
    return proto3.util.equals(StakesRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.Stake
 */
export class Stake extends Message<Stake> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: int64 timestamp = 2;
   */
  timestamp = protoInt64.zero;

  /**
   * @generated from field: string staked_wei = 3;
   */
  stakedWei = "";

  /**
   * @generated from field: string minted_sweth = 4;
   */
  mintedSweth = "";

  /**
   * @generated from field: string tx_hash = 5;
   */
  txHash = "";

  /**
   * @generated from field: swell.v3.StakeTransactionType stake_transaction_type = 6;
   */
  stakeTransactionType = StakeTransactionType.UNSPECIFIED;

  constructor(data?: PartialMessage<Stake>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.Stake";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "staked_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "minted_sweth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "tx_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "stake_transaction_type", kind: "enum", T: proto3.getEnumType(StakeTransactionType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Stake {
    return new Stake().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Stake {
    return new Stake().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Stake {
    return new Stake().fromJsonString(jsonString, options);
  }

  static equals(a: Stake | PlainMessage<Stake> | undefined, b: Stake | PlainMessage<Stake> | undefined): boolean {
    return proto3.util.equals(Stake, a, b);
  }
}

/**
 * @generated from message swell.v3.StakesResponse
 */
export class StakesResponse extends Message<StakesResponse> {
  /**
   * @generated from field: repeated swell.v3.Stake stakes = 1;
   */
  stakes: Stake[] = [];

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<StakesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.StakesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stakes", kind: "message", T: Stake, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StakesResponse {
    return new StakesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StakesResponse {
    return new StakesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StakesResponse {
    return new StakesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StakesResponse | PlainMessage<StakesResponse> | undefined, b: StakesResponse | PlainMessage<StakesResponse> | undefined): boolean {
    return proto3.util.equals(StakesResponse, a, b);
  }
}

