// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/operator.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AllOperatorsRequest, AllOperatorsResponse, DepositsManagerStatsRequest, DepositsManagerStatsResponse, DepositsUserStatsRequest, DepositsUserStatsResponse, TransactionsNativeRequest, TransactionsNativeResponse, TransactionsTokensRequest, TransactionsTokensResponse, ValidateKeysRequest, ValidateKeysResponse } from "./operator_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.OperatorService
 */
export const OperatorService = {
  typeName: "swell.v3.OperatorService",
  methods: {
    /**
     * @generated from rpc swell.v3.OperatorService.DepositsManagerStats
     */
    depositsManagerStats: {
      name: "DepositsManagerStats",
      I: DepositsManagerStatsRequest,
      O: DepositsManagerStatsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.OperatorService.AllOperators
     */
    allOperators: {
      name: "AllOperators",
      I: AllOperatorsRequest,
      O: AllOperatorsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.OperatorService.DepositsUserStats
     */
    depositsUserStats: {
      name: "DepositsUserStats",
      I: DepositsUserStatsRequest,
      O: DepositsUserStatsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.OperatorService.TransactionsTokens
     */
    transactionsTokens: {
      name: "TransactionsTokens",
      I: TransactionsTokensRequest,
      O: TransactionsTokensResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.OperatorService.TransactionsNative
     */
    transactionsNative: {
      name: "TransactionsNative",
      I: TransactionsNativeRequest,
      O: TransactionsNativeResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.OperatorService.ValidateKeys
     */
    validateKeys: {
      name: "ValidateKeys",
      I: ValidateKeysRequest,
      O: ValidateKeysResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

