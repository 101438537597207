import React from 'react'
import { StakingWidget } from '@/components/StakingWidget'
import { useStakingEnabledWithWarning } from '@/hooks/useStakingEnabled'

function StakingPage() {
  const stakingEnabledQuery = useStakingEnabledWithWarning()

  return (
    <>
      <StakingWidget
        stakingEnabled={
          stakingEnabledQuery.data
            ? { isLoading: false, value: stakingEnabledQuery.data.enabled }
            : { isLoading: true }
        }
      />
    </>
  )
}

export { StakingPage }
