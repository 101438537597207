import { useEthUsdMarketRate } from '@/state/fiat/hooks'
import { useGasPrice } from '@/state/gas/hooks'

export interface IUseTransactionFeeArgs {
  gasAmount: number
}

const useTransactionFee = ({ gasAmount }: IUseTransactionFeeArgs) => {
  const gasPrice = useGasPrice()
  const ethUsd = useEthUsdMarketRate()

  return {
    get data() {
      if (!gasPrice.data || !ethUsd.data) {
        return undefined
      }

      const {
        data: { rate },
      } = ethUsd

      return {
        feeWei: gasPrice.data.gasPriceWei.mul(gasAmount),
        ethUsdRate: rate,
      }
    },
    error: ethUsd.error ?? gasPrice.error,
    isLoading: ethUsd.isLoading || gasPrice.isLoading,
    isValidating: ethUsd.isValidating || gasPrice.isValidating,
  }
}

export default useTransactionFee
